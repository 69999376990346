export class DistributionShare {
    private readonly _point_id: string;
    private _shares: number;

    constructor(pointId: string, shares: number) {
        this._point_id = pointId;
        this._shares = shares;
    }

    get pointId(): string {
        return this._point_id;
    }

    get shares(): number {
        return this._shares;
    }

    set shares(value: number) {
        this._shares = value;
    }

    serialize() {
        return {point_id: this._point_id, shares: this._shares};
    }
}