import withConditionalRender from "../../../../../wrapper/withConditionalRender";
import {
    selectIsLowCompletudeDurationNotOverAYear,
    selectIsLowCompletudeTableCoherent, selectIsLowCompletudeTableNotOverlaping,
    selectIsLowCompletudeTableValid, selectLowCompletenessTable,
    selectShowLowCompletenessForm
} from "../../../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import ApplyLowCompletenessButton from "./ApplyLowCompletenessButton";
import React from "react";
import LowCompletenessTable from "./table/LowCompletenessTable";
import CDCPrevisuChart from "../../../../../../components/assets/graphic/CDCPrevisuChart";
import {useDispatch, useSelector} from "react-redux";
import ReplaceBy12MonthlyRows from "./ReplaceBy12MontlyRows";
import AddTableRow from "./AddTableRow";
import ExclamationIcon from "../../../../../../components/assets/icon/ExclamationIcon";
import {typologyFormActions} from "../../../../../../../../../corelogic/usecases/typology-form/typologyFormActions";
import LowCompletudeInput from "./table/LowCompletudeInput";
import {
    selectFirstNewCurve
} from "../../../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-curve/selectFormCurve";

function LowCompletenessForm() {
    const newCurve = useSelector(selectFirstNewCurve)
    const isInputValid = useSelector(selectIsLowCompletudeTableValid)
    const isCoherentInput = useSelector(selectIsLowCompletudeTableCoherent)
    const noOverlapsInTable = useSelector(selectIsLowCompletudeTableNotOverlaping)
    const durationLessThanAYear = useSelector(selectIsLowCompletudeDurationNotOverAYear)

    return <div
        className={"w-full px-4 py-2 mt-1 flex flex-col gap-8  rounded bg-blue-50 dark:bg-opacity-20 dark:bg-blue-700"}>
        <div className={'flex gap-1 flex-col'}>
            <Title/>
            <Explanation/>
        </div>
        <div className={"w-full"}>
            <FirstCurveTitle/>
            <CDCPrevisuChart color={"rgb(110,151,217)"} data={newCurve}/>
        </div>
        <div className={'flex gap-1 flex-col'}>
            <div className={"flex gap-2 justify-between w-full"}>
                <ReplaceBy12MonthlyRows/>
                <AddTableRow/>
            </div>
            {!isInputValid && <TableInputInvalidMessages/>}
            {!isCoherentInput &&  isInputValid && <TableInputIncoherentMessage/>}
            {isInputValid &&  isCoherentInput &&  !noOverlapsInTable && <TableInputOverlapMessage/>}
            {isInputValid &&  isCoherentInput &&  noOverlapsInTable &&  !durationLessThanAYear && <TableAndCurveDurationMessage/>}
            <LowCompletenessTable/>
        </div>

        <div className={"gap-2 mt-6 flex flex-col justify-end items-end"}>

            <ApplyLowCompletenessButton/>
        </div>
    </div>
}

export default withConditionalRender(LowCompletenessForm, selectShowLowCompletenessForm)

const TableAndCurveDurationMessage = () => <div className={" px-2 py-1 rounded-sm bg-red-100 dark:bg-opacity-40 dark:bg-red-700  flex gap-2"}>
    <ExclamationIcon className={"text-red-600 dark:text-red-200 h-4 w-4"}/>
    <span className={"text-red-600 dark:text-red-200 text-xs font-semibold truncate"}>Le tableau a une durée cumulée de plus de 365 jours.</span>
</div>

const TableInputOverlapMessage = () => <div className={" px-2 py-1 rounded-sm bg-red-100 dark:bg-opacity-40 dark:bg-red-700  flex gap-2"}>
    <ExclamationIcon className={"text-red-600 dark:text-red-200 h-4 w-4"}/>
    <span className={"text-red-600 dark:text-red-200 text-xs font-semibold truncate"}>Le tableau contient des recouvrements de dates.</span>
</div>
const TableInputInvalidMessages = () => {
    const table:LowCompletudeInput[] = useSelector(selectLowCompletenessTable)

    const Error = ({msg}:{msg:string}) =>  <div className={" px-2 py-1 rounded-sm bg-red-100 dark:bg-opacity-40 dark:bg-red-700  flex gap-2"}>
        <ExclamationIcon className={"text-red-600 dark:text-red-200 h-4 w-4"}/>
        <span className={"text-red-600 dark:text-red-200 text-xs font-semibold truncate"}>{msg}</span>
    </div>
    return <div className={'flex flex-col gap-1'}>{
        table.map((row: LowCompletudeInput) => {
        if (!row.validateRow()) {
            return <Error msg={row.displayRowError()}/>
        } else return <></>
    })
    }</div>
}
const TableInputIncoherentMessage = () => {
    const dispatch = useDispatch()
    return <div
        className={" px-2 py-1 bg-red-100 dark:bg-red-700 dark:bg-opacity-40 w-full justify-between  rounded-sm flex gap-4"}>
        <div className={"flex w-full gap-2 text-red-600 dark:text-red-200"}>
            <ExclamationIcon className={"  h-4 w-4"}/>
            <span className={" text-xs font-semibold truncate"}>
        Le tableau n'est pas trié dans l'ordre décroissant.
    </span>
        </div>
        <div
            onClick={() => dispatch(typologyFormActions.sortLowCompletudeTable())}
            className={"text-red-600 dark:text-red-200 whitespace-nowrap hover:cursor-pointer hover:opacity-100 opacity-80 underline text-xs font-semibold"}>
            Trier le tableau
        </div>
    </div>
}


const Title = () => <span className={"text-sm text-blue-500 dark:text-blue-300 tracking-normal font-bold"}>
    Complétude à l'aide d'un profil hebdomadaire et de volume d'énergie
</span>
const Explanation = () => <span
    className={"text-xs text-blue-500 opacity-80 dark:text-blue-300 tracking-normal font-semibold"}>
    Permet de générer une courbe de charge à partir de peu de données (min. 1 semaine) en définissant un profil type hebdomadaire à partir de la courbe de charge téléversée et en le recalant sur les volumes d'énergies renseignés pour différentes périodes.
</span>

const FirstCurveTitle = () => <span
    className={"relative top-14 left-4 text-sm text-gray-500 opacity-80 dark:text-gray-300 tracking-normal font-semibold"}>
    Profil de la courbe de charge
</span>




