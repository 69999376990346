import React from "react";
import {EnolabColors} from "../../../../../../config/app-config";
import {useSelector} from "react-redux";
import {selectIsDarkMode} from "../../../../../../corelogic/usecases/config/configSelector";
import XCircleSolidIcon from "../icon/XCircleIcon";

export default function TableRowBadge({
                                          text,
                                          color,
                                          disabled,
                                          helpText
                                      }: { text: string, color: EnolabColors, disabled?: boolean, helpText?: string }) {
    const isDarkMode = useSelector(selectIsDarkMode)
    const noText = text === ""
    let rowStyle = " py-2 px-0 text-left "
    rowStyle += disabled && " opacity-40 "

    return <td className={rowStyle}>
        {
            noText ? <NoText isDarkMode={isDarkMode} color={color}/> :
                <span
                    style={{
                        backgroundColor: isDarkMode ? color["800"] : color["200"],
                        color: isDarkMode ? color["400"] : color["600"],
                    }}
                    className={helpText ? " hover:opacity-80 hover:cursor-help badge font-bold " : " badge font-bold "}
                    title={helpText ? helpText : text}>
                    {text}
                </span>
        }
    </td>
}

function NoText({isDarkMode, color}: { isDarkMode: boolean, color: EnolabColors }) {
        return <span style={{color: isDarkMode ? color["800"] : color["200"]}}>
            <XCircleSolidIcon className={" h-6 w-6 "}/>
        </span>
}