// ChatwootWidget.js

import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {selectUser} from "../../../../../corelogic/usecases/authentication/authenticationSelector";
import {v4 as uuid} from 'uuid';

export default function ChatwootWidget() {
    const user = useSelector(selectUser)
    const win: any = window
    const unique_id = uuid();

    useEffect(() => {
        // Add Chatwoot Settings
        win.chatwootSettings = {
            darkMode : "auto",
            hideMessageBubble: false,
            position: 'right', // This can be left or right
            locale: 'fr', // Language to be set
            type: 'standard', // [standard, expanded_bubble]
            // ...Other Config
            showPopoutButton: true,
        }
        document.getElementById('woot--bubble-holder')
    }, []);


    // Paste the script from inbox settings except the <script> tag
    (function (d, t) {
        const BASE_URL = "https://app.chatwoot.com";
        const g: any = d.createElement(t);
        const s: any = d.getElementsByTagName(t)[0];
        g.src = BASE_URL + "/packs/js/sdk.js";
        g.defer = true;
        g.async = true;
        s.parentNode.insertBefore(g, s);
        g.onload = function () {
            win.chatwootSDK.run({
                websiteToken: process.env.REACT_APP_CHATWOOT_TOKEN,
                baseUrl: BASE_URL
            })
            user && win.$chatwoot.setUser(user.id || unique_id, {
                email: user.email,
                name: user.name,
                avatar_url: "https://gravatar.com/avatar/8ea6382a4dfefafcd7052038530cb1a3?s=400&d=robohash&r=x"
            });
        }
    })(document, "script");

    return null;
}

