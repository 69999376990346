import {SimulationForm} from "../../../../models/interfaces/SimulationForm";
import {UserMailAndId} from "../../../../models/types/new-simulation/UserMailAndId";
import {GeneralInfo} from "../../../../models/types/new-simulation/form/GeneralInfo";
import {Typology} from "../../../../models/types/new-simulation/form/Typology";
import {INIT_SIMULATION_FORM, newSimulationActionsType, SET_NEW_SIMULATION} from "../../newSimulationActions";

export const simulationFormInitialState: SimulationForm = new SimulationForm("", "", Date.now().toString(), Date.now().toString(), new UserMailAndId("", ""), new UserMailAndId("", ""), new GeneralInfo("", "", null), new Typology([], []))

const simulationFormReducer = (state = simulationFormInitialState, action: newSimulationActionsType) => {
    switch (action.type) {
        case SET_NEW_SIMULATION :
            return action.payload
        case INIT_SIMULATION_FORM:
            return simulationFormInitialState
        default:
            return state;
    }
}
export default simulationFormReducer