import {ProdType} from "../../../../containers/BalanceSheet";
import {useDispatch, useSelector} from "react-redux";
import React, {useCallback, useEffect} from "react";
import {
    makeSelectCanViewExternalInvestment
} from "../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectInjectionPointForm";
import {
    selectInvestorTRA,
    selectInvestorTRI,
    selectTRA,
    selectTRI
} from "../../../../../../../corelogic/usecases/financialRestitution/financialRestitutionSelector";
import {
    selectIsOpenOperation
} from "../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectGeneralInfoForm";
import {
    financialRestitutionActions
} from "../../../../../../../corelogic/usecases/financialRestitution/financialRestitutionActions";
import CardsBalance from "../../CardsBalance";
import InvestorOpenTable from "./table/InvestorOpenTable";
import InvestorSocialPatriTable from "./table/InvestorSocialPatriTable";
import {
    selectStoredExternalInvestment
} from "../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectExternalInvestment";
import { ExecutionStatus } from "../../../../../../../corelogic/usecases/simulationExecutionStatus/simulationExecutionStatusActions";
import { useSimulationExecutionStatus } from "../../../../utils/useSimulationExecutionStatus";

const InvestorView = ({selectedType, cashFlowTableRef, id, selectedView}: { selectedType: ProdType, cashFlowTableRef:any , id:string, selectedView:boolean}) => {
    const dispatch = useDispatch();

    const selectCanViewExternalInvestment = useCallback(makeSelectCanViewExternalInvestment(selectedType.id), [selectedType])
    const canViewExternalInvestment: boolean = useSelector(selectCanViewExternalInvestment)
    const externalInvestment = useSelector(selectStoredExternalInvestment)

    const tri = useSelector(selectInvestorTRI)
    const tra = useSelector(selectInvestorTRA)
    const isOpenOperation: boolean = useSelector(selectIsOpenOperation)
    const executionStatus = useSimulationExecutionStatus();

    useEffect(() => {
        if(canViewExternalInvestment && selectedView && executionStatus === ExecutionStatus.finished){
            dispatch(financialRestitutionActions.getExternalInvestmentBalanceMetrics(selectedType.scope !== "global" ? selectedType.id : undefined));
            dispatch(financialRestitutionActions.getExternalInvestmentCashFlowData(selectedType.scope !== "global" ? selectedType.id : undefined));
        }
    }, [ selectedType, id,canViewExternalInvestment, externalInvestment, selectedView, executionStatus]);

    return <div className={'bg-slate-50 dark:bg-zinc-800 flex flex-col gap-4 '}>
        <CardsBalance tri={tri} tra={tra}/>
        <div ref={cashFlowTableRef}>
            {
                isOpenOperation ?
                    <InvestorOpenTable selectedType={selectedType} id={id}/> :
                    <InvestorSocialPatriTable id={id}/>
            }
        </div>
    </div>
}
export default InvestorView;