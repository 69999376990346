import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {
    DistributionAlgorithm
} from "../../../../../../../corelogic/models/types/new-simulation/form/operation-parameters/distribution/DistributionAlgorithm";
import {newSimulationActions} from "../../../../../../../corelogic/usecases/new-simulation/newSimulationActions";
import DistributionByPriorityTable from "./DistributionByPriorityTable";
import DistributionBySharesTable from "./DistributionBySharesTable";
import BeakerIcon from "../../../../components/assets/icon/BeakerIcon";
import {
    selectDistributionAlgorithmForm,
} from "../../../../../../../corelogic/usecases/operation-parameters-form/operationParametersFormSelector";
import {
    operationParametersFormActions
} from "../../../../../../../corelogic/usecases/operation-parameters-form/operationParametersFormActions";
import DescriptionText from "../../../../components/assets/text/DescriptionText";
import CheckIcon from "../../../../components/assets/icon/CheckIcon";
import {AdjustmentsHorizontalIcon, Bars2Icon, ExclamationTriangleIcon} from "@heroicons/react/20/solid";
import {ReactComponent as ByDefaultConsumerAlgorithm} from "./images/producer_allocation_by_default.svg";
import {ReactComponent as ByDefaultConsumerAlgorithmDark} from "./images/producer_allocation_by_default_dark.svg"

import {ReactComponent as ByPriorityConsumerAlgorithm} from "./images/producer_allocation_by_priority.svg";
import {ReactComponent as ByPriorityConsumerAlgorithmDark} from "./images/producer_allocation_by_priority_dark.svg";

import {
    ReactComponent as ByUngroupedPriorityConsumerAlgorithm
} from "./images/producer_allocation_by_ungrouped_priority.svg";
import {
    ReactComponent as ByUngroupedPriorityConsumerAlgorithmDark
} from "./images/producer_allocation_by_ungrouped_priority_dark.svg";

import {ReactComponent as ByShareConsumerAlgorithm} from "./images/producer_allocation_by_share.svg";
import {ReactComponent as ByShareConsumerAlgorithmDark} from "./images/producer_allocation_by_share_dark.svg";
import {selectIsDarkMode} from "../../../../../../../corelogic/usecases/config/configSelector";
import {useSnackbar} from "notistack";
import DistributionByUngroupedPriorityTable from "./DistributionByUngroupedPriorityTable";
import {InformationCircleIcon} from "@heroicons/react/24/solid";
import {NewReleaseWrapper} from "../../../wrapper/NewReleaseWrapper";


export enum DistributionAlgorithmLabel {
    by_default = "Dynamique par défaut",
    by_priority = "Dynamique par priorité",
    by_share = "Dynamique par quote part",
    by_ungrouped_priority = "Dynamique par priorité dégroupée",
}


const coefficientRepartitionIcon2 = {
    "by_default": {
        light: <ByDefaultConsumerAlgorithm/>,
        dark: <ByDefaultConsumerAlgorithmDark/>,
    },
    "by_priority": {
        light: <ByPriorityConsumerAlgorithm/>,
        dark: <ByPriorityConsumerAlgorithmDark/>,
    },
    "by_share": {
        light: <ByShareConsumerAlgorithm/>,
        dark: <ByShareConsumerAlgorithmDark/>,
    },
    "by_ungrouped_priority": {
        light: <ByUngroupedPriorityConsumerAlgorithm/>,
        dark: <ByUngroupedPriorityConsumerAlgorithmDark/>,
    },
}

const distributionAlgorithmBadge2 = {
    by_default: [{
        textBold: "Configuration simple.",
        text: "Pas de configuration à effectuer,  la répartition se fait au pro-rata de la consommation à chaque pas de temps.",
        color: "text-zinc-500 dark:text-gray-400",
        Icon: () => CheckIcon({color: "text-zinc-500 min-w-5 min-h-5"})
    }, {
        textBold: "Point d’attention.",
        text: 'Les gros consommateurs se voient affecter une consommation supérieur aux petits consommateurs.',
        color: "text-zinc-500 dark:text-gray-400",
        Icon: () => <ExclamationTriangleIcon className="dark:text-gray-400 text-zinc-500 w-5 h-5"/>
    }, {
        textBold: "Gestion du surplus.",
        text: 'Le surplus est optimisé pour être minimal.',
        color: "text-zinc-500 dark:text-gray-400",
        Icon: () => BeakerIcon({className: " dark:text-gray-400 text-zinc-500 w-5 h-5", disabled: false})
    }],
    by_priority: [
        {
            textBold: "Personnalisée.",
            text: "Configurez quels consommateurs recevront en priorité la production locale.",
            color: "text-zinc-500 dark:text-gray-400",
            Icon: () => <AdjustmentsHorizontalIcon className="dark:text-gray-400 text-zinc-500 w-5 h-5"/>
        }, {
            textBold: "Priorité égale possible.",
            text: 'Au sein d’un même niveau de priorité, l’attribution de la production tend à être la plus égale possible.',
            color: "text-zinc-500 dark:text-gray-400",
            Icon: () => <Bars2Icon className="dark:text-gray-400 text-zinc-500 w-5 h-5"/>
        }, {
            textBold: "Gestion du surplus.",
            text: 'Le surplus est optimisé pour être minimal.',
            color: "text-zinc-500 dark:text-gray-400",
            Icon: () => BeakerIcon({className: " dark:text-gray-400 text-zinc-500 w-5 h-5", disabled: false})
        },
    ],
    by_share: [
        {
            textBold: "Personnalisée.",
            text: "Configurez le nombre de parts attribué à chaque consommateur.",
            color: "text-zinc-500 dark:text-gray-400",
            Icon: () => <AdjustmentsHorizontalIcon className="dark:text-gray-400 text-zinc-500 w-5 h-5"/>
        }, {
            textBold: "Part de surplus de production.",
            text: "Il est possible de garantir du surplus de production.",
            color: "text-zinc-500 dark:text-gray-400",
            Icon: () => <AdjustmentsHorizontalIcon className="dark:text-gray-400 text-zinc-500 w-5 h-5"/>
        }, {
            textBold: "Attribution égale possible.",
            text: ' Deux consommateurs ayant la même part se feront attribuer une production égale.',
            color: "text-zinc-500 dark:text-gray-400",
            Icon: () => <Bars2Icon className="dark:text-gray-400 text-zinc-500 w-5 h-5"/>
        }, {
            textBold: "Gestion du surplus.",
            text: 'Le surplus est optimisé pour être minimal.',
            color: "text-zinc-500 dark:text-gray-400",
            Icon: () => BeakerIcon({className: " dark:text-gray-400 text-zinc-500 w-5 h-5", disabled: false})
        },
    ],
    by_ungrouped_priority: [
        {
            textBold: "Production dégroupée.",
            text: "Définissez en détail la répartition de chaque producteur vers chaque consommateur.",
            color: "text-zinc-500 dark:text-gray-400",
            Icon: () => <AdjustmentsHorizontalIcon className="dark:text-gray-400 text-zinc-500 w-5 h-5"/>
        }, {
            textBold: "Priorité égale possible.",
            text: "Il est possible de donner la même priorité à chaque consommateur pour un producteur.",
            color: "text-zinc-500 dark:text-gray-400",
            Icon: () => <AdjustmentsHorizontalIcon className="dark:text-gray-400 text-zinc-500 w-5 h-5"/>
        }, {
            textBold: "Gestion du surplus.",
            text: 'Le surplus est optimisé pour être minimal.',
            color: "text-zinc-500 dark:text-gray-400",
            Icon: () => BeakerIcon({className: " dark:text-gray-400 text-zinc-500 w-5 h-5", disabled: false})
        },
    ]
}


export default function Distributions({isSurplusAlgorithmByPriority}: { isSurplusAlgorithmByPriority: boolean }) {
    const dispatch = useDispatch()
    const selectedAlgorithm = useSelector(selectDistributionAlgorithmForm)
    const distributionAlgorithmValues = Object.values(DistributionAlgorithm)
    const isDarkMode = useSelector(selectIsDarkMode)

    const [showDescription, setShowDescription] = useState(false)
    const {enqueueSnackbar} = useSnackbar()
    useEffect(() => {
        // return valid or not state of the Step4Form
        dispatch(newSimulationActions.setFormIsValid(selectedAlgorithm !== ""))
    }, [selectedAlgorithm])

    function handleClickOnCard(algorithm: DistributionAlgorithm) {
        if (isSurplusAlgorithmByPriority && algorithm === DistributionAlgorithm.by_share) {
            enqueueSnackbar("Il n'est pas possible de faire de la répartition de surplus par priorité avec de la répartition par quote-part.", {variant: 'warning'})
        } else {
            dispatch(operationParametersFormActions.setDistributionAlgorithm(algorithm));
        }
    }

    function openUrlInNewTab(url: string) {
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    return <>
        <NewReleaseWrapper title={'nouveau'} relativePosition={" right-4 top-12 "}>
        <span className={"whitespace-nowrap text-xl font-bold text-gray-700 dark:text-gray-300  my-2 pt-16"}>
            Répartition de la production locale pour les consommateurs
        </span>
        </NewReleaseWrapper>
        <DescriptionText classname={'text-xs font-semibold text-gray-400 mb-2'}
                         description={'Déterminez l’algorithme de répartition de la production locale vers les consommateurs.'}/>

        <div className="grid grid-cols-3 gap-6 pt-4">
            {
                distributionAlgorithmValues.map((algorithm: DistributionAlgorithm) => {
                    return <ConsumerAlgorithmSelectorCard
                        showDescription={showDescription}
                        onClick={() => handleClickOnCard(algorithm)}
                        selected={selectedAlgorithm === algorithm}
                        Icon={isDarkMode ? () => coefficientRepartitionIcon2[algorithm].dark : () => coefficientRepartitionIcon2[algorithm].light}
                        label={DistributionAlgorithmLabel[algorithm]}
                        badges={distributionAlgorithmBadge2[algorithm]}
                    />
                })
            }
        </div>
        <div className={"flex w-full justify-between pb-2 pt-2 pr-2"}>
            <span onClick={() => openUrlInNewTab('/help?theme=repartition_algorithm')}
                  className={"text-xs flex items-center gap-1 z-[150] self-end underline cursor-pointer text-gray-500 dark:text-gray-400"}>
                <InformationCircleIcon className={'w-5 h-5'}/>
                    Besoin d'aide pour comprendre les algorithmes ?
                </span>
            <span onClick={() => setShowDescription(!showDescription)}
                  className={"text-xs z-[150] self-end underline cursor-help text-gray-500 dark:text-gray-400"}>
                    {showDescription ? "Masquer" : "Afficher"} la description
                </span>
        </div>
        <DistributionByPriorityTable/>
        <DistributionByUngroupedPriorityTable/>
        <DistributionBySharesTable/>
    </>
}


function ConsumerAlgorithmSelectorCard({Icon, label, badges, selected, onClick, showDescription}: {
    Icon: any,
    label: string,
    badges: { textBold: string, text: string, color: string, Icon: () => JSX.Element }[],
    selected: boolean,
    onClick: () => void,
    showDescription: boolean,
}) {
    let mainClass = (selected ? ' border-2 ' : ' cursor-pointer hover:bg-opacity-80 dark:hover:bg-opacity-80 ') + " h-full flex  flex-col rounded-lg border-zinc-700 bg-zinc-200 bg-opacity-40 dark:bg-opacity-40 p-6 dark:border-zinc-300 dark:bg-zinc-600 dark:shadow-2xl "
    return <div
        onClick={onClick}
        className={mainClass}>
        <div className={"h-full flex-col flex  gap-4"}>
            {label && <div className="text-zinc-600 font-bold text-sm pb-2 dark:text-zinc-100">{label}</div>}
            <div className={" relative ml-12 scale-[1.3] w-full pt-8 pb-12"}><Icon/></div>
            {showDescription ? badges.map((badge) => {
                    const Icon = () => <span className={"min-w-5"}>{badge.Icon()}</span>

                    return <div className="flex gap-4">
                        <Icon/>
                        <span className="text-gray-600 dark:text-gray-300 text-xs flex-wrap flex">
                    <b>{badge.textBold}</b>{badge.text}
                </span>
                    </div>
                })
                : <></>
            }
        </div>
    </div>
}
