import {useState} from "react";
import {useSelector} from "react-redux";
import {
    selectCashFlowData,
    selectCashFlowLoading
} from "../../../../../../../corelogic/usecases/financialRestitution/financialRestitutionSelector";
import {
    selectHasLoanRepayment
} from "../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectFinancialParameter";
import {
    ACIAndACCEconomyHead,
    CapexAndOpexHead,
    CapexLoanHead, CumulativeFluxHead, IncomeProducer, NetSavingsHead,
    YearHead
} from "../../table/header/CashFlowHeadComponent";
import Loading from "../../table/Loading";
import {
    ACSavings, CumulativeFlows,
    Excess,
    Investment,
    LoanRepayment,
    Maintenance, NetSavings,
    YearlyResult
} from "../../table/body/CashFlowBodyComponent";
import {
    selectIsACI
} from "../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectConsumptionPointForm";

const SocialPatriTable = ({id}:{id:string}) => {
    const [rowHover, setRowHover] = useState(-1)
    const loading = useSelector(selectCashFlowLoading)
    const hasLoan = useSelector(selectHasLoanRepayment)
    const CashFlowData = useSelector(selectCashFlowData);
    const columnsNumber =  hasLoan ? 8 : 7
    return <div className="bg-white shadow-md rounded dark:bg-zinc-700">
        <table id={id} className="w-full table-auto rounded-lg">
            <thead className={'bg-white dark:bg-zinc-700'}>
            <tr>
                <YearHead/>
                <CapexAndOpexHead/>
                {hasLoan && <CapexLoanHead/>}
                <ACIAndACCEconomyHead/>
                <IncomeProducer  isOpenOperation={false} />
                <NetSavingsHead/>
                <CumulativeFluxHead/>
            </tr>
            <tr></tr>
            </thead>
            {
                loading ? <Loading columnsNumber={columnsNumber}/>
                    : <tbody className="text-gray-500 text-xs font-light dark:text-zinc-400 bg-white dark:bg-zinc-700">
                    {
                        CashFlowData.map((cashFlowRow: any, idx: number) =>
                            <tr
                                onMouseEnter={() => setRowHover(idx)}
                                onMouseLeave={() => setRowHover(-1)}
                                className="border-b border-gray-200 hover:bg-gray-100 dark:hover:bg-zinc-600 dark:border-zinc-800">
                                <YearlyResult idx={idx}/>
                                <Investment cashFlowRow={cashFlowRow}/>
                                <Maintenance cashFlowRow={cashFlowRow}/>
                                {hasLoan && <LoanRepayment cashFlowRow={cashFlowRow}/>}
                                <ACSavings cashFlowRow={cashFlowRow}/>
                                <Excess cashFlowRow={cashFlowRow} rowHover={rowHover} idx={idx}/>
                                <NetSavings cashFlowRow={cashFlowRow}/>
                                <CumulativeFlows cashFlowRow={cashFlowRow} rowHover={rowHover} idx={idx}/>
                            </tr>
                        )}
                    </tbody>
            }
        </table>
    </div>
}
export default SocialPatriTable
