import {ConsumptionPoint} from "../../../models/types/new-simulation/form/ConsumptionPoint";
import {useDispatch, useSelector} from "react-redux";
import {
    selectSeasonalityForm
} from "../operationParametersFormSelector";
import {
    selectConsumptionPoints
} from "../../new-simulation/new-simulation-selector/form-data/selectConsumptionPointForm";
import {useState} from "react";
import {
    operationParametersFormActions
} from "../operationParametersFormActions";
import {Seasonality} from "../../../models/types/new-simulation/form/operation-parameters/seasonality/Seasonality";

export type SeasonalityConfig = {
    monthsSelected: {
        value: boolean[],
        toggle: (index: number) => void,
        reset: () => void,
        isDisabled: () => boolean,
    },
    consumersSelected: {
        value: ConsumptionPoint[],
        selectAll: () => void,
        selectRow: (consumer: ConsumptionPoint) => void,
    },
    apply: () => void,
}
export default function useSeasonalityConfig() : SeasonalityConfig{
    const dispatch = useDispatch()
    const seasonality = useSelector(selectSeasonalityForm)
    const consumptionPoints: ConsumptionPoint[] = useSelector(selectConsumptionPoints)

    const [monthsSelected, setMonthsSelected] = useState<boolean[]>(Array(12).fill(true))
    const [consumersSelected, setConsumersSelected] = useState<ConsumptionPoint[]>([])

    function selectAll() {
        const allConsumersSelected = consumersSelected.length === consumptionPoints.length
        if (allConsumersSelected) {
            setConsumersSelected([])
        } else {
            setConsumersSelected(consumptionPoints)
        }
    }
    function selectRow(consumer: ConsumptionPoint) {
        if (consumersSelected.includes(consumer)) {
            setConsumersSelected(consumersSelected.filter((c) => c !== consumer))
        } else {
            setConsumersSelected([...consumersSelected, consumer])
        }
    }
    function applyConfiguration() {
        let newSeasonality: Seasonality = {...seasonality}
        consumersSelected.forEach((consumer) => {
            const index = newSeasonality.consumptionPoints.indexOf(consumer);
            newSeasonality.seasonActivated[index] = [...monthsSelected]
        })
        dispatch(operationParametersFormActions.setSeasonality(newSeasonality))
        setConsumersSelected([])
    }

    function toggleMonth(index: number) {
        const newMonthsSelected = [...monthsSelected]
        newMonthsSelected[index] = !newMonthsSelected[index]
        setMonthsSelected(newMonthsSelected)
    }
    function resetMonths() {
        setMonthsSelected(Array(12).fill(true))
    }

    function isDisabled() {
        return monthsSelected.every((month) => month)
    }

    return {
        monthsSelected: {
            value: monthsSelected,
            toggle: toggleMonth,
            reset : resetMonths,
            isDisabled,
        },
        consumersSelected: {
            value: consumersSelected,
            selectAll,
            selectRow,
        },
        apply: applyConfiguration,
    }
}
