import TitleText from "../components/assets/text/TitleText";
import TextInput from "../components/assets/FormAssets/TextInput";
import RegularBtn from "../components/assets/button/RegularBtn";
import {ButtonState, State} from "../../../../config/app-config";
import LoginIcon from "../components/assets/icon/LoginIcon";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {authenticationActions} from "../../../../corelogic/usecases/authentication/authenticationActions";
import {selectEnolabErrorCode} from "../../../../corelogic/usecases/errors/errorsSelector";
import Notification from "../composition/paper/Notification";
import {ErrorsEnum} from "../../utils/snackbarErrorMessages";

export default function Login()  {
    const dispatch = useDispatch()
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [usernameIsValid, setUsernameIsValid] = useState(true)
    const [passwordIsValid, setPasswordIsValid] = useState(true)
    const [invalidCredentials, setInvalidCredentials] = useState(false)

    const enolabErrorCode:string = useSelector(selectEnolabErrorCode)

    useEffect(()=> {
        if(enolabErrorCode === ErrorsEnum.LAB_LOGIN_INVALID_CREDENTIALS){
            setInvalidCredentials(true)
        }
    },[enolabErrorCode])

    function usernameOnChange(e: string) {
        setUsername(e);
        setUsernameIsValid(e.length>0)
    }
    function passwordOnChange(e: string) {
        setPassword(e);
        setPasswordIsValid(e.length>0)

    }
    function handleLogin(){
        dispatch(authenticationActions.logIn({email:username, password:password}))
    }

    return <div className=" h-screen w-screen items-center justify-center scale-95 flex flex-col md:flex-row 2xl:scale-95">
        <div className={"w-64 h-64 md:w-full md:h-min"}>
            <LoginIcon/>
        </div>
        <div className="box-border flex w-full items-center justify-center px-8 2xl:mx-8">
            <div className="flex w-full max-w-[450px] flex-col items-center justify-center 2xl:max-w-[700px]">
                <TitleText title="Se connecter à EnoLab"/>
                <div className="mx-4 mb-12 text-center text-sm text-slate-600 2xl:mb-8 dark:text-zinc-400">Saisir votre login et mot de passe
                    pour vous connecter à EnoLab.
                </div>

                {invalidCredentials && <Notification state={State.ERROR} text={"Désolé, il semble que votre identifiant ou mot de passe soit erroné."}/>}
                <TextInput id={"action-username"} value={username} onChange={usernameOnChange} mandatory={true} error={{state: !usernameIsValid, msg:"le nom d'utilisateur est requis"}} placeholder={"gerard.dupont@mail.com"} label="Nom d'utilisateur"/>
                <TextInput handleKeyEnter={handleLogin} id={"action-password"} inputType={"password"} value={password}  onChange={passwordOnChange} mandatory={true} error={{state: !passwordIsValid, msg:"le mot de passe est requis"}}  placeholder={"********"} label="Mot de passe"/>
                <div className="my-6 text-xs text-gray-500">* champs obligatoires</div>
                <RegularBtn id={"login-btn"} action={handleLogin} state={ButtonState.REGULAR} title="Connexion"/>
            </div>
        </div>
    </div>

}
