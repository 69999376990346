import * as XLSX from "xlsx";
import TemplateButton from "../button/TemplateButton";
import React from "react";

function templateCDC1hourXls(): any[] {
    let CDCTemplate2022_1hour:any[] = []
    for(let month = 1; month <= 12; month++) {
        const totalDays = new Date(2022, month, 0).getDate(); // Get the total number of days in the current month
        for (let day = 1; day <= totalDays; day++) {
            for (let hour = 0; hour < 24; hour++) {
                const monthFormatted = month <10 ? "0"+month : month
                const dayFormatted = day < 10 ? "0"+day : day
                const hourFormatted = hour <10 ? "0"+hour : hour
                CDCTemplate2022_1hour.push({
                    "Datetime (timezoneUTC) ": `${dayFormatted}/${monthFormatted}/2022 ${hourFormatted}:00:00`,
                    "Puissance (W)": 50.5
                })
            }
        }
    }
    return CDCTemplate2022_1hour
}

function templateCDC30minXls(): any[] {
    let CDCTemplate2022_30min = []
    for (let month = 1; month <= 12; month++) {
        const totalDays = new Date(2022, month, 0).getDate(); // Get the total number of days in the current month
        for (let day = 1; day <= totalDays; day++) {
            for (let hour = 0; hour < 24; hour++) {
                for (let halfHour = 0; halfHour < 2; halfHour++) {
                    const monthFormatted = month <10 ? "0"+month : month
                    const dayFormatted = day < 10 ? "0"+day : day
                    const hourFormatted = hour <10 ? "0"+hour : hour
                    const halfHourFormatted = halfHour === 0 ? "00":"30"
                    CDCTemplate2022_30min.push({
                        "Datetime (timezoneUTC) ": `${dayFormatted}/${monthFormatted}/2022 ${hourFormatted}:${halfHourFormatted}:00`,
                        "Puissance (W)": 50.5
                    })
                }
            }
        }
    }
    return CDCTemplate2022_30min
}
function templateCDC5minXls(): any[] {
    let CDCTemplate2022_5min = []
    for (let month = 1; month <= 12; month++) {
        const totalDays = new Date(2022, month, 0).getDate(); // Get the total number of days in the current month
        for (let day = 1; day <= totalDays; day++) {
            for (let hour = 0; hour < 24; hour++) {
                for (let tenMinutes = 0; tenMinutes < 6; tenMinutes++) {
                    const monthFormatted = month <10 ? "0"+month : month
                    const dayFormatted = day < 10 ? "0"+day : day
                    const hourFormatted = hour <10 ? "0"+hour : hour
                    let minutesFormatted = tenMinutes === 0 ? "00":(tenMinutes*10).toString()
                    for(let fiveMinutes = 0; fiveMinutes <2; fiveMinutes++){
                        const addFiveMinutes = fiveMinutes === 1
                        minutesFormatted = addFiveMinutes ? minutesFormatted.slice(0, -1) + '5' : minutesFormatted
                        CDCTemplate2022_5min.push({
                            "Datetime (timezoneUTC) ": `${dayFormatted}/${monthFormatted}/2022 ${hourFormatted}:${minutesFormatted}:00`,
                            "Puissance (W)": 50.5
                        })
                    }

                }
            }
        }
    }
    return CDCTemplate2022_5min
}

function templateCDC10minXls(): any[] {
    let CDCTemplate2022_10min = []
    for (let month = 1; month <= 12; month++) {
        const totalDays = new Date(2022, month, 0).getDate(); // Get the total number of days in the current month
        for (let day = 1; day <= totalDays; day++) {
            for (let hour = 0; hour < 24; hour++) {
                for (let tenMinutes = 0; tenMinutes < 6; tenMinutes++) {
                    const monthFormatted = month <10 ? "0"+month : month
                    const dayFormatted = day < 10 ? "0"+day : day
                    const hourFormatted = hour <10 ? "0"+hour : hour
                    const tenMinutesFormatted = tenMinutes === 0 ? "00":tenMinutes*10
                    CDCTemplate2022_10min.push({
                        "Datetime (timezoneUTC) ": `${dayFormatted}/${monthFormatted}/2022 ${hourFormatted}:${tenMinutesFormatted}:00`,
                        "Puissance (W)": 50.5
                    })
                }
            }
        }
    }
    return CDCTemplate2022_10min
}

enum Template_step_type {
    five_min='5_MIN',
    ten_min = '10_MIN',
    thirty_min = '30_MIN',
    one_hour = '60_MIN'
}

export default function DownloadCDCTemplate() {
    function downloadModel(step: string) {
        let templateToDownload: any

        switch (step) {
            case Template_step_type.five_min :
                templateToDownload = templateCDC5minXls();
                break;
            case Template_step_type.ten_min :
                templateToDownload = templateCDC10minXls();
                break;
            case Template_step_type.thirty_min :
                templateToDownload = templateCDC30minXls();
                break;
            case Template_step_type.one_hour :
                templateToDownload = templateCDC1hourXls();
                break;
            default :
                templateToDownload = templateCDC30minXls();
                break;
        }

        let wb = XLSX.utils.book_new();
        wb.Props = {
            Title: 'Template Excel de courbe de charge',
            Subject: 'Template formatté excel de courbe de charge. ENOGRID 2022',
            Author: 'Enogrid',
            CreatedDate: new Date(),
        };
        let wsName = 'newSheet';
        let ws = XLSX.utils.json_to_sheet(templateToDownload, {dateNF: 'dd/mm/yyyy hh:mm:ss'});
        XLSX.utils.book_append_sheet(wb, ws, wsName);
        XLSX.writeFile(wb, 'courbe_de_charge_template_' + step + '.xlsx');
    }

    return <div className={"flex items-center flex-wrap w-full gap-1 flex-row"}>
        <span className={"text-xs dark:text-blue-200 text-blue-600 "}>Récupérez un template de CDC à pas de temps fixe en UTC </span>
        <TemplateButton action={() => downloadModel(Template_step_type.five_min)} filename={'template_5_min.xls'}/>
        <TemplateButton action={() => downloadModel(Template_step_type.ten_min)} filename={'template_10_min.xls'}/>
        <TemplateButton action={() => downloadModel(Template_step_type.thirty_min)} filename={'template_30_min.xls'}/>
        <TemplateButton action={() => downloadModel(Template_step_type.one_hour)} filename={'template_60_min.xls'}/>
    </div>
}
