import React from "react";

export default function({disabled, className} : { disabled : boolean, className ?:string }) {
    return (
        <svg className={className ? className : disabled ? "h-20 w-20 text-white" : "h-20 w-20 text-slate-600" }
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 511.999 511.999"
            version="1.1"
            viewBox="0 0 511.999 511.999"
            xmlSpace="preserve"
        >
            <g fill="#CEE8FA">
                <path d="M321.755 136.278L255.999 85.795 190.244 136.278 190.244 65.825 255.999 14.847 321.755 65.825z"/>
                <path d="M321.755 204.97L496.808 204.97 470.035 105.875 321.755 105.875z"/>
                <path d="M190.244 105.875L41.964 105.875 15.19 204.97 190.244 204.97z"/>
                <path d="M190.245 362.392H321.75300000000004V497.152H190.245z"/>
                <path d="M54.259 286.178H137.216V383.179H54.259z"/>
                <path d="M374.775 286.178H457.73199999999997V383.179H374.775z"/>
            </g>
            <path
                fill="#2D527C"
                d="M511.143 201.098l-26.774-99.095a14.85 14.85 0 00-14.334-10.976H336.601V65.825c0-4.589-2.123-8.922-5.751-11.734L265.097 3.113a14.85 14.85 0 00-18.195 0L181.147 54.09a14.85 14.85 0 00-5.751 11.734v25.203H41.965a14.846 14.846 0 00-14.334 10.976L.858 201.098a14.847 14.847 0 0014.334 18.722h24.221v277.332c0 8.201 6.649 14.848 14.848 14.848h403.478c8.199 0 14.848-6.647 14.848-14.848V286.172c0-8.2-6.649-14.848-14.848-14.848h-82.957c-8.199 0-14.848 6.647-14.848 14.848v97.006c0 8.201 6.649 14.848 14.848 14.848h68.109v84.278h-106.29V219.82h160.206a14.84 14.84 0 0011.788-5.822 14.848 14.848 0 002.548-12.9zM389.63 368.33v-67.31h53.261v67.31H389.63zM205.092 204.97v-61.372l50.907-39.084 50.906 39.084v203.945H205.092V204.97zm0-131.87l50.907-39.466L306.905 73.1v33.059l-41.864-32.143a14.85 14.85 0 00-18.085 0l-41.865 32.141v-.284l.001-32.773zM53.333 120.723h122.063v69.401H34.583l18.75-69.401zm15.774 180.295h53.261v67.31H69.107v-67.31zm0 97.008h68.109c8.199 0 14.848-6.647 14.848-14.848v-97.006c0-8.201-6.649-14.848-14.848-14.848H69.107V219.82h106.289v262.484H69.107v-84.278zm135.985 84.278V377.238h101.813v105.065H205.092zm131.509-292.182v-69.399h122.065l18.75 69.399H336.601z"
            />
        </svg>
    );
}

