import {all, call, getContext, put, select, takeLatest} from "redux-saga/effects";
import {
    financialRestitutionActions,
    GET_BALANCE_METRICS,
    GET_CASH_FLOW_DATA,
    GET_ECONOMICAL_DATA,
    GET_ECONOMICAL_METRICS,
    GET_INTERNAL_INVESTMENT_BALANCE_METRICS,
    GET_INTERNAL_INVESTMENT_CASH_FLOW_DATA,
    GET_INTERNAL_INVESTMENT_PRODUCER_BALANCE_METRICS, GET_INTERNAL_INVESTMENT_PRODUCER_CASH_FLOW_DATA,
    GET_SURPLUS_DATA,
} from "./financialRestitutionActions";
import {FinancialRestitutionGatewayInterface} from "./financialRestitutionGateway.interface";
import {balanceMetricsNature} from "../../models/types/financialRestitution/NatureEnum";
import BalanceMetricsData from "../../models/types/financialRestitution/BalanceMetricsData";
import EconomicalMetricsData from "../../models/types/financialRestitution/EconomicalMetricsData";
import {
    selectSelectedSimulationId
} from "../new-simulation/new-simulation-selector/form/newSimulationSelector";
import {snackBarErrorsActions} from "../errors/snackbarErrorsActions";
import {ConsumptionPoint} from "../../models/types/new-simulation/form/ConsumptionPoint";
import {InjectionPoint} from "../../models/types/new-simulation/form/InjectionPoint";
import {
    selectActiveSoutiragePoints
} from "../new-simulation/new-simulation-selector/form-data/selectFinancialParameter";
import {selectIsOpenOperation} from "../new-simulation/new-simulation-selector/form-data/selectGeneralInfoForm";
import {
    selectActiveInjectionPoints
} from "../new-simulation/new-simulation-selector/form-data/selectInjectionPointForm";
import {selectSelectedType} from "../physicalRestitution/physicalRestitutionSelector";
import {ProdType} from "../../../adapters/primary/ui/containers/BalanceSheet";

function* getExternalInvestmentProducerCashFlowData(action:any) {
    try {
        yield put(financialRestitutionActions.setCashFlowLoading(true))
        const producerId: string|undefined = action.payload
        const simulationId: string = yield select(selectSelectedSimulationId)
        const financialRestitutionGateway: FinancialRestitutionGatewayInterface = yield getContext('financialRestitutionGatewayContext');
        const cashFlow: { data : any } = yield call(financialRestitutionGateway.getCashFlowData, simulationId, 'producer', producerId );
        yield put(financialRestitutionActions.setExternalInvestmentProducerCashFlowData(cashFlow.data))
    } catch (error: any) {
        yield put(
            financialRestitutionActions.setCashFlowData([]),
        )
        yield put(snackBarErrorsActions.handleError(error));
    }
    yield put(financialRestitutionActions.setCashFlowLoading(false))

}
function* getExternalInvestmentCashFlowData(action:any) {
    try {
        yield put(
            financialRestitutionActions.setCashFlowLoading(true)
        )
        const producerId: string|undefined= action.payload
        const simulationId: string = yield select(selectSelectedSimulationId)
        const financialRestitutionGateway: FinancialRestitutionGatewayInterface = yield getContext('financialRestitutionGatewayContext');
        const cashFlow : { data : any } = yield call(financialRestitutionGateway.getCashFlowData, simulationId,'investor', producerId);
        yield put(financialRestitutionActions.setExternalInvestmentCashFlowData(cashFlow.data))
    } catch (error: any) {
        yield put(
            financialRestitutionActions.setCashFlowData([]),
        )
        yield put(snackBarErrorsActions.handleError(error));
    }
    yield put(financialRestitutionActions.setCashFlowLoading(false))

}
function* getCashFlowData(action : any): any {
    try {
        yield put(
            financialRestitutionActions.setCashFlowLoading(true)
        )
        const pointId : string  = action.payload
        const simulationId: string = yield select(selectSelectedSimulationId)
        const financialRestitutionGateway: FinancialRestitutionGatewayInterface = yield getContext('financialRestitutionGatewayContext');
        const cashFlow : { data : any } = yield call(financialRestitutionGateway.getCashFlowData, simulationId, 'acc', pointId);
        yield put(
            financialRestitutionActions.setCashFlowData(cashFlow.data),
        )
    } catch (error: any) {
        yield put(
            financialRestitutionActions.setCashFlowData([]),
        )
        yield put(snackBarErrorsActions.handleError(error));
    }
    yield put(
        financialRestitutionActions.setCashFlowLoading(false)
    )
}

function* getInvestorProducerBalanceMetrics(action:any): any {
    try {

        yield put(
            financialRestitutionActions.setBalanceMetricsLoading(true)
        )
        const pointId : string  = action.payload
        const simulationId: string = yield select(selectSelectedSimulationId)
        const financialRestitutionGateway: FinancialRestitutionGatewayInterface = yield getContext('financialRestitutionGatewayContext');
        const calls = [];
        calls.push(call(financialRestitutionGateway.getMetricValue, simulationId, balanceMetricsNature.TRI, pointId, 'producer'));
        calls.push(call(financialRestitutionGateway.getMetricValue, simulationId, balanceMetricsNature.TRA, pointId, 'producer'));
        calls.push(call(financialRestitutionGateway.getMetricValue, simulationId, balanceMetricsNature.LCOE, pointId, 'producer'));
        calls.push(call(financialRestitutionGateway.getMetricValue, simulationId, balanceMetricsNature.internalValuation, pointId, 'producer'));
        const [tri, tra, lcoe, internalValuation] = yield all(calls);
        const balanceMetrics: BalanceMetricsData = {
            tri,
            tra,
            lcoe,
            internalValuation
        }

        yield put(
            financialRestitutionActions.setExternalInvestmentProducerBalanceMetrics(balanceMetrics),
        )
    } catch (error: any) {
        yield put(
            financialRestitutionActions.setBalanceMetrics({}),
        )
        yield put(snackBarErrorsActions.handleError(error));
    }
    yield put(
        financialRestitutionActions.setBalanceMetricsLoading(false)
    )
}
function* getInvestorProducerBalanceMetricsSaga() {
    yield takeLatest(GET_INTERNAL_INVESTMENT_PRODUCER_BALANCE_METRICS, getInvestorProducerBalanceMetrics);
}
function* getInvestorBalanceMetrics(action:any): any {
    try {

        yield put(
            financialRestitutionActions.setBalanceMetricsLoading(true)
        )
        const pointId : string  = action.payload
        const simulationId: string = yield select(selectSelectedSimulationId)
        const financialRestitutionGateway: FinancialRestitutionGatewayInterface = yield getContext('financialRestitutionGatewayContext');
        const calls = [];
        calls.push(call(financialRestitutionGateway.getMetricValue, simulationId, balanceMetricsNature.TRI, pointId, 'investor'));
        calls.push(call(financialRestitutionGateway.getMetricValue, simulationId, balanceMetricsNature.TRA, pointId, 'investor'));

        const [tri, tra] = yield all(calls);
        const balanceMetrics: BalanceMetricsData = {
            tri,
            tra,
        }

        yield put(
            financialRestitutionActions.setExternalInvestmentBalanceMetrics(balanceMetrics),
        )
    } catch (error: any) {
        yield put(
            financialRestitutionActions.setBalanceMetrics({}),
        )
        yield put(snackBarErrorsActions.handleError(error));
    }
    yield put(
        financialRestitutionActions.setBalanceMetricsLoading(false)
    )
}
function* getInvestorBalanceMetricsSaga() {
    yield takeLatest(GET_INTERNAL_INVESTMENT_BALANCE_METRICS, getInvestorBalanceMetrics);
}
function* getBalanceMetrics(action:any): any {
    try {

        yield put(
            financialRestitutionActions.setBalanceMetricsLoading(true)
        )
        const pointId : string  = action.payload
        const simulationId: string = yield select(selectSelectedSimulationId)
        const financialRestitutionGateway: FinancialRestitutionGatewayInterface = yield getContext('financialRestitutionGatewayContext');
        const calls = [];
        calls.push(call(financialRestitutionGateway.getMetricValue, simulationId, balanceMetricsNature.TRI, pointId, 'acc'));
        calls.push(call(financialRestitutionGateway.getMetricValue, simulationId, balanceMetricsNature.TRA, pointId, 'acc'));
        calls.push(call(financialRestitutionGateway.getMetricValue, simulationId, balanceMetricsNature.LCOE, pointId, 'acc'));
        calls.push(call(financialRestitutionGateway.getMetricValue, simulationId, balanceMetricsNature.internalValuation, pointId, 'acc'));
        const [tri, tra, lcoe, internalValuation] = yield all(calls);
        const balanceMetrics: BalanceMetricsData = {
            tri,
            tra,
            lcoe,
            internalValuation
        }

        yield put(
            financialRestitutionActions.setBalanceMetrics(balanceMetrics),
        )
    } catch (error: any) {
        yield put(
            financialRestitutionActions.setBalanceMetrics({}),
        )
        yield put(snackBarErrorsActions.handleError(error));
    }
    yield put(
        financialRestitutionActions.setBalanceMetricsLoading(false)
    )
}


function* getEconomicalData(): any {
    try {
        yield put(
            financialRestitutionActions.setEconomicalLoading(true)
        )
        const simulationId: string = yield select(selectSelectedSimulationId)
        const consumptionPoints: ConsumptionPoint[] = yield select(selectActiveSoutiragePoints)
        const financialRestitutionGateway: FinancialRestitutionGatewayInterface = yield getContext('financialRestitutionGatewayContext');
        const calls: any[] = [];
        consumptionPoints.forEach(consumptionPoint => {
            calls.push(call(financialRestitutionGateway.getByPointEconomicalData, simulationId, consumptionPoint.id));
        })
        calls.push(call(financialRestitutionGateway.getByPointEconomicalData, simulationId));
        const savings = yield all(calls)
        yield put(
            financialRestitutionActions.setEconomicalData(savings),
        )
    } catch (error: any) {
        yield put(
            financialRestitutionActions.setEconomicalData([]),
        )
        yield put(snackBarErrorsActions.handleError(error));
    }
    yield put(
        financialRestitutionActions.setEconomicalLoading(false)
    )
}

function* getEconomicalMetrics(): any {
    try {
        yield put(
            financialRestitutionActions.setEconomicalMetricsLoading(true)
        )
        const isOpenOperation : boolean = yield select(selectIsOpenOperation)
        const simulationId: string = yield select(selectSelectedSimulationId)
        const financialRestitutionGateway: FinancialRestitutionGatewayInterface = yield getContext('financialRestitutionGatewayContext');
        const calls = [];
        calls.push(call(financialRestitutionGateway.getMetricValue, simulationId, balanceMetricsNature.totalEconomyYear1));
        calls.push(call(financialRestitutionGateway.getMetricValue, simulationId, balanceMetricsNature.totalEconomyYear25));
        if(isOpenOperation){
            calls.push(call(financialRestitutionGateway.getMetricValue, simulationId, balanceMetricsNature.productorGainAndExcessYear1));
            calls.push(call(financialRestitutionGateway.getMetricValue, simulationId, balanceMetricsNature.productorGainAndExcessYear25));
            const [totalEconomyYear1, totalEconomyYear25, productorGainAndExcessYear1, productorGainAndExcessYear25] = yield all(calls);
            const economicalMetrics: EconomicalMetricsData = {
                productorGainAndExcessYear1,
                productorGainAndExcessYear25,
                totalEconomyYear1,
                totalEconomyYear25,
            }
            yield put(
                financialRestitutionActions.setEconomicalMetrics(economicalMetrics),
            )
        }
        else {
            const [totalEconomyYear1, totalEconomyYear25] = yield all(calls);
            const economicalMetrics: EconomicalMetricsData = {
                totalEconomyYear1,
                totalEconomyYear25,
            }
            yield put(
                financialRestitutionActions.setEconomicalMetrics(economicalMetrics),
            )
        }
    } catch (error: any) {
        yield put(
            financialRestitutionActions.setEconomicalMetrics({}),
        )
        yield put(snackBarErrorsActions.handleError(error));
    }
    yield put(
        financialRestitutionActions.setEconomicalMetricsLoading(false)
    )
}

function* getSurplus(): any {
    try {
        yield put(
            financialRestitutionActions.setSurplusLoading(true)
        )
        const simulationId: string = yield select(selectSelectedSimulationId)
        const injectionPoints: InjectionPoint[] = yield select(selectActiveInjectionPoints)
        const financialRestitutionGateway: FinancialRestitutionGatewayInterface = yield getContext('financialRestitutionGatewayContext');
        const calls: any[] = [];
        // calls.push(call(financialRestitutionGateway.getGlobalEconomicalData, simulationId));
        injectionPoints.forEach(injectionPoint => {
            calls.push(call(financialRestitutionGateway.getSurplusByPoint, simulationId, injectionPoint.id));
        })
        calls.push(call(financialRestitutionGateway.getSurplusByPoint, simulationId));
        const surplus = yield all(calls)
        yield put(
            financialRestitutionActions.setSurplusData(surplus),
        )
    } catch (error: any) {
        yield put(
            financialRestitutionActions.setSurplusData([]),
        )
        yield put(snackBarErrorsActions.handleError(error));
    }
    yield put(
        financialRestitutionActions.setSurplusLoading(false)
    )
}
function* getCashFlowDataSaga() {
    yield takeLatest(GET_CASH_FLOW_DATA, getCashFlowData);
}
function* getExternalInvestmentProducerCashFlowDataSaga() {
    yield takeLatest(GET_INTERNAL_INVESTMENT_PRODUCER_CASH_FLOW_DATA, getExternalInvestmentProducerCashFlowData);
}

function* getBalanceMetricsSaga() {
    yield takeLatest(GET_BALANCE_METRICS, getBalanceMetrics);
}

function* getEconomicalDataSaga() {
    yield takeLatest(GET_ECONOMICAL_DATA, getEconomicalData);
}

function* getEconomicalMetricsSaga() {
    yield takeLatest(GET_ECONOMICAL_METRICS, getEconomicalMetrics);
}

function* getSurplusSaga() {
    yield takeLatest(GET_SURPLUS_DATA, getSurplus);
}

function* getExternalInvestmentCashFlowDataSaga(){
    yield takeLatest(GET_INTERNAL_INVESTMENT_CASH_FLOW_DATA, getExternalInvestmentCashFlowData)
}


const financialRestitutionSagas = [ getInvestorProducerBalanceMetricsSaga, getInvestorBalanceMetricsSaga, getExternalInvestmentProducerCashFlowDataSaga, getExternalInvestmentCashFlowDataSaga, getCashFlowDataSaga, getBalanceMetricsSaga, getEconomicalDataSaga, getEconomicalMetricsSaga, getSurplusSaga]
export default financialRestitutionSagas;