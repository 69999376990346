import React from "react";
import SeasonalityTable from "./SeasonalityTable";
import TitleText from "../../../../components/assets/text/TitleText";
import Divider from "../../../../components/assets/Divider";
import {
    SeasonalityVisibility
} from "../../../../../../../corelogic/usecases/operation-parameters-form/seasonality/useSeasonalityVisibility";

export default function Seasonalities({ seasonalityVisibility}: {seasonalityVisibility : SeasonalityVisibility}) {

    return <>
        <div className={seasonalityVisibility.show ? ' w-min rounded px-2 overflow-auto' : 'overflow-auto'}>
            <div className={"flex items-center"}>
                <div className={"w-full mt-2 flex flex-row items-center"}>
                    {
                        seasonalityVisibility.show ?
                            <TitleText typeOfMargin={"small"} title={"Participation saisonnière"}
                                       textSize={' text-lg '}/>
                            : <label className="mr-2 block tracking-wide text-gray-600 font-semibold dark:text-zinc-300"
                                     htmlFor="grid-last-name"> Appliquer une participation saisonnière à certains de mes
                                consommateurs</label>
                    }
                    <div data-cy={"typology-consumption-aci-switch"} onClick={() => seasonalityVisibility.toggle()}
                         className={seasonalityVisibility.show ? "form-check-input appearance-none w-9 rounded-full float-left h-5 align-top bg-no-repeat bg-contain bg-orange-200 focus:outline-none cursor-pointer shadow-sm" : "form-check-input appearance-none w-9 rounded-full float-left h-5 align-top bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm"}
                         role="switch" id="flexSwitchCheckDefault">
                        <div
                            className={seasonalityVisibility.show ? "w-5 h-5 ml-4 bg-secondary rounded-full " : "w-5 h-5 bg-white rounded-full "}/>
                    </div>
                </div>
            </div>
            {
                seasonalityVisibility.show && <div
                    className="rounded-md">
                    <SeasonalityTable/>
                </div>
            }
            <div className={"mb-4"}></div>
        </div>
        {seasonalityVisibility.show && <Divider/>}
    </>
}
