import {createSelector} from "reselect";
import {RootState} from "../../../store/rootReducer";
import {Question} from "../../../adapters/primary/ui/composition/faq/AllQuestions";

export const selectFAQ = (state: RootState) => state.FAQ;
export const selectQuestions = createSelector(
    selectFAQ,
    FAQ => FAQ.questions
);

export const selectSearch = createSelector(
    selectFAQ,
    FAQ => FAQ.search
);
export const selectTag = createSelector(
    selectFAQ,
    FAQ => FAQ.tag
);
export const isSearchingTagSelected = createSelector(
    selectTag,
    tag =>tag && tag.includes('recherche :')
);
export const makeIsTagSelected = (tagName : string) => createSelector(
    selectTag,
    (tag)=> tag === tagName
)


export const makeIsQuestionExpanded = (question : Question) => () =>  question.expand

export const selectQuestionsSorted = createSelector(
    selectQuestions,
    selectSearch,
    selectTag,
    (questions, search, tag) => questions.filter((q:Question)=> {
        let filter = false
        if(search !== '') {
            filter = filter || q.title.toLowerCase().includes(search.toLowerCase()) || q.description.some((desc:any)=> desc.toLowerCase().includes(search.toLowerCase()))
        }
        return filter || tag === q.key
    } )
);

