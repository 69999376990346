import {ConsumptionPoint} from "../../../../../../../corelogic/models/types/new-simulation/form/ConsumptionPoint";
import {PlayIconSolid} from "../../../../components/assets/icon/PlayIcon";
import SortDescendingIcon from "../../../../components/assets/icon/SortDescendingIcon";
import CheckIcon from "../../../../components/assets/icon/CheckIcon";
import XMarkIcon from "../../../../components/assets/icon/XMarkIcon";
import React from "react";
import ViewGridIcon from "../../../../components/assets/icon/ViewGridIcon";
import CalendarIcon from "../../../../components/assets/icon/CalendarIcon";
import ArrowPathRoundIcon from "../../../../components/assets/icon/ArrowPathRoundIcon";
import {useSelector} from "react-redux";
import {selectIsDarkMode} from "../../../../../../../corelogic/usecases/config/configSelector";
import ChevronDown from "../../../../components/assets/icon/ChevronDown";
import ChevronUp from "../../../../components/assets/icon/ChevronUp";
import {
    MONTHS
} from "../../../../../../../corelogic/usecases/operation-parameters-form/seasonality/useSeasonalityRetrieve";
import useSeasonalityConfig
    , {
    SeasonalityConfig
} from "../../../../../../../corelogic/usecases/operation-parameters-form/seasonality/useSeasonalityConfig";
import {
    selectSeasonalityForm
} from "../../../../../../../corelogic/usecases/operation-parameters-form/operationParametersFormSelector";
import useSeasonalityToggle
    from "../../../../../../../corelogic/usecases/operation-parameters-form/seasonality/useSeasonalityToggle";
import {
    Seasonality
} from "../../../../../../../corelogic/models/types/new-simulation/form/operation-parameters/seasonality/Seasonality";

export default function SeasonalityTable() {
    const seasonality: Seasonality = useSelector(selectSeasonalityForm)

    const seasonalityConfig : SeasonalityConfig = useSeasonalityConfig()
    const {toggleCell} = useSeasonalityToggle()

    return <div className="w-min">
        <SeasonalityConfiguration seasonalityConfig={seasonalityConfig} />
        <div className={"w-min "}>
            <Months selectAll={seasonalityConfig.consumersSelected.selectAll}/>
            <div className={"w-full max-h-[300px] overflow-y-auto "}>
                {
                    seasonality.consumptionPoints.map((consumptionPoint, index) => {
                        const isSelected = seasonalityConfig.consumersSelected.value.includes(consumptionPoint)

                        return <div
                            className={` flex flex-row items-center border 
                            ${consumptionPoint.state ? '' : ' opacity-40 '}
                            ${isSelected ? ' border-gray-600 dark:border-zinc-300 ' : ' border-gray-200 dark:border-zinc-700 '}
                            `}>
                            <div
                                onClick={() => seasonalityConfig.consumersSelected.selectRow(consumptionPoint)}
                                className={"w-[200px] h-[42px]  flex items-center justify-between text-center text-sm text-gray-700 dark:text-gray-300 px-2 py-1 bg-slate-100  dark:bg-zinc-800 dark:bg-opacity-40 dark:hover:bg-zinc-800 rounded-sm cursor-cell hover:bg-slate-200"}>
                                <p className={"truncate text-sm max-w-[160px]"}
                                   title={consumptionPoint.name}>{consumptionPoint.name}</p>
                                <SortDescendingIcon className={"h-5 w-5 -rotate-90"}/>
                            </div>
                            {
                                seasonality.seasonActivated[index].map((isActivated, monthIndex) => {
                                    return <div
                                        onClick={() => toggleCell(index, monthIndex)}
                                        className={isActivated ?
                                            "w-[60px] h-[42px] flex items-center justify-center text-center px-2 py-1  border border-slate-200 rounded-sm text-slate-700 cursor-cell hover:bg-slate-300 dark:border-zinc-700  bg-gray-50 dark:bg-zinc-800 dark:bg-opacity-30 dark:text-zinc-200 dark:hover:bg-opacity-80"
                                            : "w-[60px] h-[42px] flex items-center justify-center  cursor-cell text-center  px-2 py-1 border-slate-100 rounded-sm  text-slate-700  hover:bg-slate-300 dark:border-zinc-800 bg-zinc-100 dark:bg-zinc-800 dark:bg-opacity-[15%] dark:text-zinc-200"}>
                                        {isActivated ?
                                            <CheckIcon/>
                                            : <XMarkIcon className={"h-5 w-5 text-slate-300 dark:text-zinc-600"}/>}
                                    </div>
                                })
                            }
                        </div>
                    })
                }
            </div>
        </div>
    </div>
}

function SeasonalityConfiguration({seasonalityConfig}:{seasonalityConfig:SeasonalityConfig})
{
    const [showConfig, setShowConfig] = React.useState(false)
    const seasonality: Seasonality = useSelector(selectSeasonalityForm)
    const {monthsSelected, consumersSelected, apply} = seasonalityConfig

    return <div
        className={"flex flex-col border border-slate-200 bg-slate-200  dark:bg-zinc-800 dark:bg-opacity-40 dark:border-zinc-700 rounded-md my-2 pt-2"}>
        <MonthRangeSelector monthsSelected={monthsSelected} showConfig={showConfig} setShowConfig={setShowConfig}/>
        {showConfig && <div className={"flex flex-col gap-1 pt-2 px-2 mb-2  rounded-t-md"}>
            <div className={"flex w-full  items-center justify-center gap-2"}>
                <MonthsConfiguration monthsSelected={monthsSelected.value}/>
                <ConsumerConfiguration consumersSelected={consumersSelected.value} seasonality={seasonality}/>
                <div
                    onClick={() => apply()}
                    className={" h-min cursor-pointer flex items-center bg-slate-600 dark:bg-zinc-300 border border-slate-400 dark:border-zinc-300 gap-2 px-3 py-1 rounded  hover:bg-slate-500 dark:hover:bg-zinc-400"}>
                    <span
                        className={"text-slate-50 dark:text-zinc-700 text-xs font-semibold"}>Appliquer la configuration</span>
                    <PlayIconSolid className={"text-slate-50 dark:text-zinc-700 h-6 w-6"}/>
                </div>
            </div>
        </div>}
    </div>
}

function ConsumerConfiguration({consumersSelected, seasonality}: {
    consumersSelected: ConsumptionPoint[],
    seasonality: Seasonality
}) {
    const noConsumerSelected = consumersSelected.length === 0
    const allConsumersSelected = consumersSelected.length === seasonality.consumptionPoints.length
    const selectedConsumers = consumersSelected.map((consumer) => consumer.name).join(", ")

    return <div
        title={noConsumerSelected ? 'Aucun consommateur sélectionné' : (allConsumersSelected ? 'Tout les consommateurs sélectionnés' : selectedConsumers)}
        className={" h-min flex items-center   gap-1 px-2 py-1 rounded  bg-slate-300 dark:bg-zinc-800"}>
        <ViewGridIcon
            className={`h-5 w-5 min-w-5 min-h-5 text-slate-500 dark:text-zinc-400`}/>
        <span
            className={"text-slate-500 dark:text-zinc-400 text-xs max-w-[300px] truncate"}> {noConsumerSelected ? 'Aucun consommateur sélectionné' : (allConsumersSelected ? 'Tout les consommateurs sélectionnés' : selectedConsumers)}</span>
    </div>

}

function MonthsConfiguration({monthsSelected}: { monthsSelected: boolean[] }) {
    const noMonthSelected = monthsSelected.every(month => !month)
    const selectedMonths = monthsSelected.map((month, index) => month ? MONTHS[index] : "").filter(month => month !== "").join(", ")

    return <div
        className={" h-min flex items-center  gap-1 px-2 py-1 rounded  bg-slate-300 dark:bg-zinc-800"}>
        <CalendarIcon
            className={`h-5 w-5 text-slate-500 dark:text-zinc-400`}/>
        <span
            className={"text-slate-500 dark:text-zinc-400 text-xs  truncate "}> {noMonthSelected ? 'Aucun mois sélectionnés' : selectedMonths}</span>
    </div>
}

function MonthRangeSelector({showConfig, monthsSelected, setShowConfig}: {
    monthsSelected: {
        value: boolean[],
        toggle: (index: number) => void,
        reset: () => void,
        isDisabled: () => boolean
    },
    showConfig: boolean,
    setShowConfig(b: boolean): void
}) {
    const isDarkMode = useSelector(selectIsDarkMode)
    return <>
        <div onClick={() => setShowConfig(!showConfig)}
             className={"flex justify-between w-full pb-2 px-2 cursor-pointer"}>
            <span className={"text-slate-600 dark:text-zinc-300  text-sm font-semibold"}>Configuration multiple</span>
            {showConfig ?
                <ChevronUp classname={"h-5 w-5 text-slate-600 dark:text-zinc-300 cursor-pointer"}/> :
                <ChevronDown classname={"h-5 w-5 text-slate-600 dark:text-zinc-300 cursor-pointer"}/>}

        </div>
        {showConfig && <div className={"flex flex-col w-full gap-2 items-center "}>
            <div className={"flex flex-col gap-1 items-center "}>

                <div className={"w-full flex justify-end"}>
                    <button
                        disabled={monthsSelected.isDisabled()}
                        className={monthsSelected.isDisabled() ?  " h-min cursor-default flex border border-gray-300 dark:border-zinc-600 gap-1 px-2 py-1 rounded items-center"
                            :" h-min cursor-pointer flex border border-gray-300 dark:border-zinc-600 gap-1 px-2 py-1 rounded items-center hover:bg-gray-100 dark:hover:bg-zinc-800"}
                        onClick={() => monthsSelected.reset()}>
                        <span className={"text-gray-500 dark:text-zinc-400 text-xs font-semibold"}>réinitialiser</span>
                        <ArrowPathRoundIcon
                            className={"h-5 w-5 text-gray-500 dark:text-zinc-400"}/>
                    </button>
                </div>
                <div className={"flex gap-1 "}>
                    {MONTHS.map((month, index) => (
                        <div
                            onClick={() => monthsSelected.toggle(index)}
                            style={{
                                backgroundColor: monthsSelected.value[index] ? (isDarkMode ? "#27272a" : "#e5e7eb") : (isDarkMode ? "#52525b" : "#f9fafb"),
                                borderColor: monthsSelected.value[index] ? (isDarkMode ? "#27272a" : "#374151") : (isDarkMode ? "#52525b" : "#e5e7eb"),
                            }}
                            className={`flex flex-col dark:bg-opacity-60 px-4 py-2 items-center rounded border hover:border-gray-600  cursor-pointer hover:dark:border-zinc-500`}>
                            <CalendarIcon
                                className={`h-6 w-6 ${monthsSelected.value[index] ? ' text-gray-700 dark:text-zinc-200 ' : ' text-gray-500 dark:text-zinc-400 '}`}/>
                            <span
                                className={`text-gray-700 dark:text-zinc-200 text-sm font-semibold ${monthsSelected.value[index] ? '' : '  line-through  '}`}>{month}</span>
                        </div>)
                    )}
                </div>
            </div>
        </div>}
    </>
}

function Months({selectAll}: { selectAll: () => void }) {

    return <div className="flex">

        <div
            onClick={() => selectAll()}
            className={'w-[200px] pt-2 pl-4 bg-slate-100  dark:bg-zinc-800 dark:bg-opacity-40 dark:border-zinc-700 hover:dark:bg-zinc-800 rounded-sm border border-slate-200 text-slate-700 cursor-cell hover:bg-slate-200'}>
            <ViewGridIcon
                className={" text-gray-700 dark:text-zinc-50  dark:hover:text-zinc-200  hover:text-gray-700 cursor-pointer "}/>
        </div>
        {
            MONTHS.map((month, index) => {
                return <div
                    className={"w-[60px] text-gray-600 dark:text-gray-300 text-sm text-center font-semibold px-2 py-1  rounded-sm border bg-slate-100 border-slate-200   dark:bg-zinc-800 dark:bg-opacity-40 dark:border-zinc-700 hover:bg-slate-200"}
                    key={index}>{month}</div>
            })
        }
    </div>
}
