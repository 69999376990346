
const selectedSwitchStyle = `dark:bg-blue-900 dark:text-blue-300 bg-blue-200 text-blue-600 border border-blue-500  font-bold   h-full   flex items-center justify-center  px-2 tracking-wide`
const nonSelectedSwitchStyle = `dark:border-zinc-600 dark:bg-zinc-800 dark:hover:bg-zinc-700 dark:text-zinc-300 dark:hover:text-zinc-200  bg-slate-50 border border-slate-300  font-regular whitespace-nowrap min-w-min  h-full flex items-center tracking-wide justify-center text-slate-400 px-2 hover:text-slate-600 hover:bg-slate-100 hover:cursor-pointer`

const disabledButtonStyle = ` pointer-events-none text-gray-300`
type Props = {
    options: string[],
    action: (option: string) => void,
    selected: string,
    disabled?: boolean,
}

export default ({options, action, selected, disabled}: Props) => {
    return (
        <div className="w-full h-full shadow rounded-lg  grid text-xs grid-cols-2">
            <div data-cy={'acc-btn'}
                onClick={() => action(options[0])}
                className={ (options[0] === selected ? selectedSwitchStyle: nonSelectedSwitchStyle) + ' rounded-l-lg'}>{options[0]}
            </div>
            {options[1] && <div data-cy={'acc-aci-btn'} onClick={() => action(options[1])}
                  className={(options[1] === selected ? selectedSwitchStyle : nonSelectedSwitchStyle) + ' rounded-r-lg' + (disabled ? disabledButtonStyle : '')}>{options[1]}
            </div>}
        </div>
    )
}
