import {Actions, ENQUEUE_SNACKBAR, RESET_SNACKBAR} from "./snackbarActions";

const initialState:any = null

const snackbarReducer = (state = initialState, action: Actions) => {
    switch (action.type) {
        case ENQUEUE_SNACKBAR:
            console.log(action.payload)
            return action.payload;
        case RESET_SNACKBAR:
            return null;
        default:
            return state;
    }
}

export default snackbarReducer;