import React from "react";

export default function({disabled, className} : { disabled : boolean, className ?:string }) {
    return (
        <svg className={className? className : disabled ? "h-20 w-20 text-white" : "h-20 w-20 text-slate-600" }
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 512.001 512.001"
            version="1.1"
            viewBox="0 0 512.001 512.001"
            xmlSpace="preserve"
        >
            <path fill="#CEE8FA" d="M474.48 84.43c-1.519 0-3.002.15-4.439.427.182-1.572.287-3.169.287-4.791 0-22.758-18.449-41.208-41.208-41.208-22.758 0-41.208 18.449-41.208 41.208 0 1.622.104 3.219.287 4.791a23.443 23.443 0 00-4.439-.427c-12.885 0-23.329 10.445-23.329 23.329s10.445 23.329 23.329 23.329h90.723c12.885 0 23.329-10.445 23.329-23.329S487.363 84.43 474.48 84.43z"/>
            <path fill="#2D527C" d="M474.48 145.28h-90.724c-20.689 0-37.52-16.832-37.52-37.52 0-17.456 11.984-32.167 28.157-36.338 4.164-26.456 27.118-46.755 54.727-46.755 27.609 0 50.564 20.299 54.728 46.755 16.172 4.171 28.155 18.882 28.155 36.338-.003 20.688-16.835 37.52-37.523 37.52zm-90.725-46.659c-5.038 0-9.137 4.099-9.137 9.137s4.1 9.137 9.137 9.137h90.724c5.038 0 9.137-4.099 9.137-9.137.001-5.617-5.174-10.063-10.892-8.966a14.193 14.193 0 01-16.78-15.576c.129-1.11.192-2.14.192-3.152 0-14.897-12.12-27.016-27.017-27.016-14.896 0-27.016 12.12-27.016 27.016 0 1.01.062 2.041.192 3.152a14.193 14.193 0 01-16.78 15.576 9.46 9.46 0 00-1.76-.171z"
            />
            <g fill="#CEE8FA">
                <path d="M235.032 124.841H320.175V473.13800000000003H235.032z"/>
                <path d="M152.943 259.026a50.427 50.427 0 003.88-19.447c0-27.932-22.643-50.576-50.576-50.576s-50.576 22.643-50.576 50.576a50.42 50.42 0 003.88 19.447c-25.481 2.61-45.36 24.138-45.36 50.309 0 27.932 22.643 50.576 50.576 50.576 17.175 0 32.34-8.569 41.481-21.655 9.141 13.088 24.306 21.655 41.481 21.655 27.932 0 50.576-22.643 50.576-50.576-.001-26.171-19.881-47.699-45.362-50.309z"/>
                <path d="M405.518 355.351H461.24899999999997V473.144H405.518z"/>
            </g>
            <path
                fill="#2D527C"
                d="M486.601 458.952h-10.313V355.351c0-7.838-6.355-14.192-14.192-14.192-.143 0-.282.017-.426.021-.143-.004-.282-.021-.426-.021h-55.726c-7.837 0-14.192 6.354-14.192 14.192v103.601H334.36v-227.07h113.543v56.777c0 7.838 6.355 14.192 14.192 14.192s14.192-6.354 14.192-14.192V217.69c0-7.838-6.355-14.192-14.192-14.192H334.36v-78.657c0-7.838-6.355-14.192-14.192-14.192h-85.143c-7.837 0-14.192 6.354-14.192 14.192v114.851H171.01c0-.038.004-.075.004-.114 0-35.712-29.055-64.767-64.767-64.767S41.48 203.865 41.48 239.578c0 3.046.209 6.06.624 9.036C17.256 257.814 0 281.627 0 309.335c0 35.712 29.055 64.767 64.767 64.767a64.68 64.68 0 0030.545-7.688v92.537H79.318c-7.837 0-14.192 6.354-14.192 14.192s6.355 14.192 14.192 14.192H486.602c7.837 0 14.192-6.354 14.192-14.192s-6.355-14.191-14.193-14.191zM117.882 330.126a14.19 14.19 0 00-23.268 0c-6.819 9.763-17.977 15.591-29.845 15.591-20.062 0-36.384-16.321-36.384-36.384 0-18.728 14.022-34.286 32.614-36.191a14.19 14.19 0 0011.653-19.58 36.14 36.14 0 01-2.787-13.983c0-20.062 16.322-36.384 36.384-36.384s36.384 16.321 36.384 36.384c0 4.845-.938 9.55-2.787 13.983a14.19 14.19 0 0011.653 19.58c18.594 1.905 32.614 17.465 32.614 36.191 0 20.062-16.322 36.384-36.384 36.384-11.87.002-23.028-5.827-29.847-15.591zm102.952 128.826h-97.138v-89.5a64.618 64.618 0 0024.033 4.651c35.712 0 64.767-29.055 64.767-64.767 0-15.637-5.498-30.03-14.751-41.259h23.09v190.875h-.001zm28.384-205.067V139.033h56.759V458.95h-56.759V253.885zM419.71 458.952v-89.409h27.343v89.409H419.71z"
            />
        </svg>
    );
}

