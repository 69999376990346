import React from "react";

type SelectorInputType = {
    label?: string,
    options: { id : string, name : string }[],
    id:string,
    onChange:(e: React.ChangeEvent<HTMLSelectElement>)=> void,
    valueId : string
}
export default function SelectorInputForOpex({label, options, id, onChange, valueId}: SelectorInputType) {

    return <label className="w-full block uppercase tracking-wide text-gray-600  ">
        {label && <span className=" text-gray-700 font-bold text-xs dark:text-gray-200">{label}</span>}
        <select id={id} data-cy={id} onChange={onChange}
                value={valueId}
                className={label ?
                    "form-select py-2 px-4 text-sm font-semibold  block w-full text-base bg-slate-200 focus:outline-none focus:ring focus:ring-gray-300 dark:text-gray-200 dark:bg-zinc-600  text-gray-700  leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    :"form-select py-2 px-4 text-sm font-semibold  block w-full text-base bg-slate-100 focus:outline-none focus:ring focus:ring-gray-300 dark:text-gray-200 dark:bg-zinc-700  text-gray-700  leading-tight focus:outline-none focus:bg-white focus:border-gray-500"}>
            {options.map((option :{ id : string, name : string }) => {
                return <option key={option.id} value={option.id}>{option.name}</option>
            })}
        </select>
    </label>
}
