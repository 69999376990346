import React from "react";
import {LabelInput} from "../../../../../components/assets/FormAssets/TextInput";
import FixedVATRateInput from "./FixedVATRateInput";


export default function FixedVATRate() {


    return <div className={"flex flex-col justify-between "}>
        <LabelInput label={'part fixe'} mandatory={true}/>
        <div className="flex max-h-min">
            <FixedVATRateInput/>
        </div>
    </div>
}


