import {createSelector} from "reselect";
import {selectNewSimulation} from "../form/newSimulationSelector";

const selectFormHasNewValidChanges = createSelector(
    selectNewSimulation,
    newSimulation => newSimulation.formHasNewValidChanges
)
const selectFormIsValid = createSelector(
    selectNewSimulation,
    newSimulation => newSimulation.formIsValid
)
const selectIsFormUpdating = createSelector(
    selectNewSimulation,
    newSimulation => newSimulation.formIsUpdating
);

const selectFormHasBeenUpdatedNotification = createSelector(
    selectNewSimulation,
    newSimulation => newSimulation.formHasBeenUpdated
)

export {
    selectFormHasNewValidChanges,
    selectFormIsValid,
    selectFormHasBeenUpdatedNotification,
    selectIsFormUpdating,
}