import {RootState} from "../../../store/rootReducer";
import {createSelector} from "reselect";
import { selectSimulationExecutionStatus } from "../simulationExecutionStatus/simulationExecutionStatusSelector";
import { ExecutionStatus } from "../simulationExecutionStatus/simulationExecutionStatusActions";

const selectPhysicalResult = (state: RootState): any => state.physicalData;

const selectPhysicalData = createSelector(
    selectPhysicalResult,
    physicalData => physicalData.physicalResult,
)
const selectPhysicalLoadings = createSelector(
    selectPhysicalResult,
    result => result.loadings,
)
export const selectSelectedType = createSelector(
    selectPhysicalResult,
    physicalData => physicalData.selectedType,
)

export const selectCDCData = createSelector(
    selectSelectedType,
    selectPhysicalData,
    ({id, aci}, physicalData) => physicalData[id]?.CDC?.[aci ? 'aci' : 'acc'] || {},
)

export const selectCDCGroupedByMonthData = createSelector(
    selectSelectedType,
    selectPhysicalData,
    ({id, aci}, physicalData) => physicalData[id]?.CDCGroupedByMonth?.[aci ? 'aci' : 'acc'] || {},
)


export const selectMetricsData = createSelector(
    selectSelectedType,
    selectPhysicalData,
    ({id, aci}, physicalData) => physicalData[id]?.metrics?.[aci ? 'aci' : 'acc'] || {}
)

export const selectCDCLoading = createSelector(
    selectPhysicalLoadings,
    selectSimulationExecutionStatus,
    (loadings, simulationExecutionStatus) => loadings.CDC || simulationExecutionStatus === ExecutionStatus.running
)


export const selectConsumptionDistributionData = createSelector(
    selectMetricsData,
    selectSelectedType,
    (metrics, {aci}) => {
        return [{
            name: 'Autoconsommation du collectif',
            value: (metrics.autoConsumption || 0) / 1000
        },
            ...(aci ? [{
                name: 'Autoconsommation individuelle',
                value: (metrics.individualAutoConsumption || 0) / 1000
            }] : []),
            {
                name: 'Complément du réseau',
                value: (metrics.supplierConsumption || 0) / 1000
            }]
    }
)

export const selectProductionDistributionData = createSelector(
    selectMetricsData,
    selectSelectedType,
    (metrics, {aci}) => {
        return [{
            name: 'Autoconsommation du collectif',
            value: (metrics.autoConsumption || 0) / 1000
        },
            ...(aci ? [{
                name: 'Autoconsommation individuelle',
                value: (metrics.individualAutoConsumption || 0) / 1000
            }] : []),
            {
                name: 'Surplus',
                value: (metrics.excess || 0) / 1000
            }]
    }
)

export const selectMetricsLoading = createSelector(
    selectPhysicalLoadings,
    selectSimulationExecutionStatus,
    (loadings, simulationExecutionStatus) => loadings.metrics || simulationExecutionStatus === ExecutionStatus.running
)

const selectConsumptionCdc = createSelector(
    selectCDCData,
    simulationData => {
        return simulationData?.consumption || []
    },
)

export const selectConsumptionCDCValues = createSelector(
    selectConsumptionCdc,
    cdcData => cdcData.map(({value}: any) => value / 1000)
)

export const selectProductionCDCValues = createSelector(
    selectCDCData,
    simulationData => simulationData?.production?.map(({value}: any) => value / 1000) || []
)


export const selectAutoconsumptionCDCValues = createSelector(
    selectCDCData,
    simulationData => simulationData?.autoConsumption?.map(({value}: any) => value / 1000) || []
)


export const selectSurplusCDCValues = createSelector(
    selectCDCData,
    simulationData => simulationData?.excess?.map(({value}: any) => -value / 1000) || []
)

export const selectAutoproductionRate = createSelector(
    selectMetricsData,
    metricsData => metricsData?.autoProductionRate || 0
)
export const selectAutoconsumptionRate = createSelector(
    selectMetricsData,
    metricsData => metricsData?.autoConsumptionRate || 0
)
export const selectTimestamps = createSelector(
    selectCDCData,
    simulationData => simulationData?.autoConsumption?.map(({timestamp}: any) => timestamp) || []
)

export const selectMonthTimestamps = createSelector(
    selectCDCGroupedByMonthData,
    simulationData => simulationData?.collectiveAutoConsumption?.map(({timestamp}: any) => {
        const date = new Date(timestamp);
        return date.toLocaleString('fr', {month: 'long'});
    })
)

export const selectNetworkConsumptionGroupedByMonth = createSelector(
    selectCDCGroupedByMonthData,
    simulationData => simulationData?.networkConsumption?.map(({value}: any) => value / 1000) || []
)

export const selectIndividualAutoconsumptionGroupedByMonth = createSelector(
    selectCDCGroupedByMonthData,
    simulationData => simulationData?.individualAutoConsumption?.map(({value}: any) => value / 1000) || []
)

const selectTotalConsumptionGroupedByMonth = createSelector(
    selectCDCGroupedByMonthData,
    simulationData => simulationData?.consumption?.map(({value}: any) => value / 1000) || []
)

export const makeSelectTotalConsumptionGroupedByMonth = (aci: boolean) => createSelector(
    selectNetworkConsumptionGroupedByMonth,
    selectTotalConsumptionGroupedByMonth,
    (networkConsumptionValues, totalConsumptionValues) => aci ? totalConsumptionValues : networkConsumptionValues
)

export const selectCollectiveAutoconsumptionGroupedByMonth = createSelector(
    selectCDCGroupedByMonthData,
    simulationData => simulationData?.collectiveAutoConsumption?.map(({value}: any) => value / 1000) || []
)


export const selectSurplusGroupedByMonth = createSelector(
    selectCDCGroupedByMonthData,
    simulationData => simulationData?.excess?.map(({value}: any) => value / 1000) || []
)

export const selectCDCGroupedByMonthLoading = createSelector(
    selectPhysicalLoadings,
    selectSimulationExecutionStatus,
    (loadings, simulationExecutionStatus) => loadings.CDCGroupedByMonth || simulationExecutionStatus === ExecutionStatus.running
)

export const selectIsCDCDataLoaded = createSelector(
    selectPhysicalData,
    selectSelectedType,
    (physicalData, {id, aci}) => physicalData[id]?.CDC?.[aci ? 'aci': 'acc'] ? true : false
)

export const selectIsCDCGroupedByMonthDataLoaded = createSelector(
    selectPhysicalData,
    selectSelectedType,
    (physicalData, {id, aci}) => physicalData[id]?.CDCGroupedByMonth?.[aci ? 'aci': 'acc'] ? true : false
)

export const selectIsMetricsDataLoaded = createSelector(
    selectPhysicalData,
    selectSelectedType,
    (physicalData, {id, aci}) => physicalData[id]?.metrics?.[aci ? 'aci': 'acc'] ? true : false
)