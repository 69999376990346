import {NewReleaseWrapper} from "../../wrapper/NewReleaseWrapper";
import NewsPaperIconOutline from "../../../components/assets/icon/NewsPaperIcon";
import React from "react";

const NewRealeaseIcon = ({
                             newReleaseModal,
                             setNewReleaseModal
                         }: { newReleaseModal: boolean, setNewReleaseModal(set: boolean): void }) => {
    return <NewReleaseWrapper relativePosition={" left-9  "}>
        <div
            title={"Nouvelles fonctionnalités"}
            onClick={() => setNewReleaseModal(!newReleaseModal)}
            className={"p-1 mx-4 hover:bg-slate-200 dark:hover:bg-slate-700 rounded-full  hover:cursor-pointer"}>
            <NewsPaperIconOutline
                className={" w-5 h-5 text-slate-400 dark:text-slate-500 hover:text-slate-700 dark:hover:text-slate-300"}/>
        </div>
    </NewReleaseWrapper>
}
export default NewRealeaseIcon