import {all, fork} from 'redux-saga/effects';
import {Saga} from "redux-saga";
import simulationsDisplaySaga from "../corelogic/usecases/simulations-display/simulationsDisplaySaga";
import authenticationSagas from "../corelogic/usecases/authentication/authenticationSaga";
import errorsSagas from "../corelogic/usecases/errors/errorsSaga";
import newSimulationSaga from "../corelogic/usecases/new-simulation/new-simulation-saga/newSimulationSaga";
import physicalRestitutionSagas from "../corelogic/usecases/physicalRestitution/physicalRestitutionSaga";
import financialRestitutionSagas from "../corelogic/usecases/financialRestitution/financialRestitutionSaga";
import restitutionSagas from "../corelogic/usecases/restitution/restitutionSaga";
import formSaga from "../corelogic/usecases/form/formSaga";
import typologyFormSagas from "../corelogic/usecases/typology-form/typologyFormSaga";
import faqSaga from "../corelogic/usecases/faq/faqSaga";
import operationParametersFormSaga from "../corelogic/usecases/operation-parameters-form/operationParametersFormSaga";
import { simulationExecutionStatusSagas } from '../corelogic/usecases/simulationExecutionStatus/simulationExecutionStatusSaga';

const sagas: Saga[] = [
    ...simulationsDisplaySaga,
    ...authenticationSagas,
    ...errorsSagas,
    ...newSimulationSaga,
    ...simulationExecutionStatusSagas,
    ...physicalRestitutionSagas,
    ...financialRestitutionSagas,
    ...restitutionSagas,
    ...formSaga,
    ...typologyFormSagas,
    ...faqSaga,
    ...operationParametersFormSaga,
];

function* rootSaga() {
    yield all(sagas.map(fork));
}

export default rootSaga
