import ReactECharts from 'echarts-for-react';
import {Curve} from "../../../../../../corelogic/models/types/new-simulation/curve/Curve";
import {loadingOption} from "../../../composition/chart/config";
import {useSelector} from "react-redux";
import {
    selectIsLoadingNewCurve,
    selectIsLoadingSimulationForm
} from "../../../../../../corelogic/usecases/loader/loaderSelector";
import {useEffect, useState} from "react";

type CDCPrevisuType = {
    data: Curve | null,
    color: string
}

function padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
}
function displayPowerAsRow(data:Curve ){

    const powerInKW = (powerInWatt:number | null) => {
        if(powerInWatt === null) return null
        if(powerInWatt === 0) return 0
        else return (powerInWatt / 1000).toFixed(2)
    }
    return data.power?.map((powerinWatt: number | null) => powerInKW(powerinWatt))
}
export default function ({data, color}: CDCPrevisuType) {
    const [options, setOptions] = useState({})
    const isLoading = useSelector(selectIsLoadingNewCurve)
    const isFormLoading = useSelector(selectIsLoadingSimulationForm)

    useEffect(() => {
        setOptions({
            tooltip: {
                trigger: 'axis',
                position: function (pt: any) {
                    return [pt[0], '10%'];
                },
                axisPointer: {
                    type: 'shadow',
                    label: {
                        formatter: ({value}: any) => {
                            const date = new Date(value);
                            return `${padTo2Digits(date.getUTCDate())}/${padTo2Digits(date.getUTCMonth() + 1)} ${padTo2Digits(date.getUTCHours())}:${padTo2Digits(date.getMinutes())}`
                        }
                    }
                },
            },
            toolbox: {
                show: true,
                feature: {
                    dataView: {readOnly: true},
                    saveAsImage: {}
                }
            },
            xAxis: {
                type: 'category',
                data: data ? data.timestamp : [],
                axisLabel: {
                    formatter: (time: any) => {
                        const date = new Date(time);
                        return `${padTo2Digits(date.getUTCDate())}/${padTo2Digits(date.getUTCMonth() + 1)}`
                    }
                }
            },
            dataZoom: [
                {
                    type: 'inside',
                    start: 0,
                    end: 100
                },
                {
                    start: 0,
                    end: 100
                }
            ],
            yAxis: {
                type: 'value',
                axisLabel: {
                    formatter: '{value} kW'
                }
            },
            series: [
                {
                    name : 'Puissance (kW)',
                    data: data ? displayPowerAsRow(data) : [],
                    type: 'line',
                    showSymbol: false,
                    areaStyle: {color: color},
                    lineStyle: {width: 0},
                    smooth: true,
                    markArea: {
                        itemStyle: {color: 'rgba(231,21,21,0.7)'},
                        data: data && data?.holes.length !== 0 ? data.toEchartsHole() : []
                    }

                }
            ]
        })
    }, [data, color])

    return data ?
        <ReactECharts showLoading={isLoading||isFormLoading} loadingOption={loadingOption} className={"my-4"} option={options}/>
        : <></>

}



