import {createAction} from "../../../store/createAction";
import {ActionsUnion} from "../../../store/actionsUnions";
import {HeatingType} from "../../models/types/new-simulation/form/HeatingType";
import {AccommodationType} from "../../models/types/new-simulation/form/AccommodationType";
import {InjectionPoint} from "../../models/types/new-simulation/form/InjectionPoint";
import {ConsumptionPoint} from "../../models/types/new-simulation/form/ConsumptionPoint";
import {ConsumerType} from "../../models/types/new-simulation/form/ConsumerType";
import { SGEFetchedCDCStatus} from "./typologyFormReducer";
import LowCompletudeInput
    from "../../../adapters/primary/ui/composition/form/load-curve-upload/completude-and-recalage-button/low-completeness/form/table/LowCompletudeInput";

export const SET_CDC_UPLOAD_OPTION = 'SET_CDC_UPLOAD_OPTION'
export const SET_HEATING_TYPE = 'SET_HEATING_TYPE'
export const SET_ACCOMMODATION_TYPE = 'SET_ACCOMMODATION_TYPE'
export const SET_SEGMENT = 'SET_SEGMENT'
export const SET_EDIT_POINT = 'SET_EDIT_POINT'
export const SET_EDIT_POINT_INDEX = 'SET_EDIT_POINT_INDEX'
export const SET_RECALAGE_BUTTON_ACTION = 'SET_RECALAGE_BUTTON_ACTION'
export const SET_RECALAGE_INPUT = 'SET_RECALAGE_INPUT'
export const RESET_CDC_UPLOAD = 'RESET_CDC_UPLOAD'
export const SET_FULL_COMPLETUDE_ACTION = 'SET_FULL_COMPLETUDE_ACTION'
export const SET_NAME = 'SET_NAME';
export const SET_GENERATED_KWH_PRICE = 'SET_GENERATED_KWH_PRICE';
export const SET_ACC_NUMBER = 'SET_ACC_NUMBER';
export const SET_CONSUMER_TYPE = 'SET_CONSUMER_TYPE';
export const SET_IS_VAT_RECOVERABLE = 'SET_IS_VAT_RECOVERABLE';
export const SET_ACI_POINT_ID = 'SET_ACI_POINT_ID';
export const ENABLE_ACI = 'ENABLE_ACI';

export const ENABLE_HPHC = 'ENABLE_HPHC';
export const SET_HC_PRIX = 'SET_HC_PRIX';
export const SET_HP_PRIX = 'SET_HP_PRIX';

export const SET_HC_HIVER_PRIX = 'SET_HC_HIVER_PRIX';
export const SET_HC_ETE_PRIX = 'SET_HC_ETE_PRIX';
export const SET_HP_ETE_PRIX = 'SET_HP_ETE_PRIX';
export const SET_HP_HIVER_PRIX = 'SET_HP_HIVER_PRIX';

export const SET_HEURE_CREUSE= 'SET_HEURE_CREUSE';
export const SET_HEURE_PLEINE = 'SET_HEURE_PLEINE';
export const SET_CLEAR_FORM = 'SET_CLEAR_FORM';
export const SET_ADDRESS  = 'SET_ADDRESS';
export const RESET_EDIT_POINT = 'RESET_EDIT_POINT';
export const FETCH_SGE_CURVE = 'FETCH_SGE_CURVE';
export const SET_SGE_PRM = 'SET_SGE_PRM';
export const SET_SGE_START_DATE_DAYS = 'SET_SGE_START_DATE_DAYS';
export const SET_SGE_START_DATE_YEARS = 'SET_SGE_START_DATE_YEARS';
export const SET_SGE_START_DATE_MONTHS = 'SET_SGE_START_DATE_MONTHS';

export const SET_SGE_END_DATE_DAYS = 'SET_SGE_END_DATE_DAYS';
export const SET_SGE_END_DATE_YEARS = 'SET_SGE_END_DATE_YEARS';
export const SET_SGE_END_DATE_MONTHS = 'SET_SGE_END_DATE_MONTHS';
export const SET_CDC_SAVED_CONFIG = 'SET_CDC_SAVED_CONFIG';
export const SAVE_SGE_CONFIG = 'SAVE_SGE_CONFIG';

export const CLICK_ON_SGE_FORM_TAB = 'CLICK_ON_SGE_FORM_TAB';
export const CLICK_ON_SGE_RESULT_TAB = 'CLICK_ON_SGE_RESULT_TAB';
export const SET_SGE_FORM_TAB= 'SET_SGE_FORM_TAB';
export const SET_CARTO_CIRCLE_IN_KM = 'SET_CARTO_CIRCLE_IN_KM';
export const SET_SGE_RETRIES = 'SET_SGE_RETRIES';
export const SET_SGE_DELAY = 'SET_SGE_DELAY';
export const SET_SGE_FETCH_IF_HOLES = 'SET_SGE_FETCH_IF_HOLES'

export const SET_SGE_WEEKLY_RESULT = 'SET_SGE_WEEKLY_RESULT';
export const SET_SGE_NUMBER_OF_SUCCESFULL_WEEKS= 'SET_SGE_NUMBER_OF_SUCCESFULL_WEEKS';
export const SET_SGE_SUCCESS_RATE = 'SET_SGE_SUCCESS_RATE';
export const INIT_SGE_FORM = 'INIT_SGE_FORM'
export const SHOW_LOW_COMPLETENESS_FORM = 'SHOW_LOW_COMPLETENESS_FORM'

export const SET_LOW_START_DATE_DAYS = 'SET_LOW_START_DATE_DAYS';
export const SET_LOW_START_DATE_MONTHS = 'SET_LOW_START_DATE_MONTHS';
export const SET_LOW_START_DATE_YEARS = 'SET_LOW_START_DATE_YEARS';
export const SET_LOW_END_DATE_DAYS = 'SET_LOW_END_DATE_DAYS';
export const SET_LOW_END_DATE_MONTHS = 'SET_LOW_END_DATE_MONTHS';
export const SET_LOW_END_DATE_YEARS = 'SET_LOW_END_DATE_YEARS';
export const SET_LOW_VOLUME = 'SET_LOW_VOLUME';
export const RESET_LOW_COMPLETUDE_FORM = 'RESET_LOW_COMPLETUDE_FORM';
export const ADD_LOW_COMPLETUDE_ROW = 'ADD_LOW_COMPLETUDE_ROW';
export const SET_LOW_COMPLETUDE_TABLE = 'SET_LOW_COMPLETUDE_TABLE';
export const REMOVE_LOW_COMPLETUDE_ROW = 'REMOVE_LOW_COMPLETUDE_ROW';
export const INIT_LOW_COMPLETUDE_TABLE= 'INIT_LOW_COMPLETUDE_TABLE'
export const SORT_LOW_COMPLETUDE_TABLE = 'SORT_LOW_COMPLETUDE_TABLE'
export const FIND_BEST_CIRCLE_PLACE = 'FIND_BEST_CIRCLE_PLACE';
export const SET_BEST_CIRCLE_PLACE = 'SET_BEST_CIRCLE_PLACE';
export const SHOW_DENSITY = 'SHOW_DENSITY';
export const GENERATE_12_MONTHLY_ROWS = 'GENERATE_12_MONTHLY_ROWS';
export const typologyFormActions = {
    // Cartographie
    setCartoCircleInKm:(km:number) => createAction(SET_CARTO_CIRCLE_IN_KM, km),
    setShowDensity:(show : boolean) => createAction(SHOW_DENSITY, show),
    findBestCirclePlace:() => createAction(FIND_BEST_CIRCLE_PLACE),
    setBestCirclePlace:(coordinates : {lat:number, lng:number}) => createAction(SET_BEST_CIRCLE_PLACE, coordinates),
    // upload CDC
    setCDCUploadOption: (uploadOptions: 'ENEDIS' | 'PVGIS' | 'UPLOAD' | 'SGE' | null) => createAction(SET_CDC_UPLOAD_OPTION, uploadOptions),
    resetCDCUpload:() => createAction(RESET_CDC_UPLOAD),
    // upload CDC via Enedis
    setHeatingType: (heatType:HeatingType) => createAction(SET_HEATING_TYPE, heatType),
    setAccommodationType:(type : AccommodationType) => createAction(SET_ACCOMMODATION_TYPE, type),

    // CDC Low Completeness
    generate12MonthsStartingOnDay:(day:string) => createAction(GENERATE_12_MONTHLY_ROWS, day),
    sortLowCompletudeTable:() => createAction(SORT_LOW_COMPLETUDE_TABLE),
    initLowCompletudeTable:()=>createAction(INIT_LOW_COMPLETUDE_TABLE),
    setLowCompletudeTable:(table : LowCompletudeInput[])=> createAction(SET_LOW_COMPLETUDE_TABLE, table),
    addLowCompletudeRow:() => createAction(ADD_LOW_COMPLETUDE_ROW),
    removeLowCompletudeRowFromIndex:(index:number) => createAction(REMOVE_LOW_COMPLETUDE_ROW, index),
    resetLowCompletudeForm:()=> createAction(RESET_LOW_COMPLETUDE_FORM),
    showLowCompletenessForm:(show : boolean) => createAction(SHOW_LOW_COMPLETENESS_FORM, show),

    setLOWStartDateDays:(days:string, index:number) => createAction(SET_LOW_START_DATE_DAYS, {days, index}),
    setLOWStartDateMonths:(months:string, index:number) => createAction(SET_LOW_START_DATE_MONTHS, {months, index}),
    setLOWStartDateYears:(years:string, index:number) => createAction(SET_LOW_START_DATE_YEARS, {years, index}),

    setLOWEndDateDays:(days:string, index:number) => createAction(SET_LOW_END_DATE_DAYS,{ days, index}),
    setLOWEndDateMonths:(months:string, index:number) => createAction(SET_LOW_END_DATE_MONTHS,{ months, index}),
    setLOWEndDateYears:(years:string, index:number) => createAction(SET_LOW_END_DATE_YEARS, {years, index}),

    setLOWVolume:(volume:string, index:number) => createAction(SET_LOW_VOLUME, {volume, index}),
    // CDC Recalage
    setRecalageButtonAction:(clicked : boolean) => createAction(SET_RECALAGE_BUTTON_ACTION, clicked),
    setRecalageInput:(energyInput:string | null)=> createAction(SET_RECALAGE_INPUT, energyInput),
    // CDC Full Completude
    setFullCompletudeButton:(clicked : boolean) => createAction(SET_FULL_COMPLETUDE_ACTION, clicked),
    // CDC SGE
    fetchSGECurve:() =>  createAction(FETCH_SGE_CURVE),
    initSGEForm:() => createAction(INIT_SGE_FORM),
    setSGEPRM:(prm:string) => createAction(SET_SGE_PRM, prm),
    setSGEFetchIfHoles:(fetch:boolean) =>createAction(SET_SGE_FETCH_IF_HOLES, fetch),
    setSGERetries:(retries:string) => createAction(SET_SGE_RETRIES, retries),
    setSGEDelay:(delay:string) => createAction(SET_SGE_DELAY, delay),
    setSGEStartDateDays:(days:string) => createAction(SET_SGE_START_DATE_DAYS, days),
    setSGEStartDateMonths:(months:string) => createAction(SET_SGE_START_DATE_MONTHS, months),
    setSGEStartDateYears:(years:string) => createAction(SET_SGE_START_DATE_YEARS, years),

    setSGEEndDateDays:(days:string) => createAction(SET_SGE_END_DATE_DAYS, days),
    setSGEEndDateMonths:(months:string) => createAction(SET_SGE_END_DATE_MONTHS, months),
    setSGEEndDateYears:(years:string) => createAction(SET_SGE_END_DATE_YEARS, years),

    saveSGECDCConfig:()=>  createAction(SAVE_SGE_CONFIG),
    setSGECDCConfig:(config : {prm:string, dateStart:string, dateEnd :string, retries : string, delay : string, fetchIfHoles : boolean})=>  createAction(SET_CDC_SAVED_CONFIG, config),

    setSGEWeeklyFailuresResults:(weeksFetched : SGEFetchedCDCStatus[]) => createAction(SET_SGE_WEEKLY_RESULT, weeksFetched),
    setSGENumberOfSuccessfullWeeks:(number:number)=> createAction(SET_SGE_NUMBER_OF_SUCCESFULL_WEEKS, number),
    setSGESuccessRate:(rate:number)=> createAction(SET_SGE_SUCCESS_RATE, rate),

    clickOnSGEFormTab:()=>createAction(CLICK_ON_SGE_FORM_TAB),
    clickOnSGEResultTab:()=>createAction(CLICK_ON_SGE_RESULT_TAB),
    setSGEFormTab:(open:boolean)=>createAction(SET_SGE_FORM_TAB, open),

    // consumption Form
    setSegment:(segment : string) => createAction(SET_SEGMENT, segment),
    setName:(name : string) => createAction(SET_NAME, name),
    setConsumerType:(consumerType:ConsumerType) => createAction(SET_CONSUMER_TYPE, consumerType),
    setIsVATRecoverable:(isVATRecoverable:boolean) => createAction(SET_IS_VAT_RECOVERABLE, isVATRecoverable),

    // consumption Form - ACI enable ACCNumber or ACIpointId
    enableACI:(enable:boolean)=> createAction(ENABLE_ACI, enable),
    setAccNumber:(accNumber:string) =>  createAction(SET_ACC_NUMBER, accNumber),
    setAciPointId:(aciPointId:string |null) => createAction(SET_ACI_POINT_ID, aciPointId),

    // consumption Form - HPHC or generatedKwhPrice
    enableHPHC:(enable:boolean)=> createAction(ENABLE_HPHC, enable),
    setGeneratedKwhPrice:(kwHPrice:string) => createAction(SET_GENERATED_KWH_PRICE, kwHPrice),
    setHeureCreusePrix:(HCPrix:string) => createAction(SET_HC_PRIX, HCPrix),
    setHeurePleinePrix:(HPPrix:string) => createAction(SET_HP_PRIX, HPPrix),

    setHeureCreuseHiverPrix:(HcHiver:string) => createAction(SET_HC_HIVER_PRIX, HcHiver),
    setHeureCreuseEtePrix:(HcEte:string) => createAction(SET_HC_ETE_PRIX, HcEte),
    setHeurePleineHiverPrix:(HpHiver:string) => createAction(SET_HP_HIVER_PRIX, HpHiver),
    setHeurePleineEtePrix:(HpEte:string) => createAction(SET_HP_ETE_PRIX, HpEte),

    setHeurePleine:(heure : string)=> createAction(SET_HEURE_PLEINE, heure),
    setHeureCreuse:(heure : string)=> createAction(SET_HEURE_CREUSE, heure),

    // injection Form

    // consumption & injection Form
    setAddress:(address:string)=> createAction(SET_ADDRESS, address),
    setClear:(clear:boolean)=> createAction(SET_CLEAR_FORM, clear),
    resetEditPoint:() => createAction(RESET_EDIT_POINT),
    setEditPoint:(edit : InjectionPoint | ConsumptionPoint | null) => createAction(SET_EDIT_POINT, edit),
    setEditPointIndex:(index : number | null) => createAction(SET_EDIT_POINT_INDEX, index),




};


export type formActionsType = ActionsUnion<typeof typologyFormActions>;
