import {RootState} from "../../../store/rootReducer";
import {createSelector} from "reselect";

const selectRouter = (state: RootState) => state.router;

export const selectLocation = createSelector(
    selectRouter,
    router => router.location
);
export const selectPathName = createSelector(
    selectLocation,
    location => location.pathname
);

export const selectRouterQuery = createSelector(
    selectLocation,
    location => location.query
);
