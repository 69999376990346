import {all, call, cancel, fork, getContext, put, select, take, takeLatest} from "redux-saga/effects";
import {
    CANCEL_GET_CDC_DATA,
    CANCEL_GET_CDC_GROUPED_BY_MONTH_DATA,
    CANCEL_GET_METRICS,
    GET_CDC_DATA,
    GET_CDC_GROUPED_BY_MONTH_DATA,
    GET_METRICS,
    physicalRestitutionActions
} from "./physicalRestitutionActions";
import {PhysicalRestitutionGatewayInterface} from "./physicalRestitutionGateway.interface";
import {CDCNature, metricsNature} from "../../models/types/physicalRestitution/NatureEnum";
import CDCData from "../../models/types/physicalRestitution/CDCData";
import MetricsData from "../../models/types/physicalRestitution/MetricsData";
import {selectSelectedSimulationId} from "../new-simulation/new-simulation-selector/form/newSimulationSelector";
import {snackBarErrorsActions} from "../errors/snackbarErrorsActions";
import {
    selectIsCDCDataLoaded,
    selectIsCDCGroupedByMonthDataLoaded,
    selectIsMetricsDataLoaded,
    selectSelectedType
} from "./physicalRestitutionSelector";

function* getCDCData(action: any): any {
    const {id: pointId, scope, aci} = yield select(selectSelectedType)
    const point_id = pointId ? pointId : 'global'

    try {
        yield put(
            physicalRestitutionActions.setCDCLoading({pointId: point_id, loading: true})
        )
        const simulationId: string = yield select(selectSelectedSimulationId)
        const physicalRestitutionGateway: PhysicalRestitutionGatewayInterface = yield getContext('physicalRestitutionGatewayContext');
        let autoConsumptionNature;
        let consumptionNature;
        let productionNature;
        let excessNature;
        const calls = [];
        let CDCData: CDCData;
        if (!scope) {
            autoConsumptionNature = aci ? CDCNature.totalConsoAc : CDCNature.totalConsoAcc
            consumptionNature = aci ? CDCNature.totalConso : CDCNature.totalConsoNetwork
            excessNature = CDCNature.totalExcess;
            calls.push(call(physicalRestitutionGateway.getCDC, simulationId, autoConsumptionNature, pointId));
            calls.push(call(physicalRestitutionGateway.getCDC, simulationId, consumptionNature, pointId));
            calls.push(call(physicalRestitutionGateway.getCDC, simulationId, excessNature, pointId));
            const [autoConsumption, consumption, excess] = yield all(calls);
            CDCData = {
                autoConsumption,
                consumption,
                excess,
            }

        } else if (scope === 'producer') {
            autoConsumptionNature = aci ? CDCNature.prodAc : CDCNature.prodAcc;
            productionNature = aci ? CDCNature.prod : CDCNature.prodNetwork;
            excessNature = CDCNature.excess;

            calls.push(call(physicalRestitutionGateway.getCDC, simulationId, autoConsumptionNature, pointId));
            calls.push(call(physicalRestitutionGateway.getCDC, simulationId, productionNature, pointId));
            calls.push(call(physicalRestitutionGateway.getCDC, simulationId, excessNature, pointId));
            const [autoConsumption, production, excess] = yield all(calls);
            CDCData = {
                autoConsumption,
                production,
                excess,
            }
        } else {
            autoConsumptionNature = aci ? CDCNature.consoAc : CDCNature.consoAcc
            consumptionNature = aci ? CDCNature.conso : CDCNature.consoNetwork
            calls.push(call(physicalRestitutionGateway.getCDC, simulationId, autoConsumptionNature, pointId));
            calls.push(call(physicalRestitutionGateway.getCDC, simulationId, consumptionNature, pointId));
            const [autoConsumption, consumption] = yield all(calls);
            CDCData = {
                autoConsumption,
                consumption,
            }
        }
        yield put(
            physicalRestitutionActions.setCDCData({pointId: point_id, data: CDCData, isAci: aci}),
        )

    } catch (error: any) {
        yield put(
            physicalRestitutionActions.setCDCData({pointId: point_id, data: {}, isAci: aci}),
        )
        yield put(snackBarErrorsActions.handleError(error));
    }
    yield put(
        physicalRestitutionActions.setCDCLoading({pointId: point_id, loading: false})
    )
}

function* getCDCGroupedByMonthData(action: any): any {
    const {aci} = yield select(selectSelectedType)
    try {
        yield put(
            physicalRestitutionActions.setCDCGroupedByMonthLoading({pointId: 'global', loading: true})
        )
        const simulationId: string = yield select(selectSelectedSimulationId)
        const physicalRestitutionGateway: PhysicalRestitutionGatewayInterface = yield getContext('physicalRestitutionGatewayContext');
        const calls = [];
        const collectiveAutoConsumptionNature = CDCNature.totalConsoAcc
        const individualAutoConsumptionNature = CDCNature.totalConsoAci
        const networkConsumptionNature = CDCNature.totalConsoNetwork
        const consumptionNature = CDCNature.totalConso
        const excessNature = CDCNature.totalExcess;
        calls.push(call(physicalRestitutionGateway.getCDCGroupedByMonth, simulationId, collectiveAutoConsumptionNature));
        calls.push(call(physicalRestitutionGateway.getCDCGroupedByMonth, simulationId, networkConsumptionNature));
        calls.push(call(physicalRestitutionGateway.getCDCGroupedByMonth, simulationId, excessNature));
        if (aci) {
            calls.push(call(physicalRestitutionGateway.getCDCGroupedByMonth, simulationId, individualAutoConsumptionNature));
            calls.push(call(physicalRestitutionGateway.getCDCGroupedByMonth, simulationId, consumptionNature));
        }
        const [collectiveAutoConsumption, networkConsumption, excess, individualAutoConsumption, consumption] = yield all(calls);
        const CDCGroupedByMonthData = {
            collectiveAutoConsumption,
            networkConsumption,
            excess,
            individualAutoConsumption,
            consumption,
        }
        yield put(
            physicalRestitutionActions.setCDCGroupedByMonthData({
                pointId: 'global',
                data: CDCGroupedByMonthData,
                isAci: aci
            })
        )

    } catch (error: any) {
        yield put(
            physicalRestitutionActions.setCDCGroupedByMonthData({pointId: 'global', data: {}, isAci: aci})
        )
        yield put(snackBarErrorsActions.handleError(error));
    }
    yield put(
        physicalRestitutionActions.setCDCGroupedByMonthLoading({pointId: 'global', loading: false})
    )
}

function* getMetrics(): any {
    const {id: pointId, scope, aci} = yield select(selectSelectedType);
    try {
        yield put(
            physicalRestitutionActions.setMetricsLoading({pointId: pointId, loading: true})
        )
        const simulationId: string = yield select(selectSelectedSimulationId);
        const physicalRestitutionGateway: PhysicalRestitutionGatewayInterface = yield getContext('physicalRestitutionGatewayContext');
        let autoConsumptionNature;
        let individualAutoConsumptionNature;
        let supplierConsumptionNature;
        let excessNature;
        let autoProductionRateNature;
        let autoConsumptionRateNature;
        const calls = [];
        let metrics: MetricsData;
        if (pointId === 'global') {
            autoConsumptionNature = metricsNature.totalConsoAcc
            individualAutoConsumptionNature = metricsNature.totalConsoAci
            supplierConsumptionNature = metricsNature.totalConsoSupplier
            autoProductionRateNature = aci ? metricsNature.aciAutoproductionRate : metricsNature.accAutoproductionRate
            autoConsumptionRateNature = aci ? metricsNature.aciAutoconsumptionRate : metricsNature.accAutoconsumptionRate
            excessNature = CDCNature.totalExcess;
            calls.push(call(physicalRestitutionGateway.getMetricValue, simulationId, autoConsumptionNature));
            calls.push(call(physicalRestitutionGateway.getMetricValue, simulationId, supplierConsumptionNature));
            calls.push(call(physicalRestitutionGateway.getMetricValue, simulationId, excessNature));
            calls.push(call(physicalRestitutionGateway.getMetricValue, simulationId, autoProductionRateNature));
            calls.push(call(physicalRestitutionGateway.getMetricValue, simulationId, autoConsumptionRateNature));
            if (aci) {
                calls.push(call(physicalRestitutionGateway.getMetricValue, simulationId, individualAutoConsumptionNature));
            }
            const [autoConsumption, supplierConsumption, excess, autoProductionRate, autoConsumptionRate, individualAutoConsumption] = yield all(calls);
            metrics = {
                autoConsumption,
                supplierConsumption,
                excess,
                autoProductionRate,
                autoConsumptionRate,
                individualAutoConsumption,
            }

        } else if (scope === 'producer') {
            autoConsumptionNature = metricsNature.prodAcc
            individualAutoConsumptionNature = metricsNature.prodAci
            excessNature = metricsNature.excess;
            autoConsumptionRateNature = aci ? metricsNature.aciAutoconsumptionRate : metricsNature.accAutoconsumptionRate
            calls.push(call(physicalRestitutionGateway.getMetricValue, simulationId, autoConsumptionNature, pointId));
            calls.push(call(physicalRestitutionGateway.getMetricValue, simulationId, excessNature, pointId));
            calls.push(call(physicalRestitutionGateway.getMetricValue, simulationId, autoConsumptionRateNature, pointId));
            if (aci) {
                calls.push(call(physicalRestitutionGateway.getMetricValue, simulationId, individualAutoConsumptionNature, pointId));
            }
            const [autoConsumption, excess, autoConsumptionRate, individualAutoConsumption] = yield all(calls);
            metrics = {
                autoConsumption,
                excess,
                autoConsumptionRate,
                individualAutoConsumption,
            }
        } else {
            autoConsumptionNature = metricsNature.consoAcc
            individualAutoConsumptionNature = metricsNature.consoAci
            supplierConsumptionNature = metricsNature.consoSupplier
            autoProductionRateNature = aci ? metricsNature.aciAutoproductionRate : metricsNature.accAutoproductionRate
            calls.push(call(physicalRestitutionGateway.getMetricValue, simulationId, autoConsumptionNature, pointId));
            calls.push(call(physicalRestitutionGateway.getMetricValue, simulationId, supplierConsumptionNature, pointId));
            calls.push(call(physicalRestitutionGateway.getMetricValue, simulationId, autoProductionRateNature, pointId));
            if (aci) {
                calls.push(call(physicalRestitutionGateway.getMetricValue, simulationId, individualAutoConsumptionNature, pointId));
            }
            const [autoConsumption, supplierConsumption, autoProductionRate, individualAutoConsumption] = yield all(calls);
            metrics = {
                autoConsumption,
                supplierConsumption,
                autoProductionRate,
                individualAutoConsumption,
            }
        }
        yield put(
            physicalRestitutionActions.setMetrics({pointId: pointId, metrics: metrics, isAci: aci})
        )
    } catch (error: any) {
        yield put(
            physicalRestitutionActions.setMetrics({pointId: pointId, metrics: {}, isAci: aci})
        )
        yield put(snackBarErrorsActions.handleError(error));
    }
    yield put(
        physicalRestitutionActions.setMetricsLoading({pointId: pointId, loading: false})
    )
}


const reloadData = (saga: any, selector: any) => {
    return function* (action: any) {
        const isDataLoaded: boolean = yield select(selector)
        if (isDataLoaded) {
            return;
        }
        yield call(saga, action);
    };
};

const withCancellation = (saga: any, actionType: string) => {
    return function* (action: any) {
        // @ts-ignore
        const task = yield fork(saga, action)
        yield take(actionType)
        yield cancel(task)
    };


}

function* getCDCDataSaga() {
    yield takeLatest(GET_CDC_DATA, reloadData(withCancellation(getCDCData, CANCEL_GET_CDC_DATA), selectIsCDCDataLoaded));
}

function* getCDCGroupedByMonthDataSaga() {
    yield takeLatest(GET_CDC_GROUPED_BY_MONTH_DATA, reloadData(withCancellation(getCDCGroupedByMonthData, CANCEL_GET_CDC_GROUPED_BY_MONTH_DATA), selectIsCDCGroupedByMonthDataLoaded));
}

function* getMetricsSaga() {
    yield takeLatest(GET_METRICS, reloadData(withCancellation(getMetrics, CANCEL_GET_METRICS), selectIsMetricsDataLoaded));
}


const physicalRestitutionSagas = [getCDCDataSaga, getCDCGroupedByMonthDataSaga, getMetricsSaga]
export default physicalRestitutionSagas;
