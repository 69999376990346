import {IconType} from "../../../../../../config/app-config";

function PlayIconOutlined({className, disabled}:IconType){
    return <svg xmlns="http://www.w3.org/2000/svg" className={className ? className : disabled ?  "h-5 w-5 text-white" :"h-5 w-5 text-gray-700" } width="24" height="24" fill="none" viewBox="0 0 24 24"
                stroke="currentColor" strokeWidth="2">
        <path strokeLinecap="round" strokeLinejoin="round"
              d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"/>
        <path strokeLinecap="round" strokeLinejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
    </svg>
}

function PlayIconSolid({className, disabled}:IconType){
    return <svg xmlns="http://www.w3.org/2000/svg" className={className ? className : disabled ?  "h-5 w-5 text-white" :"h-5 w-5 text-gray-700" } viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clipRule="evenodd" />
    </svg>
}
export {PlayIconOutlined,PlayIconSolid}
