import {Step} from "../../../../models/types/new-simulation/steps/Step";
import {ButtonState} from "../../../../../config/app-config";
import {INIT_SIMULATION_FORM, newSimulationActionsType, SET_STEPS} from "../../newSimulationActions";

const stepsInitialState: Step[] = [
    new Step("Informations Générales", ["étape 1/5"], ButtonState.FOCUS, 0),
    new Step("Points d'injection", ["étape 2/5"], ButtonState.DISABLED, 1,),
    new Step("Points de soutirage", ["étape 3/5"], ButtonState.DISABLED, 2),
    new Step("Paramètres d'opération", ["étape 4/5"], ButtonState.DISABLED, 3),
    new Step("Paramètres financiers", ["étape 5/5"], ButtonState.DISABLED, 4),
    new Step("Simulation", [], ButtonState.DISABLED, 5)
]

const stepsReducer = (state = stepsInitialState, action: newSimulationActionsType) => {
    switch (action.type) {
        case SET_STEPS:
            return action.payload;
        case INIT_SIMULATION_FORM:
            return stepsInitialState
        default:
            return state;
    }
}
export default stepsReducer