import {Actions} from "./snackbarErrorsActions";
import {RESET_SNACKBAR_ERROR, SET_SNACKBAR_ERROR} from "./snackbarErrorsActions";

const initialState: any = null


const snackBarErrorsReducer = (state = initialState, action: Actions) => {
    switch (action.type) {
        case SET_SNACKBAR_ERROR:
            return action.payload;
        case RESET_SNACKBAR_ERROR:
            return null;
        default:
            return state;
    }
}


export default snackBarErrorsReducer;