import {createAction} from "../../../store/createAction";
import {ActionsUnion} from "../../../store/actionsUnions";
import {Organisation} from "../../models/types/simulations-display/Organisation";

export const LOG_IN = 'LOG_IN';
export const LOG_OUT = 'LOG_OUT';
export const GET_SESSION = "GET_SESSION";
export const SET_USER = "SET_USER";
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const PASSWORD_HAS_BEEN_UPDATED = 'PASSWORD_HAS_BEEN_UPDATED';
export const SET_ORGANISATION = 'SET_ORGANISATION';
export const SET_ORGANISATIONS = 'SET_ORGANISATIONS'


export const authenticationActions = {
    logIn: (credentials: {email: string, password: string}) => createAction(LOG_IN, credentials),
    logOut: () => createAction(LOG_OUT),
    getSession: () => createAction(GET_SESSION),
    setUser: (user: any) => createAction(SET_USER, user),
    updatePassword:(oldPwd : string, newPwd : string) => createAction(UPDATE_PASSWORD, {oldPwd, newPwd}),
    setPasswordUpdated:(pwdUpdated:boolean) =>  createAction(PASSWORD_HAS_BEEN_UPDATED, pwdUpdated),
    setOrganisation:(organisation :Organisation|null)=> createAction(SET_ORGANISATION, organisation),
    setOrganisations:(organisations: Organisation[]) => createAction(SET_ORGANISATIONS, organisations),
};

export type Actions = ActionsUnion<typeof authenticationActions>;
