
export class CostAndGain {
    private readonly _surplusFirstYear: number;
    private readonly _acciseFirstYear: number;
    private readonly _majoTurpeFirstYear: number;
    private readonly _ifer: number;
    private readonly _firstYearSaving: number;
    private readonly _saving: number;
    private readonly _name: string;
    private readonly _accise :number | null;
    private readonly _productorGain : number | null ;
    private readonly _tvaCollected : number | null ;

    constructor(surplusFirstYear: number, acciseFirstYear: number, majoTurpeFirstYear: number, ifer: number, firstYearSaving: number, saving: number, name: string, accise ?:number, productorGain ?:number, tvaCollected ?:number) {
        this._surplusFirstYear = surplusFirstYear;
        this._acciseFirstYear = acciseFirstYear;
        this._majoTurpeFirstYear = majoTurpeFirstYear;
        this._ifer = ifer;
        this._firstYearSaving = firstYearSaving;
        this._saving = saving;
        this._name = name;
        this._accise = accise || null
        this._productorGain = productorGain || null
        this._tvaCollected = tvaCollected || null
    }


    get tvaCollected(): number | null {
        return this._tvaCollected;
    }

    get productorGain(): number | null {
        return this._productorGain;
    }

    get accise(): number | null {
        return this._accise;
    }

    get surplusFirstYear(): number {
        return this._surplusFirstYear;
    }

    get acciseFirstYear(): number {
        return this._acciseFirstYear;
    }

    get majoTurpeFirstYear(): number {
        return this._majoTurpeFirstYear;
    }

    get ifer(): number {
        return this._ifer;
    }

    get firstYearSaving(): number {
        return this._firstYearSaving;
    }

    get saving(): number {
        return this._saving;
    }

    get name(): string {
        return this._name;
    }
}