import {InformationCircleIcon} from "@heroicons/react/24/solid";


function openUrlInNewTab(url: string) {
    window.open(url, '_blank', 'noopener,noreferrer');
}


type TitleWithLinkType = {
    title: string,
    description: string,
    url: string
}


export default ({title, description, url}: TitleWithLinkType) => {
    return (
        <span className="flex">
            {title} (
            <span
                onClick={() => openUrlInNewTab(url)}
                className="flex pt-1 px-1 text-xs underline cursor-pointer text-gray-500 dark:text-gray-400"
            ><InformationCircleIcon className={"w-5 h-5 pr-1"} />{description}</span>
            )
        </span>
    );
}