import {put, select, takeLatest} from "redux-saga/effects";
import {snackBarErrorsActions} from "../errors/snackbarErrorsActions";
import {faqActions, ON_SEARCH_TYPE, ON_TAG_CLICK, SET_EXPAND_QUESTION} from "./faqActions";
import {Question} from "../../../adapters/primary/ui/composition/faq/AllQuestions";
import {selectQuestions} from "./faqSelector";

function* expandQuestionById(action:any){
    try {
        const {payload} = action
        const questions:Question[] = yield select(selectQuestions)
        questions.some((q:Question)=> {
            if(q.id === payload) {
                q.expand = !q.expand
            } else {
                q.expand = false
            }
        })
        yield put(faqActions.setQuestions(questions))
    } catch (error: any) {
        yield put(snackBarErrorsActions.handleError(error));
        console.log("error on clearQuestions saga ... ", error)
    }
}
function* expandQuestionSaga() {
    yield takeLatest(SET_EXPAND_QUESTION, expandQuestionById)
}

function* searchOnType(action:any){
    try {
        const {payload} = action
        yield put(faqActions.setSortingTag(`recherche : ${payload}`))
        yield put(faqActions.setSortingSearch(payload))
    } catch (error: any) {
        yield put(snackBarErrorsActions.handleError(error));
        console.log("error on clearQuestions saga ... ", error)
    }
}

function* searchOnTypeSaga() {
    yield takeLatest(ON_SEARCH_TYPE, searchOnType)
}

function* tagOnClick(action:any){
    try {
        const {payload} = action
        yield put(faqActions.setSortingSearch(''))
        yield put(faqActions.setSortingTag(payload))
    } catch (error: any) {
        yield put(snackBarErrorsActions.handleError(error));
        console.log("error on clearQuestions saga ... ", error)
    }
}


function* tagOnClickSaga() {
    yield takeLatest(ON_TAG_CLICK, tagOnClick)
}

const faqSaga = [
    expandQuestionSaga,
    tagOnClickSaga,
    searchOnTypeSaga,
]
export default faqSaga;
