import {useDispatch, useSelector} from "react-redux";
import {
    selectSeasonalityForm
} from "../operationParametersFormSelector";
import {
    selectConsumptionPoints
} from "../../new-simulation/new-simulation-selector/form-data/selectConsumptionPointForm";
import {useEffect, useState} from "react";
import {seasonalityUtils} from "./seasonality.utils";
import {
    operationParametersFormActions
} from "../operationParametersFormActions";

export type SeasonalityVisibility = {
    show: boolean,
    toggle: () => void,
    openConfirmUntoggle: boolean,
    confirmUntoggle: () => void,
    cancelUntoggle: () => void,
}
export default function useSeasonalityVisibility() : SeasonalityVisibility{
    const seasonality = useSelector(selectSeasonalityForm)
    const dispatch = useDispatch()
    const consumptionPoints = useSelector(selectConsumptionPoints)

    const [showSeasonality, setShowSeasonality] = useState<boolean>(false)
    const [openConfirmUntoggleSeasonality, setOpenConfirmUntoggleSeasonality] = useState<boolean>(false)

    useEffect(() => {
        setShowSeasonality(seasonalityUtils.hasSeasonality(seasonality))
    }, [seasonality]);

    function toggleVisibility() {

        const newSeasonalityToggle = !showSeasonality
        if (newSeasonalityToggle) {
            setShowSeasonality(newSeasonalityToggle)
        } else {
            if (seasonalityUtils.hasSeasonality(seasonality)) {
                setOpenConfirmUntoggleSeasonality(true)
            } else {
                dispatch(operationParametersFormActions.setSeasonality({...seasonalityUtils.init(seasonality.consumptionPoints)}))
                setShowSeasonality(false)
            }
        }
    }

    const confirmUntoggle = () => {
        dispatch(operationParametersFormActions.setSeasonality(seasonalityUtils.init(consumptionPoints)))
        setOpenConfirmUntoggleSeasonality(false)
        setShowSeasonality(false)
    }

    return {
        show: showSeasonality,
        toggle: toggleVisibility,

        openConfirmUntoggle: openConfirmUntoggleSeasonality,
        confirmUntoggle,
        cancelUntoggle: () => setOpenConfirmUntoggleSeasonality(false),
    }
}
