import {useSelector} from "react-redux";
import {
    selectACIEconomiesData,
    selectEconomiesLoading
} from "../../../../../corelogic/usecases/financialRestitution/financialRestitutionSelector";
import {formatNumber} from "../../../../../corelogic/usecases/config";

import {Saving} from "../../../../../corelogic/models/types/financialRestitution/Saving";
import {ConsumptionPoint} from "../../../../../corelogic/models/types/new-simulation/form/ConsumptionPoint";
import {
    selectConsumptionPoints,
    selectFinancialDuration
} from "../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectFinancialParameter";


const BillsImpactACIByPointTable = ({displayTVA} :{displayTVA(pointName:string, consumptionPoints:ConsumptionPoint[]):void} ) => {
    const savingsACI = useSelector(selectACIEconomiesData);
    const loading = useSelector(selectEconomiesLoading);
    const duration = useSelector(selectFinancialDuration)
    const consumptionPoints = useSelector(selectConsumptionPoints)



    return <table id={'bills-aci-by-point'} data-cy={'aci-impact-table'} className="bg-white w-full  relative overflow-x-auto p-2  table-auto shadow-md rounded-lg dark:bg-zinc-700">
        <thead>
        <tr className="text-gray-500 text-xs leading-normal dark:text-gray-400 bg-white dark:bg-zinc-700">
            <th align={"left"} className="py-3 px-4 ">Site</th>
            <th align={"center"} className="py-3 px-4 ">Economie ACI 1 an </th>
            <th align={"center"} className="py-3 px-4 ">Economie ACI {duration} ans </th>
        </tr>
        </thead>
        <tbody className="text-gray-500 text-xs font-light bg-white dark:bg-zinc-700">
        {!loading ?
            savingsACI.map((saving: Saving, index: number) => {

                return <tr key={saving.name}
                           className={`border-b border-gray-200 hover:bg-gray-100 dark:hover:bg-zinc-600 ${index === savingsACI.length - 1 ? 'bg-green-300 hover:bg-green-400 dark:hover:bg-green-700  dark:bg-green-800' : ''}`}>
                    <td align={"left"} className="py-2 px-4  whitespace-nowrap dark:text-zinc-200">
                        <span className="font-semibold">{saving.name + displayTVA(saving.name, consumptionPoints) || ''}</span>
                    </td>
                    <td align={"center"} className="py-2 px-4 ">
                        <span
                            className={`font-semibold text-sm ${index === savingsACI.length - 1 ? ' text-green-700 dark:text-green-200' : 'text-slate-400  '}`}>{saving.individualAcFirstYearSaving != null ? formatNumber(saving.individualAcFirstYearSaving)+' €': '-'}</span>
                    </td>
                    <td align={"center"} className="py-2 px-4 ">
                        <span
                            className={`font-semibold text-sm ${index === savingsACI.length - 1 ? ' text-green-700 dark:text-green-200' : 'text-slate-400 '}`}>{saving.individualAcLastYearSaving != null ? formatNumber(saving.individualAcLastYearSaving)+' €': '-'}</span>
                </td>
                </tr>
            })
            : [...Array(3)].map(()=> {
                return <tr className="border-b border-gray-200 animate-pulse">
                    {[...Array(3)].map((_, idx) => {
                        return <td key={idx} className="py-2 px-4 text-left whitespace-nowrap">
                            <div className="flex items-left">
                                <span className="font-bold bg-gray-100 h-4 w-full dark:bg-zinc-600"/>
                            </div>
                        </td>
                    })}
                </tr>
            })
        }
        </tbody>
    </table>
}

export default BillsImpactACIByPointTable
