import PlusCircleIcon from "../../../../components/assets/icon/PlusCircleIcon";
import React, {useEffect, useState} from "react";
import {ChevronLeftRight} from "../../../../components/assets/icon/ChevronUpDown";
import {InflationInterval} from "./VariableInflationInput";
import {useDispatch} from "react-redux";

export default function InflationBadge({
                                           uniqueId,
                                           oneYearWidth,
                                           inflation,
                                           inflations,
                                           index,
                                           setInflations

                                       }: { uniqueId: string, setInflations(inf: InflationInterval[]): any, oneYearWidth: number, inflation: InflationInterval, inflations: InflationInterval[], index: number }) {
    const dispatch = useDispatch()
    const [value, setValue] = useState(inflation?.value != null ? inflation?.value : '')
    const isLastBadge = index === inflations.length - 1
    const isFirstBadge = index === 0
    const badgeIsOneYearWidth = (inflation.endYear - inflation.startYear) === 1
    const nextBadgeExistsAndIsOneYearWidth = !isLastBadge ? (inflations[index + 1].endYear - inflations[index + 1].startYear) === 1 : false

    function addInflation() {
        const newInflations = [...inflations]
        const yearOfSeparation = Math.round((inflation.endYear - inflation.startYear) / 2) + inflation.startYear
        const addedInflation: InflationInterval = {
            value: inflation.value,
            startYear: yearOfSeparation,
            endYear: inflation.endYear - inflation.startYear + inflation.startYear
        }
        newInflations[index].endYear = yearOfSeparation
        newInflations.push(addedInflation)
        newInflations.sort((inflationA, inflationB) => inflationA.startYear - inflationB.startYear)

        dispatch(setInflations(JSON.parse(JSON.stringify(newInflations))))
    }

    function handleOnChange(newValue: string) {
        let value: string = newValue
        const isAFloatWith2Decimals = newValue[newValue.length - 3] === "." || newValue[newValue.length - 3] === ","
        if (isAFloatWith2Decimals) {
            value = newValue.slice(0, -1)
        }
        setValue(value)
        const valueFormatted = parseFloat(value.replace(/,/g, ".").replace(/\s/g, ''))
        if (!isNaN(valueFormatted)) {
            const newInflations = [...inflations]
            newInflations[index].value = valueFormatted
            dispatch(setInflations(JSON.parse(JSON.stringify(newInflations))))
        }

    }

    function getWidth() {
        const width = oneYearWidth * (inflation.endYear - inflation.startYear)
        const displayRightBarWidth = (isFirstBadge ? 2 : 0) + (isLastBadge ? -2 : 0)
        return width + displayRightBarWidth
    }

    return <div id={uniqueId + index + 'badge'} className={"bottom-[84px] relative "}>
        <div className={"flex justify-center items-center"} style={{width: getWidth()}}>
            <div
                className={"h-12 flex items-center justify-center w-full px-1 py-1 bg-blue-200 dark:bg-sky-700 opacity-80 text-white rounded-sm whitespace-nowrap font-bold "}>
                <input
                    type={"number"}
                    style={{
                        MozAppearance: "textfield"
                    }}
                    onChange={(e) => handleOnChange(e.target.value)}
                    value={value}
                    className={"appearance-none w-[20px] bg-blue-200 text-sm dark:bg-sky-700 dark:text-white text-blue-600 whitespace-nowrap font-semibold "}
                />
                {!badgeIsOneYearWidth && <span className={"pr-2 text-sm dark:text-white text-blue-600"}>%</span>}
                {!badgeIsOneYearWidth && <div onClick={addInflation}>
                    <PlusCircleIcon
                        className={"dark:text-white text-blue-600 w-5 h-5 hover:cursor-pointer hover:dark:text-sky-100 hover:text-blue-700"}/>
                </div>}
            </div>
            {!isLastBadge && badgeIsOneYearWidth && nextBadgeExistsAndIsOneYearWidth && <BlueSeparationBar/>}
            {!isLastBadge && !(badgeIsOneYearWidth && nextBadgeExistsAndIsOneYearWidth) &&
            <SlideBar setInflations={setInflations} uniqueId={uniqueId} index={index} inflations={inflations}
                      oneYearWidth={oneYearWidth}/>}
        </div>
    </div>

}
const BlueSeparationBar = () => <div className={"border-2 border-sky-600 h-8"}/>
type dragElementType = {
    elmnt: any,
    minOffset: number,
    maxOffset: number,
    oneYearWidth: number,
    inflations: InflationInterval[],
    index: number
}
const SlideBar = ({
                      uniqueId,
                      index,
                      inflations,
                      oneYearWidth,
                      setInflations

                  }: { setInflations(inf: InflationInterval[]): any, uniqueId: string, index: number, inflations: InflationInterval[], oneYearWidth: number }) => {
    const [minOffset, setMinOffset] = useState(0)
    const [maxOffset, setMaxOffset] = useState(0)
    const dispatch = useDispatch()

    function dragElement({elmnt, minOffset, maxOffset, oneYearWidth, inflations, index}: dragElementType) {
        let previousXPosition = 0
        let newXPosition = 0
        let leftOffset = 0
        if (elmnt) {
            if (document.getElementById(elmnt?.id)) {
                const el: any = document.getElementById(elmnt.id)
                el.onmousedown = dragMouseDown;
            }
        }

        function dragMouseDown(e: any) {
            e = e || window.event;
            e.preventDefault();
            // get the mouse cursor position at startup:
            previousXPosition = e.clientX;
            document.onmouseup = closeDragElement;
            // call a function whenever the cursor moves:
            document.onmousemove = elementDrag;
        }

        function elementDrag(e: any) {
            e = e || window.event;
            e.preventDefault();
            // calculate the new cursor position:
            newXPosition = previousXPosition - e.clientX;
            previousXPosition = e.clientX;

            // set the element's new position,
            // with max value as next badge max year pos -1
            // with min value as current badge min year pos +1
            if (elmnt.offsetLeft - newXPosition <= minOffset) {
                leftOffset = minOffset
            } else if (elmnt.offsetLeft - newXPosition >= maxOffset) {
                leftOffset = maxOffset
            } else {
                leftOffset = elmnt.offsetLeft - newXPosition
            }
            elmnt.style.left = leftOffset + "px";
        }

        function closeDragElement() {
            document.onmousemove = null;
            const numberOfWidthUnit = Math.round((leftOffset / oneYearWidth))
            const moveSlider = numberOfWidthUnit !== 0
            if (moveSlider) {
                const newInflations = [...inflations]
                const newSliderPosition = inflations[index].endYear + numberOfWidthUnit
                newInflations[index].endYear = newSliderPosition
                newInflations[index + 1].startYear = newSliderPosition
                dispatch(setInflations(JSON.parse(JSON.stringify(newInflations))))
            }
            leftOffset = 0
            elmnt.style.left = leftOffset + "px";
        }
    }

    useEffect(() => {
        dragElement({
            elmnt: document.getElementById(`${uniqueId}yellow-bar-${index}`),
            minOffset,
            maxOffset,
            oneYearWidth,
            inflations,
            index
        });
    }, [index, minOffset, maxOffset])

    useEffect(() => {
        const previousBadgeMaxWidth = oneYearWidth * (inflations[index].endYear - inflations[index].startYear) - oneYearWidth
        const nextBadgeMaxWidth = oneYearWidth * (inflations[index + 1].endYear - inflations[index + 1].startYear) - oneYearWidth
        setMinOffset(-previousBadgeMaxWidth)
        setMaxOffset(nextBadgeMaxWidth)
    }, [inflations, oneYearWidth])


    return <div className={"relative bottom-6 w-1"}>
        <div
            id={`${uniqueId}yellow-bar-${index}`}
            key={`yellow-bar-${index}`}
            className={"absolute w-1 h-12 top-2 text-yellow-500 hover:text-yellow-400 z-10"}>
            <div className={"flex items-center flex-col"}>
                <div
                    className={"border-2  h-8 hover:cursor-e-resize hover:animate-pulse border-yellow-500 hover:border-yellow-400"}/>
                <ChevronLeftRight className={" h-6 w-6  relative top-3 opacity-60"}/>
            </div>
        </div>
    </div>
}
