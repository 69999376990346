import React from "react";

export default function Switch({state, action}:{state:boolean, action():void}){
    return <div onClick={action}
         className={state ? "form-check-input appearance-none w-9 rounded-full float-left h-5 align-top bg-no-repeat bg-contain bg-orange-200 focus:outline-none cursor-pointer shadow-sm" : "form-check-input appearance-none w-9 rounded-full float-left h-5 align-top bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm"}
         role="switch" id="flexSwitchCheckInflationDefault">
        <div
            className={state ? "w-5 h-5 ml-4 bg-secondary rounded-full " : "w-5 h-5 bg-white rounded-full "}/>
    </div>
}
