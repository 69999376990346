import {
    Actions,
    PASSWORD_HAS_BEEN_UPDATED,
    SET_ORGANISATION,
    SET_ORGANISATIONS,
    SET_USER
} from "./authenticationActions";
import {Organisation} from "../../models/types/simulations-display/Organisation";

export interface Authentication {
    isAuthenticated: boolean | null
    user: any
    darkMode : boolean,
    passwordHasBeenUpdated : boolean,
    selectedOrganisation : Organisation | null,
    organisations : Organisation[],
    sideBarIsReduced : boolean
}

export const authenticationInitialState: Authentication = {
    isAuthenticated: null,
    user: null,
    organisations : [],
    darkMode : window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches,
    passwordHasBeenUpdated : false,
    selectedOrganisation : null,
    sideBarIsReduced : false
}

const authenticationReducer = (state = authenticationInitialState, action: Actions) => {
    switch (action.type) {
        case SET_USER:
            return {...state, isAuthenticated: !!action.payload, user: action.payload};
        case SET_ORGANISATION :
            return {...state, selectedOrganisation : action.payload};
        case SET_ORGANISATIONS :
            return {...state, organisations : action.payload.length > 1 ? action.payload : action.payload};
        case PASSWORD_HAS_BEEN_UPDATED :
            return {...state,  passwordHasBeenUpdated : action.payload}
        default:
            return state;
    }
}

export default authenticationReducer;
