import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectCartoCircleInKm} from "../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import {typologyFormActions} from "../../../../../../../corelogic/usecases/typology-form/typologyFormActions";
import {DARK_SAPPHIRE, ICELAND_POPPY, TOMATO_RED} from "../LeafletMap";

export default function () {
    const circleDiameter: number = useSelector(selectCartoCircleInKm)

    const dispatch = useDispatch()
    const handleChange = (perimeter : number) => {
        dispatch(typologyFormActions.setCartoCircleInKm(perimeter)); // Convert the value to a number and update the state
        dispatch(typologyFormActions.setShowDensity(false))
    };

    const clickedButton = "bg-gray-100 border-2 rounded border-gray-300 text-sm font-bold text-gray-800 px-2 py-1"
    const disabledButton = "bg-gray-100 hover:bg-gray-200 text-sm  text-gray-500 px-2 py-1"
    const circleColor= circleDiameter < 10 ? TOMATO_RED : circleDiameter < 20 ? ICELAND_POPPY : DARK_SAPPHIRE

    return <div className={"flex gap-1 items-center"}>
        <label htmlFor="default-range"
               className="block text-sm font-medium text-gray-700 whitespace-nowrap">
            Diamètre du cercle définissant le périmètre :
        </label>
        <button
            style={{borderColor : circleColor}}
            onClick={()=> handleChange(2)}
            className={circleDiameter === 2 ? clickedButton :disabledButton}>2 km</button>
        <button
            style={{borderColor : circleColor}}
            onClick={()=> handleChange(10)}
            className={circleDiameter === 10 ? clickedButton :disabledButton}>10 km</button>
        <button
            style={{borderColor : circleColor}}
            onClick={()=> handleChange(20)}
            className={circleDiameter === 20 ? clickedButton :disabledButton} >20 km</button>
    </div>
}
