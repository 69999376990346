import {createAction} from "../../../store/createAction";
import {ActionsUnion} from "../../../store/actionsUnions";
import {Simulation} from "../../models/types/simulations-display/Simulation";

export const GET_SIMULATIONS = 'GET_SIMULATIONS';
export const SET_SIMULATIONS = 'SET_SIMULATIONS';
export const DUPLICATE_SIMULATION = 'DUPLICATE_SIMULATION';
export const INIT_SIMULATIONS = 'INIT_SIMULATIONS';
export const SET_SIMULATIONS_CURRENT_PAGE = 'SET_SIMULATIONS_CURRENT_PAGE';
export const SET_SIMULATIONS_COUNT = 'SET_SIMULATIONS_COUNT';
export const SEARCH_SIMULATION= 'SEARCH_SIMULATION';
export const GET_ALL_SIMULATION = 'GET_ALL_SIMULATION';
export const SET_ALL_SIMULATIONS = "SET_ALL_SIMULATIONS";
export const INIT_ALL_SIMULATIONS = "INIT_ALL_SIMULATIONS";

export const simulationsDisplayActions = {
  searchOnType: (text: string) => createAction(SEARCH_SIMULATION, text),
  getSimulations: () => createAction(GET_SIMULATIONS),
  getAllSimulations: () => createAction(GET_ALL_SIMULATION),
  setSimulations: (simulations: Simulation[]) => createAction(SET_SIMULATIONS, simulations),
  initSimulations: () => createAction(INIT_SIMULATIONS),
  initAllSimulation: () => createAction(INIT_ALL_SIMULATIONS),
  setAllSimulations: (simulations: Simulation[]) => createAction(SET_ALL_SIMULATIONS, simulations),
  duplicateSimulation: (simulationId: string) => createAction(DUPLICATE_SIMULATION, simulationId),
  setSimulationsCurrentPage: (currentPage: number) => createAction(SET_SIMULATIONS_CURRENT_PAGE, currentPage),
  setSimulationsCount: (count: number) => createAction(SET_SIMULATIONS_COUNT, count),
};

export type Actions = ActionsUnion<typeof simulationsDisplayActions>;
