import SelectorInputForCapex from "./SelectorInputForCapex";
import TextInputForTable from "../../../../../components/assets/FormAssets/TextInputForTable";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectFormCapexes} from "../../../../../../../../corelogic/usecases/form/formSelector";
import {formActions} from "../../../../../../../../corelogic/usecases/form/formActions";
import AddLoanButton from "./AddLoanButton";
import RemoveRowButton from "./RemoveRowButton";
import LoanPriceInput from "./LoanPriceInput";
import LoanDurationInput from "./LoanDurationInput";
import LoanRateInput from "./LoanRateInput";
import {selectIsLoadingSimulationForm} from "../../../../../../../../corelogic/usecases/loader/loaderSelector";
import {FormCapex} from "../FormCapex";
import {availableCapexesPointsPlusSelf} from "../FormCapex.utils";
import {
    selectInjectionPoints
} from "../../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectInjectionPointForm";

const CapexTableBody = ({disabled}:{disabled?:true}) => {
    const injectionPoints = useSelector(selectInjectionPoints)
    const capexes = useSelector(selectFormCapexes)
    const dispatch = useDispatch()
    const isFormLoading = useSelector(selectIsLoadingSimulationForm)

    function capexOnChange(e: string, index: number) {
        // Basic Validation
        if(!disabled){
            let capexChanged:FormCapex[] = [...capexes]
            capexChanged[index].capex = e
            dispatch(formActions.setCapexes(capexChanged))
        }
    }

    return <tbody
        className="text-gray-500 text-xs font-light dark:text-zinc-300 ">
    <tr className={"py-1 h-2"}/>
    {capexes.map((cap: FormCapex, index: number) => {
        const disabledStyle: string = cap.isPointEnabled(injectionPoints) ? "" : " opacity-40 "
        return <tr
            className={cap.validateCapexPrice() ? " bg-white dark:bg-zinc-800 " + disabledStyle : "bg-red-50 dark:bg-red-800 dark:bg-opacity-20 " + disabledStyle}>
            <td align={"left"}
                className={"  text-sm  px-2 "}>
                <SelectorInputForCapex value={cap.id}
                                       index={index}
                                       disabled={disabled}
                                       id={"select-capex-" + index}
                                       options={availableCapexesPointsPlusSelf(capexes, injectionPoints, cap)}/>
            </td>
            <td className={""}>
                <TextInputForTable
                    isAFloat
                    disabled={disabled}
                    id={"capex-" + index}
                    index={index}
                    onChange={capexOnChange}
                    value={cap.capex} loading={isFormLoading}
                    isValid={cap.validateCapexPrice()}/>
            </td>
            {cap.loan ? <LoanPriceInput loading={isFormLoading} disabled={disabled} loan={cap.loan} index={index}/> : <td/>}
            {cap.loan ? <LoanDurationInput loading={isFormLoading} disabled={disabled} loan={cap.loan} index={index}/>: <td/>}
            {cap.loan ? <LoanRateInput loading={isFormLoading} disabled={disabled}  loan={cap.loan} index={index}/>: <td/>}
            <td className={'flex gap-2 items-center mx-1'}>
                <AddLoanButton disabled={disabled} index={index}/>
                <RemoveRowButton disabled={disabled} index={index}/>
            </td>
        </tr>
    })
    }
    </tbody>
}
export default CapexTableBody
