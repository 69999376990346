import React from "react";
import ChevronUp from "../../../../components/assets/icon/ChevronUp";
import ChevronDown from "../../../../components/assets/icon/ChevronDown";
import {
    DARK_SAPPHIRE,
    DARK_SAPPHIRE_LIGHT,
    DISABLED_COLOR,
    ICELAND_POPPY,
    ICELAND_POPPY_DARKEN,
    ICELAND_POPPY_LIGHT,
    TOMATO_RED,
    TOMATO_RED_DARKEN
} from "../LeafletMap";
import ExclamationIcon from "../../../../components/assets/icon/ExclamationIcon";

export default function ({expandPerimeterInfo, setExpandPerimeterInfo}: {
    expandPerimeterInfo: boolean,
    setExpandPerimeterInfo: (expand: boolean) => void
}) {
    return <div
        className={"flex ShadowL bg-opacity-80 flex-col  bg-gray-50 p-1 gap-2 relative w-[550px] left-12 top-2 rounded z-[500]"}>
        <label htmlFor="default-range"
               className="block text-sm font-medium text-gray-700 ">
            <div
                onClick={() => setExpandPerimeterInfo(!expandPerimeterInfo)}
                className={'w-full justify-between flex items-center cursor-pointer hover:bg-gray-100 px-2 py-1 rounded'}>
                <p className={'font-semibold'}>Eligibilité à un périmètre dérogatoire</p>
                {expandPerimeterInfo ? <ChevronUp classname={'w-5 h-5 cursor-pointer'}/> :
                    <ChevronDown classname={'w-5 h-5 cursor-pointer'}/>}
            </div>
            {expandPerimeterInfo && <div className={"p-2"}>
                Il existe 3 périmètres en autoconsommation collective :
                <div className="flex flex-col gap-3 pl-2 py-3">
                    <div className={"flex gap-2 items-center text-xs"}>
                        <div
                            className={"mr-[15px] h-[45px] min-w-[45px] w-[45px] flex items-center justify-center rounded-full whitespace-nowrap  border-2 bg-orange-100 border-orange-300 bg-opacity-50 text-sm font-bold text-gray-800"}>
                            <p>2 km</p>
                        </div>
                        Périmètre permettant de regrouper des participants quelle que soit la densité de population de leur commune de résidence.
                    </div>

                    <div className={"flex gap-2 items-center text-xs"}>
                    <div
                        className={" mr-[5px] h-[55px] min-w-[55px] w-[55px] flex items-center justify-center rounded-full whitespace-nowrap  border-2 bg-purple-100 border-purple-300 bg-opacity-50 text-sm font-bold text-gray-800"}>
                        <p>10 km</p>
                    </div>
                    <p>Dérogatoire pour les zones périurbaines et rurales : 10 km permettant de regrouper des participants dont le site ne se trouve pas dans de grands centre urbains ou des centres urbains intermédiaires. Pour plus de lisibilité ces communes sont donc grisées sur la carte lorsque vous sélectionnez un périmètre de 10km.
                    </p>
                    </div>
                    <div className={"flex gap-2 items-center text-xs"}>
                        <div
                            className={" h-[60px] min-w-[60px] w-[60px] flex items-center justify-center rounded-full whitespace-nowrap  border-2 bg-slate-100 border-slate-300 bg-opacity-50 text-sm font-bold text-gray-800"}>
                            <p>20 km</p>
                        </div>
                    <p>Dérogatoire pour les zones rurales : 20 km permettant de regrouper uniquement des participants dont le site se trouve dans des bourg ruraux, ou des communes rural à habitat dispersé ou très dispersé. Pour plus de lisibilité seules les communes autorisées ne sont pas grisées.
                    </p>
                    </div>
                </div>
                <p className={"text-xs pt-2"}>NB : Cette feature est approximative et ne garanti pas l'acceptation de votre dérogation par le Ministre en charge de l'énergie. Nous basons nos adresses sur le point GPS retourné par l'API du gouvernement (api.gouv.fr) </p>

                <p className={'text-xs pt-2'}> NB2: Cette fonctionnalité étant en test il n'est pas possible de changer la taille du périmètre en affichant la densité des communes il faut nécessairement repasser par la cartographie simple. Nous travaillons à simplifier cet usage.
                </p>
            </div>}
        </label>

    </div>
}
