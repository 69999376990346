import {InflationInterval} from "./variable-inflation-widget/VariableInflationInput";

export function hasInflationChanged (fixedInflation:string,variableInflations :  InflationInterval[], storedInflation : number | InflationInterval[] | undefined, variableInflationsToggled:boolean) {
    if (fixedInflation && !variableInflationsToggled) {
        return parseFloat(fixedInflation) !== storedInflation
    }
    if (variableInflationsToggled && variableInflations) {
        const allValuesAreNotTheSame = variableInflations.some((inflation)=>inflation.value !== storedInflation)
        if(!allValuesAreNotTheSame) return false
        if (!storedInflation || typeof storedInflation === "number" || storedInflation.length !== variableInflations.length) return true
        return variableInflations.some((inflation: InflationInterval, index: number) => {
            return inflation.value !== storedInflation[index]?.value
                || inflation.startYear !== storedInflation[index]?.startYear
                || inflation.endYear !== storedInflation[index]?.endYear
        })
    }
    return false
}
