import {
    formActionsType,
    INIT_SELLING_PRICE_INFLATIONS,
    INIT_VARIABLE_INFLATIONS,
    OPEN_MODAL_SELLING_PRICE_INFLATIONS,
    RESET_FINANCIAL_FORM,
    SET_CAPEXES,
    SET_CAPEXES_ARE_VALID,
    SET_DURATION,
    SET_FIXED_DEFAULT_TAX,
    SET_FIXED_TAX,
    SET_INFLATION,
    SET_OPEXES,
    SET_OPEXES_ARE_VALID,
    SET_SELECTED_CELLS,
    SET_SELLING_PRICE,
    SET_SELLING_PRICE_IS_VALID,
    SET_TEMPORARY_SELLING_PRICE_INFLATIONS,
    SET_VARIABLE_TAX,
    SET_VARIABLE_TAX_FRANCE,
    SET_VARIABLE_TAX_ZNI
} from "./formActions";
import {
    InflationInterval
} from "../../../adapters/primary/ui/composition/form/financial-parameters/variable-inflation-widget/VariableInflationInput";
import {
    SET_SELLING_PRICE_INFLATION,
    SET_SELLING_PRICE_INFLATION_TOGGLE,
    SET_VARIABLE_INFLATIONS,
    TOGGLE_VARIABLE_INFLATIONS
} from "../form/formActions";

type FormType = {
    sellingPriceTable: {
        sellingPriceInflation?: string | InflationInterval[],
        sellingPriceInflationToggled: boolean,
        sellingPrice: any,
        sellingPriceSelectedCells: any[],
        sellingPriceIsValid: any[],
        sellingPriceModalInflations: boolean,
    },
    inflationInput: {
        variableInflations: InflationInterval[],
        variableInflationsToggled: boolean,
        temporarySellingPriceInflations: InflationInterval[],
        inflation: string
    }
    capexes: any[],
    opexes: any[],
    opexesAreValid: any[],
    capexesAreValid: any[],
    duration: string | null,
    variableTax: number
    fixedTax: number
}
const initialState: FormType = {
    sellingPriceTable: {
        sellingPrice: {},
        sellingPriceSelectedCells: [],
        sellingPriceIsValid: [],
        sellingPriceInflationToggled: false,
        sellingPriceModalInflations: false,
    },
    inflationInput: {
        variableInflations: [],
        variableInflationsToggled: false,
        temporarySellingPriceInflations: [],
        inflation: ''
    },
    capexes: [],
    opexes: [],
    opexesAreValid: [],
    capexesAreValid: [],
    duration: null,
    variableTax: 20,
    fixedTax: 5.5
}


const formReducer = (state = initialState, action: formActionsType) => {
    switch (action.type) {
        case SET_FIXED_TAX :
            return {
                ...state, fixedTax: action.payload
            }
        case SET_FIXED_DEFAULT_TAX :
            return {
                ...state, fixedTax: 5.5
            }
        case SET_VARIABLE_TAX :
            return {
                ...state, variableTax: action.payload
            }
        case SET_VARIABLE_TAX_FRANCE :
            return {
                ...state, variableTax: 20,
            }
        case SET_VARIABLE_TAX_ZNI :
            return {
                ...state, variableTax: 8.5,
            }
        case SET_INFLATION :
            return {
                ...state, inflationInput: {
                    ...state.inflationInput,
                    inflation: action.payload
                }
            }
        case INIT_SELLING_PRICE_INFLATIONS:
            return {
                ...state,
                sellingPriceTable: {...state.sellingPriceTable, temporarySellingPriceInflations: action.payload}
            }
        case INIT_VARIABLE_INFLATIONS:
            return {
                ...state, inflationInput: {
                    ...state.inflationInput, variableInflations: action.payload
                }
            }
        case RESET_FINANCIAL_FORM :
            return initialState
        case SET_TEMPORARY_SELLING_PRICE_INFLATIONS:
            return {
                ...state, inflationInput: {
                    ...state.inflationInput, temporarySellingPriceInflations: action.payload
                }
            }
        case SET_DURATION :
            return {...state, duration: action.payload}
        case OPEN_MODAL_SELLING_PRICE_INFLATIONS :
            return {
                ...state,
                sellingPriceTable: {...state.sellingPriceTable, sellingPriceModalInflations: action.payload}
            }
        case SET_SELLING_PRICE:
            return {...state, sellingPriceTable: {...state.sellingPriceTable, sellingPrice: action.payload}}
        case SET_SELECTED_CELLS:
            return {
                ...state,
                sellingPriceTable: {...state.sellingPriceTable, sellingPriceSelectedCells: action.payload}
            }
        case SET_SELLING_PRICE_IS_VALID:
            return {...state, sellingPriceTable: {...state.sellingPriceTable, sellingPriceIsValid: action.payload}}
        case SET_CAPEXES:
            return {...state, capexes: action.payload}
        case SET_OPEXES:
            return {...state, opexes: action.payload}
        case SET_OPEXES_ARE_VALID : {
            return {...state, opexesAreValid: action.payload}
        }
        case SET_CAPEXES_ARE_VALID : {
            return {...state, capexesAreValid: action.payload}
        }
        case SET_VARIABLE_INFLATIONS:
            return {
                ...state, inflationInput: {
                    ...state.inflationInput, variableInflations: action.payload
                }
            }
        case TOGGLE_VARIABLE_INFLATIONS:
            return {
                ...state, inflationInput: {
                    ...state.inflationInput, variableInflationsToggled: action.payload
                }
            }
        case SET_SELLING_PRICE_INFLATION:
            return {...state, sellingPriceTable: {...state.sellingPriceTable, sellingPriceInflation: action.payload}}
        case SET_SELLING_PRICE_INFLATION_TOGGLE:
            return {
                ...state,
                sellingPriceTable: {...state.sellingPriceTable, sellingPriceInflationToggled: action.payload}
            }
        default:
            return state;
    }
}

export default formReducer;



