import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    selectSGEDateStartDays,
    selectSGEDateStartMonths
} from "../../../../../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import {typologyFormActions} from "../../../../../../../../../../../corelogic/usecases/typology-form/typologyFormActions";

type DateInputType = {
    handleInput(e: React.ChangeEvent<HTMLInputElement>, currentIndex: number): void,
    handleFocus(e: React.FocusEvent<HTMLInputElement>): void,
    handleOnBlur(): void,
    inputRefs: React.MutableRefObject<(HTMLInputElement | null)[]>,
    setFocused(value: boolean): void
}
const MonthInput = ({handleInput, handleFocus, handleOnBlur, inputRefs, setFocused}: DateInputType) => {
    const dispatch = useDispatch()
    const months = useSelector(selectSGEDateStartMonths)

    const updateNumber = (months: string) => {
        const numericValue = months.replace(/\D/g, ''); // Remove non-digit characters
        dispatch(typologyFormActions.setSGEStartDateMonths(numericValue));
    };
    return <input
        placeholder={"01"}
        className={"bg-gray-100 dark:bg-purple-800 dark:bg-opacity-20 focus:outline-none max-w-[20px]"}
        type="text"
        onInput={(e: any) => handleInput(e, 1)}
        onFocus={handleFocus}
        ref={(el) => (inputRefs.current[1] = el)}
        maxLength={2}
        value={months}
        onClick={() => setFocused(true)}
        onBlur={handleOnBlur}
        onChange={(e) => updateNumber(e.target.value)}
    />
}

export default MonthInput
