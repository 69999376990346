import React, {useRef} from "react";
import {useSelector} from "react-redux";
import {selectFormDuration} from "../../../../../../../corelogic/usecases/form/formSelector";

export default function Grid() {
    const formDuration = useSelector(selectFormDuration)
    const durationInteger = formDuration ? Math.round(parseInt(formDuration)) : 0

    return <>
        <XAxis duration={durationInteger}/>
        <BGGrid duration={durationInteger}/>
    </>
}

function BGGrid({duration}:{duration:number}) {
    const ref2: any = useRef(null)
    const styleLarge = "border-2 dark:border-white border-gray-600 opacity-10 h-16 relative bottom-2 "
    const styleThin = "border-[0.5px] dark:border-white border-gray-600 opacity-10 h-14 relative bottom-2 "

    return <div ref={ref2} className={"flex flex-row items-center justify-between h-24 px-3 pt-2"}>
        {[...Array(duration)].map((x, index) => {
            const isAMultipleOf5 = index % 5 === 0
            return <div className={isAMultipleOf5 ? styleLarge : styleThin}/>
        })}
        <div className={styleLarge}/>
    </div>
}


function XAxis({duration}:{duration:number}) {

    const numberOfDivisions = duration +1

    return <div className={" flex items-center justify-between "}>
        {[...Array(numberOfDivisions)].map((x, index) => {
            const isAMultipleOf5 = index % 5 === 0
            const isLastYear = numberOfDivisions -1 === index
            return (isAMultipleOf5 || isLastYear) ? <span className={"dark:text-gray-300 text-gray-600 w-[31px] text-center "}>
                {index}
            </span> : <span className={"w-[31px]"}/>

        })}
    </div>
}
