import {combineReducers} from "redux";
import simulationFormReducer from "./form-data/simulationFormReducer";
import externalInvestmentFormReducer from "./form-data/externalInvestmentFormReducer";
import updatingSimulationReducer from "./form-state/updatingSimulationReducer";
import stepsReducer from "./steps/stepsReducer";
import loadedCurvesReducer from "./form-data/loadedCurvesReducer";
import formIsValidReducer from "./form-state/formIsValidReducer";
import closeFormReducer from "./form-action/closeFormReducer";
import savingFormReducer from "./form-action/savingFormReducer";
import formHasNewChangesReducer from "./form-state/formHasNewChangesReducer";
import currentStepIndexReducer from "./steps/currentStepIndexReducer";
import nextStepIndexReducer from "./steps/nextStepIndexReducer";
import hasBeenUpdatedNotificationReducer from "./form-state/hasBeenUpdatedNotificationReducer";
import addressesReducer from "./form-data/addressesReducer";


export default combineReducers({
    simulationForm: simulationFormReducer,
    externalInvestmentForm : externalInvestmentFormReducer,

    loadedCurves: loadedCurvesReducer,
    addresses: addressesReducer,

    formIsUpdating: updatingSimulationReducer,
    formIsValid: formIsValidReducer,
    formHasNewValidChanges: formHasNewChangesReducer,
    formHasBeenUpdated: hasBeenUpdatedNotificationReducer,

    closeForm: closeFormReducer,
    saveForm: savingFormReducer,

    steps: stepsReducer,
    currentStepIndex: currentStepIndexReducer,
    nextStepIndex: nextStepIndexReducer,

});
