import {State} from "../../../../config/app-config";

export class Badge {

    private _text: string;
    private _state: State;


    constructor(text: string, state: State) {
        this._text = text;
        this._state = state;
    }

    static fromObject({text, state}:{text:string, state:State}) {
        return new this(text, state)
    }


    get text(): string {
        return this._text;
    }

    set text(value: string) {
        this._text = value;
    }

    get state(): State {
        return this._state;
    }

    set state(value: State) {
        this._state = value;
    }
}
