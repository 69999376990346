import {RootState} from "../../../store/rootReducer";
import {createSelector} from "reselect";


const selectConfig : any = (state: RootState) => state.config;

export const selectSideBarIsReduced = createSelector(
    selectConfig,
    authentication => authentication.sideBarIsReduced
);
export const selectIsDarkMode = createSelector(
    selectConfig,
    authentication => authentication.darkMode
);
