import TitleText from "../components/assets/text/TitleText";
import TextInput from "../components/assets/FormAssets/TextInput";
import DescriptionText from "../components/assets/text/DescriptionText";
import RegularBtn from "../components/assets/button/RegularBtn";
import {ButtonState, Theme} from "../../../../config/app-config";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import {authenticationActions} from "../../../../corelogic/usecases/authentication/authenticationActions";
import {selectEnolabErrorCode} from "../../../../corelogic/usecases/errors/errorsSelector";
import {selectPasswordHasBeenUpdated} from "../../../../corelogic/usecases/authentication/authenticationSelector";
import {containsAllCases, containsDigit, containsSpecialChars} from "../../utils/utils";
import {ErrorsEnum} from "../../utils/snackbarErrorMessages";


export default function ChangePassword() {
    const dispatch = useDispatch()
    const enolabErrorCode = useSelector(selectEnolabErrorCode)
    const [oldPwdIsIncorrect, setOldPwdIsIncorrect] = useState(false)
    const passwordHasBeenUpdated = useSelector(selectPasswordHasBeenUpdated)
    const {enqueueSnackbar, closeSnackbar} = useSnackbar()
    const [submitBtnState, setSubmitBtnState] = useState(ButtonState.DISABLED)
    const [oldPwdIsValid, setOldPwdIsValid] = useState(true)
    const [newPwdIsValid, setNewPwdIsValid] = useState(true)
    const [newPwdConfirmationIsValid, setNewPwdConfirmationIsValid] = useState(true)
    const [oldPwd, setOldPwd] = useState<string | null>(null)
    const [newPwd, setNewPwd] = useState<string | null>(null)
    const [newPwdConfirmation, setNewPwdConfirmation] = useState<string | null>(null)

    useEffect(() => {
        if (enolabErrorCode === ErrorsEnum.LAB_LOGIN_INVALID_OLD_PASSWORD) {
            setOldPwdIsValid(false)
            setOldPwdIsIncorrect(true)
        }
    }, [enolabErrorCode])

    useEffect(() => {
        function redirectToHomePage(){
            dispatch(authenticationActions.setUser(null))
        }

        if (passwordHasBeenUpdated) {

            const timer = setTimeout(function () {
                console.log("timer called ..")
                redirectToHomePage()
            }, 5000);
            enqueueSnackbar(`Votre mot de passe a bien été mis à jour. Vous allez être redirigé dans 5 secondes.`, {
                action: (key) => {
                    return <div className={"hover:cursor-pointer bg-green-700 hover:bg-green-800 px-2 py-1 rounded-md"}>
                        <RegularBtn
                            action={() => {
                                clearTimeout(timer);
                                closeSnackbar(key);
                                redirectToHomePage()
                            }}
                            title={"être redirigé maintenant"}/>
                    </div>
                }, variant: 'success'
            })
        }
    }, [closeSnackbar, dispatch, enqueueSnackbar, passwordHasBeenUpdated])

    function oldPwdOnChange(e: string) {
        setOldPwd(e);
    }

    function newPwdOnChange(e: string) {
        setNewPwd(e);
    }

    function newPwdConfirmationOnChange(e: string) {
        setNewPwdConfirmation(e);
    }

    function handleSubmitUpdatePwd() {
        if (submitBtnState === ButtonState.REGULAR) {
            dispatch(authenticationActions.updatePassword(oldPwd || "", newPwd || ""))
        } else {
            enqueueSnackbar("Assurez vous que le formulaire soit bien remplis et recommencez.", {variant: 'warning'})
        }
    }

    useEffect(() => {
        setOldPwdIsValid(oldPwd !== "")
    }, [oldPwd])

    useEffect(() => {
        setNewPwdIsValid(newPwd ? newPwd.length >= 8 && containsAllCases(newPwd) && containsSpecialChars(newPwd)  && containsDigit(newPwd): true)
    }, [newPwd])

    useEffect(() => {
        setNewPwdConfirmationIsValid(newPwd && newPwdConfirmation ? newPwd.normalize() === newPwdConfirmation.normalize() : true)
    }, [newPwdConfirmation, newPwd])


    useEffect(() => {
        if (oldPwdIsValid && newPwdIsValid && newPwdConfirmationIsValid && oldPwd && newPwd && newPwdConfirmation) {
            setSubmitBtnState(ButtonState.REGULAR)
        } else {
            setSubmitBtnState(ButtonState.DISABLED)
        }
    }, [oldPwdIsValid, newPwdIsValid, newPwdConfirmationIsValid, oldPwd, newPwd, newPwdConfirmation])


    return <div
        className={"w-full flex flex-col items-center bg-gray-100 font-sans overflow-auto h-full-minus-navbar w-full dark:bg-zinc-700"}>
        <div className={"scale-95 2xl:scale-100 max-w-[700px] flex flex-col h-full justify-evenly items-center"}>
            <div className={"w-full flex flex-col items-center "}>
                <TitleText title={"Modifier mon mot de passe"}/>
                <DescriptionText
                    description={"Il est recommandé de fournir un mot de passe comportant au moins 1 lettre, 1 numéro et 1 symbole. Le mot de passe est robuste lorsqu'il est long de 14 caractères."}/>

            </div>
            <div className={"px-4 box-border w-full"}>

                <TextInput label={"Ancien mot de passe"} placeholder={"*********"} id={"old-password"}
                           inputType={"password"}
                           value={oldPwd || ""}
                           onChange={oldPwdOnChange}
                           error={{
                               state: !oldPwdIsValid,
                               msg: oldPwdIsIncorrect ? "l'ancien mot de passe est incorrect" : "le champ est requis."
                           }}/>
                <hr className={" border-zinc-200 my-4 dark:border-zinc-600"}/>
                <TextInput label={"Nouveau mot de passe"} placeholder={"**************"} id={"new-password"}
                           inputType={"password"}
                           onChange={newPwdOnChange}
                           value={newPwd || ""}
                           error={{
                               state: !newPwdIsValid,
                               msg: "le nouveau mot de passe doit-être composé d'au moins 8 caractères et contenir au moins 1 majuscule, 1 minuscule, 1 chiffre et 1 caractère spécial (ex : Enogrid123!)"
                           }}/>
                <TextInput label={"Confirmer le nouveau mot de passe"} placeholder={"**************"}
                           id={"confirm-new-password"}
                           inputType={"password"}
                           value={newPwdConfirmation || ""}
                           onChange={newPwdConfirmationOnChange}
                           error={{
                               state: !newPwdConfirmationIsValid,
                               msg: "les deux mots de passe ne sont pas identiques."
                           }}/>
            </div>
            <div className={"px-4 box-border w-full"}>
                <RegularBtn action={handleSubmitUpdatePwd} state={submitBtnState}
                            title={"Mettre à jour le mot de passe"}
                            theme={Theme.DEFAULT}/>
            </div>

        </div>
    </div>
}
