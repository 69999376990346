import {useDispatch, useSelector} from "react-redux";
import {
    selectOrganisations, selectSelectedOrganisation,
} from "../../../../../../corelogic/usecases/authentication/authenticationSelector";
import ChevronDown from "../icon/ChevronDown";
import React, {useEffect, useState} from "react";
import {authenticationActions} from "../../../../../../corelogic/usecases/authentication/authenticationActions";
import {Organisation} from "../../../../../../corelogic/models/types/simulations-display/Organisation";
import {simulationsDisplayActions} from "../../../../../../corelogic/usecases/simulations-display/simulationsDisplayActions";
import { getSimulationsSaga } from "../../../../../../corelogic/usecases/simulations-display/simulationsDisplaySaga";

export default function OrganisationSelector() {
    const dispatch = useDispatch()
    const [openOrganisationMenu, setOpenOrganisationMenu] = useState<boolean>(false)
    const selectedOrganisation: Organisation = useSelector(selectSelectedOrganisation)
    const organisations = useSelector(selectOrganisations)
    useEffect(()=>{
        if(selectedOrganisation){
            const newSelectedOrganisation = organisations.find((orga:Organisation)=>orga.id === selectedOrganisation.id)
            dispatch(authenticationActions.setOrganisation(newSelectedOrganisation))
        }
        else if(organisations.length ===1){
            dispatch(authenticationActions.setOrganisation(organisations[0]))
        }
    },[organisations])

    function organisationOnChange(organisation: Organisation |null) {
        dispatch(authenticationActions.setOrganisation(organisation))
        dispatch(simulationsDisplayActions.getSimulations())
        dispatch(simulationsDisplayActions.setSimulationsCurrentPage(1))
        setOpenOrganisationMenu(false)
    }

    return <div className={"flex flex-col"}>
        <div onClick={organisations.length >1 ? () => setOpenOrganisationMenu(!openOrganisationMenu):()=>{}}
             className={organisations.length > 1 ?
                 "flex hover:cursor-pointer pr-1  mx-2 rounded-md items-center justify-between   bg-teal-400 hover:bg-teal-500 dark:bg-teal-700  dark:hover:bg-teal-600 text-white dark:text-slate-200  dark:hover:text-slate-100"
                 : "flex pr-1   rounded-md items-center justify-between  text-zinc-700 dark:text-slate-300  "
             }>
            <span className={organisations.length > 1 ?
                " py-1 px-3 rounded-md text-lg whitespace-nowrap font-semibold  "
                : " py-1 pl-2 rounded-md text-2xl whitespace-nowrap font-semibold  "}>{selectedOrganisation ? selectedOrganisation.name : "Toutes mes organisations"}</span>
            {organisations.length > 1 && <ChevronDown classname={"h-5 w-5"}/>}
        </div>
        {
            openOrganisationMenu  &&
            <div className={"relative h-0 top-2 z-40 "}>
                <div
                    onMouseLeave={() => setOpenOrganisationMenu(false)}
                    className={"font-semibold flex flex-col hover:cursor-pointer   mx-2 rounded-md items-center justify-between   bg-white shadow-lg dark:bg-zinc-700   text-white dark:text-zinc-300 "}>
                        <div
                            className={" hover:bg-slate-100 dark:hover:bg-zinc-500 rounded-md  w-full flex-col flex justify-center items-start"}
                            onClick={() => organisationOnChange(null)}>
                                            <span
                                                className={"px-4 py-2 text-sm  text-slate-600 dark:text-zinc-300  dark:hover:text-zinc-300"}>
                                                Toutes mes organisations
                                            </span>
                        </div>
                    {organisations.map((org: Organisation) => {
                            return <div
                                className={" hover:bg-slate-100 dark:hover:bg-zinc-500 rounded-md  w-full flex-col flex justify-center items-start"}
                                onClick={() => organisationOnChange(org)}
                                key={org.id}>
                                            <span
                                                className={"px-4 py-2 text-sm  text-slate-600 dark:text-zinc-300  dark:hover:text-zinc-300"}>
                                                {org.name}
                                            </span>
                            </div>
                        })
                    }
                </div>
            </div>
        }
    </div>
}
