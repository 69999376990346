import React, {useState} from "react";
import OutsideAlerter from "../../../wrapper/OutsideAlerter";
import FranceFlagIcon from "../../../../components/assets/icon/FranceFlagIcon";
import OtherFlagIcon from "../../../../components/assets/icon/OtherFlagIcon";
import ChevronDown from "../../../../components/assets/icon/ChevronDown";
import {country} from "./variable-vat-rate/VariableVATRate";
import {useDispatch, useSelector} from "react-redux";
import {selectFormVariableTaxCountry} from "../../../../../../../corelogic/usecases/form/formSelector";
import {formActions} from "../../../../../../../corelogic/usecases/form/formActions";

const VATRateCountrySelector = () => {
    const [expand, setExpand] = useState(false)
    const dispatch = useDispatch()
    const selectedCountry = useSelector(selectFormVariableTaxCountry)

    function handleClickOnCountry(countryClicked:country){
        if(countryClicked === country.FRANCE){
            dispatch(formActions.setVariableTaxFrance())
        } else {
            dispatch(formActions.setVariableTaxZNI())
        }
        dispatch(formActions.setFixedDefaultTax())
        setExpand(false)
    }

    return <OutsideAlerter clickOutsideAction={() => setExpand(false)} className={"w-min"}>
        <>
            <button onClick={() => setExpand(!expand)} id="states-button" data-dropdown-toggle="dropdown-states"
                    className="flex-shrink-0 w-[240px] justify-between  inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-500 bg-gray-100 border border-gray-300 rounded-l-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-zinc-700 dark:hover:bg-zinc-600 dark:focus:ring-zinc-700 dark:text-white dark:border-gray-600"
                    type="button">
                <FranceFlagIcon className={'h-4 w-4 mr-2'}/>
                <span className={"whitespace-nowrap"}>{selectedCountry}</span>
                <ChevronDown classname={"w-4 h-4 ml-2.5"}/>
            </button>
            {expand && <div className={"h-0 w-0 relative"}>
                <div id="dropdown-states"
                     className=" z-[99] relative flex flex-col gap-2 w-min   bg-white py-1 divide-y divide-gray-100 dark:divide-gray-600 rounded-lg shadow  dark:bg-zinc-700">
                    <FranceChoice onClick={() => handleClickOnCountry(country.FRANCE)}/>
                    <OtherChoice  onClick={() => handleClickOnCountry(country.OTHER)}/>
                </div>
            </div>}
        </>
    </OutsideAlerter>

}

const FranceChoice = ({onClick}: { onClick(): any }) =>
    <button type="button"
            onClick={onClick}
            className="inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white">
        <div className="inline-flex items-center">
            <FranceFlagIcon className={'h-4 w-4 mr-2'}/>
            <span className={"whitespace-nowrap text-sm text-gray-500  "}>Taxes classique : </span>
            <span className={"whitespace-nowrap pl-2"}> {country.FRANCE}</span>
        </div>
    </button>

const OtherChoice = ({onClick}: { onClick(): any }) =>
    <button type="button"
            onClick={onClick}
            className="inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white">
        <div className="inline-flex items-center">
            <FranceFlagIcon className={'h-4 w-4 mr-2'}/>
            <span className={"whitespace-nowrap text-sm text-gray-500  "}>Taxes classique : </span>
            <span className={"whitespace-nowrap pl-2 "}>{country.OTHER}</span>
        </div>
    </button>
export default VATRateCountrySelector
