import React from "react";
import VATCorseDomTomSvg from "../../utils/VATCorseDomTomSvg";
import VATCorseDomTomDarkSvg from "../../utils/VATCorseDomTomDarkSvg";
import {useSelector} from "react-redux";
import {selectIsDarkMode} from "../../../../../corelogic/usecases/config/configSelector";

export default function () {
    const isDarkMode = useSelector(selectIsDarkMode)

    return <div
        className=" absolute z-50 right-0 bottom-[300px] max-w-full h-0 w-0  mt-2  shadow-lg  hover:cursor-default "
        role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex={-1}>
        <div className="py-1 z-[1] w-[700px] bg-white text-gray-700 dark:bg-zinc-800 dark:text-zinc-200 rounded-md"
             role="none">
            <span
                className={" block w-full text-left px-4 pt-2 text-md font-semibold"}>TVA fixe et variable</span>
            <hr className="my-3 w-full"/>
            <div className={"flex flex-row justify-center "}>
                <div className={"flex flex-col"}>
                <span className={"whitespace-pre-line  block w-full text-left px-4 pt-2 text-xs"}>
                    La TVA sur la part variable correspond à la taxe sur la valeur ajoutée qui s'applique sur  l'électron, la part variable de TURPE et l'accise.
                    Elle vaut 20% en France.
                </span>
                    <span className={"whitespace-pre-line block w-full text-left px-4  text-xs"}>
                        La TVA sur la part fixe est la taxe sur la valeur ajoutée de la part fixe du TURPE et de la majoration de TURPE.
                        Elle est fixée à 5.5%.</span>

                    <span className={"whitespace-pre-line  block w-full text-left px-4 pt-2 text-sm font-semibold"}>
                   TVA pour la Corse et les DOM-TOM
                </span>
                    <div className={"px-2"}>
                        {isDarkMode ? <VATCorseDomTomDarkSvg/> : <VATCorseDomTomSvg/>}

                    </div>
                </div>
            </div>
        </div>
    </div>
}
