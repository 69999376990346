export enum ConsumerType {
    individual='Particulier',
    professional='Professionnel'
}
export function  consumerTypeFromObject(consumerType: string): ConsumerType  {
    switch (consumerType) {
        case 'professional':
            return ConsumerType['professional'];
        case 'individual':
            return ConsumerType['individual'];
        default:
            throw new Error('consumer type null')
    }
}
