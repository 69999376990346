import React from "react";

export default function () {
    return <svg width="32" height="32" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M36.75 24.5V33.25C36.75 34.1783 36.3813 35.0685 35.7249 35.7249C35.0685 36.3813 34.1783 36.75 33.25 36.75H8.75C7.82174 36.75 6.9315 36.3813 6.27513 35.7249C5.61875 35.0685 5.25 34.1783 5.25 33.25V8.75C5.25 7.82174 5.61875 6.9315 6.27513 6.27513C6.9315 5.61875 7.82174 5.25 8.75 5.25H17.5V8.75H8.75V33.25H33.25V24.5H36.75Z"
            fill="white"/>
        <path d="M36.75 12.25H29.75V5.25H26.25V12.25H19.25V15.75H26.25V22.75H29.75V15.75H36.75V12.25Z"
              fill="white"/>
    </svg>
}