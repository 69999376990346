import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import SpinningSVG from "../../components/assets/Animated/SpinningSVG";


export default function FormPopup ({action, isLoading, actionText}:{action():void,isLoading : boolean, actionText?:string}) {
    const [maxHeight, setMaxHeight] = useState(" max-h-0 p-0 ")
    useEffect(() => {
        setMaxHeight(" max-h-32 p-2 ")
    }, [])
    const colorStyle = isLoading ?  ' bg-purple-100 text-purple-800 dark:bg-purple-900 dark:bg-opacity-20 dark:text-purple-300  ' : 'bg-slate-200 text-slate-700 dark:bg-slate-700 dark:text-slate-300  '

    const title = isLoading ? 'chargement en cours...' : 'Nouvelles modifications'
    return <div className={"h-0"}>
        <div
            className={maxHeight + "relative top-0 z-50 transition-expand-height p-2 whitespace-nowrap flex items-center " + colorStyle}>
            <span className={" px-4 uppercase  font-semibold text-xs  tracking-widest"}>{title}</span>
            <span className={"w-full"}/>
            <span onClick={action}
                  className={"text-slate-700 font-semibold text-xs leading-5 hover:cursor-pointer hover:text-slate-800 hover:bg-slate-100 p-1 px-2 rounded-md  dark:text-slate-300 dark:hover:bg-slate-800"}>
                {isLoading ? <SpinningSVG/> : actionText || 'Cliquez ici pour sauvegarder le formulaire'}
            </span>
        </div>
    </div>
}
