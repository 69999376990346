import {combineReducers} from "redux";
import {
    ENABLE_ACI,
    ENABLE_HPHC,
    formActionsType,
    INIT_SGE_FORM,
    RESET_EDIT_POINT,
    RESET_LOW_COMPLETUDE_FORM,
    SET_ACC_NUMBER,
    SET_ACCOMMODATION_TYPE,
    SET_ACI_POINT_ID,
    SET_ADDRESS,
    SET_BEST_CIRCLE_PLACE,
    SET_CARTO_CIRCLE_IN_KM,
    SET_CDC_SAVED_CONFIG,
    SET_CDC_UPLOAD_OPTION,
    SET_CLEAR_FORM,
    SET_CONSUMER_TYPE,
    SET_EDIT_POINT,
    SET_EDIT_POINT_INDEX,
    SET_FULL_COMPLETUDE_ACTION,
    SET_GENERATED_KWH_PRICE,
    SET_HC_ETE_PRIX,
    SET_HC_HIVER_PRIX,
    SET_HC_PRIX,
    SET_HEATING_TYPE,
    SET_HEURE_CREUSE,
    SET_HEURE_PLEINE,
    SET_HP_ETE_PRIX,
    SET_HP_HIVER_PRIX,
    SET_HP_PRIX,
    SET_IS_VAT_RECOVERABLE,
    SET_LOW_COMPLETUDE_TABLE,
    SET_LOW_END_DATE_DAYS,
    SET_LOW_END_DATE_MONTHS,
    SET_LOW_END_DATE_YEARS,
    SET_LOW_START_DATE_DAYS,
    SET_LOW_START_DATE_MONTHS,
    SET_LOW_START_DATE_YEARS,
    SET_LOW_VOLUME,
    SET_NAME,
    SET_RECALAGE_BUTTON_ACTION,
    SET_RECALAGE_INPUT,
    SET_SEGMENT,
    SET_SGE_DELAY,
    SET_SGE_END_DATE_DAYS,
    SET_SGE_END_DATE_MONTHS,
    SET_SGE_END_DATE_YEARS,
    SET_SGE_FETCH_IF_HOLES,
    SET_SGE_FORM_TAB,
    SET_SGE_NUMBER_OF_SUCCESFULL_WEEKS,
    SET_SGE_PRM,
    SET_SGE_RETRIES,
    SET_SGE_START_DATE_DAYS,
    SET_SGE_START_DATE_MONTHS,
    SET_SGE_START_DATE_YEARS,
    SET_SGE_SUCCESS_RATE,
    SET_SGE_WEEKLY_RESULT,
    SHOW_DENSITY,
    SHOW_LOW_COMPLETENESS_FORM,
    SORT_LOW_COMPLETUDE_TABLE
} from "./typologyFormActions";
import {HeatingType} from "../../models/types/new-simulation/form/HeatingType";
import {AccommodationType} from "../../models/types/new-simulation/form/AccommodationType";
import {SegmentDetailed} from "../../models/types/new-simulation/form/SegmentDetailed";
import {ConsumptionPoint} from "../../models/types/new-simulation/form/ConsumptionPoint";
import {InjectionPoint} from "../../models/types/new-simulation/form/InjectionPoint";
import {ConsumerType} from "../../models/types/new-simulation/form/ConsumerType";
import {YEAR_N_MOINS_UN} from "../../../config/app-config";
import LowCompletudeDate
    from "../../../adapters/primary/ui/composition/form/load-curve-upload/completude-and-recalage-button/low-completeness/form/table/LowCompletudeDate";
import LowCompletudeInput, {
    sortLowCompletudeTableByDesc
} from "../../../adapters/primary/ui/composition/form/load-curve-upload/completude-and-recalage-button/low-completeness/form/table/LowCompletudeInput";

export type SGEFetchedCDCStatus = {
    from : string,
    to : string,
    error : {
        message : string,
        code : string
    },
    retries : string
}

type cdcReducerType = {
    uploadOption: 'ENEDIS' | 'PVGIS' | 'UPLOAD' | 'SGE' | null
    recalageButton: { form: boolean, input: string | null },
    lowCompletenessButton : {
        tableInput : LowCompletudeInput[],
        show : boolean
    },
    fullCompletudeButton: boolean,
    sge: {
        isFormSelected: boolean,
        form: {
            prm: string,
            retries: string,
            delay: string,
            fetchIfHoles: boolean,
            dateStart: { days: string, months: string, years: string },
            dateEnd: { days: string, months: string, years: string },
        },
        CDCSavedConfig: {
            prm?: string,
            dateStart?: string,
            dateEnd?: string,
            retries?: string,
            delay?: string,
            fetchIfHoles?: boolean
        },
        failuresByWeeks : SGEFetchedCDCStatus[],
        successfullResponseNumber : number,
        successRate: number,
    }
}
export const initialCDCReducerType: cdcReducerType = {
    uploadOption: null,
    recalageButton: {
        form: false,
        input: null
    },
    lowCompletenessButton : {
        tableInput : [new LowCompletudeInput(
            new LowCompletudeDate('','',''),
            new LowCompletudeDate('','',''),
            '')],
        show : false
    },
    fullCompletudeButton: false,
    sge: {
        isFormSelected: true,
        form: {
            prm: '',
            retries: '2',
            delay: '3',
            fetchIfHoles: false,
            dateStart: {
                days: '01',
                months: '01',
                years: YEAR_N_MOINS_UN()
            },
            dateEnd: {
                days: '31',
                months: '12',
                years: YEAR_N_MOINS_UN()
            }
        },
        CDCSavedConfig: {},
        failuresByWeeks : [],
        successfullResponseNumber : 0,
        successRate: 0,
    }
}

const cdcFormReducer = (state = initialCDCReducerType, action: formActionsType) => {
    switch (action.type) {
        case SORT_LOW_COMPLETUDE_TABLE: return {
            ...state, lowCompletenessButton: {
                ...state.lowCompletenessButton, tableInput: sortLowCompletudeTableByDesc(state.lowCompletenessButton.tableInput)
            }
        }
        case SET_LOW_COMPLETUDE_TABLE : return {
            ...state, lowCompletenessButton: {
                show : state.lowCompletenessButton.show,
                tableInput : action.payload
            }
        }
        case RESET_LOW_COMPLETUDE_FORM : return {
            ...state, lowCompletenessButton: initialCDCReducerType.lowCompletenessButton
        }
        case SET_LOW_VOLUME : {
            const {index, volume} = action.payload
            const newTable = [...state.lowCompletenessButton.tableInput]
            newTable[index].value = volume

            return {
                ...state, lowCompletenessButton : {
                    ...state.lowCompletenessButton, tableInput : newTable
                }
            }
        }
        case SET_LOW_END_DATE_YEARS : {
            const {index, years} = action.payload
            const newTable = [...state.lowCompletenessButton.tableInput]
            newTable[index].dateEnd.years = years

            return {
                ...state, lowCompletenessButton : {
                    ...state.lowCompletenessButton, tableInput : newTable
                }
            }
        }
        case SET_LOW_END_DATE_MONTHS : {
            const {index, months} = action.payload
            const newTable = [...state.lowCompletenessButton.tableInput]
            newTable[index].dateEnd.months = months

            return {
                ...state, lowCompletenessButton : {
                    ...state.lowCompletenessButton, tableInput : newTable
                }
            }
        }
        case SET_LOW_END_DATE_DAYS : {
            const {index, days} = action.payload
            const newTable = [...state.lowCompletenessButton.tableInput]
            newTable[index].dateEnd.days = days

            return {
                ...state, lowCompletenessButton : {
                    ...state.lowCompletenessButton, tableInput : newTable
                }
            }
        }

        case SET_LOW_START_DATE_YEARS : {
            const {index, years} = action.payload
            const newTable = [...state.lowCompletenessButton.tableInput]
            newTable[index].dateStart.years = years

            return {
                ...state, lowCompletenessButton : {
                    ...state.lowCompletenessButton, tableInput : newTable
                }
            }
        }
        case SET_LOW_START_DATE_MONTHS : {
            const {index, months} = action.payload
            const newTable = [...state.lowCompletenessButton.tableInput]
            newTable[index].dateStart.months = months

            return {
                ...state, lowCompletenessButton : {
                    ...state.lowCompletenessButton, tableInput : newTable
                }
            }
        }
        case SET_LOW_START_DATE_DAYS : {
            const {index, days} = action.payload
            const newTable = [...state.lowCompletenessButton.tableInput]
            newTable[index].dateStart.days = days

            return {
                ...state, lowCompletenessButton : {
                    ...state.lowCompletenessButton, tableInput : newTable
                }
            }
        }

        case INIT_SGE_FORM : return {
            ...state, sge : {
                ...state.sge, form : initialCDCReducerType.sge.form
            }
        }
        case SET_SGE_SUCCESS_RATE : return {
            ...state, sge : {
                ...state.sge, successRate : action.payload
            }
        }
        case SET_SGE_NUMBER_OF_SUCCESFULL_WEEKS : return {
            ...state, sge : {
                ...state.sge, successfullResponseNumber : action.payload
            }
        }
        case SET_SGE_WEEKLY_RESULT : return {
            ...state, sge : {
                ...state.sge, failuresByWeeks : action.payload
            }
        }
        case SET_SGE_FETCH_IF_HOLES:
            return {
                ...state, sge: {
                    ...state.sge, form: {
                        ...state.sge.form, fetchIfHoles: action.payload
                    }
                }
            }
        case SET_SGE_DELAY:
            return {
                ...state, sge: {
                    ...state.sge, form: {
                        ...state.sge.form, delay: action.payload
                    }
                }
            }
        case SET_SGE_RETRIES :
            return {
                ...state, sge: {
                    ...state.sge, form: {
                        ...state.sge.form, retries: action.payload
                    }
                }
            }
        case SET_SGE_FORM_TAB :
            return {
                ...state, sge: {
                    ...state.sge, isFormSelected: action.payload
                }
            }
        case SET_CDC_SAVED_CONFIG :
            return {
                ...state, sge: {
                    ...state.sge, CDCSavedConfig: action.payload
                }
            }
        case SET_SGE_END_DATE_DAYS :
            return {
                ...state, sge: {
                    ...state.sge, form: {
                        ...state.sge.form, dateEnd: {
                            ...state.sge.form.dateEnd, days: action.payload
                        }
                    }
                }
            }
        case SET_SGE_END_DATE_MONTHS :
            return {
                ...state, sge: {
                    ...state.sge, form: {
                        ...state.sge.form, dateEnd: {
                            ...state.sge.form.dateEnd, months: action.payload
                        }
                    }
                }
            }
        case SET_SGE_END_DATE_YEARS :
            return {
                ...state, sge: {
                    ...state.sge, form: {
                        ...state.sge.form, dateEnd: {
                            ...state.sge.form.dateEnd, years: action.payload
                        }
                    }
                }
            }

        case SET_SGE_START_DATE_DAYS :
            return {
                ...state, sge: {
                    ...state.sge, form: {
                        ...state.sge.form, dateStart: {
                            ...state.sge.form.dateStart, days: action.payload
                        }
                    }
                }
            }
        case SET_SGE_START_DATE_MONTHS :
            return {
                ...state, sge: {
                    ...state.sge, form: {
                        ...state.sge.form, dateStart: {
                            ...state.sge.form.dateStart, months: action.payload
                        }
                    }
                }
            }
        case SET_SGE_START_DATE_YEARS :
            return {
                ...state, sge: {
                    ...state.sge, form: {
                        ...state.sge.form, dateStart: {
                            ...state.sge.form.dateStart, years: action.payload
                        }
                    }
                }
            }
        case SET_SGE_PRM :
            return {
                ...state, sge: {
                    ...state.sge, form: {
                        ...state.sge.form, prm: action.payload
                    }
                }
            }
        case SET_CDC_UPLOAD_OPTION:
            return {...state, uploadOption: action.payload};
        case SHOW_LOW_COMPLETENESS_FORM :
            return {
                ...state, lowCompletenessButton : {
                    ...state.lowCompletenessButton, show : action.payload
                }
            }
        case SET_RECALAGE_BUTTON_ACTION:
            return {
                ...state, recalageButton: {
                    ...state.recalageButton, form: action.payload
                }
            }
        case SET_FULL_COMPLETUDE_ACTION:
            return {...state, fullCompletudeButton: action.payload}
        case SET_RECALAGE_INPUT:
            return {
                ...state, recalageButton: {
                    ...state.recalageButton, input: action.payload
                }
            }
        default:
            return state;
    }
}

type consumptionFormReducerType = {
    heatingType: HeatingType,
    accommodationType: AccommodationType,
    segment: SegmentDetailed,
    name: string,
    generatedKwhPrice: string,
    accNumber: string,
    consumerType: ConsumerType,
    isVATRecoverable: boolean,
    ACIPointId: null | string,
    enableACI: boolean,
    HPHC: {
        enable: boolean,
        generatedKwhPrice: string,
        heurePleine: string,
        heureCreuse: string,
        HCPrix: string,
        HPPrix: string,
        HCHiverPrix: string,
        HPHiverPrix: string,
        HCEtePrix: string,
        HPEtePrix: string
    }
}
export const initialStateconsumptionFormReducer: consumptionFormReducerType = {
    heatingType: HeatingType.electricity,
    accommodationType: AccommodationType.T1,
    segment: SegmentDetailed.C5_CU,
    name: "",
    generatedKwhPrice: "",
    accNumber: "1",
    consumerType: ConsumerType.individual,
    isVATRecoverable: false,
    ACIPointId: null,
    enableACI: false,
    HPHC: {
        enable: false,
        generatedKwhPrice: "",
        heurePleine: "06:00:00",
        heureCreuse: "22:00:00",
        HCPrix: "",
        HPPrix: "",
        HCHiverPrix: "",
        HPHiverPrix: "",
        HCEtePrix: "",
        HPEtePrix: ""
    }
}

const consumptionFormReducer = (state = initialStateconsumptionFormReducer, action: formActionsType) => {
    switch (action.type) {
        case SET_HEATING_TYPE:
            return {...state, heatingType: action.payload};
        case SET_ACCOMMODATION_TYPE:
            return {...state, accommodationType: action.payload};
        case SET_SEGMENT:
            return {...state, segment: action.payload};
        case SET_NAME:
            return {...state, name: action.payload};
        case SET_ACC_NUMBER:
            return {...state, accNumber: action.payload};
        case SET_CONSUMER_TYPE:
            return {...state, consumerType: action.payload};
        case SET_IS_VAT_RECOVERABLE:
            return {...state, isVATRecoverable: action.payload};
        case SET_ACI_POINT_ID:
            return {...state, ACIPointId: action.payload};
        case ENABLE_ACI :
            return {...state, enableACI: action.payload};
        case ENABLE_HPHC :
            return {
                ...state, HPHC: {
                    ...state.HPHC, enable: action.payload
                }
            }

        case SET_HC_PRIX :
            return {
                ...state, HPHC: {
                    ...state.HPHC, HCPrix: action.payload
                }
            }

        case SET_GENERATED_KWH_PRICE:
            return {
                ...state, HPHC: {
                    ...state.HPHC, generatedKwhPrice: action.payload
                }
            }
        case SET_HP_PRIX :
            return {
                ...state, HPHC: {
                    ...state.HPHC, HPPrix: action.payload
                }
            }

        case SET_HC_HIVER_PRIX :
            return {
                ...state, HPHC: {
                    ...state.HPHC, HCHiverPrix: action.payload
                }
            }

        case SET_HC_ETE_PRIX :
            return {
                ...state, HPHC: {
                    ...state.HPHC, HCEtePrix: action.payload
                }
            }

        case SET_HP_HIVER_PRIX :
            return {
                ...state, HPHC: {
                    ...state.HPHC, HPHiverPrix: action.payload
                }
            }

        case SET_HP_ETE_PRIX :
            return {
                ...state, HPHC: {
                    ...state.HPHC, HPEtePrix: action.payload
                }
            }

        case SET_HEURE_PLEINE :
            return {
                ...state, HPHC: {
                    ...state.HPHC, heurePleine: action.payload
                }
            }

        case SET_HEURE_CREUSE :
            return {
                ...state, HPHC: {
                    ...state.HPHC, heureCreuse: action.payload
                }
            }

        default:
            return state;
    }
}

type TypologyTypeState = {
    editPoint: { pointEdited: InjectionPoint | ConsumptionPoint | null, index: number | null },
    clear: boolean,
    address: string,
    cartographie: {
        showDensityMap: boolean,
        circleInKm: number,
        optimizedCircle: {
            lng: number,
            lat: number,
        } | null
    }
}
const initialTypologyReducer: TypologyTypeState = {
    editPoint: {
        pointEdited: null,
        index: null
    },
    clear: false,
    address: '',
    cartographie: {
        showDensityMap: false,
        circleInKm: 2,
        optimizedCircle: null
    }
}
const typologyFormReducer = (state = initialTypologyReducer, action: formActionsType) => {
    switch (action.type) {
        case SET_BEST_CIRCLE_PLACE :
            return {...state, cartographie: {...state.cartographie, optimizedCircle: action.payload}}
        case SHOW_DENSITY :
            return {...state, cartographie: {...state.cartographie, showDensityMap: action.payload}}
        case SET_CARTO_CIRCLE_IN_KM :
            return {...state, cartographie: {...state.cartographie, circleInKm: action.payload}}
        case RESET_EDIT_POINT :
            return {
                ...state, editPoint: {pointEdited: null, index: null}
            }
        case SET_EDIT_POINT : {
            return {
                ...state, editPoint: {
                    ...state.editPoint,
                    pointEdited: action.payload
                }
            }
        }
        case SET_EDIT_POINT_INDEX : {
            return {
                ...state, editPoint: {
                    ...state.editPoint,
                    index: action.payload
                }
            }
        }
        case SET_CLEAR_FORM : {
            return {...state, clear: action.payload}
        }
        case SET_ADDRESS : {
            return {...state, address: action.payload}
        }
        default:
            return state;
    }
}

export default combineReducers({
    cdcForm: cdcFormReducer,
    consumptionForm: consumptionFormReducer,
    typology: typologyFormReducer
});
