import TitleText from "../../../components/assets/text/TitleText";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {newSimulationActions} from "../../../../../../corelogic/usecases/new-simulation/newSimulationActions";
import {
    selectClosingForm,
    selectSavingForm
} from "../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-action/selectFormAction";
import {
    selectNextStep,
    selectSteps
} from "../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/steps/selectSteps";
import Distributions from "./distribution/Distributions";
import Seasonalities from "./seasonality/Seasonalities";
import {
    selectOperationParameters, selectSeasonalParameters
} from "../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectOperationParameter";
import {
    selectConsumptionPoints
} from "../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectConsumptionPointForm";
import {Step} from "../../../../../../corelogic/models/types/new-simulation/steps/Step";
import {
    OperationParameters
} from "../../../../../../corelogic/models/types/new-simulation/form/operation-parameters/OperationParameters";
import {ButtonState} from "../../../../../../config/app-config";
import {
    selectDistributionAlgorithmForm,
    selectDistributionPrioritiesForm,
    selectDistributionSharesForm, selectSeasonalityForm, selectDistributionUngroupedPrioritiesForm,
} from "../../../../../../corelogic/usecases/operation-parameters-form/operationParametersFormSelector";
import {
    operationParametersFormActions
} from "../../../../../../corelogic/usecases/operation-parameters-form/operationParametersFormActions";
import ConfirmModal from "../../confirmation-dialog/ConfirmModal";
import SurplusAllocationForm from "./surplus_allocation/SurplusAllocationForm";
import Divider from "../../../components/assets/Divider";
import useSurplusAllocation
    from "../../../../../../corelogic/usecases/operation-parameters-form/surplus_allocation/useSurplusAllocation";
import {
    seasonalityUtils
} from "../../../../../../corelogic/usecases/operation-parameters-form/seasonality/seasonality.utils";
import useSeasonalityVisibility
    from "../../../../../../corelogic/usecases/operation-parameters-form/seasonality/useSeasonalityVisibility";
import {
    Seasonality
} from "../../../../../../corelogic/models/types/new-simulation/form/operation-parameters/seasonality/Seasonality";
import useSeasonalityRetrieve
    from "../../../../../../corelogic/usecases/operation-parameters-form/seasonality/useSeasonalityRetrieve";

export default function OperationParametersForm() {
    const closingForm = useSelector(selectClosingForm)
    const saveForm = useSelector(selectSavingForm)
    const consumptionPoints = useSelector(selectConsumptionPoints)
    const nextStep = useSelector(selectNextStep)
    const dispatch = useDispatch()
    const steps = useSelector(selectSteps)
    const selectedAlgorithm = useSelector(selectDistributionAlgorithmForm)
    const distributionPriorities = useSelector(selectDistributionPrioritiesForm)
    const distributionShares = useSelector(selectDistributionSharesForm)

    const oldSelectedAlgorithm = useSelector(selectOperationParameters)
    const oldOperationParameters = useSelector(selectOperationParameters)
    const oldSeasonality: Seasonality | null = useSelector(selectSeasonalParameters)

    const surplusAllocation = useSurplusAllocation()

    const seasonality = useSelector(selectSeasonalityForm)
    useSeasonalityRetrieve()
    const seasonalityVisibility = useSeasonalityVisibility()
    const distributionByUngroupedPriority = useSelector(selectDistributionUngroupedPrioritiesForm)

    useEffect(() => {
        dispatch(operationParametersFormActions.init())
    }, [oldSelectedAlgorithm, consumptionPoints]);

    useEffect(() => {
        const currentOperationParameters = new OperationParameters(selectedAlgorithm, distributionPriorities, distributionShares, seasonality, surplusAllocation.value, distributionByUngroupedPriority)
        dispatch(newSimulationActions.setFormIsValid(currentOperationParameters.isValid()))
        if (currentOperationParameters.isValid()) {
            if (oldOperationParameters) {
                dispatch(newSimulationActions.setHasNewChanges(surplusAllocation.hasChanged() || seasonalityUtils.hasChanged(seasonality, oldSeasonality) || oldOperationParameters.isDifferentFrom(currentOperationParameters)))
            } else dispatch(newSimulationActions.setHasNewChanges(true))
        } else {
            dispatch(newSimulationActions.setHasNewChanges(false))
        }
    }, [oldOperationParameters, selectedAlgorithm, distributionPriorities, distributionShares, seasonality, surplusAllocation.value, surplusAllocation.hasChanged, distributionByUngroupedPriority]);

    useEffect(() => {
        if (saveForm) {
            if (selectedAlgorithm) {
                dispatch(newSimulationActions.setOperationParameters(new OperationParameters(selectedAlgorithm, distributionPriorities, distributionShares, seasonality, surplusAllocation.value, distributionByUngroupedPriority)))
            }
            dispatch(newSimulationActions.setSavingForm(false))
        }
    }, [saveForm])

    useEffect(() => {
        if (closingForm) {
            let stepsChange: Step[] = steps
            const currentOperationParameters = new OperationParameters(selectedAlgorithm, distributionPriorities, distributionShares, seasonality, surplusAllocation.value, distributionByUngroupedPriority)
            if(currentOperationParameters.isValid()) {
                if (!oldOperationParameters || surplusAllocation.hasChanged() || seasonalityUtils.hasChanged(seasonality, oldSeasonality) || oldOperationParameters.isDifferentFrom(currentOperationParameters)) {
                    dispatch(newSimulationActions.setOperationParameters(new OperationParameters(selectedAlgorithm, distributionPriorities, distributionShares, seasonality, surplusAllocation.value, distributionByUngroupedPriority)))
                    stepsChange[steps[3].index] = new Step(steps[3].title, [selectedAlgorithm], ButtonState.REGULAR, steps[3].index)
                } else {
                    stepsChange[steps[3].index] = new Step(steps[3].title, ["étape 4/5"], ButtonState.DISABLED, steps[3].index)
                }
            }
            dispatch(newSimulationActions.setSteps(stepsChange))
            dispatch(newSimulationActions.setCurrentStepIndex(nextStep.index))
            dispatch(newSimulationActions.setClosingForm(false))
        }
    }, [closingForm])

    return <div className="w-full h-full flex flex-col bg-gray-100 font-sans overflow-auto dark:bg-zinc-700">
        {
            seasonalityVisibility.openConfirmUntoggle && <ConfirmModal
                title={"Enlever la participation saisonnière"}
                description={"Attention, en confirmant d'enlever la participation saisonnière, vous perdrez cette configuration de saisonnalité. Voulez vous continuer ?"}
                actionContinue={() => seasonalityVisibility.confirmUntoggle()}
                actionCancel={() => seasonalityVisibility.cancelUntoggle()}
            />
        }
            <div className="mx-4 2xl:mx-8 scale-95 2xl:scale-100">
                <TitleText title={"Paramètres d'opération"}/>
                <div className="flex flex-col 2xl:flex-row">
                    <form className="w-full lg:max-w-[920px] 2xl:max-w-[1200px]">
                        <div className="flex flex-col flex-wrap m-4">
                            <Seasonalities seasonalityVisibility={seasonalityVisibility} />
                            <Distributions isSurplusAlgorithmByPriority={surplusAllocation.isByPriority}/>
                            <Divider />
                            <SurplusAllocationForm {...surplusAllocation}/>
                        </div>
                    </form>
                </div>
            </div>
        </div>

}

