export class Saving {
    private readonly _collectiveAcFirstYearSavingByUnit: number;
    private readonly _collectiveAcFirstYearSaving: number;
    private readonly _collectiveAcSaving: number;
    private readonly _individualAcFirstYearSaving: number;
    private readonly _acFirstYearSaving: number;
    private readonly _acSaving: number;
    private readonly _name: string;
    private readonly _number: number;

    private readonly _individualAcLastYearSaving: number | null;
    private readonly _internalBillLastYear: number | null;
    private readonly _internalBillFirstYear: number | null;
    private readonly _consumerGainFirstYear: number | null;
    private readonly _consumerGainLastYear: number | null;
    private readonly _gifted_energy_first_year_saving_by_unit: number | null;
    private readonly _gifted_energy_first_year_saving: number | null;
    private readonly _gifted_energy_saving: number | null;


    static fromObject({
                          collectiveAcFirstYearSavingByUnit,
                          collectiveAcFirstYearSaving,
                          collectiveAcSaving,
                          individualAcFirstYearSaving,
                          acFirstYearSaving,
                          acSaving,
                          name,
                          number,
                          individualAcLastYearSaving,
                          internalBillFirstYear,
                          internalBillLastYear,
                          consumerGainFirstYear,
                          consumerGainLastYear,
                          gifted_energy_first_year_saving,
                          gifted_energy_first_year_saving_by_unit,
                          gifted_energy_saving
                      }:
                          {
                              collectiveAcFirstYearSavingByUnit: number, collectiveAcFirstYearSaving: number, collectiveAcSaving: number, individualAcFirstYearSaving: number,
                              acFirstYearSaving: number, acSaving: number, name: string, number: number, individualAcLastYearSaving: number, internalBillFirstYear: number, internalBillLastYear: number, consumerGainFirstYear: number, consumerGainLastYear: number, gifted_energy_first_year_saving: number, gifted_energy_first_year_saving_by_unit: number,
                              gifted_energy_saving: number
                          }) {
        return new this(collectiveAcFirstYearSavingByUnit, collectiveAcFirstYearSaving, collectiveAcSaving, individualAcFirstYearSaving, acFirstYearSaving, acSaving, name, number, individualAcLastYearSaving, internalBillFirstYear, internalBillLastYear, consumerGainFirstYear, consumerGainLastYear, gifted_energy_first_year_saving, gifted_energy_first_year_saving_by_unit, gifted_energy_saving)
    }


    constructor(collectiveAcFirstYearSavingByUnit: number, collectiveAcFirstYearSaving: number, collectiveAcSaving: number, individualAcFirstYearSaving: number,
                acFirstYearSaving: number, acSaving: number, name: string, number: number, individualAcLastYearSaving?: number, internalBillFirstYear?: number, internalBillLastYear?: number, consumerGainFirstYear?: number, consumerGainLastYear?: number, gifted_energy_first_year_saving?: number, gifted_energy_first_year_saving_by_unit?: number, gifted_energy_saving?: number) {
        this._collectiveAcFirstYearSavingByUnit = collectiveAcFirstYearSavingByUnit;
        this._collectiveAcFirstYearSaving = collectiveAcFirstYearSaving;
        this._collectiveAcSaving = collectiveAcSaving;
        this._individualAcFirstYearSaving = individualAcFirstYearSaving;
        this._acFirstYearSaving = acFirstYearSaving;
        this._acSaving = acSaving;
        this._name = name;
        this._number = number;
        this._individualAcLastYearSaving = individualAcLastYearSaving || null;
        this._internalBillFirstYear = internalBillFirstYear || null;
        this._internalBillLastYear = internalBillLastYear || null;
        this._consumerGainFirstYear = consumerGainFirstYear || null;
        this._consumerGainLastYear = consumerGainLastYear || null;
        this._gifted_energy_first_year_saving = gifted_energy_first_year_saving || null;
        this._gifted_energy_saving = gifted_energy_saving || null;
        this._gifted_energy_first_year_saving_by_unit = gifted_energy_first_year_saving_by_unit || null;
    }

    get giftedEnergyFirstYearSavingByUnit(): number | null {
        return this._gifted_energy_first_year_saving_by_unit;
    }

    get giftedEnergyFirstYearSaving(): number | null {
        return this._gifted_energy_first_year_saving;
    }

    get giftedEnergySaving(): number | null {
        return this._gifted_energy_saving;
    }

    get collectiveAcSaving(): number {
        return this._collectiveAcSaving;
    }

    get internalBillLastYear(): number | null {
        return this._internalBillLastYear;
    }

    get internalBillFirstYear(): number | null {
        return this._internalBillFirstYear;
    }

    get consumerGainFirstYear(): number | null {
        return this._consumerGainFirstYear;
    }

    get consumerGainLastYear(): number | null {
        return this._consumerGainLastYear;
    }

    get individualAcLastYearSaving(): number | null {
        return this._individualAcLastYearSaving;
    }

    get collectiveAcFirstYearSavingByUnit(): number {
        return this._collectiveAcFirstYearSavingByUnit;
    }

    get collectiveAcFirstYearSaving(): number {
        return this._collectiveAcFirstYearSaving;
    }

    get individualAcFirstYearSaving(): number {
        return this._individualAcFirstYearSaving;
    }

    get acFirstYearSaving(): number {
        return this._acFirstYearSaving;
    }

    get acSaving(): number {
        return this._acSaving;
    }

    get name(): string {
        return this._name;
    }

    get number(): number {
        return this._number;
    }
}