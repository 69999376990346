import {createSelector} from "reselect";
import {selectTypologyInfo, sortData} from "../form/newSimulationSelector";

const selectConsumptionPoints = createSelector(
    selectTypologyInfo,
    typology => typology && typology.consumptionPoints ? typology.consumptionPoints : []
);
const selectConsumptionPointsNames = createSelector(
    selectConsumptionPoints,
    consumptionPoints => consumptionPoints.map((point) => point.name)
);
const selectConsumptionPointsHaveDuplicatedNames = createSelector(
    selectConsumptionPointsNames,
    consumptionPointsNames => (new Set(consumptionPointsNames)).size !== consumptionPointsNames.length
);

const makeSelectConsumptionPointsSorted = (sorting: { column: string, asc: boolean }) => createSelector(
    selectConsumptionPoints,
    (points) => sortData(points, sorting)
)


const selectAciProducers = createSelector(
    selectConsumptionPoints,
    consumptionPoints => consumptionPoints.reduce((set, curr) => {
        if (curr.injectionPointId) {
            set.add(curr.injectionPointId)
        }
        return set
    }, new Set())
)


const selectActiveSoutiragePoints = createSelector(
    selectConsumptionPoints,
    (points) => points.filter((point) => point.state))

const selectInactiveSoutiragePoints = createSelector(
    selectConsumptionPoints,
    (points) => points.filter((point) => !point.state))


const selectActiveConsumptionPointsWithAddressFilled = createSelector(
    selectActiveSoutiragePoints,
    (activePoint) => activePoint.filter((activePoint) => activePoint.address !== null)
)
const selectActiveACIConsumptionPointsWithAddressFilled = createSelector(
    selectActiveConsumptionPointsWithAddressFilled,
    (points) => points.filter((point) => point.injectionPointId !== null)
)
const selectActiveACCConsumptionPointsWithAddressFilled = createSelector(
    selectActiveConsumptionPointsWithAddressFilled,
    (points) => points.filter((point) => point.injectionPointId === null)
)

const selectInactiveConsumptionPointsWithAddressFilled = createSelector(
    selectInactiveSoutiragePoints,
    (activePoint) => activePoint.filter((activePoint) => activePoint.address !== null)
)

 const selectIsACI = createSelector(
    selectAciProducers,
    aciProducers => aciProducers.size > 0
)

 const makeSelectIsACI = (selectedType: any) => createSelector(
    selectAciProducers,
    selectIsACI,
    (producersACIIds, isACI) => {
        if (selectedType.scope === 'global') return isACI
        return producersACIIds.has(selectedType.id)
    })
 const selectACIProducersNames = createSelector(
    selectConsumptionPoints,
    consumptionPoints => {
        const ACIProducersNames = consumptionPoints.reduce((set, curr) => {
            if (curr.injectionPointId) {
                set.add(curr.name)
            }
            return set
        }, new Set())
        return ACIProducersNames
    }
)

export {
    selectACIProducersNames,
    selectActiveSoutiragePoints,
    selectAciProducers,
    selectActiveACCConsumptionPointsWithAddressFilled,
    selectActiveACIConsumptionPointsWithAddressFilled,
    selectInactiveConsumptionPointsWithAddressFilled,
    selectInactiveSoutiragePoints,
    selectActiveConsumptionPointsWithAddressFilled,
    selectTypologyInfo,
    selectConsumptionPoints,
    selectConsumptionPointsHaveDuplicatedNames,
    selectConsumptionPointsNames,
    makeSelectConsumptionPointsSorted,
    selectIsACI,
    makeSelectIsACI,

}