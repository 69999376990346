import {Simulation} from "../../../../../../../corelogic/models/types/simulations-display/Simulation";
import {useDispatch, useSelector} from "react-redux";
import {
    selectAllSimulations
} from "../../../../../../../corelogic/usecases/simulations-display/simulationsDisplaySelector";
import {
    selectSelectedSimulationId
} from "../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form/newSimulationSelector";
import {State} from "../../../../../../../config/app-config";
import {push} from "connected-react-router";
import {
    simulationsDisplayActions
} from "../../../../../../../corelogic/usecases/simulations-display/simulationsDisplayActions";
import {selectAreAllSimulationsLoading} from "../../../../../../../corelogic/usecases/loader/loaderSelector";
import LoadSpinner from "../../../../components/assets/Animated/LoadSpinner";
import DownloadIcon from "../../../../components/assets/icon/DownloadIcon";
import React from "react";

const SearchList = ({ simpleSimulationsFiltered, setShowOptions }: { simpleSimulationsFiltered: Simulation[]; setShowOptions(opt: boolean): void }) => {
  const simulations = useSelector(selectAllSimulations);
  const reloadPageWithoutResults = simulations.length === 0;

  return (
    <div className={'h-0 top-1 relative '}>
      <div className={' z-[99] relative max-h-[200px] overflow-y-auto bg-slate-600 mx-2  dark:bg-gray-800 rounded ShadowM '}>{reloadPageWithoutResults ? <ReloadSimulationsBtn /> : simpleSimulationsFiltered.length > 0 ? <SimpleSimulationsList setShowOptions={setShowOptions} simpleSimulationsFiltered={simpleSimulationsFiltered} /> : <NoResultInfo />}</div>
    </div>
  );
};
const SimpleSimulationsList = ({setShowOptions, simpleSimulationsFiltered: simulationsFiltered}:{setShowOptions(opt:boolean):void, simpleSimulationsFiltered:Simulation[]}) => {
    const dispatch = useDispatch()
    const selectedSimulationId = useSelector(selectSelectedSimulationId)

    function handleOnOptionClick(simulation: Simulation, disabled: boolean) {
        if (!disabled) {
            if (simulation.status.state === State.SUCCESS) {
                dispatch(push(`/simulation/${simulation.id}/physical-result`))
            } else {
                dispatch(push(`/simulation/${simulation.id}/edit`))
            }
            dispatch(simulationsDisplayActions.searchOnType(''))
            setShowOptions(false)
        }
    }

    return <>
        {simulationsFiltered.map((simu: Simulation) => {
            const disabled = simu.id === selectedSimulationId
            return <div className={disabled ?
                "opacity-40  flex bg-slate-200 dark:bg-gray-700  form-select py-2 px-4 text-sm  block w-full  dark:text-slate-50   text-slate-600  "
                : "opacity-90 flex bg-slate-200 dark:bg-gray-700 hover:dark:opacity-100  hover:opacity-100 hover:cursor-pointer form-select py-2 px-4 text-sm  block w-full  dark:text-slate-50   text-slate-600    "
            }
                        onClick={() => handleOnOptionClick(simu, disabled)}>
                                            <span className={"font-semibold  "}>
                                                {simu.name}
                                            </span>
                {", " + simu.organisation.name + " (" + simu.status.text + ")"}
            </div>
        })}
        </>
}
const NoResultInfo = () => <div
    className={" opacity-40 bg-slate-200 dark:bg-gray-700 flex form-select py-2 px-4 text-sm  block w-full  dark:text-gray-200   text-gray-400 "}>Pas
    de résultats</div>
const ReloadSimulationsBtn = () => {
    const dispatch = useDispatch()
    const isLoadingSimulations = useSelector(selectAreAllSimulationsLoading)

    return <div
        className={"opacity-90 flex w-full justify-between bg-slate-200 dark:bg-gray-700 hover:dark:opacity-100  hover:opacity-100 hover:cursor-pointer form-select py-2 px-4 text-sm  block w-full  dark:text-slate-50   text-slate-600    "}
        onClick={() => dispatch(simulationsDisplayActions.getAllSimulations())}>
        Chargez les simulations
        {isLoadingSimulations ? <LoadSpinner/> : <DownloadIcon/>}
    </div>
}

export default SearchList