import {AddressGouv} from "../../../../models/types/new-simulation/external-api/AddressGouv";
import {INIT_ADDRESS_FROM_API, newSimulationActionsType, SET_ADDRESS_FROM_API} from "../../newSimulationActions";

const addressesInitialStep: AddressGouv[] = []
const addressesReducer = (state = addressesInitialStep, action: newSimulationActionsType) => {
    switch (action.type) {
        case SET_ADDRESS_FROM_API:
            return action.payload
        case INIT_ADDRESS_FROM_API:
            return addressesInitialStep
        default:
            return state;
    }
}
export default addressesReducer