import {createSelector} from "reselect";
import {selectNewSimulation} from "../form/newSimulationSelector";

const selectSteps = createSelector(
    selectNewSimulation,
    newSimulation => newSimulation.steps
);
const selectCurrentStepIndex = createSelector(
    selectNewSimulation,
    newSimulation => newSimulation.currentStepIndex
);
const selectCurrentStep = createSelector(
    selectNewSimulation,
    selectCurrentStepIndex,
    (newSimulation, currentStepIndex) => {
        return newSimulation.steps[currentStepIndex]
    }
)

const selectNextStepIndex = createSelector(
    selectNewSimulation,
    newSimulation => newSimulation.nextStepIndex
);

const selectNextStep = createSelector(
    selectNewSimulation,
    selectNextStepIndex,
    (newSimulation, nextStepIndex) => {
        return newSimulation.steps[nextStepIndex]
    }
)


export {
    selectNextStep,
    selectSteps,
    selectCurrentStep,
    selectCurrentStepIndex
}
