import {PeakOffPeakHoursDefinition} from "./PeakOffPeakHoursDefinition";
import {ConsumptionPoint} from "./ConsumptionPoint";
import FlatPeakOffPeakPricing from "./FlatPeakOffPeakPricing";
import SeasonalPeakOffPeakPricing from "./SeasonalPeakOffPeakPricing";

export class ComplementProviderKwhPrice {
    private _peakOffPeakHoursDefinition : PeakOffPeakHoursDefinition | number

    constructor(peakOffPeakHoursDefinition: PeakOffPeakHoursDefinition | number) {
        this._peakOffPeakHoursDefinition = peakOffPeakHoursDefinition;
    }

    serialize(){
        if(typeof this.peakOffPeakHoursDefinition === 'number'){
            return  this.peakOffPeakHoursDefinition
        }else if( typeof this.peakOffPeakHoursDefinition === 'object') {
            return (this._peakOffPeakHoursDefinition as PeakOffPeakHoursDefinition).serialize()
        }
    }
    get peakOffPeakHoursDefinition(): PeakOffPeakHoursDefinition | number {
        return this._peakOffPeakHoursDefinition;
    }

    set peakOffPeakHoursDefinition(value: PeakOffPeakHoursDefinition | number) {
        this._peakOffPeakHoursDefinition = value;
    }
}
export function displayKwHPrice(point : ConsumptionPoint, short ?:boolean, reductionValue?:string){
    let kwHPrice = ""
    const discountValue : string = reductionValue ? reductionValue : "0"
    if(typeof (point.complementProviderKwhPrice.peakOffPeakHoursDefinition) == "number"){
        kwHPrice = ""+discount(point.complementProviderKwhPrice.peakOffPeakHoursDefinition.toString(), discountValue)
    }
    else {
        if((point.complementProviderKwhPrice.peakOffPeakHoursDefinition.pricing.peakPricing as FlatPeakOffPeakPricing).offPeakProviderKwhPrice){
            if(short){
                kwHPrice = discount((point.complementProviderKwhPrice.peakOffPeakHoursDefinition.pricing.peakPricing as FlatPeakOffPeakPricing).peakKwhProviderPrice.toString(), discountValue) + ", " + discount((point.complementProviderKwhPrice.peakOffPeakHoursDefinition.pricing.peakPricing as FlatPeakOffPeakPricing).offPeakProviderKwhPrice.toString(), discountValue)

            }
            else {
                kwHPrice = "HP, HC : " + discount((point.complementProviderKwhPrice.peakOffPeakHoursDefinition.pricing.peakPricing as FlatPeakOffPeakPricing).peakKwhProviderPrice.toString(), discountValue) + ", " + discount((point.complementProviderKwhPrice.peakOffPeakHoursDefinition.pricing.peakPricing as FlatPeakOffPeakPricing).offPeakProviderKwhPrice.toString(), discountValue)
            }
        }
        else {
            if(short){
                kwHPrice = discount((point.complementProviderKwhPrice.peakOffPeakHoursDefinition.pricing.peakPricing as SeasonalPeakOffPeakPricing).winterPeakKwhProviderPrice.toString(), discountValue) + ", " + discount((point.complementProviderKwhPrice.peakOffPeakHoursDefinition.pricing.peakPricing as SeasonalPeakOffPeakPricing).winterOffPeakKwhProviderPrice.toString(), discountValue) + ", " + discount((point.complementProviderKwhPrice.peakOffPeakHoursDefinition.pricing.peakPricing as SeasonalPeakOffPeakPricing).summerPeakKwhProviderPrice.toString(), discountValue) + ", " + discount((point.complementProviderKwhPrice.peakOffPeakHoursDefinition.pricing.peakPricing as SeasonalPeakOffPeakPricing).summerOffPeakKwhProviderPrice.toString(), discountValue)
            }
            else {
                kwHPrice = "Hiver (HP, HC) - Ete (HP, HC) : " + discount((point.complementProviderKwhPrice.peakOffPeakHoursDefinition.pricing.peakPricing as SeasonalPeakOffPeakPricing).winterPeakKwhProviderPrice.toString(), discountValue) + ", " + discount((point.complementProviderKwhPrice.peakOffPeakHoursDefinition.pricing.peakPricing as SeasonalPeakOffPeakPricing).winterOffPeakKwhProviderPrice.toString(), discountValue) + " - " + discount((point.complementProviderKwhPrice.peakOffPeakHoursDefinition.pricing.peakPricing as SeasonalPeakOffPeakPricing).summerPeakKwhProviderPrice.toString(), discountValue) + ", " + discount((point.complementProviderKwhPrice.peakOffPeakHoursDefinition.pricing.peakPricing as SeasonalPeakOffPeakPricing).summerOffPeakKwhProviderPrice.toString(), discountValue)

            }
        }
    }
    return kwHPrice
}



function discount(price : string, reductionValue : string){
    return (parseFloat(price) * (100-parseFloat(reductionValue))/100).toFixed(3)
}
