export function containsAllCases(str : string) {
    // return true if string has at least one UPPERCASE character && one LOWERCASE character
    const upper = /[A-Z]/.test(str), lower = /[a-z]/.test(str);
    return upper && lower;
}

export function containsSpecialChars(str:string) {
    // return true if string contains a special character
    return /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(str);
}

export function containsDigit(str:string){
    // return true if string contains at least one digit
    return /\d/.test(str)
}