import {createAction} from "../../../store/createAction";
import {ActionsUnion} from "../../../store/actionsUnions";
import CDCData from "../../models/types/physicalRestitution/CDCData";
import MetricsData from "../../models/types/physicalRestitution/MetricsData";
import {SelectedType} from "./physicalRestitutionReducer";

export const GET_CDC_DATA = 'GET_CDC_DATA';
export const CANCEL_GET_CDC_DATA = 'CANCEL_GET_CDC_DATA';
export const SET_CDC_DATA = 'SET_CDC_DATA';
export const SET_CDC_LOADING = 'SET_CDC_LOADING';
export const GET_METRICS = 'GET_METRICS';
export const CANCEL_GET_METRICS = 'CANCEL_GET_METRICS'
export const SET_METRICS = 'SET_METRICS';
export const SET_METRICS_LOADING = 'SET_METRICS_LOADING';
export const GET_CDC_GROUPED_BY_MONTH_DATA = 'GET_CDC_GROUPED_BY_MONTH_DATA';
export const CANCEL_GET_CDC_GROUPED_BY_MONTH_DATA = 'CANCEL_GET_CDC_GROUPED_BY_MONTH_DATA';
export const SET_CDC_GROUPED_BY_MONTH_DATA = 'SET_CDC_GROUPED_BY_MONTH_DATA';
export const SET_CDC_GROUPED_BY_MONTH_LOADING = 'SET_CDC_GROUPED_BY_MONTH_LOADING';
export const INIT_PHYSICAL_DATA = 'INIT_PHYSICAL_DATA'
export const SET_SELECTED_POINT  = 'SET_SELECTED_POINT'
export const SET_IS_ACI  = 'SET_IS_ACI'
export const INIT_SELECTED_POINT  = 'INIT_SELECTED_POINT'

export const physicalRestitutionActions = {
    initData:() => createAction(INIT_PHYSICAL_DATA, {}),

    getCDCData: () => createAction(GET_CDC_DATA),
    cancelGetCDCData: () => createAction(CANCEL_GET_CDC_DATA),
    setCDCData: ({pointId ,data, isAci}:{pointId : string,data: CDCData, isAci: boolean}) => createAction(SET_CDC_DATA, {pointId, data, isAci}),
    setCDCLoading: ({pointId ,loading}:{pointId : string,loading: boolean}) => createAction(SET_CDC_LOADING, {pointId, loading}),

    getMetrics: () => createAction(GET_METRICS),
    cancelGetMetrics: () => createAction(CANCEL_GET_METRICS),
    setMetrics: ({pointId ,metrics, isAci}:{pointId : string,metrics: MetricsData, isAci: boolean}) => createAction(SET_METRICS, {pointId, metrics, isAci}),
    setMetricsLoading: ({pointId ,loading}:{pointId : string,loading: boolean}) => createAction(SET_METRICS_LOADING, {pointId, loading}),

    getCDCGroupedByMonthData: () => createAction(GET_CDC_GROUPED_BY_MONTH_DATA),
    cancelGetCDCGroupedByMonthData: () => createAction(CANCEL_GET_CDC_GROUPED_BY_MONTH_DATA),
    setCDCGroupedByMonthData: ({pointId ,data, isAci} :{pointId : string,data: any, isAci: boolean}) => createAction(SET_CDC_GROUPED_BY_MONTH_DATA, {pointId, data, isAci}),
    setCDCGroupedByMonthLoading: ({pointId ,loading}:{pointId : string,loading: boolean}) => createAction(SET_CDC_GROUPED_BY_MONTH_LOADING, {pointId, loading}),

    setSelectedType: (type:SelectedType) => createAction(SET_SELECTED_POINT, type),
    setIsAci: (isAci:boolean) => createAction(SET_IS_ACI, isAci),
    initSelectedPoint: () => createAction(INIT_SELECTED_POINT),
};

export type Actions = ActionsUnion<typeof physicalRestitutionActions>;
