import { Actions, ExecutionStatus, SET_SIMULATION_EXECUTION_STATUS } from './simulationExecutionStatusActions';

export const simulationExecutionStatusReducer = (state: ExecutionStatus = ExecutionStatus.running, action: Actions) => {
    switch (action.type) {
        case SET_SIMULATION_EXECUTION_STATUS:
            return action.payload;
        default:
            return state;
    }
};
