export default class SeasonalPeakOffPeakPricing {
    private _summerOffPeakKwhProviderPrice : number;
    private _summerPeakKwhProviderPrice : number;
    private _winterOffPeakKwhProviderPrice : number;
    private _winterPeakKwhProviderPrice : number;

    constructor(summerOffPeakKwhProviderPrice: number, summerPeakKwhProviderPrice: number, winterOffPeakKwhProviderPrice: number, winterPeakKwhProviderPrice: number) {
        this._summerOffPeakKwhProviderPrice = summerOffPeakKwhProviderPrice;
        this._summerPeakKwhProviderPrice = summerPeakKwhProviderPrice;
        this._winterOffPeakKwhProviderPrice = winterOffPeakKwhProviderPrice;
        this._winterPeakKwhProviderPrice = winterPeakKwhProviderPrice;
    }
    serialize(){
        return {
            summer_off_peak_kwh_provider_price : this._summerOffPeakKwhProviderPrice,
            summer_peak_kwh_provider_price : this._summerPeakKwhProviderPrice,
            winter_off_peak_kwh_provider_price:this._winterOffPeakKwhProviderPrice,
            winter_peak_kwh_provider_price:this._winterPeakKwhProviderPrice
        }
    }
    get summerOffPeakKwhProviderPrice(): number {
        return this._summerOffPeakKwhProviderPrice;
    }

    set summerOffPeakKwhProviderPrice(value: number) {
        this._summerOffPeakKwhProviderPrice = value;
    }

    get summerPeakKwhProviderPrice(): number {
        return this._summerPeakKwhProviderPrice;
    }

    set summerPeakKwhProviderPrice(value: number) {
        this._summerPeakKwhProviderPrice = value;
    }

    get winterOffPeakKwhProviderPrice(): number {
        return this._winterOffPeakKwhProviderPrice;
    }

    set winterOffPeakKwhProviderPrice(value: number) {
        this._winterOffPeakKwhProviderPrice = value;
    }

    get winterPeakKwhProviderPrice(): number {
        return this._winterPeakKwhProviderPrice;
    }

    set winterPeakKwhProviderPrice(value: number) {
        this._winterPeakKwhProviderPrice = value;
    }
}