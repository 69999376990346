import React, {useState} from "react";
import {LabelInput} from "../../../../../components/assets/FormAssets/TextInput";
import VATRateCountrySelector from "../VATRateCountrySelector";
import VariableVATRateInput from "./VariableVATRateInput";
import {InformationCircleIconOrangeOnHover} from "../../../../../components/assets/icon/InformationCircleIcon";
import TVARateTooltip from "../../../../tooltip/TVARateTooltip";
import {useSelector} from "react-redux";
import {selectIsDarkMode} from "../../../../../../../../corelogic/usecases/config/configSelector";

export enum country {
    FRANCE = 'France Métropolitaine',
    OTHER = 'Autre',
}

export type Taxe = {
    country: country,
    tax: number
}


export default function VariableVATRate() {
    const [showTVATooltip, setShowTVATooltip] = useState(false)
    const isDarkMode = useSelector(selectIsDarkMode)


    return <div className={"flex flex-col "}>
        <div className={" w-[330px] flex justify-between"}>
            <div className={"flex items-center gap-1"}>
                <LabelInput label={'TVA'}/>
                <div className={"relative hover:cursor-pointer mr-2 mb-2"}
                     onMouseLeave={() => setShowTVATooltip(false)}
                     onMouseOver={() => setShowTVATooltip(true)}>
                    {InformationCircleIconOrangeOnHover()}
                    {showTVATooltip && <TVARateTooltip/>}
                </div>
            </div>
            <LabelInput label={'part variable'} mandatory={true}/>
        </div>
        <div className="flex max-h-min">
            <VATRateCountrySelector/>
            <VariableVATRateInput/>
        </div>
    </div>
}


