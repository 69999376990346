import {useSelector} from "react-redux";
import {selectNoOptionChosen} from "../../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import Header from "./Header";
import DescriptionSteps from "./DescriptionSteps";
import ArrowUpTray from "../../../../../components/assets/icon/ArrowUpTray";
import React from "react";
import {StatusEnum} from "../../../../../../../../corelogic/models/types/new-simulation/curve/Curve";
import {selectIsRoleEnogrid} from "../../../../../../../../corelogic/usecases/authentication/authenticationSelector";
import {
    selectNewCurveStatus
} from "../../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-curve/selectFormCurve";

export default function DescriptionBtn() {
    const noOption = useSelector(selectNoOptionChosen)

    return <div className="w-full grow shrink basis-0 flex-col justify-center items-start gap-2 inline-flex">
        <Header/>
        <div className={"w-full flex justify-between"}>
            <div className="flex-col justify-center items-start gap-0.5 flex">
                <Text/>
                {noOption && <DescriptionSteps/>}
            </div>
            {noOption && <ArrowUpTray className={"h-6 w-6 text-gray-600  dark:text-gray-300"}/>}
        </div>
    </div>
}
function Text(){
    const noOption = useSelector(selectNoOptionChosen)
    const curveStatus = useSelector(selectNewCurveStatus)
    const isRoleEnogrid = useSelector(selectIsRoleEnogrid)
    const text = () => {
        if(noOption){
            return 'Ajouter un fichier de courbe de charge XLS par clic.'
        } else {
            switch(curveStatus){
                case StatusEnum.REJECTED: return isRoleEnogrid ? "Courbe invalide ? Complétez votre courbe de charge à l'aide d'un des boutons de complétude de données."
                    : "Courbe rejetée : la courbe de charge n'est pas acceptée. Veuillez ré-essayer avec une autre courbe de charge."
                case StatusEnum.STANDARD_COMPLETION_COMPLIANT :return "Courbe invalide ? Complétez votre courbe de charge à l'aide d'un des boutons de complétude de données."
                case StatusEnum.VALID :return "Cette courbe de charge est valide au pas de temps 30 minutes sur une année complète."

                default : return "Chargement de la courbe de charge..."
            }
        }
    }

    return <div className="text-gray-600 dark:text-gray-200 text-xs font-light leading-none">{text()}</div>
}
