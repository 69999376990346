import React from "react";
import {SGEFetchedCDCStatus} from "../../../../../../../../../corelogic/usecases/typology-form/typologyFormReducer";

const AFailedWeek = ({failInfos}: { failInfos: SGEFetchedCDCStatus }) => {

    const format_YYYYMMDD_to_DDMMYYYY = (yyyyddmm: string): string => {
        const parts = yyyyddmm.split('-');
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        const newDateFormat = `${day}/${month}/${year}`;
        return newDateFormat;
    }
    return <div
        className={"flex justify-between hover:bg-red-100 gap-2 dark:hover:bg-red-800 dark:hover:bg-opacity-20    items-center p-2"}>
        <div className={"flex gap-1 items-center"}>
            <div className={"h-8 w-1.5 bg-red-500  dark:bg-red-400"}></div>
            <div className={"flex-col flex"}>
                <span
                    className={" text-gray-700 dark:text-gray-200 text-xs whitespace-nowrap"}>{format_YYYYMMDD_to_DDMMYYYY(failInfos.from)} </span>
                <span
                    className={" text-gray-700 dark:text-gray-200 text-xs whitespace-nowrap"}>{format_YYYYMMDD_to_DDMMYYYY(failInfos.to)}</span>

            </div>

        </div>
        <span
            className={"  text-red-500 dark:text-red-400 text-sm ml-2 font-semibold w-full"}>{failInfos.error.message}</span>
        <span className={" text-gray-500 dark:text-gray-400 text-xs"}>{failInfos.error.code}</span>
        <span
            className={" text-gray-500 dark:text-gray-400 text-xs whitespace-nowrap"}>{failInfos.retries} retry</span>

    </div>
}

export default AFailedWeek
