export class Loan {
    private _price: string
    private _duration: string
    private _rate: string

    constructor(price: string, duration: string, rate: string) {
        this._price = price;
        this._duration = duration;
        this._rate = rate;
    }

    serialize():{ price: string, duration: string, rate: string } {
        return {
            price: this._price,
            duration: this._duration,
            rate: this._rate
        }
    }

    get price(): string {
        return this._price;
    }

    set price(value: string) {
        this._price = value;
    }

    get duration(): string {
        return this._duration;
    }

    set duration(value: string) {
        this._duration = value;
    }

    get rate(): string {
        return this._rate;
    }

    set rate(value: string) {
        this._rate = value;
    }
}