import React from "react";
import {selectSGECDCSavedConfig} from "../../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import {useSelector} from "react-redux";

const FormRecap = ({showInfos}: { showInfos: boolean }) => {
    const recap: { prm: string, dateStart: string, dateEnd: string, retries : string, delay  :string, fetchIfHoles:boolean } = useSelector(selectSGECDCSavedConfig)

    return showInfos ? <div
        className={"gap-2 h-min  p-4 text-gray-600 flex flex-col w-min whitespace-nowrap dark:text-gray-300"}>
        <div className={"w-full flex justify-between gap-8 text-sm"}>
            <span className={"font-semibold"}>PRM</span>
            <span className={"font-light"}>{recap.prm}</span>
        </div>
        <div className={"w-full flex justify-between  gap-8 text-sm"}>
            <span className={"font-semibold"}>Date de début</span>
            <span className={"font-light"}>{recap.dateStart}</span>
        </div>
        <div className={"w-full flex justify-between  gap-8  text-sm"}>
            <span className={"font-semibold"}>Date de fin</span>
            <span className={"font-light"}>{recap.dateEnd}</span>
        </div>
        <div className={"w-full flex justify-between  gap-8 text-sm"}>
            <span className={"font-semibold"}>Nombre de retry</span>
            <span className={"font-light"}>x{recap.retries}</span>
        </div>
        <div className={"w-full flex justify-between  gap-8 text-sm"}>
            <span className={"font-semibold"}>Delai entre 2 essai</span>
            <span className={"font-light"}>{recap.delay}sec</span>
        </div>
        <div className={"w-full flex justify-between  gap-8 text-sm"}>
            <span className={"font-semibold"}>Récupération avec trous</span>
            <span className={"font-light"}>{recap.fetchIfHoles ? 'Oui' : 'Non'}</span>
        </div>
    </div> : <></>
}

export default FormRecap;
