import TitleText from "../../../components/assets/text/TitleText";
import TextInput from "../../../components/assets/FormAssets/TextInput";
import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {newSimulationActions} from "../../../../../../corelogic/usecases/new-simulation/newSimulationActions";
import {GeneralInfo} from "../../../../../../corelogic/models/types/new-simulation/form/GeneralInfo";
import {
    selectAddresses,
    selectFormOrganisationId,
} from "../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form/newSimulationSelector";
import DescriptionText from "../../../components/assets/text/DescriptionText";
import {InformationCircleIcon} from "../../../components/assets/icon/InformationCircleIcon";
import {OperationType} from "../../../../../../corelogic/models/types/new-simulation/form/OperationType";
import EstateIcon from "../../../components/assets/icon/EstateIcon";
import SocialIcon from "../../../components/assets/icon/SocialIcon";
import OpenIcon from "../../../components/assets/icon/OpenIcon";
import {makeSelectToAllowedOpenOperation} from "../../../../../../corelogic/usecases/authentication/authenticationSelector";
import SelectorInputCard from "../../../components/assets/FormAssets/selector/SelectorInputCard";
import {
    makeAreGeneralInfoFormValid,
    makeHaveGeneralInfoChanged,
    makeIsNameValid
} from "../../../../../../corelogic/usecases/new-simulation/ValidatorsSelector";
import {formActions} from "../../../../../../corelogic/usecases/form/formActions";
import PostCodeInput from "./PostCodeInput";
import {AddressGouv} from "../../../../../../corelogic/models/types/new-simulation/external-api/AddressGouv";
import OrganisationInput from "./OrganisationInput";
import {selectIsLoadingSimulationForm} from "../../../../../../corelogic/usecases/loader/loaderSelector";
import {
    selectNextStep
} from "../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/steps/selectSteps";
import {
    selectGeneralInfo
} from "../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectGeneralInfoForm";
import {
    selectClosingForm,
    selectSavingForm
} from "../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-action/selectFormAction";

export default function GeneralInfoForm() {
    const dispatch = useDispatch()

    const nextStep = useSelector(selectNextStep)
    const generalInfo = useSelector(selectGeneralInfo)
    const formOrganisationId = useSelector(selectFormOrganisationId)
    const saveForm = useSelector(selectSavingForm)
    const closeForm = useSelector(selectClosingForm)
    const address: AddressGouv[] =  useSelector(selectAddresses);
    const isLoading = useSelector(selectIsLoadingSimulationForm)
    const [name, setName] = useState("")
    const [postcode, setPostcode] = useState("")
    const [selectedType, setSelectedType] = useState<keyof typeof OperationType | null>(null)
    const [organisationId, setOrganisationId] = useState<string>("")

    const areGeneralInfoValidSelector = useCallback(makeAreGeneralInfoFormValid(name, postcode, selectedType), [name, postcode, selectedType])
    const areGeneralInfoValid = useSelector(areGeneralInfoValidSelector)

    const haveGeneralInfoChangedSelector = useCallback(makeHaveGeneralInfoChanged(name, postcode, selectedType, organisationId), [name, postcode, selectedType, organisationId])
    const haveGeneralInfoChanged = useSelector(haveGeneralInfoChangedSelector)

    const isNameValidSelector = useCallback(makeIsNameValid(name), [name])
    const isNameValid = useSelector(isNameValidSelector);
    useEffect(()=>{
        // when edit simulation page is opened, reset form store to make sure all variables are empty
       dispatch(formActions.resetFinancialForm())
    },[])

    function nameOnChange(e: string) {
        setName(e);
    }

    function thereIsANewValidVersion():boolean{
        return haveGeneralInfoChanged && areGeneralInfoValid
    }

    function supplySavedData(generalInfo:GeneralInfo){
        setName(generalInfo.name)
        setSelectedType(generalInfo.typeOfOperation)
        if (generalInfo.postcode) {
            setPostcode(generalInfo.postcode)
        }
    }

    useEffect(() => {
        dispatch(newSimulationActions.setHasNewChanges(thereIsANewValidVersion()))
    }, [haveGeneralInfoChanged, areGeneralInfoValid])

    useEffect(() => {
        if (formOrganisationId) {
            setOrganisationId(formOrganisationId)
        }
    }, [formOrganisationId])

    useEffect(() => {
        dispatch(newSimulationActions.setFormIsValid(areGeneralInfoValid))
    }, [areGeneralInfoValid])

    useEffect(() => {
        if (generalInfo) {
            supplySavedData(generalInfo)
        }
    }, [generalInfo])

    useEffect(() => {
        if (saveForm && selectedType && organisationId) {
            const postCodeHasNotChanged = address.length === 0
            const addressToSend = postCodeHasNotChanged ? postcode : address[0].postcode
            dispatch(newSimulationActions.setGeneralInfo(new GeneralInfo(name, addressToSend, selectedType), organisationId))
            dispatch(newSimulationActions.setSavingForm(false))
        }
    }, [saveForm])

    useEffect(() => {
        if (closeForm) {
            // => saving current form into store, updating sidebar steps status
            if (thereIsANewValidVersion() && selectedType && organisationId) {
                const postCodeHasNotChanged = address.length === 0
                const addressToSend = postCodeHasNotChanged ? postcode : address[0].postcode

                dispatch(newSimulationActions.setGeneralInfo(new GeneralInfo(name, addressToSend, selectedType), organisationId))
                dispatch(newSimulationActions.setHasNewChanges(false))
            }
            dispatch(newSimulationActions.setCurrentStepIndex(nextStep.index)) // <=> goto new page
            dispatch(newSimulationActions.setClosingForm(false))
        }
    }, [closeForm])


    return <div data-cy={'general-info-form'}
                className="w-full flex flex-col bg-gray-100 font-sans overflow-auto h-full dark:bg-zinc-700">
        <div className=" mx-4 scale-95 2xl:scale-100 ">

            <TitleText title={"Informations générales de simulation"}/>
            <DescriptionText
                description={"Commencez en donnant un nom à votre simulation ainsi que son code postale."}/>

            <div className="flex flex-col 2xl:flex-row 2xl:mt-12">
                <form className="w-full 2xl:max-w-[920px]">

                    <OrganisationInput
                        organisationId={organisationId}
                        setOrganisationId={setOrganisationId}
                        isNoSavedOrganisationId={!formOrganisationId}/>

                    <div className="flex flex-wrap m-4">
                        <PostCodeDisclaimer/>
                        <div className="w-full grid grid-cols-2 gap-6 bg-slate-100 pt-4 pb-8 dark:bg-zinc-700">
                            <TextInput dataCy={"general-info-name"} mandatory={true} label={"NOM"}
                                       placeholder={"(ex : Simulation Enogrid)"} id={"step1-name"}
                                       loading={isLoading}
                                       onChange={nameOnChange} value={name} error={{
                                state: !isNameValid,
                                msg: "le nom doit être composé d'au moins 2 caractères"
                            }}/>
                            <PostCodeInput generalInfo={generalInfo}
                                          postcode={postcode}

                                          setPostcode={setPostcode}
                            />
                        </div>
                        <TypeOfOperationInput organisationId={organisationId}
                                              selectedType={selectedType}
                                              setSelectedType={setSelectedType}
                        />
                        <div className={"text-gray-500 text-xs "}>* champs obligatoires</div>
                    </div>
                </form>
            </div>
        </div>
    </div>
}

function TypeOfOperationInput({
                                  organisationId,
                                  selectedType,
                                  setSelectedType
                              }: { organisationId: string, selectedType: keyof typeof OperationType | null, setSelectedType(type: keyof typeof OperationType | null): void }) {
    const isAllowedToOpenOperationSelector = useCallback(makeSelectToAllowedOpenOperation(organisationId), [organisationId])
    const isAllowedToOpenOperation = useSelector(isAllowedToOpenOperationSelector)
    const isLoadingForm = useSelector(selectIsLoadingSimulationForm)
    const operationTypeIcon = [
        EstateIcon({disabled: false}),
        SocialIcon({disabled: false}),
        OpenIcon({disabled: false})
    ]
    const operationTypeDescription = [
        "Tous les points de production et de consommation appartiennent à un unique acteur (par exemple, une collectivité). Le partage d\'électricité entre bâtiments permet un évitement de facture.",
        "Le projet d\'autoconsommation collective rassemble un bailleur social avec ses locataires. L\'électricité est cédée gratuitement par le bailleur à ses locataires afin qu\'ils réalisent des économies de factures.",
        "Les points de production et de consommation appartiennent à des acteurs différents (par exemple des entreprises, des citoyens, des collectivités). L\'électricité est généralement vendue par les producteurs aux consommateurs."]

    useEffect(()=> {
        /**
         * specific case : I have 2 organisations : one that allow "open" operation and one that doesn't.
         * 1. choose organisation 1 (with rights) + selectType of operation to "open"
         * 2. switch organisation 1 (with rights) to organisation 2 (that cannot do "open" operations)
         * 3. typeOfOperation is still "open" and needs to be reset to null
         */
        if(!isAllowedToOpenOperation && selectedType === "open"){
            setSelectedType(null)
        }
    },[isAllowedToOpenOperation])

    return <>
        <span
            className="text-gray-700 w-full uppercase tracking-wide text-gray-600 text-xs font-bold dark:text-zinc-200">type d'opération*</span>
        <div
            className={isAllowedToOpenOperation ? "w-full grid grid-cols-3 gap-6 bg-slate-100 pt-2 pb-4 dark:bg-zinc-700" : "w-full grid grid-cols-2 gap-6 bg-slate-100 pt-2 pb-4 dark:bg-zinc-700"}>
            {Object.keys(OperationType).map((key: string, index) => {
                if (index !== 2 || index === 2 && isAllowedToOpenOperation) {
                    return <SelectorInputCard dataCy={"general-info-type-of-operation-" + key}
                                              description={operationTypeDescription[index]}
                                              Icon={() => operationTypeIcon[index]}
                                              label={"Opération " + OperationType[key as keyof typeof OperationType]}
                                              disabled={isLoadingForm || !(selectedType === key)}
                                              badges={[]}
                                              onClick={() => setSelectedType(key as keyof typeof OperationType)}/>
                } else {
                    return <></>
                }
            })}
        </div>
    </>
}

function PostCodeDisclaimer() {
    return <div
        className={"bg-slate-200 text-slate-500 text-xs rounded-sm p-3 w-full my-1 flex items-center dark:bg-zinc-500 dark:text-zinc-300"}>
                            <span className={"w-10 mr-2 "}><InformationCircleIcon
                                textcolor={" dark:text-zinc-300 "}/></span>
        Le code postal est utilisé pour la modélisation de courbe de charge avec PVGIS (Points
        d'injection) ou Enedis (Points de soutirage).
    </div>
}



