import {Pricing} from "./Pricing";

export class PeakOffPeakHoursDefinition {
    private _offPeakStartTime : string;
    private _peakStartTime : string
    private _pricing : Pricing

    constructor(offPeakStartTime: string, peakStartTime: string, pricing: Pricing) {
        this._offPeakStartTime = offPeakStartTime;
        this._peakStartTime = peakStartTime;
        this._pricing = pricing;
    }
    serialize(){
        return {
            off_peak_start_time: this._offPeakStartTime,
            peak_start_time: this._peakStartTime,
            pricing: this._pricing.serialize()
        }
    }
    get offPeakStartTime(): string {
        return this._offPeakStartTime;
    }

    set offPeakStartTime(value: string) {
        this._offPeakStartTime = value;
    }

    get peakStartTime(): string {
        return this._peakStartTime;
    }

    set peakStartTime(value: string) {
        this._peakStartTime = value;
    }

    get pricing(): Pricing {
        return this._pricing;
    }

    set pricing(value: Pricing) {
        this._pricing = value;
    }
}