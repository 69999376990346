import React, {useRef, useState} from "react";
import SellingPriceTable from "../form/financial-parameters/selling-price/selling-price-table/SellingPriceTable";
import {SellingPriceFunction} from "../../../../../corelogic/models/types/new-simulation/form/SellingPriceFunction";
import {WrapperCard} from "../wrapper/WrapperCard";

export default function SellingPriceRecapTable() {
    const sellingPriceTableRef = useRef(null)
    const [sellingPriceTableRefresh, setSellingPriceTableRefresh] = useState(false)

    return <WrapperCard
        exportAsPNGRef={sellingPriceTableRef}
        title={"Prix de vente interne"}>
        <div ref={sellingPriceTableRef}>
            <SellingPriceTable
                sellingPriceTableRefresh={sellingPriceTableRefresh}
                setSellingPriceTableRefresh={setSellingPriceTableRefresh}
                typeOfAffectation={SellingPriceFunction.FIXED_PRICE}
                valueToAffect={undefined}
                disabled={true}/>
        </div>
    </WrapperCard>
}
