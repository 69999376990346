import React from 'react';
import UploadCDCFile from "../button/UploadCDCFile";
import DownloadCDCTemplate from "./DownloadCDCTemplate";

type FileInputType = {
    action(): void,
}

export default function FileInput({action}: FileInputType) {

    return <>
        <UploadCDCFile action={action}/>
        <DownloadCDCTemplate/>
    </>
}




