import ReactEcharts from "echarts-for-react";
import React, {useRef} from "react";
import {useSelector} from "react-redux";
import {
    selectConsumptionDistributionData,
    selectMetricsLoading,
} from "../../../../../corelogic/usecases/physicalRestitution/physicalRestitutionSelector";
import usePieChartOption from "./usePieChartOption";
import AutoproductionKpiRate from "./AutoproductionKpiRate";
import {loadingOption} from "./config";
import {selectIsDarkMode} from "../../../../../corelogic/usecases/config/configSelector";
import {WrapperCard} from "../wrapper/WrapperCard";

export default function ({dataCy}: { dataCy : string }) {
    const data = useSelector(selectConsumptionDistributionData)
    const loading = useSelector(selectMetricsLoading)
    const isDarkMode = useSelector(selectIsDarkMode)
    const option = usePieChartOption(data, 'consumption');
    const consumptionDistributionPieChartRef = useRef(null)

    return (<WrapperCard title={"Répartition de la consommation"} exportAsPNGRef={consumptionDistributionPieChartRef} exportAsXLSData={data}>
        <div

            ref={consumptionDistributionPieChartRef}><div data-cy={dataCy} className="flex flex-col items-center min-w-[250px] bg-white dark:bg-zinc-700 dark:text-gray-300 font-bold text-normal text-gray-700 min-h-[10rem] shadow-sm rounded-md overflow-hidden p-6 min-w-[15rem];">
                <div className="flex justify-between w-full">
                    <ReactEcharts theme={isDarkMode ? 'dark':''} option={option} className="pie-chart w-full" showLoading={loading} loadingOption={loadingOption}/>
                </div>
                <div>Taux d'autoproduction</div>
                <AutoproductionKpiRate/>
            </div>
        </div>
    </WrapperCard>)
}
