 function XIcon({className}:{className ?:string}){
    return <svg xmlns="http://www.w3.org/2000/svg" className={className ? className : "h-5 w-5 text-gray-700"} fill="none" viewBox="0 0 24 24"
                stroke="currentColor" strokeWidth="2">
        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
    </svg>
}
 function XIconRed(){
    return <svg xmlns="http://www.w3.org/2000/svg" className={"h-5 w-5 text-red-500 dark:text-red-400 "} fill="none" viewBox="0 0 24 24"
                stroke="currentColor" strokeWidth="2">
        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
    </svg>
}

export {XIcon, XIconRed}
