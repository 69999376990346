import ReactEcharts from "echarts-for-react";
import React, {useRef} from "react";
import {useSelector} from "react-redux";
import {
    selectMetricsLoading,
    selectProductionDistributionData,
} from "../../../../../corelogic/usecases/physicalRestitution/physicalRestitutionSelector";
import usePieChartOption from "./usePieChartOption";
import {loadingOption} from "./config";
import AutoconsumptionKpiRate from "./AutoconsumptionKpiRate";
import {selectIsDarkMode} from "../../../../../corelogic/usecases/config/configSelector";
import {WrapperCard} from "../wrapper/WrapperCard";

export default function ({dataCy}: { dataCy: string }) {
    const data = useSelector(selectProductionDistributionData)
    const loading = useSelector(selectMetricsLoading)
    const isDarkMode = useSelector(selectIsDarkMode)
    const productionDistributionPieChartRef = useRef(null)


    const option = usePieChartOption(data, 'production');
    return (<WrapperCard title={"Répartition de la production"} exportAsPNGRef={productionDistributionPieChartRef} exportAsXLSData={data}>
        <div ref={productionDistributionPieChartRef}>
            <div data-cy={dataCy}
                 className="flex flex-col items-center min-w-[250px] bg-white font-bold text-normal text-gray-700 min-h-[10rem] shadow-sm rounded-md  p-6 min-w-[15rem] dark:bg-zinc-700 dark:text-gray-300 ">
                <div className="flex justify-between w-full">
                    <ReactEcharts theme={isDarkMode ? 'dark' : ''} option={option} className="pie-chart w-full"
                                  showLoading={loading} loadingOption={loadingOption}/>

                </div>
                <div>Taux d'autoconsommation</div>
                <AutoconsumptionKpiRate/>

            </div>
        </div>
    </WrapperCard>)
}
