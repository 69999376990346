type ToggleType = {
    checked: boolean,
    action:()=> void,
    title?: string,
}

function SmallToggle({title, action, checked}: ToggleType) {
    return <label htmlFor="small-toggle" className="inline-flex relative items-center cursor-pointer " >
        <input type="checkbox" value="" id="small-toggle" className="sr-only peer" checked={checked} />
        <div
            onClick={action}
            className={"w-7 h-4 bg-gray-200 peer-focus:outline-none dark:peer-focus:ring-slate-800 rounded-full peer dark:bg-gray-200 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white   after:rounded-full after:h-3 after:w-3 after:transition-all  peer-checked:bg-slate-600"}>

        </div>
        {title && <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">{title}</span>}
    </label>
}

function Toggle({title, action, checked}: ToggleType) {
    return <label htmlFor="default-toggle-size" className="inline-flex relative items-center cursor-pointer">
        <input type="checkbox" value="" id="default-toggle-size" className="sr-only peer" checked={checked}
               onClick={action}/>
        <div
            className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-slate-300 dark:peer-focus:ring-slate-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-slate-600">

        </div>
        {title && <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">{title}</span>}
    </label>
}


function LargeToggle({title, action, checked}: ToggleType) {
    return <label htmlFor="large-toggle" className="inline-flex relative items-center cursor-pointer">
        <input type="checkbox" value="" id="large-toggle" className="sr-only peer" checked={checked} onClick={action}/>
        <div
            className="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-slate-300 dark:peer-focus:ring-slate-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-slate-600">

        </div>
        {title && <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">{title}</span>}
    </label>
}

export {SmallToggle, Toggle, LargeToggle}
