import React, {useState} from "react";
import DescriptionText from "../../../../components/assets/text/DescriptionText";
import {ReactComponent as PrioSurplusAllocation} from "./images/surplus_allocation_prio.svg";
import {ReactComponent as DefaultSurplusAllocation} from "./images/surplus_allocation_default.svg";
import {ReactComponent as PrioSurplusAllocationDark} from "./images/surplus_allocation_prio_dark.svg";
import {ReactComponent as DefaultSurplusAllocationDark} from "./images/surplus_allocation_default_dark.svg";
import {useSelector} from "react-redux";
import {selectIsDarkMode} from "../../../../../../../corelogic/usecases/config/configSelector";
import SurplusAllocationCard from "./SurplusAllocationCard";
import SurplusAllocationPriorityTable from "./SurplusAllocationPriorityTable";
import {
    UseSurplusAllocation
} from "../../../../../../../corelogic/usecases/operation-parameters-form/surplus_allocation/useSurplusAllocation";
import {InformationCircleIcon} from "@heroicons/react/24/solid";
import CheckIcon from "../../../../components/assets/icon/CheckIcon";
import {AdjustmentsHorizontalIcon, Bars2Icon, ExclamationTriangleIcon} from "@heroicons/react/20/solid";
import {
    selectIsDistributionAlgorithmByUngroupedPriorities
} from "../../../../../../../corelogic/usecases/operation-parameters-form/operationParametersFormSelector";
import {State} from "../../../../../../../config/app-config";
import Notification from "../../../paper/Notification";
import {NewReleaseWrapper} from "../../../wrapper/NewReleaseWrapper";
import {
    SurplusAllocationAlgorithm
} from "../../../../../../../corelogic/models/types/new-simulation/form/operation-parameters/surplus-allocation/SurplusAllocation";

export enum SurplusAllocationAlgorithmLabel {
    by_default = "Dynamique par défaut",
    by_priority = "Dynamique par priorité",
}

const surplusAllocationIcons = {
    "by_default": {
        light: <DefaultSurplusAllocation/>,
        dark: <DefaultSurplusAllocationDark/>,
    },
    "by_priority": {
        light: <PrioSurplusAllocation/>,
        dark: <PrioSurplusAllocationDark/>,
    },
}

const surplusAllocationBadges = {
    by_default: [{
        textBold: "Configuration simple.",
        text: "Pas de configuration à effectuer,  la répartition se fait au pro-rata de la production à chaque pas de temps.",
        color: "text-zinc-500 dark:text-gray-400",
        Icon: () => CheckIcon({color: "text-zinc-500 min-w-5 min-h-5"})
    }, {
        textBold: "Point d’attention.",
        text: 'Les gros producteurs se voient affecter un surplus supérieur aux petits producteurs.',
        color: "text-zinc-500 dark:text-gray-400",
        Icon: () => <ExclamationTriangleIcon className="dark:text-gray-400 text-zinc-500 w-5 h-5"/>
    }],
    by_priority: [
        {
            textBold: "Personnalisée.",
            text: "Configurez quels producteurs recevront en priorité le surplus de production.",
            color: "text-zinc-500 dark:text-gray-400",
            Icon: () => <AdjustmentsHorizontalIcon className="dark:text-gray-400 text-zinc-500 w-5 h-5"/>
        }, {
            textBold: "Priorité égale possible.",
            text: 'Il est possible de donner la même quantité de surplus à chaque producteurs.',
            color: "text-zinc-500 dark:text-gray-400",
            Icon: () => <Bars2Icon className="dark:text-gray-400 text-zinc-500 w-5 h-5"/>
        }
    ],
}

export default function SurplusAllocationForm(surplusAllocation: UseSurplusAllocation) {
    const isDarkMode = useSelector(selectIsDarkMode)
    const isDistributionByUngroupedPriority = useSelector(selectIsDistributionAlgorithmByUngroupedPriorities)
    const [showDescription, setShowDescription] = useState(false)

    function openUrlInNewTab(url: string) {
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    return <>
        <NewReleaseWrapper title={'nouveau'} relativePosition={" right-4 top-12 "}>
        <span className={"whitespace-nowrap text-xl font-bold text-gray-700 dark:text-gray-300  my-2 pt-16"}>
            Répartition du surplus de production locale
        </span>
        </NewReleaseWrapper>
        <DescriptionText classname={'text-xs font-semibold text-gray-400 mb-2'}
                         description={'Lorsque votre production est supérieure à votre consommation, du surplus est généré.\n' +
                             'Déterminez l’algorithme de répartition du surplus éventuel de votre production locale vers vos producteurs.'}/>


        {
                isDistributionByUngroupedPriority ?
                    <Notification
                        state={State.INFO}
                        text={"La répartition par priorité dégroupée calcule le surplus directement chez chaque producteur. Ainsi, il n'y a pas de surplus global à redistribuer."}
                    />
                    :
                    <div className="grid grid-cols-3 gap-6 pt-4">
                        {Object.values(SurplusAllocationAlgorithm).map((algorithm) => {
                                return <SurplusAllocationCard
                                    Icon={isDarkMode ? () => surplusAllocationIcons[algorithm].dark : () => surplusAllocationIcons[algorithm].light}
                                    label={SurplusAllocationAlgorithmLabel[algorithm]}
                                    badges={surplusAllocationBadges[algorithm]}
                                    selected={surplusAllocation.checkIfSelected(algorithm)}
                                    onClick={() => surplusAllocation.onCardClick(algorithm)}
                                    showDescription={showDescription}
                                />
                            }
                        )}
                    </div>
            }

        <div className={"flex w-full justify-between pb-2 pt-2 pr-2"}>
            <span onClick={() => openUrlInNewTab('/help?theme=repartition_algorithm')}
                  className={"text-xs flex items-center gap-1 z-[150] self-end underline cursor-pointer text-gray-500 dark:text-gray-400"}>
                <InformationCircleIcon className={'w-5 h-5'}/>
                    Besoin d'aide pour comprendre les algorithmes ?
                </span>
            {!isDistributionByUngroupedPriority && <span onClick={() => setShowDescription(!showDescription)}
                                                         className={"text-xs z-[150] self-end underline cursor-help text-gray-500 dark:text-gray-400"}>
                    {showDescription ? "Masquer" : "Afficher"} la description
                </span>}
        </div>
        {!isDistributionByUngroupedPriority && surplusAllocation.isByPriority &&
            <SurplusAllocationPriorityTable {...surplusAllocation}/>}
    </>
}

