import {XIcon} from '../../components/assets/icon/XIcon';
import LockClosedIconSolid from '../../components/assets/icon/LockClosedIcon';
import EstateIcon from '../../components/assets/icon/EstateIcon';
import SocialIcon from '../../components/assets/icon/SocialIcon';
import {MoonIcon} from '../../components/assets/icon/MoonIcon';
import OpenIcon from '../../components/assets/icon/OpenIcon';
import TableCellsIconOutlined from '../../components/assets/icon/TableCellsIcon';
import NewsPaperIconOutline from '../../components/assets/icon/NewsPaperIcon';
import UsersIconOutlined from '../../components/assets/icon/UsersIcon';
import NoSymbolIconOutlined from '../../components/assets/icon/NoSymbolIcon';
import DuplicateIconOutlined from '../../components/assets/icon/DuplicateIcon';
import {BanknoteSolidIcon} from '../../components/assets/icon/BanknotesIcon';
import DownloadIcon from '../../components/assets/icon/DownloadIcon';
import {useEffect, useState} from 'react';
import WrenchScrewdriverIcon from '../../components/assets/icon/WrenchScrewdriverIcon';
import {BugAntOutlinedIcon} from '../../components/assets/icon/BugAntIcon';
import BeakerIcon from '../../components/assets/icon/BeakerIcon';
import WhatsNew from '../../components/assets/text/WhatsNew';
import ViewGridIcon from '../../components/assets/icon/ViewGridIcon';
import PaperClipIcon from '../../components/assets/icon/PaperClipIcon';
import ReceiptPercentIcon from '../../components/assets/icon/ReceiptPercentIcon';
import {CurrencyEuroSolidIcon} from '../../components/assets/icon/CurrencyEuro';
import RocketIcon from '../../components/assets/icon/RocketIcon';
import ShareIcon from '../../components/assets/icon/ShareIcon';
import {MapIconSolid} from '../../components/assets/icon/MapIcon';
import {MapPinIconSolid} from '../../components/assets/icon/MapPinIcon';
import ArrowUpTray from "../../components/assets/icon/ArrowUpTray";
import ChartBarIcon from "../../components/assets/icon/ChartBarIcon";
import {QuestionMarkOutlined} from "../../components/assets/icon/QuestionMarkIcon";
import {InformationCircleOutlinedIcon} from "../../components/assets/icon/InformationCircleIcon";
import ArrowPathRoundIcon from "../../components/assets/icon/ArrowPathRoundIcon";
import {MagnifyingOutlinedIcon} from "../../components/assets/icon/MagnifyingGlass";
import AdjustmentIcon from "../../components/assets/icon/AdjustmentIcon";
import {PuzzlePieceIconSolid} from "../../components/assets/icon/PuzzlePieceIcon";
import {BanknotesIcon} from "@enogrid/enocomponent/dist/esm/components/icon/BanknotesIcon";
import {InformationCircleIcon, ScaleIcon} from "@heroicons/react/24/solid";
import TitleWithLink from "../../components/assets/text/TitleWithLink";

type PopupModalType = {
    action(): void;
};

const WhatsNew100 = {
    version: '1.0.0',
    release_date: '15/09/2022',
    major: [
        {
            title: 'Opérations patrimoniales',
            descriptions: ['Par défaut les opérations précédemment créées deviennent patrimoniale.', "Si la puissance d'un des producteur est supérieure à 1MWc, il est affiché dans le bilan économique, table [Surplus, Turpe et taxes producteur] un nouveau champ Accise sur l'electricité."],
            icon: <EstateIcon disabled={false} className={'h-5 w-5 text-slate-600'}/>,
        },
        {
            title: 'Opération Sociale',
            descriptions: ['Les opérations déjà créées deviennent patrimoniale.'],
            icon: <SocialIcon disabled={false} className={'h-5 w-5 text-slate-600'}/>,
        },
        {
            title: 'Opération Ouverte',
            descriptions: ['En opération ouverte, renseignez le prix de vente interne dans les paramètres financiers du formulaire.', 'Dans la page bilan économique, un nouveau champ Gain producteur, table [Surplus, Turpe et taxes producteur] est affiché.', "Dans la page bilan économique, les résultats 'Economie totale et surplus' se décompose en 2 valeurs : 'Economie totale' et 'Gain producteur + surplus'", "Dans la page bilan économique, une table montre l'impact sur les factures d'electicité sur les sites en ACI et une autre table sur les sites en ACC sont disponibles.", "Dans la page bilan financier, il est possible de visualiser le bilan financier par producteur lorsque l'opération est composée de plusieurs producteurs."],
            icon: <OpenIcon disabled={false} className={'h-5 w-5 text-slate-600'}/>,
        },
        {
            title: 'Mise à jour du TURPE',
            descriptions: ['Nous utilisons le TURPE tel que défini par la CRE au 1er août 2022.'],
            icon: <BanknoteSolidIcon className={'h-6 w-6 text-sky-800'}/>,
        },
        {
            title: 'Prix de vente interne en opération ouverte',
            descriptions: ['Renseignez rapidement des prix de vente interne dans le formulaire étape 5 (paramètres financiers) en suivant les points suivants : ', '1. Choisissez le type de fonction à affecter : un prix fixe (€ HT /kWh) ou une réduction du tarif de complément (en %).', '2. Insérez le prix (chiffre positif) ou la réduction (en %) à affecter', '3. Séléctionnez/déselectionnez au choix : une ou plusieurs cellules, une colonne par un click sur le nom de la colonne, une ligne par click sur le nom de la ligne ou séléctionnez/déselectionnez toute les cellules avec un click sur la cellule en haut à gauche du tableau.', "4. Appuyer sur le bouton 'play' qui affecte les nouvelles valeurs.", 'NOTA : les points 1, 2 et 3 sont interchangeable.'],
            icon: <TableCellsIconOutlined classname={'h-5 w-5 text-sky-800'}/>,
        },
    ],
    minor: [
        {
            title: 'Modifier mon mot de passe',
            descriptions: ["Accès au changement de mot de passe par l'icône de profil puis click sur le bouton changer mon mot de passe."],
            icon: <LockClosedIconSolid className={'h-5 w-5 text-sky-800'}/>,
        },
        {
            title: "Activation / désactivation des points d'injection ou de soutirage",
            descriptions: ["Vous pouvez désactiver des points d'injection ou de soutirage au sein d'une même simulation (étape 2 et 3 du formulaire)."],
            icon: <NoSymbolIconOutlined className={'h-5 w-5 text-sky-800'}/>,
        },
        {
            title: 'Theming',
            descriptions: ["Accès au mode DARK et LIGHT par appui sur le switch dans la barre en haut de l'application. Par défaut, le theme est celui du navigateur."],
            icon: <MoonIcon classname={'h-5 w-5 text-sky-800'}/>,
        },
        {
            title: 'Notification de nouvelles fonctionnalités',
            descriptions: ["Découvrez les dernières fonctionnalités d'Enolab sur cette page (par l'icône dédiée dans la barre de navigation en haut de page). Sur l'application, elles sont notifiées par un tag sur fond rouge ou un point rouge."],
            icon: <NewsPaperIconOutline className={'h-5 w-5 text-sky-800 '}/>,
        },
        {
            title: 'Gestion du multi organisation',
            descriptions: ["Si vous faites parties de plusieurs organisations, il est possible de triez vos simulations dans la page d'accueil. ", "Dans le formulaire de création de simulation, il faudra rattacher l'organisation à la simulation à l'étape 1 (Informations générales)."],
            icon: <UsersIconOutlined className={'h-5 w-5 text-sky-800'}/>,
        },

        {
            title: "Duplication d'une simulation",
            descriptions: ['Vous pouvez désormais dupliquer une simulation existante. Cela vous coutera un crédit de simulation.'],
            icon: <DuplicateIconOutlined className={'h-5 w-5 text-sky-800'}/>,
        },
    ],bugFixes:[]
};
const WhatsNew101 = {
    version: '1.0.1',
    release_date: '20/09/2022',
    major: [],
    minor: [
        {
            title: 'Correction de bug : Edition de simulation',
            descriptions: ["Un utilisateur qui n'avait plus de crédit ne pouvait pas editer ses simulations existantes."],
            icon: <LockClosedIconSolid className={'h-5 w-5 text-sky-800'}/>,
        },
        {
            title: 'Gestion des erreurs : Téléchargement de courbe de charge',
            descriptions: ["Lorsque le téléchargement de courbe de charge n'est pas réalisé correctement, des erreurs spécifiques (API indisponible, code postal invalide, format de la courbe de charge) sont fournis à l'utilisateur."],
            icon: <DownloadIcon className={'h-5 w-5 text-sky-800'}/>,
        },
    ],bugFixes:[]
};
const WhatsNew102 = {
    version: '1.0.2',
    release_date: '08/10/2022',
    major: [],
    minor: [
        {
            title: 'Meilleur gestion des erreurs',
            descriptions: ["Nous avons amélioré la gestion des erreurs pour éviter l'apparition de pages blanches dans le formulaire de création et mise à jour de simulation."],
            icon: <BugAntOutlinedIcon className={'h-5 w-5 text-sky-800'}/>,
        },
        {
            title: 'Mise en cohérence du bilan économique et financier',
            descriptions: ["Dans le cas d'une simulation avec une inflation du tarif de fourniture de complément nul, les économies à 25 ans dans le bilan financier et dans le bilan économique étaient différentes. Nous avons corrigé ce point."],
            icon: <BanknoteSolidIcon className={'h-5 w-5 text-sky-800'}/>,
        },
        {
            title: 'Amélioration des performances',
            descriptions: ["Les calculs de la restitution de simulation ont été optimisés et le temps d'exécution de la simulation après création ou modification a été réduit."],
            icon: <WrenchScrewdriverIcon className={'h-5 w-5 text-sky-800'}/>,
        },
    ],bugFixes:[]
};
const WhatsNew120 = {
    version: '1.2.0',
    release_date: '16/11/2022',
    major: [
        {
            title: 'Nouveaux algorithmes de répartition',
            descriptions: ["Nous avons ajoutés les algorithmes de répartition dynamiques par priorité et par quote part. Rendez-vous dans la section PARAMÈTRES D'OPÉRATION du formulaire de création et mise à jour de simulation pour plus d'information"],
            icon: <BeakerIcon disabled={false} className={'h-5 w-5 text-sky-800'}/>,
        },
    ],
    minor: [],bugFixes:[]
};
const WhatsNew130 = {
    version: '1.3.0',
    release_date: '24/11/2022',
    major: [
        {
            title: 'Tableaux de synthèses',
            descriptions: ["Une vue agrégée de beaucoup d'informations, sous forme de tableaux."],
            icon: <ViewGridIcon className={'h-5 w-5 text-sky-800'}/>,
        },
        {
            title: 'Vue organisationelle',
            descriptions: ["Un schema explicatif de l'organisation, en fonction du type d'opération choisi."],
            icon: <UsersIconOutlined className={'h-5 w-5 text-sky-800'}/>,
        },
    ],
    minor: [],bugFixes:[]
};
const WhatsNew131 = {
    version: '1.3.1',
    release_date: '20/12/2022',
    major: [
        {
            title: 'Template de courbe de charges 10min, 30min (déjà existant) et 60 min',
            descriptions: ['Nous mettons à disposition de nouveaux templates de courbe de charges au pas de temps 10 minutes et 60 minutes.', "Vous pouvez les télécharger dans les étapes 2 (Points d'injection) et 3 (Points de soutirage) au moment d'ajouter une courbe de charge."],
            icon: <PaperClipIcon className={'h-5 w-5 text-sky-800'}/>,
        },
    ],
    minor: [
        {
            title: "Refonte visuelle - Paramètres d'opération",
            descriptions: ["Amélioration visuelle de l'étape 4 (Paramètres d'opération) du formulaire de création de simulation."],
            icon: <ViewGridIcon className={'h-5 w-5 text-sky-800'}/>,
        },
        {
            title: 'Refonte visuelle - Page de restitution',
            descriptions: ['Amélioration visuelle des tables de synthèses des pages de restitution de simulation.', 'Amélioration visuelle de la sidebar des pages de restitution de simulation.'],
            icon: <ViewGridIcon className={'h-5 w-5 text-sky-800'}/>,
        },
    ],bugFixes:[]
};
const WhatsNew150 = {
    version: '1.5.0',
    release_date: '06/06/2023',
    major: [
        {
            title: 'Inflation variable sur le tarif de complément',
            descriptions: ['Vous pouvez désormais affecter une inflation variable sur le tarif de complément sur la durée définie de simulation. ', 'Pour ce faire, dans les paramètres financiers, cliquez sur le toggle Inflation (fixe/ variable) et utilisez le widget.'],
            icon: <ReceiptPercentIcon className={'h-5 w-5 text-sky-800'}/>,
        },
        {
            title: 'Prix de vente interne avec indexation fixe',
            descriptions: ['Vous pouvez affecter une inflation sur le prix de vente interne.', 'Pour ce faire, dans les paramètres financiers, dans le tableau de prix de vente interne, choisissez la fonction [Prix indexé] puis ajouter un [prix de base] et une [inflation fixe].'],
            icon: <ReceiptPercentIcon className={'h-5 w-5 text-sky-800'}/>,
        },
        {
            title: 'Prix de vente interne avec indexation variable',
            descriptions: ['Vous pouvez affecter une inflation variable sur le prix de vente interne.', "Pour ce faire, dans les paramètres financiers, dans le tableau de prix de vente interne, choisissez la fonction [Prix indexé] puis ajouter un [prix de base] et une [inflation variable] à l'aide du widget d'inflation variable."],
            icon: <CurrencyEuroSolidIcon className={'h-5 w-5 text-sky-800'}/>,
        },
        {
            title: 'Récupération des profils type Enedis de 2022',
            descriptions: ["Les profils type Enedis pouvant être modélisés pour un point de soutirage sont désormais récupérés sur l'année 2022 en lieu et place de 2021."],
            icon: <ViewGridIcon className={'h-5 w-5 text-sky-800'}/>,
        },
        {
            title: "Partage gratuit d'électricité au sein d'opérations hybrides",
            descriptions: ["Pour la vente interne de vos opérations ouvertes, il est désormais possible de faire du partage d'électricité gratuit entre un producteur et un consommateur.", "Ce cas d'usage permet de modéliser plus finement le cas où une même personne morale possède un point de production et un point de consommation dans une opération et ne souhaite pas s'autofacturer."],
            icon: <ShareIcon className={'h-5 w-5 text-sky-800'}/>,
        },
    ],
    minor: [
        {
            title: 'Appellation : revente interne devient vente interne',
            descriptions: [],
            icon: <ViewGridIcon className={'h-5 w-5 text-sky-800'}/>,
        },
    ],bugFixes:[]
};
const WhatsNew151 = {
    version: '1.5.1',
    release_date: '20/06/2023',
    major: [
        {
            title: 'Récupération de la TVA',
            descriptions: ["L'activation de la récupération de TVA permet d' exécuter les calculs financier en HT ce qui est souvent nécessaire pour les participants (producteur, consommateur de type professionnel).", 'Vous pouvez activer la récupération de la TVA pour vos points de production et/ou de consommation.', "Par défaut, la récupération de la TVA n'est pas activée (TTC)."],
            icon: <CurrencyEuroSolidIcon className={'h-5 w-5 text-sky-800'}/>,
        },
    ],
    minor: [],bugFixes:[]
};
const WhatsNew152 = {
    version: '1.5.2',
    release_date: '30/06/2023',
    major: [
        {
            title: "Carte du périmètre de l'opération",
            descriptions: ["Vous pouvez désormais afficher vos sites sur une carte, pour visualiser s'ils font bien partie du périmètre.", "Pour ce faire, ajoutez ou modifiez vos points d'injection et de soutirage et ajoutez-leur une adresse (nom de rue, ou numéro d'habitation).", "Visualisez la carte dans le formulaire après l'ajout d'un point ou dans la page de restitution : Carte du périmètre.", "Si l'adresse est manquante, nous ne pouvons pas afficher le point sur la carte."],
            icon: <MapPinIconSolid className={'h-5 w-5 text-sky-800'}/>,
        },
    ],
    minor: [],bugFixes:[]
};
const WhatsNew153 = {
    version: '1.5.3',
    release_date: '11/07/2023',
    major: [],
    minor: [
        {
            title: "Optimisation technique de l'application",
            descriptions: ['Amélioration des performances de récupération des données énergétiques.'],
            icon: <ViewGridIcon className={'h-5 w-5 text-sky-800'}/>,
        },
        {
            title: 'Correction de bugs',
            descriptions: ["Le téléversement d'un template 60 minutes sur un point d'injection ne fonctionnait pas.", "Le téléchargement des templates de courbes de charge n'était pas fonctionnel via chrome", "Sous certaines conditions, la récupération d'adresse n'était pas fonctionnelle et causait l'impossibilité de sauvegarder un formulaire."],
            icon: <BugAntOutlinedIcon className={'h-5 w-5 text-sky-800'}/>,
        },
    ],bugFixes:[]
};

const WhatsNew154 = {
    version: '1.5.4',
    release_date: '04/08/2023',
    major: [
        {
            title: "Points de soutirage : Téléversement de courbe de charge incomplète & complétion",
            descriptions: ["Il est désormais possible de téléverser des courbes de charge avec moins d'une année de données (minimum : 6 mois de données sans trous).",
                "Nous proposons également de compléter vos courbes de charge lorsque celles-ci manquent de données. Après avoir téléversé une courbe incomplète, cliquez sur le bouton 'complétude annuelle' pour la compléter."],
            icon: <ChartBarIcon className={'h-5 w-5 text-sky-800'}/>,
        },
        {
            title: "Export des assets graphiques",
            descriptions: ['Vous pouvez désormais exporter au format .png les diagrammes (pie-charts) et les courbes de charge de vos simulations. Vous pouvez également copier-coller les données de vos courbes de charge sur Excel.'],
            icon: <ArrowUpTray className={'h-5 w-5 text-sky-800'}/>,
        },
        {
            title: "Export des tableaux de restitution",
            descriptions: ['Tous les tableaux de restitution sont exportables au format Excel (.xls).'],
            icon: <ArrowUpTray className={'h-5 w-5 text-sky-800'}/>,
        }
    ],
    minor: [],bugFixes:[]
};

const WhatsNew155 = {
    version: '1.5.5',
    release_date: '23/08/2023',
    major: [
        {
            title: "Ajout d'une FAQ",
            descriptions: ["Retrouvez les réponses à vos questions directement dans l'application."],
            icon: <QuestionMarkOutlined className={'h-5 w-5 text-sky-800'}/>,
        },
        {
            title: 'Amélioration Exports Excel et images',
            descriptions: ["Téléchargez tous vos graphiques en image.", "Téléchargez vos courbes de charge dans un fichier Excel plus facilement exploitable."],
            icon: <ArrowUpTray className={'h-5 w-5 text-sky-800'}/>,
        },
        {
            title: 'Configuration du diamètre de périmètre',
            descriptions: ["Visualisez le cercle du périmètre avec un diamètre personnalisable."],
            icon: <MapPinIconSolid className={'h-5 w-5 text-sky-800'}/>,
        },
        {
            title: 'Template 5mn',
            descriptions: ["Téléchargez un template 5mn pour vos études."],
            icon: <PaperClipIcon className={'h-5 w-5 text-sky-800'}/>,
        },
    ],
    minor: [],
    bugFixes:[]
};
const WhatsNew156 = {
    version: '1.5.6',
    release_date: '01/09/2023',
    major: [
        {
            title: "Recalage en énergie d'une courbe de charge",
            descriptions: ["Génerez une courbe de charge à partir d'un volume annuel et d'un profil de courbe de charge."],
            icon: <AdjustmentIcon className={'h-5 w-5 text-sky-800'}/>,
        },
        {
            title: "Naviguez vers d'autre simulations",
            descriptions: ["Naviguez plus facilement vers d'autres simulations avec la barre de recherche sans repasser par la page d'accueil. Pour cela, recherchez une simulation par son nom."],
            icon: <MagnifyingOutlinedIcon className={'h-5 w-5 text-sky-800'}/>,
        },
    ],
    minor: [
        {
            title: "Amélioration de la récupération d'adresses",
            descriptions: ["Récupérez la liste exhaustive de communes partageant le même code postal."],
            icon: <BugAntOutlinedIcon className={'h-5 w-5 text-sky-800'}/>,
        },
        {
            title: "Energie totale d'une courbe de charge et puissance maximale",
            descriptions: ["Visualisez l'énergie totale (en kWh) et la puissance maximale (en W) d'une courbe de charge téléchargées ou modélisée par Enedis."],
            icon: <InformationCircleOutlinedIcon className={'h-5 w-5 text-sky-800'}/>,
        },
        {
            title: "Mise à jour du TURPE et de l'IFER",
            descriptions: ["Réalisez vos bilans financiers en accord avec les changements de fiscalité d'Aout 2023 (TURPE, IFER)."],
            icon: <ArrowPathRoundIcon className={'h-5 w-5 text-sky-800'}/>,
        },
    ],
    bugFixes:[]
};
const WhatsNew157 = {
    version: '1.5.7',
    release_date: '05/09/2023',
    major: [
        {
            title: "Variabilisation de la TVA ",
            descriptions: ["Configurez le niveau de TVA sur la part variable et la part fixe de la facture d'électricité.",
                "Effectuez des études de potentielles dans les ZNI."],
            icon: <AdjustmentIcon className={'h-5 w-5 text-sky-800'}/>,
        },

    ],
    minor: [],
    bugFixes:[]
};

const WhatsNew158 = {
    version: '1.5.8',
    release_date: '12/09/2023',
    major: [
        {
            title: "Modélisation de l'emprunt",
            descriptions: ["Configurez l'emprunt de vos capex.",
                "Visualisez le remboursement de l'emprunt dans le bilan financier."],
            icon: <AdjustmentIcon className={'h-5 w-5 text-sky-800'}/>,
        },

    ],
    minor: [],
    bugFixes:[]
};
const WhatsNew159 = {
    version: '1.5.9',
    release_date: '19/09/2023',
    major: [
        {
            title: "Complétude de courbes de charge ayant peu de données",
            descriptions: ["Complétez une courbe de charge incomplète avec la 'complétude peu de données'."],
            icon: <PuzzlePieceIconSolid className={'h-5 w-5 text-sky-800'}/>,
        },

    ],
    minor: [],
    bugFixes:[]
};
const WhatsNew1510 = {
    version: '1.5.10',
    release_date: '11/10/2023',
    major: [
        {
          title: "Modélisation de tiers investisseur",
            descriptions: ["Modélisez sur vos producteurs un tiers investisseur qui prend en charge une partie des capex."],
            icon: <PuzzlePieceIconSolid className={'h-5 w-5 text-sky-800'}/>,
        }
    ],
    minor: [],
    bugFixes : [{
        type : 'résolu',
        description : 'Résolution d\'un bug qui empêchait l\'édition des champs heure pleine / heure creuse dans les paramètres financiers.'
    }]
};

const WhatsNew1511 = {
    version: '1.5.11',
    release_date: '18/10/2023',
    major: [
        {
            title: "Cartographie : Vérification de l'éligibilité à un périmètre dérogatoire",
            descriptions: ["Vérifiez l'éligibilité de vos sites à un périmètre dérogatoire."],
            icon: <MapIconSolid className={'h-5 w-5 text-sky-800'}/>,
        },
        {
            title: "Visualisation du bilan financier par producteur",
            descriptions: ["Visualisez sur vos opérations patrimoniales et sociales le bilan financier par producteur."],
            icon: <BanknotesIcon className={'h-5 w-5 text-sky-800'}/>,
        },

    ],
    minor: [],
    bugFixes : []
};

const WhatsNew161 = {
    version: '1.6.1',
    release_date: '04/01/2024',
    major: [
        {
            title: "Participation saisonnière des consommateurs",
            descriptions: [
                "Vous pouvez désormais ajouter de la saisonnalité sur vos consommateurs dans les paramètres d'opération."
            ],
            icon: <AdjustmentIcon className={'h-5 w-5 text-sky-800'}/>,
        },

    ],
    minor: [{
        title: "Modélisation PVGIS avec pertes à 20% par défaut",
        descriptions: [
            "Lors de la modélisation avec PVGIS, les pertes sont désormais à 20% par défaut. Ces pertes sont modifiables."],
        icon: <AdjustmentIcon className={'h-5 w-5 text-sky-800'}/>,
    },],
    bugFixes: []
};

const WhatsNew160 = {
    version: '1.6.0',
    release_date: '20/11/2023',
    major: [
        {
            title: "Explorez plus rapidement les paramètres financiers de vos études",
            descriptions: [
                "Lors de l'exécution d'une simulation, le plus long est le calcul de la répartition énergétique. " +
                "Aussi, dorénavant, lorsque vous changez un paramètre financier, EnoLab ne recalcule pas la répartition " +
                "énergétique. Le temps d'exécution est donc considérablement réduit."],
            icon: <RocketIcon className={'h-5 w-5 text-sky-800'}/>,
        },

    ],
    minor: [],
    bugFixes: [
        {
            type: 'résolu',
            description: 'Le calcul du gain prend mal en compte le partage gratuit.'
        },
        {
            type: 'résolu',
            description: 'Erreur lors de la copie d\'une simulation.'
        },
        {
            type: 'résolu',
            description: 'Inflation non prise en compte sur l\'économie ACI.'
        }
    ]
};

const WhatsNew162 = {
    version: '1.6.2',
    release_date: '23/01/2024',
    major: [
        {
            title: "Répartition prioritaire du surplus de production",
            descriptions: ["Dans les paramètres d'opération, optimisez la distribution du surplus de production avec une stratégie personnalisée qui respecte les priorités de chaque producteur."],
            icon: <ScaleIcon className={'h-5 w-5 text-sky-800'}/>,
        },
        {
            title: "Explication des algorithmes de répartition de la production et du surplus",
            descriptions: ["Dans la FAQ, retrouvez les explications imagées des algorithmes de répartition de la production et du surplus."],
            icon: <InformationCircleIcon className={'h-5 w-5 text-sky-800'}/>,
        },

    ],
    minor: [
        {
            title: "Accélération de la modélisation de courbe de charge ayant peu de données",
            descriptions: ["Cette fonctionnalité simplifie le processus de modélisation en fournissant un tableau pré-conçu de 12 colonnes."],
            icon: <ChartBarIcon className={'h-5 w-5 text-sky-800'}/>,
        },
    ],
    bugFixes: []
};
const WhatsNew163 = {
    version: '1.6.3',
    release_date: '01/02/2024',
    major: [
        {
            title: "Répartition prioritaire d'énergie avec producteurs dégroupés",
            descriptions: ["Distribuez de manière ciblée votre production locale, en définissant une priorité pour chaque paire producteur-consommateur."],
            icon: <ScaleIcon className={'h-5 w-5 text-sky-800'}/>,
        }

    ],
    minor:[],
    bugFixes: []
};

const WhatsNew170 = {
  version: '1.7.0',
  release_date: '14/02/2024',
  major: [
    {
      title: <TitleWithLink
          title="Mise à jour des coefficients de l'accise sur l'électricité"
          url="https://www.legifrance.gouv.fr/loda/id/LEGISCTA000049064398/2024-02-01/"
          description="voir l'article sur legifrance"/>,
      descriptions: ["Ménages et assimilés : 21€/MWh", " Petites et moyennes entreprises : 20,5€/MWh", "Haute puissance : 20,5€/MWh", "Applicable au 1er février 2024"],
      icon: <AdjustmentIcon className={'h-5 w-5 text-sky-800'}/>,
    },
    {
      title: <TitleWithLink
          title="Ajout de l'IFER pour les producteurs éoliens"
          url="https://www.economie.gouv.fr/entreprises/imposition-forfaitaire-entreprises-reseaux-ifer"
          description="plus d'information sur economie.gouv.fr"/>,
      descriptions: [
          "L'IFER concerne uniquement les centrales dont la puissance est supérieure ou égale à 100 kilowatts.",
          "Cet impôt s'élève à 8,16 € par kilowatt de puissance installée par an.",
          "En rappel, pour les centrales photovoltaïques ou hydrauliques le montant est de 3,394€ par kilowatt de puissance installée par an."],
      icon: <AdjustmentIcon className={'h-5 w-5 text-sky-800'}/>,
    }
  ],
  minor: [],
  bugFixes: []
};

const WhatsNew1_9 = {
  version: '1.9.0',
  release_date: '03/09/2024',
  major: [],
  minor: [
    {
      title: <TitleWithLink
          title="Mise à jour de l'IFER"
          url="https://www.legifrance.gouv.fr/codes/id/LEGISCTA000022877271"
          description="voir l'article sur legifrance"/>,
      descriptions: [
        "Pour des puissance supérieures à 100 kilowatts",
        "Photovoltaïque : 3,479 €/kW",
        "Hydraulique : 3,479 €/kW",
        "Eolienne : 8,36 €/kW"],
      icon: <ArrowPathRoundIcon className={'h-5 w-5 text-sky-800'}/>,
    },
],
  bugFixes: []
};


const lastRelease = WhatsNew1_9;
const releasesHistory = [WhatsNew170, WhatsNew163, WhatsNew162,WhatsNew161, WhatsNew160,
  WhatsNew1511, WhatsNew1510, WhatsNew159, WhatsNew158, WhatsNew157, WhatsNew156,
  WhatsNew155, WhatsNew154, WhatsNew153, WhatsNew152, WhatsNew151, WhatsNew150,
  WhatsNew131, WhatsNew130, WhatsNew120, WhatsNew102, WhatsNew101, WhatsNew100];

export default function PopupModal({action}: PopupModalType) {
    const [versionSelected, setVersionSelected] = useState<string | undefined>(lastRelease.version);
    const [whatsNewWidth, setWhatsNewWidth] = useState(' w-0 ');
    const [whatsNewWidthReverse, setWhatsNewWidthReverse] = useState(' w-0 ');

    useEffect(() => {
        setWhatsNewWidth(' w-8/12 ');
        setWhatsNewWidthReverse(' w-4/12 ');
    }, []);

    function handleExpandClick(versionClicked: string) {
        if (versionClicked !== versionSelected) {
            setVersionSelected(versionClicked);
        } else {
            setVersionSelected(undefined);
        }
    }

    return (
        <div className="absolute fixed top-[60px] right-0 z-[60] w-screen h-full-minus-navbar"
             aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className={'flex h-full w-full'}>
                <div
                    className={whatsNewWidthReverse + ' bg-gray-400  transition-all-fast h-full bg-opacity-70 dark:bg-gray-800 dark:bg-opacity-40'}
                    onClick={action}></div>
                <div className={whatsNewWidth + ' bg-white dark:bg-gray-800 h-full right-0'}>
                    <div
                        className={whatsNewWidth + ' flex flex-col overflow-y-auto transition-all-fast fixed h-full-minus-navbar right-0'}>
                        <div className={'bg-yellow-500 dark:bg-amber-500 '}>
                            <div className={'flex justify-between  p-4'}>
                                <div className={'flex px-4'}>
                                    <div className={'text-3xl font-black text-white uppercase '}>enolab</div>
                                </div>
                                <div onClick={action} className={'hover:cursor-pointer'}>
                                    <XIcon className={'h-5 w-5 text-white'}/>
                                </div>
                            </div>
                            <div className={'text-2xl font-light text-white px-8 pb-9'}>Étudiez rapidement vos projets
                                d'autoconsommation collective
                            </div>
                        </div>
                        <div
                            className={'px-8 text-gray-600 dark:text-gray-400 bg-white font-semibold dark:bg-gray-800 pt-9 pb-2'}>Dernière
                            livraison
                        </div>
                        <WhatsNew version={lastRelease.version} release_date={lastRelease.release_date} bugfixes={lastRelease.bugFixes}
                                  major={lastRelease.major} minor={lastRelease.minor}
                                  selected={versionSelected === lastRelease.version}
                                  handleExpandClick={handleExpandClick}/>
                        <div
                            className={'px-8 text-gray-600 dark:text-gray-400 bg-white font-semibold dark:bg-gray-800 pb-2'}>Anciennes
                            versions
                        </div>
                        {releasesHistory.map((whatsnew) => {
                            return <WhatsNew version={whatsnew.version} release_date={whatsnew.release_date} bugfixes={whatsnew.bugFixes}
                                             major={whatsnew.major} minor={whatsnew.minor}
                                             selected={versionSelected === whatsnew.version}
                                             handleExpandClick={handleExpandClick}/>;
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}
