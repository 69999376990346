import {RestitutionGatewayInterface} from "../../../../corelogic/usecases/restitution/restitutionGateway.interface";
import apiClient from "../Api/ApiClient";

export class RestitutionApiGateway implements RestitutionGatewayInterface {
    getRestitution = async (simulationId: string): Promise<any> => {
        // TODO return Promise<Restitution>
        const {data: value} = await apiClient.get(`/simulations/${simulationId}/restitution`);
        return value;
    }
}
