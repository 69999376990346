import {useDispatch, useSelector} from "react-redux";
import {selectIsDarkMode} from "../../../../../../corelogic/usecases/config/configSelector";
import React, {useEffect, useState} from "react";
import {configActions} from "../../../../../../corelogic/usecases/config/configActions";
import {SunIconOutlined} from "../../../components/assets/icon/SunIcon";
import {MoonIconOutlined} from "../../../components/assets/icon/MoonIcon";

const ThemeIcon = () => {
    const isDarkMode = useSelector(selectIsDarkMode)
    const [darkMode, setDarkMode] = useState<boolean>(isDarkMode)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(configActions.setDarkMode(darkMode))
    }, [darkMode])

    return <>
        <span className={"pr-1 text-zinc-100 text-xs font-semibold dark:text-zinc-700"}>
                    <SunIconOutlined classname={"h-4 w-4"}/>
                </span>
        <label htmlFor="default-toggle" className="inline-flex relative items-center cursor-pointer">
            <input type="checkbox" value="" id="default-toggle" className="sr-only peer" checked={darkMode}/>
            <div
                onClick={() => setDarkMode(!darkMode)}
                className="w-11 h-6 bg-yellow-500   rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border after:rounded-full after:h-5 after:w-5 after:transition-all   peer-checked:bg-slate-700">
            </div>
            <span className={"pl-1 text-slate-500 text-xs font-semibold dark:text-slate-200"}>
                        <MoonIconOutlined classname={"h-4 w-4"}/>
                    </span>
        </label>
    </>
}

export default ThemeIcon