import React, {useEffect, useState} from "react";
import {authenticationActions} from "../../../../../corelogic/usecases/authentication/authenticationActions";
import {useDispatch, useSelector} from "react-redux";
import profilePic from "../../utils/profil.png"
import {selectEmail, selectName} from "../../../../../corelogic/usecases/authentication/authenticationSelector";
import {push} from "connected-react-router";
import {wait} from "@testing-library/user-event/dist/utils";


export default function ({dataCy}: {dataCy : string}) {
    const [openDropDownMenu, setOpenDropDownMenu] = useState(false)
    const [askForQuestion, setAskForQuestion] = useState(false)

    const dispatch = useDispatch()
    const name = useSelector(selectName)
    const email = useSelector(selectEmail)
    const win: any = window

    win.addEventListener("chatwoot:ready", function () {
        // Use window.$chatwoot here
        win.$chatwoot.toggle();
        win.$chatwoot.toggleBubbleVisibility("hide");
    })

    useEffect(()=> {
        if(askForQuestion){
            openChatwootChatOnClick()
        }
    },[askForQuestion])

    function openChatwootChatOnClick(){
        win.$chatwoot.toggle("open");
        win.$chatwoot.toggleBubbleVisibility("hide");
    }

    function triggerChatwootChat(){
        setAskForQuestion(false)
        wait(100).then(() => setAskForQuestion(true))
    }

    return <div className="relative inline-block text-left">
        <div>
            <button type="button"
                    className="flex mr-3 text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                    onClick={() => setOpenDropDownMenu(!openDropDownMenu)}
                    id="user-menu-button" aria-expanded="false" data-dropdown-toggle="dropdown">
                <img className="w-8 h-8 rounded-full" src={profilePic} alt="user photo"/>
            </button>
        </div>

        {openDropDownMenu && <div
            data-cy = {dataCy}
            className="origin-top-right z-60 absolute right-0 mt-2 min-w-[270px] rounded-md shadow-lg bg-white dark:bg-zinc-900 ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex={-1}
            onMouseLeave={() => setOpenDropDownMenu(false)}>
            <div className="py-1 z-[1] relative" role="none">
                <span
                    className={"text-gray-600 dark:text-zinc-400 uppercase  tracking-wide font-bold block w-full text-left px-4 pt-2 text-[0.7rem]"}>compte</span>
                <span
                    className={"text-gray-700 dark:text-zinc-300 text-sm font-black block w-full text-left px-4 text-sm"}>{name}</span>
                <span
                    className={"text-gray-400 dark:text-zinc-600 block w-full text-left font-semibold px-4 py-2 text-[0.7rem]"}> connecté en tant que {email}</span>
                <hr className="my-2 w-full border-zinc-400 dark:border-zinc-600"/>
                <button type="submit"
                        className="text-gray-700 dark:text-zinc-300 block w-full text-left px-4 py-2 text-sm hover:bg-slate-100 dark:hover:bg-zinc-800"
                        onClick={() => dispatch(push('/change-password'))}
                        role="menuitem" id="menu-item-3">Modifier mon mot de passe
                </button>
                <button type="submit"
                        className="text-gray-700 dark:text-zinc-300 block w-full text-left px-4 py-2 text-sm hover:bg-slate-100 dark:hover:bg-zinc-800"
                        onClick={triggerChatwootChat}
                        role="menuitem" id="menu-item-3">Une requête ou une question ?
                </button>
                <button type="submit"
                        className="text-gray-700 dark:text-zinc-300 block w-full text-left px-4 py-2 text-sm hover:bg-slate-100 dark:hover:bg-zinc-800"
                        onClick={() => dispatch(authenticationActions.logOut())}
                        role="menuitem" id="logout-btn">Se déconnecter
                </button>

            </div>
        </div>}
    </div>


}
