import React from "react";
import inclinaisonGif from "../../utils/inclinaison.gif"
export default function () {
    return <div
        className=" absolute right-[80px] z-50 bottom-[105px] max-w-full h-0 w-0  mt-2  shadow-lg  hover:cursor-default "
        role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex={-1}>
        <div className="py-1 z-[1] w-[650px] bg-white text-gray-700 dark:bg-zinc-800 dark:text-zinc-200 rounded-md" role="none">
            <span
                className={" block w-full text-left px-4 pt-2 text-md font-semibold"}>Angle d'inclinaison ou pente</span>
            <hr className="my-3 w-full"/>
            <div className={"flex flex-row justify-center"}>
                <img height="150px" width="150px" src={inclinaisonGif} alt={"inclinaison_gif"}/>
                <div className={"flex flex-col"}>
                <span className={"whitespace-pre-line  block w-full text-left px-4 pt-2 text-sm"}>
                    C'est l'angle entre le plan des panneaux photovoltaïques et l'horizontale, pour un montage fixe (sans système de suivi).
                </span>
                    <span className={"whitespace-pre-line  block w-full text-left px-4 py-2 text-sm"}>
                    Pour certaines applications, l'angle d'inclination et d'orientation seront déjà établis. Par exemple si les panneaux PV seront installés sur un toit existant. Cependant, si vous pouvez choisir l'inclinaison et/ou l'azimut (orientation), cette application peut également calculer les angles d'inclinaison et d'orientation optimaux (en supposant des angles fixes pour toute l'année).
                </span>
                </div>

            </div>

        </div>
    </div>
}