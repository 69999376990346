export const PriorityInput = ({ valeur, onChange, min, max }:any) => {
    const handleChange = (e:any) => {
        const value = parseInt(e.target.value);
        if (!isNaN(value) && value >= min && value <= max) {
            onChange(value);
        }
    };

    return (
        <input
            type="number"
            value={valeur}
            onChange={handleChange}
            min={min}
            max={max}
            className="border-none w-[40px] dark:text-zinc-200  px-2 py-.5 rounded-sm dark:bg-zinc-800 dark:bg-opacity-60  dark:border-zinc-800
      bg-white border-gray-300 focus:border-blue-500 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
        />
    );
};
