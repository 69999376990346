import {InjectionPoint} from "./InjectionPoint";
import {ConsumptionPoint} from "./ConsumptionPoint";

export class Typology {
     _injectionPoints: InjectionPoint[];
     _consumptionPoints: ConsumptionPoint[];


    constructor(injectionPoints: InjectionPoint[], consumptionPoints: ConsumptionPoint[]) {
        this._injectionPoints = injectionPoints;
        this._consumptionPoints = consumptionPoints;
    }
    serialize(){
        return{
            injection_points : this._injectionPoints.map((injectionPoint:InjectionPoint) => {
                return injectionPoint.serialize()
            }),
            consumption_points : this._consumptionPoints.map((consumptionPoint:ConsumptionPoint) => {
                return consumptionPoint.serialize()
            })
        }
    }
    get injectionPoints(): InjectionPoint[] {
        return this._injectionPoints;
    }

    set injectionPoints(value: InjectionPoint[]) {
        this._injectionPoints = value;
    }

    get consumptionPoints(): ConsumptionPoint[] {
        return this._consumptionPoints;
    }

    set consumptionPoints(value: ConsumptionPoint[]) {
        this._consumptionPoints = value;
    }
}