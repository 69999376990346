import {useDispatch, useSelector} from "react-redux";
import {typologyFormActions} from "../../../../../../../corelogic/usecases/typology-form/typologyFormActions";
import {ViewFinderSolidCircle} from "../../../../components/assets/icon/ViewfinderCircle";
import React from "react";
import {
    selectAllPointsHaveAddress,
    selectOneInjAndProdPoints
} from "../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectInjectionPointForm";
import {useSnackbar} from "notistack";
import SpinningSVG from "../../../../components/assets/Animated/SpinningSVG";
import {selectIsEnoptiLoading} from "../../../../../../../corelogic/usecases/loader/loaderSelector";
import {selectCartoCircleInKm} from "../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";

function Enoptimize() {

    return <div className="flex items-center gap-4 justify-between w-full">
        <label htmlFor="default-range"
               className="block flex items-center gap-1 text-sm font-medium text-gray-700 whitespace-nowrap">
            <div
                className={"flex items-center px-1 py-0.5 bg-gray-700 w-min rounded-sm hover:cursor-help"}
                title={"Fonctionnalité expérimentale : Risque d'erreur de calcul"}>
                <p className={" uppercase text-gray-100 text-xs font-semibold "}>ALPHA</p>
            </div>
            Trouvez le périmètre qui minimise le surplus parmis tout vos points
        </label>
        <PlaceCircleButton/>
    </div>
}

const PlaceCircleButton = () => {
    const isLoading = useSelector(selectIsEnoptiLoading)
    const atLeastOneInjAndProdActive = useSelector(selectOneInjAndProdPoints)
    const allPointsHaveAddress = useSelector(selectAllPointsHaveAddress)
    const canUseEnopti = atLeastOneInjAndProdActive && allPointsHaveAddress
    const dispatch = useDispatch()
    const {enqueueSnackbar} = useSnackbar()
    const disabledButton = "flex gap-1 rounded bg-orange-200 text-orange-500 text-xs px-2 py-1"
    const enabledButton = " flex gap-1 rounded bg-orange-200 text-orange-700 px-2 py-1 text-xs hover:bg-orange-300"

    function handleClick(){
        if(canUseEnopti){
            dispatch(typologyFormActions.findBestCirclePlace())
        } else {
            if(!allPointsHaveAddress){
                enqueueSnackbar("Tout les points doivent avoir une addresse.", {variant: "warning"})
            }
            if(!atLeastOneInjAndProdActive){
                enqueueSnackbar("Il doit y avoir au moins 1 point d'injection et un point de soutirage avec addresse.", {variant: "warning"})
            }
        }
    }

    return <button
        onClick={handleClick}
        className={canUseEnopti ? enabledButton : disabledButton}>
        {isLoading ?'Optimisation ..' : 'Placer le cercle' }
        {isLoading ?
            <SpinningSVG className={ "w-4 h-4 text-orange-200 animate-spin dark:text-white fill-orange-900"} /> :
            <ViewFinderSolidCircle className={canUseEnopti ? "h-4 w-4 text-orange-700" : "h-4 w-4 text-orange-500"}/>}
    </button>
}
export default Enoptimize
