import {PriorityInput} from "../PriorityInput";
import {
    UseSurplusAllocation
} from "../../../../../../../corelogic/usecases/operation-parameters-form/surplus_allocation/useSurplusAllocation";

export default function SurplusAllocationPriorityTable(surplusAllocation:UseSurplusAllocation) {
    return <div
        className="flex flex-col mt-4">
        <span className={"font-semibold whitespace-nowrap text-md text-gray-700 dark:text-gray-300  my-2 pt-4"}>
            Répartition du surplus par priorité
        </span>
        <div className="flex flex-col">
            <div className="flex flex-row justify-between">
                <div className="flex flex-row gap-4">
                    <span className="text-gray-600 dark:text-gray-300 text-xs flex-wrap flex">
                        <b>1</b> : priorité la plus haute
                    </span>
                </div>
            </div>
            <Table {...surplusAllocation}/>
        </div>
    </div>
}



const Table = (surplusAllocation:UseSurplusAllocation) =>  {

    return (
        <table className="table-auto w-full my-4">
            <thead>
            <tr className={"text-gray-600 dark:text-gray-300 text-sm"}>
                <th className="px-4 py-2 text-left">Point d'injection</th>
                <th className="px-4 py-2 text-left">Priorité</th>
            </tr>
            </thead>
            <tbody>
            {surplusAllocation.value.priorities.map((item, index) => (
                <tr key={index} className={`
                ${index % 2 === 0 ? ' bg-gray-50 dark:bg-zinc-800 dark:bg-opacity-30 ' : '  bg-zinc-100 dark:bg-zinc-800 dark:bg-opacity-[15%] '}
                ${item.pointState ? ' ' : ' opacity-40 '}
                `}>
                    <td className="border dark:border-zinc-700 px-4 py-1 text-sm text-gray-700 dark:text-gray-300">{item.pointName}</td>
                    <td className="border dark:border-zinc-700 px-4 py-1  text-gray-700 dark:text-gray-300">
                        <PriorityInput  min={1} max={9} valeur={item.priority} onChange={(val:any) => surplusAllocation.onPriorityChange(index, val)} />
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
    );
};



