import {Route, Switch} from "react-router";
import React, {useEffect} from "react";
import Home from "./containers/Home";
import AuthenticatedRoute from "./components/utils/AuthenticatedRoute";
import NotFound from "./containers/NotFound";
import NewSimulationForm from "./containers/NewSimulationForm";
import PhysicalResult from "./containers/PhysicalResult";
import EconomicalResult from "./containers/EconomicalResult";
import Login from "./containers/Login"
import ChangePassword from "./containers/ChangePassword";
import BalanceSheet from "./containers/BalanceSheet";
import RecapTableResult from "./containers/RecapTableResult";
import OrganisationalView from "./containers/OrganisationalView";
import PerimeterMapView from "./containers/PerimeterMapView";
import {useDispatch} from "react-redux";
import {physicalRestitutionActions} from "../../../corelogic/usecases/physicalRestitution/physicalRestitutionActions";
import FAQ from "./containers/FAQ";

export default function Routes() {
    return <Switch>
        <AuthenticatedRoute exact path={"/"} Component={Home}/>
        <AuthenticatedRoute exact path={"/help"} Component={FAQ}/>
        <Route exact path={"/login"} component={Login}/>
        <AuthenticatedRoute exact path={"/change-password"} Component={ChangePassword}/>
        <AuthenticatedRoute exact path={"/simulation/:id/edit"} Component={NewSimulationForm}/>
        <AuthenticatedRoute exact path={"/simulation/create"} Component={NewSimulationForm}/>
        <ResultRoutes/>

        <Route component={NotFound}/>
    </Switch>
}

const ResultRoutes = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        return () => {
            dispatch(physicalRestitutionActions.initData())
            dispatch(physicalRestitutionActions.initSelectedPoint())
        }
    }, [])

    return <div>
        <AuthenticatedRoute exact path={"/simulation/:id/physical-result"} Component={PhysicalResult}/>
        <AuthenticatedRoute exact path={"/simulation/:id/financial-result"} Component={BalanceSheet}/>
        <AuthenticatedRoute exact path={"/simulation/:id/economical-result"} Component={EconomicalResult}/>
        <AuthenticatedRoute exact path={"/simulation/:id/recap-table-result"} Component={RecapTableResult}/>
        <AuthenticatedRoute exact path={"/simulation/:id/organisational-result"} Component={OrganisationalView}/>
        <AuthenticatedRoute exact path={"/simulation/:id/perimeter-map"} Component={PerimeterMapView}/>
    </div>
}
