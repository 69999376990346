import {createAction} from "../../../store/createAction";
import {ActionsUnion} from "../../../store/actionsUnions";

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const RESET_SNACKBAR = 'RESET_SNACKBAR';

export const snackBarActions = {
    enqueueSnackbar: (message: string, options: any) => createAction(ENQUEUE_SNACKBAR, {message, options}),
    resetSnackbar: () => createAction(RESET_SNACKBAR)
}
export type Actions = ActionsUnion<typeof snackBarActions>;