import * as XLSX from "xlsx";
import {ClipboardDocumentListSolid} from "../components/assets/icon/ClipboardDocumentListIcon";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectSelectedType} from "../../../../corelogic/usecases/physicalRestitution/physicalRestitutionSelector";
import {Type} from "../containers/PhysicalResult";
import {
    selectSimulationName
} from "../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectGeneralInfoForm";
import {
    selectTypes
} from "../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectInjectionPointForm";

export default function XLSExportButton({data, title}: { data: any[], title:string }) {
    const simulationName=  useSelector(selectSimulationName)
    const selectedType = useSelector(selectSelectedType)
    const types = useSelector(selectTypes)

    const [selectedName, setSelectedName] = useState('')

    useEffect(()=> {
        setSelectedName(types.find((type:Type)=>type.id ===selectedType.id)?.name ||  '')
    },[selectedType])

    const accOrACI = selectedType.aci ? 'ACI' : 'ACC'

    const exportDataToExcel = () => {
        // Create a new workbook
        const workbook = XLSX.utils.book_new();


        // I don't know why, but restitution curve are loaded with a header (0 1 2 3), but not form curves (their title are ENEDIS_CDC, SGE_CDC & CDC_UPLOAD
        // so we remove header for restitution curve loaded to excel
        const ignoreHeaderForCDCResults =  title !== "ENEDIS_CDC" && title !== "SGE_CDC" && title !== "CDC_UPLOAD"

        // Create a worksheet and add data
        const worksheet = XLSX.utils.json_to_sheet(data, {skipHeader : ignoreHeaderForCDCResults});

        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        // Write the workbook to a file
        XLSX.writeFile(workbook, `${simulationName}_${title}_${selectedName}_${accOrACI}.xlsx`);
    }
    return (
        <button
            type={"button"}
            id={'export-to-excel'}
            onClick={exportDataToExcel}
            title={'Exporter sur excel'}
            className={" p-2 font-semibold hover:cursor-pointer  rounded bg-gray-200  dark:bg-zinc-800 opacity-40 hover:opacity-100"}>
            <ClipboardDocumentListSolid className={'w-5 h-5  text-gray-600 dark:text-zinc-300   '}/>
        </button>
    );
}