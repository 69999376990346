import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectFormVariableTaxValue} from "../../../../../../../../corelogic/usecases/form/formSelector";
import {formActions} from "../../../../../../../../corelogic/usecases/form/formActions";
import {makePercentNumberIsValid} from "../../../../../../../../corelogic/usecases/new-simulation/ValidatorsSelector";
import {ErrorMsgInput} from "../../../../../components/assets/FormAssets/TextInput";

const VariableVATRateInput = () => {
    const dispatch = useDispatch()
    const taxValue = useSelector(selectFormVariableTaxValue)
    const selectTaxInputIsValid = useCallback(makePercentNumberIsValid(taxValue.toString()), [taxValue])
    const isValid = useSelector(selectTaxInputIsValid)
    const handleChange = (e: any) => {
        const inputValue = e.target.value.replace(/,/g, ".").replace(/\s/g, '')
        dispatch(formActions.setVariableTax(inputValue));
    };

    return (
        <div className="relative">
            <div className={"flex"}>
                <input
                    type="text"
                    className={"appearance-none bg-gray-50 w-20 border border-gray-300 text-gray-900 text-sm rounded-r-lg border-l-gray-100 dark:border-l-zinc-700 border-l-2 focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-zinc-700 dark:border-zinc-600 dark:placeholder-zinc-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"}
                    placeholder="20"
                    value={taxValue}
                    onChange={handleChange}
                />
                <div
                    className={"absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none text-gray-700 dark:text-gray-200"}>
                    %
                </div>
            </div>

            <div className={"w-0 h-0  relative"}>
                <ErrorMsgInput error={{state: !isValid, msg: "La taxe doit être comprise entre 0 et 100%"}}/>
            </div>

        </div>
    );
}
export default VariableVATRateInput
