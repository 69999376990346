import {createSelector} from "reselect";
import {selectSimulationForm} from "../form/newSimulationSelector";
import {
    DistributionAlgorithm
} from "../../../../models/types/new-simulation/form/operation-parameters/distribution/DistributionAlgorithm";

const selectOperationParameters = createSelector(
    selectSimulationForm,
    simulationForm => simulationForm?._operation_parameters
);
const selectDistributionAlgorithm = createSelector(
    selectOperationParameters,
    operationParameters => operationParameters?.distribution_algorithm || null
);

const selectDistributionPriorities = createSelector(
    selectOperationParameters,
    operationParameters => operationParameters?.distribution_priorities || null
);
const selectDistributionByUngroupedPriority = createSelector(
    selectOperationParameters,
    operationParameters => operationParameters?.distribution_ungrouped_priorities || null
);
const selectDistributionShares = createSelector(
    selectOperationParameters,
    operationParameters => operationParameters?.distribution_shares || null
);

const selectSeasonalParameters = createSelector(
    selectOperationParameters,
    operationParameters => operationParameters?.seasonality || null
);
const selectSurplusAllocation = createSelector(
    selectOperationParameters,
    operationParameters => operationParameters?.surplus_allocation || null
);


export {
    selectDistributionByUngroupedPriority,
    selectSurplusAllocation,
    selectOperationParameters,
    selectSeasonalParameters,
    selectDistributionAlgorithm,
    selectDistributionPriorities,
    selectDistributionShares,
}
