import React, {useEffect, useRef, useState} from "react";
import ToggleBtn from "../components/assets/toggle/ToggleBtn";
import {useDispatch, useSelector} from "react-redux";
import CDCLineChart from "../composition/chart/CDCLineChart";
import CDCGroupedByMonthLineChart from "../composition/chart/CDCGroupedByMonthLineChart";
import withRestitutionNavBar from "../composition/wrapper/withRestitutionNavBar";
import {PathnameEnum} from "../../../../corelogic/models/types/routing/PathnameEnum";
import {newSimulationActions} from "../../../../corelogic/usecases/new-simulation/newSimulationActions";
import SelectorMenu from "../components/assets/FormAssets/selector/SelectorMenu";
import TitleText from "../components/assets/text/TitleText";
import {WrapperCard} from "../composition/wrapper/WrapperCard";
import DistributionPieChart from "../composition/chart/DistributionPieChart";
import {
    selectSelectedSimulationId,
} from "../../../../corelogic/usecases/new-simulation/new-simulation-selector/form/newSimulationSelector";
import {selectSelectedType} from "../../../../corelogic/usecases/physicalRestitution/physicalRestitutionSelector";
import {physicalRestitutionActions} from "../../../../corelogic/usecases/physicalRestitution/physicalRestitutionActions";
import {selectPathName} from "../../../../corelogic/usecases/routing/routingSelector";
import {
    selectTypes
} from "../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectInjectionPointForm";

export type Type = { id: string, name: string, scope?: string, aci: boolean, loading?: boolean }


const PhysicalResult = () => {
    const types = useSelector(selectTypes)
    const selectedType = useSelector(selectSelectedType)
    const simulationId = useSelector(selectSelectedSimulationId)
    const [disableAci, setDisableAci] = useState(false)
    const locationPathName = useSelector(selectPathName) // on pathName change, reload form
    const CDCGroupedByMonthRef = useRef(null)

    useEffect(() => {
        dispatch(newSimulationActions.getSimulationForm())

        return () => {
            dispatch(physicalRestitutionActions.initData())
            dispatch(physicalRestitutionActions.initSelectedPoint())
        }
    }, [locationPathName])

    useEffect(() => {
        setDisableAci(!types.find(({id}: any) => id === selectedType.id)?.aci)
    }, [types, selectedType])

    const dispatch = useDispatch();
    const handleACISwitch = (selected: string) => {
        handleSelectedAci(selected === 'ACC + ACI')
    }

    const handleSelectedAci = (isAci: boolean) => {
        dispatch(physicalRestitutionActions.setIsAci(isAci))

    }
    const handleSelectedType = (type: Type) => {
        const hasACIButACCShowed = type.aci && !selectedType.aci
        if(hasACIButACCShowed){
            handleSelectedAci(true);
        }
        const hasNotACIButACIShowed = !type.aci && selectedType.aci
        if (hasNotACIButACIShowed) {
            handleSelectedAci(false);
        }
        dispatch(physicalRestitutionActions.setSelectedType({id: type.id, scope: type.scope}))
    }


    useEffect(() => {
        if (simulationId) {
            handleSelectedType(types[0])
            handleSelectedAci(types[0].aci);
        }
    }, [types])



    return <div
        className="min-w-[640px] w-full flex flex-col items-end bg-gray-100 font-sans overflow-hidden dark:bg-zinc-800 ">
        <div className=" bg-white shadow-sm flex justify-between align-center w-full dark:bg-zinc-800">
            <span className={"scale-95 2xl:scale-100 px-4"}>
                <TitleText title={"Bilan énergétique"}/>
            </span>
            <div
                className="scale-95 2xl:scale-100 relative z-[40] px-6 grid mt-1 gap-3 min-w-min  max-w-[800px]  h-min grid-cols-2  py-2 justify-between">
                <SelectorMenu selected={selectedType} types={types} action={handleSelectedType}/>
                <ToggleBtn action={handleACISwitch} options={['ACC', 'ACC + ACI']}
                           selected={selectedType.aci && !disableAci ? 'ACC + ACI' : 'ACC'} disabled={disableAci}/>
            </div>
        </div>
        <hr className="border-1 w-full"/>
        <div className={"overflow-y-auto w-full relative z-[1] p-4"}>
            <DistributionPieChart/>


            <div className={"pb-4"}>
                {!selectedType.scope &&
                <WrapperCard
                    exportAsPNGRef={CDCGroupedByMonthRef}
                    title={"Vue d'ensemble de l'étude de foisonnement sur l'année"}>
                    <div
                        ref={CDCGroupedByMonthRef}
                        className="flex flex-col items-center bg-white font-bold text-normal text-gray-700 h-[500px] shadow-sm rounded-md overflow-hidden p-4  dark:bg-zinc-700 dark:text-gray-300 ">
                        <CDCGroupedByMonthLineChart/>
                    </div>
                </WrapperCard>
                }
            </div>

            <CDCLineChart/>


        </div>
    </div>

}

export default withRestitutionNavBar(PhysicalResult, PathnameEnum.PHYSICAL_RESTITUTION);
