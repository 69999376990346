import React, {useEffect, useRef} from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(clickOutsideAction:any, ref:any) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event:any) {
            if (ref.current && !ref.current.contains(event.target)) {
                clickOutsideAction()
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

/**
 * Component that alerts if you click outside of it
 */
function OutsideAlerter({clickOutsideAction , children, className}:{clickOutsideAction():void,children: JSX.Element, className ?: string}) {
    const wrapperRef = useRef(null);
    useOutsideAlerter(clickOutsideAction, wrapperRef);

    return <div className={className || ''} ref={wrapperRef}>{children}</div>;
}


export default OutsideAlerter;
