import {useSelector} from "react-redux";
import {
    selectACIAndGiftedElectricityEconomiesData,
    selectEconomiesLoading
} from "../../../../../corelogic/usecases/financialRestitution/financialRestitutionSelector";
import {formatNumber} from "../../../../../corelogic/usecases/config";

import {Saving} from "../../../../../corelogic/models/types/financialRestitution/Saving";
import {
    selectConsumptionPoints,
    selectFinancialDuration
} from "../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectFinancialParameter";


const BillsImpactGiftedElectricityTable = ({withACI, displayTVA}:{withACI:boolean, displayTVA:any}) => {
    const savings = useSelector(selectACIAndGiftedElectricityEconomiesData);
    const duration = useSelector(selectFinancialDuration)
    const loading = useSelector(selectEconomiesLoading);
    const consumptionPoints = useSelector(selectConsumptionPoints)

    return <table id={'bills-gifted-table'} className="bg-white w-full  relative overflow-x-auto px-2 py-4 table-auto shadow-md rounded-lg dark:bg-zinc-700">
        <thead>
        <tr className="text-gray-500 text-xs leading-normal dark:text-zinc-400 bg-white dark:bg-zinc-700">
            <th align={"left"} className="py-3 px-4 ">Site</th>
            <th align={"center"} className="py-3 px-4 ">Economie partage gratuit d’électricité par site 1 an </th>
            <th align={"center"} className="py-3 px-4 ">Nombre</th>
            {withACI && <th align={"center"} className="py-3 px-4 ">Economie ACI 1 an </th>}
            <th align={"center"} className="py-3 px-4 ">Economie partage gratuit d’électricité 1 an </th>
            {withACI && <th align={"center"} className="py-3 px-4 ">Economie ACI {duration} ans </th>}
            <th align={"center"} className="py-3 px-4 ">Economie partage gratuit d’électricité {duration} ans </th>
        </tr>
        </thead>
        <tbody className="text-gray-500 text-xs font-light dark:text-zinc-300 bg-white dark:bg-zinc-700">
        {!loading ?
            savings.map((saving: Saving, index: number) => {
                return <tr key={saving.name}
                           className={`border-b border-gray-200 hover:bg-gray-100 dark:hover:bg-zinc-600 ${index === savings.length - 1 ? 'bg-green-300 hover:bg-green-400 dark:bg-green-900 dark:hover:bg-green-800' : ''}`}>
                    <td align={"left"} className="py-2 px-4  whitespace-nowrap">
                        <span className="font-semibold">{saving.name + displayTVA(saving.name, consumptionPoints) || ''}</span>
                    </td>
                    <td align={"center"} className="py-2 px-4 ">
                        <span className={`font-semibold text-sm ${index === savings.length - 1 ? 'text-white text-slate-600 dark:text-zinc-200' : 'text-slate-400 dark:text-zinc-400'}`}>{saving.giftedEnergyFirstYearSavingByUnit != null ? formatNumber(saving?.giftedEnergyFirstYearSavingByUnit)+' €' : '-'}</span>
                    </td>
                    <td align={"center"} className="py-2 px-4 ">
                        <span className={`font-semibold text-sm ${index === savings.length - 1 ? 'text-white text-slate-600 dark:text-zinc-200' : 'text-slate-400 dark:text-zinc-400'}`}>{saving?.number || '-'}</span>
                    </td>
                    {withACI && <td align={"center"} className="py-2 px-4 ">
                        <span
                            className={`font-semibold text-sm ${index === savings.length - 1 ? 'text-white text-slate-600 dark:text-zinc-200' : 'text-slate-400 dark:text-zinc-400'}`}>{saving.individualAcFirstYearSaving != null ? formatNumber(saving.individualAcFirstYearSaving)+' €': '-'}</span>
                    </td>}
                    <td align={"center"} className="py-2 px-4 ">
                        <span className={`font-semibold text-sm ${index === savings.length - 1 ? 'text-white text-slate-600 dark:text-zinc-200' : 'text-slate-400 dark:text-zinc-400'}`}>{saving.giftedEnergyFirstYearSaving != null ? formatNumber(saving?.giftedEnergyFirstYearSaving)+' €' : '-'}</span>
                    </td>
                    {withACI && <td align={"center"} className="py-2 px-4 ">
                        <span
                            className={`font-semibold text-sm ${index === savings.length - 1 ? 'text-white text-slate-600 dark:text-zinc-200' : 'text-slate-400 dark:text-zinc-400'}`}>{saving.individualAcLastYearSaving != null ? formatNumber(saving.individualAcLastYearSaving)+' €': '-'}</span>
                    </td>}
                    <td align={"center"} className="py-2 px-4 ">
                        <span className={`font-semibold text-sm ${index === savings.length - 1 ? 'text-white text-slate-600 dark:text-zinc-200' : 'text-slate-400 dark:text-zinc-400'}`}>{saving.giftedEnergySaving != null ? formatNumber(saving?.giftedEnergySaving)+' €' : '-'}</span>
                    </td>
                </tr>
            })
            : [...Array(4)].map(()=> {
                return <tr className="border-b border-gray-200 animate-pulse">
                    {[...Array(7)].map((_, idx) => {
                        return <td key={idx} className="py-2 px-4 text-left whitespace-nowrap">
                            <div className="flex items-left">
                                <span className="font-bold bg-gray-100 h-4 w-full dark:bg-zinc-600"/>
                            </div>
                        </td>
                    })}
                </tr>
            })
        }
        </tbody>
    </table>
}

export default BillsImpactGiftedElectricityTable
