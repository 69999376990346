import TextInputForTable from "../../../../../components/assets/FormAssets/TextInputForTable";
import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectFormCapexes} from "../../../../../../../../corelogic/usecases/form/formSelector";
import {formActions} from "../../../../../../../../corelogic/usecases/form/formActions";
import {makeDurationNumberIsValid} from "../../../../../../../../corelogic/usecases/new-simulation/ValidatorsSelector";
import {loanInputType} from "./LoanPriceInput";
import {FormCapex} from "../FormCapex";
import {Loan} from "../../../../../../../../corelogic/models/types/new-simulation/form/Loan";

const LoanDurationInput = ({loan, index, loading, disabled}: loanInputType) => {
    const selectLoanDurationIsValid = useCallback(makeDurationNumberIsValid(loan.duration), [loan.duration]);
    const durationLoanIsValid = useSelector(selectLoanDurationIsValid)

    const capexes = useSelector(selectFormCapexes)
    const dispatch = useDispatch()

    function durationLoanOnChange(e: string, index: number) {
        let capexChanged:FormCapex[] = [...capexes]

        const newDuration = e.replace(',','').replace('.','')
        capexChanged[index].loan = new Loan(capexChanged[index].loan?.price || '',  newDuration, capexChanged[index].loan?.rate || '')

        dispatch(formActions.setCapexes(capexChanged));
    }

    return <td className={""}>
        {loan && <TextInputForTable
            isAFloat disabled={disabled}
            id={"capex-loan-duration" + index}
            index={index}
            onChange={durationLoanOnChange}
            value={loading ? 'chargement...':loan.duration}
            isValid={durationLoanIsValid}/>}
    </td>
}

export default LoanDurationInput
