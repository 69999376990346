import {InformationCircleIcon} from "../../../../components/assets/icon/InformationCircleIcon";
import React from "react";
import {useSelector} from "react-redux";
import {
    selectAllCapexPricesAreValid,
    selectAllLoanPricesAreValid,
    selectFormCapexesAreValid,
    selectFormCapexesHaveAtLeastOneLoan
} from "../../../../../../../corelogic/usecases/form/formSelector";


const CapexTableHead = () => {
    const withLoan = useSelector(selectFormCapexesHaveAtLeastOneLoan)
    return withLoan ? <CapexWithLoanTableHead/> : <CapexTableWithoutLoanHead/>
}
const CapexTableWithoutLoanHead = () => {
    const isValid = useSelector(selectFormCapexesAreValid)
    return <thead>
    <tr className="bg-slate-100   text-gray-500 text-sm  dark:text-zinc-300 dark:hover:bg-zinc-800  dark:bg-zinc-800 ">
        <th align={"left"} className=" py-4 px-4 bg-slate-100 dark:bg-zinc-800   ">
            Affectation
        </th>
        <th title={"Saisir un nombre positif"} align={"right"}
            className=" py-4 px-4 bg-slate-100 dark:bg-zinc-800 ">
            <div
                className={!isValid ? "gap-1 text-red-400 dark:text-red-500 flex items-center justify-end" : "gap-1flex items-center justify-end"}>
                Montant du capex (€ HT)
                {!isValid && <InformationCircleIcon width={"w-4 h-4"}
                                                    textcolor={"text-red-400 dark:text-red-500"}/>}
            </div>
        </th>
        <th className={"w-0"}/>
        <th className={"w-0"}/>
        <th className={"w-0"}/>
        <th align={"left"}  className="w-[60px] py-4 px-2  bg-slate-100 dark:bg-zinc-800 ">

        </th>
    </tr>
    </thead>
}
const CapexWithLoanTableHead = () => {

    const allCapexPricesValid = useSelector(selectAllCapexPricesAreValid)
    const allLoansValid = useSelector(selectAllLoanPricesAreValid)

    return <thead>
    <tr className="bg-slate-100   text-gray-500 text-sm  dark:text-zinc-300 dark:hover:bg-zinc-800  dark:bg-zinc-800 ">
        <th align={"left"} rowSpan={2} className=" py-2 px-4 bg-slate-100 dark:bg-zinc-800   ">
            Affectation
        </th>
        <th rowSpan={2} title={"Saisir un nombre positif"} align={"right"}
            className=" py-2 px-4 bg-slate-100 dark:bg-zinc-800 ">
            <div
                className={!allCapexPricesValid ? "text-red-400 gap-1 dark:text-red-500 flex items-center justify-end whitespace-nowrap" : "gap-1 whitespace-nowrap flex items-center justify-end"}>
                Montant du capex (€ HT)
                {!allCapexPricesValid && <InformationCircleIcon width={"w-4 h-4"}
                                                    textcolor={"text-red-400 dark:text-red-500"}/>}
            </div>
        </th>

        <th colSpan={3} className=" py-2 px-2 w-min  text-slate-500   dark:text-slate-300  uppercase text-xs tracking-wider ">Emprunt</th>
        <th align={"left"} rowSpan={2} className="w-[60px] py-2 px-2  bg-slate-100 dark:bg-zinc-800 ">
        </th>
    </tr>
    <tr className="bg-zinc-100    text-sm  text-slate-500   dark:text-slate-300 dark:hover:bg-zinc-800  dark:bg-zinc-800 ">
        <th title={"Saisir un nombre positif inférieur au capex"} align={"right"}
            className=" py-2 px-4 bg-slate-100 dark:bg-zinc-800 ">
            <div
                className={allLoansValid ?  "gap-1 flex items-center justify-end" : "gap-1 text-red-400 dark:text-red-500 flex items-center justify-end"}>
                Montant (€)
                {!allLoansValid && <InformationCircleIcon width={"w-4 h-4"}
                                                    textcolor={"text-red-400 dark:text-red-500"}/>}
            </div>
        </th>
        <th title={"Saisir un durée positive"} align={"right"}
            className=" py-2 px-4 bg-slate-100 dark:bg-zinc-800 ">
            <div
                className={"flex items-center justify-end"}>
                Durée (années)

            </div>
        </th>
        <th title={"Saisir un taux entre 0 et 100%"} align={"right"}
            className=" py-2 px-4 bg-slate-100 dark:bg-zinc-800 ">
            <div
                className={"flex items-center justify-end"}>
                Taux (%)
            </div>
        </th>
    </tr>
    </thead>
}
export default CapexTableHead