import {createSelector} from "reselect";
import {
    selectAddresses,
    selectFormOrganisationId,
} from "./new-simulation-selector/form/newSimulationSelector";
import {AddressGouv} from "../../models/types/new-simulation/external-api/AddressGouv";
import {OperationType} from "../../models/types/new-simulation/form/OperationType";
import {InjectionPoint} from "../../models/types/new-simulation/form/InjectionPoint";
import {selectGeneralInfo, selectPostcode} from "./new-simulation-selector/form-data/selectGeneralInfoForm";
import {selectInjectionPoints} from "./new-simulation-selector/form-data/selectInjectionPointForm";

export type OperationTypeKeys = keyof typeof OperationType
const makeIsNameValid = (name: string) => () => name.trim().length > 2
const makeIsPostcodeValid = (postcode: string) => createSelector(
    selectAddresses,
    selectPostcode,
    (addresses, generalInfosPostCode) => {
        const isAlreadyValidAndSaved = generalInfosPostCode === postcode
        if (isAlreadyValidAndSaved) return true;
        return addresses.some((address: AddressGouv) => address.label + ', ' + address.context === postcode || address.postcode === postcode)
    }
)

const isSelectedTypeValid = (selectedType: OperationTypeKeys | null) => selectedType !== null

const makeAreGeneralInfoFormValid = (name: string, postcode: string, selectedType: OperationTypeKeys | null) => createSelector(
    makeIsPostcodeValid(postcode),
    makeIsNameValid(name),
    (isPostcodeValid, isNameValid) => isNameValid && isPostcodeValid && isSelectedTypeValid(selectedType)
)
const makeHaveGeneralInfoChanged = (name: string, postcode: string, selectedType: OperationTypeKeys | null, organisationId: string) => createSelector(
    selectGeneralInfo,
    selectFormOrganisationId,
    (generalInfo, oldOrganisationId) => {
        return (generalInfo?.name !== name
            || generalInfo?.postcode !== postcode
            || generalInfo?.typeOfOperation !== selectedType
            || organisationId !== oldOrganisationId)
    }
)


const makeIsInjectionPointNameValid = (name: string, rowToEdit: any) => createSelector(
    selectInjectionPoints,
    (injectionPoints) => {
        const nameDoenstAlreadyExist = !injectionPoints.some((existingPoint: InjectionPoint) => existingPoint.name === name)
        const ownUniqueNameBeingEdited = rowToEdit?._name === name && !(injectionPoints.filter((existingPoint: InjectionPoint) => existingPoint.name === name).length === 2)
        return ownUniqueNameBeingEdited || (name.trim().length > 1 && nameDoenstAlreadyExist)
    }
)
const makeIsMaximumProductionPowerValid = (maxProductionPower: string) => () => maxProductionPower.length > 0 && !isNaN(Number(maxProductionPower))

export const isPriceValid = (price : string) => price !== '' && !isNaN(Number(price))
const makeIsPriceValid = (price: string) => () =>isPriceValid(price)

export const isPercentageValid = (value : string) => value !== '' && !isNaN(Number(value)) && Number(value) >= 0 && Number(value) <= 100
const makePercentNumberIsValid = (value: string) => () => isPercentageValid(value)

export const isRateValid = (value : string) => value !== '' && !isNaN(Number(value)) && Number(value) >= 0 && Number(value) <= 1
const makeRateNumberIsValid = (value: string) => () => isRateValid(value)

export const isDurationValid = (value : string) => !isNaN(Number(value)) && Number(value) > 0
const makeDurationNumberIsValid = (value: string) => () => isDurationValid(value)

export {
    makeRateNumberIsValid,
    makePercentNumberIsValid,
    makeDurationNumberIsValid,
    makeAreGeneralInfoFormValid,
    makeHaveGeneralInfoChanged,
    makeIsNameValid,
    makeIsPostcodeValid,
    makeIsInjectionPointNameValid,
    makeIsMaximumProductionPowerValid,
    makeIsPriceValid,
}
