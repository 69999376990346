import {Actions, SET_RESTITUTION_DATA, SET_RESTITUTION_LOADING} from "./restitutionActions";
import {combineReducers} from "redux";

const ResitutionReducer = (state: any = {}, action: Actions) => {
    switch (action.type) {
        case SET_RESTITUTION_DATA:
            return {...action.payload};
        default:
            return state;
    }
}

const ResitutionLoadingReducer = (state: boolean = false, action: Actions) => {
    switch (action.type) {
        case SET_RESTITUTION_LOADING:
            return action.payload;
        default:
            return state;
    }
}

export default combineReducers({
    data: ResitutionReducer,
    loading: ResitutionLoadingReducer,
});
