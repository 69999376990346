import RegularBtn from "../../../../components/assets/button/RegularBtn";
import React, {useEffect, useState} from "react";
import {ButtonState} from "../../../../../../../config/app-config";
import {useDispatch, useSelector} from "react-redux";
import {
    selectEnableNewSoutiragePointButton,
    selectPointBeingEdited
} from "../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import {newSimulationActions} from "../../../../../../../corelogic/usecases/new-simulation/newSimulationActions";
import {push} from "connected-react-router";
import {useSnackbar} from "notistack";
import {
    selectNewCurve
} from "../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-curve/selectFormCurve";

export default function AddConsumptionPointButton(){

    const enableNewSoutiragePointBtn = useSelector(selectEnableNewSoutiragePointButton)
    const [addInjectionBtn, setAddInjectionBtn] = useState(ButtonState.DISABLED)
    const rowToEdit = useSelector(selectPointBeingEdited)
    const dispatch = useDispatch()
    const newCurve = useSelector(selectNewCurve)
    const {enqueueSnackbar} = useSnackbar()


    useEffect(() => {
        // return valid or not state of the Step3Form
        if (enableNewSoutiragePointBtn) {
            setAddInjectionBtn(ButtonState.REGULAR)
        } else {
            setAddInjectionBtn(ButtonState.DISABLED)
        }
    }, [enableNewSoutiragePointBtn])

    function impossibleToAddPoint() {
        enqueueSnackbar("Le formulaire doit être valide pour ajouter un nouveau point de soutirage", {variant: "warning"})
    }

    function createOrUpdatePoint() {
         if(newCurve) {
            if(newCurve.id){
                if(rowToEdit){
                    dispatch(newSimulationActions.updateConsumptionPoint2())
                }
                else {
                    dispatch(newSimulationActions.addConsumptionPoint2())
                }
            } else  {
                if(rowToEdit){
                    dispatch(newSimulationActions.saveCurveAndUpdatePoint())
                }else {
                    dispatch(newSimulationActions.saveCurveAndAddPoint())
                }
            }
        } else {
            enqueueSnackbar("problème de récupération de l'id de la courbe de charge",
                {
                    variant: "error",
                })
        }
        dispatch(push("#top"))
    }

    return <RegularBtn dataCy={"typology-consumption-add-point"}
                       action={enableNewSoutiragePointBtn ? createOrUpdatePoint : impossibleToAddPoint}
                       state={addInjectionBtn}
                       title={rowToEdit ? "Mettre à jour le point de soutirage" : "Ajouter le point de soutirage"}/>
}
