import React, {useEffect, useState} from "react";

type TitleTextType = {
    title: string,
    textSize?: string,
    typeOfMargin?: string,
}
export default function ({title, textSize, typeOfMargin}:TitleTextType) {
    const [margin, setMargin] = useState(" mt-6 mb-2 mx-4 ")
    useEffect(() => {
        if (typeOfMargin === "small") {
            setMargin(' py-3 px-4 ')
        }
    }, [typeOfMargin])
    return <div
        className={textSize ? textSize + margin + " font-semibold whitespace-nowrap text-lg text-gray-700 dark:text-gray-300 " : margin + " text-2xl whitespace-nowrap font-bold text-gray-700 dark:text-gray-300 "}>{title}</div>

}
