import React, {useEffect, useRef} from "react";
import {PathnameEnum} from "../../../../corelogic/models/types/routing/PathnameEnum";
import TitleText from "../components/assets/text/TitleText";
import withRestitutionNavBar from "../composition/wrapper/withRestitutionNavBar";
import {useDispatch, useSelector} from "react-redux";

import openOperationModel from '../utils/modeles_enolab_ouverte.svg';
import socialOperationModel from '../utils/modeles_enolab_sociale.svg';
import estateOperationModel from '../utils/modeles_enolab_patrimoniale.svg';
import {newSimulationActions} from "../../../../corelogic/usecases/new-simulation/newSimulationActions";
import {WrapperCard} from "../composition/wrapper/WrapperCard";
import {
    selectIsEstateOperation, selectIsOpenOperation,
    selectIsSocialOperation
} from "../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectGeneralInfoForm";


const OrganisationalView = () => {
    const divRef = useRef(null);
    const dispatch = useDispatch();
    const isOpenOperation = useSelector(selectIsOpenOperation)
    const isSocialOperation = useSelector(selectIsSocialOperation)
    const isEstateOperation = useSelector(selectIsEstateOperation)

    useEffect(() => {
       dispatch(newSimulationActions.getSimulationForm())
    }, [dispatch])

    return <div
        className="min-w-[640px]  w-full  flex flex-col bg-gray-100 font-sans overflow-y-scroll dark:bg-zinc-800">
        <div className=" bg-white shadow-sm  flex items-center w-full border-box dark:bg-zinc-800">
            <div className="flex w-full z-[50] justify-between items-center scale-95 2xl:scale-100 px-6 ">
                <TitleText title={"Type d'organisation"}/>
            </div>
        </div>
        <div  className="px-4 pt-8 scale-95 2xl:scale-100 gap-6 w-[1000px] ">
        <WrapperCard title={'Vue organisationnelle'} exportAsPNGRef={divRef}>
            <div ref={divRef}>
                {isOpenOperation && <img className={"bg-gray-100 dark:bg-zinc-700 rounded-lg"} src={openOperationModel}
                                         alt="Operation ouverte"/>}
                {isSocialOperation && <img src={socialOperationModel} alt="Operation patrimoniale"/>}
                {isEstateOperation && <img src={estateOperationModel} alt="Operation sociale"/>}
            </div>
        </WrapperCard>
        </div>
    </div>
}

export default withRestitutionNavBar(OrganisationalView, PathnameEnum.ORGANISATIONAL_RESTITUTION)
