import {createSelector} from "reselect";
import {selectTypologyInfo, sortData} from "../form/newSimulationSelector";
import {InjectionPoint} from "../../../../models/types/new-simulation/form/InjectionPoint";
import {FormCapex} from "../../../../../adapters/primary/ui/composition/form/financial-parameters/capex/FormCapex";
import {
    selectAciProducers,
    selectActiveConsumptionPointsWithAddressFilled,
    selectActiveSoutiragePoints,
    selectConsumptionPoints
} from "./selectConsumptionPointForm";
import {selectTypeOfOperation} from "./selectGeneralInfoForm";
import {selectStoredExternalInvestment} from "./selectExternalInvestment";
import {
    globalExternalInvestment
} from "../../../../../adapters/primary/ui/composition/balance-sheet/external-investment-form/ExternalInvestment";

const selectInjectionPoints = createSelector(
    selectTypologyInfo,
    typology => {
        return typology && typology.injectionPoints ? typology.injectionPoints : []
    }
);

const selectInjectionPointsNames = createSelector(
    selectInjectionPoints,
    injectionPoints => injectionPoints.map((point) => point.name)
);
const selectInjectionPointsHaveDuplicatedNames = createSelector(
    selectInjectionPointsNames,
    injectionPointsNames => (new Set(injectionPointsNames)).size !== injectionPointsNames.length
);


const selectIsProductorsPowerGreaterThan1MWc = createSelector(
    selectInjectionPoints,
    injectionPoints => injectionPoints.some((point) => point.maximumProductionPower >= 1000)
);


const makeSelectInjectionPointsSorted = (sorting: { column: string, asc: boolean }) => createSelector(
    selectInjectionPoints,
    (points) => sortData(points, sorting)
)

const selectEnabledInjectionPoints = createSelector(
    selectInjectionPoints,
    injectionPoints => injectionPoints.filter((injectionPoint: InjectionPoint) => injectionPoint.state)
);

const makeSelectNonLinkedInjectionPoints = (linkedToEditedPointId: string | null) => createSelector(
    selectEnabledInjectionPoints,
    selectAciProducers,
    (injectionPointsEnabled, linkedProducersIds) => injectionPointsEnabled.filter(point => !linkedProducersIds.has(point.id) || point.id === linkedToEditedPointId)
)

const selectInjectionPointCapexes = createSelector(
    selectInjectionPoints,
    (injectionPoints) => {
        const withCapexes:InjectionPoint[] = injectionPoints.filter((point) => point.capexEntries)
        return withCapexes.map((point) => new FormCapex(point.id, point.capexEntries?.capex || '', point.capexEntries?.loan || null) )
    }
)
const selectActivesInjectionPointCapexes = createSelector(
    selectInjectionPoints,
    (injectionPoints) => injectionPoints.filter((point) => point.capexEntries&& point.state).map((point) => point.capexEntries )
)

const selectIsEstateWithGreaterThan1MWcProduction = createSelector(
    selectIsProductorsPowerGreaterThan1MWc,
    selectTypeOfOperation,
    (isProducerPowerGreaterThan1MWc, typeOfOperation) => typeOfOperation === 'estate' && isProducerPowerGreaterThan1MWc
);

const selectDisplayAcciseInGainsTable = createSelector(
    selectIsEstateWithGreaterThan1MWcProduction,
    selectTypeOfOperation,
    (isEstateWithGreaterThan1MWcProduction, typeOfOperation) => typeOfOperation === 'social' || isEstateWithGreaterThan1MWcProduction
)

 const selectActiveInjectionPoints = createSelector(
    selectInjectionPoints,
    (points) => points.filter((point) => point.state))

 const selectInactiveInjectionPoints = createSelector(
    selectInjectionPoints,
    (points) => points.filter((point) => !point.state))

const selectActiveInjectionPointsWithAddressFilled = createSelector(
    selectActiveInjectionPoints,
    (activeInjectionPoints) => activeInjectionPoints.filter((activePoint) => activePoint.address !== null)
);
const selectInjectionPointsWithAddressFilled = createSelector(
    selectInjectionPoints,
    (injectionPoints) => injectionPoints.filter((point) => point.address !== null))
const selectConsumptionPointsWithAddressFilled = createSelector(
    selectConsumptionPoints,
    (consumptionPoints) => consumptionPoints.filter((point) => point.address !== null))
const atLeast1ActiveInjectionPointsWithAddress = createSelector(
    selectActiveInjectionPointsWithAddressFilled,
    (activeInjectionPointsWithAddressFilled) => activeInjectionPointsWithAddressFilled.length > 0
);

const selectInactiveInjectionPointsWithAddressFilled = createSelector(
    selectInactiveInjectionPoints,
    (activeInjectionPoints) => activeInjectionPoints.filter((activePoint) => activePoint.address !== null)
);

const selectProductors = createSelector(
    selectActiveInjectionPoints,
    injectionPoints => {
        const injectionPointsTypes = injectionPoints.map(injectionPoint => ({
            id: injectionPoint.id,
            name: injectionPoint.name,
            scope: 'producer',
            productionType: injectionPoint.productionType
        }))
        return [{name: 'Etude globale', id: 'global', scope: 'global'}, ...injectionPointsTypes]
    }
)


export const selectTypes = createSelector(
    selectActiveInjectionPoints,
    selectActiveSoutiragePoints,
    selectAciProducers,
    (injectionPoints, consumptionPoints, aciProducers) => {
        const injectionPointsTypes = injectionPoints.map(injectionPoint => ({
            id: injectionPoint.id,
            name: injectionPoint.name,
            scope: 'producer',
            aci: aciProducers.has(injectionPoint.id),
            productionType: injectionPoint.productionType
        }))


        const consumptionPointsTypes = consumptionPoints.map(consumptionPoint => ({
            id: consumptionPoint.id,
            name: consumptionPoint.name,
            scope: 'consumer',
            aci: !!consumptionPoint.injectionPointId
        }))
        return [{
            name: 'Etude globale',
            id: 'global',
            aci: aciProducers.size > 0
        }, ...injectionPointsTypes, ...consumptionPointsTypes]
    }
)

const makeSelectIsExternalInvestHybrid = (producerId: string) => createSelector(
    selectStoredExternalInvestment,
    selectActiveInjectionPoints,
    (externalInvestments, activeInjectionPoints) => {
        if(!externalInvestments  || externalInvestments.length === 0) return false
        if(externalInvestments.length === 1 && externalInvestments[0].id === globalExternalInvestment.id) return false
        if(producerId !== 'global') return false
        if(producerId === 'global') return externalInvestments.length < activeInjectionPoints.length
    }
)
const selectStoredActiveExternalInvestment = createSelector(
    selectStoredExternalInvestment,
    selectActiveInjectionPoints,
    (externalInvestments, activeInjectionPoints) => {
        if(!externalInvestments) return undefined
        if(externalInvestments.length === 1 && externalInvestments[0].id === globalExternalInvestment.id) return externalInvestments
        return externalInvestments.filter((externalInvestment) => activeInjectionPoints.some((point) => point.id === externalInvestment.id))
    }
);

const makeSelectCanViewExternalInvestment = (producerId: string) => createSelector(
    selectStoredActiveExternalInvestment,
    (activeExternalInvestments) => {
        if(!activeExternalInvestments || activeExternalInvestments.length === 0) return false
        if(activeExternalInvestments.length === 1 && activeExternalInvestments[0].id === globalExternalInvestment.id) return true
        if(producerId === 'global')return true
        else return activeExternalInvestments.some((invest)=>invest.id === producerId)
    })

const selectOneInjAndProdPoints = createSelector(
    selectInjectionPoints,
    selectConsumptionPoints,
    (injectionPoints, consumptionPoints) => {
        return injectionPoints.length > 0 && consumptionPoints.length > 0
    })

const selectAllPointsHaveAddress =  createSelector(
    selectInjectionPointsWithAddressFilled,
    selectConsumptionPointsWithAddressFilled,
    selectInjectionPoints,
    selectConsumptionPoints,
    (injPointWithAddress, consPointWithAddress, injPoints, consPoints) => {
        if(injPointWithAddress.length === injPoints.length && consPointWithAddress.length === consPoints.length) return true
        return false
    }
)




export {
    selectOneInjAndProdPoints,
    selectAllPointsHaveAddress,
    makeSelectCanViewExternalInvestment,
    makeSelectIsExternalInvestHybrid,
    selectProductors,
    selectActiveInjectionPointsWithAddressFilled,
    atLeast1ActiveInjectionPointsWithAddress,
    selectInactiveInjectionPointsWithAddressFilled,
    selectActiveInjectionPoints,
    selectInactiveInjectionPoints,
    selectIsEstateWithGreaterThan1MWcProduction,
    selectDisplayAcciseInGainsTable,
    selectInjectionPoints,
    selectInjectionPointsNames,
    selectInjectionPointsHaveDuplicatedNames,
    selectTypologyInfo,
    selectIsProductorsPowerGreaterThan1MWc,
    selectActivesInjectionPointCapexes,
    selectInjectionPointCapexes,
    selectEnabledInjectionPoints,
    makeSelectNonLinkedInjectionPoints,
    makeSelectInjectionPointsSorted,
}
