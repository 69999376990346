import apiClient from "../Api/ApiClient";
import {FinancialRestitutionGatewayInterface} from "../../../../corelogic/usecases/financialRestitution/financialRestitutionGateway.interface";
import {Saving} from "../../../../corelogic/models/types/financialRestitution/Saving";
import {CostAndGain} from "../../../../corelogic/models/types/financialRestitution/CostAndGain";

export class FinancialRestitutionInApiGateway implements FinancialRestitutionGatewayInterface {

    getMetricValue = async (simulationId: string, nature: string, pointId ?: string, view?:string ): Promise<number> => {
        const {data: {value}} = await apiClient.get(`/simulations/${simulationId}/financial/metrics/${nature}`, {
            params: {
                point_id: pointId,
                nature : view
            }
        });
        return value;
    }

    async getCashFlowData(simulationId: string, nature:string, pointId?:string): Promise<{ data : any }> {

        const {data} = await apiClient.get(`/simulations/${simulationId}/financial/table/cash_flow`,{
            params: {
                nature : nature,
                point_id: pointId
            }
        });
        const cashFlow = data.table.map((elt: any) => ({
            investment: elt['Investissement (€)'],
            maintenance: elt['Maintenance (€)'],
            rent : !isNaN(elt['Loyer (€)']) ? elt['Loyer (€)'] : null,
            royalty : !isNaN(elt['Redevance (€)']) ? elt['Redevance (€)'] : null,
            loanRepayment : !isNaN(elt["Intérêt de l'emprunt (€)"]) ? elt["Intérêt de l'emprunt (€)"] : null,
            acSavings: !isNaN(elt['Economie ACI + ACC (€)']) ? elt['Economie ACI + ACC (€)'] : null,
            accSavings: !isNaN(elt['Economie ACC (€)']) ? elt['Economie ACC (€)'] : null,
            aciSavings: !isNaN(elt['Economie ACI (€)']) ? elt['Economie ACI (€)'] : null,
            excess: elt['Vente du surplus (€)'],
            producerGain :!isNaN(elt['Gain producteur (€)']) ? elt['Gain producteur (€)'] : null,
            netSavings: elt['Economie nette (€)'],
            cumulativeFlows: elt['Flux cumulés (€)'],
            gifted:!isNaN(elt["Don de l'electricité (€)"]) ? elt["Don de l'electricité (€)"] : null
        }));
        return {data : cashFlow};
    }

    async getGlobalEconomicalData(simulationId: string): Promise<any> {
        const {data} = await apiClient.get(`/simulations/${simulationId}/financial/table/global_economy`);
        let firstYearKey: string, lastYearKey: string;
        Object.keys(data.table[0]).forEach(elt => {
            if (elt.startsWith('ECO AN')) {
                lastYearKey = elt;
            } else if (elt !== 'index') {
                firstYearKey = elt;
            }
        })
        const globalEconomy = data.table.reduce((cum: any, curr: any) => {
            if (curr.index.startsWith('Economie bâtiments ACC')) {
                cum.acc = {
                    firstYear: curr[firstYearKey],
                    lastYear: curr[lastYearKey]
                }
            } else if (curr.index.startsWith('Economie bâtiments ACI')) {
                if (!cum.aci) {
                    cum.aci = [];
                }
                cum.aci.push({
                    firstYear: curr[firstYearKey],
                    lastYear: curr[lastYearKey],
                })
            } else if (curr.index.startsWith('Vente')) {
                cum.excess = {
                    firstYear: curr[firstYearKey],
                    lastYear: curr[lastYearKey]
                }
            } else if (curr.index.startsWith('Collecte')) {
                cum.tcfe = {
                    firstYear: curr[firstYearKey],
                    lastYear: curr[lastYearKey]
                }
            } else if (curr.index.startsWith('Economie totale (avec collecte de TCFE)')) {
                cum.total = {
                    firstYear: curr[firstYearKey],
                    lastYear: curr[lastYearKey]
                }
            }
            return cum
        }, {})
        return globalEconomy
    }

    async getByPointEconomicalData(simulationId: string, pointId?: string): Promise<any> {
        const {data} = await apiClient.get(`/simulations/${simulationId}/financial/saving`, {
            params: {
                point_id: pointId
            }
        });
        return new Saving(data.collective_ac_first_year_saving_by_unit, data.collective_ac_first_year_saving, data.collective_ac_saving, data.individual_ac_first_year_saving,
            data.ac_first_year_saving, data.ac_saving, data.name, data.number,  data.individual_ac_saving,  data.internal_bill_first_year,data.internal_bill, data.consumer_gain_first_year, data.consumer_gain, data.gifted_energy_first_year_saving, data.gifted_energy_first_year_saving_by_unit, data.gifted_energy_saving)
    }

    async getSurplusByPoint(simulationId: string, pointId?: string): Promise<any>{
    const {data} = await apiClient.get(`/simulations/${simulationId}/financial/cost-and-gain`, {
            params: {
                point_id: pointId
            }
        });
        return new CostAndGain(data.surplus_first_year_gain, data.accise_first_year, data.majo_turpe_first_year,
            data.ifer_first_year, data.costs_and_gains_first_year_saving, data.costs_and_gains_saving, data.name,
            data.accise ? data.accise : null, data.producer_gain_first_year ? data.producer_gain_first_year : null,
            data.collected_vat ? data.collected_vat : null);
    }


}
