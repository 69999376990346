import ReactEcharts from "echarts-for-react";
import React, {useCallback, useEffect, useState} from "react";
import { lightenDarkenColor, lineInitialOption, loadingOption} from "./config";
import {
    makeSelectTotalConsumptionGroupedByMonth,
    selectCDCGroupedByMonthLoading,
    selectCollectiveAutoconsumptionGroupedByMonth,
    selectIndividualAutoconsumptionGroupedByMonth,
    selectMonthTimestamps, selectSelectedType,
    selectSurplusGroupedByMonth
} from "../../../../../corelogic/usecases/physicalRestitution/physicalRestitutionSelector";
import {useDispatch, useSelector} from "react-redux";
import {physicalRestitutionActions} from "../../../../../corelogic/usecases/physicalRestitution/physicalRestitutionActions";
import {
    selectSelectedSimulationId
} from "../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form/newSimulationSelector";
import {selectIsDarkMode} from "../../../../../corelogic/usecases/config/configSelector";
import {
    selectIsFormUpdating
} from "../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-state/selectFormState";
import { useSimulationExecutionStatus } from "../../utils/useSimulationExecutionStatus";
import { ExecutionStatus } from "../../../../../corelogic/usecases/simulationExecutionStatus/simulationExecutionStatusActions";

const getColorFromName = (name: string) => {
    switch (name) {
        case 'Consommation totale':
        case 'Production totale':
            return '#2A5E8A';
        case 'Autoconsommation collective':
            return '#f3b617';
        case 'Autoconsommation individuelle':
            return lightenDarkenColor('#f3b617', 60);
        case 'Surplus':
            return '#c4c4c4';
    }
}

export default function () {
    const type = useSelector(selectSelectedType)
    const [option, setOption] = useState<any>(lineInitialOption(false, "kWh"))
    const selectTotalConsumptionGroupedByMonth = useCallback(makeSelectTotalConsumptionGroupedByMonth(type.aci), [type.aci]);
    const timestamps = useSelector(selectMonthTimestamps);
    const totalConsumptionGroupedByMonth = useSelector(selectTotalConsumptionGroupedByMonth)
    const autoconsumptionGroupedByMonth = useSelector(selectCollectiveAutoconsumptionGroupedByMonth)
    const isDarkMode = useSelector(selectIsDarkMode)
    const individualAutoconsumptionGroupedByMonth = useSelector(selectIndividualAutoconsumptionGroupedByMonth)
    const surplusGroupedByMonth = useSelector(selectSurplusGroupedByMonth)
    const loading = useSelector(selectCDCGroupedByMonthLoading)
    const simulationId = useSelector(selectSelectedSimulationId)
    const isFormUpdating = useSelector(selectIsFormUpdating)
    const executionStatus = useSimulationExecutionStatus();

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(physicalRestitutionActions.cancelGetCDCGroupedByMonthData())
        }
    }, [])

    useEffect(() => {
        if(simulationId && !isFormUpdating && type.id && executionStatus === ExecutionStatus.finished) {
            dispatch(physicalRestitutionActions.getCDCGroupedByMonthData())
        }
    }, [type.id, type.aci, simulationId, isFormUpdating, executionStatus])


    useEffect(() => {
        const series = [];
        const initialSerie = {
            type: 'bar',
            areaStyle: {},
        }
        if (totalConsumptionGroupedByMonth.length) {
            const name = 'Consommation totale'
            series.push({
                ...initialSerie,
                name,
                color: getColorFromName(name),
                stack: 'conso',
                data: totalConsumptionGroupedByMonth,
            })
        }
        if (individualAutoconsumptionGroupedByMonth.length && type.aci) {
            const name = 'Autoconsommation individuelle'
            series.push({
                ...initialSerie,
                name,
                color: getColorFromName(name),
                stack: 'prod',
                data: individualAutoconsumptionGroupedByMonth,
            })
        }
        if (autoconsumptionGroupedByMonth.length) {
            const name = 'Autoconsommation collective'
            series.push({
                ...initialSerie,
                name,
                color: getColorFromName(name),
                stack: 'prod',
                data: autoconsumptionGroupedByMonth,
            })
        }
        if (surplusGroupedByMonth.length) {
            const name = 'Surplus'
            series.push({
                ...initialSerie,
                name,
                color: getColorFromName(name),
                stack: 'prod',
                data: surplusGroupedByMonth,
            })
        }
        const newOption = {...option, series, xAxis: {...option.xAxis, data: timestamps}}
        setOption(newOption)
    }, [totalConsumptionGroupedByMonth, autoconsumptionGroupedByMonth, surplusGroupedByMonth, timestamps, type.aci])


    useEffect(() => {
        setOption((option: any) => ({...option, backgroundColor:isDarkMode ?"#3F3F46":'#fff'}) )
    },[isDarkMode])

    return <ReactEcharts theme={isDarkMode? 'dark':''} option={option} style={{height: '100%'}} className="w-full" notMerge showLoading={loading} loadingOption={loadingOption}/>
}
