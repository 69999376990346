import {
    DistributionUngroupedPriorities, ZERO_PRIORITY
} from "../../../../models/types/new-simulation/form/operation-parameters/distribution/DistributionUngroupedPriorities";

function isValid(distribution:DistributionUngroupedPriorities): boolean {
    if(distribution.rows.length === 0 || distribution.consumptionColumnTitles.length === 0 || !distribution) return false

    // Check if the number of priorities in each row matches the number of consumption points
    if (!distribution.rows.every(row => row.priorities.length === distribution.consumptionColumnTitles.length)) {
        return false;
    }

    // Iterate through each column
    for (let columnIndex = 0; columnIndex < distribution.consumptionColumnTitles.length; columnIndex++) {
        const seenPriorities = new Set<number>();

        // Check each row for the current column
        for (const row of distribution.rows) {
            const priority = row.priorities[columnIndex];
            // ZERO_PRIORITY may be multiple for on consumer
            if (priority === ZERO_PRIORITY) {
                continue;
            }

            // Check if the priority value has already been seen
            if (seenPriorities.has(priority)) {
                // Duplicate priority value found in the same column
                return false;
            }
            seenPriorities.add(priority);
        }
    }

    return true;
}
function isDifferentFrom(distribution:DistributionUngroupedPriorities, backendDistribution:DistributionUngroupedPriorities): boolean {
    return JSON.stringify(distribution) !== JSON.stringify(backendDistribution)
}


export const ungroupedPriorityUtils = {
    isValid,
    isDifferentFrom
}
