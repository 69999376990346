export type BadgeStyle = {
    color: BadgeStyleColor,
    backgroundColor: BadgeStyleBackground
}
export type IconType = {
    className?: string,
    disabled?: boolean
}

export enum ButtonState {FOCUS, REGULAR, DISABLED, OUTLINED, ERROR}

export enum Sort {DISABLED, ORDER, REVERSE}

export enum Theme {DEFAULT, SECONDARY}

export enum BadgeStyleColor {SUCCESS = ' text-green-600 dark:text-green-300 ', WARNING = ' text-yellow-600 dark:text-yellow-300 '}

export enum BadgeStyleBackground {SUCCESS = ' bg-green-200 dark:bg-green-900 ', WARNING = ' bg-yellow-200 dark:bg-yellow-700 '}

export enum State {SUCCESS, WARNING, INFO, ERROR}

export enum UnitType {KILO_WATT = "kW", EURO_HT_BY_KILO_WATT = "€ HT/kWh", YEAR = "années", PERCENT = "%", EURO = "€", EURO_HT = "€ HT", KILO_WATT_HOUR = "kWh"}

export type EnolabColors = {
    50: string,
    100: string,
    200: string,
    300: string,
    400: string,
    500: string,
    600: string,
    700: string,
    800: string,
    900: string,
}
export const gray: EnolabColors = {
    50: '#f9fafb',
    100: '#f3f4f6',
    200: '#e5e7eb',
    300: '#d1d5db',
    400: '#9ca3af',
    500: '#6b7280',
    600: '#4b5563',
    700: '#374151',
    800: '#1f2937',
    900: '#111827',
};
export const blue: EnolabColors = {
    50: '#eff6ff',
    100: '#dbeafe',
    200: '#bfdbfe',
    300: '#93c5fd',
    400: '#60a5fa',
    500: '#3b82f6',
    600: '#2563eb',
    700: '#1d4ed8',
    800: '#1e40af',
    900: '#1e3a8a',
};
export const red: EnolabColors = {
    50: '#fef2f2',
    100: '#fee2e2',
    200: '#fecaca',
    300: '#fca5a5',
    400: '#f87171',
    500: '#ef4444',
    600: '#dc2626',
    700: '#b91c1c',
    800: '#991b1b',
    900: '#7f1d1d',
};
export const purple: EnolabColors = {
    50: '#faf5ff',
    100: '#f3e8ff',
    200: '#e9d5ff',
    300: '#d8b4fe',
    400: '#c084fc',
    500: '#a855f7',
    600: '#9333ea',
    700: '#7e22ce',
    800: '#6b21a8',
    900: '#581c87',
};
export const yellow: EnolabColors = {
    50: '#fefce8',
    100: '#fef9c3',
    200: '#fef08a',
    300: '#fde047',
    400: '#facc15',
    500: '#eab308',
    600: '#ca8a04',
    700: '#a16207',
    800: '#854d0e',
    900: '#713f12',
};
export const amber: EnolabColors = {
    50: '#fffbeb',
    100: '#fef3c7',
    200: '#fde68a',
    300: '#fcd34d',
    400: '#fbbf24',
    500: '#f59e0b',
    600: '#d97706',
    700: '#b45309',
    800: '#92400e',
    900: '#78350f',
};
export const orange: EnolabColors = {
    50: '#fff7ed',
    100: '#ffedd5',
    200: '#fed7aa',
    300: '#fdba74',
    400: '#fb923c',
    500: '#f97316',
    600: '#ea580c',
    700: '#c2410c',
    800: '#9a3412',
    900: '#7c2d12',
};
export const zinc: EnolabColors = {
    50: '#fafafa',
    100: '#f4f4f5',
    200: '#e4e4e7',
    300: '#d4d4d8',
    400: '#a1a1aa',
    500: '#71717a',
    600: '#52525b',
    700: '#3f3f46',
    800: '#27272a',
    900: '#18181b',
};
export const green: EnolabColors = {
    50: '#f0fdf4',
    100: '#dcfce7',
    200: '#bbf7d0',
    300: '#86efac',
    400: '#4ade80',
    500: '#22c55e',
    600: '#16a34a',
    700: '#15803d',
    800: '#166534',
    900: '#14532d',
};

export const YEAR_N_MOINS_UN = ():string => {
    // Get the current date
    var currentDate = new Date();

    // Get the year of the current date
    var currentYear = currentDate.getFullYear();

    // Subtract one year
    var lastYear = currentYear - 1;

    // Get the last two digits of the year
    return (lastYear % 100).toString();
}