import {
    ZERO_PRIORITY, DistributionUngroupedPriorities, PriorityRow
} from "../../../../models/types/new-simulation/form/operation-parameters/distribution/DistributionUngroupedPriorities";
import {InjectionPoint} from "../../../../models/types/new-simulation/form/InjectionPoint";
import {ConsumptionPoint} from "../../../../models/types/new-simulation/form/ConsumptionPoint";




type BackendUngroupedDistribution = {
    [key: string]: { point_id: string; priority: number | null; }[];
}

function sanitize(ungroupedDistributionRows : PriorityRow[]) : PriorityRow[] {
    const allNumbersFromTable = new Set(ungroupedDistributionRows.flatMap(row => row.priorities))

    // mapping starts to 0 if there is deactivated priority (which is priority 0), else otherwise
    const hasDeactivatedPriority : boolean = allNumbersFromTable.has(ZERO_PRIORITY);
    let firstIndex: number = hasDeactivatedPriority ? 0 : 1;

    // Convert the Set to an Array and sort it
    const sortedArray: number[] = Array.from(allNumbersFromTable).sort((a, b) => a - b);

    // Create the mapping
    const mapping: Record<number, number> = {};
    sortedArray.forEach((value, index) => {
      mapping[value] = firstIndex + index;
    });

    // Apply the mapping to the table
    const sanitizedRows:PriorityRow[] = ungroupedDistributionRows.map(row => ({
        ...row,
        priorities: row.priorities.map(priority => mapping[priority])
    }));
    return sanitizedRows
}
function fromObject(backendDistribution  : BackendUngroupedDistribution, injectionPoints : InjectionPoint[], consumptionPoints : ConsumptionPoint[]): DistributionUngroupedPriorities {
    if(!injectionPoints || !consumptionPoints || !backendDistribution) return { consumptionColumnTitles : [], rows : [] }
    const consumptionColumnTitles = consumptionPoints.map(point => ({ id: point.id, name: point.name, state : point.state, segment : point.segment }));

    let rows: PriorityRow[] = []

    Object.keys(backendDistribution).forEach(producerPointId => {
        const injectionPoint = injectionPoints.find(point => point.id === producerPointId);
        // remove the injection point from the distribution
        if (!injectionPoint) return;

        const priorities = new Array(consumptionPoints.length).fill(9);
        backendDistribution[producerPointId].forEach(distribution => {
            const index = consumptionColumnTitles.findIndex(title => title.id === distribution.point_id);
            if (index !== -1) {
                if (distribution.priority == null || consumptionPoints[index].isInACIWith(producerPointId)) {
                    priorities[index] = ZERO_PRIORITY;
                }
                else {
                    priorities[index] = distribution.priority;
                }

            }
        });
        rows.push({
            injectionPoint: {
                id: injectionPoint.id,
                name: injectionPoint.name,
                state : injectionPoint.state,
                generatedKwhPrice : injectionPoint.generatedKwhPrice
            },
            priorities: priorities
        });
    });

    // if there are less rows than injection points, add the missing ones
    if(rows.length < injectionPoints.length){
        injectionPoints.forEach((injectionPoint) => {
            if(!rows.find((row) => row.injectionPoint.id === injectionPoint.id)){
                rows.push({
                    injectionPoint: {
                        id: injectionPoint.id,
                        name: injectionPoint.name,
                        state : injectionPoint.state,
                        generatedKwhPrice : injectionPoint.generatedKwhPrice
                    },
                    priorities: new Array(consumptionPoints.length).fill(9)
                })
            }
        })
    }

    const distribution: DistributionUngroupedPriorities = {
        consumptionColumnTitles: consumptionColumnTitles,
        rows: sanitize(rows)
    };
    console.log("fromObject  ", {distribution})

    return distribution;

}

function serialize(distribution : DistributionUngroupedPriorities ): BackendUngroupedDistribution{
    const sanitizedRows = sanitize(distribution.rows);
    const distributionSerialized: BackendUngroupedDistribution = {};

    sanitizedRows.forEach((row) => {
        if (!distributionSerialized[row.injectionPoint.id]) {
            distributionSerialized[row.injectionPoint.id] = [];
        }

        row.priorities.forEach((priority, index) => {
            const consumerPointId = distribution.consumptionColumnTitles[index]?.id;
            if (consumerPointId) {
                distributionSerialized[row.injectionPoint.id].push({
                    point_id: consumerPointId,
                    priority: (priority === ZERO_PRIORITY ? null : priority)
                });
            }
        });
    });

    return distributionSerialized;
}

export const ungroupedPriorityApi = {
    fromObject,
    serialize
}
