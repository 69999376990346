import {ConsumptionPoint} from "../../../models/types/new-simulation/form/ConsumptionPoint";
import {INITIAL_MONTHLY_SEASON} from "./useSeasonalityRetrieve";
import {Seasonality} from "../../../models/types/new-simulation/form/operation-parameters/seasonality/Seasonality";

function fromObject(object: { [key: string]: boolean[] }, consumptionPoints : ConsumptionPoint[]): Seasonality {
    if(!object ||  consumptionPoints.length === 0){
        return {
            consumptionPoints: [],
            seasonActivated: []
        }
    }
    const seasonActivated: boolean[][] = []
    consumptionPoints.forEach((consumptionPoint) => {
        const seasonActivatedForPoint = object[consumptionPoint.id] ? [...object[consumptionPoint.id]] : null
        if(seasonActivatedForPoint){
            seasonActivated.push([...seasonActivatedForPoint])
        }else {
            seasonActivated.push([...INITIAL_MONTHLY_SEASON])
        }
    })
    return {consumptionPoints, seasonActivated : [...[...seasonActivated]]}
}

function serialize(seasonality : Seasonality | null): { [key: string]: boolean[] } | null {
    if(seasonality?.seasonActivated.length === 0 || !seasonality){
        return null
    }
    const serialized: { [key: string]: boolean[] } = {}
    seasonality.consumptionPoints.forEach((consumptionPoint, index) => {
        serialized[consumptionPoint.id] = seasonality.seasonActivated[index]
    })
    return serialized
}

export const seasonalityApi = {
    fromObject,
    serialize
}
