import {useDispatch, useSelector} from "react-redux";
import {selectIsLoadingNewCurve} from "../../../../../../../../corelogic/usecases/loader/loaderSelector";
import {typologyFormActions} from "../../../../../../../../corelogic/usecases/typology-form/typologyFormActions";
import XMarkIcon from "../../../../../components/assets/icon/XMarkIcon";
import React from "react";

export default function XIcon() {

    const loading = useSelector(selectIsLoadingNewCurve)
    const dispatch = useDispatch()
    const styleLoading = loading ? " cursor-progress " : " hover:bg-blue-200 hover:cursor-pointer"

    function closeAndClearNewCurve() {
        if (!loading) {
            dispatch(typologyFormActions.resetCDCUpload())
        }
    }

    return <div
        onClick={closeAndClearNewCurve}>
        <XMarkIcon className={"h-5 w-5 text-blue-600 rounded " + styleLoading}/>
    </div>

}
