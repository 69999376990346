import {Badge} from "./Badge";
import {State} from "../../../../config/app-config";
import {OperationType} from "../new-simulation/form/OperationType";
import {Organisation} from "./Organisation";

export class Simulation {
    private readonly _id: string;
    private readonly _name: string;
    private readonly _created: string;
    private readonly _actor: string;
    private readonly _lastModified: string
    private readonly _status: Badge
    private readonly _typeOfOperation  : keyof typeof OperationType | null
    private readonly _organisation : Organisation

    constructor(id : string, name: string, created: string, actor: string, lastModified: string, status: State, typeOfOperation : keyof typeof OperationType | null, organisation : Organisation) {
        this._name = name;
        this._created = created;
        this._actor = actor;
        this._lastModified = lastModified;
        this._status = status === State.SUCCESS ? new Badge('Terminé', status) : new Badge('En cours de création', status) ;
        this._id = id;
        this._typeOfOperation = typeOfOperation
        this._organisation = organisation
    }

    static fromObject({id, name, created, actor, lastModified, status, typeOfOperation, organisation}:{id:string, name: string, created: string, actor: string, lastModified: string, status: State, typeOfOperation: keyof typeof OperationType | null, organisation:Organisation}) {
        return new this(id, name, created, actor, lastModified, status, typeOfOperation, organisation)
    }

    get organisation(): Organisation {
        return this._organisation;
    }

    get typeOfOperation(): keyof typeof OperationType | null {
        return this._typeOfOperation;
    }


    get id(): string {
        return this._id;
    }

    get name(): string {
        return this._name;
    }


    get created(): string {
        return this._created;
    }


    get actor(): string {
        return this._actor;
    }


    get lastModified(): string {
        return this._lastModified;
    }


    get status(): Badge {
        return this._status;
    }


}
