import React from "react";

export default function () {
    return <div
        className=" absolute z-50 right-0 bottom-[60px] max-w-full h-0 w-0  mt-2  shadow-lg  hover:cursor-default "
        role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex={-1}>
        <div className="py-1 z-[1] w-[650px] bg-white text-gray-700 dark:bg-zinc-800 dark:text-zinc-200 rounded-md"
             role="none">
            <span
                className={" block w-full text-left px-4 pt-2 text-md font-semibold"}>Récupération de la TVA</span>
            <hr className="my-3 w-full"/>
            <div className={"flex flex-row justify-center "}>
                <div className={"flex flex-col"}>
                <span className={"whitespace-pre-line  block w-full text-left px-4 pt-2 text-sm"}>
l'activation de la récupération de TVA permet d' exécuter les calculs financier en HT ce qui est souvent nécessaire pour les participants (producteur, consommateur de type professionnel).             </span>
                </div>
            </div>
        </div>
    </div>
}
