import {createSelector} from "reselect";
import {RootState} from "../../../store/rootReducer";

import {
    hasInflationChanged
} from "../../../adapters/primary/ui/composition/form/financial-parameters/inflationHasChanged";
import {SellingPriceFunction} from "../../models/types/new-simulation/form/SellingPriceFunction";
import {InjectionPoint} from "../../models/types/new-simulation/form/InjectionPoint";
import {
    country
} from "../../../adapters/primary/ui/composition/form/financial-parameters/vat-rate/variable-vat-rate/VariableVATRate";
import {
    atLeastOneLoanCapex,
    validateAllCapexes
} from "../../../adapters/primary/ui/composition/form/financial-parameters/capex/FormCapex.utils";
import {FormCapex} from "../../../adapters/primary/ui/composition/form/financial-parameters/capex/FormCapex";
import {
    selectActiveSoutiragePoints,
    selectComplementProviderInflationRate
} from "../new-simulation/new-simulation-selector/form-data/selectFinancialParameter";
import {
    selectActiveInjectionPoints
} from "../new-simulation/new-simulation-selector/form-data/selectInjectionPointForm";

export const selectForm = (state: RootState) => state.financialForm;
const selectFormSellingPriceTable = createSelector(
    selectForm,
    form => form.sellingPriceTable
)
const selectFormInflationInput = createSelector(
    selectForm,
    form => form.inflationInput
)
const selectFormSellingPrice = createSelector(
    selectFormSellingPriceTable,
    form => form.sellingPrice
);
const selectModalSellingPriceInflations =
    createSelector(
        selectFormSellingPriceTable,
        financialParam => financialParam.sellingPriceModalInflations
    );
const selectVariableInflationsToggled =createSelector(
    selectFormInflationInput,
    financialParam => financialParam.variableInflationsToggled
);
const selectInflation =createSelector(
    selectFormInflationInput,
    financialParam => financialParam.inflation
);
const selectSellingPriceInflationToggled =createSelector(
    selectFormSellingPriceTable,
    financialParam => financialParam.sellingPriceInflationToggled
);
const selectSellingPriceInflation =createSelector(
    selectFormSellingPriceTable,
    sellingPrice  => typeof sellingPrice.sellingPriceInflation === "string" ? sellingPrice.sellingPriceInflation : undefined
);
const selectSellingPriceVariableInflations =createSelector(
    selectFormSellingPriceTable,
    sellingPrice  => typeof sellingPrice.sellingPriceInflation !== "string"  ? sellingPrice.sellingPriceInflation : undefined
);
const selectSellingPriceInflationToApply = createSelector(
    selectSellingPriceInflationToggled,
    selectSellingPriceInflation,
    selectSellingPriceVariableInflations,
    (toggle, inflation, inflations) => toggle ? inflations : inflation
)
const selectSellingPriceTemporaryVariableInflations = createSelector(
    selectFormInflationInput,
    form => form.temporarySellingPriceInflations)
;
const selectFormDuration = createSelector(
    selectForm,
    form => form.duration && !Number.isNaN(parseInt(form.duration)) ? form.duration : "" )
;

const selectFormVariableTaxValue = createSelector(
    selectForm,
    form => form.variableTax)

const selectFormFixedTaxValue = createSelector(
    selectForm,
    form => form.fixedTax)

const selectFormVariableTaxCountry = createSelector(
    selectFormVariableTaxValue,
    selectFormFixedTaxValue,
    ( variableTaxValue, fixedTax) => {
        if(variableTaxValue === 20 && fixedTax === 5.5) return country.FRANCE
        else return country.OTHER
    })

const selectFormDurationIsValid = createSelector(
    selectFormDuration,
    (duration) => !isNaN(Number(duration)) && parseInt(duration) > 1
)
const selectInflationIsValid = createSelector(
    selectInflation,
    (inflation) => !isNaN(Number(inflation)) && parseInt(inflation) >=0
)
const selectVariableInflations = createSelector(
    selectFormInflationInput,
    financialParam => financialParam.variableInflations )
;
const selectFormSellingPriceSelectedCells = createSelector(
    selectFormSellingPriceTable,
    form => form.sellingPriceSelectedCells
);

const selectFormCapexes = createSelector(
    selectForm,
    form => form.capexes
);
const makeSelectCapexFromId = (capexId:string) => createSelector(
    selectFormCapexes,
    (capexes)=> capexes.find((capex)=> capex.id === capexId)
)
const selectFormCapexesHaveAtLeastOneLoan = createSelector(
    selectFormCapexes,
    (capexes:FormCapex[]) => atLeastOneLoanCapex(capexes)
);

const selectFormOpexes = createSelector(
    selectForm,
    form => form.opexes
);
const selectSellingPriceIsValid = createSelector(
    selectFormSellingPriceTable,
    form => !form.sellingPriceIsValid.some((sellingPricesRow:boolean[])=> {
        return sellingPricesRow.some((sellingPrice)=> {
            return !sellingPrice
        })
    })
);
const selectFormOpexesAreValid = createSelector(
    selectForm,
    form => form.opexesAreValid
);


export const makeIsLoanCapexValid  = (capex:FormCapex) => () =>capex.validateLoanPrice()
const selectFormCapexesLoanAreValid = createSelector(
    selectFormCapexes,
    (capexes) =>   capexes.every((capex:FormCapex) => capex.validateLoan())
)
const selectFormCapexesAreValid = createSelector(
    selectFormCapexes,
    (capexes) => validateAllCapexes(capexes)
)

const selectAllLoanPricesAreValid = createSelector(
    selectFormCapexes,
    (capexes)=> capexes.every((capex:any)=> capex.validateLoanPrice())
)
const selectAllCapexPricesAreValid  = createSelector(
    selectFormCapexes,
    (capexes)=> capexes.every((capex:any)=> capex.validateCapexPrice())
)

const selectHasInflationChanged =  createSelector(
    selectInflation,
    selectVariableInflations,
    selectComplementProviderInflationRate,
    selectVariableInflationsToggled,
    (inflation, variableInflationsForm, storeInflation, variableInflationsToggled) => hasInflationChanged(inflation, variableInflationsForm, storeInflation, variableInflationsToggled)
);

const selectHasSellingPriceInflationsChanged =  createSelector(
    selectSellingPriceTemporaryVariableInflations,
    selectSellingPriceVariableInflations,
    selectSellingPriceInflationToggled,
    (variableInflationsForm, storeInflation, variableInflationsToggled) => hasInflationChanged('', variableInflationsForm, storeInflation, variableInflationsToggled)
);

const selectWillDurationChangeAffectVariableInflations = createSelector(
    selectFormSellingPrice,
    selectActiveInjectionPoints,
    selectActiveSoutiragePoints,
    (sellingPriceInTable, injectionPoints, soutiragePoints) => {
        if(soutiragePoints.length > 0 && injectionPoints.length >0 ){
            return soutiragePoints.some((sout: any) => {
                return injectionPoints.some((inj:InjectionPoint) => {
                    if(sellingPriceInTable[sout.id] && sellingPriceInTable[sout.id][inj.id]){
                        return sellingPriceInTable[sout.id][inj.id].type === SellingPriceFunction.FIXED_PRICE_WITH_INFLATION
                    }
                })
            })
        }
        return false
    }
);


export {
    makeSelectCapexFromId,
    selectAllCapexPricesAreValid,
    selectAllLoanPricesAreValid,
    selectFormCapexesLoanAreValid,
    selectFormCapexesAreValid,
    selectFormFixedTaxValue,
    selectFormVariableTaxCountry,
    selectFormVariableTaxValue,
    selectWillDurationChangeAffectVariableInflations,
    selectHasSellingPriceInflationsChanged,
    selectHasInflationChanged,
    selectFormDurationIsValid,
    selectInflationIsValid,
    selectFormCapexesHaveAtLeastOneLoan,
    selectInflation,
    selectSellingPriceTemporaryVariableInflations,
    selectFormDuration,
    selectVariableInflations,
    selectModalSellingPriceInflations,
    selectVariableInflationsToggled,
    selectSellingPriceVariableInflations,
    selectSellingPriceInflationToggled,
    selectSellingPriceInflation,
    selectSellingPriceInflationToApply,
    selectFormSellingPrice,selectFormSellingPriceSelectedCells, selectFormCapexes,selectFormOpexes, selectFormOpexesAreValid,   selectSellingPriceIsValid}
