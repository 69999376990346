import {LabelInput} from "../../../../../../components/assets/FormAssets/TextInput";
import React, {useState} from "react";
import {typologyFormActions} from "../../../../../../../../../corelogic/usecases/typology-form/typologyFormActions";
import {useDispatch, useSelector} from "react-redux";
import {
    selectSGERetries,
    selectSGERetriesHasError
} from "../../../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";

export default function RetriesInput() {

    return <div className={"max-w-40 w-32"}>
        <LabelInput label={"NOMBRE DE RETRY"} mandatory={true}/>
        <Input/>
    </div>
}

const Input: React.FC = () => {
    const dispatch  = useDispatch()
    const retry = useSelector(selectSGERetries)
    const retryHasError = useSelector(selectSGERetriesHasError)
    const [mouseHasLeft, setMouseHasLeft] = useState(false)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(e.target.value.length <= 1){
            // Remove all non-digit characters
            const digitsOnly = e.target.value.replace(/\D/g, '');
            dispatch(typologyFormActions.setSGERetries(digitsOnly));
        }
    }



    return (<div className={"flex flex-col"}>
            <input
                placeholder={"(ex : 2)"}
                className={"px-2 w-full text-gray-700 dark:text-gray-300 focus:outline-none py-1 rounded bg-gray-100 dark:bg-purple-700 dark:bg-opacity-20 focus:border-purple-600 focus:border-2 border-purple-200 dark:border-gray-700 border font-bold tracking-widest"}
                type="text"
                pattern="[0-9]" // Allow only digits
                value={retry}
                onBlur={() => setMouseHasLeft(true)}
                onChange={handleChange}
            />
            {retryHasError && mouseHasLeft &&
            <span className={"text-xs text-red-500  left-1 bottom-[-2]"}>
                Le nombre de retry autorisé est entre 1 et 9.
            </span>}
        </div>
    );
};

