import {applyMiddleware, createStore} from "redux";
import rootReducer from "./rootReducer";
import createSagaMiddleware from 'redux-saga';
import {routerMiddleware} from "connected-react-router";
import rootSaga from "./rootSaga";
import {Dependencies} from "../dependencies";
import {composeWithDevTools} from "@redux-devtools/extension";

export const createReduxStore = (dependencies: Dependencies, history: any) => {
    const sagaMiddleware = createSagaMiddleware({ context: dependencies });
    const middlewares = [sagaMiddleware, routerMiddleware(history)];
    const middlewareEnhancer = applyMiddleware(...middlewares);
    const enhancers = [middlewareEnhancer];
    const composedEnhancers = composeWithDevTools(...enhancers);
    const store = createStore(rootReducer(history),composedEnhancers);
    sagaMiddleware.run(rootSaga);
    return store;
};