import {useDispatch, useSelector} from "react-redux";
import {push} from "connected-react-router";
import {selectRemainingNumberOfSimulations} from "../../../../../corelogic/usecases/authentication/authenticationSelector";
import {useSnackbar} from "notistack";

type EmptyTableType = {
    title: string,
    description: string,
    btnText?:string,
    btnDisabled ?:boolean
}
export default function ({title, description, btnText, btnDisabled}: EmptyTableType) {
    const dispatch = useDispatch()
    const {enqueueSnackbar} = useSnackbar()
    function redirectToCreationPage(){
        if(!btnDisabled){
            dispatch(push('/simulation/create'))
        }else{
            enqueueSnackbar(`Vous n'avez plus de crédits pour cette organisation. Contactez Enogrid pour en savoir plus.`, {variant: 'warning'})

        }
    }

    return <div data-cy={"empty-table"} className="bg-slate-50 w-full flex flex-col p-10 items-center dark:bg-zinc-800">
        <div className="flex">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-gray-800 dark:text-zinc-300" fill="none"
                 viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round"
                      d="M8 13v-1m4 1v-3m4 3V8M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"/>
            </svg>
            <div className="text-gray-800 dark:text-zinc-300 text-xl py-2 pl-2">{title}</div>
        </div>
        <div className="text-gray-500 dark:text-zinc-400 font-light">{description}
        </div>
        {
            btnText && <button onClick={redirectToCreationPage}
                                 className={!btnDisabled ?"btn-selected bg-secondary mr-6 opacity-80 hover:opacity-100 my-3" :  "btn-selected bg-slate-400 mr-6 opacity-40 my-3"}>{btnText}</button>
        }

    </div>
}
