import React from "react";
import DeleteLowCompletenessRow from "./DeleteLowCompletenessRow";
import DateStartInput from "./DateStartInput";
import DateEndInput from "./DateEndInput";
import VolumeInput from "./VolumeInput";
import {useSelector} from "react-redux";
import {
    selectLowCompletenessTable
} from "../../../../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";

const LowCompletenessTable = () => {
    return <table className="min-w-full rounded mb-3 ">
        <THead/>
        <TBody/>
    </table>
}

export default LowCompletenessTable

const THead = () => <thead>
<tr className="  rounded text-gray-600 dark:text-gray-300 text-sm leading-normal">
    <th className="pt-3 pr-0 text-left overflow-ellipsis overflow-hidden  whitespace-nowrap">Date de début</th>
    <th className=" pt-3 px-0 text-left overflow-ellipsis overflow-hidden  whitespace-nowrap">Date de fin</th>
    <th className="pt-3 px-0 text-left overflow-ellipsis overflow-hidden  whitespace-nowrap">Volume</th>
    <th rowSpan={2} className="py-3 px-0 text-left w-[60px]"></th>
</tr>
<tr className=" rounded text-gray-500 dark:text-gray-400 tracking-wide text-xs leading-normal  ">
    <th className="pb-3 pr-0  text-left overflow-ellipsis overflow-hidden uppercase  whitespace-nowrap">JJ - MM - AAAA
    </th>
    <th className=" pb-3 px-0 text-left overflow-ellipsis overflow-hidden  uppercase whitespace-nowrap">JJ - MM - AAAA
    </th>
    <th className=" pb-3 px-0 text-left overflow-ellipsis overflow-hidden  whitespace-nowrap w-[30px]">kWh</th>
</tr>

</thead>
const TBody = () => {
    const data = useSelector(selectLowCompletenessTable)
    return <tbody className={" text-blue-600 dark:text-blue-100 opacity-80"} id="table-body">
    {
        data.map((_:any, index:number) => {
                return <tr className={'border-b-2 border-blue-200 dark:border-opacity-20 '}>
                    <DateStartInput index={index}/>
                    <DateEndInput index={index}/>
                    <VolumeInput  index={index}/>
                    {index !== 0 && <DeleteLowCompletenessRow  index={index}/>}
                </tr>
            }
        )}
    </tbody>
}



