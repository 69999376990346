import {RootState} from "../../../store/rootReducer";
import {createSelector} from "reselect";
import {Organisation} from "../../models/types/simulations-display/Organisation";
import {selectSimulations} from "../simulations-display/simulationsDisplaySelector";

const selectAuthentication : any = (state: RootState) => state.authentication;

export const selectIsAuthenticated = createSelector(
    selectAuthentication,
    authentication => !!authentication.user
);

const selectPasswordHasBeenUpdated = createSelector(
    selectAuthentication,
    authentication => authentication.passwordHasBeenUpdated
);
const selectUser = createSelector(
    selectAuthentication,
    authentication => authentication.user
);

const selectEmail = createSelector(
    selectUser,
    user => user.email
);
const selectName = createSelector(
    selectUser,
    user => user.name
);
export const selectOrganisations = createSelector(
    selectAuthentication,
    (auth) => auth.organisations.sort((org1:Organisation, org2:Organisation) => org1.name.localeCompare(org2.name))
);

const selectIsRoleEnogrid = createSelector(
    selectOrganisations,
    (organisations) => organisations.some((organisation:any) => organisation.name.toLowerCase() === 'enogrid')
)

const selectSelectedOrganisation = createSelector(
    selectAuthentication,
    (auth) => auth.selectedOrganisation
);
const selectSelectedOrganisationId = createSelector(
    selectSelectedOrganisation,
    (orgaSelected) => orgaSelected?.id || null
);

const selectSelectedOrganisationName = createSelector(
    selectSelectedOrganisation,
    (orgaSelected) => orgaSelected?.name
);
const selectOpenOperationAccess = createSelector(
    selectUser,
    user => user.open_operation_allowed
);

export const makeSelectToAllowedOpenOperation = (organisationId: string) => createSelector(
    selectOrganisations,
    (organisations) => organisations.length> 0 && organisationId ?
        organisations.filter((orga: Organisation) => orga.id === organisationId)[0].open_operation_allowed
        : false
)


const selectAreCreditsRemaining = createSelector(
    selectOrganisations,
    (organisations : Organisation[]) => organisations.some((organisation : Organisation)=> organisation.remaining_number_of_simulations>0)
);


const selectRemainingNumberOfSimulations = createSelector(
    selectSelectedOrganisation,
    (orgaSelected) => orgaSelected?.remaining_number_of_simulations || 0
);

export {selectIsRoleEnogrid, selectUser, selectAreCreditsRemaining, selectPasswordHasBeenUpdated, selectOpenOperationAccess, selectEmail, selectName, selectRemainingNumberOfSimulations, selectSelectedOrganisationId, selectSelectedOrganisation}
