import TextInputForTable from "../../../../../components/assets/FormAssets/TextInputForTable";
import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {makeIsLoanCapexValid, selectFormCapexes} from "../../../../../../../../corelogic/usecases/form/formSelector";
import {formActions} from "../../../../../../../../corelogic/usecases/form/formActions";
import {FormCapex} from "../FormCapex";
import {Loan} from "../../../../../../../../corelogic/models/types/new-simulation/form/Loan";

export type loanInputType = {
    loan: { price: string, duration: string, rate: string },
    index: number,
    disabled?:boolean
    loading ?:boolean
}

const LoanPriceInput = ({loan, index ,loading, disabled}: loanInputType) => {
    const capexes = useSelector(selectFormCapexes)

    const selectLoanPriceIsValid = useCallback(makeIsLoanCapexValid(capexes[index]), [capexes[index]]);
    const loanIsValid = useSelector(selectLoanPriceIsValid)

    const dispatch = useDispatch()

    function loanOnChange(e: string, index: number) {
        let capexChanged:FormCapex[] = [...capexes]
        capexChanged[index].loan = new Loan(e,  capexChanged[index].loan?.duration || '', capexChanged[index].loan?.rate || '')

        dispatch(formActions.setCapexes(capexChanged));
    }

    return <td className={""}>
        {loan && <TextInputForTable
            id={"capex-loan-price" + index}
            disabled={disabled}
            index={index}
            onChange={loanOnChange}
            value={loading ? 'chargement...':loan.price}
            isValid={loanIsValid}/>}
    </td>
}
export default LoanPriceInput