import {useSelector} from "react-redux";
import {selectDisplayCDCSGEBtn} from "../../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import ChartBarIcon from "../../../../../components/assets/icon/ChartBarIcon";
import React from "react";
import XIcon from "./XIcon";
import {PlusIcon} from "../../../../../components/assets/icon/PlusIcon";
import XLSExportButton from "../../../../../utils/XLSExportButton";
import {
    selectNewCurve
} from "../../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-curve/selectFormCurve";

export default function Header() {
    const newCurve = useSelector(selectNewCurve)
    const sge = useSelector(selectDisplayCDCSGEBtn)

    return <>
        <div className={"flex w-full justify-between gap-2"}>
            <div className="self-stretch justify-start items-center gap-2 inline-flex">
                <ChartBarIcon className={"h-6 w-6 text-gray-700 dark:text-gray-200"}/>
                <div
                    className="text-gray-700 dark:text-gray-200 text-sm font-bold leading-normal whitespace-nowrap">Récupération
                    SGE
                </div>
            </div>
            <div className={"flex gap-2  justify-end"}>
                {newCurve && <XLSExportButton title={'SGE_CDC'} data={newCurve.measurement}/>}
                {sge ? <XIcon/> : <PlusIcon/>}
            </div>
        </div>
        <div className=" text-gray-400 text-xs font-medium leading-none">Récupérez la courbe de charge SGE avec le PRM
            du participant.
        </div>
    </>
}
