import {useSelector} from "react-redux";
import CDCPrevisuChart from "../../../../../components/assets/graphic/CDCPrevisuChart";
import React, {useState} from "react";
import FormRecap from "./FormRecap";
import FullCompletudeButton from "../../completude-and-recalage-button/full-completude/FullCompletudeButton";
import SGEWeeksSuccessRecap from "./success/SGEWeeksSuccessRecap";
import SGEWeeksFailureRecap from "./failure/SGEWeeksFailureRecap";
import ChevronRight from "../../../../../components/assets/icon/ChevronRight";
import ChevronDown from "../../../../../components/assets/icon/ChevronDown";
import {
    selectAtLeastOneSGEWeekFailed
} from "../../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import CheckIcon from "../../../../../components/assets/icon/CheckIcon";
import ExclamationIcon from "../../../../../components/assets/icon/ExclamationIcon";
import RecalageButton from "../../completude-and-recalage-button/recalage/button/RecalageButton";
import RecalageForm from "../../completude-and-recalage-button/recalage/form/RecalageForm";
import LowCompletenessButton from "../../completude-and-recalage-button/low-completeness/LowCompletenessButton";
import LowCompletenessForm from "../../completude-and-recalage-button/low-completeness/form/LowCompletenessForm";
import FullCompletudeForm from "../../completude-and-recalage-button/full-completude/FullCompletudeForm";
import {LoadCurveStatusBadge} from "../../badge/LoadCurveBadge";
import TotalEnergyBadge from "../../badge/TotalEnergyBadge";
import MaxPowerBadge from "../../badge/MaxPowerBadge";
import {
    selectNewCurve
} from "../../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-curve/selectFormCurve";


export default function Results() {
    const newCurve = useSelector(selectNewCurve)
    const [showInfos, setShowInfos] = useState(false)

    return <div className={"pt-4 w-full"}>
        <Header showInfos={showInfos} setShowInfos={setShowInfos}/>

        <div className={"flex gap-4 w-full p-2"}>
            <FormRecap showInfos={showInfos}/>
            <SGEWeeksFetchedRecap showInfos={showInfos}/>
        </div>
        {
            newCurve && newCurve.measurement.length > 0 ? <>
                <div className={"w-full flex justify-end my-2 gap-2"}>
                    <LowCompletenessButton/>
                    <FullCompletudeButton/>
                    <RecalageButton/>
                </div>
                <RecalageForm/>
                <FullCompletudeForm/>
                <LowCompletenessForm/>
                <div className={"flex w-full flex-wrap gap-2 relative top-14 left-6"}>
                    {newCurve &&<span
                        className={"text-sm text-gray-500  dark:text-gray-300 tracking-normal font-semibold"}>
                    Courbe de charge ajoutée
                </span>}
                    {newCurve && <LoadCurveStatusBadge/>}
                    <TotalEnergyBadge/>
                    <MaxPowerBadge/>
                </div>
                <div className={"w-full"}>
                    <CDCPrevisuChart color={"rgb(59,130,246)"} data={newCurve}/>
                </div>
            </> : <span
                className={" w-full text-sm px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-600 dark:text-gray-300"}>Courbe récupérée vide.</span>
        }
    </div>
}
const Header = ({showInfos, setShowInfos}: { showInfos: boolean, setShowInfos(show: boolean): void }) => {
    const infosContainError = useSelector(selectAtLeastOneSGEWeekFailed)
    return <div
        onClick={() => setShowInfos(!showInfos)}
        className={"gap-2 bg-gray-200 bg-opacity-60 h-min  px-4 py-2 my-1 text-gray-600 flex w-full justify-between whitespace-nowrap dark:bg-opacity-10 dark:bg-gray-800 dark:text-gray-300  w-full hover:cursor-pointer opacity-80 hover:opacity-100"}>
        <div className={"flex gap-2"}>
            <span className={"font-semibold text-gray-800 text-sm dark:text-gray-200"}>Courbe de charge récupérée et résultats de récupération</span>
            {infosContainError ?
                <ExclamationIcon className={"h-5 w-5 text-red-600"}/>
                : <CheckIcon color={'text-green-600'}/>
            }
        </div>
        {showInfos ?
            <ChevronDown classname={"text-gray-700 dark:text-gray-200 h-5 w-5"}/>
            : <ChevronRight className={"text-gray-700 dark:text-gray-200 h-5 w-5"}/>}
    </div>
}

function SGEWeeksFetchedRecap({showInfos}: { showInfos: boolean }) {
    return showInfos ? <div className={"flex flex-col gap-2 w-full"}>
        <SGEWeeksSuccessRecap/>
        <SGEWeeksFailureRecap/>
    </div> : <></>
}

