export enum balanceMetricsNature {

    TRI = 'irr',
    TRA = 'dpd',
    LCOE = 'lcoe',
    internalValuation = 'internal_kwh_valuation',
    totalEconomyYear1 = 'total_economy_first_year',
    totalEconomyYear25 = 'total_economy_last_year',
    productorGainAndExcessYear1 = 'total_gain_first_year',
    productorGainAndExcessYear25 = 'total_gain_last_year',
}