import {ReactComponent as ExplanationRepartitionAlgo} from "./assets/explanation-algo-repartition.svg";
import {ReactComponent as ExplanationRepartitionAlgoDark} from "./assets/explanation-algo-repartition-dark.svg";

import {ReactComponent as ExplanationRepartitionDefaultAlgo} from "./assets/explanation-default-repartition.svg";
import {
    ReactComponent as ExplanationRepartitionDefaultAlgoDark
} from "./assets/explanation-default-repartition-dark.svg";

import {ReactComponent as ExplanationRepartitionByPrioAlgo} from "./assets/explanation-piority-repartition.svg";
import {
    ReactComponent as ExplanationRepartitionByPrioAlgoDark
} from "./assets/explanation-priority-repartition-dark.svg";
import {ReactComponent as ExplanationRepartitionByPrioComplexAlgo} from "./assets/explanation-piority-repartition-complex.svg";
import {ReactComponent as ExplanationRepartitionByPrioComplexAlgoDark} from "./assets/explanation-piority-repartition-complex-dark.svg";
import {ReactComponent as ExplanationSurplusRepartitionDefault} from "./assets/explanation-surplus-default.svg";
import {
    ReactComponent as ExplanationSurplusRepartitionDefaultDark
} from "./assets/explanation-surplus-default-dark.svg";

import {ReactComponent as ExplanationSurplusRepartitionByPrioAlgo} from "./assets/explanation-surplus-prio.svg";
import {
    ReactComponent as ExplanationSurplusRepartitionByPrioAlgoDark
} from "./assets/explanation-surplus-prio-dark.svg";

import {ReactComponent as ExplanationRepartitionByShareAlgo} from "./assets/explanation-share-repartition.svg";
import {ReactComponent as ExplanationRepartitionByShareAlgoDark} from "./assets/explanation-share-repartition-dark.svg";

import {ReactComponent as ExplanationRepartitionByShareComplexAlgo} from "./assets/explanation-share-repartition-complex.svg";
import {ReactComponent as ExplanationRepartitionByShareComplexAlgoDark} from "./assets/explanation-share-repartition-complex-dark.svg";

import {
    ReactComponent as ExplanationRepartitionByUngroupedPriorityAlgo
} from "./assets/explanation-ungrouped-priority-repartition.svg";
import {
    ReactComponent as ExplanationRepartitionByUngroupedPriorityAlgoDark
} from "./assets/explanation-ungrouped-priority-repartition-dark.svg";

export type Question = {
    id: string,
    title: string,
    description: string[],
    svg?: {
        light: JSX.Element,
        dark: JSX.Element
    },
    key: string,
    expand: boolean,
}
export const AllQuestions = (): Question[] => {
    const eco1 = {
        id: 'eco1',
        title: 'Comment et où est prise en compte la majoration de TURPE ?',
        description: ['La majoration de TURPE liée à l’autoconsommation collective est prise en compte dans les économies \n' +
        'ACC ou ACI de chaque consommateur. Il est calculé ce qu’un consommateur économise annuellement \n' +
        'sur sa facture d’électricité en consommant localement puis il est déduit la majoration de TURPE à \n' +
        'laquelle il est soumis selon son segment consommateur. Cette majoration est multipliée par le taux de \n' +
        'TVA à 5.5%.'],
        key: 'bilan économique',
        expand: false,
    }
    const eco2 = {
        id: 'eco2',
        title: 'Comment est calculée l’économie ACI ?',
        description: ["Pour calculer l'économie ACI, le nombre de kWh autoconsommé en ACI est multiplié par la somme du\n" +
        "        prix d'un kWh plus l'Accise qui s'applique dessus (différenciation consommateur professionnel ou \n" +
        "        particulier) et du TURPE qui s’applique sur le segment. Le résultat est ensuite multiplié suivant les 20%\n" +
        "    de taux de TVA. A cela il est déduit la majoration de TURPE multipliée par la TVA à 5.5%.\n" +
        "    La formule est la suivante :",
            "        (𝑘𝑊ℎ 𝑎𝑢𝑡𝑜𝑐𝑜𝑛𝑠𝑜𝑚𝑚é 𝑒𝑛 𝐴𝐶𝐼 ∗ (𝑉𝐴𝐿𝑂 𝑘𝑊ℎ 𝐻𝑇𝑇 + 𝐴𝑐𝑐𝑖𝑠𝑒 + 𝑇𝑈𝑅𝑃𝐸) ∗ 𝑇𝑉𝐴(20%))\n" +
            "− 𝑀𝐴𝐽𝑂 𝑇𝑈𝑅𝑃𝐸 ∗ 𝑇𝑉𝐴(5,5%)",
            "    Si vous avez déclaré qu’un participant pratique la récupération de TVA, ses économies seront calculées\n" +
            "    sans prendre en compte la TVA."],
        key: 'bilan économique',
        expand: false,
    }
    const eco3 = {
        id: 'eco3',
        title: 'Est-ce que la majoration de TURPE subit une inflation ?',
        description: ["La majoration de TURPE évolue tous les ans au 1er août. Le tarif étant fixé par la CRE, il n’est pas possible \n" +
        "de prévoir exactement son évolution. L’inflation pour ce paramètre est fixée à 1,5%, ce qui est aussi \n" +
        "souvent l’hypothèse d’inflation des OPEX."],
        key: 'bilan économique',
        expand: false,
    }
    const eco4 = {
        id: 'eco4',
        title: 'Comment est calculée l’économie ACC ?',
        description: ["L’économie ACC est la même que l’économie ACI à la différence du TURPE qui n’est pas économisé. Le \n" +
        "TURPE est collecté par le fournisseur sur la totalité de la consommation dans le cas d’un \n" +
        "consommateur en ACC.\n" +
        "La formule est alors la suivante : \n",
            "(𝑘𝑊ℎ 𝑎𝑢𝑡𝑜𝑐𝑜𝑛𝑠𝑜𝑚𝑚é 𝑒𝑛 𝐴𝐶𝐶 ∗ (𝑉𝐴𝐿𝑂 𝑘𝑊ℎ 𝐻𝑇𝑇 + 𝐴𝑐𝑐𝑖𝑠𝑒 ) ∗ 𝑇𝑉𝐴(20%)) − 𝑀𝐴𝐽𝑂 𝑇𝑈𝑅𝑃𝐸\n" +
            "∗ 𝑇𝑉𝐴(5,5%)\n",
            "Si vous avez déclaré qu’un participant pratique la récupération de TVA, ses économies seront calculées \n" +
            "sans prendre en compte la TVA."],
        key: 'bilan économique',
        expand: false,
    }

    const eco5 = {
        id: 'eco5',
        title: 'Comment fonctionne l’Accise sur l’électricité ?',
        description: ["L’accise sur l’électricité est une taxe en vigueur depuis le 1er février 2022 qui vient remplacer les autres \n" +
        "taxes comme la TICFE, la CSPE et les taxe communale et départementale sur la consommation finale \n" +
        "d’électricité. En 2024, le niveau d’accise est de 21€/MWh pour les ménages et assimilés \n" +
        "et 20,5€/MWh pour les autres."],
        key: 'bilan économique',
        expand: false,
    }
    const eco6 = {
        id: 'eco6',
        title: ' Dans quelle typologie d’opération l’Accise est économisée ou due sur les kWh ' +
            'autoconsommés collectivement ?',
        description: ["L’accise doit être reversée lorsque le producteur et le consommateur sont des entités différentes. En \n" +
        "opération patrimoniale, l’Accise est économisée (si la puissance des centrales est inférieure à 1MWc)\n" +
        "alors qu’en opération sociale, l’Accise est toujours due par le bailleur."],
        key: 'bilan économique',
        expand: false,
    }
    const eco7 = {
        id: 'eco7',
        title: 'Comment fonctionne l’IFER ?',
        description: ["L’IFER est un impôt sur les installations photovoltaïques et hydrauliques d’une puissance supérieure ou \n" +
        "égale à 100kW, Il est à ce jour de 3,394€ par kW de puissance installée."],
        key: 'bilan économique',
        expand: false,
    }
    const eco8 = {
        id: 'eco8',
        title: 'Comment est calculée la facture interne ?',
        description: ["Pour calculer la facture interne, les kWh autoconsommés en ACC sont multipliés par le tarif de vente \n" +
        "interne HTT plus les accises, le tout multiplié par la TVA à 20%. \n" +
        "La formule est la suivante : \n",
            "(𝑘𝑊ℎ 𝑎𝑢𝑡𝑜𝑐𝑜𝑛𝑠𝑜𝑚𝑚é 𝑒𝑛 𝐴𝐶𝐶 ∗ (𝑡𝑎𝑟𝑖𝑓 𝑑𝑒 𝑣𝑒𝑛𝑡𝑒 𝐻𝑇𝑇 + 𝐴𝑐𝑐𝑖𝑠𝑒) ∗ 𝑇𝑉𝐴(20%))\n",
            "Si vous avez déclaré qu’un participant pratique la récupération de TVA ces économies seront calculées \n" +
            "sans prendre en compte la TVA."],
        key: 'bilan économique',
        expand: false,
    }
    const eco9 = {
        id: 'eco9',
        title: 'Pourquoi la facture interne est différente du revenu producteur ?',
        description: ["Le revenu producteur correspond à la facture interne moins ce qui doit être reversé à l’état : c’est-à-dire les taxes : Accises et TVA. "],
        key: 'bilan économique',
        expand: false,
    }
    const fin1 = {
        id: 'fin1',
        title: 'Comment sont répartis les CAPEX et OPEX globaux par producteur ?',
        description: ["Lorsque vous entrez des CAPEX ou OPEX globaux et qu’il y a plusieurs producteurs, le bilan financier \n" +
        "est séparé par producteur. Dans ce cas les CAPEX et OPEX sont répartis sur les centrales au prorata des \n" +
        "puissances de production."],
        key: 'bilan financier',
        expand: false,
    }

    const methodo1 = {
        id: 'methodo1',
        title: 'Comment déterminer le prix de vente interne ?',
        description: ["Pour déterminer le prix de vente interne entre producteur et consommateur, il faut trouver un prix \n" +
        "supérieur au LCOE et généralement supérieur à la vente de surplus, mais inférieur au prix fournisseur \n" +
        "du consommateur de cette façon il est trouvé dans un mécanisme gagnant-gagnant entre le producteur \n" +
        "et les consommateurs."],
        key: 'méthodologie',
        expand: false,
    }
    const methodo2 = {
        id: 'methodo2',
        title: 'Où peut-on ajouter le tarif fournisseur en heure de pointe ?',
        description: ["Il n’est pas possible pour le moment de renseigner le coût de l’électron en heure de pointe. Pendant \n" +
        "cette plage horaire, c’est le prix HPH (heure pleine hiver) qui sera considéré. À noter qu’avec une \n" +
        "production solaire il n’y a quasiment jamais de production pendant les heures de pointe."],
        key: 'méthodologie',
        expand: false,
    }
    const methodo3 = {
        id: 'methodo3',
        title: 'Est-ce que la perte de rendement des panneaux solaires est prise en compte ?',
        description: ["Non, la perte de rendement n’est pas prise en compte, il est renseigné une courbe de charge en année \n" +
        "1 et elle sera valable sur la durée de l’étude choisie. A titre informatif, l’ADEME considère que les \n" +
        "panneaux solaires produisent en moyenne 0,5% de puissance en moins tous les ans. Economiquement \n" +
        "il est possible de prendre en compte cette baisse de production en diminuant l’inflation des tarifs de la \n" +
        "fourniture de complément prise en compte d’autant."],
        key: 'méthodologie',
        expand: false,
    }
    const methodo4 = {
        id: 'methodo4',
        title: ' Qu’est-ce que la majoration de TURPE ?',
        description: ["La majoration de TURPE est le mécanisme par lequel les GRD répercutent les coûts de gestion \n" +
        "supplémentaires issue de la mise en place d’une ACC sur leur réseau. En faisant partie d’une opération \n" +
        "d’autoconsommation collective, un consommateur ou producteur doit s’acquitter tous les ans de ces\n" +
        "frais supplémentaires sur la part fixe du TURPE. Cette majoration n’est pas un facteur économiquement \n" +
        "dimensionnant pour l’opération, les coûts restent marginaux."],
        key: 'méthodologie',
        expand: false,
    }
    const methodo5 = {
        id: 'methodo5',
        title: ' Qu’est-ce que la réduction sur le tarif de complément ?',
        description: ["La réduction sur le tarif de complément permet de choisir un prix de vente interne à l’opération qui est \n" +
        "réduit d’un certain pourcentage par rapport au tarif entré en paramètre auquel le consommateur \n" +
        "achète son électricité auprès de son fournisseur. "],
        key: 'méthodologie',
        expand: false,
    }
    const ope1 = {
        id: 'ope1 =',
        title: 'Qui facture l’électricité au sein de l’opération d’ACC ?',
        description: ["C’est le producteur qui facture l’électricité à chacun des consommateurs. Il est important de ne pas confondre \n" +
        "producteur et PMO, ce sont des rôles différents."],
        key: "fonctionnement opérationnel d'une ACC",
        expand: false,
    }
    const ope2 = {
        id: 'ope2',
        title: 'C’est quoi le tarif de complément ?',
        description: ["On appelle tarif de complément le tarif auquel vous achetez votre électricité auprès de votre \n" +
        "fournisseur habituel."],
        key: "fonctionnement opérationnel d'une ACC",
        expand: false,
    }
    const ope3 = {
        id: 'ope3',
        title: 'C’est quoi ACI/ACC ?',
        description: ["ACI désigne l’autoconsommation individuelle, donc le fait de raccorder son moyen de production en \n" +
        "amont de son compteur de consommation. \n" +
        "ACC désigne l’autoconsommation collective."],
        key: "fonctionnement opérationnel d'une ACC",
        expand: false,
    }
    const ope4 = {
        id: 'ope4',
        title: 'Qu’est-ce que le segment ?',
        description: ["Un segment C5 correspond à un consommateur ou un producteur avec une puissance de raccordement inférieure \n" +
        "à 36kVA. Cela correspond à un tarif bleu. \n" +
        "Un segment C4 est un consommateur ou un producteur avec une puissance de raccordement entre 36 \n" +
        "et 250kVA. Cela correspond à un tarif jaune.\n" +
        "Un segment C3 ou C2 est un consommateur ou un producteur avec une puissance de raccordement \n" +
        "supérieure à 250kVA. Aussi appelé HTA, cela correspond à un tarif vert. "],
        key: "fonctionnement opérationnel d'une ACC",
        expand: false,
    }
    const ope5 = {
        id: 'ope5',
        title: 'Comment fonctionne le périmètre de 2km ?',
        description: ["Tous les participants (producteurs et consommateurs) doivent être contenus dans un cercle de 2km de \n" +
        "diamètre. Cela signifie qu’il ne peut pas y avoir plus de 2km entre les participants les plus éloignés de \n" +
        "l’opération. \n" +
        "Sur Enolab vous pouvez choisir la taille du cercle que vous souhaitez appliquer. "],
        key: "fonctionnement opérationnel d'une ACC",
        expand: false,
    }
    const ope6 = {
        id: 'ope6',
        title: 'Est-ce que je peux faire un périmètre de 20km ?',
        description: ["Pour faire un cercle de 20km de diamètre au lieu de 2km, une dérogation est nécessaire. Il faut pour \n" +
        "cela envoyer un courrier au ministère de la transition écologique en justifiant le besoin par la ruralité \n" +
        "de l’endroit où vous voulez mettre en place votre opération."],
        key: "fonctionnement opérationnel d'une ACC",
        expand: false,
    }
    const ope7 = {
        id: 'ope7',
        title: 'Dans quel cas mon producteur en ACI peut consommer en ACC ?',
        description: ["S’il y a plusieurs producteurs au sein de l’opération, le consommateur qui est relié en ACI sur son propre \n" +
        "moyen de production peut consommer en ACC l’électricité produite par d’autres producteurs de \n" +
        "l’opération."],
        key: "fonctionnement opérationnel d'une ACC",
        expand: false,
    }
    const ope8 = {
        id: 'ope8',
        title: 'Comment changer ma puissance de production lorsque j’ai fait une modélisation PVGis',
        description: ["Si vous utilisez la modélisation PVGis intégrée à Enolab et que vous avez déjà un point d’injection, la procédure est \n" +
        "relativement simple. Il va falloir recharger une courbe de production donc appuyez sur “éditer le point”, \n" +
        "supprimez l’ancienne courbe sur la croix rouge et vous pouvez maintenant simuler une nouvelle courbe \n" +
        "avec les hypothèses adéquates en cochant “Modéliser ma production solaire”."],
        key: "fonctionnement opérationnel d'une ACC",
        expand: false,
    }
    const ope9 = {
        id: 'ope9',
        title: 'Est-ce qu’il y a des profils types à disposition ? ',
        description: ["Des profils type générés par Enedis en fonction de la location de l’opération sont effectivement mis à \n" +
        "disposition dans le logiciel. Lors de la création d’un point de Soutirage, vous pouvez cocher “Modéliser \n" +
        "un profil type d'ENEDIS”. Il vous faudra choisir le moyen de chauffage ainsi que le type d’appartement \n" +
        "pour obtenir le profil le plus proche de la réalité.\n"],
        key: "fonctionnement opérationnel d'une ACC",
        expand: false,
    }
    const ope10 = {
        id: 'ope10',
        title: 'Est-ce que je peux dupliquer un point de consommation à l’identique ?',
        description: ["On ne peut pas dupliquer de point de consommation directement, mais de manière équivalente, on \n" +
        "peut indiquer le “NOMBRE DE POINTS À AJOUTER*”. Si on veut dupliquer un point de consommation, \n" +
        "il suffit donc d’indiquer que l’on veut ajouter 2 points de soutirages ayant cette courbe de charge."],
        key: "fonctionnement opérationnel d'une ACC",
        expand: false,
    }
    const repartition_algorithm = {
        id: 'repartition_algorithm',
        title: "Qu'est ce qu'un algorithme de répartition de la production ?",
        svg: {
            light: <ExplanationRepartitionAlgo/>,
            dark: <ExplanationRepartitionAlgoDark/>
        },
        description: ["L’objectif d'un algorithme de répartition est de décider, à partir de la production locale et de la consommation de chaque participant," +
        "de la quantité de production allouée à chaque consommateur.",
            "Cette répartition est effectuée au pas de temps 30 minutes.",
            "Il existe plusieurs algorithmes de répartition, chacun ayant ses avantages et ses inconvénients."],
        key: "algorithme de répartition",
        expand: false,
    }
    const repartition_algorithm_default = {
        id: 'repartition_algorithm_default',
        title: "Comment fonctionne l'algorithme de répartition par défaut ?",
        svg: {
            light: <ExplanationRepartitionDefaultAlgo/>,
            dark: <ExplanationRepartitionDefaultAlgoDark/>
        },
        description: ["L'algorithme de répartition par défaut permet de répartir la production locale au pro-rata de la consommation de chaque participant.",
            "Dans cet exemple, on définit le pro-rata de chaque participant comme étant sa consommation divisée par la somme des consommations de tous les participants.",
            "C1 = 80 / (80 + 40 + 40) = 50%",
            "C2 = 40 / (80 + 40 + 40) = 25%",
            "C3 = 40 / (80 + 40 + 40) = 25%",
            "La production locale est de 100 kWh. On alloue donc 50 kWh à C1, 25 kWh à C2 et 25 kWh à C3."],
        key: "algorithme de répartition",
        expand: false,
    }
    const repartition_algorithm_priority = {
        id: 'repartition_algorithm_priority',
        title: "Comment fonctionne l'algorithme de répartition par priorité ?",
        svg: {
            light: <ExplanationRepartitionByPrioAlgo/>,
            dark: <ExplanationRepartitionByPrioAlgoDark/>
        },
        description: ["L'algorithme de répartition par priorité permet de répartir la production locale en priorité à un ou plusieurs participant(s).",
            "Dans cet exemple, je veux que C2 et C3 consomment en priorité la production locale. Je définis donc une priorité de 1 pour C2 et C3 et de 2 pour C1.",
            "L'algorithme va d'abord allouer la production locale à C2 et C3.",
            "C2 = 40 kWh",
            "C3 = 40 kWh",
            "Il reste 20 kWh à allouer. L'algorithme va ensuite allouer la production locale à C1, de priorité inférieure.",
            "C1 = 20 kWh"
        ],
        key: "algorithme de répartition",
        expand: false,
    }
    const repartition_algorithm_priority_complex = {
        id: 'repartition_algorithm_priority_complex',
        title: "Comment fonctionne l'algorithme de répartition par priorité ? (cas détaillé)",
        svg: {
            light: <ExplanationRepartitionByPrioComplexAlgo/>,
            dark: <ExplanationRepartitionByPrioComplexAlgoDark/>
        },
        description: ["L'algorithme de répartition par priorité permet de répartir la production locale en priorité à un ou plusieurs participant(s).",
            "Dans cet exemple, je veux que C2 et C3 consomment en priorité la production locale. Je définis donc une priorité de 1 pour C2 et C3 et de 2 pour C1.",
            "L'algorithme va d'abord allouer la production locale à C2 et C3, de manière égalitaire, car leur priorité est la même.",
            "C2 = 50 kWh",
            "C3 = 50 kWh",
            "C3 ne peut absorber que 30 kWh de production locale. 20 kWh reste donc à allouer.",
            "Les données d'entrée ont changé. 20 kWh sont à répartir entre C1 de priorité 2, et C2 de priorité 1, dont il reste 10 kWh de consommation.",
            "L'algorithme va allouer la production aux priorités 1, donc à C2 :",
            "C2 = 20 kWh",
            "C2 ne peut absorber que 10 kWh de production locale. 10 kWh reste donc à allouer.",
            "Les données d'entrée ont changé. 10 kWh sont à répartir entre C1 de priorité 2.",
            "C1 = 10 kWh",
            "Au final, C1 récupère 10 kWh, C2 60 kWh (50 + 10) et C3 30 kWh."
        ],
        key: "algorithme de répartition",
        expand: false,
    }
    const repartition_algorithm_ungrouped_priority = {
        id: 'repartition_algorithm_ungrouped_priority',
        title: "Comment fonctionne l'algorithme de répartition par priorité dégroupée ?",
        svg: {
            light: <ExplanationRepartitionByUngroupedPriorityAlgo/>,
            dark: <ExplanationRepartitionByUngroupedPriorityAlgoDark/>
        },
        description: ["L'algorithme de répartition par priorité dégroupée permet de répartir, pour chaque producteur, une priorité vers des consommateurs.",
            "A l'inverse des autres algorithmes, il n'y a pas de pot commun de production. Chaque producteur a sa propre priorité de répartition. ",
            "Dans cet exemple, je veux que C1 récupère en priorité la production de P1, et que C2 récupère en priorité la production de P2.",
            "Je définis donc une priorité de 1 pour C1 et P1, et de 1 pour C2 et P2.",
            "L'algorithme va d'abord allouer la production de P1 à C1, et P2 à C2 :  c'est le tour des priorités 1.",
            "C1 = 50 kWh = toute la production de P1, 0 kWh de P2",
            "C2 = 40 kWh = 0 kWh de P1, 40 kWh de P2.",
            "Il ne reste alors rien à distribuer de la part de P1, et 20kWh de la part de P2.",
            "L'algorithme va ensuite allouer la production de P2 restante au consommateur de priorité 2, C3.",
            "C3 = 20 kWh = toute la production restante de P2.",
            "Il ne reste alors rien à distribuer de la part de P2.",
        ],
        key: "algorithme de répartition",
        expand: false,
    }
    const repartition_algorithm_share = {
        id: 'repartition_algorithm_share',
        title: "Comment fonctionne l'algorithme de répartition par quote part ?",
        svg: {
            light: <ExplanationRepartitionByShareAlgo/>,
            dark: <ExplanationRepartitionByShareAlgoDark/>
        },
        description: ["L'algorithme de répartition par quote-part permet de répartir la production locale selon des parts fixe. Il permet également de définir une part de surplus.",
            "Dans cet exemple, je veux que C3 récupère deux fois plus que C1 et C2. Je définis donc 2 parts pour C3 et 1 pour C1 et C2.",
            "Je veux également définir une part de surplus.",
            "Il y a donc 5 parts égales de 20kWh chacune à répartir : 2 pour C3, 1 pour C1, 1 pour C2 et 1 pour le surplus.",
            "C3 = 2 * 20 kWh = 40 kWh",
            "C1 = 1 * 20 kWh = 20 kWh",
            "C2 = 1 * 20 kWh = 20 kWh",
            "Surplus = 1 * 20 kWh = 20 kWh"
        ],
        key: "algorithme de répartition",
        expand: false,
    }
    const repartition_algorithm_share_complex = {
        id: 'repartition_algorithm_share_complex',
        title: "Comment fonctionne l'algorithme de répartition par quote part ? (cas détaillé)",
        svg: {
            light: <ExplanationRepartitionByShareComplexAlgo/>,
            dark: <ExplanationRepartitionByShareComplexAlgoDark/>
        },
        description: ["L'algorithme de répartition par quote-part permet de répartir la production locale selon des parts fixe. Il permet également de définir une part de surplus.",
            "Dans cet exemple, je veux que C3 récupère quatre fois plus que C1 et C2. Je définis donc 4 parts pour C3 et 1 pour C1 et C2.",
            "Je veux également définir une part de surplus.",
            "Il y a donc 7 parts égales de 20kWh chacune à répartir : 4 pour C3, 1 pour C1, 1 pour C2 et 1 pour le surplus.",
            "C3 = 4 * 20 kWh = 80 kWh",
            "C1 = 1 * 20 kWh = 20 kWh",
            "C2 = 1 * 20 kWh = 20 kWh",
            "Surplus = 1 * 20 kWh = 20 kWh",
            "C3 ne peut absorber que 40 kWh de production locale. 40 kWh reste donc à allouer.",
            "Deuxième tour de répartition :",
            "Les données d'entrée ont changé. 40 kWh sont à répartir entre C1 et C2, qui ont une part de 1.",
            "Comme C3 a déjà rempli sa consommation et que le surplus n'est affecté qu'au premier tour de répartition,",
            "il y a donc 2 parts égales de 20 kWh chacune (40/2) :"
            ,"C1 = 1 * 20 kWh = 20 kWh",
            "C2 = 1 * 20 kWh = 20 kWh",
            "Au final, C1 récupère au bout de deux tours de répartition 40 kWh, C2 40 kWh, C3 40 kWh et le surplus 20 kWh."
        ],
        key: "algorithme de répartition",
        expand: false,
    }

    const repartition_surplus_algorithm_default = {
        id: 'repartition_surplus_algorithm_default',
        title: "Comment fonctionne l'algorithme de répartition du surplus par défaut ?",
        svg: {
            light: <ExplanationSurplusRepartitionDefault/>,
            dark: <ExplanationSurplusRepartitionDefaultDark/>
        },
        description: ["L'algorithme de répartition par défaut permet de répartir le surplus de production locale au pro-rata de la production de chaque producteur.",
            "Dans cet exemple, on définit le pro-rata de chaque producteurs comme étant sa production divisée par la somme des productions de tous les producteur.",
            "P2 = 100 / (100 + 60 + 40) = 50%",
            "P2 = 60 / (100 + 60 + 40) = 30%",
            "P3 = 40 / (100 + 60 + 40) = 20%",
            "Le surplus de production est de 100kWh. On alloue donc 50 kWh à P, 30 kWh à P2 et 20 kWh à P3."],
        key: "algorithme de répartition",
        expand: false,
    }
    const repartition_surplus_algorithm_priority = {
        id: 'repartition_surplus_algorithm_priority',
        title: "Comment fonctionne l'algorithme de répartition du surplus par priorité ?",
        svg: {
            light: <ExplanationSurplusRepartitionByPrioAlgo/>,
            dark: <ExplanationSurplusRepartitionByPrioAlgoDark/>
        },
        description: ["L'algorithme de répartition par priorité permet de répartir le surplus de production locale en priorité à un ou plusieurs producteur(s).",
            "Dans cet exemple, je veux que P2 récupère en priorité le surplus de production locale. Je définis donc une priorité de 1 pour P2 et de 2 pour P1 et P3.",
            "L'algorithme va d'abord allouer le surplus de production locale à P2.",
            "P2  = 60 kWh (il récupère toute sa production sous forme de surplus)",
            "Il reste 40 kWh à allouer. L'algorithme va ensuite allouer le surplus de production locale à P1 et P3, de priorité inférieure.",
            "P1 = 20 kWh",
            "P3 = 20 kWh"
        ],
        key: "algorithme de répartition",
        expand: false,
    }
    return [
        repartition_algorithm,
        repartition_algorithm_default,
        repartition_algorithm_priority,
        repartition_algorithm_priority_complex,
        repartition_algorithm_share,
        repartition_algorithm_share_complex,
        repartition_algorithm_ungrouped_priority,
        repartition_surplus_algorithm_default,
        repartition_surplus_algorithm_priority,
        eco1, eco2, eco3, eco4, eco5, eco6, eco7, eco8, eco9,
        fin1,
        methodo1, methodo2, methodo3, methodo4, methodo5,
        ope1, ope2, ope3, ope4, ope5, ope6, ope7, ope8, ope9, ope10
    ]
}
