import SellingPriceTable from "../form/financial-parameters/selling-price/selling-price-table/SellingPriceTable";
import React, {useState} from "react";
import {SellingPriceFunction} from "../../../../../corelogic/models/types/new-simulation/form/SellingPriceFunction";
import SellingPriceHeader from "../form/financial-parameters/selling-price/selling-price-table/SellingPriceHeader";

export const SellingPriceMap = {
    [SellingPriceFunction.FIXED_PRICE]: 'Prix fixe',
    [SellingPriceFunction.FIXED_PRICE_WITH_INFLATION]: 'Prix indexé (nouveau)',
    [SellingPriceFunction.DISCOUNT_COMPLEMENT_PRICE]: 'Réduction sur le tarif de complément',
    [SellingPriceFunction.GIFTED] : "Partage gratuit d’électricité (nouveau)"
}

export default function SellingPriceTableWithFunction() {

    const [functionValue, setFunctionValue] = useState<string>()
    const [sellingPriceTableRefresh, setSellingPriceTableRefresh] = useState(false)
    const [functionToApply, setFunctionToApply] = useState<SellingPriceFunction>(SellingPriceFunction.FIXED_PRICE)
    return <div
        className={"m-4 w-full  md:max-w-screen-sm sm:max-w-screen-xs lg:max-w-screen-md xl:max-w-screen-lg 2xl:max-w-screen-xl"}>
        <hr className="mx-2 my-4 border-zinc-300 dark:border-zinc-600"/>
        <div
            className="bg-white   rounded-md shadow box-border border-white dark:bg-zinc-800 border-4 dark:border-zinc-800">
            <SellingPriceHeader functionToApply={functionToApply}
                                setFunctionToApply={setFunctionToApply} functionValue={functionValue}
                                setFunctionValue={setFunctionValue}
                                setSellingPriceTableRefresh={setSellingPriceTableRefresh}/>
            <hr className="w-full border-slate-300 dark:border-zinc-600"/>
            <SellingPriceTable sellingPriceTableRefresh={sellingPriceTableRefresh}
                               setSellingPriceTableRefresh={setSellingPriceTableRefresh}
                               typeOfAffectation={functionToApply} valueToAffect={functionValue}
            />
        </div>
    </div>
}


