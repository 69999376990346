import React, {useState} from "react";
import {InformationCircleIconOrangeOnHover} from "../../../../components/assets/icon/InformationCircleIcon";
import TVATooltip from "../../../tooltip/TVATooltip";
import {useDispatch, useSelector} from "react-redux";
import {selectConsumptionPointIsVATRecoverable} from "../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import {typologyFormActions} from "../../../../../../../corelogic/usecases/typology-form/typologyFormActions";

function ChooseTVA({isVATRecoverable, setIsVATRecoverable}:{isVATRecoverable:boolean, setIsVATRecoverable(tva:boolean):void}) {
    const [showTooltip, setShowTooltip] = useState(false)

    return <div className="w-full flex flex-row items-center py-3">
        <label
            className="mr-2 block tracking-wide text-gray-600 font-semibold dark:text-gray-300 "
            htmlFor="grid-last-name"> Récupération de la TVA</label>
        <div className={"relative hover:cursor-pointer mr-2"}
             onMouseLeave={() => setShowTooltip(false)}
             onMouseOver={() => setShowTooltip(true)}>
            {InformationCircleIconOrangeOnHover()}
            {showTooltip && <TVATooltip/>}
        </div>
        <div data-cy={"typology-injection-tva"} onClick={() => setIsVATRecoverable(!isVATRecoverable)}
             className={isVATRecoverable ? "form-check-input appearance-none w-9 rounded-full float-left h-5 align-top bg-no-repeat bg-contain bg-orange-200 focus:outline-none cursor-pointer shadow-sm" : "form-check-input appearance-none w-9 rounded-full float-left h-5 align-top bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm"}
             role="switch" id="flexSwitchCheckDefault">
            <div
                className={isVATRecoverable ? "w-5 h-5 ml-4 bg-secondary rounded-full " : "w-5 h-5 bg-white rounded-full "}/>
        </div>
    </div>
}
function ChooseConsumptionPointTVA() {
    const dispatch = useDispatch()
    const isVATRecoverable = useSelector(selectConsumptionPointIsVATRecoverable)

    const [showTooltip, setShowTooltip] = useState(false)

    return <div className="w-full flex flex-row items-center py-3">
        <label
            className="mr-2 block tracking-wide text-gray-600 font-semibold dark:text-gray-300 "
            htmlFor="grid-last-name"> Récupération de la TVA</label>
        <div className={"relative hover:cursor-pointer mr-2"}
             onMouseLeave={() => setShowTooltip(false)}
             onMouseOver={() => setShowTooltip(true)}>
            {InformationCircleIconOrangeOnHover()}
            {showTooltip && <TVATooltip/>}
        </div>
        <div data-cy={"typology-injection-tva"} onClick={() => dispatch(typologyFormActions.setIsVATRecoverable(!isVATRecoverable))}
             className={isVATRecoverable ? "form-check-input appearance-none w-9 rounded-full float-left h-5 align-top bg-no-repeat bg-contain bg-orange-200 focus:outline-none cursor-pointer shadow-sm" : "form-check-input appearance-none w-9 rounded-full float-left h-5 align-top bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm"}
             role="switch" id="flexSwitchCheckDefault">
            <div
                className={isVATRecoverable ? "w-5 h-5 ml-4 bg-secondary rounded-full " : "w-5 h-5 bg-white rounded-full "}/>
        </div>
    </div>
}
export {ChooseTVA, ChooseConsumptionPointTVA}
