import {useSelector} from "react-redux";
import {
    selectCostsData,
    selectSurplusLoading
} from "../../../../../corelogic/usecases/financialRestitution/financialRestitutionSelector";
import {formatNumber} from "../../../../../corelogic/usecases/config";

import {CostAndGain} from "../../../../../corelogic/models/types/financialRestitution/CostAndGain";
import { selectFinancialDuration } from "../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectFinancialParameter";
import {
    selectTypeOfOperation
} from "../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectGeneralInfoForm";
import {
    selectDisplayAcciseInGainsTable, selectInjectionPoints
} from "../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectInjectionPointForm";

const ExcessEconomieTable = () => {
    const costs = useSelector(selectCostsData);
    const loading = useSelector(selectSurplusLoading);
    const duration = useSelector(selectFinancialDuration);
    const typeOfOperation = useSelector(selectTypeOfOperation)
    const displayAcciseInGainsTable = useSelector(selectDisplayAcciseInGainsTable)
    const injectionPoints = useSelector(selectInjectionPoints)

    function displayTVA(pointName:string){
        const matchingPoint = injectionPoints.filter((point) => point.name === pointName)[0]
        return matchingPoint ? matchingPoint.isVATRecoverable ?  " € HT" :" € TTC" : " €"
    }
    return <table id={'cost-and-gain'} className="bg-white  w-full  relative overflow-x-auto px-2 py-4 table-auto shadow-md rounded-lg dark:bg-zinc-700">
        <thead>
        <tr className="text-gray-500 text-xs leading-normal dark:text-zinc-400 bg-white dark:bg-zinc-700">
            <th align={"left"} className="py-3 px-4 ">Point d'injection</th>
            {displayAcciseInGainsTable && <th align={"right"} className="py-3 px-4 ">Accise sur l'electricité 1 an (€ HT)</th>}
            <th align={"right"} className="py-3 px-4 ">Vente de surplus 1 an (€ HT)</th>
            <th align={"right"} className="py-3 px-4 ">Turpe producteur 1 an </th>
            <th align={"right"} className="py-3 px-4 ">IFER 1 an</th>
            {typeOfOperation === 'open' && <th align={"right"} className="py-3 px-4 ">Revenu vente interne 1 an (€ HT)</th>}
            <th align={"right"} className="py-3 px-4 ">Revenu total 1 an</th>
            <th align={"right"} className="py-3 px-4 ">Revenu total {duration} ans</th>
        </tr>
        </thead>
        <tbody className="text-gray-500 text-xs font-ligh bg-white dark:bg-zinc-700">
        {!loading ? costs.map((cost: CostAndGain, index: number) =>
                <tr key={cost.name || 'total'}
                    className={`border-b border-gray-200 hover:bg-gray-100 dark:hover:bg-zinc-600 ${index === costs.length - 1 ? 'dark:hover:bg-green-800  dark:bg-green-900 bg-green-300 hover:bg-green-400' : ''}`}>
                    <td align={"left"} className="py-2 px-4  whitespace-nowrap dark:text-zinc-200">
                        <span className="font-semibold">{cost.name}</span>
                    </td>
                    {displayAcciseInGainsTable && <td align={"right"} className="py-2 px-4 dark:text-zinc-200 ">
                <span
                    className={`font-semibold text-sm ${index === costs.length - 1 ? 'text-green-700 dark:text-green-200' : 'text-slate-400 dark:text-zinc-400'}`}>{formatNumber(cost.acciseFirstYear || 0)} €</span>
                    </td>}

                    <td align={"right"} className="py-2 px-4 ">
                <span
                    className={`font-semibold text-sm ${index === costs.length - 1 ? 'text-green-700 dark:text-green-200' : 'text-slate-400 dark:text-zinc-400'}`}>{formatNumber(cost.surplusFirstYear)} €</span>
                    </td>
                    <td align={"right"} className="py-2 px-4 ">
                <span
                    className={`font-semibold text-sm ${index === costs.length - 1 ? 'text-green-700 dark:text-green-200' : 'text-slate-400 dark:text-zinc-400'}`}>{cost.majoTurpeFirstYear != null ? formatNumber(cost.majoTurpeFirstYear) + displayTVA(cost.name): '-'}</span>
                    </td>
                    <td align={"right"} className="py-2 px-4 ">
                <span
                    className={`font-semibold text-sm ${index === costs.length - 1 ? 'text-green-700 dark:text-green-200' : 'text-slate-400 dark:text-zinc-400'}`}>{cost.ifer != null ? formatNumber(cost.ifer) + ' €' : '-'}</span>
                    </td>
                    {typeOfOperation === "open" &&   <td align={"right"} className="py-2 px-4 ">
                <span
                    className={`font-semibold text-sm ${index === costs.length - 1 ? 'text-green-700 dark:text-green-200' : 'text-slate-400 dark:text-zinc-400'}`}>{cost.productorGain ? formatNumber(cost.productorGain) : '-'} €</span>
                    </td>}
                    <td align={"right"} className="py-2 px-4 ">
                <span
                    className={`text-slate-600 font-semibold text-sm ${index === costs.length - 1 ? 'text-green-700 dark:text-green-200' : 'text-slate-400 dark:text-zinc-400'}`}>{formatNumber(cost.firstYearSaving)} €</span>
                    </td>

                    <td align={"right"} className="py-2 px-4 ">
                <span
                    className={`text-slate-600 font-semibold text-sm ${index === costs.length - 1 ? 'text-green-700 dark:text-green-200' : 'text-slate-400 dark:text-zinc-400'}`}>{formatNumber(cost.saving)} €</span>
                    </td>
                </tr>)
            : [...Array(3)].map(() => {
                return <tr className="border-b border-gray-200 animate-pulse">
                    {[...Array(7)].map((_, idx) => {
                        return <td key={idx} className="py-2 px-4 text-left whitespace-nowrap">
                            <div className="flex items-left">
                                <span className="font-bold bg-gray-100 dark:bg-zinc-600 h-4 w-full"/>
                            </div>
                        </td>
                    })}
                </tr>
            })
        }
        </tbody>
    </table>
}

export default ExcessEconomieTable
