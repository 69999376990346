import TitleText from "../../components/assets/text/TitleText";
import React from "react"
import ClipboardExportButton from "../../utils/ClipboardExportButton";
import PNGExportButton from "../../utils/PNGExportButton";
import XLSExportButton from "../../utils/XLSExportButton";

type Tab = {
    menu: string[],
    selected: number
}

type withTitleCardType = {
    children: JSX.Element,
    title: string | Tab,
    setTab?(tab : Tab):void,
    exportTableId?: string,
    exportAsPNGRef?: React.MutableRefObject<null>,
    exportAsXLSData?: any[]
}
export const WrapperCard = ({children, title, setTab, exportTableId, exportAsPNGRef, exportAsXLSData}: withTitleCardType) => {
    const hasTabs = typeof title !== 'string'
    const tabs: Tab =hasTabs ? title : {menu: [title], selected: 0}
    const selectedTitle = tabs.menu[tabs.selected]

    return <div className="scale-95 2xl:scale-100 w-full">
        <div className="bg-white rounded-md shadow box-border dark:bg-zinc-700">
            <div className={"w-full flex justify-between items-center pr-4"}>
                {
                    hasTabs ?
                        <TabsTitle setTab={setTab} tabs={tabs}/>
                        :<TitleText title={tabs.menu[0]} textSize={"text-sm"} typeOfMargin={"small"}/>

                }
                <div className={"flex gap-2"}>
                    {exportTableId && selectedTitle && <ClipboardExportButton title={selectedTitle} tableId={exportTableId}/>}
                    {exportAsPNGRef && selectedTitle &&  <PNGExportButton title={selectedTitle} exportAsPNGRef={exportAsPNGRef}/>}
                    {exportAsXLSData && selectedTitle && <XLSExportButton title={selectedTitle} data={exportAsXLSData}/>}
                </div>
            </div>
            <hr className={"w-full border dark:border-zinc-800"}/>
            {children}
        </div>
    </div>
}


const TabsTitle = ({tabs, setTab}:{tabs:Tab, setTab?(tabs:Tab):void}) => {
    function handleClickOnTab(index: number) {
        setTab ? setTab({...tabs, selected : index}) : alert('undefined')
    }

    return <div className={"flex  mt-6 mb-2 mx-4 "}>
        {
            tabs.menu.map((title: string, index:number) => {
                const selectedStyle = index === tabs.selected ? ' border-b-2 border-blue-600 ' : ' border-b border-gray-200 opacity-60 hover:cursor-pointer hover:opacity-100 '
                return <div
                    onClick={() => handleClickOnTab(index)}
                    className={selectedStyle + "   font-semibold whitespace-nowrap text-lg text-gray-700 dark:text-gray-300 "}>
                    <p className={"px-2 pb-2"}>
                        {title}
                    </p>
                </div>
            })
        }
    </div>
}





