import {useEffect} from "react";
import {useSnackbar} from "notistack";
import {useDispatch, useSelector} from "react-redux";
import {
    selectSnackbarErrors, selectSnackbarEnolabErrorCode
} from "../../../../corelogic/usecases/errors/errorsSelector";
import {snackbarErrorMessages} from "../../utils/snackbarErrorMessages";
import {snackBarErrorsActions} from "../../../../corelogic/usecases/errors/snackbarErrorsActions";

export default function ErrorSnackbar()  {
    const dispatch = useDispatch()

    const {enqueueSnackbar} = useSnackbar()

    const error = useSelector(selectSnackbarErrors)
    const enolabErrorCode = useSelector(selectSnackbarEnolabErrorCode)


    useEffect(() => {
        if (error) {
            const enolabErrorCode = error?.response?.data?.enolab_error_code
            const errorCode = enolabErrorCode ? enolabErrorCode : error.code
            const snackbarErrorMessage = snackbarErrorMessages(errorCode)
            enqueueSnackbar(snackbarErrorMessage.message, {variant : snackbarErrorMessage.variant})
            dispatch(snackBarErrorsActions.resetError())
        }
    },[dispatch, enqueueSnackbar, error, enolabErrorCode])

    return null
}
