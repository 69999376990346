export enum ContractType{base, double_price, quadruple_price}

export function contractTypeFromObject(contractType: string): ContractType  {
    switch (contractType) {
        case 'base':
            return ContractType['base'];
        case 'double_price':
            return ContractType['double_price'];
        case 'quadruple_price':
            return ContractType['quadruple_price'];
        default:
            throw new Error('contract type null')
    }
}
