import DownloadCDCTemplate from "../../../components/assets/FormAssets/DownloadCDCTemplate";
import EnedisCDC from "./enedis-cdc-button/EnedisCDC";
import SGECDC from "./sge-cdc/SGECDC";
import {useSelector} from "react-redux";
import {
    selectDisplayAllCDCButtons,
    selectDisplayCDCSGEBtn,
    selectDisplayCDCUploadBtn,
    selectIsEnableEnedisBtn
} from "../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import UploadUnsavedCDCButton from "./upload-unsaved-cdc/UploadUnsavedCDCButton";
import {selectIsRoleEnogrid} from "../../../../../../corelogic/usecases/authentication/authenticationSelector";

export default function () {
    const displayCDCBtns = useSelector(selectDisplayAllCDCButtons)
    const displaySGEBtn = useSelector(selectDisplayCDCSGEBtn)
    return <div className={"flex flex-col gap-2 w-full"}>
            <div className={"text-gray-700 font-semibold dark:text-gray-300 "}>Ajouter une courbe de charge*</div>
        {
            displayCDCBtns ?
                <CDCByUploadOrEnedisOrSGE/>
                :
                displaySGEBtn ?
                    <SGECDC/> :
                    <EnedisCDC/>
        }
        <DownloadCDCTemplate/>
    </div>
}
const CDCByUploadOrEnedisOrSGE = () => {

    const upload = useSelector(selectDisplayCDCUploadBtn)
    const isRoleEnogrid = useSelector(selectIsRoleEnogrid)
    const isEnabledEnedisModelisation = useSelector(selectIsEnableEnedisBtn)

    return <div className={"w-full flex items-center gap-2"}>
        <UploadUnsavedCDCButton/>
        {
            !upload &&
            <div className={"flex flex-col gap-2"}>
                {isEnabledEnedisModelisation && <span
                    className={"text-gray-600 dark:text-gray-400 text-sm"}>ou utiliser un profil type d'une banque de données: </span>}
                {isEnabledEnedisModelisation &&<EnedisCDC/>}
                {isRoleEnogrid && <SGECDC/>}
            </div>

        }
    </div>
}
