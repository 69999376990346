import React from "react";

export default function () {
    return <div
        className=" absolute z-50 right-0 bottom-[60px] max-w-full h-0 w-0  mt-2  shadow-lg  hover:cursor-default "
        role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex={-1}>
        <div className="py-1 z-[1] w-[650px] bg-white text-gray-700 dark:bg-zinc-800 dark:text-zinc-200 rounded-md" role="none">
            <span
                className={" block w-full text-left px-4 pt-2 text-md font-semibold"}>Modélisation par PVGIS</span>
            <hr className="my-3 w-full"/>
            <div className={"flex flex-row justify-center "}>
                <div className={"flex flex-col"}>
                <span className={"whitespace-pre-line  block w-full text-left px-4 pt-2 text-sm"}>
                   PVGIS est un logiciel développé et actualisé par la Commission Européenne. Il recense les degrés d'ensoleillement et de radiation partout dans le monde chaque année. Cette collecte d'informations lui permet de fournir des données précises et claires sur la production potentielle d’une installation photovoltaïque.
                </span>
                    <span className={"whitespace-pre-line block w-full text-left px-4 pt-2 text-sm"}>
La simulation réalisée ici utilise les paramètres renseignés par l’utilisateur à savoir la localisation (code postal), la puissance crête, l’orientation, l’inclinaison et les pertes du système. Certains paramètres sont fixes comme la base de données de rayonnement (PVGIS-SARAH2), l’année d’ensoleillement (2020) et la technologie des modules (silicium cristallin).
</span>
                    <span className={"whitespace-pre-line block w-full text-left px-4 pt-2 text-sm "}>
                        Vous pouvez réaliser vous même votre simulation depuis le site PVGIS pour plus de liberté sur les paramètres si vous le souhaitez en vous rendant sur <a
                        className={"font-semibold "} target="_blank"
                        href={"https://re.jrc.ec.europa.eu/pvg_tools/fr/tools.html"}>https://re.jrc.ec.europa.eu/pvg_tools/fr/tools.html</a>. Il vous faudra ensuite importer la courbe de charge de production obtenue au format *.xls.
                </span>
                </div>
            </div>
        </div>
    </div>
}