import {useDispatch, useSelector} from "react-redux";
import {typologyFormActions} from "../../../../../../../corelogic/usecases/typology-form/typologyFormActions";
import {selectDisplayCDCSGEBtn} from "../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import React, {useEffect} from "react";
import Divider from "../../../../components/assets/Divider";
import Header from "./header/Header";
import SGEForm from "./form/SGEForm";

export default function SGECDC() {
    const dispatch = useDispatch()
    const sge = useSelector(selectDisplayCDCSGEBtn)

    const opacity = sge ? " opacity-100 " : " opacity-80 "
    const enableStyle = !sge ? ' hover:cursor-pointer hover:opacity-100' : ''

    useEffect(()=>{
      // when SGE component is render, init PRM to null and startDate/endDate
        dispatch(typologyFormActions.initSGEForm())
    },[])

    function clickOnEnedisCDCBtn() {
        if (!sge) {
            dispatch(typologyFormActions.setCDCUploadOption('SGE'))
        }
    }

    return <div
        onClick={clickOnEnedisCDCBtn}
        className={"w-full flex-col  items-start gap-1 inline-flex justify-end px-6 py-4  bg-gray-100 dark:bg-purple-900 dark:bg-opacity-5 rounded border border-purple-600 justify-between  gap-4 " +
        enableStyle + opacity}>
        <Header/>
        {sge && <Divider/>}
        {sge && <SGEForm/>}
    </div>
}




