import PlusCircleIcon from "../../../../../../components/assets/icon/PlusCircleIcon";
import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    makeIsLowCompletudeRowValid,
    selectLowCompletenessTable
} from "../../../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import {useSnackbar} from "notistack";
import {typologyFormActions} from "../../../../../../../../../corelogic/usecases/typology-form/typologyFormActions";

const AddTableRow = () => {
    const dispatch = useDispatch()
    const {enqueueSnackbar} = useSnackbar()

    const lowCompletudeTable = useSelector(selectLowCompletenessTable)
    const selectAddRow = useCallback(makeIsLowCompletudeRowValid(lowCompletudeTable.length - 1), [lowCompletudeTable])
    const enabled = useSelector(selectAddRow)


    const style= " self-end gap-2 whitespace-nowrap flex w-min rounded px-3 py-2 text-xs text-blue-600 dark:text-blue-100 bg-blue-200 dark:bg-opacity-20 "
    const enabledStyle = enabled ? ' hover:opacity-100 opacity-90 hover:cursor-pointer font-semibold ' : ' opacity-60 '
    function handleClick() {
        if (enabled) {
            dispatch(typologyFormActions.addLowCompletudeRow())
        } else {
            enqueueSnackbar("La dernière ligne du tableau n'est pas valide.", {variant: 'warning'})
        }
    }

    return <div
        onClick={handleClick}
        className={style + enabledStyle}>
        Ajouter une ligne
        <PlusCircleIcon className={'w-4 h-4 text-blue-600  dark:text-blue-100'}/>
    </div>
}

export default AddTableRow
