import withConditionalRender from "../../../wrapper/withConditionalRender";

import TitleText from "../../../../components/assets/text/TitleText";
import RegularBtn from "../../../../components/assets/button/RegularBtn";
import {ButtonState, Theme} from "../../../../../../../config/app-config";
import EmptyTable from "../../../table/EmptyTable";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {newSimulationActions} from "../../../../../../../corelogic/usecases/new-simulation/newSimulationActions";
import {availableExternalInvestment} from "../ExternalInvestment.utils";
import {useSnackbar} from "notistack";
import {ExternalInvestment, globalExternalInvestment} from "../ExternalInvestment";
import ApplyExternalInvestButton from "../ApplyExternalInvestButton";
import {
    selectExternalInvestmentHasNewValidChanges,
    selectExternalInvestments,
    selectShowExternalInvestment
} from "../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectExternalInvestmentForm";
import {
    selectInjectionPoints
} from "../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectInjectionPointForm";
import ExternalInvestmentFormTable from "./ExternalInvestmentFormTable";
import FormPopup from "../../../confirmation-dialog/FormPopup";
import {
    selectFormHasBeenUpdatedNotification
} from "../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-state/selectFormState";

const ExternalInvestmentForm = () => {
    const dispatch  = useDispatch()
    const {enqueueSnackbar} = useSnackbar()
    const externalInvestment = useSelector(selectExternalInvestments)
    const injectionPoints = useSelector(selectInjectionPoints)
    const hasNewValidChanges = useSelector(selectExternalInvestmentHasNewValidChanges)
    const formHasBeenUpdatedNotification = useSelector(selectFormHasBeenUpdatedNotification)

    useEffect(() => {
        if (formHasBeenUpdatedNotification) {
            enqueueSnackbar('Le formulaire a été sauvegardé.', {variant: "info"})
            dispatch(newSimulationActions.hasBeenUpdatedNotification(false))
            dispatch(newSimulationActions.showExternalInvestment(false))
        }
    }, [formHasBeenUpdatedNotification])

    function handleClickOnAddGlobalExternalInvestButton(){
        const noGlobalInvestment = externalInvestment[0]?.id !== globalExternalInvestment.id
        if(noGlobalInvestment){
            dispatch(newSimulationActions.setExternalInvestments([new ExternalInvestment(globalExternalInvestment.id, '', '')]))
        }
    }
    function handleClickOnAddExternalInvestButton() {
        const availablePointsToAdd : {name : string, investment : ExternalInvestment} | null = availableExternalInvestment(externalInvestment, injectionPoints)
        const isGlobal = externalInvestment.length !== 0 && externalInvestment[0].id === globalExternalInvestment.id
        if(availablePointsToAdd) {
            if(isGlobal)dispatch(newSimulationActions.setExternalInvestments([availablePointsToAdd.investment]))
            else dispatch(newSimulationActions.addExternalInvestment(availablePointsToAdd.investment))
        } else {
            enqueueSnackbar("Vous pouvez ajouter un tiers investissement par producteur ou un tiers investissement global.", {variant:'warning'})
        }
    }

    return <div className={" p-4 w-full"}>
        <div className=" bg-white border-2 rounded-md border-slate-300 box-border dark:bg-zinc-600">
            {hasNewValidChanges && <div className={"h-[32px] mb-2"}>
                <FormPopup
                    actionText={"Appliquer les modifications"}
                    action={() =>dispatch(newSimulationActions.applyExternalInvestment())}
                    isLoading={false} />
            </div>}

            <div className={"flex justify-between items-center"}>
                <TitleText title={"Tiers Investissement"} textSize={"text-sm"}
                           typeOfMargin={"small"}/>
                <div className={"w-min mr-4 flex gap-2 py-3 "}>
                    <RegularBtn
                        title={"Ajouter un tiers investissement global"}
                        theme={Theme.SECONDARY} state={ButtonState.REGULAR} action={handleClickOnAddGlobalExternalInvestButton}/>
                    <RegularBtn
                        title={"Ajouter un tiers investissement par producteur"}
                        theme={Theme.SECONDARY} state={ButtonState.REGULAR} action={handleClickOnAddExternalInvestButton}/>
                        {/*<ApplyExternalInvestButton/>*/}
                </div>
            </div>
            <hr className={"w-full border dark:border-zinc-800"}/>
            <div className={"overflow-x-auto "}>
                {externalInvestment.length > 0 ?
                    <ExternalInvestmentFormTable/>
                    :
                    <EmptyTable title={"Pas de Tiers Investissement"}
                                description={"Ajouter un tiers investissement avec le bouton 'Ajouter un tiers investissement' au dessus du tableau"}/>}
            </div>
        </div>

    </div>

}

export default withConditionalRender(ExternalInvestmentForm, selectShowExternalInvestment)

