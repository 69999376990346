import React from "react";
import {useSelector} from "react-redux";
import {
    selectDateStartIsOlderThanDateEnd, selectSGEDateEndHasError, selectSGEDateEndIsOlderThanToday,
    selectSGEDateStartHasError
} from "../../../../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";

export default function ErrorInput({mouseHasLeft}: { mouseHasLeft: boolean }) {
    const hasError = useSelector(selectSGEDateEndHasError)
    const isNewerThanStartDate = useSelector(selectDateStartIsOlderThanDateEnd)
    const isOlderThanToday = useSelector(selectSGEDateEndIsOlderThanToday)

    const msg = isNewerThanStartDate ? 'Date de fin avant date de début.'
        :
        (isOlderThanToday ? 'Date de fin supérieure à la date du jour.':
         "La date n'est pas valide.")
    return <>
        {
            hasError && mouseHasLeft &&
            <span className={"text-xs text-red-500  left-1 bottom-[-2]"}>
               {msg}
            </span>
        }
    </>

}
