import {combineReducers} from "redux";
import MetricsData from "../../models/types/physicalRestitution/MetricsData";
import {
    Actions,
    INIT_FINANCIAL_DATA,
    SET_BALANCE_METRICS,
    SET_BALANCE_METRICS_LOADING,
    SET_CASH_FLOW_DATA,
    SET_CASH_FLOW_LOADING,
    SET_ECONOMICAL_DATA,
    SET_ECONOMICAL_LOADING,
    SET_ECONOMICAL_METRICS,
    SET_ECONOMICAL_METRICS_LOADING,
    SET_EXTERNAL_INVESTMENT_BALANCE_METRICS,
    SET_EXTERNAL_INVESTMENT_CASH_FLOW_DATA,
    SET_EXTERNAL_INVESTMENT_PRODUCER_BALANCE_METRICS,
    SET_EXTERNAL_INVESTMENT_PRODUCER_CASH_FLOW_DATA,
    SET_SURPLUS_DATA,
    SET_SURPLUS_LOADING
} from "./financialRestitutionActions";

const metricsInvestorProducerDataReducer = (state: MetricsData = {}, action: Actions) => {
    switch (action.type) {
        case SET_EXTERNAL_INVESTMENT_PRODUCER_BALANCE_METRICS:
            return {...action.payload};
        case INIT_FINANCIAL_DATA :
            return {}
        default:
            return state;
    }
}

const metricsInvestorDataReducer = (state: MetricsData = {}, action: Actions) => {
    switch (action.type) {
        case SET_EXTERNAL_INVESTMENT_BALANCE_METRICS:
            return {...action.payload};
        case INIT_FINANCIAL_DATA :
            return {}
        default:
            return state;
    }
}
const metricsDataReducer = (state: MetricsData = {}, action: Actions) => {
    switch (action.type) {
        case SET_BALANCE_METRICS:
            return {...action.payload};
        case INIT_FINANCIAL_DATA :
            return {}
        default:
            return state;
    }
}

const metricsLoadingReducer = (state: boolean = true, action: Actions) => {
    switch (action.type) {
        case SET_BALANCE_METRICS_LOADING:
            return action.payload;
        default:
            return state;
    }
}
const metricsDataCombinedReducer = combineReducers(
    {
        global : metricsDataReducer,
        investor : metricsInvestorDataReducer,
        investorProducer : metricsInvestorProducerDataReducer,
    }
)

const metricsReducer = combineReducers({
    data: metricsDataCombinedReducer,
    loading: metricsLoadingReducer,
});


const cashFlowDataReducer = (state = [], action: Actions) => {
    switch (action.type) {
        case SET_CASH_FLOW_DATA:
            return [...action.payload];
        case INIT_FINANCIAL_DATA :
            return []
        default:
            return state;
    }
}
const cashFlowExternalInvestmentProducerReducer = (state = [], action: Actions) => {
    switch (action.type) {
        case SET_EXTERNAL_INVESTMENT_PRODUCER_CASH_FLOW_DATA:
            return [...action.payload];
        case INIT_FINANCIAL_DATA :
            return []
        default:
            return state;
    }
}
const cashFlowExternalInvestmentReducer = (state = [], action: Actions) => {
    switch (action.type) {
        case SET_EXTERNAL_INVESTMENT_CASH_FLOW_DATA:
            return [...action.payload];
        case INIT_FINANCIAL_DATA :
            return []
        default:
            return state;
    }
}

const cashFlowLoadingReducer = (state: boolean = true, action: Actions) => {
    switch (action.type) {
        case SET_CASH_FLOW_LOADING:
            return action.payload;
        default:
            return state;
    }
}

const  cashFlowReducer = combineReducers({
    data: cashFlowDataReducer,
    dataExternalInvestment : cashFlowExternalInvestmentReducer,
    dataExternalInvestmentProducer : cashFlowExternalInvestmentProducerReducer,
    loading: cashFlowLoadingReducer,
});



const balanceReducer =  combineReducers({
    metrics: metricsReducer,
    cashFlow: cashFlowReducer
});

const economicalMetricsDataReducer = (state: MetricsData = {}, action: Actions) => {
    switch (action.type) {
        case SET_ECONOMICAL_METRICS:
            return {...action.payload};
        case INIT_FINANCIAL_DATA :
            return {}
        default:
            return state;
    }
}

const economicalMetricsLoadingReducer = (state: boolean = true, action: Actions) => {
    switch (action.type) {
        case SET_ECONOMICAL_METRICS_LOADING:
            return action.payload;
        default:
            return state;
    }
}

const economicalMetricsReducer = combineReducers({
    data: economicalMetricsDataReducer,
    loading: economicalMetricsLoadingReducer,
});

const economiesDataReducer = (state = [], action: Actions) => {
    switch (action.type) {
        case SET_ECONOMICAL_DATA:
            return action.payload;
        case INIT_FINANCIAL_DATA :
            return []
        default:
            return state;
    }
}

const economiesLoadingReducer = (state: boolean = true, action: Actions) => {
    switch (action.type) {
        case SET_ECONOMICAL_LOADING:
            return action.payload;
        default:
            return state;
    }
}

const  economiesReducer = combineReducers({
    data: economiesDataReducer,
    loading: economiesLoadingReducer,
});



const costsAndGainsDataReducer = (state = [], action: Actions) => {
    switch (action.type) {
        case SET_SURPLUS_DATA:
            return action.payload;
        case INIT_FINANCIAL_DATA :
            return []
        default:
            return state;
    }
}


const costsAndGainsLoadingReducer = (state: boolean = true, action: Actions) => {
    switch (action.type) {
        case SET_SURPLUS_LOADING:
            return action.payload;
        default:
            return state;
    }
}

const  costsAndGainsReducer = combineReducers({
    data: costsAndGainsDataReducer,
    loading: costsAndGainsLoadingReducer,
});




const economyReducer =  combineReducers({
    metrics: economicalMetricsReducer,
    economies: economiesReducer,
});


export default combineReducers({
    balance: balanceReducer,
    economy: economyReducer,
    costsAndGains: costsAndGainsReducer,
});
