import React from "react";
import {useSelector} from "react-redux";
import {
    selectSGENumberOfSuccess,
    selectSGESuccessPercentRate
} from "../../../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";

const HeadSuccess = () => {
    const successRate = useSelector(selectSGESuccessPercentRate)
    const weeksFetchedSuccessfully = useSelector(selectSGENumberOfSuccess)

    return <div className={"flex gap-4 bg-gray-200 dark:bg-gray-700 p-1 px-2 rounded-t"}>
        <div className={"flex gap-2 items-center"}>
            <span className={"text-gray-400 dark:text-gray-500 text-xs font-semibold"}>Taux de succès </span>
            <span className={"text-gray-700 dark:text-gray-200 text-sm font-bold"}>{successRate}%</span>
        </div>
        <div className={"flex gap-2 items-center"}>
            <span className={"text-gray-400 dark:text-gray-500 text-xs font-semibold"}>Semaines récupérées</span>
            <span className={"text-green-500 dark:text-green-400 text-sm font-bold"}>{weeksFetchedSuccessfully}</span>
        </div>
    </div>
}
export default HeadSuccess