import React from "react";

export default function({disabled, className} : { disabled : boolean, className ?:string }) {
    return (
        <svg className={className ? className :disabled ? "h-20 w-20 text-white" : "h-20 w-20 text-slate-600" }
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 512 512"
            version="1.1"
            viewBox="0 0 512 512"
            xmlSpace="preserve"
        >
            <g fill="#CEE8FA">
                <path d="M493.107 203.378L18.863 203.378 77.629 85.92 434.342 85.92z"/>
                <path d="M212.912 355.871H299.052V497.034H212.912z"/>
            </g>
            <path
                fill="#2D527C"
                d="M506.074 195.86L448.602 80.989V14.96c0-8.262-6.698-14.96-14.96-14.96h-70.405c-8.262 0-14.96 6.698-14.96 14.96v56H77.628c-5.665 0-10.845 3.2-13.379 8.267L5.484 196.683a14.96 14.96 0 0013.379 21.655h33.6V497.04c0 8.262 6.698 14.96 14.96 14.96H444.545c8.262 0 14.96-6.698 14.96-14.96V275.32c0-8.262-6.698-14.96-14.96-14.96h-92.078c-8.262 0-14.96 6.698-14.96 14.96v104.041c0 8.262 6.698 14.96 14.96 14.96h77.118v87.757H314.012V355.871c0-8.262-6.698-14.96-14.96-14.96h-86.136c-8.262 0-14.96 6.698-14.96 14.96V482.08H82.385v-87.757h77.118c8.262 0 14.96-6.698 14.96-14.96V275.321c0-8.262-6.698-14.96-14.96-14.96H82.385v-42.023H493.137c8.262 0 14.96-6.698 14.96-14.96 0-2.741-.736-5.309-2.023-7.518zM367.427 364.402v-74.121h62.158v74.121h-62.158zm-139.549 6.429h56.216V482.08h-56.216V370.831zm-83.335-80.55v74.121H82.385v-74.121h62.158zM378.197 29.92h40.485v41.04h-40.485V29.92zM43.076 188.418l43.796-87.537h338.227l43.796 87.537H43.076z"
            />
        </svg>
    );
}

