import {ButtonState} from "../../../../../config/app-config";
import InformationsIcon from "../../components/assets/icon/InformationsIcon";
import InjectionIcon from "../../components/assets/icon/InjectionIcon";
import RegularBtn from "../../components/assets/button/RegularBtn";
import ChipIcon from "../../components/assets/icon/ChipIcon";
import OfficeIcon from "../../components/assets/icon/OfficeIcon";
import CurrencyEuroIcon from "../../components/assets/icon/CurrencyEuroIcon";
import {PlayIconOutlined} from "../../components/assets/icon/PlayIcon";
import TwoFieldsBtn from "../../components/assets/button/TwoFieldsBtn";
import {useDispatch, useSelector} from "react-redux";
import {
    selectSimulationForm,
} from "../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form/newSimulationSelector";
import {newSimulationActions} from "../../../../../corelogic/usecases/new-simulation/newSimulationActions";
import React, {useEffect} from "react";
import {InjectionPoint} from "../../../../../corelogic/models/types/new-simulation/form/InjectionPoint";
import {ConsumptionPoint} from "../../../../../corelogic/models/types/new-simulation/form/ConsumptionPoint";
import {Step} from "../../../../../corelogic/models/types/new-simulation/steps/Step";
import {useSnackbar} from "notistack";
import {OperationType} from "../../../../../corelogic/models/types/new-simulation/form/OperationType";
import DoubleChevronLeft from "../../components/assets/icon/DoubleChevronLeft";
import DoubleChevronRight from "../../components/assets/icon/DoubleChevronRight";
import {typologyFormActions} from "../../../../../corelogic/usecases/typology-form/typologyFormActions";
import {selectIsLoadingSimulationForm} from "../../../../../corelogic/usecases/loader/loaderSelector";
import {
    selectCurrentStep, selectSteps
} from "../../../../../corelogic/usecases/new-simulation/new-simulation-selector/steps/selectSteps";
import {
    selectInjectionPointsHaveDuplicatedNames
} from "../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectInjectionPointForm";
import {
    selectConsumptionPointsHaveDuplicatedNames
} from "../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectConsumptionPointForm";
import { DistributionAlgorithmLabel } from "../form/operation-parameters-form/distribution/Distributions";


type SideBarType = {
    reduceSideBar(reduce: boolean): void,
    nextStepBtnState: ButtonState,
    expanded: boolean,
}


export default function SideBar({reduceSideBar, nextStepBtnState, expanded}: SideBarType) {

    const dispatch = useDispatch()
    const currentStep = useSelector(selectCurrentStep)
    const steps = useSelector(selectSteps)
    const simulationForm = useSelector(selectSimulationForm)
    const injectionPointFormHaveDuplicatedNames = useSelector(selectInjectionPointsHaveDuplicatedNames)
    const soutiragePointsFormHaveDuplicatedNames = useSelector(selectConsumptionPointsHaveDuplicatedNames)
    const isLoadingForm = useSelector(selectIsLoadingSimulationForm)


    const {enqueueSnackbar} = useSnackbar()
    useEffect(() => {
        let newSteps = [...steps]
        // return on form, load sidebar with info if complete

        if (simulationForm._general_info && simulationForm._general_info.name !== "" && simulationForm._general_info.postcode !== "") {
            let description: string [] = simulationForm._general_info.typeOfOperation ? [OperationType[simulationForm._general_info.typeOfOperation as keyof typeof OperationType]] : []
            description.push(simulationForm._general_info.name)
            description.push(simulationForm._general_info.postcode)
            newSteps[0] = new Step(steps[0].title, description, currentStep.index === 0 ? ButtonState.FOCUS : ButtonState.REGULAR, steps[0].index)
        } else {
            newSteps[0] = new Step(steps[0].title, [isLoadingForm ? "chargement...": "étape 1/5"], currentStep.index === 0 ? ButtonState.FOCUS : ButtonState.DISABLED, steps[0].index)
        }
        if (simulationForm._operation_parameters) {
            newSteps[3] = new Step(steps[3].title, [DistributionAlgorithmLabel[simulationForm._operation_parameters.distribution_algorithm]], currentStep.index === 3 ? ButtonState.FOCUS : ButtonState.REGULAR, steps[3].index)
        } else {
            newSteps[3] = new Step(steps[3].title, [isLoadingForm ? "chargement...":"étape 4/5"], currentStep.index === 3 ? ButtonState.FOCUS : ButtonState.DISABLED, steps[3].index)

        }
        if (simulationForm._financial_parameters) {
            newSteps[4] = new Step(steps[4].title, [simulationForm._financial_parameters.duration + "ans"], currentStep.index === 4 ? ButtonState.FOCUS : ButtonState.REGULAR, steps[4].index)
        } else {
            newSteps[4] = new Step(steps[4].title, [isLoadingForm ? "chargement...":"étape 5/5"], currentStep.index === 4 ? ButtonState.FOCUS : ButtonState.DISABLED, steps[4].index)
        }
        if (simulationForm._typology && simulationForm._typology.injectionPoints.some((injectionPoint) => injectionPoint.state)) {
            const buttonState = injectionPointFormHaveDuplicatedNames ? ButtonState.ERROR : (currentStep.index === 1 ? ButtonState.FOCUS : ButtonState.REGULAR)
            newSteps[1] = new Step(steps[1].title, simulationForm._typology.injectionPoints.filter((point: InjectionPoint) => point.state).map((pointEnabled) => pointEnabled.name), buttonState, steps[1].index)
        } else {
            newSteps[1] = new Step(steps[1].title, [isLoadingForm ? "chargement...":"étape 2/5"], currentStep.index === 1 ? ButtonState.FOCUS : ButtonState.DISABLED, steps[1].index)
        }

        if (simulationForm._typology && simulationForm._typology.consumptionPoints.some((injectionPoint) => injectionPoint.state)) {
            const buttonState = soutiragePointsFormHaveDuplicatedNames ? ButtonState.ERROR : (currentStep.index === 2 ? ButtonState.FOCUS : ButtonState.REGULAR)
            newSteps[2] = new Step(steps[2].title, simulationForm._typology.consumptionPoints.filter((point: ConsumptionPoint) => point.state).map((pointEnabled) => pointEnabled.name), buttonState, steps[2].index)
        } else {
            newSteps[2] = new Step(steps[2].title, [isLoadingForm ? "chargement...":"étape 3/5"], currentStep.index === 2 ? ButtonState.FOCUS : ButtonState.DISABLED, steps[2].index)
        }
        dispatch(newSimulationActions.setSteps(newSteps))
    }, [simulationForm, currentStep.index,  isLoadingForm])


    function pushToNextStep() {
        if (currentStep.index === 1) {
            dispatch(typologyFormActions.resetEditPoint())
        }
        if (currentStep.index === 4 && nextStepBtnState === ButtonState.DISABLED) {
            enqueueSnackbar("Toute les étapes du formulaire doivent être complétées pour réaliser la simulation.", {variant: "warning"})
        } else {
            dispatch(newSimulationActions.setNextStepIndex(currentStep.index + 1))
        }
    }

    function pushToStep1() {
        dispatch(newSimulationActions.setNextStepIndex(0))
    }

    function pushToStep2() {
        dispatch(typologyFormActions.resetEditPoint())
        dispatch(newSimulationActions.setNextStepIndex(1))
    }

    function pushToStep3() {
        dispatch(typologyFormActions.resetEditPoint())
        dispatch(newSimulationActions.setNextStepIndex(2))
    }

    function pushToStep4() {
        dispatch(newSimulationActions.setNextStepIndex(3))
    }

    function pushToStep5() {
        dispatch(newSimulationActions.setNextStepIndex(4))
    }

    return <aside className={expanded ?  `transition-width-smooth overflow-y-auto overflow-x-hidden min-w-[275px]  w-[350px] flex flex-col items-center justify-between dark:bg-zinc-800`
        :`transition-width-smooth overflow-y-auto flex flex-col min-w-[80px]  w-[100px] items-center justify-between dark:bg-zinc-800`}
                  data-cy={"form-side-bar"} aria-label="Sidebar">
        <div className="2xl:mx-3 center h-full scale-90 2xl:scale-100 w-max">
            <TwoFieldsBtn dataCy={'sidebar-step-1'}
                          Icon={() => InformationsIcon(steps[0].state === ButtonState.DISABLED)}
                          description={steps[0].description}
                          title={steps[0].title}
                          reduced={!expanded}
                          state={steps[0].state}
                          action={pushToStep1}/>
            <TwoFieldsBtn dataCy={'sidebar-step-2'} Icon={() => InjectionIcon(steps[1].state === ButtonState.DISABLED)}
                          description={steps[1].description}
                          reduced={!expanded}
                          title={steps[1].title}
                          state={steps[1].state}
                          action={pushToStep2}/>
            <TwoFieldsBtn dataCy={'sidebar-step-3'} Icon={() => ChipIcon(steps[2].state === ButtonState.DISABLED)}
                          description={steps[2].description}
                          title={steps[2].title}
                          reduced={!expanded}
                          state={steps[2].state}
                          action={pushToStep3}/>
            <TwoFieldsBtn dataCy={'sidebar-step-4'} Icon={() => OfficeIcon(steps[3].state === ButtonState.DISABLED)}
                          description={steps[3].description}
                          title={steps[3].title}
                          reduced={!expanded}
                          state={steps[3].state}
                          action={pushToStep4}/>
            <TwoFieldsBtn dataCy={'sidebar-step-5'}
                          Icon={() => CurrencyEuroIcon(steps[4].state === ButtonState.DISABLED)}
                          description={steps[4].description}
                          title={steps[4].title}
                          reduced={!expanded}
                          state={steps[4].state}
                          action={pushToStep5}/>
        </div>
        <div className="  center mb-2 w-full flex px-3 border-box">
            <RegularBtn dataCy={"form-sidebar-nextstep"}
                        IconComponent={() => <PlayIconOutlined disabled={true}/>}
                        title={(currentStep.index === 4 ? "Lancer la simulation" : "Etape suivante")}
                        state={nextStepBtnState} action={pushToNextStep}
                        reduced={!expanded}/>
        </div>
        <div className={"w-full"}>
            <hr className={"border-zinc-50 dark:border-zinc-700"}/>
            <div title={expanded ? "Réduire" : "Etendre"} onClick={() => reduceSideBar(expanded)}
                 className=" h-min flex justify-center hover:cursor-pointer text-gray-600 hover:bg-gray-100 dark:bg-zinc-800 dark:text-gray-200 dark:hover:bg-zinc-700  p-2 rounded-lg m-2">
                <div
                    className={expanded ? "flex justify-start  w-full border-gray-500  pl-2 py-2 rounded-full " : "flex  w-full border-gray-500  pl-2 py-2 rounded-full justify-center"}>
                    {expanded ? <DoubleChevronLeft/> : <DoubleChevronRight/>}
                    {expanded && <div className="pl-2 ">Réduire</div>}
                </div>
            </div>
        </div>
    </aside>
}
