import React, {useCallback, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    makeLowCompletudeDateStartDaysHasError,
    makeLowCompletudeDateStartMonthsHasError,
    makeLowCompletudeDateStartYearsHasError,
    makeLowCompletudeStartDateHasError,
    makeSelectFromIndexLowCompletenessStartDate,
    makeSelectFromIndexLowCompletenessStartDateDays,
    makeSelectFromIndexLowCompletenessStartDateMonths,
    makeSelectFromIndexLowCompletenessStartDateYears
} from "../../../../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import {typologyFormActions} from "../../../../../../../../../../corelogic/usecases/typology-form/typologyFormActions";

const DateStartInput = ({index}: { index: number }) => {
    const selectStartDate = useCallback(makeSelectFromIndexLowCompletenessStartDate(index), [index])
    const startDate = useSelector(selectStartDate)

    const selectDateStartError = useCallback(makeLowCompletudeStartDateHasError(index), [index])
    const error = useSelector(selectDateStartError)

    return <td className="py-1  font-semibold  text-left text-sm">
        <div className="flex items-left gap-0.5">
            <DayInput index={index} dateError={error}/>
            <MonthInput index={index} dateError={error}/>
            <YearsInput index={index} dateError={error}/>
        </div>
    </td>
}

export default DateStartInput


const DayInput = ({index, dateError}: { index: number, dateError: boolean }) => {
    const dispatch = useDispatch()
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
    const selectDateStartDays = useCallback(makeSelectFromIndexLowCompletenessStartDateDays(index), [index])
    const days = useSelector(selectDateStartDays)

    const selectHasDayError = useCallback(makeLowCompletudeDateStartDaysHasError(index), [index])
    const hasDayError = useSelector(selectHasDayError)
    const style = "border-blue-200 dark:border-opacity-20  bg-blue-200 border rounded px-2 py-1 dark:bg-blue-800 dark:bg-opacity-20  max-w-[35px]"
    const errorStyle = "border-red-200 dark:border-opacity-20  bg-red-200 text-red-700 border rounded px-2 py-1 dark:bg-red-800 dark:bg-opacity-20  max-w-[35px]"
    const handleInput = (e: React.ChangeEvent<HTMLInputElement>, currentIndex: number) => {
        const inputValue = e.target.value;
        if (inputValue.length === 2) {
            const nextIndex = currentIndex + 1;
            const nextInput = inputRefs.current[nextIndex];
            if (nextInput) {
                nextInput.focus();
            }
        }
    };
    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        if (inputValue.length > 0) {
            e.target.select();
        }
    };
    const updateNumber = (days: string) => {
        const numericValue = days.replace(/\D/g, ''); // Remove non-digit characters
        dispatch(typologyFormActions.setLOWStartDateDays(numericValue, index));
    };

    return <input
        placeholder={"01"}
        className={(hasDayError || dateError) ? errorStyle : style}
        type="text"
        onInput={(e: any) => handleInput(e, 0)}
        onFocus={handleFocus}
        ref={(el) => (inputRefs.current[0] = el)}
        maxLength={2}
        value={days}
        onChange={(e) => updateNumber(e.target.value)}
    />
}
const MonthInput = ({index, dateError}: { index: number, dateError: boolean }) => {
    const dispatch = useDispatch()
    const selectDateStartMonths = useCallback(makeSelectFromIndexLowCompletenessStartDateMonths(index), [index])
    const months = useSelector(selectDateStartMonths)

    const selectHasMonthError = useCallback(makeLowCompletudeDateStartMonthsHasError(index), [index])
    const hasMonthError = useSelector(selectHasMonthError)
    const style = "border-blue-200 dark:border-opacity-20  bg-blue-200 border rounded px-2 py-1 dark:bg-blue-800 dark:bg-opacity-20  max-w-[35px]"
    const errorStyle = "border-red-200 dark:border-opacity-20  bg-red-200 text-red-700 border rounded px-2 py-1 dark:bg-red-800 dark:bg-opacity-20  max-w-[35px]"

    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
    const handleInput = (e: React.ChangeEvent<HTMLInputElement>, currentIndex: number) => {
        const inputValue = e.target.value;
        if (inputValue.length === 2) {
            const nextIndex = currentIndex + 1;
            const nextInput = inputRefs.current[nextIndex];
            if (nextInput) {
                nextInput.focus();
            }
        }
    };
    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        if (inputValue.length > 0) {
            e.target.select();
        }
    };
    const updateNumber = (months: string) => {
        const numericValue = months.replace(/\D/g, ''); // Remove non-digit characters
        dispatch(typologyFormActions.setLOWStartDateMonths(numericValue, index));
    };
    return <input
        placeholder={"12"}
        className={(hasMonthError || dateError) ? errorStyle : style}
        type="text"
        onInput={(e: any) => handleInput(e, 1)}
        onFocus={handleFocus}
        ref={(el) => (inputRefs.current[1] = el)}
        maxLength={2}
        value={months}
        onChange={(e) => updateNumber(e.target.value)}
    />
}

const YearsInput = ({index, dateError}: { index: number, dateError: boolean }) => {

    const dispatch = useDispatch()
    const selectDateStartYears = useCallback(makeSelectFromIndexLowCompletenessStartDateYears(index), [index])
    const years = useSelector(selectDateStartYears)


    const selectHasYearsError = useCallback(makeLowCompletudeDateStartYearsHasError(index), [index])
    const hasYearsError = useSelector(selectHasYearsError)
    const style = "border-blue-200 dark:border-opacity-20  bg-blue-200 border rounded px-2 py-1 dark:bg-blue-800 dark:bg-opacity-20  max-w-[50px]"
    const errorStyle = "border-red-200 dark:border-opacity-20  bg-red-200 text-red-700 border rounded px-2 py-1 dark:bg-red-800 dark:bg-opacity-20  max-w-[50px]"

    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
    const handleInput = (e: React.ChangeEvent<HTMLInputElement>, currentIndex: number) => {
        const inputValue = e.target.value;
        if (inputValue.length === 2) {
            const nextIndex = currentIndex + 1;
            const nextInput = inputRefs.current[nextIndex];
            if (nextInput) {
                nextInput.focus();
            }
        }
    };
    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        if (inputValue.length > 0) {
            e.target.select();
        }
    };
    const updateNumber = (years: string) => {
        const numericValue = years.replace(/\D/g, ''); // Remove non-digit characters
        dispatch(typologyFormActions.setLOWStartDateYears(numericValue, index));
    };
    return <input
        placeholder={"2022"}
        className={(hasYearsError || dateError) ? errorStyle : style}
        type="text"
        onInput={(e: any) => handleInput(e, 2)}
        onFocus={handleFocus}
        ref={(el) => (inputRefs.current[2] = el)}
        maxLength={4}
        value={years}
        onChange={(e) => updateNumber(e.target.value)}
    />
}
