import {
  DistributionUngroupedPriorities, ZERO_PRIORITY
} from "../../../../models/types/new-simulation/form/operation-parameters/distribution/DistributionUngroupedPriorities";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    selectConsumptionPoints
} from "../../../new-simulation/new-simulation-selector/form-data/selectConsumptionPointForm";
import {
    selectInjectionPoints
} from "../../../new-simulation/new-simulation-selector/form-data/selectInjectionPointForm";
import {selectDistributionUngroupedPrioritiesForm} from "../../operationParametersFormSelector";
import {
    selectDistributionAlgorithm,
    selectDistributionByUngroupedPriority
} from "../../../new-simulation/new-simulation-selector/form-data/selectOperationParameter";
import {operationParametersFormActions} from "../../operationParametersFormActions";
import {
    DistributionAlgorithm
} from "../../../../models/types/new-simulation/form/operation-parameters/distribution/DistributionAlgorithm";
import {ungroupedPriorityUtils} from "./ungroupedPriorityUtils";

export type UseByUngroupedPriority = {
    table: DistributionUngroupedPriorities,
    updatePriority: (newPriority: number, injectionPointIndex: number, consumptionPointIndex: number) => void,
    isValid : boolean
}

export default function useByUngroupedPriority(): UseByUngroupedPriority {
    const dispatch = useDispatch()
    const [isValid, setIsValid] = useState(true)
    const ungroupedPriorities: DistributionUngroupedPriorities = useSelector(selectDistributionUngroupedPrioritiesForm)
    const consumptionPoints = useSelector(selectConsumptionPoints)
    const injectionPoints = useSelector(selectInjectionPoints)

    const oldSelectedAlgorithm = useSelector(selectDistributionAlgorithm)
    const oldByUngroupedPriorityDistribution = useSelector(selectDistributionByUngroupedPriority)

    useEffect(() => {
        // initialize
        if (consumptionPoints.length === 0 || injectionPoints.length === 0 || ungroupedPriorities.rows.length > 0) return
        const newUngroupedPriorities: DistributionUngroupedPriorities = {
            consumptionColumnTitles: consumptionPoints.map((consumptionPoint) => {
                return {id: consumptionPoint.id, name: consumptionPoint.name, state : consumptionPoint.state, segment : consumptionPoint.segment}
            }),
            rows: []
        }
        injectionPoints.map((injectionPoint, index) => {
            newUngroupedPriorities.rows.push({
                injectionPoint: {
                    id: injectionPoint.id,
                    name: injectionPoint.name,
                    state : injectionPoint.state,
                    generatedKwhPrice : injectionPoint.generatedKwhPrice},
                priorities: consumptionPoints.map((consumer, index_c) => {
                    if (consumer.isInACIWith(injectionPoint.id)) {
                        return ZERO_PRIORITY;
                    }
                    else {
                        return (index + 1)
                    }
                })
            })
        })
        dispatch(operationParametersFormActions.setDistributionUngroupedPriorities(newUngroupedPriorities))
    }, [consumptionPoints, injectionPoints, ungroupedPriorities]);

    useEffect(() => {
        //retrieve
        const distributionUngroupedPriorities = oldByUngroupedPriorityDistribution? JSON.parse(JSON.stringify(oldByUngroupedPriorityDistribution)) : {consumptionColumnTitles : [], rows : []};

        dispatch(operationParametersFormActions.setDistributionUngroupedPriorities(distributionUngroupedPriorities));

    }, [oldSelectedAlgorithm, oldByUngroupedPriorityDistribution]);

    useEffect(() => {
        setIsValid(ungroupedPriorityUtils.isValid(ungroupedPriorities))
    }, [ungroupedPriorities]);

    function updatePriority(newPriority: number, injectionPointIndex: number, consumptionPointIndex: number) {
        const newUngroupedPriorities = {
            ...ungroupedPriorities,
            rows: ungroupedPriorities.rows.map((row, rowIndex) => {
                if (rowIndex === injectionPointIndex) {
                    return {
                        ...row,
                        priorities: row.priorities.map((priority, priorityIndex) => {
                            if (priorityIndex === consumptionPointIndex) {
                                return newPriority
                            }
                            return priority
                        })
                    }
                }
                return row
            })
        }
        dispatch(operationParametersFormActions.setDistributionUngroupedPriorities(newUngroupedPriorities))
    }

    return {
        table: ungroupedPriorities,
        updatePriority,
        isValid
    }
}
