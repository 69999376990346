import {useSelector} from "react-redux";
import {
    selectBalanceMetricsLoading
} from "../../../../../corelogic/usecases/financialRestitution/financialRestitutionSelector";
import Card from "../metrics/Card";
import Return from "../../components/assets/icon/Return";
import Clock from "../../components/assets/icon/Clock";
import {CurrencyEuroSolidIcon} from "../../components/assets/icon/CurrencyEuro";
import Cash from "../../components/assets/icon/Cash";
import React from "react";

type CardsBalanceType = {
    tri?: string,
    tra?: string,
    lcoe?: string,
    internalValuation?: string
}
const CardsBalance = ({tri, tra, lcoe, internalValuation}: CardsBalanceType) => {
    const loading = useSelector(selectBalanceMetricsLoading)

    return <div className="px-4 pt-4 scale-95 2xl:scale-100 grid gap-6  w-full grid-cols-2 2xl:grid-cols-4 ">
        {tri && <Card color="red"
                      dataCy={'tri'}
                      Icon={Return} title="Taux de rentabilité interne (TRI)" value={`${tri}%`}
                      loading={loading}
                      tooltip={"Le taux de rendement interne (TRI) est un taux d’actualisation qui rend nulle la valeur actualisée nette (VAN) de tous les flux de trésorerie d’un projet\n" +
                          "Il donne une idée d’à quel point il est souhaitable ou non d’entreprendre un investissement : plus il est élevé, plus il est intéressant de lancer l’investissement."}/>}
        {tra && <Card color="green"
                      dataCy={'tra'}
                      Icon={Clock} title="Temps de retour actualisé (TRA)" value={`${tra} ans`}
                      loading={loading} tooltip={"Le temps de retour actualisé (TRA) est le\n" +
            "nombre d'années nécessaires afin que la valeur actualisée du projet soit au moins égale aux investissements initiaux (CAPEX) et frais opérationnels (OPEX) en cours. Il représente le temps nécessaire au projet pour générer un profit net."}/>}
        {lcoe && <Card color="blue"
                       dataCy={'lcoe'}
                       Icon={() => CurrencyEuroSolidIcon({className: "h-5 w-5"})} title="LCOE"
                       value={`${lcoe} c€/kWh HT`} loading={loading}
                       tooltip={"LCOE est l’acronyme de « Levelized Cost Of Energy », soit en français le « coût actualisé de l’énergie ».\n" +
                           "Il correspond à la somme des coûts de production d’énergie divisée par la quantité d’énergie produite actualisés sur un temps donné (20 ans, 25 ans, 30 ans, ...).\n" +
                           "Il s’exprime typiquement en c€/kWh et est fréquemment employé dans le secteur électrique pour comparer le coût de la production d'électricité entre différents projets."}/>}
        {internalValuation &&
            <Card color="slate" Icon={Cash} title="Valorisation interne kWh" value={`${internalValuation} c€/kWh`}
                  loading={loading}
                  dataCy={'valo-interne'}
                  tooltip={"La valorisation interne correspond à la somme des gains issus de la production d’énergie divisée par la quantité d’énergie produite actualisés sur un temps donné (20 ans, 25 ans, 30 ans, ...).\n" +
                      "Il s’exprime typiquement en c€/kWh.\n" +
                      "Il est à comparer au LCOE, la différence des deux étant le gain moyen de production d'un kWh sur la période observée."}/>}
    </div>
}
export default CardsBalance