import {VariantType} from "notistack";

export enum ErrorsEnum {
    // Load curves
    LAB_LOAD_CURVE_INVALID_FORMAT = "lab-load-curve-invalid-format",
    LAB_LOAD_CURVE_NOT_FOUND = "lab-load-curve-not-found",
    LAB_LOAD_CURVE_INVALID_VALUES = "lab-load-curve-invalid-values",
    // Enedis open-data API
    LAB_ENEDIS_OPEN_DATA_UNAVAILABLE = "lab-enedis-open-data-unavailable",
    LAB_INVALID_POSTCODE = "lab-invalid-postcode",

    // PVGIS
    LAB_PVGIS_UNAVAILABLE = "lab-pvgis-unavailable",

    // Login
    LAB_LOGIN_INVALID_CREDENTIALS = "lab-login-invalid-credentials",
    LAB_LOGIN_INVALID_OLD_PASSWORD = "lab-login-invalid-old-password",
    LAB_LOGIN_PASSWORD_UPDATE_ERROR = "lab-login-password-update-error",

    // Simulations
    LAB_SIMULATION_NOT_FOUND = "lab-simulation-not-found",
    LAB_SIMULATION_EXECUTION_ERROR = "lab-simulation-execution-error",

    // Customer accounts
    LAB_CUSTOMER_ACCOUNT_NOT_FOUND = "lab-customer-account-not-found",

    // Users
    LAB_USER_CREATION_ERROR = "lab-user-creation-error",
    LAB_USER_UPDATE_PASSWORD_ERROR = "lab-user-update-password-error",
    LAB_USER_UPDATE_ERROR = "lab-user-update-error",
    LAB_USER_NOT_FOUND = "lab-user-not-found",

    // Organisations
    LAB_ORGANISATION_NOT_FOUND = "lab-organisation-not-found",
    LAB_ORGANISATION_STILL_LINKED_TO_USERS = "lab-organisation-still-linked-to-users",

    // Restitution
    LAB_SIMULATION_IN_EXECUTION = "lab-simulation-in-execution",
    LAB_SIMULATION_NOT_EXECUTABLE = "lab-simulation-not_executable",
    LAB_POINT_NOT_FOUND = "lab-point-not-found",
}


export const snackbarErrorMessages = (errorCode?: ErrorsEnum | string): { message: string, variant: VariantType } => {
    switch (errorCode) {
        case ErrorsEnum.LAB_ENEDIS_OPEN_DATA_UNAVAILABLE:
            return {
                message: "L'API de notre partenaire Enedis n'est pas disponible pour le moment. Veuillez réessayer plus tard.",
                variant: "error"
            }
        case ErrorsEnum.LAB_LOAD_CURVE_INVALID_FORMAT:
            return {
                message: "Une erreur est survenue lors du traitement du fichier de courbe de charge. Veuillez vérifier que le fichier soit au bon format.",
                variant: "error"
            }
        case ErrorsEnum.LAB_INVALID_POSTCODE:
            return {message: "Le code postal fourni n'est pas valide", variant: "error"}
        case ErrorsEnum.LAB_PVGIS_UNAVAILABLE:
            return {
                message: "L'API de notre partenaire PVGIS n'est pas disponible pour le moment. Veuillez réessayer plus tard.",
                variant: "error"
            }
        case ErrorsEnum.LAB_SIMULATION_EXECUTION_ERROR:
            return {
                message: "L'exécution de la simulation n'a pas abouti. Vous pouvez essayer de rafraichir la page dans quelques minutes. Si le problème persiste, contactez notre équipe.",
                variant: "warning"
            }
        case ErrorsEnum.LAB_LOAD_CURVE_INVALID_VALUES:
            return {
                message: "La constitution de votre courbe de charge ne correspond pas au format attendu. Veuillez utiliser le template.",
                variant: "error"
            }

        default:
            return {
                message: `Une erreur est survenue. Vous pouvez communiquer ce code a notre equipe : ${errorCode}`,
                variant: "error"
            }

    }
}
