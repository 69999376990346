import {exportComponentAsPNG} from "react-component-export-image";
import {PhotoSolid} from "../components/assets/icon/PhotoIcon";
import React from "react";
import {useSelector} from "react-redux";
import {
    selectSimulationName
} from "../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectGeneralInfoForm";

export default function PNGExportButton({exportAsPNGRef, title}: { exportAsPNGRef: any, title:string }) {
    const simulationName=  useSelector(selectSimulationName)
    return <button
        onClick={() => exportComponentAsPNG(exportAsPNGRef, {
            fileName: simulationName+'_'+title
        })}
        title={"Exporter en image"}
        className={" p-2 font-semibold hover:cursor-pointer  rounded bg-gray-200  dark:bg-zinc-800 opacity-40 hover:opacity-100"}>
        <PhotoSolid className={'w-5 h-5  text-gray-600 dark:text-zinc-300   '}/>
    </button>
}
