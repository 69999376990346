import {ExternalInvestment, globalExternalInvestment} from "./ExternalInvestment";
import {InjectionPoint} from "../../../../../../corelogic/models/types/new-simulation/form/InjectionPoint";

export function availableExternalInvestment(investments: ExternalInvestment[], injectionPoints: InjectionPoint[]): { name : string, investment : ExternalInvestment } | null {
    const injectionPointsAvailable = findMissingInjectionPoint(investments, injectionPoints)
    if (injectionPointsAvailable)
        return {
            name : injectionPointsAvailable.name,
            investment : new ExternalInvestment(injectionPointsAvailable.id, '', '')
    }
    else return null
}

function findMissingInjectionPoint(investments: ExternalInvestment[], injectionPoints: InjectionPoint[]): InjectionPoint | null {
    // Create a Set from array2 for efficient lookup
    const investmentsSet: Set<string> = new Set(investments.map((investment) => investment.id));

    // Loop through array1 and check if each ID exists in array2
    for (const point of injectionPoints) {
        if (!investmentsSet.has(point.id)) {
            return point; // Return the first ID that is missing in array2
        }
    }

    return null; // Return null if no missing ID is found
}

export function removeInjectionPointInvestment(point_id: string, investments: ExternalInvestment[]): ExternalInvestment[] {
    for (let i = 0; i < investments.length; i++) {
        if (investments[i].id == point_id) {
            investments.splice(i, 1);
            break;
        }
    }
    return investments
}


export function listAvailableExternalInvestment(investments: ExternalInvestment[], injectionPoints: InjectionPoint[], currentInvest: ExternalInvestment): {
    id: string,
    name: string
}[] {
    let listAvailableExternalInvestment: { id: string, name: string }[] = [{
        id: currentInvest.id,
        name: getExternalInvestmentNameFromId(currentInvest.id, injectionPoints)
    }]
    if(currentInvest.id === globalExternalInvestment.id){
        return listAvailableExternalInvestment
    }
    injectionPoints.forEach((point) => {
        if (isIdAvailable(point.id, investments)) {
            listAvailableExternalInvestment.push({id: point.id, name: point.name})
        }
    })
    return listAvailableExternalInvestment

}

function isIdAvailable(id: string, investments: ExternalInvestment[]): boolean {
    return investments.every((invest) => invest.id !== id)
}

export function canApplyExternalInvests(invests: ExternalInvestment[]): boolean {
    return invests.length > 0 && invests.every((invest) => invest.validate())
}

export function getExternalInvestmentNameFromId(id: string, injectionPoints: InjectionPoint[]): string {
    const found = injectionPoints.find((point) => {
        if (point.id === id) {
            return true
        }
    })
    return found?.name || globalExternalInvestment.name
}


function areArraysEqual(arr1:any[], arr2:any[]) {
    // Check if the arrays have the same length
    if (arr1.length !== arr2.length) {
        return false;
    }

    // Sort both arrays by stringifying their objects
    const sortedArr1 = arr1.map(obj => JSON.stringify(obj)).sort();
    const sortedArr2 = arr2.map(obj => JSON.stringify(obj)).sort();

    // Compare the sorted arrays
    for (let i = 0; i < sortedArr1.length; i++) {
        if (sortedArr1[i] !== sortedArr2[i]) {
            return false;
        }
    }

    return true;
}

export function externalInvestsHasNewValidChanges(externalInvestment:ExternalInvestment[], storedExternalInvestment?:ExternalInvestment[] ):boolean{
    if(!storedExternalInvestment && externalInvestment.length === 0) return false
    const formIsValid = externalInvestment.every((invest:ExternalInvestment)=>invest.validate())
    const formAndStoredExternalInvestmentAreEquals = storedExternalInvestment  ? areArraysEqual(externalInvestment, storedExternalInvestment) : false
    return formIsValid && !formAndStoredExternalInvestmentAreEquals
}
