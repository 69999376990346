import {SimulationsDisplayGatewayInterface} from "../../../corelogic/usecases/simulations-display/simulationsDisplayGateway.interface";
import {Simulation} from "../../../corelogic/models/types/simulations-display/Simulation";
import {State} from "../../../config/app-config";
import apiClient from "./Api/ApiClient";

export type LandingSimulationConfig = {
    simulations: Simulation[],
    simulationsCount: number,
}

function isFormComplete(simulation : any) : State{
    if(simulation && simulation["general_info"] && simulation["general_info"].name && simulation["general_info"].postcode && simulation["typology"] && simulation["typology"]["injection_points"].find((injectionPoint:any) => injectionPoint.is_active) && simulation["typology"]["consumption_points"].find((consumptionPoint:any) => consumptionPoint.is_active) && simulation["operation_parameters"] && simulation["operation_parameters"]["distribution_algorithm"] && simulation["financial_parameters"]){

        return State.SUCCESS
    }
    return State.WARNING
}
export class APISimulationsDisplayGateway implements SimulationsDisplayGatewayInterface {
  async getSimulations(skip: number, limit: number, organisationId?: string): Promise<LandingSimulationConfig> {

    const queryOrganisation = organisationId ? `&organisation_id=${organisationId}` : '';
    const { data } = await apiClient.get(`/simulations?skip=${skip}&limit=${limit}${queryOrganisation}`);
    const simulations = data.simulations.map((simulationReceived: any) => {
      return new Simulation(simulationReceived.id, simulationReceived['general_info']?.name || '[sans nom] - ' + simulationReceived.id, new Date(simulationReceived.creation_timestamp).toLocaleDateString(), simulationReceived.updated_by.email, new Date(simulationReceived.update_timestamp).toLocaleDateString(), isFormComplete(simulationReceived), simulationReceived['general_info']?.operation_type || null, simulationReceived.organisation);
    });
    const simulationsCount = data.simulations_count;
    return { simulations, simulationsCount };
  }

  async getAllSimulations(): Promise<Simulation[]> {
    const { data: simulations } = await apiClient.get(`/simulations-all`);
    return simulations.map((simulationReceived: any) => {
      return new Simulation(simulationReceived.id, simulationReceived['general_info']?.name || '[sans nom] - ' + simulationReceived.id, '', '', '', isFormComplete(simulationReceived), simulationReceived['general_info']?.operation_type || null, simulationReceived.organisation);
    });
  }

  async duplicateSimulation(simulationId: string): Promise<Simulation> {
    const { data: simulationReceived } = await apiClient.post(`/simulations/${simulationId}`);
    return new Simulation(simulationReceived.id, simulationReceived['general_info']?.name || '[sans nom] - ' + simulationReceived.id, new Date(simulationReceived.creation_timestamp).toLocaleDateString(), simulationReceived.updated_by.email, new Date(simulationReceived.update_timestamp).toLocaleDateString(), isFormComplete(simulationReceived), simulationReceived['general_info'].operation_type || null, simulationReceived.organisation);
  }
}
