import {useDispatch, useSelector} from "react-redux";
import {typologyFormActions} from "../../../../../../../../../corelogic/usecases/typology-form/typologyFormActions";
import React from "react";
import {selectSGEFetchIfHoles} from "../../../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";

export default function FetchIfHolesSwitch(){
    const dispatch = useDispatch()
    const fetchIfHoles = useSelector(selectSGEFetchIfHoles)

    return <div className="w-full mt-2 mb-4 flex flex-row items-center">
        <label className="mr-2 block tracking-wide text-gray-600 font-semibold dark:text-zinc-300"
               htmlFor="grid-last-name"> Récupération si trous de données</label>
        <div data-cy={"typology-consumption-HPHC-switch"}
             onClick={() => dispatch(typologyFormActions.setSGEFetchIfHoles(!fetchIfHoles))}
             className={fetchIfHoles ? "form-check-input appearance-none w-9 rounded-full float-left h-5 align-top bg-no-repeat bg-contain bg-orange-200 focus:outline-none cursor-pointer shadow-sm" : "form-check-input appearance-none w-9 rounded-full float-left h-5 align-top bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm"}
             role="switch" id="flexSwitchCheckDefault">
            <div
                className={fetchIfHoles ? "w-5 h-5 ml-4 bg-secondary rounded-full " : "w-5 h-5 bg-white rounded-full "}/>
        </div>
    </div>
}
