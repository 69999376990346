import React from "react";

export default function DescriptionSteps(){
    return <div><span className="text-gray-500 text-xs font-light leading-none">Pas de temps de </span><span
        className="text-gray-500 text-xs font-bold leading-none">5</span><span
        className="text-gray-500 text-xs font-light leading-none">, </span><span
        className="text-gray-500 text-xs font-bold leading-none">10</span><span
        className="text-gray-500 text-xs font-light leading-none">, </span><span
        className="text-gray-500 text-xs font-bold leading-none">15</span><span
        className="text-gray-500 text-xs font-light leading-none">, </span><span
        className="text-gray-500 text-xs font-bold leading-none">30</span><span
        className="text-gray-500 text-xs font-light leading-none"> ou </span><span
        className="text-gray-500 text-xs font-bold leading-none">60 minutes</span><span
        className="text-gray-500 text-xs font-normal leading-none">.</span><span
        className="text-gray-500 text-xs font-bold leading-none"> </span></div>
}
