import {matchPath} from "react-router";

export const formatNumber = (nbr: number | undefined, digit: number = 0) => nbr != null ? parseFloat(nbr.toFixed(digit)).toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace('.', ",") : '-';



export const getSimulationIdFromUrl = (url: string) => {
    const match: any = matchPath(
        url,
        { path: '/simulation/:simulationId/*' }
    );
    return match?.params?.simulationId
}
