import withConditionalRender from "../../../../../wrapper/withConditionalRender";
import {
    makeIsRecalageEnergyValid,
    selectDisplayRecalageForm,
    selectRecalageEnergy
} from "../../../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import {ErrorMsgInput, Input} from "../../../../../../components/assets/FormAssets/TextInput";
import {UnitType} from "../../../../../../../../../config/app-config";
import React, {useCallback} from "react";
import ApplyRecalageButton from "./ApplyRecalageButton";
import {typologyFormActions} from "../../../../../../../../../corelogic/usecases/typology-form/typologyFormActions";
import {useDispatch, useSelector} from "react-redux";

function RecalageForm() {
    const dispatch = useDispatch()
    const recalageEnergy = useSelector(selectRecalageEnergy)
    const selectIsRecalageEnergyValid = useCallback(makeIsRecalageEnergyValid(recalageEnergy), [recalageEnergy])
    const isRecalageEnergyValid = useSelector(selectIsRecalageEnergyValid)

    const error = {state: !isRecalageEnergyValid, msg: 'doit être un nombre positif.'}

    return <div
        className={'flex bg-indigo-200 dark:text-indigo-200 dark:bg-indigo-700 dark:bg-opacity-20 rounded px-4 py-3 gap-2 w-full'}>
        <div className={"flex flex-col"}>
            <Title/>
            <Explanation/>
        </div>
        <div className={"flex w-full gap-2 items-center justify-end"}>
            <div className={"flex flex-col h-min"}>
                <div className={"h-0 relative left-1 bottom-4"}>
                    <ErrorMsgInput error={error}/>
                </div>
                <div
                    className={"rounded flex items-end text-indigo-700 dark:text-indigo-200 bg-indigo-100 dark:bg-indigo-600 dark:bg-opacity-20 py-1"}>
                    <input className={"w-[120px] bg-transparent border-none ring-0 font-semibold   pl-2 "}
                           pattern="[0-9]*"
                           required
                           type={"text"}
                           value={recalageEnergy}
                           id={'energy-volume-recalage'}
                           placeholder={'35000'}
                           onChange={(e) => dispatch(typologyFormActions.setRecalageInput(e.target.value.trim()))}/>
                    <span className={"text-sm font-semibold px-1"}>kWh</span>
                </div>
            </div>
            <ApplyRecalageButton/>
        </div>
    </div>
}

const Title = () => <span
    className={"text-indigo-600 dark:text-indigo-300 w-full text-sm font-bold overflow-hidden whitespace-nowrap"}>Recalage en énergie</span>
const Explanation = () => <span
    className={"text-indigo-600 dark:text-indigo-300  opacity-80 w-full text-xs font-semibold overflow-hidden whitespace-nowrap"}>Recalez la courbe de charge sur un volume d’énergie annuel</span>


export default withConditionalRender(RecalageForm, selectDisplayRecalageForm)



