export class OpexEntries {
    private _opexName: string;
    private _opex: string;
    private _opexAverageInflation: string
    private _id: string


    constructor(id: string, opexName: string, opex: string, opexAverageInflation: string) {
        this._id = id;
        this._opexName = opexName;
        this._opex = opex;
        this._opexAverageInflation = opexAverageInflation;
    }

    serialize() {
        return {
            id:this._id,
            name: this._opexName.trim(),
            opex: this._opex,
            opex_average_inflation: this._opexAverageInflation
        }
    }

    get id(): string {
        return this._id;
    }

    set id(value: string) {
        this._id = value;
    }

    get opexName(): string {
        return this._opexName;
    }

    set opexName(value: string) {
        this._opexName = value;
    }

    get opex(): string {
        return this._opex;
    }

    set opex(value: string) {
        this._opex = value;
    }

    get opexAverageInflation(): string {
        return this._opexAverageInflation;
    }

    set opexAverageInflation(value: string) {
        this._opexAverageInflation = value;
    }
}
