import React, {useCallback} from "react";
import {formActions} from "../../../../../../../../corelogic/usecases/form/formActions";
import {useDispatch, useSelector} from "react-redux";
import {makeSelectCapexFromId, selectFormCapexes} from "../../../../../../../../corelogic/usecases/form/formSelector";
import {FormCapex} from "../FormCapex";
import {
    selectInjectionPoints
} from "../../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectInjectionPointForm";

type SelectorInputType = {
    options: string[],
    id:string,
    index:number,
    value : string,
    disabled?:boolean
}
export default function SelectorInputForCapex({ options, id,index, value, disabled}: SelectorInputType) {
    const dispatch = useDispatch()
    const injectionPoints = useSelector(selectInjectionPoints)
    const capexes = useSelector(selectFormCapexes)

    const selectCapex = useCallback(makeSelectCapexFromId(value), [value])
    const capex:FormCapex = useSelector(selectCapex)
    function displayNamesFromId(capexAvailablePointId: string[]): string[] {
        let optionsName: string[] = [...capexAvailablePointId]
        capexAvailablePointId.map((capId, index) => {
            if (injectionPoints.some((point) => point.id === capId)) {
                optionsName[index] = injectionPoints.filter((point) => point.id === capId)[0].name
            }
        })
        return optionsName
    }
    function getIdFromName(capexName: string): string {
        if (injectionPoints.some((point) => point.name === capexName)) {
            return injectionPoints.filter((point) => point.name === capexName)[0].id
        } else return capexName
    }

    function injectionPointCapexOnChange(e: React.ChangeEvent<HTMLSelectElement>, index: number) {
        if(!disabled){
            let newInjectionCapex = [...capexes]
            newInjectionCapex[index].id = getIdFromName(e.target.value)
            dispatch(formActions.setCapexes(newInjectionCapex))
        }
    }

    return <label className="w-full block uppercase tracking-wide text-gray-600 text-xs font-bold  ">
        <select id={id} data-cy={id} onChange={(e) => injectionPointCapexOnChange(e,index)}
                value={capex.displayName(injectionPoints)}
                className="form-select focus:outline-none focus:ring focus:ring-gray-300  block py-2 px-4 text-sm font-semibold  w-full text-base bg-slate-100 dark:text-gray-200 dark:bg-zinc-700  text-gray-700 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
            {
                displayNamesFromId(options).map((option :string) => {
                return <option key={option}>{option}</option>
            })
            }
        </select>
    </label>
}
