import {createAction} from "../../../store/createAction";
import {ActionsUnion} from "../../../store/actionsUnions";
import BalanceMetricsData from "../../models/types/financialRestitution/BalanceMetricsData";

export const GET_CASH_FLOW_DATA = 'GET_CASH_FLOW_DATA';
export const SET_CASH_FLOW_DATA = 'SET_CASH_FLOW_DATA';
export const SET_CASH_FLOW_LOADING = 'SET_CASH_FLOW_LOADING';
export const GET_BALANCE_METRICS = 'GET_BALANCE_METRICS';
export const SET_BALANCE_METRICS = 'SET_BALANCE_METRICS';
export const SET_BALANCE_METRICS_LOADING = 'SET_BALANCE_METRICS_LOADING';
export const GET_ECONOMICAL_DATA = 'GET_ECONOMICAL_DATA';
export const SET_ECONOMICAL_DATA = 'SET_ECONOMICAL_DATA';
export const SET_ECONOMICAL_LOADING = 'SET_ECONOMICAL_LOADING';
export const GET_ECONOMICAL_METRICS = 'GET_ECONOMICAL_METRICS';
export const SET_ECONOMICAL_METRICS = 'SET_ECONOMICAL_METRICS';
export const SET_ECONOMICAL_METRICS_LOADING = 'SET_ECONOMICAL_METRICS_LOADING';
export const INIT_FINANCIAL_DATA = 'INIT_FINANCIAL_DATA'
export const GET_SURPLUS_DATA = 'GET_SURPLUS_DATA'
export const SET_SURPLUS_DATA = 'SET_SURPLUS_DATA'
export const SET_SURPLUS_LOADING = 'SET_SURPLUS_LOADING'
export const GET_INTERNAL_INVESTMENT_CASH_FLOW_DATA = 'GET_INTERNAL_INVESTMENT_CASH_FLOW_DATA'
export const SET_EXTERNAL_INVESTMENT_CASH_FLOW_DATA = 'SET_EXTERNAL_INVESTMENT_CASH_FLOW_DATA';
export const SET_EXTERNAL_INVESTMENT_PRODUCER_CASH_FLOW_DATA = 'SET_EXTERNAL_INVESTMENT_PRODUCER_CASH_FLOW_DATA';
export const SET_EXTERNAL_INVESTMENT_BALANCE_METRICS = 'SET_EXTERNAL_INVESTMENT_BALANCE_METRICS';
export const SET_EXTERNAL_INVESTMENT_PRODUCER_BALANCE_METRICS = 'SET_EXTERNAL_INVESTMENT_PRODUCER_BALANCE_METRICS';
export const GET_INTERNAL_INVESTMENT_BALANCE_METRICS ='GET_INTERNAL_INVESTMENT_BALANCE_METRICS';
export const GET_INTERNAL_INVESTMENT_PRODUCER_BALANCE_METRICS ='GET_INTERNAL_INVESTMENT_PRODUCER_BALANCE_METRICS';
export const GET_INTERNAL_INVESTMENT_PRODUCER_CASH_FLOW_DATA ='GET_INTERNAL_INVESTMENT_PRODUCER_CASH_FLOW_DATA';
export const financialRestitutionActions = {
    initData:() => createAction(INIT_FINANCIAL_DATA),
    getCashFlowData: (pointId ?: string ) => createAction(GET_CASH_FLOW_DATA, pointId),


    setCashFlowData: (data: any) => createAction(SET_CASH_FLOW_DATA, data),
    setCashFlowLoading: (loading: boolean) => createAction(SET_CASH_FLOW_LOADING, loading),
    getBalanceMetrics: (pointId ?: string ) => createAction(GET_BALANCE_METRICS, pointId),
    setBalanceMetrics: (metrics: BalanceMetricsData) => createAction(SET_BALANCE_METRICS, metrics),
    setBalanceMetricsLoading: (loading: boolean) => createAction(SET_BALANCE_METRICS_LOADING, loading),

    getExternalInvestmentBalanceMetrics: (pointId ?: string ) => createAction(GET_INTERNAL_INVESTMENT_BALANCE_METRICS, pointId),
    setExternalInvestmentBalanceMetrics:(data: any) => createAction(SET_EXTERNAL_INVESTMENT_BALANCE_METRICS, data),

    getExternalInvestmentProducerBalanceMetrics: (pointId ?: string ) => createAction(GET_INTERNAL_INVESTMENT_PRODUCER_BALANCE_METRICS, pointId),
    setExternalInvestmentProducerBalanceMetrics:(data: any) => createAction(SET_EXTERNAL_INVESTMENT_PRODUCER_BALANCE_METRICS, data),

    getExternalInvestmentCashFlowData: (pointId ?: string ) => createAction(GET_INTERNAL_INVESTMENT_CASH_FLOW_DATA, pointId),
    setExternalInvestmentCashFlowData:(data: any) => createAction(SET_EXTERNAL_INVESTMENT_CASH_FLOW_DATA, data),

    getExternalInvestmentProducerCashFlowData: (pointId ?: string ) => createAction(GET_INTERNAL_INVESTMENT_PRODUCER_CASH_FLOW_DATA, pointId),
    setExternalInvestmentProducerCashFlowData:(data: any) => createAction(SET_EXTERNAL_INVESTMENT_PRODUCER_CASH_FLOW_DATA, data),

    getEconomicalData: () => createAction(GET_ECONOMICAL_DATA),
    setEconomicalData: (data: any) => createAction(SET_ECONOMICAL_DATA, data),
    setEconomicalLoading: (loading: boolean) => createAction(SET_ECONOMICAL_LOADING, loading),
    getEconomicalMetrics: () => createAction(GET_ECONOMICAL_METRICS),
    setEconomicalMetrics: (metrics: any) => createAction(SET_ECONOMICAL_METRICS, metrics),
    setEconomicalMetricsLoading: (loading: boolean) => createAction(SET_ECONOMICAL_METRICS_LOADING, loading),
    getSurplusData: () => createAction(GET_SURPLUS_DATA),
    setSurplusData: (data: any) => createAction(SET_SURPLUS_DATA, data),
    setSurplusLoading: (loading: boolean) => createAction(SET_SURPLUS_LOADING, loading),
};

export type Actions = ActionsUnion<typeof financialRestitutionActions>;
