import {SegmentDetailed} from "../../SegmentDetailed";

export const ZERO_PRIORITY: number = 0;

export type DistributionUngroupedPriorities = {
    consumptionColumnTitles : { id : string, name : string, state : boolean, segment : SegmentDetailed }[],
    rows : PriorityRow[]
}

export type PriorityRow = {
    injectionPoint: {
        id: string,
        name: string,
        state : boolean,
        generatedKwhPrice : string
    },
    priorities : number[]
}
