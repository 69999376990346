import React, {ComponentType, useEffect, useState} from "react";
import {ButtonState, Theme} from "../../../../../../config/app-config";

type RegularBtnType = {
    title?: string,
    IconComponent?: ComponentType,
    state?: ButtonState,
    action?():void,
    dragging ?:boolean
    theme?:Theme,
    id?:string,
    dataCy?:string,
    reduced ?: boolean,
    loading ?:boolean
}

export default function ({id, title, IconComponent, state, action, dragging, theme, dataCy, reduced, loading}: RegularBtnType) {
    const [className, setClassName] = useState("")

    useEffect(()=> {
       switch(state) {
           case ButtonState.DISABLED :setClassName("w-full items-center whitespace-nowrap flex justify-center text-center font-semibold text-white px-4 py-3 rounded text-sm bg-slate-200 dark:bg-zinc-800 dark:text-zinc-700"); break;
           case ButtonState.FOCUS : setClassName("w-full items-center whitespace-nowrap flex justify-center text-center hover:animate-none hover:cursor-pointer shadow-lg my-4 font-semibold text-white px-4 py-3  rounded text-sm bg-yellow-500 hover:bg-yellow-600 dark:text-yellow-50 "); break;
           case ButtonState.OUTLINED : setClassName("flex items-center whitespace-nowrap w-full justify-center text-slate-400 px-4 py-9 rounded text-sm border-2 border-dashed border-slate-500 hover:cursor-pointer bg-slate-100 hover:bg-slate-200");break;
           case ButtonState.REGULAR : theme && theme === Theme.SECONDARY ?
               setClassName("w-full whitespace-nowrap flex justify-center items-center font-semibold text-blue-500 px-4 py-2 rounded text-xs hover:cursor-pointer hover:bg-blue-100 dark:hover:bg-blue-900") :
               setClassName("w-full whitespace-nowrap flex justify-center items-center  font-semibold text-white px-4 py-3 rounded text-sm hover:cursor-pointer bg-blue-500 hover:bg-blue-600"); break;
           default : break;
       }
    },[state])

    function enableDropping(event : React.DragEvent<HTMLDivElement>){
        event.preventDefault()
        event.stopPropagation()
    }
    function handleDrop(event : React.DragEvent<HTMLDivElement>){
        event.preventDefault()
        event.stopPropagation()
        //TODO read file
    }

    return <div title={title} data-cy={dataCy} id={id}  onDragOver={!!dragging ? enableDropping : () => console.log("drag disabled ...")}
                 onDrop={!!dragging  ? handleDrop:() => console.log("drag disabled ...")}
                 onClick={action? action : ()=>{console.log("RegularBtnDisabled ...")} }
                 className={className}>
        {dragging && <input className={"hidden"} type={"file"} id={"file_upload"}/>}
        {!reduced && <div className={IconComponent ? "pl-2 w-full" : "h-min whitespace-nowrap"}>{title}</div>}
        {IconComponent && <IconComponent/>}
    </div>

}
