import TextInput from "../../../components/assets/FormAssets/TextInput";
import {UnitType} from "../../../../../../config/app-config";
import React from "react";
import {useSelector} from "react-redux";
import {selectIsLoadingSimulationForm} from "../../../../../../corelogic/usecases/loader/loaderSelector";

type FixedInflationType = {
    id : string,
    inflation: any,
    inflationIsValid: boolean,
    inflationOnChange(newInflation: any): void
}

export default function FixedInflationInput({id, inflation, inflationIsValid, inflationOnChange}: FixedInflationType) {
    const isFormLoading = useSelector(selectIsLoadingSimulationForm)
    return <div className={"w-full "}>
        <TextInput id={id} placeholder={"(ex : 3.7)"} value={inflation} error={{
            state: !inflationIsValid,
            msg: "l'inflation doit être un nombre"
        }} onChange={inflationOnChange} unit={UnitType.PERCENT} loading={isFormLoading}
               dataCy={"financial-params-inflation"} isAFloat/>
    </div>
}
