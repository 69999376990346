import {formatNumber} from "../../../../../../../corelogic/usecases/config";

const YearlyResult = ({idx}: { idx: number }) => <td align={"center"}
                                                     className="py-1 px-4  whitespace-nowrap border-r dark:border-zinc-800">
    <span className="font-bold">{`A${idx}`}</span>
</td>

const Investment = ({cashFlowRow}: { cashFlowRow: any }) => <td align={"right"} className="py-1 px-4 ">
    <span
        className="text-slate-400 font-semibold text-sm dark:text-zinc-400 whitespace-nowrap">{cashFlowRow.investment !== null ? formatNumber(cashFlowRow.investment) : ''}</span>
</td>

const Maintenance = ({cashFlowRow}: { cashFlowRow: any }) => <td align={"right"} className="py-1 px-4 ">
    <span
        className="text-slate-400 font-semibold text-sm dark:text-zinc-400 whitespace-nowrap">{cashFlowRow.maintenance !== null ? formatNumber(cashFlowRow.maintenance) : ''}</span>
</td>

const LoanRepayment = ({cashFlowRow}: { cashFlowRow: any }) => <td align={"right"} className="py-1 px-4 ">
    <span
        className="text-slate-400 font-semibold text-sm dark:text-zinc-400 whitespace-nowrap">{cashFlowRow.loanRepayment !== null ? formatNumber(cashFlowRow.loanRepayment) : ''}</span>
</td>
const ACSavings = ({cashFlowRow}: { cashFlowRow: any }) => <td align={"right"} className="py-1 px-4 ">
    <span
        className="text-slate-400 font-semibold text-sm dark:text-zinc-400 whitespace-nowrap">{cashFlowRow.acSavings !== null ? formatNumber(cashFlowRow.acSavings) : ''}</span>
</td>
const Excess = ({cashFlowRow, rowHover, idx}: { cashFlowRow: any, rowHover: number, idx: number }) => <td
    align={"center"}
    className={rowHover === idx ? "py-1 px-4 dark:bg-neutral-600  bg-neutral-200" : "py-1 px-4 dark:bg-neutral-700  bg-neutral-50"}>
    <span
        className=" font-semibold text-sm text-neutral-400 whitespace-nowrap">{cashFlowRow.excess !== null ? formatNumber(cashFlowRow.excess) : ''}</span>
</td>
const NetSavings = ({cashFlowRow}: { cashFlowRow: any }) => <td align={"right"} className="py-1 px-4 ">
    <span
        className="text-slate-400 font-semibold text-sm dark:text-zinc-400 whitespace-nowrap">{cashFlowRow.netSavings !== null ? formatNumber(cashFlowRow.netSavings) : ''}</span>
</td>
const CumulativeFlows = ({cashFlowRow, rowHover, idx}: { cashFlowRow: any, rowHover: number, idx: number }) => <td
    align={"center"}
    className={
        `py-1 px-4 border-l-2 dark:border-zinc-800   ${cashFlowRow.cumulativeFlows >= 0
            ? (rowHover === idx ? 'bg-green-400 dark:bg-green-800 text-green-700 dark:text-green-100' : 'bg-green-300 dark:bg-green-900 text-green-700 dark:text-green-100')
            : (rowHover === idx ? 'bg-red-400 dark:bg-red-800 text-red-700 dark:text-red-100' : 'bg-red-300 dark:bg-red-900 text-red-700 dark:text-red-100')}`}
>
    <span
        className="font-black text-sm whitespace-nowrap">{cashFlowRow.cumulativeFlows !== null ? formatNumber(cashFlowRow.cumulativeFlows) : ''}</span>
</td>


// for open operation
const VariableInflation = ({inflationRate}: { inflationRate: string }) => <td align={"center"}
                                                                              className="border-r dark:border-zinc-800 text-slate-400 font-semibold text-sm dark:text-zinc-400">
    <span className="font-bold whitespace-nowrap">{inflationRate}</span>
</td>
const GiftedElecticity = ({idx, rowHover, cashFlowRow}: { idx: number, rowHover: number, cashFlowRow: any }) => <td
    align={"center"}
    className={rowHover === idx ? "py-1 px-4 dark:bg-sky-700  bg-sky-200" : "py-1 px-4 dark:bg-sky-900  bg-sky-50"}>
                                    <span
                                        className=" font-semibold text-sm text-sky-400 dark:text-sky-600 whitespace-nowrap">{cashFlowRow.gifted !== null ? formatNumber(cashFlowRow.gifted) : ''}</span>
</td>

const Royalty = ({idx, rowHover, cashFlowRow}: { idx: number, rowHover: number, cashFlowRow: any }) =>
    <td align={"center"}
        className={rowHover === idx ?
            "py-1 px-4 dark:bg-indigo-700  dark:bg-opacity-70 bg-indigo-200 " : " dark:bg-opacity-40  py-1 px-4 dark:bg-indigo-800  bg-indigo-50"}>
                                    <span
                                        className=" font-semibold text-sm text-indigo-400 dark:text-indigo-400 whitespace-nowrap">{cashFlowRow.royalty !== null ? formatNumber(cashFlowRow.royalty) : ''}</span>
    </td>

const Rent = ({idx, rowHover, cashFlowRow}: { idx: number, rowHover: number, cashFlowRow: any }) =>
    <td align={"center"}
        className={rowHover === idx ? "py-1 px-4 dark:bg-indigo-700  dark:bg-opacity-70 bg-indigo-200 " : " dark:bg-opacity-40  py-1 px-4 dark:bg-indigo-800  bg-indigo-50"}>
                                    <span
                                        className=" font-semibold text-sm text-indigo-400 dark:text-indigo-400 whitespace-nowrap">{cashFlowRow.rent !== null ? formatNumber(cashFlowRow.rent) : ''}</span>
    </td>

const ProducerGain = ({idx, rowHover, cashFlowRow}: { idx: number, rowHover: number, cashFlowRow: any }) =>
    <td align={"center"}
        className={rowHover === idx ? "py-1 px-4 dark:bg-neutral-600  bg-neutral-200" : "py-1 px-4 dark:bg-neutral-700  bg-neutral-50"}>
                                    <span
                                        className=" font-semibold text-sm text-neutral-400 whitespace-nowrap">{cashFlowRow.producerGain !== null ? formatNumber(cashFlowRow.producerGain) : ''}</span>
    </td>

const ACISavings = ({idx, rowHover, cashFlowRow}: { idx: number, rowHover: number, cashFlowRow: any }) => <td
    align={"center"}
    className={rowHover === idx ? "py-1 px-4 dark:bg-sky-700  bg-sky-200" : "py-1 px-4 dark:bg-sky-900  bg-sky-50"}>
                                    <span
                                        className=" font-semibold text-sm text-sky-400 dark:text-sky-600 whitespace-nowrap">{cashFlowRow.aciSavings !== null ? formatNumber(cashFlowRow.aciSavings) : ''}</span>
</td>


const ACCSavings = ({idx, rowHover, cashFlowRow}: { idx: number, rowHover: number, cashFlowRow: any }) =>
    <td align={"left"} className={rowHover === idx ?
        " py-1 px-4 border-l-2 dark:bg-slate-600  bg-slate-100  dark:border-zinc-800 "
        : " py-1 px-4 border-l-2 dark:bg-slate-700  bg-slate-50  dark:border-zinc-800 "}>
                                    <span
                                        className="text-slate-500 font-semibold text-sm  dark:text-slate-400 whitespace-nowrap">{cashFlowRow.accSavings !== null ? formatNumber(cashFlowRow.accSavings) : ''}</span>
    </td>
export {
    Royalty,
    Rent,
    YearlyResult,
    Investment,
    Maintenance,
    LoanRepayment,
    ACSavings,
    Excess,
    NetSavings,
    CumulativeFlows,
    VariableInflation,
    GiftedElecticity,
    ProducerGain,
    ACISavings,
    ACCSavings
}
