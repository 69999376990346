import ExclamationIcon from "../../components/assets/icon/ExclamationIcon";
import React, {useEffect, useState} from "react";
import {State} from "../../../../../config/app-config";
import {InformationCircleIcon} from "../../components/assets/icon/InformationCircleIcon";
import CheckIcon from "../../components/assets/icon/CheckIcon";
import {XIcon} from "../../components/assets/icon/XIcon";

type NotificationType  = {
    state :State,
    text : string,
    onClose?(value: any): void
}

export default function Notification({state, text, onClose}:NotificationType){
    const [className, setClassName] = useState("")

    useEffect(()=>{
        switch(state){
            case State.ERROR : setClassName("bg-red-200 dark:bg-red-500 dark:bg-opacity-40 text-gray-700 dark:text-red-100 text-sm rounded-sm p-3 w-full my-4 flex items-center justify-between"); break
            case State.SUCCESS : setClassName("bg-green-200 dark:bg-green-500 dark:bg-opacity-40  text-gray-700 dark:text-green-100 text-sm rounded-sm p-3 w-full my-4 flex items-center justify-between"); break
            case State.WARNING : setClassName("bg-yellow-200 dark:bg-yellow-500 dark:bg-opacity-40  text-gray-700 dark:text-yellow-100 text-sm rounded-sm p-3 w-full my-4 flex items-center justify-between") ; break
            default  : setClassName("bg-blue-200 text-gray-700 dark:text-gray-100 dark:bg-opacity-40  text-sm rounded-sm p-3 w-full my-4 flex items-center justify-between")
        }
    },[state])

    function getNotifIcon(){
        switch(state){
            case State.ERROR : return <ExclamationIcon />
            case State.SUCCESS : return <CheckIcon />
            case State.WARNING : return <ExclamationIcon />
            default : return <InformationCircleIcon/>

        }
    }
    return <div className={className}>
        <span className={"w-10 "}>{getNotifIcon()}</span>
        <span className={"w-full text-center"}> {text}</span>
        {onClose && <div className={"hover:cursor-pointer"} onClick={onClose}><XIcon/></div>}
    </div>
}
