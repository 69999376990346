import TitleText from "../components/assets/text/TitleText";
import SearchBar from "../composition/faq/SearchBar";
import Tags from "../composition/faq/FAQTags";
import FAQResults from "../composition/faq/FAQResults";
import {useDispatch, useSelector} from "react-redux";
import {selectRouterQuery} from "../../../../corelogic/usecases/routing/routingSelector";
import {useEffect} from "react";
import {faqActions} from "../../../../corelogic/usecases/faq/faqActions";

export default function FAQ(){
    const dispatch = useDispatch()
    const routerQuery = useSelector(selectRouterQuery)

    useEffect(() => {
        if(routerQuery?.theme === 'repartition_algorithm'){
            dispatch(faqActions.tagOnClick('algorithme de répartition'))
        }
    }, [routerQuery]);

    return <div className={"w-full dark:bg-zinc-800 h-full-minus-navbar overflow-y-auto items-center flex flex-col py-4 gap-8 2xl:gap-12 2xl:py-8"}>
        <Header/>
        <SearchBar/>
        <Tags/>
        <FAQResults/>
        <span className={"text-sm text-gray-500"}> N'hésitez pas à nous contacter via la bulle de chat si vous avez une autre question.</span>
    </div>
}
const Header = () => {
    return <div className={"flex flex-col items-center"}>
        <TitleText title={"FAQ"} />
        <span className={' text-2xl font-light text-gray-600 dark:text-gray-300'}>Une question ? Nous sommes là pour vous aider.</span>
    </div>
}


