import withRestitutionNavBar from "../composition/wrapper/withRestitutionNavBar";
import {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {financialRestitutionActions} from "../../../../corelogic/usecases/financialRestitution/financialRestitutionActions";
import EconomiesByPointTable from "../composition/table/EconomiesByPointTable";
import {
    selectEconomyMetricsLoading,
    selectProductorGainAndExcessYear1,
    selectProductorGainAndExcessYear25,
    selectTotalEconomyYear1,
    selectTotalEconomyYear25
} from "../../../../corelogic/usecases/financialRestitution/financialRestitutionSelector";
import Card from "../composition/metrics/Card";
import Cash from "../components/assets/icon/Cash";
import {PathnameEnum} from "../../../../corelogic/models/types/routing/PathnameEnum";
import {newSimulationActions} from "../../../../corelogic/usecases/new-simulation/newSimulationActions";
import {
    selectSelectedSimulationId
} from "../../../../corelogic/usecases/new-simulation/new-simulation-selector/form/newSimulationSelector";
import CostsAndGainsTable from "../composition/table/CostsAndGainsTable";
import TitleText from "../components/assets/text/TitleText";
import {WrapperCard} from "../composition/wrapper/WrapperCard";
import {CurrencyEuroSolidIcon} from "../components/assets/icon/CurrencyEuro";
import BillsImpactACCByPointTable from "../composition/table/BillsImpactACCByPointTable";
import BillsImpactACIByPointTable from "../composition/table/BillsImpactACIByPointTable";
import BillsImpactGiftedElectricityTable from "../composition/table/BillsImpactGiftedElectricityTable";
import TVAAndAcciseTable from "../composition/table/TVAAndAcciseTable";
import {ConsumptionPoint} from "../../../../corelogic/models/types/new-simulation/form/ConsumptionPoint";
import {
    selectFinancialDuration, selectHasACCBilling, selectHasGiftedElectricity
} from "../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectFinancialParameter";
import {
    selectIsACI
} from "../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectConsumptionPointForm";
import {
    selectIsOpenOperation
} from "../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectGeneralInfoForm";
import { useSimulationExecutionStatus } from "../utils/useSimulationExecutionStatus";
import { ExecutionStatus } from "../../../../corelogic/usecases/simulationExecutionStatus/simulationExecutionStatusActions";

function displayTVA(pointName:string, consumptionPoints:ConsumptionPoint[]){
    const matchingConsumptionPoint = consumptionPoints.filter((point) => point.name === pointName)[0]
    return matchingConsumptionPoint ? matchingConsumptionPoint.isVATRecoverable ?  " (€ HT)" : " (€ TTC)" : null
}

const EconomicalResult = () => {
    const dispatch = useDispatch();
    const totalEconomyYear1 = useSelector(selectTotalEconomyYear1);
    const totalEconomyYear25 = useSelector(selectTotalEconomyYear25);
    const productorGainAndExcessYear1 = useSelector(selectProductorGainAndExcessYear1)
    const productorGainAndExcessYear25 = useSelector(selectProductorGainAndExcessYear25)
    const isOpenOperation = useSelector(selectIsOpenOperation)
    const loading = useSelector(selectEconomyMetricsLoading);
    const simulationId = useSelector(selectSelectedSimulationId)
    const duration = useSelector(selectFinancialDuration)
    const isACI = useSelector(selectIsACI)
    const hasGiftedElectricity = useSelector(selectHasGiftedElectricity)
    const hasACCBilling = useSelector(selectHasACCBilling)
    const costAndGainRef=useRef(null)
    const economieByPointRef=useRef(null)
    const tvaAcciseRef=useRef(null)
    const executionStatus = useSimulationExecutionStatus();


    useEffect(() => {
        dispatch(newSimulationActions.getSimulationForm())
    }, [])

    useEffect(() => {
        if (simulationId && executionStatus === ExecutionStatus.finished) {
            dispatch(financialRestitutionActions.getEconomicalMetrics());
            dispatch(financialRestitutionActions.getEconomicalData());
            dispatch(financialRestitutionActions.getSurplusData());
        }
    }, [simulationId, executionStatus])
    return <div
        className="min-w-[640px] w-full   flex flex-col bg-gray-100 font-sans overflow-y-auto dark:bg-zinc-800 ">
        <div className=" bg-white shadow-sm  flex items-center w-full border-box  dark:bg-zinc-800">
            <div
                className="scale-95 2xl:scale-100 px-4 min-w-min  h-min  justify-between">
                <TitleText title={"Bilan économique sur " + duration + " ans"}/>
            </div>
        </div>
        <div
            className={isOpenOperation ? "scale-95 2xl:scale-100 px-4 pt-8 grid gap-6 w-full md:grid-cols-2 2xl:grid-cols-4" : "scale-95 2xl:scale-100 px-4 pt-8 grid gap-6 w-full md:grid-cols-2 2xl:grid-cols-3"}>
            {isOpenOperation ?
                <Card Icon={Cash} color="red"
                      dataCy={'net-gain-1-year'}
                      title={isOpenOperation ? isACI ? "Gain net pour les consommateurs (+ Economie ACI) 1 an" : "Gain net pour les consommateurs 1 an" : "Economie totale + surplus sur la première année"}
                      value={`${totalEconomyYear1} €`} loading={loading}/>
                : <Card Icon={Cash} color="red"
                        title={"Economie totale + surplus sur la première année"}
                        value={`${totalEconomyYear1} €`} loading={loading}/>}
            {isOpenOperation ?
                <Card Icon={Cash} color="red"
                      dataCy={'net-gain-25-year'}
                      title={isOpenOperation ? isACI ? `Gain net pour les consommateurs (+ Economie ACI) ${duration} ans` : `Gain net pour les consommateurs ${duration} ans` : "Economie totale + surplus sur " + duration + " ans"}
                      value={`${totalEconomyYear25} €`} loading={loading}/>
                : <Card Icon={Cash} color="red"
                        title={isOpenOperation ? `Economie totale ${duration} ans` : "Economie totale + surplus sur " + duration + " ans"}
                        value={`${totalEconomyYear25} €`} loading={loading}/>}
            {isOpenOperation &&
            <>
                <Card Icon={() => CurrencyEuroSolidIcon({className:"h-5 w-5"})} color="blue" title="Revenu producteur + Surplus 1 an"
                      dataCy={'prod-excess-1-year'}
                      value={`${productorGainAndExcessYear1} €`} loading={loading}/>
                <Card Icon={() => CurrencyEuroSolidIcon({className:"h-5 w-5"})} color="blue" title={`Revenu producteur + Surplus ${duration} ans`}
                      dataCy={'prod-excess-25-year'}
                      value={`${productorGainAndExcessYear25} €`} loading={loading}/>
            </>
            }
        </div>
        {isOpenOperation ?
            <>
                <BillsImpactTableForACIAndGiftedElectricityHandler isACI={isACI} hasACCBilling={hasACCBilling}
                                                                   hasGiftedElectricity={hasGiftedElectricity}/>
            </> :
            <div className={"pt-4 px-4"}>
                <WrapperCard exportAsPNGRef={economieByPointRef} title={"Impact sur les factures d'électricité"} exportTableId={'economied-by-point'}>
                    <div ref={economieByPointRef}>
                        <EconomiesByPointTable displayTVA={displayTVA}/>
                    </div>
                </WrapperCard>
            </div>
        }
        <div className={"p-4"}>
            <WrapperCard exportAsPNGRef={costAndGainRef} exportTableId={'cost-and-gain'} title={isOpenOperation ? "Revenu producteur et taxes" : "Surplus, Turpe et taxes producteur"}>
                <div ref={costAndGainRef}><CostsAndGainsTable /></div>
            </WrapperCard>
        </div>
        {isOpenOperation && <div className={"p-4"}>
            <WrapperCard exportAsPNGRef={tvaAcciseRef} title={"Taxes collectées et reversées"} exportTableId={'tva-accise-table'}>
                <div ref={tvaAcciseRef}><TVAAndAcciseTable/></div>
            </WrapperCard>
        </div>}

    </div>
}
const BillsImpactACC = () => {
    const billsACCByPointRef = useRef(null)
    return <div className={"pt-4 px-4"}>
        <WrapperCard exportAsPNGRef={billsACCByPointRef} title={"Impact sur les factures d'électricité des sites acheteurs de la boucle ACC"}
                     exportTableId={'bills-acc-by-point'}>
            <div ref={billsACCByPointRef}><BillsImpactACCByPointTable displayTVA={displayTVA}/></div>
        </WrapperCard>
    </div>
}

const BillsImpactACI = () => {
    const billsACIByPointRef = useRef(null)

    return <div className={"pt-4 px-4"}>
        <WrapperCard exportAsPNGRef={billsACIByPointRef} title={"Impact sur les factures d'électricité des sites en ACI"}
                     exportTableId={'bills-aci-by-point'}>
            <div ref={billsACIByPointRef}><BillsImpactACIByPointTable displayTVA={displayTVA}/></div>
        </WrapperCard>
    </div>
}

const BillsImpactACIAndGiftedElectricity = ({withACI}: { withACI: boolean }) => {
    const billsGiftedRef = useRef(null)
   return  <div className={"pt-4 px-4"}>
        <WrapperCard exportAsPNGRef={billsGiftedRef} exportTableId={'bills-gifted-table'}
                     title={"Évitement de factures d'électricité des sites en ACI et en partage gratuit d'électricité"}>
            <div ref={billsGiftedRef}><BillsImpactGiftedElectricityTable displayTVA={displayTVA} withACI={withACI}/></div>
        </WrapperCard>
    </div>
}
function BillsImpactTableForACIAndGiftedElectricityHandler({
                                                               isACI,
                                                               hasGiftedElectricity,
                                                                hasACCBilling
                                                           }: { isACI: boolean, hasGiftedElectricity: boolean, hasACCBilling : boolean }) {

    return <>
        {isACI && !hasGiftedElectricity && <BillsImpactACI/>}
        {hasGiftedElectricity && <BillsImpactACIAndGiftedElectricity withACI={isACI}/>}
        {hasACCBilling && <BillsImpactACC/>}
    </>
}

export default withRestitutionNavBar(EconomicalResult, PathnameEnum.ECONOMICAL_RESTITUTION);
