import React from "react";
import TabSelector from "./TabSelector";
import PRMInput from "./prm-input/PRMInput";
import DateStartInput from "./date-input/date-start-input/DateStartInput";
import GetSGECurveButton from "./GetSGECurveButton";
import {useSelector} from "react-redux";
import Results from "../results/Results";
import DateEndInput from "./date-input/date-end-input/DateEndInput";
import {selectSGEFormTab} from "../../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import RetriesInput from "./retries-input/RetriesInput";
import DelayInput from "./daily-between-retries-input/DelayInput";
import FetchIfHolesSwitch from "./fetch-if-holes/FetchIfHolesSwitch";

export default function SGEForm() {
    const tabFormSelected = useSelector(selectSGEFormTab)

    return <div className={"flex flex-col w-full pt-4"}>
        <TabSelector/>
        {tabFormSelected ?  <Form/>  :<Results/>}
    </div>
}

function Form() {
    return <form className={'p-4 flex flex-col w-full gap-4'}>
        <PRMInput/>
        <div className={"flex gap-4 justify-between"}>
            <div className={"flex gap-4  "}>
                <DateStartInput/>
                <DateEndInput/>
            </div>
            <div className={"flex gap-4  "}>
                <RetriesInput/>
                <DelayInput/>
            </div>
        </div>
        <FetchIfHolesSwitch/>
        <GetSGECurveButton/>
    </form>
}