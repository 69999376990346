import {createSelector} from "reselect";
import {selectNewSimulation} from "../form/newSimulationSelector";

const selectSavingForm = createSelector(
    selectNewSimulation,
    newSimulation => newSimulation.saveForm
)

const selectClosingForm = createSelector(
    selectNewSimulation,
    newSimulation => newSimulation.closeForm
)

export {
    selectClosingForm,
    selectSavingForm,
}