import {RootState} from "../../../store/rootReducer";
import {createSelector} from "reselect";
import { selectSimulationExecutionStatus } from "../simulationExecutionStatus/simulationExecutionStatusSelector";
import { ExecutionStatus } from "../simulationExecutionStatus/simulationExecutionStatusActions";

const selectRestitution = (state: RootState): any => state.restitution;

export const selectRestitutionData = createSelector(
    selectRestitution,
    restitution => restitution.data,
)

export const selectRestitutionLoading = createSelector(
    selectRestitution,
    selectSimulationExecutionStatus,
    (restitution, simulationExecutionStatus) => restitution.loading || simulationExecutionStatus === ExecutionStatus.running

)
