import {green, orange, red, State} from "../../../../../../../config/app-config";
import {CheckIconMini} from "../../../../components/assets/icon/CheckIcon";
import Badge from "../../../../components/assets/tag/Badge";
import React from "react";
import {XIcon} from "../../../../components/assets/icon/XIcon";

import {useSelector} from "react-redux";
import ExclamationIcon from "../../../../components/assets/icon/ExclamationIcon";
import {
    selectCDCStatusBadgeHelper,
    selectCDCStatusBadgeState,
    selectCDCStatusBadgeText
} from "../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-curve/selectFormCurve";


export function LoadCurveStatusBadge(){
    const badgeText:string = useSelector(selectCDCStatusBadgeText)
    const badgeState:State = useSelector(selectCDCStatusBadgeState)
    const badgeHelper:string = useSelector(selectCDCStatusBadgeHelper)

    const enolabColor = () => {
        switch (badgeState){
            case State.SUCCESS : return green;
            case State.WARNING : return orange;
            case State.ERROR : return red
            default :return red
        }
    }
    const Icon = () => {
        switch (badgeState){
            case State.SUCCESS : return <CheckIconMini className={"h-4 w-4 text-green-800 dark:text-green-300"}/>;
            case State.WARNING : return <ExclamationIcon className={"h-4 w-4 text-red-800 dark:text-red-300"}/>;
            case State.ERROR : return<XIcon className={"h-4 w-4 text-red-800 dark:text-red-300"}/>
            default : return <></>
        }
    }
    return <Badge enolabColor={enolabColor()} Icon={() => <Icon/>} text={badgeText} helper={badgeHelper}/>
}
export function CurveBadgeValid (){
    return <Badge
        enolabColor={green} text={'courbe valide'}
        Icon={() => <CheckIconMini className={"h-4 w-4 text-green-800 dark:text-green-300"}/>}
    />
}
export function CurveBadgeRejectedInvalid(){
    return <Badge
        enolabColor={red} text={'rejetée : courbe incomplète'} helper={"Rejetée : la courbe de charge doit avoir au moins 6 mois de données sans trous de plus d'une semaine par mois."}
        Icon={() => <XIcon className={"h-4 w-4 text-red-800 dark:text-red-300"}/>}
    />
}
