import {useEffect, useState} from "react";
import {
    selectSurplusAllocation
} from "../../new-simulation/new-simulation-selector/form-data/selectOperationParameter";
import {useSelector} from "react-redux";
import {InjectionPoint} from "../../../models/types/new-simulation/form/InjectionPoint";
import {
    selectInjectionPoints
} from "../../new-simulation/new-simulation-selector/form-data/selectInjectionPointForm";
import {
    selectIsDistributionAlgorithmByShares,
} from "../operationParametersFormSelector";
import {useSnackbar} from "notistack";
import {
    isSurplusAllocationByDefault,
    isSurplusAllocationByPriority,
    sanitizeSurplusAllocation
} from "./surplusAllocation.utils";
import {
    SurplusAllocation, SurplusAllocationAlgorithm
} from "../../../models/types/new-simulation/form/operation-parameters/surplus-allocation/SurplusAllocation";



export const INITIAL_SURPLUS_ALLOCATION: SurplusAllocation = {
    selectedAlgorithm: SurplusAllocationAlgorithm.by_default,
    priorities: []
}

export type UseSurplusAllocation = {
    value: SurplusAllocation,

    onCardClick: (algorithm: SurplusAllocationAlgorithm) => void,
    onPriorityChange: (index: number, priority: number) => void,

    checkIfSelected: (algorithm: SurplusAllocationAlgorithm) => boolean,
    isByPriority: boolean,
    hasChanged: () => boolean,
}

export default function useSurplusAllocation(): UseSurplusAllocation {
    const {enqueueSnackbar} = useSnackbar()

    const [surplusAllocation, setSurplusAllocation] = useState<SurplusAllocation>(INITIAL_SURPLUS_ALLOCATION)
    const backendSurplusAllocation: SurplusAllocation | null = useSelector(selectSurplusAllocation)
    const injectionPoints: InjectionPoint[] = useSelector(selectInjectionPoints)
    const isDistributionAlgorithmByShares: boolean = useSelector(selectIsDistributionAlgorithmByShares)

    // -- SELECTORS --
    const checkIfSelected = (algorithm: SurplusAllocationAlgorithm) => {
        return surplusAllocation.selectedAlgorithm === algorithm
    }
    const isByPriority = isSurplusAllocationByPriority(surplusAllocation.selectedAlgorithm)
    const hasChanged = (): boolean => {
        if (!backendSurplusAllocation) return false
        if (isSurplusAllocationByDefault(surplusAllocation.selectedAlgorithm) && isSurplusAllocationByDefault(backendSurplusAllocation.selectedAlgorithm)) return false
        return JSON.stringify(sanitizeSurplusAllocation(surplusAllocation)) !== JSON.stringify(backendSurplusAllocation)
    }

    // --- US1 - Retrieve Surplus from backend if exists else initialize ---
    useEffect(() => {
        let retrievedSurplus: SurplusAllocation = {
            selectedAlgorithm: SurplusAllocationAlgorithm.by_default,
            priorities: injectionPoints.map((ip: InjectionPoint) => ({pointId: ip.id, priority: 1, pointName: ip.name, pointState :ip.state}))
        }
        if (backendSurplusAllocation) {
            retrievedSurplus = {
                selectedAlgorithm: backendSurplusAllocation.selectedAlgorithm,
                priorities: backendSurplusAllocation.priorities.length > 0 ?
                    backendSurplusAllocation.priorities.map(item => ({ ...item }))
                    : retrievedSurplus.priorities
            }
        }
        setSurplusAllocation(retrievedSurplus)
    }, [backendSurplusAllocation])

    // --- US2 - selectCardOnClick, else notify error ---
    const onCardClick = (algorithm: SurplusAllocationAlgorithm) => {
        if (isDistributionAlgorithmByShares && algorithm === SurplusAllocationAlgorithm.by_priority) {
            enqueueSnackbar("Il n'est pas possible de faire de la répartition de surplus par priorité avec de la répartition par quote-part.", {variant: 'warning'})
        } else {
            setSurplusAllocation({...surplusAllocation, selectedAlgorithm: algorithm})
        }
    }

    // --- US3 - table inputs change ---
    const onPriorityChange = (index: number, priority: number) => {
        const updatedPriorites = [...surplusAllocation.priorities];
        updatedPriorites[index].priority = priority;
        setSurplusAllocation({...surplusAllocation, priorities: updatedPriorites})
    };

    return {onCardClick, onPriorityChange, checkIfSelected, isByPriority, hasChanged, value: surplusAllocation}
}
