import {RootState} from "../../../store/rootReducer";
import {createSelector} from "reselect";
import {
    DistributionAlgorithm
} from "../../models/types/new-simulation/form/operation-parameters/distribution/DistributionAlgorithm";

export const selectOperationsParametersForm = (state: RootState): any => state.operationParametersForm;
export const selectDistributionForm = (state: any) => state.operationParametersForm.distribution;
export const selectSeasonalityForm = (state: any) => state.operationParametersForm.seasonality;
export const selectDistributionAlgorithmForm = (state: any) => state.operationParametersForm.distribution.selectedAlgorithm;
export const selectDistributionPrioritiesForm = (state: any) => state.operationParametersForm.distribution.distributionPriorities;
export const selectDistributionSharesForm = (state: any) => state.operationParametersForm.distribution.distributionShares;
export const selectDistributionUngroupedPrioritiesForm = (state: any) => state.operationParametersForm.distribution.distributionUngroupedPriorities;

export const selectIsDistributionAlgorithmByUngroupedPriorities = createSelector(
    selectDistributionAlgorithmForm,
    distributionAlgorithm => distributionAlgorithm === DistributionAlgorithm.by_ungrouped_priority
);

export const selectIsDistributionAlgorithmByShares = createSelector(
    selectDistributionAlgorithmForm,
    distributionAlgorithm => distributionAlgorithm === DistributionAlgorithm.by_share
);
