import {ButtonState} from "../../../../../config/app-config";
import RegularBtn from "../../components/assets/button/RegularBtn";
import React, {useEffect, useState} from "react";
import {XIconRed} from "../../components/assets/icon/XIcon";
import {useDispatch, useSelector} from "react-redux";
import {newSimulationActions} from "../../../../../corelogic/usecases/new-simulation/newSimulationActions";

import CDCPrevisuChart from "../../components/assets/graphic/CDCPrevisuChart";
import {useSnackbar} from "notistack";
import FileInput from "../../components/assets/FormAssets/FileInput";
import {selectIsLoadingNewCurve} from "../../../../../corelogic/usecases/loader/loaderSelector";
import RainbowLoader from "../../components/assets/Animated/RainbowLoader";
import {
    selectNewCurve
} from "../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-curve/selectFormCurve";

type LoadCurveInputType = {
    setLoadCurveIsValid(valid: boolean): void
    withModel: boolean,
    model: string | null,
    loadingState: ButtonState,
    clear: boolean,
    chartColor: string,
    rowToEdit: any,
    loss?: string,
    angle?: string,
    orientation?: string,
    peakPower?: string,
    accommodationType?: string,
    heatingType?: string,
    maximumProductionPower?: number,
    setDisplayMaximumPowerCoherenceWarning?(val: boolean): void,
}

export default function LoadCurveInput({
                                           setLoadCurveIsValid,
                                           withModel,
                                           loadingState,
                                           clear,
                                           model,
                                           chartColor,
                                           rowToEdit,
                                           loss,
                                           angle,
                                           orientation,
                                           peakPower,
                                           accommodationType,
                                           heatingType,
                                           maximumProductionPower,
                                           setDisplayMaximumPowerCoherenceWarning,
                                       }: LoadCurveInputType) {
    const dispatch = useDispatch()
    const {enqueueSnackbar} = useSnackbar()
    const [loaded, setLoaded] = useState(false)
    const [btnText, setBtnText] = useState("")

    const pvgisParams = {loss: loss, angle: angle, orientation: orientation, peakPower: peakPower}
    const enedisParams = {accommodationType: accommodationType, heatingType: heatingType}
    const newCurve = useSelector(selectNewCurve)

    useEffect(() => {
        if (rowToEdit) {
            setLoaded(true)
        }
    }, [rowToEdit])

    useEffect(() => {
        if (clear) {
            setLoaded(false)
        }
    }, [clear])

    useEffect(() => {
        if (withModel) {
            if (model === "PVGIS") {
                setBtnText("Modéliser avec PVGIS")
            } else {
                setBtnText("Modéliser un profil type")
            }
        } else {
            setBtnText("Ajouter une courbe de charge par clic ou glisser-déposer*")
        }
    }, [model, withModel])


    function handleDownloadLoadCurve() {
        if (loadingState === ButtonState.DISABLED && withModel) {
            enqueueSnackbar("Attention, un ou plusieurs champ(s) vide(s) ou invalide(s) bloque(nt) la modélisation du point !",
                {
                    variant: "warning",
                })
        } else if (model === 'PVGIS') {
            dispatch(newSimulationActions.modelPvgis({
                loss: pvgisParams.loss || '',
                angle: pvgisParams.angle || '',
                orientation: pvgisParams.orientation || '',
                peakPower: pvgisParams.peakPower || '',
            }));
        } else {
            dispatch(newSimulationActions.modelEnedis({
                accommodationType: enedisParams.accommodationType,
                heatingType: enedisParams.heatingType || '',
            }))
        }
        setLoaded(true)
    }


    useEffect(() => {
        setLoadCurveIsValid(loaded)
    }, [setLoadCurveIsValid, loaded])

    useEffect(() => {
        if (setDisplayMaximumPowerCoherenceWarning) {
            if (newCurve?.maxPower && maximumProductionPower && maximumProductionPower * 1000 < newCurve?.maxPower) {
                setDisplayMaximumPowerCoherenceWarning(true)
            } else {
                setDisplayMaximumPowerCoherenceWarning(false)
            }
        }
    }, [newCurve, maximumProductionPower, setDisplayMaximumPowerCoherenceWarning])

    return <div className={"w-full"}>
        <label
            className="block uppercase tracking-wide text-gray-600 text-xs font-bold mb-2 my-4 dark:text-gray-200"
            htmlFor="grid-first-name">
            Ajouter une courbe de charge*
        </label>
        {!loaded ?
            (model ?
                <RegularBtn
                    dataCy={"typology-cdc-load-button"}
                    action={handleDownloadLoadCurve}
                    dragging={true}
                    title={btnText}
                    state={loadingState}/>
                :
                <FileInput
                    action={() => setLoaded(true)}/>)
            : <CDCCard
                setLoaded={setLoaded}
                chartColor={chartColor}/>
        }
    </div>
}


function CDCCard({chartColor, setLoaded}: { chartColor: string, setLoaded(load: boolean): void }) {

    const newCurve = useSelector(selectNewCurve)
    const isLoading = useSelector(selectIsLoadingNewCurve)

    return <div
        className={"shadow items-center rounded-lg border border-2 border-slate-200 px-4 py-2 flex flex-col justify-between"}>
        {
            isLoading ?
                <RainbowLoader text={"Chargement de la courbe de charge. Cela peut prendre quelques minutes"}/>
                :
                <>
                    <HeaderCDC setLoaded={setLoaded}/>
                    <div className={"w-full"}>
                        <CDCPrevisuChart color={chartColor}
                                         data={newCurve}/>
                    </div>
                </>
        }

    </div>
}
function HeaderCDC({setLoaded}: {setLoaded(load: boolean): void }){
    const dispatch = useDispatch()

    function handleClose() {
        dispatch(newSimulationActions.setNewCurve(null))
        dispatch(newSimulationActions.setFirstNewCurve(null))
        setLoaded(false)
    }

    return <div className={"flex w-full"}>
        <div className={"flex w-full items-center"}>
            <div className={"text-gray-600 font-semibold text-md"}>Courbe de charge</div>
        </div>
        <div className={"flex items-center justify-center"}>
            <div onClick={handleClose}
                 className={"bg-red-50 border-2 border-red-300 rounded-full p-1 hover:bg-red-200 hover:cursor-pointer"}>
                <XIconRed/></div>
        </div>
    </div>
}


