import ChevronLeft from "../icon/ChevronLeft";
import ChevronRight from "../icon/ChevronRight";
import {useDispatch, useSelector} from "react-redux";
import {
    selectNumberOfPages,
    selectSimulationsCount,
    selectSimulationsCurrentPage,
    selectPagination
} from '../../../../../../corelogic/usecases/simulations-display/simulationsDisplaySelector';
import {
    simulationsDisplayActions
} from "../../../../../../corelogic/usecases/simulations-display/simulationsDisplayActions";
import {useEffect} from "react";
import DoubleChevronRight from "../icon/DoubleChevronRight";
import DoubleChevronLeft from "../icon/DoubleChevronLeft";
import {selectAreLoadingSimulationsForm} from "../../../../../../corelogic/usecases/loader/loaderSelector";

export default function () {
    const dispatch = useDispatch()
    const numberOfPages = useSelector(selectNumberOfPages)
    const currentPage = useSelector(selectSimulationsCurrentPage)
    const simulationsCount = useSelector(selectSimulationsCount)
    const pagination = useSelector(selectPagination);
    const isLoading = useSelector(selectAreLoadingSimulationsForm)

    return (isLoading ? <></>:
        <div className="flex w-full items-center justify-between border-t border-gray-200 dark:border-zinc-400 bg-white  px-2 py-2 sm:px-6 dark:bg-zinc-700">
            <div className="flex flex-1 items-center justify-between">
                <div>
                    <p className="text-sm font-regular text-gray-700 dark:text-gray-300">
                        Simulations
                        <span className="font-semibold"> {currentPage === 1 ? 1 : currentPage * 10 - 10} </span>à<span
                        className="font-semibold"> {currentPage === numberOfPages ? simulationsCount : currentPage * 10} </span>
                        sur
                        <span className="font-semibold"> {simulationsCount} </span>
                        résultats
                    </p>
                </div>
                <div>
                    <nav className="relative z-0 inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                        <div
                            onClick={() => {
                                currentPage > 1 && dispatch(simulationsDisplayActions.setSimulationsCurrentPage(1));
                            }}
                            className={currentPage > 1 ?
                                'dark:border-zinc-500 dark:hover:bg-zinc-700 relative inline-flex dark:bg-zinc-800 dark:text-zinc-300 items-center border border-gray-300 bg-white px-1 py-1 text-sm font-medium text-gray-500 hover:bg-gray-50 hover:cursor-pointer'
                                : 'relative inline-flex items-center border border-gray-300 bg-white px-1 py-1 text-sm font-medium text-gray-300 bg-gray-50 dark:border-zinc-600 dark:bg-zinc-800 dark:text-zinc-600'}
                        >
                            <DoubleChevronLeft/>
                        </div>
                        <div
                            onClick={() => {
                                currentPage > 1 && dispatch(simulationsDisplayActions.setSimulationsCurrentPage(currentPage - 1));
                            }}
                            className={currentPage <= 1 ? 'relative inline-flex items-center border border-gray-300 bg-white px-1 py-1 text-sm font-medium text-gray-300 bg-gray-50 dark:bg-zinc-800 dark:text-zinc-600 dark:border-zinc-600'
                                : 'dark:border-zinc-500 dark:hover:bg-zinc-700 relative inline-flex items-center border border-gray-300  dark:bg-zinc-800 dark:text-zinc-300 bg-white px-1 py-1 text-sm font-medium text-gray-500 hover:bg-gray-50 hover:cursor-pointer'}
                        >
                            <ChevronLeft/>
                        </div>
                        {numberOfPages > 0 &&
                            pagination.map((e, i) => (
                                <a key={i}
                                   onClick={() => dispatch(simulationsDisplayActions.setSimulationsCurrentPage(e))}
                                   aria-current="page"
                                   className={currentPage === e ? 'relative dark:border-zinc-500 z-10 inline-flex items-center border border-slate-500 bg-slate-50 dark:border dark:border-zinc-600 px-2 py-1 text-sm font-medium text-slate-600 dark:bg-zinc-800 dark:text-zinc-300' : 'relative dark:hover:bg-zinc-700  inline-flex items-center bg-white border px-2 py-1  text-sm font-medium dark:border-zinc-600 border-gray-300 text-gray-500 hover:bg-gray-50 hover:cursor-pointer dark:bg-zinc-800 dark:text-zinc-600'}>
                                    {' '}
                                    {e}
                                </a>
                            ))}
                        <div
                            onClick={() => {
                                currentPage <= numberOfPages - 1 && dispatch(simulationsDisplayActions.setSimulationsCurrentPage(currentPage + 1));
                            }}
                            className={currentPage <= numberOfPages - 1 ?
                                'dark:border-zinc-500 dark:hover:bg-zinc-700 relative inline-flex dark:bg-zinc-800 dark:text-zinc-300 items-center border border-gray-300 bg-white px-1 py-1 text-sm font-medium text-gray-500 hover:bg-gray-50 hover:cursor-pointer'
                                : 'relative inline-flex items-center border border-gray-300 bg-white px-1 py-1 text-sm font-medium text-gray-300 bg-gray-50 dark:border-zinc-600 dark:bg-zinc-800 dark:text-zinc-600'

                            }
                        >
                            <ChevronRight/>
                        </div>
                        <div
                            onClick={() => {
                                currentPage <= numberOfPages - 1 && dispatch(simulationsDisplayActions.setSimulationsCurrentPage(numberOfPages));
                            }}
                            className={currentPage <= numberOfPages - 1 ?
                                'dark:border-zinc-500 dark:hover:bg-zinc-700 relative inline-flex dark:bg-zinc-800 dark:text-zinc-300 items-center border border-gray-300 bg-white px-1 py-1 text-sm font-medium text-gray-500 hover:bg-gray-50 hover:cursor-pointer'
                                : 'relative inline-flex items-center border border-gray-300 bg-white px-1 py-1 text-sm font-medium text-gray-300 bg-gray-50 dark:border-zinc-600 dark:bg-zinc-800 dark:text-zinc-600'}
                        >
                            <DoubleChevronRight/>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    );

}
