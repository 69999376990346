import {DistributionAlgorithm} from "./distribution/DistributionAlgorithm";
import {DistributionPriority} from "./distribution/DistributionPriority";
import {DistributionShare} from "./distribution/DistributionShare";
import {SurplusAllocationAPI} from "../../../../../usecases/operation-parameters-form/surplus_allocation/surplusAllocation.api";
import {seasonalityApi} from "../../../../../usecases/operation-parameters-form/seasonality/seasonality.api";
import {Seasonality} from "./seasonality/Seasonality";
import {DistributionUngroupedPriorities} from "./distribution/DistributionUngroupedPriorities";
import {
    ungroupedPriorityUtils
} from "../../../../../usecases/operation-parameters-form/distribution/ungrouped-by-priority/ungroupedPriorityUtils";
import {
    ungroupedPriorityApi
} from "../../../../../usecases/operation-parameters-form/distribution/ungrouped-by-priority/ungroupedPriorityApi";
import {SurplusAllocation} from "./surplus-allocation/SurplusAllocation";


export class OperationParameters {

    private _distribution_algorithm: DistributionAlgorithm;
    private _distribution_priorities: DistributionPriority[];
    private _distribution_shares: DistributionShare[];
    private _distribution_ungrouped_priorities: DistributionUngroupedPriorities;
    private _seasonality: Seasonality | null;
    private _surplus_allocation: SurplusAllocation

    constructor(distributionAlgorithm: DistributionAlgorithm, distributionPriorities: DistributionPriority[], distributionShares: DistributionShare[], seasonality: Seasonality | null, surplusAllocation: SurplusAllocation, distributionUngroupedPriorities: DistributionUngroupedPriorities) {
        this._distribution_algorithm = distributionAlgorithm;
        this._distribution_priorities = distributionPriorities ?? [];
        this._distribution_shares = distributionShares ?? [];
        this._seasonality = seasonality ? {...seasonality} : null;
        this._surplus_allocation = surplusAllocation;
        this._distribution_ungrouped_priorities = distributionUngroupedPriorities
    }


    isValid(): boolean {
        if ((this.distribution_algorithm as any) === "" || !this.distribution_algorithm) return false
        else {
            if (this.distribution_priorities.length === 0 && this.distribution_algorithm === DistributionAlgorithm.by_priority) return false
            else if (!ungroupedPriorityUtils.isValid(this.distribution_ungrouped_priorities) && this.distribution_algorithm === DistributionAlgorithm.by_ungrouped_priority) return false
            else if (this.distribution_shares.length <= 1 && this.distribution_algorithm === DistributionAlgorithm.by_share) return false
            else return true
        }
    }

    isDifferentFrom(operationParameters: OperationParameters | null): boolean {
        if (!operationParameters) {
            return false
        }
        const distributionByUngroupedPriorityDifferent = ungroupedPriorityUtils.isDifferentFrom(this.distribution_ungrouped_priorities, operationParameters.distribution_ungrouped_priorities)
        const distributionAlgorithmDifferent = this.distribution_algorithm !== operationParameters.distribution_algorithm
        const newDistributionPriorities = this.sanitizeDistributionPriorities(operationParameters.distribution_priorities)
        const distributionPrioritiesDifferent = (newDistributionPriorities.length === 0 || newDistributionPriorities.length !== this.distribution_priorities.length) ? false :
            this.distribution_priorities.some((p: DistributionPriority, index: number) => p.priority !== newDistributionPriorities[index].priority)
        const distributionSharesDifferent = (operationParameters.distribution_shares.length === 0 || operationParameters.distribution_shares.length !== this.distribution_shares.length) ?
            false :
            this.distribution_shares.some((s: DistributionShare) => operationParameters.distribution_shares.some((s2: DistributionShare) => s.pointId === s2.pointId && s.shares !== s2.shares))
        return distributionByUngroupedPriorityDifferent || distributionAlgorithmDifferent || distributionPrioritiesDifferent || distributionSharesDifferent
    }


    get distribution_ungrouped_priorities(): DistributionUngroupedPriorities {
        return this._distribution_ungrouped_priorities;
    }

    set distribution_ungrouped_priorities(value: DistributionUngroupedPriorities) {
        this._distribution_ungrouped_priorities = value;
    }

    get surplus_allocation(): SurplusAllocation {
        return this._surplus_allocation;
    }

    set surplus_allocation(value: SurplusAllocation) {
        this._surplus_allocation = value;
    }

    get seasonality(): Seasonality | null {
        return this._seasonality;
    }


    set seasonality(value: Seasonality | null) {
        this._seasonality = value;
    }

    get distribution_algorithm(): DistributionAlgorithm {
        return this._distribution_algorithm;
    }

    set distribution_algorithm(value: DistributionAlgorithm) {
        this._distribution_algorithm = value;
    }

    get distribution_priorities(): DistributionPriority[] {
        return this._distribution_priorities;
    }

    set distribution_priorities(value: DistributionPriority[]) {
        this._distribution_priorities = value;
    }

    get distribution_shares(): DistributionShare[] {
        return this._distribution_shares;
    }

    set distribution_shares(value: DistributionShare[]) {
        this._distribution_shares = value;
    }

    sanitizeDistributionPriorities(distributionPriorities: DistributionPriority[]): DistributionPriority[] {
        const priorityIndexes: number[] = Array.from(new Set<number>(distributionPriorities.map((p: DistributionPriority) => p.priority)))
        priorityIndexes.sort()
        const sanitisedPriorities = distributionPriorities.map((p: DistributionPriority) => new DistributionPriority(p.pointId, priorityIndexes.indexOf(p.priority)))
        return sanitisedPriorities
    }

    serialize() {
        return {
            distribution_algorithm: this._distribution_algorithm,
            distribution_priorities: this.sanitizeDistributionPriorities(this._distribution_priorities).map((p: DistributionPriority) => p.serialize()),
            distribution_shares: this._distribution_shares.map((s: DistributionShare) => s.serialize()),
            distribution_ungrouped_priorities: ungroupedPriorityApi.serialize(this.distribution_ungrouped_priorities),
            seasonality: seasonalityApi.serialize(this.seasonality) || null,
            surplus_algorithm: SurplusAllocationAPI.serializeSurplusAllocation(this.surplus_allocation).surplus_algorithm,
            surplus_priorities: SurplusAllocationAPI.serializeSurplusAllocation(this.surplus_allocation).surplus_priorities
        }
    }
}
