import {ConsumerType} from "../../../../../../../corelogic/models/types/new-simulation/form/ConsumerType";
import SelectorInput from "../../../../components/assets/FormAssets/selector/SelectorInput";
import React from "react";
import {typologyFormActions} from "../../../../../../../corelogic/usecases/typology-form/typologyFormActions";
import {useDispatch, useSelector} from "react-redux";
import {selectConsumptionPointConsumerType} from "../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import {selectIsLoadingSimulationForm} from "../../../../../../../corelogic/usecases/loader/loaderSelector";

export default function ConsumerTypeSelectorInput(){
    const dispatch = useDispatch()
    const consumerType = useSelector(selectConsumptionPointConsumerType)
    const isLoadingForm = useSelector(selectIsLoadingSimulationForm)

    function consumerTypeOnChange(e: React.ChangeEvent<HTMLSelectElement>) {
        switch (e.target.value) {
            case ConsumerType['professional'] :
                dispatch(typologyFormActions.setConsumerType(ConsumerType['professional']));
                break;
            case ConsumerType['individual'] :
                dispatch(typologyFormActions.setConsumerType(ConsumerType['individual']));
                break;
            default :
                break;
        }
    }

    return <SelectorInput dataCy={"typology-consumption-type"} value={isLoadingForm ? 'chargement ...' : consumerType} id={"select-type"}
                          onChange={consumerTypeOnChange}
                          label={"TYPE"}
                          options={Object.values(ConsumerType)}/>
}