export default function FranceFlagIcon({className}:{className:string}) {
    return <svg
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        fill="none"
        viewBox="0 0 26 25"
    >
        <path
            fill="#428BC1"
            d="M1.242 12.5c0 5.117 3.281 9.453 7.813 11.055V1.445C4.523 3.047 1.242 7.383 1.242 12.5z"
        ></path>
        <path
            fill="#ED4C5C"
            d="M24.68 12.5a11.68 11.68 0 00-7.813-11.055v22.11A11.68 11.68 0 0024.68 12.5z"
        ></path>
        <path
            fill="#fff"
            d="M9.055 23.555c1.21.43 2.539.664 3.906.664s2.695-.235 3.906-.664V1.445c-1.21-.43-2.5-.664-3.906-.664-1.406 0-2.695.235-3.906.664v22.11z"
        ></path>
    </svg>
}
