import React from 'react';
import * as XLSX from "xlsx";
import {ClipboardDocumentListSolid} from "../components/assets/icon/ClipboardDocumentListIcon";
import {useSelector} from "react-redux";
import {
    selectSimulationName
} from "../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectGeneralInfoForm";

const ClipboardExportButton: React.FC<{ tableId: string , title:string}> = ({tableId, title}) => {
    const simulationName=  useSelector(selectSimulationName)

    const exportTableToExcel = () => {
        const wb = XLSX.utils.table_to_book(document.getElementById(tableId), {sheet: `${tableId}`, raw: true});

        // Choose the sheet you want to work with
        const sheetName = wb.SheetNames[0];
        const sheet = wb.Sheets[sheetName];

        // Loop through the cells, find the ones with numbers and remove their spaces
        for (const cellAddress in sheet) {
            if (sheet.hasOwnProperty(cellAddress)) {
                const cell = sheet[cellAddress];
                if (cell.v && typeof cell.v === 'string' && !isNaN(parseInt(cell.v))) {
                    cell.v = cell.v.replace(/\s/g, ''); // Remove spaces
                    cell.v = cell.v.replace(/,/g, '.'); // Replace comma for dot so that excel understand it is a floating number
                    cell.t = 'n'
                }
            }
        }

        // Format to XLS and download file
        const wbout = XLSX.write(wb, {bookType: 'xlsx', type: 'binary'});

        function s2ab(s: any) {
            const buf = new ArrayBuffer(s.length);
            const view = new Uint8Array(buf);
            for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
            return buf;
        }

        const blob = new Blob([s2ab(wbout)], {type: 'application/octet-stream'});
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${simulationName}_${title}.xlsx`;
        link.click();
        URL.revokeObjectURL(url);
    };

    return (
        <button
            onClick={exportTableToExcel}
            title={'Exporter le tableau sur excel'}
            className={" p-2 font-semibold hover:cursor-pointer  rounded bg-gray-200  dark:bg-zinc-800 opacity-40 hover:opacity-100"}>
            <ClipboardDocumentListSolid className={'w-5 h-5  text-gray-600 dark:text-zinc-300   '}/>
        </button>
    );
};

export default ClipboardExportButton;
