import ConsumptionDistributionPieChart from "./ConsumptionDistributionPieChart";
import ProductionDistributionPieChart from "./ProductionDistributionPieChart";
import React, {useEffect} from "react";
import {
    physicalRestitutionActions
} from "../../../../../corelogic/usecases/physicalRestitution/physicalRestitutionActions";
import {useDispatch, useSelector} from "react-redux";
import {
    selectSelectedSimulationId
} from "../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form/newSimulationSelector";
import {selectSelectedType} from "../../../../../corelogic/usecases/physicalRestitution/physicalRestitutionSelector";
import {
    selectIsFormUpdating
} from "../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-state/selectFormState";
import { useSimulationExecutionStatus } from "../../utils/useSimulationExecutionStatus";
import { ExecutionStatus } from "../../../../../corelogic/usecases/simulationExecutionStatus/simulationExecutionStatusActions";

export default function DistributionPieChart() {
    const simulationId = useSelector(selectSelectedSimulationId)
    const isFormUpdating = useSelector(selectIsFormUpdating)
    const executionStatus = useSimulationExecutionStatus();

    const dispatch = useDispatch();

    const type = useSelector(selectSelectedType)
    useEffect(() => {
        return () => {
            dispatch(physicalRestitutionActions.cancelGetMetrics())
        }
    }, [])

    useEffect(() => {
        if (simulationId && !isFormUpdating && type.id && executionStatus === ExecutionStatus.finished) {
            dispatch(physicalRestitutionActions.getMetrics())
        }
    }, [type.id, type.aci, simulationId, isFormUpdating, executionStatus])

    return <div className=" w-full pt-4 pb-0 2xl:pb-4  px-3 2xl:px-0 grid grid-cols-1 lg:grid-cols-2 ">
        {type?.scope !== 'producer' &&

        <ConsumptionDistributionPieChart dataCy={'piechart-conso'}/>

        }
        {type.scope !== 'consumer' &&
        <div className={type.scope === 'producer' ? "pl-0" : "2xl:pl-4 pl-0"}>
            <ProductionDistributionPieChart dataCy={'piechart-prod'}/>
        </div>}
    </div>
}
