import {call, getContext, put, select, takeEvery, takeLatest} from "redux-saga/effects";
import {loaderActions} from "../../../loader/loaderActions";
import {
     selectFormOrganisationId,
    selectOrganisationIdWithCreditLeft,
    selectSelectedSimulationId, selectTargetYear
} from "../../new-simulation-selector/form/newSimulationSelector";
import {NewSimulationGatewayInterface} from "../../newSimulationGateway.interface";
import {snackBarErrorsActions} from "../../../errors/snackbarErrorsActions";
import {Curve} from "../../../../models/types/new-simulation/curve/Curve";
import {
    APPLY_ANNUAL_COMPLETION, APPLY_LOW_DATA_COMPLETION,
    APPLY_RECALAGE, LOAD_CURVE,
    LOAD_NEW_CURVE,
    MODEL_ENEDIS, MODEL_PVGIS,
    newSimulationActions, SAVE_LOADED_CURVE,
    UPLOAD_LOAD_CURVE,
    UPLOAD_UNSAVED_LOAD_CURVE
} from "../../newSimulationActions";
import {FETCH_SGE_CURVE, typologyFormActions} from "../../../typology-form/typologyFormActions";
import {ErrorsEnum} from "../../../../../adapters/primary/utils/snackbarErrorMessages";
import {CurveLoaded} from "../../../../models/types/new-simulation/curve/CurveLoaded";
import {
    selectLowCompletenessTable,
    selectRecalageEnergy, selectSGEDateEnd, selectSGEDateStart, selectSGEDelay, selectSGEFetchIfHoles,
    selectSGEPRM, selectSGERetries
} from "../../../typology-form/typologyFormSelector";
import LowCompletudeInput
    from "../../../../../adapters/primary/ui/composition/form/load-curve-upload/completude-and-recalage-button/low-completeness/form/table/LowCompletudeInput";
import {saveSGECDCConfig} from "../../../typology-form/typologyFormSaga";
import {SGEResults} from "../../../../../adapters/secondary/gateways/APINewSimulationGateway";
import {makeSelectLoadedCurve, selectFirstNewCurve, selectNewCurve} from "../../new-simulation-selector/form-curve/selectFormCurve";
import {selectPostcode} from "../../new-simulation-selector/form-data/selectGeneralInfoForm";

function* uploadLoadCurve(action: any): any {
    try {
        yield put(loaderActions.setIsLoadingNewCurve(true));
        const organisationId: any = yield select(selectOrganisationIdWithCreditLeft);

        const {type, nature, stepLength, body} = action.payload;
        const simulationId = yield select(selectSelectedSimulationId);
        const newSimulationGateway: NewSimulationGatewayInterface = yield getContext('newSimulationGatewayContext');
        const curve = yield call(newSimulationGateway.loadCurvesFromBody, type, nature, stepLength, body, organisationId, simulationId);
        yield call(loadNewCurve, {payload: curve.curveId});
    } catch (error: any) {
        yield put(snackBarErrorsActions.handleError(error));
        console.log('error on pushNewCurveUnFormatted saga ...', error);
    }
    yield put(loaderActions.setIsLoadingNewCurve(false));
}

function* uploadUnsavedLoadCurve(action: any): any {
    try {
        yield put(loaderActions.setIsLoadingNewCurve(true));
        const organisationId: any = yield select(selectOrganisationIdWithCreditLeft);
        const rawBody = action.payload;
        const simulationId = yield select(selectSelectedSimulationId);
        const newSimulationGateway: NewSimulationGatewayInterface = yield getContext('newSimulationGatewayContext');
        const resCurve: Curve = yield call(newSimulationGateway.loadUnsavedCurveFromBody, rawBody, organisationId, simulationId);
        yield put(newSimulationActions.setNewCurve(resCurve))
        yield put(newSimulationActions.setFirstNewCurve(resCurve))
        yield put(typologyFormActions.initLowCompletudeTable())
    } catch (error: any) {
        const customError = {
            message: "LAB_LOAD_CURVE_INVALID_VALUES",
            name: "LAB_LOAD_CURVE_INVALID_VALUES",
            code: ErrorsEnum.LAB_LOAD_CURVE_INVALID_VALUES,
            response: {}
        }
        yield put(snackBarErrorsActions.handleError(customError));
        yield put(typologyFormActions.setCDCUploadOption(null))
        console.log('error on uploadUnsavedLoadCurve saga ...', error);
    }
    yield put(loaderActions.setIsLoadingNewCurve(false));
}

function* saveLoadCurve() {
    try {
        yield put(loaderActions.setIsLoadingNewCurve(true));
        const unsavedCurve: Curve = yield select(selectNewCurve);
        const organisationId: string = yield select(selectOrganisationIdWithCreditLeft);
        const simulationId: string = yield select(selectSelectedSimulationId);
        const newSimulationGateway: NewSimulationGatewayInterface = yield getContext('newSimulationGatewayContext');
        const curveLoaded: CurveLoaded = yield call(newSimulationGateway.saveLoadCurve, unsavedCurve.measurement, simulationId, organisationId);
        yield call(loadNewCurve, {payload: curveLoaded.curveId});
    } catch (error: any) {
        yield put(snackBarErrorsActions.handleError(error));
        console.log('error on saveLoadCurve saga ...', error);
    }
    yield put(loaderActions.setIsLoadingNewCurve(false));
}

function* modelEnedis(action: any): any {
    try {
        yield put(loaderActions.setIsLoadingNewCurve(true));
        const {accommodationType, heatingType} = action.payload;
        const organisationId: any = yield select(selectOrganisationIdWithCreditLeft);
        const postCode: string = yield select(selectPostcode);
        const simulationId = yield select(selectSelectedSimulationId);
        const newSimulationGateway: NewSimulationGatewayInterface = yield getContext('newSimulationGatewayContext');
        const curve = yield call(newSimulationGateway.loadCurvesFromEnedis, postCode, accommodationType, heatingType, organisationId, simulationId);
        yield call(loadNewCurve, {payload: curve.curveId});
    } catch (error: any) {
        yield put(snackBarErrorsActions.handleError(error));
        console.log('error on modelEnedis saga ...', error);
    }
    yield put(loaderActions.setIsLoadingNewCurve(false));
}

function* applyRecalage(): any {
    try {
        yield put(loaderActions.setIsLoadingNewCurve(true));
        const energyInput = yield select(selectRecalageEnergy)
        const simulationId = yield select(selectSelectedSimulationId)
        const organisationId: any = yield select(selectOrganisationIdWithCreditLeft);
        const targetYear: number = yield select(selectTargetYear)
        const unsavedNewCurve: Curve = yield select(selectNewCurve)
        const newSimulationGateway: NewSimulationGatewayInterface = yield getContext('newSimulationGatewayContext');
        const curve = yield call(newSimulationGateway.applyRecalage,unsavedNewCurve.serialize().measurement, targetYear, simulationId, organisationId , energyInput);
        yield put(newSimulationActions.setNewCurve(curve))
    } catch (error: any) {
        yield put(snackBarErrorsActions.handleError(error));
        console.log('error on pushNewCurveUnFormatted saga ...', error);
    }
    yield put(loaderActions.setIsLoadingNewCurve(false));
}

function* applyAnnualCompletion(): any {
    try {
        yield put(loaderActions.setIsLoadingNewCurve(true));
        const simulationId = yield select(selectSelectedSimulationId)
        const organisationId: any = yield select(selectOrganisationIdWithCreditLeft);
        const targetYear: number = yield select(selectTargetYear)
        const unsavedNewCurve: Curve = yield select(selectNewCurve)
        const newSimulationGateway: NewSimulationGatewayInterface = yield getContext('newSimulationGatewayContext');
        const curve = yield call(newSimulationGateway.applyAnnualCompletion, unsavedNewCurve.serialize().measurement, targetYear, simulationId, organisationId);
        yield put(newSimulationActions.setNewCurve(curve))
    } catch (error: any) {
        yield put(snackBarErrorsActions.handleError(error));
        console.log('error on pushNewCurveUnFormatted saga ...', error);
    }
    yield put(loaderActions.setIsLoadingNewCurve(false));
}

function* applyLowDataCompletion(): any {
    try {
        yield put(loaderActions.setIsLoadingNewCurve(true));
        const simulationId = yield select(selectSelectedSimulationId)
        const organisationId: any = yield select(selectOrganisationIdWithCreditLeft);
        const targetYear: number = yield select(selectTargetYear)
        const unsavedFirstNewCurve: Curve = yield select(selectFirstNewCurve)
        const tableInput : LowCompletudeInput[] = yield select(selectLowCompletenessTable)
        const newSimulationGateway: NewSimulationGatewayInterface = yield getContext('newSimulationGatewayContext');
        const curve = yield call(newSimulationGateway.applyLowDataCompletion,unsavedFirstNewCurve.serialize().measurement, tableInput, targetYear, simulationId, organisationId);
        yield put(newSimulationActions.setNewCurve(curve))
    } catch (error: any) {
        yield put(snackBarErrorsActions.handleError(error));
        console.log('error on pushNewCurveUnFormatted saga ...', error);
    }
    yield put(loaderActions.setIsLoadingNewCurve(false));
}

function* modelPvgis(action: any): any {
    try {
        yield put(loaderActions.setIsLoadingNewCurve(true));
        const {loss, angle, orientation, peakPower} = action.payload;
        const organisationId: any = yield select(selectOrganisationIdWithCreditLeft);
        const postCode: string = yield select(selectPostcode);
        const simulationId = yield select(selectSelectedSimulationId);
        const newSimulationGateway: NewSimulationGatewayInterface = yield getContext('newSimulationGatewayContext');
        const curve = yield call(newSimulationGateway.loadCurvesFromPVGIS, postCode, peakPower, loss, angle, orientation, organisationId, simulationId);
        yield call(loadNewCurve, {payload: curve.curveId});
    } catch (error: any) {
        yield put(snackBarErrorsActions.handleError(error));
        console.log('error on pushNewCurveUnFormatted saga ...', error);
    }
    yield put(loaderActions.setIsLoadingNewCurve(false));
}

function* loadNewCurve(action: any): any {
    yield put(loaderActions.setIsLoadingNewCurve(true));
    const {payload} = action;
    try {
        const newSimulationGateway: NewSimulationGatewayInterface = yield getContext('newSimulationGatewayContext');
        const curve = yield call(newSimulationGateway.loadCurveFromId, payload);
        yield put(newSimulationActions.setNewCurve(curve));
    } catch (error: any) {
        yield put(snackBarErrorsActions.handleError(error));
        console.log('error on getCurveFromId saga ... ', error);
    }
    yield put(loaderActions.setIsLoadingNewCurve(false));
}

function* loadCurve(action: any): any {
    const {payload} = action;
    try {
        yield put(newSimulationActions.setPushLoadedCurveLoading({loadedCurveId: payload, loading: true}));

        const loadedCurve = yield select(makeSelectLoadedCurve(payload));

        const newSimulationGateway: NewSimulationGatewayInterface = yield getContext('newSimulationGatewayContext');
        if (!loadedCurve) {
            const curve = yield call(newSimulationGateway.loadCurveFromId, payload);
            yield put(newSimulationActions.pushLoadedCurve(curve));
        }
    } catch (error: any) {
        yield put(snackBarErrorsActions.handleError(error));
        console.log('error on getCurveFromId saga ... ', error);
    }
    yield put(newSimulationActions.setPushLoadedCurveLoading({loadedCurveId: payload, loading: false}));
}

function* uploadLoadCurveSaga() {
    yield takeLatest(UPLOAD_LOAD_CURVE, uploadLoadCurve);
}

function* uploadUnsavedLoadCurveSaga() {
    yield takeLatest(UPLOAD_UNSAVED_LOAD_CURVE, uploadUnsavedLoadCurve);
}

function* modelEnedisSaga() {
    yield takeLatest(MODEL_ENEDIS, modelEnedis);
}

function* modelPvgisSaga() {
    yield takeLatest(MODEL_PVGIS, modelPvgis);
}

function* loadNewCurveSaga() {
    yield takeLatest(LOAD_NEW_CURVE, loadNewCurve);
}

function* applyRecalageSaga() {
    yield takeLatest(APPLY_RECALAGE, applyRecalage)
}

function* applyAnnualCompletionSaga() {
    yield takeLatest(APPLY_ANNUAL_COMPLETION, applyAnnualCompletion)
}

function* applyLowDataCompletionSaga() {
    yield takeLatest(APPLY_LOW_DATA_COMPLETION, applyLowDataCompletion)
}

function* loadCurveSaga() {
    yield takeEvery(LOAD_CURVE, loadCurve);
}

function* fetchSgeCurve() {
    try {
        yield put(loaderActions.setIsLoadingNewCurve(true));
        yield call(saveSGECDCConfig)
        const simulationId: string = yield select(selectSelectedSimulationId)
        const organisationId: string = yield select(selectFormOrganisationId)
        const prm: string = yield select(selectSGEPRM)
        const startDate: { days: string, months: string, years: string } = yield select(selectSGEDateStart)
        const endDate: { days: string, months: string, years: string } = yield select(selectSGEDateEnd)
        const retries :string = yield select(selectSGERetries)
        const delay:string = yield select(selectSGEDelay)
        const ifHolesFetch:boolean = yield select(selectSGEFetchIfHoles)

        const twoDigitLong = (digit: string): string => {
            if (digit.length === 1) {
                return '0' + digit
            } else return digit
        }

        const startDateToAdd: string = '20' + startDate.years + '-' + twoDigitLong(startDate.months) + '-' + twoDigitLong(startDate.days)
        const endDateToAdd: string = '20' + endDate.years + '-' + twoDigitLong(endDate.months) + '-' + twoDigitLong(endDate.days)
        const newSimulationGateway: NewSimulationGatewayInterface = yield getContext('newSimulationGatewayContext');
        const resCurveWithSGEResults: { sgeResults: SGEResults; newCurve: Curve } = yield call(newSimulationGateway.loadUnsavedCurveFromSGE, prm,retries, delay, ifHolesFetch, startDateToAdd, endDateToAdd, organisationId, simulationId);
        yield put(newSimulationActions.setNewCurve(resCurveWithSGEResults.newCurve))
        yield put(newSimulationActions.setFirstNewCurve(resCurveWithSGEResults.newCurve))
        yield put(typologyFormActions.initLowCompletudeTable())
        yield put(typologyFormActions.setSGEWeeklyFailuresResults(resCurveWithSGEResults.sgeResults.failuresByWeeks))
        yield put(typologyFormActions.setSGENumberOfSuccessfullWeeks(resCurveWithSGEResults.sgeResults.successfullResponseNumber))
        yield put(typologyFormActions.setSGESuccessRate(resCurveWithSGEResults.sgeResults.successRate))
        yield put(typologyFormActions.setSGEFormTab(false))
    } catch (error: any) {
        yield put(snackBarErrorsActions.handleError(error));
    }
    yield put(loaderActions.setIsLoadingNewCurve(false));
}

function* fetchSgeCurveSaga() {
    yield takeLatest(FETCH_SGE_CURVE, fetchSgeCurve)
}

function* saveLoadCurveSaga() {
    yield takeLatest(SAVE_LOADED_CURVE, saveLoadCurve)
}

const newSimulationCurveSaga = [
    uploadLoadCurveSaga,
    uploadUnsavedLoadCurveSaga,
    modelEnedisSaga,
    modelPvgisSaga,
    loadNewCurveSaga,
    applyRecalageSaga,
    applyAnnualCompletionSaga,
    applyLowDataCompletionSaga,
    loadCurveSaga,
    fetchSgeCurveSaga,
    saveLoadCurveSaga,
];
export default newSimulationCurveSaga