export enum SegmentDetailed {
    C5_CU = 'C5 CU (BASE)',
    C5_MUDT = 'C5 MUDT',
    C5_CU4='C5 CU4',
    C4_CU4='C4 CU4',
    C4_LU4='C4 LU4',
    HTA_CU5 ='HTA CU5 POINTE FIXE',
    HTA_LU5 ='HTA LU5 POINTE FIXE',
}
export function SegmentDetailedFromObject(segment:string, subSegment:string):SegmentDetailed{
    return  Object.values(SegmentDetailed).find(segmentValue => segmentValue === segment+" "+subSegment) || SegmentDetailed.C5_CU
}

export function isSegmentDoublePrice(segment: SegmentDetailed): boolean{
    switch (segment.toString()){
        case SegmentDetailed.C5_CU:
        case SegmentDetailed.C5_MUDT:
            return true
        default:
            return false
    }
}

export function isC5(segment:SegmentDetailed):boolean {
    return segment.toString().startsWith('C5')
}