import React from "react";
import TitleText from "../../../components/assets/text/TitleText";
import SoutiragePointsTable from "../../table/SoutiragePointsTable";
import DescriptionText from "../../../components/assets/text/DescriptionText";
import PerimeterMap from "./PerimeterMap";
import ConsumptionPointForm from "./form/ConsumptionPointForm";


export default function Index() {
    return <div className="w-full h-full flex flex-col bg-gray-100 font-sans overflow-auto dark:bg-zinc-700">
        <div className="transform-none  2xl:transform-none mx-4 2xl:mx-8 scale-95 2xl:scale-100 ">
            <span id="top"/>
            <TitleText title={"Typologie - Points de soutirage"}/>
            <DescriptionText
                description={"Ajouter des points de consommation à votre opération. Il est possible d'utiliser un profil type d'ENEDIS pour les consommateurs d'un C5."}/>
            <div className="flex flex-col">
                <SoutiragePointsTable/>
                <PerimeterMap/>
                <ConsumptionPointForm/>
            </div>
        </div>
    </div>
}

