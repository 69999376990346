import {OpexEntries} from "./OpexEntries";
import {ProductionType, productionTypeFromObject} from "./ProductionType";
import {PhotovoltaicModel} from "./PhotovoltaicModel";
import {CapexEntries} from "./CapexEntries";
import {AddressGouv} from "../external-api/AddressGouv";
import {Loan} from "./Loan";

export class InjectionPoint {
    private _id : string;
    private _name : string;
    private _productionType : ProductionType;
    private _segment : string;
    private _generatedKwhPrice : string;
    private _maximumProductionPower : number;
    private _opexEntries: OpexEntries[] | null;
    private _capex: CapexEntries | null;
    private _loadCurveId : string;
    private _photovoltaicModel : PhotovoltaicModel | null
    private _state:boolean
    private _isVATRecoverable:boolean
    private _address : AddressGouv | null


    constructor(id: string, name: string, productionType: ProductionType, segment: string, generatedKwhPrice: string, maximumProductionPower: number, opexEntries: OpexEntries[] | null, capex: CapexEntries| null, loadCurveId: string, photovoltaicModel : PhotovoltaicModel | null, state : boolean, isVATRecoverable:boolean, address : AddressGouv |null) {
        this._id = id;
        this._name = name;
        this._productionType = productionType;
        this._segment = segment;
        this._generatedKwhPrice = generatedKwhPrice;
        this._maximumProductionPower = maximumProductionPower;
        this._opexEntries = opexEntries;
        this._capex = capex;
        this._loadCurveId = loadCurveId;
        this._photovoltaicModel = photovoltaicModel;
        this._state = state;
        this._isVATRecoverable = isVATRecoverable;
        this._address = address;
    }
    //value is not a valid enumeration member; permitted: 'photovoltaics', 'co-generation', 'wind-turbine', 'hydroelectric', 'geothermal'

    static fromObject(updatedInjPoint: any): InjectionPoint{
        return new this(
            updatedInjPoint.id,
            updatedInjPoint.name.trim().replace("  ", " "),
            productionTypeFromObject(updatedInjPoint.production_type),
            updatedInjPoint.segment,
            updatedInjPoint.generated_kwh_price,
            updatedInjPoint.maximum_production_power,
            updatedInjPoint.opex_entries && updatedInjPoint.opex_entries.length > 0
                ? updatedInjPoint.opex_entries.map((opexEntrie: any) => {
                    return new OpexEntries(updatedInjPoint.id, opexEntrie.name.trim(), opexEntrie.opex, opexEntrie.opex_average_inflation);
                })
                : null,
            updatedInjPoint.capex ? new CapexEntries(updatedInjPoint.capex.capex, updatedInjPoint.capex.loan ?
                new Loan(updatedInjPoint.capex.loan.price, updatedInjPoint.capex.loan.duration, (Number(updatedInjPoint.capex.loan.rate) * 100).toFixed(2)) : null
            ) : null,
            updatedInjPoint.load_curve_id,
            null,
            updatedInjPoint.is_active,
            updatedInjPoint.is_vat_recoverable,
            updatedInjPoint.address ? new AddressGouv(
                updatedInjPoint.address.label,
                updatedInjPoint.address.latitude,
                updatedInjPoint.address.longitude,
                updatedInjPoint.address.context,
                updatedInjPoint.address.name,
                updatedInjPoint.address.type,
                updatedInjPoint.address.city,
                updatedInjPoint.address.postcode) : null
        )
    }
    serialize(){
        return {
            id :this._id,
            name :this._name.trim().replace("  ", " "),
            production_type : this.serializeProductionType(this._productionType),
            segment : this._segment,
            generated_kwh_price : this._generatedKwhPrice,
            maximum_production_power : this._maximumProductionPower,
            opex_entries : this._opexEntries?.map((opexEntrie)=>{
                return opexEntrie.serialize()
            } ),
            capex : this._capex?.serialize(),
            load_curve_id : this._loadCurveId,
            is_active : this._state,
            is_vat_recoverable:this._isVATRecoverable,
            address : this._address?.serialize()
        }
    }


    get address(): AddressGouv | null {
        return this._address;
    }

    set address(value: AddressGouv | null) {
        this._address = value;
    }

    get isVATRecoverable(): boolean {
        return this._isVATRecoverable;
    }

    set isVATRecoverable(value: boolean) {
        this._isVATRecoverable = value;
    }

    get state(): boolean  {
        return this._state;
    }

    set state(value: boolean ) {
        this._state = value;
    }

    serializeProductionType(productionType : ProductionType){
        switch(productionType){
            case ProductionType['co-generation']: return 'co-generation';
            case ProductionType['geothermal']:return 'geothermal';
            case ProductionType['hydroelectric']:return 'hydroelectric';
            case ProductionType['photovoltaics']:return 'photovoltaics';
            case ProductionType['wind-turbine']:return 'wind-turbine';
            default :break;
        }
    }
    get id(): string {
        return this._id;
    }

    set id(value: string) {
        this._id = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get productionType(): ProductionType {
        return this._productionType;
    }

    set productionType(value: ProductionType) {
        this._productionType = value;
    }

    get photovoltaicModel(): PhotovoltaicModel | null {
        return this._photovoltaicModel;
    }

    set photovoltaicModel(value: PhotovoltaicModel | null) {
        this._photovoltaicModel = value;
    }

    get segment(): string {
        return this._segment;
    }

    set segment(value: string) {
        this._segment = value;
    }

    get generatedKwhPrice(): string {
        return this._generatedKwhPrice;
    }

    set generatedKwhPrice(value: string) {
        this._generatedKwhPrice = value;
    }

    get maximumProductionPower(): number {
        return this._maximumProductionPower;
    }

    set maximumProductionPower(value: number) {
        this._maximumProductionPower = value;
    }


    set opexEntries(value: OpexEntries[] | null) {
        this._opexEntries = value;
    }


    get opexEntries(): OpexEntries[] | null {
        return this._opexEntries;
    }

    get capexEntries(): CapexEntries | null {
        return this._capex;
    }

    set capexEntries(value: CapexEntries | null) {
        this._capex = value;
    }

    get loadCurveId(): string {
        return this._loadCurveId;
    }

    set loadCurveId(value: string) {
        this._loadCurveId = value;
    }
}
