import withConditionalRender from "../../../../wrapper/withConditionalRender";
import {
    selectFullCompletudeButton
} from "../../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import React from "react";
import ApplyFullCompletudeButton from "./ApplyFullCompletudeButton";

function FullCompletudeForm() {
    return <div
        className={'flex bg-orange-200 dark:text-orange-200 dark:bg-orange-700 dark:bg-opacity-20 rounded px-4 py-3 gap-2 w-full'}>
        <div className={"flex flex-col"}>
            <Title/>
            <Explanation/>
        </div>
        <div className={"flex w-full gap-2 items-center justify-end"}>
            <ApplyFullCompletudeButton/>
        </div>
    </div>
}

const Title = () => <span
    className={"text-orange-600 dark:text-orange-300 w-full text-sm font-bold overflow-hidden whitespace-nowrap"}>Complétude annuelle</span>
const Explanation = () => <span
    className={"text-orange-600 dark:text-orange-300  opacity-80 w-full text-xs font-semibold overflow-hidden whitespace-nowrap"}>Complétez vos courbes de charge dont quelques données sont manquantes.</span>


export default withConditionalRender(FullCompletudeForm, selectFullCompletudeButton)



