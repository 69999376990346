
const selectedSwitchStyle = `hover:cursor-pointer text-xs dark:bg-blue-900 dark:text-blue-300 bg-blue-200 text-blue-600 border border-blue-500  font-bold   h-full   flex gap-2 items-center justify-center  px-2 tracking-wide`
const nonSelectedSwitchStyle = `text-xs dark:border-zinc-600 dark:bg-zinc-800 dark:hover:bg-zinc-700 dark:text-zinc-300 dark:hover:text-zinc-200  gap-2 bg-slate-50 border border-slate-300  font-regular whitespace-nowrap min-w-min  h-full flex items-center tracking-wide justify-center text-slate-400 px-2 hover:text-slate-600 hover:bg-slate-100 hover:cursor-pointer`

type Props = {
    option: string,
    action: (option: string) => void,
    Icon:JSX.Element,
    selected: boolean,
}

export default ({option, action,Icon, selected}: Props) => {
    return <div onClick={() => action(option)}
                 className={ (selected ? selectedSwitchStyle: nonSelectedSwitchStyle) + ' rounded-lg'}>{option}
                {Icon}
            </div>
}
