import React from "react";
import {EnolabColors} from "../../../../../config/app-config";
import {useSelector} from "react-redux";
import {selectIsDarkMode} from "../../../../../corelogic/usecases/config/configSelector";

export default function Divider({color, twHeight}:{color?:EnolabColors, twHeight?:string}){
    const height = twHeight ? twHeight : ' h-px mt-4 mb-2 '
    const isDarkMode = useSelector(selectIsDarkMode)
    return <hr
        style={color && {
            backgroundColor :isDarkMode ?  color["400"] : color["300"]
        }}
        className={" w-full bg-gray-300 border-0 dark:bg-gray-400 " + height}/>
}
