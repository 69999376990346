import {loaderActionsType, RESET_SORTING_TAG, SET_QUESTIONS, SET_SORTING_SEARCH, SET_SORTING_TAG,} from "./faqActions";
import {AllQuestions, Question} from "../../../adapters/primary/ui/composition/faq/AllQuestions";

type FAQState = {
    questions : Question[],
    search : string,
    tag : string | null
}
const initialState: FAQState = {
    questions : AllQuestions(),
    search : '',
    tag : null,
}

const FAQReducer = (state = initialState, action: loaderActionsType) => {
    switch (action.type) {
        case SET_QUESTIONS : return {...state, questions : action.payload};

        case SET_SORTING_SEARCH : return {...state, search : action.payload};
        case SET_SORTING_TAG : return  {...state, tag : action.payload}

        case RESET_SORTING_TAG : return  {...state, tag : null}

        default:
            return state;
    }
}

export default FAQReducer;



