export enum StatusEnum {
    'REJECTED',
    'ONE_WEEK_COMPLETE',
    'STANDARD_COMPLETION_COMPLIANT',
    'VALID',
    'COMPLETED_6_MONTH'
}

export type Measurement = {
    timestamp: string,
    value: (number | null)
}
export type Hole = {
    dateStart: string,
    dateEnd: string
}
type EchartsHole = [
    {
        xAxis: string, //"2022-01-01T00:30:00+00:00"
    },
    {
        xAxis: string //"2022-10-01T00:30:00+00:00"
    }
]

export class Curve {
    // definitive-curve : (id, measurement, maxPower)
    // undefinitive-curve : (measurement, maxPower, status, maxNbOfDaysWithContiguousData, stepInMinutes, dataCoversTargetYearWith30mnSteps)

    id: string | null;
    measurement: Measurement[];
    maxPower: number;
    totalEnergy : number;
    status: StatusEnum;
    maxNbOfDaysWithContiguousData: number;
    stepInMinutes: number;
    dataCoversTargetYearWith30mnSteps: boolean;
    holes: Hole[] = []

    constructor(id: string | null = null, measurement: Measurement[], maxPower: number, totalEnergy : number,status: StatusEnum = StatusEnum.VALID, maxNbOfDaysWithContiguousData: number = 365, stepInMinutes: number = 30, dataCoversTargetYearWith30mnSteps: boolean = true) {
        this.id = id;
        this.measurement = measurement
        this.maxPower = maxPower;
        this.totalEnergy = totalEnergy;
        this.status = status;
        this.maxNbOfDaysWithContiguousData = maxNbOfDaysWithContiguousData;
        this.stepInMinutes = stepInMinutes;
        this.dataCoversTargetYearWith30mnSteps = dataCoversTargetYearWith30mnSteps;
        this.calculateStepLengthInMinutes()
        this.calculateHoles()
        this.addHolesToMeasurement()
    }


    serialize(): Curve {
        this.deleteHolesFromMeasurement()
        return this
    }

    isDefinitive(): boolean {
        return typeof this.id === 'string'
    }

    deleteHolesFromMeasurement() {

        if (this.stepInMinutes && this.holes.length > 0) {
            this.holes.forEach((hole: Hole) => {
                let indexesToDelete: number[] = []
                this.measurement.forEach((measure: Measurement, index: number) => {
                    const limitLowDate = new Date(hole.dateStart).toISOString()
                    const limitUpDateUTC = new Date(hole.dateEnd).toISOString()
                    const dateUTC = new Date(measure.timestamp).toISOString()
                    const isLaterThanHoleStart = dateUTC.localeCompare(limitLowDate) > 0
                    const isEarlierThanHoleEnd = dateUTC.localeCompare(limitUpDateUTC) < 0
                    if (isLaterThanHoleStart && isEarlierThanHoleEnd) {
                        indexesToDelete.push(index)
                    }
                })
                this.measurement.splice(indexesToDelete[0], indexesToDelete.length)
            })
        }
    }

    addHolesToMeasurement() {
        if (this.stepInMinutes && this.holes.length > 0) {
            this.holes.forEach((hole: Hole) => {
                this.measurement.forEach((measure: Measurement, index: number) => {
                    if (measure.timestamp === hole.dateStart) {
                        const holesObjects: Measurement[] = this.generateHoles(hole.dateStart, hole.dateEnd, this.stepInMinutes)
                        this.measurement.splice(index + 1, 0, ...holesObjects)
                    }
                })
            })
        }
    }

    generateHoles(startDateStr: string, endDateStr: string, stepLengthInMinutes: number): { timestamp: string, value: null }[] {
        const startDate = new Date(startDateStr);
        const endDate = new Date(endDateStr);

        const timestampObjects: { timestamp: string, value: null }[] = [];

        const oneStepInMilliSeconds = stepLengthInMinutes * 60 * 1000
        let currentTimestamp = startDate.getTime() + oneStepInMilliSeconds;
        const endTimestamp = endDate.getTime();

        while (currentTimestamp < endTimestamp) {
            timestampObjects.push({timestamp: new Date(currentTimestamp).toISOString(), value: null});
            currentTimestamp += oneStepInMilliSeconds; // Convert stepLengthInMinutes to milliseconds
        }

        return timestampObjects;
    }

    calculateHoles() {
        let holes: Hole[] = []

        this.measurement.map((measure: Measurement, index: number) => {
            if (index !== this.measurement.length - 1) {
                if (this.getDateDifferenceInMinutes(this.measurement[index].timestamp, this.measurement[index + 1].timestamp) > (this.stepInMinutes === 60 ? 60 : 30)) {
                    holes.push({
                        dateStart: this.measurement[index].timestamp,
                        dateEnd: this.measurement[index + 1].timestamp
                    })
                }
            }
        })
        this.holes = holes
    }

    calculateStepLengthInMinutes() {
        let minStepLength: number = 3600
        this.measurement.forEach((measure: Measurement, index: number) => {
            if (index !== this.measurement.length - 1) {
                const dateDifference = this.getDateDifferenceInMinutes(this.measurement[index].timestamp, this.measurement[index + 1].timestamp)
                if (dateDifference < minStepLength) minStepLength = dateDifference
            }
        })
        this.stepInMinutes = minStepLength
    }

    getDateDifferenceInMinutes(dateStr1: string, dateStr2: string): number {
        const date1 = new Date(dateStr1);
        const date2 = new Date(dateStr2);

        // Calculate the difference in milliseconds
        const timeDifferenceInMilliseconds = date2.getTime() - date1.getTime();
        // Convert milliseconds to minutes
        const minutesDifference = timeDifferenceInMilliseconds / (1000 * 60);
        return Math.abs(minutesDifference);
    }

    get timestamp(): string[] {
        return this.measurement.map((measurement) => measurement.timestamp);
    }

    get power(): (number | null)[] {
        return this.measurement.map((measurement) => measurement.value);
    }

    toEchartsHole(): EchartsHole[] {
        if (this.holes.length === 0) return []
        return this.holes.map((hole: Hole) => {
            return [{xAxis: hole.dateStart}, {xAxis: hole.dateEnd}]
        })
    }
}
