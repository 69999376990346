import {OperationType} from "./OperationType";

export class GeneralInfo {


    private _name: string;
    private _postcode: string;
    private _typeOfOperation :keyof typeof OperationType | null

    constructor(name: string, postcode: string, typeOfOperation :keyof typeof OperationType | null) {
        this._name = name;
        this._postcode = postcode;
        this._typeOfOperation = typeOfOperation;
    }

    static fromObject(generalInformation:any):GeneralInfo {
        return new this(generalInformation.name, generalInformation.postcode, generalInformation.operation_type )
    }

    serialize(){
        return {
            name :this._name,
            postcode :this._postcode,
            operation_type : this._typeOfOperation,
        }
    }



    get typeOfOperation(): keyof typeof OperationType | null {
        return this._typeOfOperation;
    }

    set typeOfOperation(value: keyof typeof OperationType | null) {
        this._typeOfOperation = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get postcode(): string {
        return this._postcode;
    }

    set postcode(value: string) {
        this._postcode = value;
    }


}
