export default class FlatPeakOffPeakPricing {
    private _offPeakProviderKwhPrice  :number;
    private _peakKwhProviderPrice : number;

    constructor(offPeakProviderKwhPrice: number, peakKwhProviderPrice: number) {
        this._offPeakProviderKwhPrice = offPeakProviderKwhPrice;
        this._peakKwhProviderPrice = peakKwhProviderPrice;
    }
    serialize(){
        return {
            off_peak_provider_kwh_price: this._offPeakProviderKwhPrice,
            peak_kwh_provider_price: this._peakKwhProviderPrice
        }
    }
    get offPeakProviderKwhPrice(): number {
        return this._offPeakProviderKwhPrice;
    }

    set offPeakProviderKwhPrice(value: number) {
        this._offPeakProviderKwhPrice = value;
    }

    get peakKwhProviderPrice(): number {
        return this._peakKwhProviderPrice;
    }

    set peakKwhProviderPrice(value: number) {
        this._peakKwhProviderPrice = value;
    }
}