import {put, takeLatest} from "redux-saga/effects";
import {authenticationActions} from "../authentication/authenticationActions";
import {errorsActions, HANDLE_ERROR} from "./errorsActions";
import {push} from "connected-react-router";
import {HANDLE_SNACKBA_ERROR, snackBarErrorsActions} from "./snackbarErrorsActions";


function* handleError(action: any) {
    const {payload: error} = action
    if(error.response) {
        if(error?.response?.status === 401) {
            yield put(authenticationActions.setUser(null))
            yield put(push('/login'))
        }
        yield put(errorsActions.setError(error))
    }
}

function* handleSnackbarError(action: any) {
    const {payload: error} = action
    if(error.response) {
        if(error?.response?.status === 401) {
            yield put(authenticationActions.setUser(null))
            yield put(push('/login'))
        } else {
            yield put(snackBarErrorsActions.setError(error))
        }
    }
}

export function* handleErrorSaga() {
    yield takeLatest(HANDLE_ERROR, handleError);
}

export function* handleSnackbarErrorSaga() {
    yield takeLatest(HANDLE_SNACKBA_ERROR, handleSnackbarError);
}

const errorsSagas = [handleErrorSaga, handleSnackbarErrorSaga]
export default errorsSagas;