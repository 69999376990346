import EmptyTable from "./EmptyTable";
import React, {Fragment, useCallback, useEffect, useState} from "react";
import {InjectionPoint} from "../../../../../corelogic/models/types/new-simulation/form/InjectionPoint";
import {useDispatch, useSelector} from "react-redux";
import {newSimulationActions} from "../../../../../corelogic/usecases/new-simulation/newSimulationActions";
import {XIconRed} from "../../components/assets/icon/XIcon";
import CDCPrevisuChart from "../../components/assets/graphic/CDCPrevisuChart";
import {useSnackbar} from "notistack";
import {ConsumptionPoint} from "../../../../../corelogic/models/types/new-simulation/form/ConsumptionPoint";
import RainbowLoader from "../../components/assets/Animated/RainbowLoader";
import {SmallToggle} from "../../components/assets/toggle/Toggle";
import ConfirmModal from "../confirmation-dialog/ConfirmModal";
import ChevronDown from "../../components/assets/icon/ChevronDown";
import ChevronUp from "../../components/assets/icon/ChevronUp";
import Notification from "../paper/Notification";
import {amber, blue, State, yellow, zinc} from "../../../../../config/app-config";
import TableRowBadge from "../../components/assets/table/TableRowBadge";
import {MapPinIconSolid, MapPinIconSolidDisabled} from "../../components/assets/icon/MapPinIcon";
import {TrashIconMini} from "../../components/assets/icon/TrashIcon";
import {
    selectPointBeingEdited,
    selectPointEditedIndex
} from "../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import {typologyFormActions} from "../../../../../corelogic/usecases/typology-form/typologyFormActions";
import {selectIsLoadingSimulationForm} from "../../../../../corelogic/usecases/loader/loaderSelector";
import {
    selectConsumptionPoints
} from "../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectFinancialParameter";
import {
    selectCurves
} from "../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-curve/selectFormCurve";
import {
    makeSelectInjectionPointsSorted,
    selectInjectionPointsHaveDuplicatedNames
} from "../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectInjectionPointForm";

type InjectionPointTableType = {
    clearForm(): void,
}
export default function ({clearForm,
                         }: InjectionPointTableType) {
    const dispatch = useDispatch()
    const {enqueueSnackbar} = useSnackbar()
    const consumptionPoints = useSelector(selectConsumptionPoints)
    const [expand, setExpand]: any[] = useState([])
    const [loadingCurveIndex, setLoadingCurveIndex] = useState<number | null>(null)
    const loadedCurves = useSelector(selectCurves)
    const [dialog, setDialog] = useState<{ state: boolean, indexToApply: number }>({state: false, indexToApply: -1})
    const [deletePointDialog, setDeletePointDialog] = useState<{ state: boolean, indexToApply: number }>({state: false, indexToApply: -1})
    const [sorting, setSorting] = useState({column: '', asc: true})
    const selectSortInjectionPoints = useCallback(makeSelectInjectionPointsSorted(sorting), [sorting])
    const injectionPoints = useSelector(selectSortInjectionPoints)
    const isFormLoading = useSelector(selectIsLoadingSimulationForm)
    const injectionPointFormHaveDuplicatedNames = useSelector(selectInjectionPointsHaveDuplicatedNames)
    const rowToEdit = useSelector(selectPointBeingEdited)
    const indexOnEdit = useSelector(selectPointEditedIndex)


    useEffect(() => {
        if (!rowToEdit) {
            dispatch(typologyFormActions.setEditPoint(null))
            dispatch(typologyFormActions.setEditPointIndex(null))
            clearForm()
        }
    }, [rowToEdit])

    function handleExpand(index: number) {
        let newExpand = [...expand]
        if (!expand[index]) {
            newExpand[index] = 1
        } else {
            newExpand[index] = 0
        }
        setLoadingCurveIndex(index);
        dispatch(newSimulationActions.loadCurve(injectionPoints[index].loadCurveId))
        setExpand(newExpand)
    }

    function handleCheck(index: number) {
        if (consumptionPoints.some((consumptionPoint) => consumptionPoint.injectionPointId === injectionPoints[index].id && consumptionPoint.state)) {
            setDialog({state: true, indexToApply: index})
        } else {
            dispatch(newSimulationActions.setPointState(injectionPoints[index].id, !injectionPoints[index].state))
        }
    }

    function rowClassName(index: number) {
        let checkStateStyle: string = !injectionPoints[index].state ? " text-opacity-60 bg-opacity-60 bg-zinc-100 hover:bg-zinc-100 dark:bg-zinc-600 dark:hover:bg-zinc-600" : ""
        let indexStateStyle: string = indexOnEdit === index ? " text-gray-600 dark:text-zinc-400 border-2 bg-blue-300 dark:border-0 dark:bg-slate-800 w-full border-blue-400" : " text-gray-600 dark:text-zinc-400 border-b border-gray-200 w-full hover:bg-gray-100 dark:hover:bg-zinc-600"
        return indexStateStyle + checkStateStyle
    }

    function handleSorting(column: string) {
        setSorting({
            column: (sorting.column === column && !sorting.asc) ? '' : column,
            asc: !(sorting.column === column && sorting.asc)
        })
    }

    useEffect(() => {
        if (!dialog.state && dialog.indexToApply !== -1) {
            let injectionPointToDisable: InjectionPoint = injectionPoints[dialog.indexToApply]
            injectionPointToDisable.state = false
            dispatch(newSimulationActions.updateInjectionPoint(injectionPointToDisable))
            setDialog({state: false, indexToApply: -1})
        }
    }, [dialog])

    function handleDesactivatePoint() {
        let consumptionPointToRemoveACI: ConsumptionPoint[] = consumptionPoints.filter((consumptionPoint) => consumptionPoint.injectionPointId === injectionPoints[dialog.indexToApply].id && consumptionPoint.state)
        if (consumptionPointToRemoveACI.length === 1) {
            consumptionPointToRemoveACI[0].injectionPointId = null
            dispatch(newSimulationActions.updateConsumptionPoint(consumptionPointToRemoveACI[0]))
        }
        setDialog({state: false, indexToApply: dialog.indexToApply})
    }
    function handleConfirmDeleteRow() {
        const index = deletePointDialog.indexToApply
        const pointLinked: string | null = pointIsLinkedToConsumptionPointOrFinancialParams(injectionPoints[index])
        if (pointLinked) {
            enqueueSnackbar("Le point que vous essayez de supprimer est lié à un " + pointLinked + ". Veuillez le(s) supprimer avant de retirer le point de la table.", {variant: 'warning'})
        } else {

            const pointNameToDelete: string = injectionPoints[index].name
            dispatch(newSimulationActions.removeInjectionPoint(injectionPoints[index]))
            enqueueSnackbar("Le point " + pointNameToDelete + " a été supprimé.", {variant: 'info'})
        }
        setDeletePointDialog({state: false, indexToApply: deletePointDialog.indexToApply})
    }

    function onEditActionChange(index: number) {
        if (indexOnEdit === index) {
            return <div className="flex items-right items-end justify-center ">
                <span title={"Annuler"} onClick={() => handleCancelEditRow()}
                      className={"flex text-xs text-red-500 items-center font-semibold hover:cursor-pointer hover:scale-110"}>
                    Annuler
                    <div className="icon">
                        <XIconRed/>
                    </div>
                </span>
            </div>
        } else {
            return <div className="flex items-left ">
                <span title={"Editer le point"} className={" icon " + " hover:cursor-pointer "} onClick={() => handleEditRow(index)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"/>
                    </svg>
                </span>
                <span title={"Supprimer le point"}  onClick={() => setDeletePointDialog({state : true, indexToApply: index})}>
                    <TrashIconMini className={" icon " + " hover:cursor-pointer "}/>
                </span>
                <span>
                    {injectionPoints[index].address ?
                        <div title={"Adresse indiquée : " + injectionPoints[index].address.label}>
                            <MapPinIconSolid className={" icon " + " hover:cursor-help "}/>
                        </div>
                        : <div title={"pas d'adresse renseignée"}>
                            <MapPinIconSolidDisabled  className={" icon " +" hover:cursor-help "}/>
                        </div>
                    }
                </span>
                <span title={!expand[index] ? "Montrer la courbe de charge" : "Cacher la courbe de charge"}
                      className={" icon " + " hover:cursor-pointer "} onClick={() => handleExpand(index)}>
                    {!expand[index] ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                           stroke="currentColor" strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7"/>
                        </svg> :
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor" strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M5 15l7-7 7 7"/>
                        </svg>
                    }
                </span>

                <span className={"ml-2"}
                      title={!injectionPoints[index].state ? "Activer le point" : "Désactiver le point"}>
                    <SmallToggle checked={injectionPoints[index].state} action={() => handleCheck(index)}/>
                </span>
            </div>
        }
    }

    function pointIsLinkedToConsumptionPointOrFinancialParams(injectionPoint: InjectionPoint): string | null {
        let link: string | null = null
        if (consumptionPoints.some((consumptionPoint: ConsumptionPoint) => consumptionPoint.injectionPointId === injectionPoint.id)) {
            link = "point de soutirage"
        }
        if (injectionPoint.opexEntries || injectionPoint.capexEntries) {
            link = link ? link + " et à au moins un paramètre financier" : "au moins un paramètre financier"
        }
        return link
    }



    function handleCancelEditRow() {
        dispatch(newSimulationActions.setNewCurve(null))
        dispatch(typologyFormActions.setEditPoint(null))
        dispatch(typologyFormActions.setEditPointIndex(null))
        clearForm()
    }

    function handleEditRow(index: number) {
        dispatch(typologyFormActions.setEditPoint(injectionPoints[index]))
        dispatch(typologyFormActions.setEditPointIndex(index))
    }

    return <>
        {
            deletePointDialog.state &&
            <ConfirmModal
                title={"Supprimer un point d'injection"}
                description={"Le point d'injection (" + injectionPoints[deletePointDialog.indexToApply].name + ") sera supprimé du formulaire et des résultats de simulation."}
                actionContinue={() => handleConfirmDeleteRow()}
                actionCancel={()=>setDeletePointDialog({state : false, indexToApply : -1})}
                isLoading={isFormLoading}
            />
        }
        {
            dialog.state &&
            <ConfirmModal title={"Désactiver un point d'injection lié en ACI"}
                          description={"Le point d'injection (" + injectionPoints[dialog.indexToApply].name + ") est lié en ACI avec un point de soutirage. Le désactiver va également enlever le lien entre ce point et le point de soutirage."}
                          actionContinue={() => handleDesactivatePoint()}
                          actionCancel={() => setDialog({state: false, indexToApply: -1})}/>
        }
        <div className={"box-border mx-4 shadow-md mt-8 "}>
            {injectionPointFormHaveDuplicatedNames && <Notification state={State.ERROR}
                                                                    text={"Attention, il y a parmi vos points d'injection des doublons dans les noms. Veuillez éditer le nom ou supprimer le point posant problème."}/>}
            <table data-cy={"typology-injection-table"} className="w-full table-auto   h-min">
                <thead>
                <tr className="bg-gray-200 text-gray-600 text-sm leading-normal">
                    <th onClick={() => handleSorting('name')}
                        className="py-3 pl-3 text-left hover:cursor-pointer hover:text-gray-500 flex gap-2">
                        Nom
                        <SortIcon focused={sorting.column === "name"} asc={sorting.asc}/>
                    </th>
                    <th className="py-3 px-0 text-left">Type</th>
                    <th onClick={() => handleSorting('segment')}
                        className=" flex gap-2 py-3 px-0 text-left hover:cursor-pointer hover:text-gray-500">
                        Segment
                        <SortIcon focused={sorting.column === "segment"} asc={sorting.asc}/></th>
                    <th className="py-3 px-0 text-left overflow-ellipsis overflow-hidden  whitespace-nowrap">Valorisation
                        surplus (€ HT/kWh)
                    </th>
                    <th className="py-3 px-0 text-left overflow-ellipsis overflow-hidden  whitespace-nowrap">Puissance
                        installée (kW)
                    </th>
                    <th className="py-3 px-0 text-left overflow-ellipsis   whitespace-nowrap"><span>TVA</span>
                    </th>
                    <th className="py-3 px-0 text-left">Actions</th>
                </tr>
                </thead>
                <tbody className=" text-sm font-light  ">
                {
                    injectionPoints.length > 0 &&
                    injectionPoints.map((point: InjectionPoint, index: number) => {
                        return (<Fragment>
                            <tr id={"injection" + index} className={rowClassName(index)}>
                                <td className="py-2 pl-3  text-left font-medium">
                                    <div className="flex items-left">
                                    <span
                                        className={"overflow-ellipsis overflow-hidden  whitespace-nowrap max-w-[150px]"}
                                        title={point.name}>{point.name}</span>
                                    </div>
                                </td>
                                <TableRowBadge
                                    text={point.productionType}
                                    color={blue}
                                    disabled={!injectionPoints[index].state}
                                />
                                <TableRowBadge
                                    text={point.segment}
                                    color={zinc}
                                    disabled={!injectionPoints[index].state}
                                />
                                <td className="py-2 px-0  text-left">
                                    <span>{point.generatedKwhPrice}</span>
                                </td>
                                <td className="py-2 px-0  text-left">
                                    <div className="flex items-left">
                                        <span>{point.maximumProductionPower}</span>
                                    </div>
                                </td>
                                <TableRowBadge
                                    color={point.isVATRecoverable ? yellow : amber}
                                    text={point.isVATRecoverable ? '€ HT ' : '€ TTC'}
                                    disabled={!injectionPoints[index].state}
                                    helpText={point.isVATRecoverable ? "La récupération de la TVA est activée pour ce point." : "La récupération de la TVA n'est pas activée pour ce point."}/>
                                <td className="py-2 px-0  text-left">

                                    {onEditActionChange(index)}
                                </td>
                            </tr>
                            {expand && expand[index] === 1 ?
                                <tr>
                                    <td colSpan={6} className={"w-full"}>
                                        {loadedCurves[point.loadCurveId]?.loading && index === loadingCurveIndex ?
                                            <RainbowLoader text={"Chargement"}/> :
                                            <CDCPrevisuChart color="rgb(243,182,23)"
                                                             data={loadedCurves[point.loadCurveId]?.curve}/>}
                                    </td>
                                </tr>
                                :
                                <Fragment/>
                            }
                        </Fragment>)
                    })
                }

                </tbody>
            </table>

            {injectionPoints.length === 0 && <EmptyTable title={"Aucun points d'injection"}
                                                         description={"Vous n'avez pas encore ajouté de points d'injection."}/>}
        </div>
    </>
}


export function SortIcon({focused, asc}: { focused: boolean, asc: boolean }) {
    if (!focused) return <ChevronDown classname={'h-5 w-5 opacity-40'}/>
    if (asc) {
        return <ChevronDown classname={'h-5 w-5'}/>
    } else {
        return <ChevronUp classname={'h-5 w-5'}/>
    }
}



