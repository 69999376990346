export class AddressGouv {

    constructor(
        public readonly label: string,
        public readonly latitude: number,
        public readonly longitude: number,
        public readonly context: string,
        public readonly name: string,
        public readonly type: string,
        public readonly city: string,
        public readonly postcode: string) {
    }

    serialize() {
        return {
            label: this.label,
            latitude: this.latitude,
            longitude: this.longitude
        }
    }

}

