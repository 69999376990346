import DiameterSelector from "./DiameterSelector";
import ToggleDensityMapView from "../ToggleDensityMapView";
import React from "react";
import DensityPerimeterInfo from "./DensityPerimeterInfo";
import {useSelector} from "react-redux";
import {
    selectCartoCircleInKm,
    selectShowCartoDensity
} from "../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import Enoptimize from "./Enoptimize";
import {selectIsRoleEnogrid} from "../../../../../../../corelogic/usecases/authentication/authenticationSelector";

export default function LeafletControls() {
    const showDensity = useSelector(selectShowCartoDensity)
    const isRoleEnogrid = useSelector(selectIsRoleEnogrid)
    const isEnoptiEnabled = process.env.REACT_APP_ENOPTI_ENABLED === "true"
    const [expandPerimeterInfo, setExpandPerimeterInfo] = React.useState(true)

    return <div className={"relative h-0 w-0"}>
        <div className={"flex flex-col gap-2"}>
            <div
                className={"flex ShadowL bg-opacity-80 flex-col  bg-gray-50 p-3 gap-2 relative w-[650px] left-12 top-2 rounded z-[500]"}>
                <DiameterSelector/>
                <ToggleDensityMapView/>
                {isEnoptiEnabled && isRoleEnogrid && <Enoptimize/>}
            </div>
            {showDensity && <DensityPerimeterInfo
                expandPerimeterInfo={expandPerimeterInfo}
                setExpandPerimeterInfo={setExpandPerimeterInfo}/>}
        </div>
    </div>
}
