import TitleText from "../../../components/assets/text/TitleText";
import React, {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {newSimulationActions} from "../../../../../../corelogic/usecases/new-simulation/newSimulationActions";
import {FinancialParameters} from "../../../../../../corelogic/models/types/new-simulation/form/FinancialParameters";
import {CapexEntries} from "../../../../../../corelogic/models/types/new-simulation/form/CapexEntries";
import {InjectionPoint} from "../../../../../../corelogic/models/types/new-simulation/form/InjectionPoint";
import {OpexEntries} from "../../../../../../corelogic/models/types/new-simulation/form/OpexEntries";
import {
    selectFormCapexes,
    selectFormCapexesLoanAreValid,
    selectFormDuration,
    selectFormDurationIsValid,
    selectFormFixedTaxValue,
    selectFormOpexes,
    selectFormOpexesAreValid,
    selectFormSellingPrice,
    selectFormVariableTaxValue,
    selectHasInflationChanged,
    selectInflation,
    selectInflationIsValid,
    selectSellingPriceIsValid,
    selectVariableInflations,
    selectVariableInflationsToggled
} from "../../../../../../corelogic/usecases/form/formSelector";
import {ConsumptionPoint} from "../../../../../../corelogic/models/types/new-simulation/form/ConsumptionPoint";
import SellingPriceTableWithFunction from "../../wrapper/SellingPriceTableWithFunction";
import CapexTable from "./capex/CapexTable";
import OpexTable, {OpexFormType} from "./opex/OpexTable";
import {formActions} from "../../../../../../corelogic/usecases/form/formActions";
import InflationInput from "./inflation/InflationInput";
import DurationInput from "./duration/DurationInput";
import VariableVATRate from "./vat-rate/variable-vat-rate/VariableVATRate";
import FixedVATRate from "./vat-rate/majo-turpe-vat-rate/FixedVATRate";
import {makePercentNumberIsValid} from "../../../../../../corelogic/usecases/new-simulation/ValidatorsSelector";
import {FormCapex} from "./capex/FormCapex";
import {capexesHaveChanged, validateAllCapexes} from "./capex/FormCapex.utils";
import {
    selectClosingForm,
    selectSavingForm
} from "../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-action/selectFormAction";
import {
    selectCapexes,
    selectComplementProviderInflationRate,
    selectConsumptionPoints,
    selectFinancialDuration,
    selectFinancialParameters,
    selectOpexes
} from "../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectFinancialParameter";
import {
    selectNextStep
} from "../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/steps/selectSteps";
import {
    selectTypeOfOperation
} from "../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectGeneralInfoForm";
import {
    selectInjectionPoints
} from "../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectInjectionPointForm";


export default function FinancialParametersForm() {
    const duration = useSelector(selectFormDuration)
    const nextStep = useSelector(selectNextStep);
    const closingForm = useSelector(selectClosingForm)
    const storeFinancialParameters = useSelector(selectFinancialParameters)
    const storeDuration = useSelector(selectFinancialDuration)
    const storeInflation = useSelector(selectComplementProviderInflationRate)
    const storeOpexes = useSelector(selectOpexes)
    const storeCapexes = useSelector(selectCapexes)
    const injectionPoints: InjectionPoint[] = useSelector(selectInjectionPoints)
    const consumptionPoints: ConsumptionPoint[] = useSelector(selectConsumptionPoints)
    const sellingPrice: any = useSelector(selectFormSellingPrice)
    const sellingPriceIsValid = useSelector(selectSellingPriceIsValid)
    const typeOfOperation = useSelector(selectTypeOfOperation)
    const savingForm = useSelector(selectSavingForm)
    const variableInflationsForm = useSelector(selectVariableInflations)
    const variableInflationsToggled = useSelector(selectVariableInflationsToggled)

    const dispatch = useDispatch()
    const capexes: FormCapex[] = useSelector(selectFormCapexes)
    const opexes: OpexFormType[] = useSelector(selectFormOpexes)
    const opexesAreValid: boolean[] = useSelector(selectFormOpexesAreValid)

    const inflation = useSelector(selectInflation)
    const durationIsValid = useSelector(selectFormDurationIsValid)
    const inflationIsValid = useSelector(selectInflationIsValid)
    const inflationHasChanged = useSelector(selectHasInflationChanged)

    // vat rate
    const fixedVATRate = useSelector(selectFormFixedTaxValue)
    const variableVATRate = useSelector(selectFormVariableTaxValue)
    const capexesLoanAreValid = useSelector(selectFormCapexesLoanAreValid)

    // vat rate validators
    const selectFixedTaxIsValid = useCallback(makePercentNumberIsValid(fixedVATRate.toString()), [fixedVATRate])
    const selectVariableTaxIsValid = useCallback(makePercentNumberIsValid(variableVATRate.toString()), [variableVATRate])

    const fixedTaxIsValid = useSelector(selectFixedTaxIsValid)
    const variableTaxIsValid = useSelector(selectVariableTaxIsValid)


    function sellingPriceHasChanged(): boolean {
        if (isOpenOperation()) {
            if (!storeFinancialParameters?.selling_price) {
                return true
            }
            for (const consumptionPoint of consumptionPoints) {
                for (const injectionPoint of injectionPoints) {
                    if (sellingPrice[consumptionPoint.id]?.[injectionPoint.id]?.type !== storeFinancialParameters?.selling_price[consumptionPoint.id]?.[injectionPoint.id]?.type
                        || sellingPrice[consumptionPoint.id]?.[injectionPoint.id]?.value !== storeFinancialParameters?.selling_price[consumptionPoint.id]?.[injectionPoint.id]?.value
                        || sellingPrice[consumptionPoint.id]?.[injectionPoint.id]?.inflation !== storeFinancialParameters?.selling_price[consumptionPoint.id]?.[injectionPoint.id]?.inflation) {
                        return true
                    }
                }

            }
        }
        return false
    }

    function getDifferenceBetweenOpexArrays(array1: {
        id: string,
        name: string,
        opex: any,
        opex_average_inflation: any
    }[], array2: { id: string, name: string, opex: any, opex_average_inflation: any }[]) {
        return array1.flat().filter((object1: { id: string, name: string, opex: any, opex_average_inflation: any }) => {
            return !array2.flat().some((object2: {
                id: string,
                name: string,
                opex: any,
                opex_average_inflation: any
            }) => {
                return object1.id === object2.id && object1.opex.toString() === object2.opex.toString() && object1.name === object2.name && object1.opex_average_inflation.toString() === object2.opex_average_inflation.toString();
            });
        });
    }

    function financialParametersHasChanged() {
        return storeDuration?.toString() !== duration
            || inflationHasChanged
            || capexesHaveChanged(capexes, storeCapexes)
            || opexesHasChanged()
            || sellingPriceHasChanged()
            || vatRateHasChanged()
    }

    function vatRateHasChanged() {
        let fixedVATRateHasChanged = storeFinancialParameters?.fixedVATRate !== fixedVATRate
        let variableVATRateHasChanged = storeFinancialParameters?.variableVATRate !== variableVATRate
        return fixedVATRateHasChanged || variableVATRateHasChanged
    }


    function opexesHasChanged() {
        let oldOpexes: any[] = [...storeOpexes]
        let opexesFormatted: any[] = []
        opexes.forEach((opex, index) => {
            opexesFormatted[index] = {
                id: opex.id,
                name: opex.opexName,
                opex: opex.opex,
                opex_average_inflation: opex.opexAverageInflation
            }
        })
        return getDifferenceBetweenOpexArrays(oldOpexes, opexesFormatted).length > 0 || getDifferenceBetweenOpexArrays(opexesFormatted, oldOpexes).length > 0

    }

    function form5IsValid() {
        return durationIsValid && fixedTaxIsValid && variableTaxIsValid && validateInflation() && duration !== null && (isOpenOperation() ? sellingPriceIsValid : true) && !opexesAreValid.some((opexValid) => !opexValid) && capexesLoanAreValid && validateAllCapexes(capexes)
    }

    function validateInflation() {
        const inflationIsFixedAndValid = !variableInflationsToggled && inflationIsValid && inflation !== null
        return inflationIsFixedAndValid || variableInflationsToggled
    }

    function isOpenOperation() {
        return typeOfOperation && typeOfOperation.toString() === "open"
    }

    function pushFinancialParametersForm() {
        const newInjectionPoints = [...injectionPoints]
        const mapNameToCapex = capexes.reduce((acc: any, curr) => {
            acc[curr.id] = curr;
            return acc;
        }, {})


        const newGlobalCapex = mapNameToCapex['Capex global']
        newInjectionPoints.forEach(newInjPoint => {
            const id = newInjPoint.id
            if (opexes.some((opexes) => opexes.id === id)) {
                let opexesToAddToPoint: OpexFormType[] = opexes.filter((opexes) => opexes.id === id)
                let newOpexEntries: any[] = []
                opexesToAddToPoint.forEach((opexToAdd) => {
                    newOpexEntries.push(new OpexEntries(opexToAdd.id, opexToAdd.opexName, opexToAdd.opex.toString(), opexToAdd.opexAverageInflation))
                })
                newInjPoint.opexEntries = newOpexEntries
            } else {
                newInjPoint.opexEntries = [];
            }
            if (mapNameToCapex[id]) {
                newInjPoint.capexEntries = new CapexEntries(mapNameToCapex[id].capex, mapNameToCapex[id].loan)
            } else {
                newInjPoint.capexEntries = null;
            }
        })

        let newGlobalOpex: any[] = []
        opexes.filter((opexes) => opexes.id === "Opex global").forEach((opexToAddToGlobal) => {
            newGlobalOpex.push(new OpexEntries(opexToAddToGlobal.id, opexToAddToGlobal.opexName, opexToAddToGlobal.opex.toString(), opexToAddToGlobal.opexAverageInflation))
        })


        const financial_parameters = new FinancialParameters(
            parseInt(duration),
            newGlobalOpex && newGlobalOpex.length > 0 ? newGlobalOpex : null,
            newGlobalCapex ? new CapexEntries(newGlobalCapex.capex, newGlobalCapex.loan) : null,
            variableInflationsToggled ? variableInflationsForm : parseFloat(inflation),
            fixedVATRate,
            variableVATRate,
            isOpenOperation() ? sellingPrice : null)
        dispatch(newSimulationActions.setFinancialParameters(financial_parameters, newInjectionPoints))
    }

    useEffect(() => {
        // copie du store dans formulaire
        if (storeFinancialParameters) {
            dispatch(formActions.setDuration((storeFinancialParameters.duration ? storeFinancialParameters.duration.toString() : "")))
            dispatch(formActions.setFixedTax(storeFinancialParameters.fixedVATRate))
            dispatch(formActions.setVariableTax(storeFinancialParameters.variableVATRate))

            if (storeInflation != null) {
                const inflationIsFixed = typeof storeInflation === "number"
                if (inflationIsFixed) {
                    dispatch(formActions.setInflation(storeInflation.toString()))
                } else {
                    dispatch(formActions.resetLastSavedVariableInflations())
                }
                dispatch(formActions.setVariableInflationsToggled(!inflationIsFixed))
            }
        }
    }, [storeFinancialParameters, storeInflation])


    useEffect(() => {
        if (savingForm) {
            pushFinancialParametersForm()
            dispatch(newSimulationActions.setSavingForm(false))
        }
    }, [savingForm])

    useEffect(() => {
        if (form5IsValid() && financialParametersHasChanged()) {
            dispatch(newSimulationActions.setHasNewChanges(true))
        } else {
            dispatch(newSimulationActions.setHasNewChanges(false))
        }
    }, [duration, opexes, capexes, inflation, sellingPrice, form5IsValid, fixedVATRate, variableVATRate, variableInflationsForm, variableInflationsToggled])


    useEffect(() => {
        // return form validation State
        dispatch(newSimulationActions.setFormIsValid(form5IsValid()))
    }, [durationIsValid, variableTaxIsValid, fixedTaxIsValid, inflationIsValid, duration, inflation, sellingPriceIsValid, sellingPrice, variableInflationsToggled, variableInflationsForm])

    useEffect(() => {
        if (closingForm && form5IsValid() && financialParametersHasChanged()) {
            pushFinancialParametersForm()
            dispatch(newSimulationActions.setHasNewChanges(false))
        }
        dispatch(newSimulationActions.setCurrentStepIndex(nextStep.index))
        dispatch(newSimulationActions.setClosingForm(false))
    }, [closingForm])


    return <div className="w-full h-full flex flex-col bg-gray-100  font-sans overflow-auto dark:bg-zinc-700">
        <div className="mx-4 2xl:mx-8 scale-95 2xl:scale-100  border-box">
            <TitleText title={"Paramètres financiers"}/>
            <div className="flex flex-col mt-6">
                <form className="w-full 2xl:max-w-[920px]">
                    <div className="flex flex-wrap m-4">
                        <DurationInput/>
                        <hr className="mx-2 my-3 w-full border-zinc-300 dark:border-zinc-600"/>
                        <div className={"w-full"}>
                            <CapexTable/>
                        </div>
                        <hr className="mx-2 my-3 w-full border-zinc-300 dark:border-zinc-600"/>
                        <OpexTable/>
                        <hr className="mx-2 my-3 w-full border-zinc-300 dark:border-zinc-600"/>

                        <div className={"flex gap-2"}>
                            <VariableVATRate/>
                            <FixedVATRate/>
                        </div>


                        <InflationInput/>
                    </div>
                </form>
                {isOpenOperation() && <SellingPriceTableWithFunction/>}
                <div className={"text-gray-500 text-xs mt-4 mb-2"}>* champs obligatoires</div>
            </div>
        </div>
    </div>
}


