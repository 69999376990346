import {createSelector} from "reselect";
import {RootState} from "../../../../../store/rootReducer";
import {Typology} from "../../../../models/types/new-simulation/form/Typology";
import {selectOrganisations, selectSelectedOrganisation,} from "../../../authentication/authenticationSelector";
import {Organisation} from "../../../../models/types/simulations-display/Organisation";

export const selectNewSimulation = (state: RootState) => state.newSimulation;

export const selectSimulationForm = createSelector(
    selectNewSimulation,
    newSimulation => newSimulation.simulationForm
);


const selectFormOrganisationId = createSelector(
    selectSimulationForm,
    simulationForm => simulationForm.organisation_id
);

const selectSelectedSimulationId = createSelector(
    selectSimulationForm,
    simulationForm => simulationForm._id
)


const selectTargetYear = createSelector(
    selectSimulationForm,
    simulationForm => simulationForm?.target_year
);

const selectOrganisationIdWithCreditLeft = createSelector(
    selectOrganisations,
    selectFormOrganisationId,
    selectSelectedOrganisation,
    (orgas, formOrgaId, selectedOrga) => {
        if (formOrgaId) {
            return formOrgaId
        } else {
            if (selectedOrga) {
                return selectedOrga.id
            } else {
                return orgas.some((orga: Organisation) => orga.remaining_number_of_simulations > 0) ? orgas.find((orga: Organisation) => orga.remaining_number_of_simulations > 0).id : ""
            }
        }
    }
)

const selectTypologyInfo = createSelector(
    selectSimulationForm,
    simulationForm => simulationForm && simulationForm._typology ? simulationForm._typology : new Typology([], [])
);


export const sortData = (data: any[], sorting: { column: string, asc: boolean }) => {
    let dataSorted = [...data]
    if (!sorting.column) return data
    dataSorted.sort((a: any, b: any) => {
        if (a[sorting.column] < b[sorting.column]) {
            return -1;
        }
        if (a[sorting.column] > b[sorting.column]) {
            return 1;
        }
        return 0;
    })
    return sorting.asc ? dataSorted.reverse() : dataSorted
}

const selectAddresses = createSelector(
    selectNewSimulation,
    newSimulation => newSimulation.addresses
)
const makeSelectAddressGouvFrom = (addressLabel: string | null) => createSelector(
    selectAddresses,
    (addresses) => addressLabel ? addresses.find((addr: any) => addr.label === addressLabel) || null : null
)
const selectTypesOfAddress = createSelector(
    selectAddresses,
    addresses => new Set(addresses.map((address: any) => {
        return address.type
    }))
)





export {
    makeSelectAddressGouvFrom,
    selectAddresses,
    selectTypesOfAddress,
    selectTypologyInfo,
    selectSelectedSimulationId,
    selectFormOrganisationId,
    selectOrganisationIdWithCreditLeft,
    selectTargetYear,
}

