import {XIconRed} from "../../../../../components/assets/icon/XIcon";
import React from "react";
import {InjectionPoint} from "../../../../../../../../corelogic/models/types/new-simulation/form/InjectionPoint";
import {formActions} from "../../../../../../../../corelogic/usecases/form/formActions";
import {useDispatch, useSelector} from "react-redux";
import {
    selectFormCapexes
} from "../../../../../../../../corelogic/usecases/form/formSelector";
import {deleteLineFromArray} from "../FormCapex.utils";
import {newSimulationActions} from "../../../../../../../../corelogic/usecases/new-simulation/newSimulationActions";
import {
    selectInjectionPoints
} from "../../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectInjectionPointForm";

export default function ({index, disabled}: { index: number, disabled ?:true }) {
    const dispatch = useDispatch()
    const injectionPoints = useSelector(selectInjectionPoints)
    const capexes = useSelector(selectFormCapexes)
    const styleDisabled = disabled ? ' hover:cursor-auto opacity-40 border-none ' : ''

    function deleteCapexFromInjectionPoint(id: string) {
        const newInjectionPoints = [...injectionPoints]
        const matchingPoint: InjectionPoint | undefined = newInjectionPoints.find((point) => id === point.id)
        if (matchingPoint) {
            matchingPoint.capexEntries = null
            dispatch(newSimulationActions.setInjectionPoints(newInjectionPoints))
        }
    }

    function deleteRow(indexToDelete: number) {
        if(!disabled){
            deleteCapexFromInjectionPoint(capexes[indexToDelete].id)
            dispatch(formActions.setCapexes(deleteLineFromArray(capexes, indexToDelete)))
        }
    }

    return <div
        className={styleDisabled + " w-min text-center h-min rounded-md border-2  p-2 my-1 hover:cursor-pointer hover:bg-red-200 border-red-100 hover:border-red-500 bg-red-100 dark:hover:bg-red-700 dark:border-red-800  dark:bg-red-800"}
        onClick={() => deleteRow(index)}>
        <XIconRed/>
    </div>
}
