import {newSimulationActionsType, SET_UPDATING_SIMULATION} from "../../newSimulationActions";

const updatingSimulationReducer = (state = false, action: newSimulationActionsType) => {
    switch (action.type) {
        case SET_UPDATING_SIMULATION :
            return action.payload
        default:
            return state;
    }
}

export default updatingSimulationReducer