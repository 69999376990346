import {RootState} from "../../../store/rootReducer";
import {createSelector} from "reselect";
import {selectSelectedOrganisationId} from "../authentication/authenticationSelector";

export const selectSimulations = (state: RootState) => state.simulations.simulations;
export const selectAllSimulations = (state: RootState) => state.simulations.allSimulations;
export const selectSimulationsCurrentPage = (state: RootState) => state.simulations.simulationsCurrentPage;

const selectSimulationsCount = (state: RootState) => state.simulations.simulationsCount;

const selectMySimulation =  createSelector(
    selectSimulations,
    selectSelectedOrganisationId,
    (simus, selectedOrgId) => selectedOrgId ? simus.filter((simu) => simu.organisation.id === selectedOrgId) : simus,
)
const selectNumberOfSimulations = createSelector(
    selectMySimulation,
    simus => simus?.length || 0,
)
const selectNumberOfPages = createSelector(
    selectSimulationsCount,
    count => Math.ceil(count/10),
)

const selectPagination = createSelector(selectNumberOfPages, selectSimulationsCurrentPage, (numberOfPages, currentPage) => { 
    const pagination = [];
    let minPage = currentPage - 2;
    let maxPage = currentPage + 2;

    if(minPage <= 1) {
        maxPage += Math.abs(minPage -1); // 0 is not counted here, it is why -1.
        minPage = 1;
    }

    if(maxPage > numberOfPages) {
        minPage = Math.max(minPage - (maxPage - numberOfPages), 1);
        maxPage = numberOfPages;
    }

    while (minPage <= maxPage) {
        pagination.push(minPage);
        minPage++;
    }

    return pagination;

})
const makeSelectSimulations = (organisationId : string | null) => createSelector(
    selectMySimulation,
    simus =>!organisationId ? simus :  simus.filter((simu)=> simu.organisation.id === organisationId)
)
const selectSimulationsSearchedText = (state: RootState) =>  state.simulations.search

export { selectNumberOfSimulations, selectNumberOfPages, selectPagination, makeSelectSimulations, selectSimulationsSearchedText, selectSimulationsCount };

