import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {Simulation} from "../../../../../../../corelogic/models/types/simulations-display/Simulation";

import {
    selectAllSimulations,
    selectSimulationsSearchedText
} from "../../../../../../../corelogic/usecases/simulations-display/simulationsDisplaySelector";
import OutsideAlerter from "../../../wrapper/OutsideAlerter";
import SearchBar from "./SearchBar";
import SearchList from "./SearchList";

const SearchBarWithOptions = () => {
    const [showOptions, setShowOptions] = useState(false)
    const searchedText = useSelector(selectSimulationsSearchedText)
    const simulations = useSelector(selectAllSimulations)
    const [simulationsFiltered, setSimulationsFiltered] = useState<Simulation[]>([])

    useEffect(() => {
        if (searchedText.length > 0) {
            const simulationsFilteredByText = simulations.filter((simulation) => simulation.name.toLowerCase().includes(searchedText.toLowerCase()))
            setSimulationsFiltered(simulationsFilteredByText)
            setShowOptions(true)
        } else {
            setSimulationsFiltered(simulations)
        }
    }, [searchedText, simulations])

    return <OutsideAlerter clickOutsideAction={() => setShowOptions(false)}>
        <>
            <SearchBar showOptions={showOptions} setShowOptions={setShowOptions}/>
            {showOptions && <SearchList simpleSimulationsFiltered={simulationsFiltered} setShowOptions={setShowOptions}/>}
        </>
    </OutsideAlerter>
}




export default SearchBarWithOptions