import {useDispatch, useSelector} from "react-redux";
import {typologyFormActions} from "../../../../../../corelogic/usecases/typology-form/typologyFormActions";
import {selectShowCartoDensity} from "../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import React from "react";
import {QuestionMarkOutlined} from "../../../components/assets/icon/QuestionMarkIcon";

export default function ToggleDensityMapView() {
    const showDensity = useSelector(selectShowCartoDensity)
    const dispatch = useDispatch();
    const handleChange = (event: any) => {
        dispatch(typologyFormActions.setShowDensity(!showDensity));
    }

    return <div className="flex items-center gap-4 justify-between w-full">
        <label htmlFor="default-range"
               className="block flex items-center gap-1 text-sm font-medium text-gray-700 whitespace-nowrap">
            <div
                className={"flex items-center px-1 py-0.5 bg-gray-700 w-min rounded-sm hover:cursor-help"}
                title={"Fonctionnalité expérimentale : Risque d'instabilité"}>
                <p className={" uppercase text-gray-100 text-xs font-semibold "}>ALPHA</p>
            </div>

            Vérifier votre élligibilité à un périmètre dérogatoire
        </label>
        <label className="flex items-center space-x-2 cursor-pointer">
            <div
                className={`relative bg-gray-300 rounded-full w-10 h-5 transition duration-300 ease-in-out ${
                    showDensity ? 'bg-yellow-500' : ''
                }`}
            >
                <div
                    className={`absolute w-5 h-5 bg-white rounded-full transform transition-transform duration-300 ease-in-out ${
                        showDensity ? 'translate-x-5' : ''
                    }`}
                />
            </div>
            <input type="checkbox" className="hidden" onChange={handleChange}/>
        </label>
    </div>
}

