import HeadFailure from "./HeadFailure";
import AFailedWeek from "./AFailedWeek";
import {useSelector} from "react-redux";
import {
    selectSGEFailuresAggregatedByErrorCode,
    selectSGEFailuresByWeeks
} from "../../../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import {SGEFetchedCDCStatus} from "../../../../../../../../../corelogic/usecases/typology-form/typologyFormReducer";

export default function SGEWeeksFailureRecap(){
    const failuresByWeeksResult = useSelector(selectSGEFailuresAggregatedByErrorCode)
    return <div className={"flex flex-col rounded border border-gray-200 dark:border-gray-700  w-full"}>
        <HeadFailure/>
        <div className={"max-h-[150px] overflow-y-auto"}>
            {failuresByWeeksResult.map((fail:SGEFetchedCDCStatus)=><AFailedWeek failInfos={fail}/>)}
        </div>
    </div>
}

