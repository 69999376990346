import React from "react";

export default function({text} : {text: string}){
    return <div className={"w-full h-12 self-center flex align-bottom mt-4"}>
        <div className="loader w-16">
            <div className="loader-inner">
                <div className="loader-line-wrap">
                    <div className="loader-line"/>
                </div>
                <div className="loader-line-wrap">
                    <div className="loader-line"/>
                </div>
                <div className="loader-line-wrap">
                    <div className="loader-line"/>
                </div>
                <div className="loader-line-wrap">
                    <div className="loader-line"/>
                </div>
                <div className="loader-line-wrap">
                    <div className="loader-line"/>
                </div>
            </div>
        </div>
        <span className={"pl-4 text-orange-400 text-md"}>
        {text}...
        </span>
    </div>
}