import {LabelInput} from "../../../../../../../components/assets/FormAssets/TextInput";
import React, {useState} from "react";
import ErrorInput from "./ErrorInput";
import DateInput from "./date-input/DateInput";

export default function DateEndInput() {

    const [mouseHasLeft, setMouseHasLeft] = useState(false)

    return <div className={""}>
        <LabelInput label={'DATE DE FIN'} mandatory={true}/>
        <div className={"flex flex-col"}>
            <DateInput setMouseHasLeft={setMouseHasLeft}/>
            <ErrorInput mouseHasLeft={mouseHasLeft}/>
        </div>
    </div>
}
