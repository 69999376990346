function PlusIcon(){
    return<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className={"h-5 w-5 text-gray-700 dark:text-gray-300"} fill="none" viewBox="0 0 24 24" stroke="currentColor"
         strokeWidth="2">
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4"/>
    </svg>
}
function PlusIconBlue(){
    return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className={"h-5 w-5 text-blue-500"} fill="none" viewBox="0 0 24 24" stroke="currentColor"
                strokeWidth="2">
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4"/>
    </svg>
}

export {PlusIcon, PlusIconBlue}

