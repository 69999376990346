import {useSelector} from "react-redux";
import ChartBarIcon from "../../../../../components/assets/icon/ChartBarIcon";
import React from "react";
import XIcon from "./XIcon";
import XLSExportButton from "../../../../../utils/XLSExportButton";
import {
    selectNewCurve
} from "../../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-curve/selectFormCurve";

export default function Header() {
    const newCurve = useSelector(selectNewCurve)

    return <div className="flex w-full justify-between items-center gap-1">
        <div className={"self-stretch justify-start w-full items-center gap-2 inline-flex"}>
            <ChartBarIcon className={"h-6 w-6 text-gray-600 dark:text-gray-200"}/>
            <div className="text-gray-600 dark:text-gray-200 text-sm font-bold leading-normal">
                Téléverser une courbe de charge raisonnablement complète sur au moins 6 mois
            </div>
        </div>
        <div className={"flex gap-2  justify-end"}>
            {newCurve && <XLSExportButton title={'CDC_UPLOAD'} data={newCurve.measurement}/>}
            <XIcon/>
        </div>
    </div>
}
