import Routes from "./Routes";
import HeadBar from "./composition/nav-bar/head-bar/HeadBar";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import ErrorSnackbar from "./containers/ErrorSnackbar";
import {selectIsAuthenticated} from "../../../corelogic/usecases/authentication/authenticationSelector";
import {selectIsDarkMode} from "../../../corelogic/usecases/config/configSelector";
import {Snackbar} from "@mui/material";
import {selectSnackbar} from "../../../corelogic/usecases/snackbar/snackbarSelector";
import {useSnackbar} from "notistack";
import {snackBarActions} from "../../../corelogic/usecases/snackbar/snackbarActions";

export default function App(){
    const isAuthenticated = useSelector(selectIsAuthenticated)
    const isDarkMode = useSelector(selectIsDarkMode)

    return(
        <div className={isDarkMode ? "dark h-screen w-screen overflow-hidden bg-zinc-700" : "h-screen w-screen overflow-hidden dark:bg-zinc-700"}>
            <ErrorSnackbar/>
            <Snack/>
            {isAuthenticated && <HeadBar/>}
            <Routes/>
        </div>
    )
}


function Snack(){
    const snack = useSelector(selectSnackbar)
    const {enqueueSnackbar} = useSnackbar()
    const dispatch = useDispatch()

    useEffect(() => {
        if(snack){
            enqueueSnackbar(snack.message, {variant: snack.options.variant})
            dispatch(snackBarActions.resetSnackbar())
        }
    }, [snack]);
    return <></>
}