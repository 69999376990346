import {Loan} from "./Loan";


export class CapexEntries {
    private _capex: string;
    private _loan: Loan | null

    constructor(capex: string, loan: Loan | null) {
        this._capex = capex;
        this._loan = loan
    }

    serialize() {
        return {
            capex: this._capex,
            loan: this.loan ? this.serializeLoan() : null
        }
    }
    serializeLoan(){
        return {
            price: this.loan?.price,
            duration: this.loan?.duration,
            rate: this.loan ? Number(this.loan.rate)/100 : null
        }
    }
    get loan(): Loan | null {
        return this._loan;
    }

    set loan(value: Loan | null) {
        this._loan = value;
    }

    get capex(): string {
        return this._capex;
    }

    set capex(value: string) {
        this._capex = value;
    }
}
