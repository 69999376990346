import {useDispatch} from "react-redux";
import {PlayIconSolid} from "../../../../../components/assets/icon/PlayIcon";
import {newSimulationActions} from "../../../../../../../../corelogic/usecases/new-simulation/newSimulationActions";


export default function ApplyFullCompletudeButton() {
    const dispatch = useDispatch()
    function applyAnnualCompletionIfEnable() {
        dispatch(newSimulationActions.applyAnnualCompletion())
    }

    return <div
        className={'bg-orange-600 rounded   px-2 py-1  text-white text-sm font-semibold opacity-80 hover:opacity-100 hover:cursor-pointer'}
        onClick={applyAnnualCompletionIfEnable}
    >
        <span className={"flex gap-2 items-center"}>
            Compléter la courbe de charge
            <PlayIconSolid className={'text-white h-5 w-5'}/>
        </span>
    </div>
}
