import {AuthenticationGatewayInterface} from "../../../../corelogic/usecases/authentication/authenticationGateway.interface";
import apiClient from "../Api/ApiClient";

export class AuthenticationApiGateway implements AuthenticationGatewayInterface {

    async logIn(credentials: { email: string, password: string }): Promise<any> {
        const formData = new FormData()
        formData.append("email", credentials.email)
        formData.append("password", credentials.password)
        await apiClient.post('/login', formData)
        return null;
    }
    async logOut(): Promise<any> {
        const {data: response} = await apiClient.post('/logout')
        return response;
    }
    async getSession(): Promise<any> {
        const {data: user} = await apiClient.get('/session')
        return user;
    }

    async updatePassword({oldPwd, newPwd}: { oldPwd: string; newPwd: string }) {
        const {status} = await apiClient.post('/auth/password', {
            old_password : oldPwd,
            new_password : newPwd,
            new_password_confirm : newPwd
        })
        return status
    }
}
