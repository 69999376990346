import {XIconRed} from "../../../../../../../components/assets/icon/XIcon";
import React from "react";
import {typologyFormActions} from "../../../../../../../../../../corelogic/usecases/typology-form/typologyFormActions";
import {useDispatch} from "react-redux";

const DeleteLowCompletenessRow = ({index}: { index: number }) => {
    const sharedStyle = " ml-4 w-min  text-center  rounded-md border-2  p-1 dark:border-red-800 border-red-100  dark:bg-red-800 bg-red-100 "
    const enabledStyle = ' mt-1  opacity-100 hover:cursor-pointer hover:bg-red-200  hover:border-red-500  dark:hover:bg-red-700 '
    const style = sharedStyle + enabledStyle
    const dispatch = useDispatch()

    function handleClick() {
        dispatch(typologyFormActions.removeLowCompletudeRowFromIndex(index))
    }

    return <div
        className={style}
        onClick={handleClick}>
        <XIconRed/>
    </div>
}


export default DeleteLowCompletenessRow
