import React from "react";
import HeadSuccess from "./HeadSuccess";

export default function SGEWeeksSuccessRecap(){
    return <div className={"flex flex-col rounded border border-gray-200 dark:border-gray-700 w-full"}>
        <HeadSuccess/>
    </div>
}


