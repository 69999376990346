import {
    InflationInterval
} from "../../../adapters/primary/ui/composition/form/financial-parameters/variable-inflation-widget/VariableInflationInput";
import {put, select, takeLatest} from "redux-saga/effects";
import {
    CANCEL_SELLING_PRICE_INFLATION_MODAL,
    CONFIRM_SELLING_PRICE_INFLATION_MODAL,
    RESET_LAST_SAVED_SELLING_PRICE_INFLATIONS,
    RESET_LAST_SAVED_VARIABLE_INFLATIONS
} from "../form/formActions";
import {snackBarErrorsActions} from "../errors/snackbarErrorsActions";
import {formActions} from "./formActions";
import {selectFormDuration, selectSellingPriceVariableInflations} from "./formSelector";
import {
    selectComplementProviderInflationRate
} from "../new-simulation/new-simulation-selector/form-data/selectFinancialParameter";

function* resetLastSavedValueVariableInflations(){
    try {
        const duration:string = yield select(selectFormDuration)
        const storeInflations:InflationInterval[] | number | undefined  = yield select(selectComplementProviderInflationRate)
        if(!storeInflations) yield put(formActions.setVariableInflations([{value:0, startYear:1, endYear:parseInt(duration)}]))
        if(storeInflations && typeof storeInflations !== "number") {
            const newVariableInflations = JSON.parse(JSON.stringify(storeInflations))
            yield put(formActions.setVariableInflations(newVariableInflations))
        }
        if(storeInflations && typeof storeInflations === "number")  yield put(formActions.setVariableInflations([{value:storeInflations, startYear:1, endYear:parseInt(duration)}]))
    } catch (error: any) {
        yield put(snackBarErrorsActions.handleError(error));
        console.log("error on resetLastSavedValueVariableInflations saga ... ", error)
    }
}

function* confirmSellingPriceInflation(action:any){
    try {
        const {payload} = action
        yield put(formActions.openModalSellingPriceInflations(false))
        yield put(formActions.setSellingPriceInflation(JSON.parse(JSON.stringify(payload))))
    } catch (error: any) {
        yield put(snackBarErrorsActions.handleError(error));
        console.log("error on confirmSellingPriceInflation saga ... ", error)
    }
}
function* resetSellingPriceInflations(){
    try {
        const storedSellingPriceInflations:InflationInterval[] | undefined = yield select(selectSellingPriceVariableInflations)
        const duration:string = yield select(selectFormDuration)
        if(storedSellingPriceInflations){
            const newInflations = JSON.parse(JSON.stringify(storedSellingPriceInflations))
            yield put(formActions.setTemporarySellingPriceInflation(newInflations))
        } else {
            yield put(formActions.setTemporarySellingPriceInflation([{value:0, startYear:1, endYear:parseInt(duration)}]))
        }
    } catch (error: any) {
        yield put(snackBarErrorsActions.handleError(error));
        console.log("error on cancelSellingPriceInflation saga ... ", error)
    }
}
function* cancelSellingPriceInflation(){
    try {
        yield put(formActions.resetLastSavedSellingPriceInflations())
        yield put(formActions.openModalSellingPriceInflations(false))
    } catch (error: any) {
        yield put(snackBarErrorsActions.handleError(error));
        console.log("error on cancelSellingPriceInflation saga ... ", error)
    }
}
function* resetSellingPriceInflationsSaga(){
    yield takeLatest(RESET_LAST_SAVED_SELLING_PRICE_INFLATIONS, resetSellingPriceInflations)
}
function* cancelSellingPriceInflationModalSaga(){
    yield takeLatest(CANCEL_SELLING_PRICE_INFLATION_MODAL, cancelSellingPriceInflation)
}
function* confirmSellingPriceInflationSaga() {
    yield takeLatest(CONFIRM_SELLING_PRICE_INFLATION_MODAL, confirmSellingPriceInflation)
}
function* resetLastSavedValueVariableInflationsSaga() {
    yield takeLatest(RESET_LAST_SAVED_VARIABLE_INFLATIONS, resetLastSavedValueVariableInflations)
}
const formSaga = [
    resetLastSavedValueVariableInflationsSaga,
    cancelSellingPriceInflationModalSaga,
    confirmSellingPriceInflationSaga,
    resetSellingPriceInflationsSaga
]
export default formSaga;
