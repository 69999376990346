import {call, getContext, put, takeLatest} from "redux-saga/effects";
import {authenticationActions, GET_SESSION, LOG_IN, LOG_OUT, UPDATE_PASSWORD} from "./authenticationActions";
import {AuthenticationGatewayInterface} from "./authenticationGateway.interface";
import {push} from 'connected-react-router'
import {errorsActions} from "../errors/errorsActions";
import {simulationsDisplayActions} from "../simulations-display/simulationsDisplayActions";
import {newSimulationActions} from "../new-simulation/newSimulationActions";
import {snackBarErrorsActions} from "../errors/snackbarErrorsActions";

function* logIn(action: any) {
    try {
        const authenticationGateway: AuthenticationGatewayInterface = yield getContext('authenticationGatewayContext');
        yield call(authenticationGateway.logIn, action.payload);
        yield call(getSession);
        yield put(push('/'))
        yield put(errorsActions.resetError())

    } catch (error: any) {
        yield put(errorsActions.handleError(error));
    }
}

function* updatePassword(action: any) {
    try {
        const authenticationGateway: AuthenticationGatewayInterface = yield getContext('authenticationGatewayContext');
        const status: number = yield call(authenticationGateway.updatePassword, action.payload);
        yield put(authenticationActions.setPasswordUpdated(status === 204))

    } catch (error: any) {
        yield put(errorsActions.handleError(error));
    }
}

function* logOut() {
    try {
        const authenticationGateway: AuthenticationGatewayInterface = yield getContext('authenticationGatewayContext');
        yield call(authenticationGateway.logOut);
        yield put(authenticationActions.setUser(null))
        yield put(simulationsDisplayActions.initSimulations())
        yield put(simulationsDisplayActions.initAllSimulation())
        yield put(newSimulationActions.initSimulationForm())
    } catch (error: any) {
        yield put(snackBarErrorsActions.handleError(error));
    }
}

function* getSession() {
    try {
        const authenticationGateway: AuthenticationGatewayInterface = yield getContext('authenticationGatewayContext');
        const user: { user: any, organisations: any } = yield call(authenticationGateway.getSession);
        yield put(authenticationActions.setUser(user.user))
        yield put(authenticationActions.setOrganisations(user.organisations))
    } catch (error: any) {
        yield put(snackBarErrorsActions.handleError(error));
    }
}


export function* logInSaga() {
    yield takeLatest(LOG_IN, logIn);
}

export function* logOutSaga() {
    yield takeLatest(LOG_OUT, logOut);
}

export function* getSessionSaga() {
    yield takeLatest(GET_SESSION, getSession);
}

export function* updatePasswordSaga() {
    yield takeLatest(UPDATE_PASSWORD, updatePassword);
}

const authenticationSagas = [logInSaga, logOutSaga, getSessionSaga, updatePasswordSaga]
export default authenticationSagas
