
function DoubleChevronDown({className} : {className?: string}){
    return <svg xmlns="http://www.w3.org/2000/svg" className={className ? className : "h-6 w-6 text-gray-500"} fill="none" viewBox="0 0 24 24" stroke="currentColor"
                strokeWidth="2">
        <path strokeLinecap="round" strokeLinejoin="round" d="M19 13l-7 7-7-7m14-8l-7 7-7-7"/>
    </svg>
}


export {DoubleChevronDown}
