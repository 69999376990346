import React, {ChangeEvent, useEffect, useState} from "react";
import {InformationCircleIcon} from "../icon/InformationCircleIcon";
import {UnitType} from "../../../../../../config/app-config";
import EyeIcon from "../icon/EyeIcon";
import SpinningSVG from "../Animated/SpinningSVG";

type TextInputType = {
    placeholder: string,
    id: string,
    onChange: (e: string) => void,
    label?: string,
    mandatory?: boolean,
    value?: string
    error?: {
        state: boolean,
        msg: string
    },
    disabled?: boolean,
    inputType?: "password" | "text",
    unit?: UnitType,
    tooltipComponent?: JSX.Element,
    unitComponent?: JSX.Element,
    isAFloat?: boolean
    onFocus?(): void,
    topMargin?: string
    handleKeyEnter?(): void,
    dataCy?: string,
    loading?: boolean
}
export default function ({
                             label,
                             placeholder,
                             id,
                             onChange,
                             mandatory,
                             value,
                             error,
                             disabled,
                             inputType,
                             tooltipComponent,
                             unit,
                             unitComponent,
                             isAFloat,
                             handleKeyEnter,
                             dataCy,
                             onFocus,
                             topMargin,
                             loading
                         }: TextInputType) {
    const [errorState, setErrorState] = useState(false)

    useEffect(() => {
        if (error && value != null)
            setErrorState(error?.state && value.length > 0)
    }, [error?.state, value])

    return <div className={topMargin ? "w-full " + topMargin : "w-full  py-1"}>
        <div className={"flex flex-row align-center"}>
            <LabelInput label={label} mandatory={mandatory}/>
            <TooltipInput tooltipComponent={tooltipComponent}/>
        </div>
        <Input loading={loading} inputType={inputType} id={id} placeholder={placeholder} disabled={disabled}
               value={value}
               onFocus={onFocus} error={error ? {state: errorState, msg: error?.msg} : undefined} onChange={onChange}
               unit={unit} unitComponent={unitComponent}
               dataCy={dataCy} handleKeyEnter={handleKeyEnter} isAFloat={isAFloat}/>
        <ErrorMsgInput error={error ? {state: errorState, msg: error?.msg} : undefined} id={id}/>
    </div>
}
export const TooltipInput = ({tooltipComponent}: { tooltipComponent?: JSX.Element }) => {
    const [showTooltip, setShowTooltip] = useState(false)

    return <>
        {
            tooltipComponent &&
            <div className={"hover:cursor-pointer ml-2"} onMouseLeave={() => setShowTooltip(false)}
                 onMouseOver={() => setShowTooltip(true)}>
                {InformationCircleIcon({textcolor: "text-gray-600"})}
                {showTooltip && tooltipComponent}
            </div>}
    </>
}
export const LabelInput = ({label, mandatory}: { label?: string, mandatory?: boolean }) => {
    const mandatoryDisplayed = mandatory ? "*" : ""
    return <>
        {label && <label
            className="block overflow-hidden whitespace-nowrap overflow-ellipsis w-max-min tracking-wide text-gray-600 dark:text-gray-300 text-xs font-bold mb-2"
            htmlFor="grid-first-name">{label}{mandatoryDisplayed}</label>}
    </>
}

type ErrorMsgInputType = { error?: { state: boolean, msg: string }, id?: string }
export const ErrorMsgInput = ({error, id}: ErrorMsgInputType) => {
    return <>
        {error && error.state &&
            <div id={id + '-error'} className={"text-xs text-red-500 whitespace-nowrap  left-1 bottom-[-2]"}>
                {error.msg}
            </div>}
    </>
}

type InputType = {
    placeholder: string,
    id: string,
    onChange: (e: string) => void,
    value?: string
    error?: {
        state: boolean,
        msg: string
    },
    disabled?: boolean,
    inputType?: "password" | "text",
    unit?: UnitType,
    unitComponent?: JSX.Element,
    isAFloat?: boolean
    onFocus?(): void,
    handleKeyEnter?(): void,
    dataCy?: string,
    loading?: boolean
}
export const Input = ({
                          error,
                          value,
                          disabled,
                          dataCy,
                          inputType,
                          id,
                          placeholder,
                          unit,
                          unitComponent,
                          isAFloat,
                          handleKeyEnter,
                          onChange,
                          onFocus,
                          loading
                      }: InputType) => {
    const [className, setClassName] = useState("")
    useEffect(() => {
        if (disabled || loading) {
            setClassName("min-w-[64px] appearance-none block  w-full bg-gray-100 dark:bg-zinc-500 dark:focus:bg-zinc-400   rounded-l  text-gray-400 border  py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:text-gray-200 dark:border-zinc-400 ")
        } else if (value && value.length === 0 || !value) {
            setClassName("min-w-[64px] appearance-none dark:bg-zinc-600 dark:focus:bg-zinc-500 block w-full bg-gray-200 text-gray-700 rounded-l  py-3 px-4 leading-tight focus:outline-none focus:bg-white")
        } else if (error && error.state) {
            setClassName("min-w-[64px] appearance-none block dark:bg-zinc-600 dark:text-zinc-300 dark:focus:bg-zinc-500 w-full rounded-l border bg-white border-red-400  py-3 px-4 leading-tight focus:outline-none focus:bg-white")
        } else {
            setClassName("min-w-[64px] appearance-none block dark:bg-zinc-600 dark:text-zinc-200 dark:focus:bg-zinc-500 w-full bg-slate-100   text-gray-700 rounded-l border-2 border-slate-200 dark:border-zinc-600  py-3 px-4 leading-tight focus:outline-none focus:bg-white")
        }
    }, [error && error.state, disabled, value])


    function handleOnChange(e: ChangeEvent<HTMLInputElement>) {
        isAFloat ? onChange(e.target.value.replace(/,/g, ".").replace(/\s/g, '')) : onChange(e.target.value)
    }

    function handleShowHideText() {
        var x: any = document.getElementById(id);
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
    }

    function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter' && handleKeyEnter) {
            // keyboard 'Enter' key is pressed
            handleKeyEnter()
        }
    }

    function handleClick() {
        const input: any = document.getElementById(id)
        if (input) {
            input.focus()
        }
    }

    return <div className={error?.state ?
        "flex items-center justify-between bg-slate-300 rounded border  border-red-400 dark:bg-zinc-600" :
        "dark:bg-zinc-600 flex items-center justify-between bg-slate-200 rounded"}>

        <input
            onClick={handleClick}
            data-cy={dataCy}
            onKeyDown={(e) => handleKeyDown(e)}
            onFocus={onFocus}
            tabIndex={0}
            type={inputType ? inputType : "text "}
            onChange={(e) => handleOnChange(e)}
            value={loading ? 'chargement ..' : value ? value : ""}
            className={className}
            id={id}
            placeholder={placeholder}
            disabled={disabled || loading}/>
        {loading && <div className={'w-min appearance-none dark:bg-zinc-600 dark:focus:bg-zinc-500 block bg-gray-200 text-gray-700 rounded-l  h-full px-4 leading-tight focus:outline-none focus:bg-white'}>
            <SpinningSVG/>
        </div>}
        {inputType === "password" && <div onClick={() => handleShowHideText()} className={"px-2"}><EyeIcon
            className={"h-4 w-4  text-slate-500 hover:text-orange-600 hover:cursor-pointer "}/></div>}
        <div
            className={unit ? "w-min whitespace-nowrap flex items-center justify-between h-max  py-3 px-6 font-bold text-slate-700 text-sm rounded-r dark:bg-zinc-500 dark:text-zinc-300 dark:border-2 dark:border-zinc-500" : "rounded-r"}>
            {unitComponent && unitComponent} {unit}</div>
    </div>
}
