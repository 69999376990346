import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {typologyFormActions} from "../../../../../../../../../../../corelogic/usecases/typology-form/typologyFormActions";
import {selectSGEDateStartDays} from "../../../../../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";

type DateInputType = {
    handleInput(e: React.ChangeEvent<HTMLInputElement>, currentIndex: number): void,
    handleFocus(e: React.FocusEvent<HTMLInputElement>): void,
    handleOnBlur(): void,
    inputRefs: React.MutableRefObject<(HTMLInputElement | null)[]>,
    setFocused(value: boolean): void
}
const DayInput = ({handleInput, handleFocus, handleOnBlur, inputRefs, setFocused}: DateInputType) => {
    const dispatch = useDispatch()
    const days = useSelector(selectSGEDateStartDays)

    const updateNumber = (days: string) => {
        const numericValue = days.replace(/\D/g, ''); // Remove non-digit characters
        dispatch(typologyFormActions.setSGEStartDateDays(numericValue));
    };

    return <input
        placeholder={"01"}
        className={"bg-gray-100 dark:bg-purple-800 dark:bg-opacity-20 focus:outline-none max-w-[20px]"}
        type="text"
        onInput={(e: any) => handleInput(e, 0)}
        onFocus={handleFocus}
        ref={(el) => (inputRefs.current[0] = el)}
        maxLength={2}
        value={days}
        onClick={() => setFocused(true)}
        onBlur={handleOnBlur}
        onChange={(e) => updateNumber(e.target.value)}
    />
}

export default DayInput
