function MapPinIconOutline({className}:{className:string}){
    return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={className}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
    </svg>
}

function MapPinIconSolid({className}:{className:string}){
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={className}>
        <path fillRule="evenodd" d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
    </svg>
}
function MapPinIconSolidDisabled({className}:{className:string}){
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            fill="currentColor"
            viewBox="0 0 20 20"
        >
            <path
                fill="currentColor"
                fillOpacity="0.6"
                fillRule="evenodd"
                d="M9.69 18.933l.003.001C9.89 19.02 10 19 10 19s.11.02.308-.066l.002-.001.006-.003.018-.008a11.25 11.25 0 001.038-.573c.62-.384 1.445-.966 2.274-1.765C15.302 14.988 17 12.493 17 9A7.002 7.002 0 005.05 4.05 7 7 0 003 9c0 3.492 1.698 5.988 3.355 7.584a13.733 13.733 0 002.273 1.765c.317.197.642.378.976.544l.062.029.018.008.006.003zM10 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z"
                clipRule="evenodd"
            ></path>
            <path stroke="currentColor" strokeWidth="1.6" d="M3.5 2L17 17"></path>
        </svg>
    );
}
export {MapPinIconOutline,MapPinIconSolid, MapPinIconSolidDisabled}

