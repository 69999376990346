import {createAction} from "../../../store/createAction";
import {ActionsUnion} from "../../../store/actionsUnions";
import {Step} from "../../models/types/new-simulation/steps/Step";
import {GeneralInfo} from "../../models/types/new-simulation/form/GeneralInfo";
import {InjectionPoint} from "../../models/types/new-simulation/form/InjectionPoint";
import {ConsumptionPoint} from "../../models/types/new-simulation/form/ConsumptionPoint";
import {OperationParameters} from "../../models/types/new-simulation/form/operation-parameters/OperationParameters";
import {FinancialParameters} from "../../models/types/new-simulation/form/FinancialParameters";
import {SimulationForm} from "../../models/interfaces/SimulationForm";
import {Curve} from "../../models/types/new-simulation/curve/Curve";
import {AddressGouv} from "../../models/types/new-simulation/external-api/AddressGouv";
import {ExternalInvestment} from "../../../adapters/primary/ui/composition/balance-sheet/external-investment-form/ExternalInvestment";

export const GET_STEPS = 'GET_STEPS';
export const SET_STEPS = 'SET_STEPS';
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';

export const SET_NEXT_STEP_INDEX = 'SET_NEXT_STEP_INDEX';

export const GET_SIMULATION_FROM_ID = "GET_SIMULATION_FROM_ID";

export const SET_GENERAL_INFO = "SET_GENERAL_INFO";
export const SET_INJECTION_POINTS = "SET_INJECTION_POINTS";
export const SET_OPERATION_PARAMETERS = 'SET_OPERATION_PARAMETERS';
export const SET_FINANCIAL_PARAMETERS = 'SET_FINANCIAL_PARAMETERS';

export const PUSH_LOADED_CURVE = 'PUSH_LOADED_CURVE';
export const PUSH_LOADED_CURVE_LOADING = 'PUSH_LOADED_CURVE_LOADING';
export const SET_NEW_CURVE = 'SET_NEW_CURVE';
export const UPLOAD_LOAD_CURVE = 'UPLOAD_LOAD_CURVE';
export const MODEL_ENEDIS = 'MODEL_ENEDIS';
export const MODEL_PVGIS = 'MODEL_PVGIS';
export const LOAD_NEW_CURVE = 'LOAD_NEW_CURVE';
export const LOAD_CURVE = 'LOAD_CURVE';

export const SET_NEW_SIMULATION = 'SET_NEW_SIMULATION';
export const REMOVE_NEW_CURVE = 'REMOVE_NEW_CURVE';
export const HAS_BEEN_UPDATED = 'HAS_BEEN_UPDATED';
export const REMOVE_CONSUMPTION_POINT= 'REMOVE_CONSUMPTION_POINT';
export const REMOVE_INJECTION_POINT= 'REMOVE_INJECTION_POINT';
export const ADD_CONSUMPTION_POINT= 'ADD_CONSUMPTION_POINT';
export const ADD_INJECTION_POINT= 'ADD_INJECTION_POINT';
export const UPDATE_CONSUMPTION_POINT= 'UPDATE_CONSUMPTION_POINT';
export const UPDATE_INJECTION_POINT= 'UPDATE_INJECTION_POINT';
export const INIT_SIMULATION_FORM = 'INIT_SIMULATION_FORM';
export const GET_ADDRESS_FROM_API = 'GET_ADDRESS_FROM_API';
export const SET_ADDRESS_FROM_API = 'SET_ADDRESS_FROM_API';
export const INIT_ADDRESS_FROM_API = 'INIT_ADDRESS_FROM_API';
export const SET_POINT_STATE = 'SET_POINT_STATE';
export const SET_UPDATING_SIMULATION = 'SET_UPDATING_SIMULATION';
export const SET_SAVING_FORM = 'SET_SAVING_FORM';
export const SET_CLOSING_FORM = 'SET_CLOSING_FORM';
export const SET_NEW_CHANGES = 'SET_NEW_CHANGES';
export const SET_FORM_IS_VALID = 'SET_FORM_IS_VALID';
export const APPLY_RECALAGE = 'APPLY_RECALAGE';
export const UPLOAD_UNSAVED_LOAD_CURVE = 'UPLOAD_UNSAVED_LOAD_CURVE';
export const SET_UNSAVED_NEW_CURVE = 'SET_UNSAVED_NEW_CURVE';
export const APPLY_ANNUAL_COMPLETION = 'APPLY_ANNUAL_COMPLETION';
export const SAVE_LOADED_CURVE = 'SAVE_LOADED_CURVE';
export const ADD_CONSUMPTION_POINT2 = 'ADD_CONSUMPTION_POINT2';
export const UPDATE_CONSUMPTION_POINT2 = 'UPDATE_CONSUMPTION_POINT2';
export const SAVE_CURVE_AND_ADD_CONSUMPTION_POINT = 'SAVE_CURVE_AND_ADD_CONSUMPTION_POINT';
export const SAVE_CURVE_AND_UPDATE_CONSUMPTION_POINT = 'SAVE_CURVE_AND_UPDATE_CONSUMPTION_POINT';
export const SHOW_EXTERNAL_INVESTMENT = 'SHOW_EXTERNAL_INVESTMENT'
export const SET_FIRST_NEW_CURVE = 'SET_FIRST_NEW_CURVE'
export const APPLY_LOW_DATA_COMPLETION= 'APPLY_LOW_DATA_COMPLETION';
export const ADD_EXTERNAL_INVESTMENT = 'ADD_EXTERNAL_INVESTMENT'
export const REMOVE_EXTERNAL_INVESTMENT = 'REMOVE_EXTERNAL_INVESTMENT'

export const SET_EXTERNAL_INVESTMENTS = 'SET_EXTERNAL_INVESTMENTS'
export const APPLY_EXTERNAL_INVESTMENT = 'APPLY_EXTERNAL_INVESTMENT';
export const INIT_EXTERNAL_INVESTMENT_FORM = 'INIT_EXTERNAL_INVESTMENT_FORM';

export const newSimulationActions = {
    // form steps
    setGeneralInfo: (generalInfos: GeneralInfo, organisationId : string) => createAction(SET_GENERAL_INFO, {generalInfos, organisationId}),
    setInjectionPoints: (injectionPoints: InjectionPoint[]) => createAction(SET_INJECTION_POINTS, injectionPoints),
    setOperationParameters: (op: OperationParameters) => createAction(SET_OPERATION_PARAMETERS, op),
    setFinancialParameters: (fin: FinancialParameters, injectionPoints: InjectionPoint[]) => createAction(SET_FINANCIAL_PARAMETERS, {fin, injectionPoints}),
    getSimulationForm: () => createAction(GET_SIMULATION_FROM_ID),
    setSimulationForm: (newSimulation: SimulationForm) => createAction(SET_NEW_SIMULATION, newSimulation),
    removeConsumptionPoint: (point: ConsumptionPoint) => createAction(REMOVE_CONSUMPTION_POINT, point),
    removeInjectionPoint: (point: InjectionPoint) => createAction(REMOVE_INJECTION_POINT, point),
    addConsumptionPoint: (point: ConsumptionPoint) => createAction(ADD_CONSUMPTION_POINT, point),
    addConsumptionPoint2: () => createAction(ADD_CONSUMPTION_POINT2),

    addInjectionPoint: (point: InjectionPoint) => createAction(ADD_INJECTION_POINT, point),
    updateConsumptionPoint: (point: ConsumptionPoint) => createAction(UPDATE_CONSUMPTION_POINT, point),
    updateConsumptionPoint2: () => createAction(UPDATE_CONSUMPTION_POINT2),
    updateInjectionPoint: (point: InjectionPoint) => createAction(UPDATE_INJECTION_POINT, point),
    saveCurveAndAddPoint:()=> createAction(SAVE_CURVE_AND_ADD_CONSUMPTION_POINT),
    saveCurveAndUpdatePoint:()=> createAction(SAVE_CURVE_AND_UPDATE_CONSUMPTION_POINT),
    // address handler
    getAddressFromAPI: (address : string, types?:string[]) => createAction(GET_ADDRESS_FROM_API, {address, types}),
    setAddressFromAPI: (addressGouv : AddressGouv[]) => createAction(SET_ADDRESS_FROM_API, addressGouv),
    initAddressFromAPI: () => createAction(INIT_ADDRESS_FROM_API),

    // point state & flow
    setPointState:(pointId:string, enable:boolean) => createAction(SET_POINT_STATE,{pointId, enable}),
    setIsUpdatingForm:(isSimulationUpdating:boolean) => createAction(SET_UPDATING_SIMULATION, isSimulationUpdating),
    setSavingForm:(isToSave:boolean) => createAction(SET_SAVING_FORM, isToSave),
    setClosingForm:(isToClose:boolean) => createAction(SET_CLOSING_FORM, isToClose),
    setHasNewChanges:(hasNewChanges:boolean) => createAction(SET_NEW_CHANGES, hasNewChanges),
    setFormIsValid:(isCurrentFormValid : boolean) => createAction(SET_FORM_IS_VALID, isCurrentFormValid),
    getSteps: () => createAction(GET_STEPS),
    setSteps: (steps: Step[]) => createAction(SET_STEPS, steps),
    initSimulationForm:() => createAction(INIT_SIMULATION_FORM),
    setCurrentStepIndex: (index: number) => createAction(SET_CURRENT_STEP, index),
    setNextStepIndex: (index: number) => createAction(SET_NEXT_STEP_INDEX, index),
    hasBeenUpdatedNotification: (hasBeenUpdated: boolean) => createAction(HAS_BEEN_UPDATED, hasBeenUpdated),

    // LoadCurve
    removeNewCurve: () => createAction(REMOVE_NEW_CURVE),
    loadCurve: (id: string) => createAction(LOAD_CURVE, id),
    loadNewCurve: (id: string) => createAction(LOAD_NEW_CURVE, id),
    uploadLoadCurve: (curveParams: { type: string, nature: string, stepLength: number, body: any }) => createAction(UPLOAD_LOAD_CURVE, curveParams),
    modelEnedis: (curveParams: {
        accommodationType?: string, heatingType: string
    }) => createAction(MODEL_ENEDIS, curveParams),
    modelPvgis: (curveParams: {
        loss: string, angle: string, orientation: string, peakPower: string
    }) => createAction(MODEL_PVGIS, curveParams),
    setNewCurve: (newCurve: Curve | null) => createAction(SET_NEW_CURVE, newCurve),
    setFirstNewCurve: (newCurve: Curve | null) => createAction(SET_FIRST_NEW_CURVE, newCurve),
    pushLoadedCurve: (loadedCurve: Curve) => createAction(PUSH_LOADED_CURVE, loadedCurve),
    setPushLoadedCurveLoading: (obj: { loadedCurveId: string, loading: boolean }) => createAction(PUSH_LOADED_CURVE_LOADING, obj),

    saveLoadedCurve : () => createAction(SAVE_LOADED_CURVE),
    uploadUnsavedLoadCurve:(rawBody:FormData) =>  createAction(UPLOAD_UNSAVED_LOAD_CURVE, rawBody),

    // NewCurve CDC Recalage
    applyRecalage:() => createAction(APPLY_RECALAGE),
    applyAnnualCompletion:() => createAction(APPLY_ANNUAL_COMPLETION),
    applyLowDataCompletion:()=> createAction(APPLY_LOW_DATA_COMPLETION),

    // Internal Investment on Financial Restitution
    initExternalInvestmentForm:(investments:ExternalInvestment[]) => createAction(INIT_EXTERNAL_INVESTMENT_FORM, {investments}),
    showExternalInvestment:(show:boolean) => createAction(SHOW_EXTERNAL_INVESTMENT, show),
    addExternalInvestment:(externalInvestment:ExternalInvestment) =>  createAction(ADD_EXTERNAL_INVESTMENT, externalInvestment),
    removeExternalInvestment:(externalInvestment:ExternalInvestment) =>  createAction(REMOVE_EXTERNAL_INVESTMENT, externalInvestment),
    setExternalInvestments:(investments:ExternalInvestment[]) =>  createAction(SET_EXTERNAL_INVESTMENTS, investments),
    applyExternalInvestment:()=> createAction(APPLY_EXTERNAL_INVESTMENT),
};


export type newSimulationActionsType = ActionsUnion<typeof newSimulationActions>;
