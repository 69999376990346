import React from "react";

export default function SurplusAllocationCard({ Icon, label, badges, selected, onClick, showDescription}: {
    Icon: any,
    label: string,
    badges: { textBold: string, text: string, color: string, Icon: () => JSX.Element }[],
    selected: boolean,
    onClick: () => void,
    showDescription: boolean,
}) {
    const mainClass = (selected ? ' border-2 ' : ' cursor-pointer hover:bg-opacity-80 dark:hover:bg-opacity-80 ') + " h-full flex  flex-col rounded-lg border-zinc-700 bg-zinc-200 bg-opacity-40 dark:bg-opacity-40 p-6 dark:border-zinc-300 dark:bg-zinc-600 dark:shadow-2xl "
    return <div
        onClick={onClick}
        className={mainClass}>
        <div className={"h-full flex-col flex  gap-4"}>
            {label && <div className="text-zinc-600 font-bold text-sm pb-2 dark:text-zinc-100">{label}</div>}
            <div className={" relative ml-12 scale-[1.3] w-full pt-8 pb-12"}><Icon/></div>
            {showDescription ? badges.map((badge) => {
                    const Icon = () => <span className={"min-w-5"}>{badge.Icon()}</span>
                    return <div className="flex gap-4">
                        <Icon/>
                        <span className="text-gray-600 dark:text-gray-300 text-xs flex-wrap flex">
                    <b>{badge.textBold}</b>{badge.text}
                </span>
                    </div>
                })
                : <></>
            }
        </div>
    </div>
}
