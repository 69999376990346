import {BuildingLibraryOutlined} from "../../../../../components/assets/icon/BuildingLibraryIcon";
import React from "react";
import {formActions} from "../../../../../../../../corelogic/usecases/form/formActions";
import {useDispatch, useSelector} from "react-redux";
import {selectFormCapexes} from "../../../../../../../../corelogic/usecases/form/formSelector";
import {Loan} from "../../../../../../../../corelogic/models/types/new-simulation/form/Loan";
import {FormCapex} from "../FormCapex";

export default function ({index, disabled}: { index: number , disabled?:true}) {
    const dispatch = useDispatch()
    const capexes = useSelector(selectFormCapexes)
    const loanDisplayed = !!capexes[index].loan

    function handleClickOnLoanForCapex() {
        if(!disabled){
            const newCapexes:FormCapex[] = [...capexes]
            newCapexes[index].toggleLoan()
            dispatch(formActions.setCapexes(newCapexes))
        }
    }
    const styleDisabled = disabled ? ' hover:cursor-auto opacity-40 border-none ' : ''
    const style = loanDisplayed ? 'w-min text-center h-min rounded-md border-2 px-1 py-0.5 my-1 hover:cursor-pointer bg-zinc-200 border-zinc-500 dark:border-zinc-300 dark:bg-zinc-600 dark:border-zinc-700  dark:bg-zinc-700'
        : "w-min text-center h-min rounded-md border-2  px-1 py-0.5 my-1 hover:cursor-pointer hover:bg-zinc-200 border-zinc-100 hover:border-zinc-500 bg-zinc-100 dark:hover:bg-zinc-600 dark:hover:border-zinc-300 dark:border-zinc-700  dark:bg-zinc-700"
    return <div
        title={"Modéliser l'emprunt"}
        className={style + styleDisabled + " flex flex-col items-center justify-center"}
        onClick={() => handleClickOnLoanForCapex()}>
        <div className={"text-[8px] font-semibold"}>EMPRUNT</div>
        <BuildingLibraryOutlined
            className={'h-4 w-4 text-zinc-500 dark:text-zinc-300 '}/>
    </div>
}
