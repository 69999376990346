import React from "react";

type SelectorInputType = {
    label?: string,
    options: string[],
    id:string,
    onChange:(e: React.ChangeEvent<HTMLSelectElement>)=> void,
    value : string,
    dataCy?:string,
    loading?:boolean
}

export default function SelectorInput({label, options, id, onChange, value, dataCy, loading}: SelectorInputType) {
    return <label className="w-full max-w-[512px] block overflow-hidden whitespace-nowrap overflow-ellipsis uppercase tracking-wide text-gray-600 text-xs font-bold pb-1 ">
        {label && <span className="text-gray-700 mb-2 dark:text-gray-200">{label}</span>}
        <select data-cy={dataCy} id={id} onChange={onChange}
                value={loading ? 'chargement ...' : value}
                className="form-select block w-full mt-2 text-base bg-slate-200 dark:text-gray-200 dark:bg-zinc-600  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
            {options.map((option :string, index:number) => {
                return <option key={option}>{option}</option>
            })}
        </select>
    </label>
}
