import {ComponentType} from "react";
import {ButtonState} from "../../../../../../config/app-config";

type TwoFieldsBtnType = {
    title: string,
    description: string[],
    Icon: ComponentType,
    state: ButtonState,
    action?(): void,
    dataCy?:string
    reduced ?:boolean
}
export default function ({title, description, Icon, state, action, dataCy , reduced}: TwoFieldsBtnType) {

    function stepStyle(): string {
        switch (state) {
            case ButtonState.FOCUS:
                return "min-w-max  flex justify-end items-center bg-gray-100 p-2 rounded-lg my-2 2xl:my-6 dark:bg-zinc-700 dark:shadow-lg";
            case ButtonState.ERROR:
                return "min-w-max border-2 border-red-400 dark:hover:bg-opacity-40 dark:hover:bg-red-700 hover:cursor-pointer hover:bg-red-200 dark:border-red-800 flex justify-end items-center bg-red-100 p-2 rounded-lg my-2 2xl:my-6 dark:bg-red-900 dark:bg-opacity-40 dark:shadow-lg";
            case ButtonState.REGULAR:
                return "min-w-max flex justify-end items-center hover:cursor-pointer hover:bg-gray-100 p-2 rounded-lg my-2 2xl:my-6 dark:bg-zinc-800 dark:hover:bg-zinc-700 ";
            case ButtonState.DISABLED :
        }
        return "min-w-max   opacity-60 flex justify-end items-center hover:cursor-pointer hover:bg-gray-100 p-2 rounded-lg my-2 2xl:my-6 dark:bg-zinc-800 dark:hover:bg-zinc-700"
    }

    return <div data-cy={dataCy} title={title+" : ("+description+")"} onClick={action ? action : () => {
        console.log("RegularBtnDisabled ...")
    }} className={stepStyle()}>
        <div className="flex items-end flex-col whitespace-nowrap ">
            {!reduced && <div
                data-cy={dataCy+'-title'}
                className={state === ButtonState.FOCUS ? "px-2 text-sm font-semibold leading-normal mt-2 2xl:mt-3 uppercase  text-secondary  " : "px-2 text-sm font-semibold leading-normal mt-2 2xl:mt-3 uppercase  text-gray-700 dark:text-zinc-200 "}>{title}
            </div>}
            {!reduced  &&
            <div className={"flex overflow-ellipsis overflow-hidden hover:overflow-x-auto  whitespace-nowrap max-w-[200px]"}>
                {description.map((text: string) => {
                    return <div key={text} title={text}
                                className="mr-1 badge bg-zinc-200 dark:bg-zinc-600 dark:text-zinc-300 text-zinc-500 px-2 overflow-ellipsis  whitespace-nowrap  text-xs leading-normal mb-2 2xl:mb-3 ">
                        {text}
                    </div>
                })}
            </div>}
        </div>
        <div
            className={state === ButtonState.DISABLED ? "border-gray-500 border p-2 rounded-full mx-1" : "mx-1 bg-secondary p-2 rounded-full"}>
            <Icon/>
        </div>
    </div>

}
