import {Curve} from "../../../../models/types/new-simulation/curve/Curve";
import {
    INIT_SIMULATION_FORM,
    newSimulationActionsType,
    PUSH_LOADED_CURVE,
    PUSH_LOADED_CURVE_LOADING, SET_FIRST_NEW_CURVE, SET_NEW_CURVE
} from "../../newSimulationActions";
import {combineReducers} from "redux";

const loadedCurvesInitialState: { [key: string]: { curve: Curve, loading: boolean, notLinked?: boolean } } = {}

const curvesReducer = (state = loadedCurvesInitialState, action: newSimulationActionsType) => {
    switch (action.type) {
        case PUSH_LOADED_CURVE :
            const loadedCurve:Curve = action.payload
            if(loadedCurve.id){
                return {...state, [loadedCurve.id]: {curve: loadedCurve, loading: state[loadedCurve.id]?.loading}}
            }
            return state
        case PUSH_LOADED_CURVE_LOADING :
            const {loadedCurveId, loading} = action.payload
            return {...state, [loadedCurveId]: {curve: state[loadedCurveId]?.curve || null, loading}}
        case INIT_SIMULATION_FORM:
            return loadedCurvesInitialState
        default:
            return state;
    }
}

const newCurveReducer = (state: Curve | null = null, action: newSimulationActionsType) => {
    switch (action.type) {
        case SET_NEW_CURVE:
            return action.payload
        case INIT_SIMULATION_FORM:
            return null
        default:
            return state;
    }
}

const firstNewCurveReducer = (state: Curve | null = null, action: newSimulationActionsType) => {
    switch (action.type) {
        case SET_FIRST_NEW_CURVE:
            return action.payload
        case INIT_SIMULATION_FORM:
            return null
        default:
            return state;
    }
}

const loadedCurvesReducer = combineReducers({
    curves: curvesReducer,
    newCurve: newCurveReducer,
    firstNewCurve : firstNewCurveReducer
});
export default loadedCurvesReducer
