import React, {useCallback} from "react";
import {
    makeSelectFromIndexLowCompletenessValue
} from "../../../../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import {useDispatch, useSelector} from "react-redux";
import {typologyFormActions} from "../../../../../../../../../../corelogic/usecases/typology-form/typologyFormActions";
import {
    makeIsMaximumProductionPowerValid
} from "../../../../../../../../../../corelogic/usecases/new-simulation/ValidatorsSelector";

const VolumeInput = ({index}: { index: number }) => {
    const selectVolume = useCallback(makeSelectFromIndexLowCompletenessValue(index), [index])
    const volume = useSelector(selectVolume)
    const dispatch = useDispatch()


    const selectIsVolumeValid = useCallback(makeIsMaximumProductionPowerValid(volume), [volume])
    const isVolumeValid = useSelector(selectIsVolumeValid)
    const style = "border-blue-200 dark:border-opacity-20  bg-blue-200 border rounded px-2 py-1 dark:bg-blue-800 dark:bg-opacity-20  max-w-[120px]"
    const errorStyle = "border-red-200 dark:border-opacity-20  bg-red-200 text-red-700 border rounded px-2 py-1 dark:bg-red-800 dark:bg-opacity-20  max-w-[120px]"
    function onChange(e:any){
        dispatch(typologyFormActions.setLOWVolume(e.target.value, index))
    }
    return <td className="py-1  text-left font-bold text-sm">
        <div className="flex items-left">
            <input className={isVolumeValid ? style : errorStyle}
                   pattern="[0-9]*"
                   required
                   type={"text"}
                   value={volume}
                   id={'energy-volume-recalage'}
                   placeholder={'150'}
                   onChange={(e) => onChange(e)}/>
        </div>
    </td>
}
export default VolumeInput
