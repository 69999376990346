import {useDispatch, useSelector} from "react-redux";
import {newSimulationActions} from "../../../../../../corelogic/usecases/new-simulation/newSimulationActions";
import {BanknoteSolidIcon} from "../../../components/assets/icon/BanknotesIcon";
import React from "react";
import {
    selectShowExternalInvestment
} from "../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectExternalInvestmentForm";

const AddExternalInvestmentButton = () => {
    const dispatch = useDispatch()
    const showInvestmentForm = useSelector(selectShowExternalInvestment)

    const defaultStyle = "flex gap-2 items-center py-2 px-3 bg-gray-100 rounded  dark:bg-zinc-700 "
    const buttonStyle  = defaultStyle + (showInvestmentForm ? ' border-2 border-gray-500 dark:border-gray-400 opacity-100  ' : '  opacity-60 hover:opacity-100  ')

    return (
        <button
        onClick={() => dispatch(newSimulationActions.showExternalInvestment(!showInvestmentForm)) }
        title={'Ajouter du tiers investissement'}
        className={buttonStyle}>
        <BanknoteSolidIcon className={"h-5 w-5 text-gray-600 dark:text-zinc-200"}/>
        <p className={"truncate text-sm text-gray-600  dark:text-zinc-200"}>Ajouter du tiers investissement</p>
    </button>)

}
export default AddExternalInvestmentButton
