import {CapexEntries} from "./CapexEntries";
import {OpexEntries} from "./OpexEntries";
import {
    InflationInterval
} from "../../../../../adapters/primary/ui/composition/form/financial-parameters/variable-inflation-widget/VariableInflationInput";
import {Loan} from "./Loan";


export class FinancialParameters {

    private _duration: number;
    private _global_opex_entries: OpexEntries[] | null
    private _global_capex: CapexEntries | null
    private _complement_provider_kwh_price_inflation_rate: number | InflationInterval[]
    private _selling_price: any
    private _fixedVATRate: number;
    private _variableVATRate: number;


    constructor(duration: number, globalOpexEntries: OpexEntries[] | null, globalCapex: CapexEntries | null, complementProviderKwhPriceInflationRate: number | InflationInterval[], fixedVATRate: number, variableVATRate: number, sellingPrice ?: any | null) {
        this._duration = duration;
        this._global_opex_entries = globalOpexEntries;
        this._global_capex = globalCapex;
        this._complement_provider_kwh_price_inflation_rate = complementProviderKwhPriceInflationRate
        this._selling_price = sellingPrice || null;
        this._fixedVATRate = fixedVATRate;
        this._variableVATRate = variableVATRate;
    }

    static fromObject(financialUpdated : any) :FinancialParameters{
        return new this(
           financialUpdated.duration,
           financialUpdated.global_opex_entries?.map((globalOpexEntries: any) => {
                return new OpexEntries('Opex global', globalOpexEntries.name.trim(), globalOpexEntries.opex, globalOpexEntries.opex_average_inflation);
            }) || undefined,
           financialUpdated.global_capex ? new CapexEntries(financialUpdated.global_capex.capex,financialUpdated.global_capex.loan ?
                new Loan(financialUpdated.global_capex.loan.price,financialUpdated.global_capex.loan.duration, (Number(financialUpdated.global_capex.loan.rate) * 100).toFixed(2)) : null
            ) : null,
            FinancialParameters.complementProviderKwhPriceInflationRateFromObject(financialUpdated.complement_provider_kwh_price_inflation_rate),
            FinancialParameters.vatRateFromObject(financialUpdated.majo_turpe_vat_rate),
            FinancialParameters.vatRateFromObject(financialUpdated.vat_rate),
           financialUpdated.selling_prices || null)
    }

    static vatRateFromObject(rate: number): number {
        return Number((rate * 100).toFixed(2))
    }

    static complementProviderKwhPriceInflationRateFromObject(inflationRate: number[]): number | InflationInterval[] {
        const isFixedInflation = inflationRate.every((val) => val === inflationRate[0]);
        if (isFixedInflation) return inflationRate[0];
        else {
            let inflations: InflationInterval[] = [{value: inflationRate[0], startYear: 1, endYear: 1}];
            inflationRate.map((inflation: number, index: number) => {
                if (inflation !== inflations[inflations.length - 1].value) {
                    inflations[inflations.length - 1].endYear = index + 1;
                    inflations.push({value: inflation, startYear: index + 1, endYear: index + 1});
                }
            });
            inflations[inflations.length - 1].endYear = inflationRate.length + 1;
            return inflations;
        }
    }


    serialize() {
        return {
            duration: this._duration,
            global_opex_entries: this._global_opex_entries?.map((opexentrie) => {
                return opexentrie.serialize()
            }),
            global_capex: this._global_capex?.serialize(),
            complement_provider_kwh_price_inflation_rate: this.serializeInflationRate(this._complement_provider_kwh_price_inflation_rate),
            selling_prices: this._selling_price,
            vat_rate: this.serializePercentToRate(this._variableVATRate),
            majo_turpe_vat_rate: this.serializePercentToRate(this._fixedVATRate)
        }
    }

    private serializePercentToRate(percent: number): number {
        return percent / 100
    }

    private serializeInflationRate(inflationRate: number | InflationInterval[]): number | number[] {
        if (typeof inflationRate === "number") {
            return inflationRate
        } else {
            return inflationRate.map((inflation) => {
                const repeatNtimes = inflation.endYear - inflation.startYear
                return Array(repeatNtimes).fill(inflation.value)
            }).flat()
        }
    }

    get fixedVATRate(): number {
        return this._fixedVATRate;
    }

    set fixedVATRate(value: number) {
        this._fixedVATRate = value;
    }

    get variableVATRate(): number {
        return this._variableVATRate;
    }

    set variableVATRate(value: number) {
        this._variableVATRate = value;
    }

    get selling_price(): any | null {
        return this._selling_price;
    }

    set selling_price(value: any | null) {
        this._selling_price = value;
    }


    get complementProviderKwhPriceInflationRate(): number | InflationInterval[] {
        return this._complement_provider_kwh_price_inflation_rate;
    }

    set complementProviderKwhPriceInflationRate(value: number | InflationInterval[]) {
        this._complement_provider_kwh_price_inflation_rate = value;
    }

    get duration(): number {
        return this._duration;
    }

    set duration(value: number) {
        this._duration = value;
    }

    get globalOpexEntries(): OpexEntries[] | null {
        return this._global_opex_entries;
    }

    set globalOpexEntries(value: OpexEntries[] | null) {
        this._global_opex_entries = value;
    }

    get globalCapex(): CapexEntries | null {
        return this._global_capex;
    }

    set globalCapex(value: CapexEntries | null) {
        this._global_capex = value;
    }
}
