import {
    DistributionAlgorithm,
} from "../../../../../../../corelogic/models/types/new-simulation/form/operation-parameters/distribution/DistributionAlgorithm";
import {
    DistributionPriority as DistributionPriorityModel
} from "../../../../../../../corelogic/models/types/new-simulation/form/operation-parameters/distribution/DistributionPriority";
import React from "react";
import {
    operationParametersFormActions
} from "../../../../../../../corelogic/usecases/operation-parameters-form/operationParametersFormActions";
import {useDispatch, useSelector} from "react-redux";
import {
    selectDistributionAlgorithmForm,
    selectDistributionPrioritiesForm
} from "../../../../../../../corelogic/usecases/operation-parameters-form/operationParametersFormSelector";
import {PriorityInput} from "../PriorityInput";


export class DistributionPriority extends DistributionPriorityModel {

    private readonly _point_name: string;
    private readonly _point_state: boolean;

    constructor(pointId: string, pointName: string, pointState: boolean, priority: number) {
        super(pointId, priority);
        this._point_name = pointName
        this._point_state = pointState
    }

    get pointState() {
        return this._point_state;
    }

    get pointName() {
        return this._point_name;
    }

}

function PriorityRow({
                         priority,
                         onChange,
                         index
                     }: {
    priority: DistributionPriority,
    onChange(priority: DistributionPriority): void,
    index: number
}) {

    function updatePriority(priorityValue: string) {
        const newPriority = isNaN(parseInt(priorityValue)) ? 0 : parseInt(priorityValue)
        onChange(new DistributionPriority(priority.pointId, priority.pointName, priority.pointState, newPriority));
    }

    return <tr key={index}
               className={` 
               ${priority.pointState ? '' : ' opacity-40' }
               ${index % 2 === 0 ? 'bg-gray-50 dark:bg-zinc-800 dark:bg-opacity-30' : ' bg-zinc-100 dark:bg-zinc-800 dark:bg-opacity-[15%]'}
               `}>
        <td className="border dark:border-zinc-700 px-4 py-1 text-sm text-gray-700 dark:text-gray-300">{priority.pointName}</td>
        <td align={'center'} className="border dark:border-zinc-700 px-4 py-1 text-gray-700 dark:text-gray-300">
            <PriorityInput min={1} max={9}  id={`${priority.pointName}-priority`} valeur={priority.priority}
                           onChange={(val: any) => updatePriority(val)}/>
        </td>
    </tr>
}


export default function DistributionByPriorityTable() {
    const dispatch = useDispatch()
    const distributionAlgorithm = useSelector(selectDistributionAlgorithmForm)
    const priorities = useSelector(selectDistributionPrioritiesForm)

    function updatePriorities(newPriority: DistributionPriority) {
        const newPriorities = priorities.map((priority: DistributionPriority) => {
            return newPriority.pointId === priority.pointId ? newPriority : priority
        })
        dispatch(operationParametersFormActions.setDistributionPriorities(newPriorities));
    }

    return <div>
        {distributionAlgorithm === DistributionAlgorithm.by_priority &&
            <div className={'flex flex-col'}>
             <span
                 className={"font-semibold whitespace-nowrap text-md text-gray-700 dark:text-gray-300 my-2 pt-4 pb-2"}>
                Configuration de l'algorithme dynamique par priorité
             </span>
                <div className="flex flex-row justify-between">
                    <div className="flex flex-row gap-4 mb-2">
                    <span className="text-gray-600 dark:text-gray-300 text-xs flex-wrap flex">
                        <b>1</b> : priorité la plus haute
                    </span>
                    </div>
                </div>
                <table
                    className=" relative overflow-x-auto px-2 py-4 table-auto rounded-lg">
                    <thead>
                    <tr className="text-gray-600 dark:text-gray-300 text-sm">
                        <th align={'left'} className="px-4 py-2 text-left">Point de soutirage</th>
                        <th className="px-4 py-2">Priorité</th>
                    </tr>
                    </thead>
                    <tbody>
                    {priorities.map((priority: DistributionPriority, index: number) => {
                        return <PriorityRow index={index} key={priority.pointId} priority={priority}
                                            onChange={updatePriorities}/>
                    })}
                    </tbody>
                </table>
            </div>
        }
    </div>
}
