import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ButtonState} from "../../../../config/app-config";
import {newSimulationActions} from "../../../../corelogic/usecases/new-simulation/newSimulationActions";
import {Step} from "../../../../corelogic/models/types/new-simulation/steps/Step";
import {
    selectSimulationForm,
} from "../../../../corelogic/usecases/new-simulation/new-simulation-selector/form/newSimulationSelector";
import GeneralInfoForm from "../composition/form/general-info-form/GeneralInfoForm";
import InjectionPointsForm from "../composition/form/injection-point-form/InjectionPointsForm";
import Index from "../composition/form/consumption-point-form";
import OperationParametersForm from "../composition/form/operation-parameters-form/OperationParametersForm";
import FinancialParametersForm from "../composition/form/financial-parameters/FinancialParametersForm";
import {selectIsLoadingSimulationForm} from "../../../../corelogic/usecases/loader/loaderSelector";
import {useSnackbar} from "notistack";
import {push} from "connected-react-router";
import ConfirmationDialog from "../composition/confirmation-dialog/ConfirmationDialog";
import {selectAreCreditsRemaining} from "../../../../corelogic/usecases/authentication/authenticationSelector";
import NotFound from "./NotFound";
import {selectLocation, selectPathName} from "../../../../corelogic/usecases/routing/routingSelector";
import SideBar from "../composition/nav-bar/SideBar";
import FormPopup from "../composition/confirmation-dialog/FormPopup";
import PopupVariableInflation
    from "../composition/form/financial-parameters/selling-price/selling-price-table/PopupVariableInflation";
import {
    selectFormDuration,
    selectModalSellingPriceInflations,
    selectSellingPriceInflation,
    selectSellingPriceInflationToggled
} from "../../../../corelogic/usecases/form/formSelector";
import {
    selectCurrentStep, selectNextStep,
    selectSteps
} from "../../../../corelogic/usecases/new-simulation/new-simulation-selector/steps/selectSteps";
import {
    selectFormHasBeenUpdatedNotification,
    selectFormHasNewValidChanges,
    selectFormIsValid
} from "../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-state/selectFormState";

export default function NewSimulationForm() {
    const dispatch = useDispatch()
    // steps : Etats général de mon formulaire
    const steps: Step[] = useSelector(selectSteps)
    // étape courante, prochaine étape demandée
    const currentStep = useSelector(selectCurrentStep)
    const nextStep = useSelector(selectNextStep)
    const isLoading = useSelector(selectIsLoadingSimulationForm)
    const simulationForm = useSelector(selectSimulationForm)
    // déclaration des états de la sidebar
    const [nextStepBtn, setNextStepBtn] = useState(ButtonState.DISABLED)
    const [reduce, setReduce] = useState(false)
    const formHasNewValidChanges = useSelector(selectFormHasNewValidChanges)
    const formIsValid = useSelector(selectFormIsValid)
    const creditLeftToCreateSimulation = useSelector(selectAreCreditsRemaining)
    const location = useSelector(selectLocation)
    const duration = useSelector(selectFormDuration)
    // gestion modal changement de page avec données non valides
    const [confirmationDialog, setConfirmationDialog] = useState(false)
    const formHasBeenUpdatedNotification = useSelector(selectFormHasBeenUpdatedNotification)
    const {enqueueSnackbar} = useSnackbar()
    const locationPathName = useSelector(selectPathName)
    const financialParametersSellingPriceVariableInflationToggle = useSelector(selectSellingPriceInflationToggled)
    const financialParametersSellingPriceFixedInflation = useSelector(selectSellingPriceInflation)
    const openPopupVariableInflations = useSelector(selectModalSellingPriceInflations)
    // inputs
    useEffect(()=> {
        return () => {
            // when leaving form, we re-init form to not do early backend calls with old simulation Id
            dispatch(newSimulationActions.initSimulationForm())
        }
    },[])
    useEffect(() => {
        if (formHasBeenUpdatedNotification) {
            enqueueSnackbar('Le formulaire a été sauvegardé.', {variant: "info"})
            dispatch(newSimulationActions.hasBeenUpdatedNotification(false))
        }
    }, [formHasBeenUpdatedNotification])
    window.addEventListener("resize", () => setReduce(window.innerWidth < 1240));

    function reduceSideBar(reducing: boolean) {
        // Sidebar Expand / Reduced
        setReduce(reducing)
    }

    function continueChangeRouteWithoutSaving() {
        // changement de page après confirmation de non sauvegarde de l'étape en cours
        dispatch(newSimulationActions.setClosingForm(true))
        setConfirmationDialog(false)
    }

    function cancelChangeRoute() {
        // annulation de changement de page
        setConfirmationDialog(false);
        dispatch(newSimulationActions.setNextStepIndex(currentStep.index))
    }


    useEffect(() => {
         // on pathName change, reload form else it wont re-render
        dispatch(newSimulationActions.getSteps())
        dispatch(newSimulationActions.getSimulationForm())

    }, [locationPathName])


    function formIsCompleted() {
        return steps[0].state === ButtonState.REGULAR && steps[1].state === ButtonState.REGULAR && steps[2].state === ButtonState.REGULAR && steps[3].state === ButtonState.REGULAR && formIsValid
    }

    useEffect(() => {
        // activation du bouton "étape suivante" si le formulaire courant est valide
        // désactivation par defaut du bouton au changement d'étape car le formulaire est par défaut vide
        switch (currentStep.index) {
            case 0 :
            case 1 :
            case 2 :
            case 3 :
                formIsValid ? setNextStepBtn(ButtonState.FOCUS) : setNextStepBtn(ButtonState.DISABLED);
                break;
            case 4 :
                formIsCompleted() ? setNextStepBtn(ButtonState.FOCUS) : setNextStepBtn(ButtonState.DISABLED);
                break;
            case 5 :
                dispatch(push(`/simulation/${simulationForm._id}/physical-result`));
                break;
            default :
                setNextStepBtn(ButtonState.DISABLED);
        }
    }, [currentStep, formIsValid])


    function askingForAStepChange() {
        return nextStep.index !== currentStep.index
    }

    useEffect(() => {
        if (askingForAStepChange()) {
            if (formIsValid) {
                dispatch(newSimulationActions.setClosingForm(true))
            } else {
                setConfirmationDialog(true)
            }
        }
    }, [nextStep.index])

    function saveNewChanges() {
        dispatch(newSimulationActions.setSavingForm(true))
        dispatch(newSimulationActions.setHasNewChanges(false))
    }

    return (creditLeftToCreateSimulation || location.pathname.includes('edit') ?
            <div className={isLoading ? "h-full-minus-navbar opacity-80" : "h-full-minus-navbar"}>
                {
                    confirmationDialog && <ConfirmationDialog
                        actionContinue={continueChangeRouteWithoutSaving}
                        actionCancel={() => {
                            cancelChangeRoute()
                        }}
                        title={"Changement d'étape sans données"}
                        content={"L'étape actuelle est soit vide, soit comporte des renseignements manquants. Cliquer sur annuler pour compléter l'étape actuelle ou suivant pour continuer."}
                    />
                }
                {openPopupVariableInflations && financialParametersSellingPriceVariableInflationToggle && duration && <PopupVariableInflation inflation={financialParametersSellingPriceFixedInflation}/>}
                <div className=" flex flex-row w-full  bg-gray-50 rounded dark:bg-gray-800 h-full">
                    <SideBar nextStepBtnState={nextStepBtn} reduceSideBar={reduceSideBar} expanded={!reduce}/>
                    <div className={"flex flex-col w-full "}>
                        {(formHasNewValidChanges || isLoading) && <FormPopup action={saveNewChanges} isLoading={isLoading} />}
                        {currentStep.index === 0 &&
                        <GeneralInfoForm/>}
                        {currentStep.index === 1 &&
                        <InjectionPointsForm/>}
                        {currentStep.index === 2 &&
                        <Index/>}
                        {currentStep.index === 3 &&
                        <OperationParametersForm/>}
                        {currentStep.index === 4 && <FinancialParametersForm/>}
                    </div>
                </div>
            </div>
            : <NotFound/>
    )
}
