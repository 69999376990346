import {SimulationsDisplayGatewayInterface} from "./corelogic/usecases/simulations-display/simulationsDisplayGateway.interface";
import {AuthenticationGatewayInterface} from "./corelogic/usecases/authentication/authenticationGateway.interface";
import {NewSimulationGatewayInterface} from "./corelogic/usecases/new-simulation/newSimulationGateway.interface";
import {AuthenticationApiGateway} from "./adapters/secondary/gateways/Api/AuthenticationApiGateway";
import {PhysicalRestitutionGatewayInterface} from "./corelogic/usecases/physicalRestitution/physicalRestitutionGateway.interface";
import {FinancialRestitutionGatewayInterface} from "./corelogic/usecases/financialRestitution/financialRestitutionGateway.interface";
import {PhysicalRestitutionInApiGateway} from "./adapters/secondary/gateways/Api/PhysicalRestitutionInApiGateway";
import {APINewSimulationGateway} from "./adapters/secondary/gateways/APINewSimulationGateway";
import {APISimulationsDisplayGateway} from "./adapters/secondary/gateways/APISimulationsDisplayGateway";
import {FinancialRestitutionInApiGateway} from "./adapters/secondary/gateways/Api/FinancialRestitutionInApiGateway";
import {RestitutionGatewayInterface} from "./corelogic/usecases/restitution/restitutionGateway.interface";
import {RestitutionApiGateway} from "./adapters/secondary/gateways/Api/RestitutionApiGateway";

export type Dependencies = {
    simulationsDisplayGatewayContext ?: SimulationsDisplayGatewayInterface,
    authenticationGatewayContext ?: AuthenticationGatewayInterface,
    newSimulationGatewayContext ?: NewSimulationGatewayInterface,
    physicalRestitutionGatewayContext ?: PhysicalRestitutionGatewayInterface,
    financialRestitutionGatewayContext ?: FinancialRestitutionGatewayInterface,
    restitutionGatewayContext ?: RestitutionGatewayInterface,
}

export const dependencies: Dependencies = {
    simulationsDisplayGatewayContext : new APISimulationsDisplayGateway(),
    authenticationGatewayContext : new AuthenticationApiGateway(),
    physicalRestitutionGatewayContext : new PhysicalRestitutionInApiGateway(),
    newSimulationGatewayContext : new APINewSimulationGateway(),
    financialRestitutionGatewayContext : new FinancialRestitutionInApiGateway(),
    restitutionGatewayContext : new RestitutionApiGateway(),
}