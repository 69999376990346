import {useDispatch, useSelector} from "react-redux";
import {selectSimulationsSearchedText} from "../../../../../../../corelogic/usecases/simulations-display/simulationsDisplaySelector";
import {simulationsDisplayActions} from "../../../../../../../corelogic/usecases/simulations-display/simulationsDisplayActions";
import {MagnifyingOutlinedIcon} from "../../../../components/assets/icon/MagnifyingGlass";
import React from "react";

const SearchBar = ({
                       setShowOptions,
                       showOptions
                   }: { showOptions: boolean, setShowOptions(opt: boolean): void }) => {
    const dispatch = useDispatch()
    const searchingText = useSelector(selectSimulationsSearchedText)

    function handleOnChange(e: any) {
        dispatch(simulationsDisplayActions.searchOnType(e.target.value))
    }

    function handleOnClick() {
        if (!showOptions) {
            setShowOptions(true)
        }
    }


    return <form className={"w-full p-2 w-[400px]"}>
        <label htmlFor="default-search"
               className="mb-2   text-sm font-medium text-slate-900 sr-only dark:text-white">Recherchez une
            organisation par son nom ...</label>
        <div className="relative w-full ">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <MagnifyingOutlinedIcon className={"w-4 h-4 text-gray-400 dark:text-gray-600"}/>
            </div>
            <input
                onClick={handleOnClick}
                onChange={handleOnChange}
                value={searchingText || ''}
                type="search" id="default-search"
                className="block w-full  px-4 py-2 pl-10 text-sm text-gray-400 border border-slate-700 rounded-lg hover:bg-slate-500 bg-slate-600 focus:ring-blue-500 focus:border-blue-500  dark:border-gray-900 dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:placeholder-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:focus:ring-none"
                placeholder="Recherchez une
            simulation par son nom ..."/>
        </div>
    </form>

}
export default SearchBar