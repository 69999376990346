import {useSelector} from "react-redux";
import {
    makeSelectCanViewExternalInvestment,
    selectProductors
} from "../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectInjectionPointForm";
import {
    selectComplementProviderInflationRateDisplayer
} from "../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectFinancialParameter";

import TitleText from "../../components/assets/text/TitleText";
import SelectorMenu from "../../components/assets/FormAssets/selector/SelectorMenu";
import AddExternalInvestmentButton from "./external-investment-form/AddExternalInvestmentButton";
import React, {useCallback, useEffect} from "react";
import {ProdType} from "../../containers/BalanceSheet";
import {BanknoteSolidIcon} from "../../components/assets/icon/BanknotesIcon";
import { NewReleaseWrapper } from "../wrapper/NewReleaseWrapper";

const HeaderBalance = ({selectedType, setSelectedType, setExternalInvestmentView, externalInvestmentView}: {
    selectedType: ProdType,
    setSelectedType(type: ProdType): void,
    setExternalInvestmentView(viewExternalInvestment: boolean): void,
    externalInvestmentView: boolean,
}) => {
    const productors = useSelector(selectProductors)
    const complementProviderInflationRate = useSelector(selectComplementProviderInflationRateDisplayer);
    const selectCanViewExternalInvestment = useCallback(makeSelectCanViewExternalInvestment(selectedType.id), [selectedType])
    const canViewExternalInvestment: boolean = useSelector(selectCanViewExternalInvestment)

    useEffect(() => {
        if (!canViewExternalInvestment) {
            setExternalInvestmentView(false)
        }
    }, [selectedType, canViewExternalInvestment])
    const handleSelectedType = (type: ProdType) => {
        setSelectedType(type)
    }
    return <div className=" bg-white shadow-sm  flex items-center w-full border-box dark:bg-zinc-800">
        <div className="flex w-full z-[50] justify-between items-center scale-95 2xl:scale-100 px-6 ">
            <TitleText title={"Bilan financier avec inflation à " + complementProviderInflationRate}/>
            <div className={"flex justify-end gap-2"}>
                <AddExternalInvestmentButton/>
                <SelectorMenu selected={selectedType} types={productors} action={handleSelectedType}/>
                {canViewExternalInvestment &&
                    <ToggleExternalView
                        externalInvestmentView={externalInvestmentView}
                        setExternalInvestmentView={setExternalInvestmentView}
                    />}
            </div>
        </div>
    </div>
}

export default HeaderBalance


const selectedSwitchStyle = `py-2 dark:bg-blue-900 dark:text-blue-300 bg-blue-200 text-blue-600 border border-blue-500  font-bold   h-full   flex items-center justify-center  px-2 tracking-wide`
const nonSelectedSwitchStyle = `py-2  dark:border-zinc-600 dark:bg-zinc-800 dark:hover:bg-zinc-700 dark:text-zinc-300 dark:hover:text-zinc-200  bg-slate-50 border border-slate-300  font-regular whitespace-nowrap min-w-min  h-full flex items-center tracking-wide justify-center text-slate-400 px-2 hover:text-slate-600 hover:bg-slate-100 hover:cursor-pointer`

type Props = {
    externalInvestmentView: boolean,
    setExternalInvestmentView(show: boolean): void,
}

const ToggleExternalView = ({externalInvestmentView, setExternalInvestmentView}: Props) => {

    return (
            <div className="w-full h-[42px] shadow rounded-lg  grid text-xs grid-cols-2">
                <div data-cy={'acc-btn'}
                     onClick={() => setExternalInvestmentView(false)}
                     className={(externalInvestmentView ? nonSelectedSwitchStyle : selectedSwitchStyle) + ' rounded-l-lg'}>
                    ACC
                </div>
                <div
                    data-cy={'acc-aci-btn'} onClick={() => setExternalInvestmentView(true)}
                    className={(externalInvestmentView ? selectedSwitchStyle : nonSelectedSwitchStyle) + ' rounded-r-lg'}>
                    ACC + <BanknoteSolidIcon className={"h-5 w-5 text-gray-600 dark:text-zinc-300"}/>
                </div>
            </div>
    )
}
