import {createSelector} from "reselect";
import {selectSimulationForm} from "../form/newSimulationSelector";
import {OperationType} from "../../../../models/types/new-simulation/form/OperationType";

const selectGeneralInfo = createSelector(
    selectSimulationForm,
    simulationForm => simulationForm?._general_info
);

const selectPostcode = createSelector(
    selectGeneralInfo,
    generalInfo => generalInfo?.postcode || ''
)

const selectSimulationName = createSelector(
    selectGeneralInfo,
    generalInfo => generalInfo?.name || ''
)
const selectTypeOfOperation = createSelector(
    selectGeneralInfo,
    generalInfo => generalInfo?.typeOfOperation || null
)

const selectIsOpenOperation = createSelector(
    selectTypeOfOperation,
    typeOfOperation => OperationType[typeOfOperation as keyof typeof OperationType] === OperationType.open
)


const selectIsEstateOperation = createSelector(
    selectTypeOfOperation,
    typeOfOperation => OperationType[typeOfOperation as keyof typeof OperationType] === OperationType.estate
)

const selectIsSocialOperation = createSelector(
    selectTypeOfOperation,
    typeOfOperation => OperationType[typeOfOperation as keyof typeof OperationType] === OperationType.social
)

export {
    selectPostcode,
    selectTypeOfOperation,
    selectGeneralInfo,
    selectSimulationName,
    selectIsSocialOperation,
    selectIsEstateOperation,
    selectIsOpenOperation,

}