import {createSelector} from "reselect";
import {
    selectSimulationForm
} from "../form/newSimulationSelector";
import {FormCapex} from "../../../../../adapters/primary/ui/composition/form/financial-parameters/capex/FormCapex";
import {
    selectActiveInjectionPoints,
    selectActivesInjectionPointCapexes, selectEnabledInjectionPoints,
    selectInjectionPointCapexes,
    selectInjectionPoints
} from "./selectInjectionPointForm";
import {
    InflationInterval
} from "../../../../../adapters/primary/ui/composition/form/financial-parameters/variable-inflation-widget/VariableInflationInput";
import {selectActiveSoutiragePoints, selectConsumptionPoints} from "./selectConsumptionPointForm";
import {ConsumptionPoint} from "../../../../models/types/new-simulation/form/ConsumptionPoint";
import {SellingPriceFunction} from "../../../../models/types/new-simulation/form/SellingPriceFunction";
import {InjectionPoint} from "../../../../models/types/new-simulation/form/InjectionPoint";
import {selectIsOpenOperation} from "./selectGeneralInfoForm";

const selectFinancialParameters = createSelector(
    selectSimulationForm,
    simulationForm => simulationForm?._financial_parameters
);

const selectFinancialDuration = createSelector(
    selectFinancialParameters,
    financialParameters => financialParameters?.duration
)
const selectGlobalCapexes = createSelector(
    selectFinancialParameters,
    (financialParam) => financialParam?.globalCapex ?
        new FormCapex("Capex global", financialParam.globalCapex.capex, financialParam.globalCapex.loan)
        :null
)

const selectCapexes = createSelector(
    selectGlobalCapexes,
    selectInjectionPointCapexes,
    (globalCapexes, injectionPointsCapexes) => {
        if (globalCapexes) {
            if (injectionPointsCapexes) {
                return [globalCapexes, injectionPointsCapexes].flat();
            }
            return [globalCapexes].flat();
        }
        if (injectionPointsCapexes) {
            return [injectionPointsCapexes].flat();
        }
        return [].flat();
    })
const selectActivesCapexes = createSelector(
    selectGlobalCapexes,
    selectActivesInjectionPointCapexes,
    (globalCapexes, injectionPointsCapexes) => {
        if (globalCapexes) {
            if (injectionPointsCapexes) {
                return [globalCapexes, injectionPointsCapexes].flat();
            }
            return [globalCapexes].flat();
        }
        if (injectionPointsCapexes) {
            return [injectionPointsCapexes].flat();
        }
        return [].flat();
    })

const selectHasLoanRepayment = createSelector(
    selectActivesCapexes,
    (capexes) => capexes.some((capex)=> capex?.loan )
)
const selectHasGlobalLoanRepayment = createSelector(
    selectGlobalCapexes,
    selectInjectionPoints,
    (globalCapexes, injectionPoints) => {
        if(globalCapexes && globalCapexes.loan) return true
        return false
    }
)

const makeSelectHasProducerLoanRepayment = (producerId : string) => createSelector(
    selectHasGlobalLoanRepayment,
    selectInjectionPoints,
    (globalLoan, injectionPoints) => {
        if(globalLoan) return true
        return injectionPoints.some((point)=> point.id === producerId && point.capexEntries && point.capexEntries.loan)
    }
)

const selectActiveInjectionPointCapexesWithId = createSelector(
    selectEnabledInjectionPoints,
    (injectionPoints) => injectionPoints.filter((point) => point.capexEntries).map((point) => {
        return {
            point_id: point.id,
            capex: point.capexEntries?.serialize().capex
        }
    })
)

const selectCapexesWithIds = createSelector(
    selectGlobalCapexes,
    selectActiveInjectionPointCapexesWithId,
    (globalCapexes, injectionPointsCapexesWithId) => {
        let map: any = {}
        if (globalCapexes) {
            map["global"] = globalCapexes.capex
        }
        if (injectionPointsCapexesWithId.length) {
            injectionPointsCapexesWithId.forEach(ip_cap => {
                map[ip_cap.point_id] = ip_cap.capex
            })
        }
        return map
    })


const selectGlobalOpexes = createSelector(
    selectFinancialParameters,
    (financialParam) => financialParam?.globalOpexEntries?.map((opex) => opex.serialize()) || null
)
const selectInjectionPointOpexes = createSelector(
    selectInjectionPoints,
    (injectionPoints) => injectionPoints.filter((point) => point.opexEntries).map((point) => point.opexEntries?.map((opex) => opex.serialize()))
)

const selectOpexes = createSelector(
    selectGlobalOpexes,
    selectInjectionPointOpexes,
    (globalOpexes, injectionPoints) => globalOpexes && globalOpexes.length > 0 ? (injectionPoints ?
            [[globalOpexes], injectionPoints].flat()
            : [[globalOpexes]].flat())
        : (injectionPoints ? [injectionPoints].flat()
            : [].flat())
)
const selectComplementProviderInflationRate = createSelector(
    selectFinancialParameters,
    financialParameters => financialParameters?.complementProviderKwhPriceInflationRate
)

const selectComplementProviderInflationRateDisplayer = createSelector(
    selectComplementProviderInflationRate,
    (inflationRate) => typeof inflationRate === "number" ? inflationRate.toString() + "%" : "taux variable"
)
const selectSellingPrice = createSelector(
    selectFinancialParameters,
    financialParameters => financialParameters?.selling_price
)


const selectIsVariableInflation = createSelector(
    selectComplementProviderInflationRate,
    inflation => typeof inflation !== "number"
)
const selectComplementProviderInflationRateArrayString = createSelector(
    selectComplementProviderInflationRate,
    inflationRate => {
        if (inflationRate && typeof inflationRate !== "number") {
            const inflationRateArray: string[] = ["x", "x"]
            inflationRate.forEach((inflation: InflationInterval) => {
                const repeatNtimes = inflation.endYear - inflation.startYear
                const arr: string[] = Array(repeatNtimes).fill(`${inflation.value.toString()} %`)
                inflationRateArray.push(...arr)
            })
            return inflationRateArray
        }
        return null
    }
)
function isSellingPriceCoupleInACI(injectionPoint: InjectionPoint, consumptionPoint: ConsumptionPoint) {
    return consumptionPoint.injectionPointId === injectionPoint.id
}

const selectHasACCBilling = createSelector(
    selectIsOpenOperation,
    selectSellingPrice,
    selectActiveInjectionPoints,
    selectActiveSoutiragePoints,
    (isOpenOperation, sellingPrice, injectionPoints, soutiragePoints) => {
        if (!isOpenOperation) return false
        return soutiragePoints.some((sout: ConsumptionPoint) => {
            return injectionPoints.some((inj) => {
                return sellingPrice[sout.id][inj.id].type !== SellingPriceFunction.GIFTED && !isSellingPriceCoupleInACI(inj, sout)
            })
        })
    }
)
const selectHasGiftedElectricity = createSelector(
    selectIsOpenOperation,
    selectSellingPrice,
    selectActiveInjectionPoints,
    selectActiveSoutiragePoints,
    (isOpenOperation, sellingPrice, injectionPoints, soutiragePoints) => {
        if (!isOpenOperation) return false
        return soutiragePoints.some((sout: ConsumptionPoint) => {
            return injectionPoints.some((inj) => {
                return sellingPrice[sout.id][inj.id].type === SellingPriceFunction.GIFTED
            })
        })
    }
)

const makeSelectHasProducerGiftedElectricity = (producerId: string) => createSelector(
    selectIsOpenOperation,
    selectSellingPrice,
    selectActiveSoutiragePoints,
    (isOpenOperation, sellingPrice, soutiragePoints) => {
        if (!isOpenOperation) return false
        return soutiragePoints.some((sout: ConsumptionPoint) => {
            return sellingPrice[sout.id][producerId].type === SellingPriceFunction.GIFTED
        })
    }
)


export {
    selectOpexes,
    selectInjectionPointOpexes,
    selectCapexes,
    selectActivesCapexes,
    selectCapexesWithIds,
    selectGlobalCapexes,
    selectGlobalOpexes,
    selectFinancialDuration,
    selectFinancialParameters,
    selectHasGlobalLoanRepayment,
    selectHasLoanRepayment,
    selectActivesInjectionPointCapexes,
    selectInjectionPointCapexes,
    selectSimulationForm,
    makeSelectHasProducerLoanRepayment,
    selectComplementProviderInflationRate,
    selectComplementProviderInflationRateArrayString,
    selectComplementProviderInflationRateDisplayer,
    selectIsVariableInflation,
    selectSellingPrice,
    makeSelectHasProducerGiftedElectricity,
    selectConsumptionPoints,
    selectHasACCBilling,
    selectHasGiftedElectricity,
    selectActiveSoutiragePoints,

}
