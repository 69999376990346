import {ExternalInvestment, globalExternalInvestment} from "../ExternalInvestment";
import SelectorInputForExternalInvestment from "../SelectorInputForExternalInvestment";
import {listAvailableExternalInvestment} from "../ExternalInvestment.utils";
import TextInputForTable from "../../../../components/assets/FormAssets/TextInputForTable";
import {newSimulationActions} from "../../../../../../../corelogic/usecases/new-simulation/newSimulationActions";
import {XIconRed} from "../../../../components/assets/icon/XIcon";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    selectExternalInvestments
} from "../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectExternalInvestmentForm";
import {
    selectInjectionPoints
} from "../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectInjectionPointForm";

const ExternalInvestmentFormTable = () => {
    const dispatch  = useDispatch()
    const externalInvestment = useSelector(selectExternalInvestments)
    const injectionPoints = useSelector(selectInjectionPoints)
    function isPointEnabled(injectionPointId: string): boolean {
        return injectionPointId === globalExternalInvestment.id || injectionPoints.some((point) => point.id === injectionPointId && point.state)
    }

    function externalInvestmentOnChange(e: React.ChangeEvent<HTMLSelectElement>, index: number) {
        let newExternalInvestments = [...externalInvestment]
        newExternalInvestments[index].id = e.target.value
        dispatch(newSimulationActions.setExternalInvestments(newExternalInvestments))
    }

    function rentOnChange(e:string, index: number) {
        // Basic Validation
        let newExternalInvestments = [...externalInvestment]
        newExternalInvestments[index].rent = e
        dispatch(newSimulationActions.setExternalInvestments(newExternalInvestments))
    }

    function royaltyOnChange(e: string, index: number) {
        // Basic Validation
        let newExternalInvestments = [...externalInvestment]
        newExternalInvestments[index].royalty = e
        dispatch(newSimulationActions.setExternalInvestments(newExternalInvestments))
    }
    return <table className="bg-white shadow-md dark:bg-zinc-800 w-full  table-auto rounded-lg ">
        <TableHead/>
        <tbody
            className="text-gray-600 text-xs font-light dark:text-zinc-300 ">
        <tr className={"bg-white dark:bg-zinc-800 py-1 h-2"}/>
        {externalInvestment.map((invest: ExternalInvestment, index: number) => {
            const disabledStyle: string = isPointEnabled(invest.id) ? "" : " opacity-40 "
            return <>
                <tr className={" bg-white  dark:bg-zinc-800 " + disabledStyle}>
                    <td align={"left"}
                        className={"  text-sm "}>
                        <div className={"ml-2 "}>
                            <SelectorInputForExternalInvestment valueId={invest.id}
                                                                onChange={(e) => externalInvestmentOnChange(e, index)}
                                                                id={"select-external-investment-" + index}
                                                                options={listAvailableExternalInvestment(externalInvestment, injectionPoints, invest)}/>
                        </div>
                    </td>
                    <td className={""}>
                        <TextInputForTable
                            id={"external-investment-rent-" + index}
                            index={index}
                            onChange={rentOnChange}
                            value={invest.rent}
                            isValid={invest.validateRent()}/>
                    </td>
                    <td className={"ml-2 "}>
                        <TextInputForTable
                            isAFloat
                            id={"external-investment-royalty-" + index}
                            index={index}
                            onChange={royaltyOnChange}
                            value={invest.royalty}
                            isValid={invest.validateRoyalty()}/>

                    </td>
                    <td>
                        <div
                            className={" ml-4 w-min  text-center  rounded-md border-2  p-1 hover:cursor-pointer hover:bg-red-200 border-red-100 hover:border-red-500 bg-red-100 dark:hover:bg-red-700 dark:border-red-800  dark:bg-red-800"}
                            onClick={() => dispatch(newSimulationActions.removeExternalInvestment(invest))}>
                            <XIconRed/>
                        </div>
                    </td>
                </tr>
            </>
        })
        }
        </tbody>
        <div className={"pb-2"}></div>
    </table>
}

export default ExternalInvestmentFormTable

const TableHead = () => <thead className={"sticky top-0"}>
<tr className="bg-slate-100   text-gray-500 text-sm  dark:text-zinc-300 dark:hover:bg-zinc-800  dark:bg-zinc-800 ">
    <th align={"left"} className=" py-5 px-4 bg-slate-100 dark:bg-zinc-800   ">
        Affectation
    </th>
    <th title={"Le nom doit comporter au moins 2 caractères ou deux champs comporte le même nom"}
        align={"right"} className=" py-5 px-4 bg-slate-100 dark:bg-zinc-800   ">
        <div
            className={"flex items-center justify-end"}>
            Loyer (€ /an)
        </div>
    </th>
    <th title={"Le prix doit être un nombre positif"} align={"right"}
        className=" py-5 px-4 bg-slate-100 dark:bg-zinc-800 ">
        <div
            className={"flex items-center justify-end"}>
            Redevance (€ /an)
        </div>

    </th>
    <th align={"left"} className=" py-5 px-2 w-min bg-slate-100 dark:bg-zinc-800 ">
        Action
    </th>
</tr>
</thead>
