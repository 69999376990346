import {useDispatch, useSelector} from "react-redux";
import {
    selectIsLowCompletudeDurationNotOverAYear,
    selectIsLowCompletudeTableCoherent, selectIsLowCompletudeTableNotOverlaping,
    selectIsLowCompletudeTableValid
} from "../../../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import {newSimulationActions} from "../../../../../../../../../corelogic/usecases/new-simulation/newSimulationActions";
import {PuzzlePieceIconSolid} from "../../../../../../components/assets/icon/PuzzlePieceIcon";
import {useSnackbar} from "notistack";


export default function ApplyLowCompletenessButton() {
    const dispatch = useDispatch()
    const isInputValid = useSelector(selectIsLowCompletudeTableValid)
    const isCoherentInput = useSelector(selectIsLowCompletudeTableCoherent)
    const noOverlapsInTable = useSelector(selectIsLowCompletudeTableNotOverlaping)
    const durationLessThanAYear = useSelector(selectIsLowCompletudeDurationNotOverAYear)
    const {enqueueSnackbar} = useSnackbar()
    const inputValidStyle = isInputValid && isCoherentInput && noOverlapsInTable && durationLessThanAYear ? ' opacity-80  hover:opacity-100 hover:cursor-pointer' : ' opacity-50 '

    function applyLowCompleteness() {
        if (isInputValid && isCoherentInput && noOverlapsInTable && durationLessThanAYear) {
            dispatch(newSimulationActions.applyLowDataCompletion())
        } else {
            const errMsg = !isCoherentInput ?
                "Le tableau n'est pas cohérent, il doit être trié dans l'ordre décroissant et ne peut pas se recouvrir." :
                !durationLessThanAYear ?
                    'Le tableau a une durée cumulée de plus de 365 jours.'
                    : 'Le tableau de complétude est invalide ou incomplet.'
            enqueueSnackbar(errMsg, {variant: 'warning'})
        }
    }

    return <div
        className={'flex gap-2  rounded w-min whitespace-nowrap  px-4 py-2 items-center bg-blue-600 text-blue-50 text-sm font-semibold ' + inputValidStyle}
        onClick={applyLowCompleteness}
    >
        Générer une courbe de charge à partir du profil
        <PuzzlePieceIconSolid className={'w-4 h-4 text-blue-50'}/>
    </div>
}
