import {
    ChartPie,
    DocumentChartBar,
    EuroIcon,
    HeadInfos,
    ListNavButtons,
    RedirectNavButton,
    ReduceButton
} from "@enogrid/enocomponent/dist/esm/components/index"
import ArrowsRightLeftIcon from '@enogrid/enocomponent/dist/esm/components/icon/ArrowsRightLeftIcon'
import PresentationChartIcon from '@enogrid/enocomponent/dist/esm/components/icon/PresentationChartIcon'
import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {
    selectSelectedSimulationId
} from "../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form/newSimulationSelector";
import {PathnameEnum} from "../../../../../corelogic/models/types/routing/PathnameEnum";
import {push} from "connected-react-router";
import {newSimulationActions} from "../../../../../corelogic/usecases/new-simulation/newSimulationActions";
import {BanknotesIcon} from "@enogrid/enocomponent/dist/esm/components/icon/BanknotesIcon";
import {configActions} from "../../../../../corelogic/usecases/config/configActions";
import {selectSideBarIsReduced} from "../../../../../corelogic/usecases/config/configSelector";
import {OperationType} from "../../../../../corelogic/models/types/new-simulation/form/OperationType";
import EstateIcon from "../../components/assets/icon/EstateIcon";
import OpenIcon from "../../components/assets/icon/OpenIcon";
import SocialIcon from "../../components/assets/icon/SocialIcon";
import {MapIconOutline} from "../../components/assets/icon/MapIcon";
import {useSnackbar} from "notistack";
import {
    selectPostcode,
    selectSimulationName,
    selectTypeOfOperation
} from "../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectGeneralInfoForm";
import {
    atLeast1ActiveInjectionPointsWithAddress
} from "../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectInjectionPointForm";

type SideBarEnolabType = {
    pathName: string
}
export default function SideBarEnoLab({pathName}: SideBarEnolabType) {
    const dispatch = useDispatch()

    const isReduced = useSelector(selectSideBarIsReduced)
    const name = useSelector(selectSimulationName)
    const postCode = useSelector(selectPostcode)
    const simulationId = useSelector(selectSelectedSimulationId)
    const typeOfOperation = useSelector(selectTypeOfOperation)
    const atLeast1InjectionPointWithAddressActive = useSelector(atLeast1ActiveInjectionPointsWithAddress)
    const {enqueueSnackbar} = useSnackbar()
    const navigateTo = (to: PathnameEnum) => () => {
        dispatch(push(to))
    }

    function handleEditSimulation() {
        dispatch(newSimulationActions.setCurrentStepIndex(0))
        dispatch(newSimulationActions.setNextStepIndex(0))
        dispatch(push(`/simulation/${simulationId}/edit`))
    }

    function handleClickOnPerimeterView(){
        if(atLeast1InjectionPointWithAddressActive){
            dispatch(push(PathnameEnum.PERIMETER_MAP))
        }else {
            enqueueSnackbar("Vous devez avoir au moins 1 point d'injection activé ayant son addresse renseignée pour observer cette page.", {variant :"warning"})
        }
    }

    function IconFromOperationType(reduce: boolean) {
        switch (typeOfOperation) {
            case "open" :
                return <OpenIcon disabled={false} className={reduce ? "h-6 w-6" : "h-8 w-8"}/>
            case "estate" :
                return <EstateIcon disabled={false} className={reduce ? "h-6 w-6" : "h-8 w-8"}/>
            case "social" :
                return <SocialIcon disabled={false} className={reduce ? "h-6 w-6" : "h-8 w-8"}/>
        }
    }

    function IconBasedOnOperationType(reduce: boolean) {
        return <div className={"rounded opacity-80 bg-slate-200 dark:bg-slate-50 p-2"}>
            {IconFromOperationType(reduce)}
        </div>
    }

    const sideBarWidth = isReduced ? ' w-[60px] ' : ' w-[260px] max-w-[260px] min-w-[260px] '
    return (
        <aside
            className={
                'h-full-minus-navbar transition-[width] overflow-x-hidden  flex flex-col justify-between rounded-r-lg border-2 border-gray-200 ShadowM dark:border-zinc-900 ' +
                sideBarWidth
            }
        >
            <div>
                <HeadInfos
                    title={name || ""}
                    descriptions={[OperationType[typeOfOperation as keyof typeof OperationType] || "", postCode]}
                    reduce={isReduced}
                    Icon={(reduce:boolean) => IconBasedOnOperationType(reduce)}
                />
                <hr className={'w-full border-0.5 pb-2 border-gray-200 dark:border-zinc-800'}/>

                <ul className={'flex flex-col justify-start items-start max-h-[calc(100vh-212px)] overflow-y-auto'}>
                    <ListNavButtons
                        reduce={isReduced}
                        sectionName={'Restitution'}
                        sectionButtons={[
                            <RedirectNavButton
                                reduce={isReduced}
                                title={'Bilan énergétique'}
                                selected={pathName === PathnameEnum.PHYSICAL_RESTITUTION}
                                Icon={() => DocumentChartBar({className: 'h-5 w-5 min-w-[20px]'})}
                                actionOnClick={navigateTo(PathnameEnum.PHYSICAL_RESTITUTION)}
                            />,
                            <RedirectNavButton
                                reduce={isReduced}
                                selected={pathName === PathnameEnum.ECONOMICAL_RESTITUTION}
                                title={'Bilan économique'}
                                Icon={() => EuroIcon({className: 'h-5 w-5 min-w-[20px]'})}
                                actionOnClick={navigateTo(PathnameEnum.ECONOMICAL_RESTITUTION)}
                            />,
                            <RedirectNavButton
                                reduce={isReduced}
                                selected={pathName === PathnameEnum.BALANCE_RESTITUTION}
                                title={'Bilan financier'}
                                Icon={() => BanknotesIcon({className: 'h-5 w-5 min-w-[20px]'})}
                                actionOnClick={navigateTo(PathnameEnum.BALANCE_RESTITUTION)}
                            />,
                            <RedirectNavButton
                                reduce={isReduced}
                                selected={pathName === PathnameEnum.RECAP_TABLE_RESTITUTION}
                                title={'Tableaux de synthèse'}
                                Icon={() => PresentationChartIcon({className: 'h-5 w-5 min-w-[20px]'})}
                                actionOnClick={navigateTo(PathnameEnum.RECAP_TABLE_RESTITUTION)}
                            />,
                            <RedirectNavButton
                                reduce={isReduced}
                                selected={pathName === PathnameEnum.ORGANISATIONAL_RESTITUTION}
                                title={'Vue organisationnelle'}
                                Icon={() => ChartPie({className: 'h-5 w-5 min-w-[20px]'})}
                                actionOnClick={navigateTo(PathnameEnum.ORGANISATIONAL_RESTITUTION)}
                            />,
                            <RedirectNavButton
                                reduce={isReduced}
                                selected={pathName === PathnameEnum.PERIMETER_MAP}
                                title={'Carte du périmètre'}
                                Icon={() => MapIconOutline({className: 'h-5 w-5 min-w-[20px]'})}
                                actionOnClick={handleClickOnPerimeterView}
                            />
                        ]}
                    />
                    <ListNavButtons
                        reduce={isReduced}
                        sectionName={'Paramètres'}
                        sectionButtons={[
                            <RedirectNavButton
                                reduce={isReduced}
                                title={'Modifier la simulation'}
                                Icon={() => ArrowsRightLeftIcon({className: 'h-5 w-5 min-w-[20px]'})}
                                actionOnClick={handleEditSimulation}
                            />,
                        ]}
                    />
                </ul>
            </div>
            <ReduceButton reduce={isReduced} action={() => dispatch(configActions.setSideBarIsReduced(!isReduced))}/>
        </aside>
    )
}
