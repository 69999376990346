
function InformationCircleIcon ({textcolor, width}: { textcolor?:string, width?:string }){
    const w:string = width ? width : " h-5 w-5 "
    const textc:string = textcolor?textcolor : " text-slate-400 "
    return <svg xmlns="http://www.w3.org/2000/svg" className={w+textc} viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clipRule="evenodd"/>
    </svg>
}


function InformationCircleIconOrangeOnHover(){
    return <svg xmlns="http://www.w3.org/2000/svg" className={ "h-5 w-5 text-slate-400 hover:text-orange-500"} viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clipRule="evenodd"/>
    </svg>
}
function InformationCircleOutlinedIcon ({className}:{className:string}){
    return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={className}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
    </svg>


}
export {InformationCircleIcon, InformationCircleIconOrangeOnHover, InformationCircleOutlinedIcon}
