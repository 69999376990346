import AdjustmentIcon from "../../../../../components/assets/icon/AdjustmentIcon";
import Divider from "../../../../../components/assets/Divider";
import {purple, zinc} from "../../../../../../../../config/app-config";
import BeakerIcon from "../../../../../components/assets/icon/BeakerIcon";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {typologyFormActions} from "../../../../../../../../corelogic/usecases/typology-form/typologyFormActions";
import {
    selectSGEFormTab,
    selectSGEResultTab
} from "../../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import {
    selectNewCurve
} from "../../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-curve/selectFormCurve";

export default function TabSelector(){
    return <div className={"flex items-end  "}>
        <FormTabHeader/>
        <ResultTabHeader/>
        <div className={"w-full"}>
            <Divider/>
        </div>
    </div>
}

const FormTabHeader = () => {
    const dispatch = useDispatch()
    const tabSelected = useSelector(selectSGEFormTab)
    const style = tabSelected ?  "flex items-center mx-4 gap-1 dark:text-gray-300"  :"hover:cursor-pointer opacity-80 hover:opacity-100 flex items-center gap-1 mx-4 text-gray-700  dark:text-gray-400"

    function handleFormTabClicked(){
        dispatch(typologyFormActions.clickOnSGEFormTab())
    }
    return <div>
        <div onClick={handleFormTabClicked} className={style}>
            <AdjustmentIcon className={"h-4 w-4 "}/>
            <span className={"font-semibold truncate text-md"}>Formulaire de récupération</span>
        </div>
        <Divider color={tabSelected ?purple : zinc } twHeight={' my-2 h-1'}/>
    </div>
}
const ResultTabHeader = () => {
    const dispatch = useDispatch()

    const tabSelected = useSelector(selectSGEResultTab)
    const hasResults = useSelector(selectNewCurve)
    const style = tabSelected ?  " flex items-center mx-4 gap-1 dark:text-gray-200" :(hasResults ?
        " hover:cursor-pointer opacity-80 hover:opacity-100 flex items-center gap-1 mx-4 text-gray-700 dark:text-gray-400 "
    :" flex items-center gap-1 mx-4 text-gray-500 ")

    function handleFormTabClicked(){
        dispatch(typologyFormActions.clickOnSGEResultTab())
    }
    return <div className={""}>
        <div onClick={handleFormTabClicked}  className={style}>
            <BeakerIcon className={"h-4 w-4"} disabled={false}/>
            <span className={"font-semibold truncate text-md"}>Résultats de récupération</span>
        </div>
        <Divider color={hasResults ? purple : zinc} twHeight={'  my-2  '+ (hasResults? ' h-1 ' : ' h-px ')}/>
    </div>
}
