import {
    DistributionAlgorithm,
} from "../../../../../../../corelogic/models/types/new-simulation/form/operation-parameters/distribution/DistributionAlgorithm";
import {
    DistributionShare as DistributionShareModel
} from "../../../../../../../corelogic/models/types/new-simulation/form/operation-parameters/distribution/DistributionShare";
import React from "react";
import TextInput from "../../../../components/assets/FormAssets/TextInput";
import {
    operationParametersFormActions
} from "../../../../../../../corelogic/usecases/operation-parameters-form/operationParametersFormActions";
import {useDispatch, useSelector} from "react-redux";
import {
    selectDistributionAlgorithmForm,
    selectDistributionSharesForm
} from "../../../../../../../corelogic/usecases/operation-parameters-form/operationParametersFormSelector";
import {PriorityInput} from "../PriorityInput";


export class DistributionShare extends DistributionShareModel {

    private readonly _point_name: string;
    private readonly _point_state : boolean;
    private readonly _shares_percentage: number;
    private readonly _disabled: boolean;

    constructor(pointId: string, pointName: string,pointState : boolean, share: number, disabled: boolean = false, shares_percentage?: number) {
        super(pointId, share);
        this._point_name = pointName
        this._shares_percentage = shares_percentage ?? 0
        this._disabled = disabled
        this._point_state = pointState
    }
    get pointState(){
        return this._point_state
    }
    get pointName() {
        return this._point_name;
    }

    get sharesPercentage() {
        return this._shares_percentage;
    }

    get disabled(){
        return this._disabled;
    }

}

function ShareRow({
                      share,
                      onChange,
    index
                  }: { share: DistributionShare, onChange(share: DistributionShare): void , index:number}) {

    function updateShare(shareValue: string) {
        const newShare = isNaN(parseInt(shareValue)) ? 0 : parseInt(shareValue)
        onChange(new DistributionShare(share.pointId, share.pointName, share.pointState, newShare, share.disabled));
    }

    return                 <tr key={index} className={`
    ${share.pointState ? '' : ' opacity-40' }
    ${index % 2 === 0 ? 'bg-gray-50 dark:bg-zinc-800 dark:bg-opacity-30' : ' bg-zinc-100 dark:bg-zinc-800 dark:bg-opacity-[15%]'}
    `}>
    <td className="border dark:border-zinc-700 px-4 py-1 text-sm text-gray-700 dark:text-gray-300">{share.pointName}</td>
        <td align={"center"} className="w-[60px] border dark:border-zinc-700 px-4 py-1  text-gray-700 dark:text-gray-300">
            <PriorityInput  min={0} max={99} id={`${share.pointName}-share`} valeur={share.shares} onChange={(val: any) => updateShare(val)}/>
        </td>
        <td align={"center"} className="border dark:border-zinc-700 px-4 py-1 text-sm text-gray-700 dark:text-gray-300">{share.sharesPercentage.toFixed(2)} %</td>
    </tr>
}



export function sharesWithPercentage(shares: DistributionShare[]): DistributionShare[] {
    const totalShares = shares.reduce((sum, current) => !current.disabled ? sum + current.shares : sum, 0)
    return shares.map((p: DistributionShare) => {
        const sharesPercentage = !p.disabled ? p.shares / totalShares * 100 : 0
        return new DistributionShare(p.pointId, p.pointName,p.pointState, p.shares, p.disabled,  sharesPercentage)
    })
}


export default function DistributionBySharesTable() {
    const distributionShares = useSelector(selectDistributionSharesForm)
    const distributionAlgorithm = useSelector(selectDistributionAlgorithmForm)
    const dispatch = useDispatch()
    function updateShares(newShare: DistributionShare) {
        const newShares = distributionShares.map((share: DistributionShare) => {
            return newShare.pointId === share.pointId ? newShare : share
        })
        const newSharesWithPercentage = sharesWithPercentage(newShares)
        dispatch(operationParametersFormActions.setDistributionShares(newSharesWithPercentage));
    }

    return <div>
        {distributionAlgorithm === DistributionAlgorithm.by_share &&
            <div className={'flex flex-col'}>
             <span className={"font-semibold whitespace-nowrap text-md text-gray-700 dark:text-gray-300  my-2 pt-4 pb-2"}>
                Configuration de l'algorithme dynamique par quote-part
            </span>
            <table className="table-auto w-full my-4">
                <thead>
                <tr className="text-gray-600 dark:text-gray-300 text-sm">
                    <th className="px-4 py-2 w-[250px]">Point de soutirage</th>
                    <th className="px-4 py-2 w-[150px] ">Parts</th>
                    <th className="px-4 py-2 w-[150px] ">Pourcentage</th>
                </tr>
                </thead>
                <tbody>
                {distributionShares.map((share: DistributionShare, index:number) => {
                    return <ShareRow index={index} key={share.pointId} share={share} onChange={updateShares}/>
                })}
                </tbody>
            </table>
            </div>}
    </div>
}
