import {DoubleChevronUp} from "../icon/DoubleChevronUp";
import {DoubleChevronDown} from "../icon/DoubleChevronDown";
import Paper from "../../../composition/paper/Paper";




type noteType = {
    title: string|JSX.Element,
    descriptions: string[],
    icon: JSX.Element
}
type WhatsNewType = {
    version: string,
    release_date: string,
    major: noteType[],
    minor: noteType[],
    bugfixes: { type : string, description :string }[],
    selected: boolean,
    handleExpandClick: (versionClicked: string) => void,
}
export default function WhatsNew({
                                     version,
                                     release_date,
                                     major,
                                     minor,
                                     bugfixes,
                                     selected = false,
                                     handleExpandClick
                                 }: WhatsNewType) {


    return <div className={""}>
        <div className={"sticky top-0 bg-yellow-500 dark:bg-amber-500 "}>
            <div className={"flex justify-between items-center"}>
                <div>
                    <div className={"flex px-8 pt-4 text-yellow-100"}>
                        <div>version:</div>
                        <div className={"text font-semibold ml-2"}>{version}</div>
                    </div>
                    <div className={"flex px-8 pb-4 text-yellow-100"}>
                        <div>date de livraison:</div>
                        <div className={"text font-semibold ml-2"}>{release_date}</div>
                    </div>
                </div>
                <div className={"pr-8 hover:cursor-pointer"} onClick={() => handleExpandClick(version)}>
                    {selected ?
                        <DoubleChevronUp className={"h-6 w-6 text-yellow-100 "}/>
                        : <DoubleChevronDown className={"h-6 w-6 text-yellow-100 "}/>}
                </div>
            </div>
        </div>

        {selected ?
            <div
                className="bg-white dark:bg-gray-800 flex-col px-8 py-4 overflow-y-auto min-h-min h-full box-border max-w-8/12 relative top-0">

                {
                    major.length > 0 && <>
                    <div className={"text-xl text-gray-700 dark:text-gray-300 font-black pt-6 pb-2"}>Nouvelles
                        Fonctionnalités Majeures
                    </div>
                    {
                        major.map((majorRelease: any) => {
                            return <Paper
                                title={majorRelease.title}
                                descriptions={majorRelease.descriptions}
                                icon={majorRelease.icon}
                            />
                        }
                    )}
                </>
                }
                {minor.length > 0 && <>
                    <div className={"text-lg text-gray-700 dark:text-gray-300 font-black pt-6 pb-2"}>Fonctionnalités
                        Techniques
                    </div>
                    {
                        minor.map((minorFeature) => {
                            return <Paper
                                title={minorFeature.title}
                                descriptions={minorFeature.descriptions}
                                icon={minorFeature.icon}/>
                        })
                    }
                </>
                }
                {
                    bugfixes.length > 0 &&
                    <>
                        <div className={"text-md text-gray-700 dark:text-gray-300 font-black pt-6 pb-2"}>Corrections de
                            Bug
                        </div>
                        {
                            bugfixes.map((bug) =>
                                <div className={"flex gap-2 items-center"}>
                                <span
                                    className={"bg-gray-300 rounded-sm font-semibold dark:bg-gray-800 text-gray-600 dark:text-gray-400 px-1 py-0.5 text-[10px] uppercase"}>
                                    {`${bug.type}`}
                                </span>
                                <span
                                className={"text-gray-600 dark:text-gray-400 text-xs"}>{` ${bug.description}`}</span>
                                </div>)
                        }
                    </>
                }
            </div> :
            <div
                className="bg-white dark:bg-gray-800 flex-col px-8 py-4 overflow-y-auto h-0 box-border max-w-8/12 relative top-0"/>
        }
    </div>
}
