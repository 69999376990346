import {isPriceValid, makeIsPriceValid} from "../../../../../../corelogic/usecases/new-simulation/ValidatorsSelector";

export const globalExternalInvestment = {
    id: 'GLOBAL_EXTERNAL_INVESTMENT',
    name: 'Tiers Investissement Global'
}



type BackendGlobalExternalInvestment = {
    rent : number,
    royalties : number
}
type BackendProducerExternalInvestment = {
    rent : number,
    royalties : number,
    injection_point_id : string
}


export class ExternalInvestment {
    constructor(public  id: string , public  rent: string, public  royalty: string) {
    }

    clone():ExternalInvestment{
        return new ExternalInvestment(this.id, this.rent, this.royalty)
    }
    serialize() {
        let serialized: {
            injection_point_id?: string,
            rent: number,
            royalties: number
        } = {
            rent: Number(this.rent),
            royalties: Number(this.royalty)
        }
        if (this.id !== globalExternalInvestment.id) {
            serialized.injection_point_id = this.id
        }
        return serialized
    }

    static fromObject(externalInvestment : BackendGlobalExternalInvestment | BackendProducerExternalInvestment[]):ExternalInvestment[]{
       if(Array.isArray(externalInvestment)){
           return externalInvestment.map((invest:BackendProducerExternalInvestment) => new ExternalInvestment(invest.injection_point_id, invest.rent.toString(), invest.royalties.toString()))
       }else {
           return [new ExternalInvestment(globalExternalInvestment.id,  externalInvestment.rent.toString(), externalInvestment.royalties.toString())]
       }
    }


    validateRent():boolean{
        return isPriceValid(this.rent)
    }
    validateRoyalty():boolean{
        return isPriceValid(this.royalty)
    }

    validate():boolean{
        return this.validateRoyalty() && this.validateRent()
    }
}
