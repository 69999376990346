import {InjectionPoint} from "../../../models/types/new-simulation/form/InjectionPoint";
import {sanitizeSurplusAllocation} from "./surplusAllocation.utils";
import {
    SurplusAllocation,
    SurplusAllocationAlgorithm
} from "../../../models/types/new-simulation/form/operation-parameters/surplus-allocation/SurplusAllocation";

export type BackendSurplusAllocation = {
    surplus_algorithm: SurplusAllocationAlgorithm,
    surplus_priorities: { point_id: string, priority: number }[]
}

function getInjectionPointFromId(pointId: string, injectionPoints: InjectionPoint[]): InjectionPoint | undefined {
    return injectionPoints.find((ip: InjectionPoint) => ip.id === pointId)

}


export const SurplusAllocationAPI = {
    fromObjectSurplusAllocation(surplus_allocation: BackendSurplusAllocation, injectionPoints: InjectionPoint[]): SurplusAllocation {

        let surplusAllocation: SurplusAllocation = {
            selectedAlgorithm: SurplusAllocationAlgorithm.by_default,
            priorities: []
        }
        if (!injectionPoints || !surplus_allocation) return surplusAllocation

        if (surplus_allocation?.surplus_algorithm === SurplusAllocationAlgorithm.by_priority) {
            const priorities : { pointId: string, priority: number, pointName: string, pointState : boolean }[] = []
            surplus_allocation.surplus_priorities.forEach((p: any) => {
                const point = getInjectionPointFromId(p.point_id, injectionPoints)
                if(!point) return
                priorities.push({
                    pointId: p.point_id,
                    priority: p.priority,
                    pointName: point?.name || "",
                    pointState: point?.state || false
                })
            })
            surplusAllocation = {
                selectedAlgorithm: SurplusAllocationAlgorithm.by_priority,
                priorities: priorities
            }
        } else {
            surplusAllocation = {
                selectedAlgorithm: SurplusAllocationAlgorithm.by_default,
                priorities: []
            }
        }

        if (surplusAllocation.priorities.length < injectionPoints.length) {
            injectionPoints.forEach((injectionPoint) => {
                if (!surplusAllocation.priorities.find((priority) => priority.pointId === injectionPoint.id)) {
                    surplusAllocation.priorities.push({
                        pointId: injectionPoint.id,
                        priority: 9,
                        pointName: injectionPoint.name,
                        pointState: injectionPoint.state
                    })
                }
            })
        }
        return surplusAllocation
    },
    serializeSurplusAllocation(surplusAllocation: SurplusAllocation): BackendSurplusAllocation {
        const sanitizedSurplusAllocation = sanitizeSurplusAllocation(surplusAllocation)
        return {
            surplus_algorithm: sanitizedSurplusAllocation.selectedAlgorithm,
            surplus_priorities: sanitizedSurplusAllocation.priorities.map((p: any) => ({
                point_id: p.pointId,
                priority: p.priority
            }))
        }
    }
}
