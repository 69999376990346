import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedSimulationId } from '../../../../corelogic/usecases/new-simulation/new-simulation-selector/form/newSimulationSelector';
import { useEffect } from 'react';
import {
    ExecutionStatus,
    simulationExecutionStatusActions,
} from '../../../../corelogic/usecases/simulationExecutionStatus/simulationExecutionStatusActions';
import { selectSimulationExecutionStatus } from '../../../../corelogic/usecases/simulationExecutionStatus/simulationExecutionStatusSelector';

export const useSimulationExecutionStatus = () => {
    const simulationId: string = useSelector(selectSelectedSimulationId);
    const dispatch = useDispatch();
    const status: ExecutionStatus = useSelector(selectSimulationExecutionStatus);

    useEffect(() => {
        if (simulationId) {
            dispatch(simulationExecutionStatusActions.getSimulationExecutionStatus());
        }
    }, [simulationId]);

    return status;
};
