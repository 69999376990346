import {RootState} from "../../../store/rootReducer";
import {createSelector} from "reselect";
import {formatNumber} from "../config";
import {SellingPriceFunction} from "../../models/types/new-simulation/form/SellingPriceFunction";
import {
    selectConsumptionPoints,
    selectSellingPrice
} from "../new-simulation/new-simulation-selector/form-data/selectFinancialParameter";
import {
    makeSelectIsExternalInvestHybrid,
    selectActiveInjectionPoints
} from "../new-simulation/new-simulation-selector/form-data/selectInjectionPointForm";
import {selectACIProducersNames} from "../new-simulation/new-simulation-selector/form-data/selectConsumptionPointForm";
import { selectSimulationExecutionStatus } from "../simulationExecutionStatus/simulationExecutionStatusSelector";
import { ExecutionStatus, simulationExecutionStatusActions } from "../simulationExecutionStatus/simulationExecutionStatusActions";

const selectFinancialData = (state: RootState): any => state.financialData;

const selectBalance = createSelector(
    selectFinancialData,
    financialData => financialData.balance
)

const selectCashFlow = createSelector(
    selectBalance,
    balance => balance.cashFlow,
)
const selectBalanceMetrics = createSelector(
    selectBalance,
    balance => balance.metrics,
)

export const selectCashFlowData = createSelector(
    selectCashFlow,
    cashFlow => cashFlow.data,
)
export const selectCashFlowExternalInvestmentData = createSelector(
    selectCashFlow,
    cashFlow => cashFlow.dataExternalInvestment,
)
export const selectCashFlowExternalInvestmentProducerData = createSelector(
    selectCashFlow,
    cashFlow => cashFlow.dataExternalInvestmentProducer,
)
export const selectCashFlowLoading = createSelector(
    selectCashFlow,
    cashFlow => cashFlow.loading,
)

const selectBalanceMetricsCombinedData = createSelector(
    selectBalanceMetrics,
    balanceMetrics => balanceMetrics.data
)
const selectBalanceMetricsData = createSelector(
    selectBalanceMetricsCombinedData,
    balanceMetricsCombinedData => balanceMetricsCombinedData.global
)
const selectBalanceInvestorMetricsData = createSelector(
    selectBalanceMetricsCombinedData,
    balanceMetricsCombinedData => balanceMetricsCombinedData.investor
)
const selectBalanceInvestorProducerMetricsData = createSelector(
    selectBalanceMetricsCombinedData,
    balanceMetricsCombinedData => balanceMetricsCombinedData.investorProducer
)

export const selectInvestorTRI = createSelector(
    selectBalanceInvestorMetricsData,
    balanceMetricsData => balanceMetricsData.tri !== 'xx' ? formatNumber((balanceMetricsData.tri || 0) * 100, 1) : 'xx'
)
export const selectInvestorTRA = createSelector(
    selectBalanceInvestorMetricsData,
    balanceMetricsData => balanceMetricsData.tra !== 'xx'  ? formatNumber(balanceMetricsData.tra || 0, 1) : 'xx'
)

export const selectInvestorProducerTRI = createSelector(
    selectBalanceInvestorProducerMetricsData,
    balanceMetricsData => balanceMetricsData.tri !== 'xx' ? formatNumber((balanceMetricsData.tri || 0) * 100, 1) : 'xx'
)
export const selectInvestorProducerTRA = createSelector(
    selectBalanceInvestorProducerMetricsData,
    balanceMetricsData => balanceMetricsData.tra !== 'xx'  ? formatNumber(balanceMetricsData.tra || 0, 1) : 'xx'
)

export const makeSelectInvestorProducerTRI = (selectedTypeId : string) => createSelector(
    selectInvestorProducerTRI,
    makeSelectIsExternalInvestHybrid(selectedTypeId),
    (producerTRI, isHybrid) => isHybrid ? producerTRI : undefined
)
export const makeSelectInvestorProducerTRA = (selectedTypeId : string) => createSelector(
    selectInvestorProducerTRA,
    makeSelectIsExternalInvestHybrid(selectedTypeId),
    (producerTRA, isHybrid) => isHybrid ? producerTRA : undefined
)


export const selectInvestorProducerLCOE = createSelector(
    selectBalanceInvestorProducerMetricsData,
    balanceMetricsData => balanceMetricsData.lcoe !== 'xx' ? formatNumber(balanceMetricsData.lcoe*100 || 0, 1) : 'xx'
)
export const selectInvestorProducerInternalValuation = createSelector(
    selectBalanceInvestorProducerMetricsData,
    balanceMetricsData => balanceMetricsData.internalValuation !== 'xx' ? formatNumber(balanceMetricsData.internalValuation *100 || 0, 1) : 'xx'
)

export const selectBalanceMetricsLoading = createSelector(
    selectBalanceMetrics,
    selectSimulationExecutionStatus,
    (balanceMetrics, simulationExecutionStatus) => balanceMetrics.loading || simulationExecutionStatus === ExecutionStatus.running

)

export const selectTRI = createSelector(
    selectBalanceMetricsData,
    balanceMetricsData => balanceMetricsData.tri !== 'xx' ? formatNumber((balanceMetricsData.tri || 0) * 100, 1) : 'xx'
)

export const selectTRA = createSelector(
    selectBalanceMetricsData,
    balanceMetricsData => balanceMetricsData.tra !== 'xx'  ? formatNumber(balanceMetricsData.tra || 0, 1) : 'xx'
)

export const selectLCOE = createSelector(
    selectBalanceMetricsData,
    balanceMetricsData => balanceMetricsData.lcoe !== 'xx' ? formatNumber(balanceMetricsData.lcoe*100 || 0, 1) : 'xx'
)

export const selectInternalValuation = createSelector(
    selectBalanceMetricsData,
    balanceMetricsData => balanceMetricsData.internalValuation !== 'xx' ? formatNumber(balanceMetricsData.internalValuation *100 || 0, 1) : 'xx'
)

const selectEconomy = createSelector(
    selectFinancialData,
    financialData => financialData.economy
)

const selectEconomies = createSelector(
    selectEconomy,
    economy => economy?.economies
)

const selectCostsAndGains = createSelector(
    selectFinancialData,
    financialData => financialData.costsAndGains
)

export const selectEconomiesData = createSelector(
    selectEconomies,
    economies => economies.data
)

export const selectACCEconomiesData =  createSelector(
    selectEconomiesData,selectACIProducersNames,selectActiveInjectionPoints,
    (economiesData, ACIProducersNames, activeInjectionPoints) => {
        if (activeInjectionPoints.length === 1){
            return economiesData.filter((ecoData:any) => !ACIProducersNames.has(ecoData.name));
        }
        return economiesData;
    }
)

export const selectNoNullACCEconomiesData =  createSelector(
    selectACCEconomiesData,
    (savings) => savings.filter(({collectiveAcFirstYearSaving, internalBillFirstYear, consumerGainFirstYear}: any) =>
        !!collectiveAcFirstYearSaving || !!internalBillFirstYear || !!consumerGainFirstYear)
)
export const selectACIEconomiesData =  createSelector(
    selectEconomiesData,selectACIProducersNames,
    (economiesData,ACIProducersNames) => {
        return economiesData.filter((ecoData:any) => ACIProducersNames.has(ecoData.name) || !ecoData.name )
    }
)
export const selectConsumersWithGiftedEnergy = createSelector(
    selectSellingPrice,
    selectConsumptionPoints,
    (sellingPrices, consumptionPoints) => {
        return consumptionPoints.filter(({id})=> Object.values(sellingPrices[id]).some(({type}:any) => type === SellingPriceFunction.GIFTED))
    }
)
export const selectACIAndGiftedElectricityEconomiesData = createSelector(
    selectEconomiesData,
    selectACIProducersNames,
    selectConsumersWithGiftedEnergy,
    (economiesData,ACIProducersNames, consumersWithGiftedEnergy) => {
        const consumersNames = consumersWithGiftedEnergy.map((consumer)=> consumer.name)
        return economiesData.filter((ecoData:any) => ACIProducersNames.has(ecoData.name) || !ecoData.name || consumersNames.includes(ecoData.name) )
    }
)

export const selectEconomiesLoading = createSelector(
    selectEconomies,
    selectSimulationExecutionStatus,
    (economies, simulationExecutionStatus) => economies.loading || simulationExecutionStatus === ExecutionStatus.running
)

export const selectCostsData = createSelector(
    selectCostsAndGains,
    costsAndGains => costsAndGains.data
)

export const selectSurplusLoading = createSelector(
    selectCostsAndGains,
    selectSimulationExecutionStatus,
    (costsAndGains, simulationExecutionStatus) => costsAndGains.loading || simulationExecutionStatus === ExecutionStatus.running
)

const selectEconomyMetrics = createSelector(
    selectEconomy,
    economy => economy.metrics
)

const selectEconomyMetricsData = createSelector(
    selectEconomyMetrics,
    metrics => metrics.data
)

export const selectGlobalEconomies = createSelector(
    selectEconomiesData,
    economiesData => economiesData.global || {}
)


export const selectTotalEconomyYear1 = createSelector(
    selectEconomyMetricsData,
    metrics => formatNumber(metrics.totalEconomyYear1 || 0)
)
export const selectProductorGainAndExcessYear1 = createSelector(
    selectEconomyMetricsData,
    metrics => formatNumber(metrics.productorGainAndExcessYear1 || 0)
)
export const selectProductorGainAndExcessYear25 = createSelector(
    selectEconomyMetricsData,
    metrics => formatNumber(metrics.productorGainAndExcessYear25 || 0)
)

export const selectTotalEconomyYear25 = createSelector(
    selectEconomyMetricsData,
    metrics => formatNumber(metrics.totalEconomyYear25 || 0)
)

export const selectEconomyMetricsLoading = createSelector(
    selectEconomyMetrics,
    selectSimulationExecutionStatus,
    (economyMetrics, simulationExecutionStatus) => economyMetrics.loading || simulationExecutionStatus === ExecutionStatus.running
)



