import React, {ChangeEvent} from "react";

type TextInputForTableType = {
    isValid : boolean,
    onChange(event :any, index : number):void,
    index : number,
    value : string,
    id : string,
    isAFloat ?: boolean,
    loading ?:boolean,
    disabled?:boolean,
}
export default function TextInputForTable({isValid, onChange, index, value, id, isAFloat, loading, disabled } : TextInputForTableType){


    const validInputStyle = "w-full font-semibold    text-right text-sm focus:outline-none px-4 py-2  bg-slate-100 whitespace-nowrap   hover:bg-slate-200 dark:bg-zinc-700 dark:hover:bg-zinc-600"
    const invalidInputStyle = 'w-full font-semibold  px-4 py-2 text-right text-sm text-red-500   border-box whitespace-nowrap  border-red-500   bg-red-200 focus:outline-none dark:border-red-900 dark:bg-red-800  '

    function handleOnChange(e:ChangeEvent<HTMLInputElement>, index:number){
        isAFloat ? onChange(e.target.value.replace(/,/g, ".").replace(/\s/g, ''), index) : onChange(e.target.value, index)
    }

    return <input
        disabled={disabled}
        data-cy={id}
        type={"text"}
        onChange={(e)=>handleOnChange(e, index)}
        tabIndex={0}
        className={isValid ? validInputStyle : invalidInputStyle}
        id={id}
        value={loading ? 'chargement...':value}/>
}
