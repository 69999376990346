import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    selectLowCompletenessTableFirstDateStartDays,
    selectLowCompletenessTableFirstDateStartHasError
} from "../../../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import {useSnackbar} from "notistack";
import {typologyFormActions} from "../../../../../../../../../corelogic/usecases/typology-form/typologyFormActions";
import {RocketLaunchIcon} from "@heroicons/react/24/solid";
import {NewReleaseWrapper} from "../../../../../wrapper/NewReleaseWrapper";

const ReplaceBy12MonthlyRows = () => {
    const dispatch = useDispatch()
    const {enqueueSnackbar} = useSnackbar()

    const disabled = useSelector(selectLowCompletenessTableFirstDateStartHasError)
    const firstDay = useSelector(selectLowCompletenessTableFirstDateStartDays)
    const style= " self-end gap-2 whitespace-nowrap flex w-min rounded px-3 py-2 text-xs text-blue-600 dark:text-blue-100 bg-blue-200 dark:bg-opacity-20 "
    const enabledStyle = disabled ? ' opacity-60 ' : ' hover:opacity-100 opacity-90 hover:cursor-pointer font-semibold '
    function handleClick() {
        if (disabled) {
            enqueueSnackbar("La première cellule du tableau (1er jour) doit être valide.", {variant: 'warning'})
        } else {
            dispatch(typologyFormActions.generate12MonthsStartingOnDay(firstDay))
        }
    }

    return <NewReleaseWrapper title={'nouveau'} relativePosition={" right-4 bottom-4 "}><div
        onClick={handleClick}
        className={style + enabledStyle}>
        Générer 12 lignes mensuelles débutant le {firstDay ? firstDay : '<1er jour manquant>' }
        <RocketLaunchIcon className={'w-4 h-4 text-blue-600  dark:text-blue-100'}/>
    </div>
    </NewReleaseWrapper>
}

export default ReplaceBy12MonthlyRows
