import React, {useState} from "react";
import DropDownMenu from "../../paper/DropDownMenu";
import {NewReleaseWrapper} from "../../wrapper/NewReleaseWrapper";
import PopupModal from "../../confirmation-dialog/PopupModal";
import NewRealeaseIcon from "./NewRelease";
import Help from "./Help";
import ThemeIcon from "./Theme";
import SearchBarWithOptions from "./search-bar/SearchBarWithOptions";
import Home from "./Home";


export default function HeadBar() {

    const [newReleaseModal, setNewReleaseModal] = useState(false)

    return <>
        <aside id="head-bar" className="h-[60px] z-50  w-full bg-slate-700 px-8 flex items-center dark:bg-zinc-900 "
               aria-label="top-bar">
            {newReleaseModal && <PopupModal action={() => setNewReleaseModal(false)}/>}
            <Home/>
            <div className={"w-full flex items-center"}>
                <SearchBarWithOptions/>
            </div>
            <div className="flex items-center">
                <ThemeIcon/>
                <NewReleaseWrapper relativePosition={" left-9  "}>
                    <Help/>
                </NewReleaseWrapper>
                <NewRealeaseIcon newReleaseModal={newReleaseModal} setNewReleaseModal={setNewReleaseModal}/>

                <div className="flex flex-end items-center w-8 relative z-[70]">
                    <DropDownMenu dataCy={"headbar-dropdown"}/>
                </div>
            </div>
        </aside>
    </>
}





