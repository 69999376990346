import {createAction} from "../../../store/createAction";
import {ActionsUnion} from "../../../store/actionsUnions";
import {InflationInterval} from "../../../adapters/primary/ui/composition/form/financial-parameters/variable-inflation-widget/VariableInflationInput";
import {FormCapex} from "../../../adapters/primary/ui/composition/form/financial-parameters/capex/FormCapex";

export const SET_SELLING_PRICE = 'SET_SELLING_PRICE';
export const SET_SELECTED_CELLS = 'SET_SELECTED_CELLS';
export const SET_CAPEXES = 'SET_CAPEXES';
export const SET_OPEXES = 'SET_OPEXES';
export const SET_CAPEXES_ARE_VALID= 'SET_CAPEXES_ARE_VALID';
export const SET_OPEXES_ARE_VALID = 'SET_OPEXES_ARE_VALID';
export const SET_SELLING_PRICE_IS_VALID = 'SET_SELLING_PRICE_IS_VALID';
export const SET_VARIABLE_INFLATIONS = 'SET_VARIABLE_INFLATIONS';
export const TOGGLE_VARIABLE_INFLATIONS = 'TOGGLE_VARIABLE_INFLATIONS';
export const RESET_LAST_SAVED_VARIABLE_INFLATIONS = 'RESET_LAST_SAVED_VARIABLE_INFLATIONS';
export const SET_SELLING_PRICE_INFLATION = 'SET_SELLING_PRICE_INFLATION';
export const SET_SELLING_PRICE_INFLATION_TOGGLE = 'SET_SELLING_PRICE_INFLATION_TOGGLE';
export const INIT_TOGGLE_VARIABLE_INFLATIONS = 'INIT_TOGGLE_VARIABLE_INFLATIONS';
export const CONFIRM_SELLING_PRICE_INFLATION_MODAL = 'CONFIRM_SELLING_PRICE_INFLATION_MODAL';
export const OPEN_MODAL_SELLING_PRICE_INFLATIONS = 'OPEN_MODAL_SELLING_PRICE_INFLATIONS';
export const CANCEL_SELLING_PRICE_INFLATION_MODAL = 'CANCEL_SELLING_PRICE_INFLATION_MODAL';
export const SET_DURATION = 'SET_DURATION';
export const SET_TEMPORARY_SELLING_PRICE_INFLATIONS = 'SET_TEMPORARY_SELLING_PRICE_INFLATIONS'
export const RESET_LAST_SAVED_SELLING_PRICE_INFLATIONS = 'RESET_LAST_SAVED_SELLING_PRICE_INFLATIONS';
export const RESET_FINANCIAL_FORM = 'RESET_FINANCIAL_FORM';
export const INIT_VARIABLE_INFLATIONS = 'INIT_VARIABLE_INFLATIONS';
export const INIT_SELLING_PRICE_INFLATIONS = 'INIT_SELLING_PRICE_INFLATIONS';
export const SET_INFLATION = 'SET_INFLATION'

export const SET_VARIABLE_TAX_ZNI = 'SET_VARIABLE_TAX_ZNI';
export const SET_VARIABLE_TAX = 'SET_VARIABLE_TAX'
export const SET_VARIABLE_TAX_FRANCE = 'SET_VARIABLE_TAX_FRANCE';
export const SET_FIXED_TAX = 'SET_FIXED_TAX';
export const SET_FIXED_DEFAULT_TAX = 'SET_FIXED_DEFAULT_TAX';

export const formActions = {
    resetFinancialForm:() => createAction(RESET_FINANCIAL_FORM),
    setSellingPriceValue: (sellingPrices: any[]) => createAction(SET_SELLING_PRICE, sellingPrices),
    setSellingPriceIsValid : (sellingPricesIsValid : any[])=> createAction(SET_SELLING_PRICE_IS_VALID, sellingPricesIsValid),
    setCapexes:(capexes : FormCapex[])=> createAction(SET_CAPEXES, capexes),
    setCapexesAreValid:(capexesAreValid : boolean[]) => createAction(SET_CAPEXES_ARE_VALID, capexesAreValid),
    setOpexesAreValid:(opexesAreValid : boolean[]) => createAction(SET_OPEXES_ARE_VALID, opexesAreValid),
    setOpexes:(opexes : any)=> createAction(SET_OPEXES, opexes),
    setSellingPriceSelected:(sellingPriceSelectedCells : any[])=> createAction(SET_SELECTED_CELLS, sellingPriceSelectedCells),
    setVariableInflations:(inflations:InflationInterval[]) => createAction(SET_VARIABLE_INFLATIONS, inflations),
    setSellingPriceInflation:(inflation:string | InflationInterval[]) => createAction(SET_SELLING_PRICE_INFLATION, inflation),
    setTemporarySellingPriceInflation:(inflations:InflationInterval[])=> createAction(SET_TEMPORARY_SELLING_PRICE_INFLATIONS, inflations),
    setSellingPriceInflationToggle:(toggleVariableInflations:boolean) => createAction(SET_SELLING_PRICE_INFLATION_TOGGLE, toggleVariableInflations),
    setVariableInflationsToggled:(toggle:boolean) => createAction(TOGGLE_VARIABLE_INFLATIONS, toggle),
    resetLastSavedVariableInflations:()=> createAction(RESET_LAST_SAVED_VARIABLE_INFLATIONS),
    resetLastSavedSellingPriceInflations:()=> createAction(RESET_LAST_SAVED_SELLING_PRICE_INFLATIONS),
    initSellingPriceInflationToggle:()=> createAction(INIT_TOGGLE_VARIABLE_INFLATIONS),
    confirmSellingPriceInflationModal:(inflations:InflationInterval[]) => createAction(CONFIRM_SELLING_PRICE_INFLATION_MODAL, inflations),
    cancelSellingPriceInflationModal:()=>createAction(CANCEL_SELLING_PRICE_INFLATION_MODAL),
    openModalSellingPriceInflations:(open:boolean) => createAction(OPEN_MODAL_SELLING_PRICE_INFLATIONS, open),
    setDuration:(duration:string) => createAction(SET_DURATION, duration),
    initVariableInflations:(initialInflation:InflationInterval[])=> createAction(INIT_VARIABLE_INFLATIONS, initialInflation),
    initSellingPriceInflations:(initialInflation:InflationInterval[])=> createAction(INIT_SELLING_PRICE_INFLATIONS, initialInflation),
    setInflation:(inflation:string) => createAction(SET_INFLATION, inflation),

    // Taxes
    setVariableTaxFrance:() => createAction(SET_VARIABLE_TAX_FRANCE),
    setVariableTaxZNI:()=> createAction(SET_VARIABLE_TAX_ZNI),
    setVariableTax:(tax:number)=> createAction(SET_VARIABLE_TAX, tax),

    setFixedTax:(tax:number) => createAction(SET_FIXED_TAX, tax),
    setFixedDefaultTax:() => createAction(SET_FIXED_DEFAULT_TAX),
};


export type formActionsType = ActionsUnion<typeof formActions>;
