export default function Loading({columnsNumber}:{columnsNumber:number}) {
    return <tbody className="text-gray-500 text-xs font-light dark:text-zinc-400 bg-white dark:bg-zinc-700">
    {
        [...Array(25)].map(() => {
            return <tr className="border-b border-gray-200 animate-pulse">
                {[...Array(columnsNumber)].map(() => {
                    return <td className="py-2 px-4 text-left whitespace-nowrap">
                        <div className="flex items-left">
                            <span className="font-bold bg-gray-100 h-4 w-full dark:bg-zinc-600"/>
                        </div>
                    </td>
                })}
            </tr>
        })
    }
    </tbody>
}