import {selectSearch} from "../../../../../corelogic/usecases/faq/faqSelector";
import {useDispatch, useSelector} from "react-redux";
import {faqActions} from "../../../../../corelogic/usecases/faq/faqActions";
import {MagnifyingOutlinedIcon} from "../../components/assets/icon/MagnifyingGlass";
import {selectRouterQuery} from "../../../../../corelogic/usecases/routing/routingSelector";
import {useEffect} from "react";

const SearchBar = () => {
    const dispatch = useDispatch()
    const searchingText = useSelector(selectSearch)

    function handleOnChange(e:any){
            dispatch(faqActions.searchOnType(e.target.value))
    }
    return <form className={"w-[400px]"}>
        <label htmlFor="default-search"
               className="mb-2   text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
        <div className="relative w-full ">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <MagnifyingOutlinedIcon className={"w-4 h-4 text-gray-500 dark:text-gray-400"}/>
            </div>
            <input
                onChange={handleOnChange}
                    value={searchingText || ''}
                    type="search" id="default-search"
                   className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:focus:ring-none"
                   placeholder="recherche par mot clé (ex : IFER, TURPE, calcul...)" />
        </div>
    </form>

}


export default SearchBar
