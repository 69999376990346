import React, {lazy, Suspense, useEffect} from "react";
import {PathnameEnum} from "../../../../corelogic/models/types/routing/PathnameEnum";
import TitleText from "../components/assets/text/TitleText";
import withRestitutionNavBar from "../composition/wrapper/withRestitutionNavBar";
import {useDispatch, useSelector} from "react-redux";
import {newSimulationActions} from "../../../../corelogic/usecases/new-simulation/newSimulationActions";
import DescriptionText from "../components/assets/text/DescriptionText";
import {selectShowCartoDensity} from "../../../../corelogic/usecases/typology-form/typologyFormSelector";
import Loading from "../composition/map/leaflet-map/loading/Loading";
import LeafletControls from "../composition/map/leaflet-map/layers/LeafletControls";

const LeafletMap = lazy(()=> import('../composition/map/leaflet-map/LeafletMap'));
const LeafletMapWithCommunes = lazy(()=> import('../composition/map/leaflet-map/LeafletMapWithCommunes'));

const PerimeterMapView = () => {
    const dispatch = useDispatch();
    const showDensity = useSelector(selectShowCartoDensity)
    useEffect(() => {
        dispatch(newSimulationActions.getSimulationForm())
    }, [dispatch])

    return <div
        className="min-w-[640px]  w-full  flex flex-col items-end bg-gray-100 font-sans overflow-y-scroll dark:bg-zinc-800">
        <div className=" bg-white shadow-sm  flex items-center w-full border-box dark:bg-zinc-800">
            <div className="flex w-full z-[50] justify-between items-center scale-95 2xl:scale-100 px-6 ">
                <TitleText title={"Carte du périmètre"}/>
            </div>
        </div>
        <div className="px-4 pt-8 scale-95 2xl:scale-100 gap-6 w-full ">
            <DescriptionText
                description={"Visualisez vos sites actifs (dont l'adresse est renseignée) et vérifiez qu'ils rentrent tous dans le périmètre défini. Vous pouvez déplacer le cercle avec votre curseur en drag and drop."}/>

            <div className={"m-4 z-20"}>
                <LeafletControls/>
                {showDensity ?
                <Suspense fallback={<Loading/>}>
                    <LeafletMapWithCommunes/>
                </Suspense> :
            <Suspense fallback={<Loading/>}>

                <LeafletMap/>
            </Suspense>
            }
            </div>
        </div>
    </div>
}

export default withRestitutionNavBar(PerimeterMapView, PathnameEnum.PERIMETER_MAP)
