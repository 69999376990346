import React, {ComponentType} from "react";
import {useSnackbar} from "notistack";
import {useSelector} from "react-redux";
import {
    selectRemainingNumberOfSimulations,
    selectSelectedOrganisationId
} from "../../../../../../corelogic/usecases/authentication/authenticationSelector";

type HugeBtnType = {
    title: string,
    IconComponent: ComponentType,
    action?(): void,
    disabled?: boolean
    dataCy ?:string
}

export default function CreateSimulationsBtn({title, IconComponent, action, disabled, dataCy}: HugeBtnType) {
    const {enqueueSnackbar} = useSnackbar()
    const selectedOrganisationId = useSelector(selectSelectedOrganisationId)
    const remainingNumberOfSimulations = useSelector(selectRemainingNumberOfSimulations)

    function handleOnClick() {
        if(disabled){
            enqueueSnackbar("Votre organisation a atteint la limite de création de simulations.", {variant: "warning"})
        }else {
            action ? action() : alert("error")
        }
    }

    return <div
        data-cy={dataCy}
        onClick={() => handleOnClick()}
        className={disabled ?
            "opacity-40 relative flex font-semibold items-center hover:animate-none whitespace-nowrap text-white text-lg bg-slate-400 w-min h-min rounded-md p-3 "
            : "flex relative font-semibold items-center hover:animate-none whitespace-nowrap text-white text-lg bg-secondary w-min h-min rounded-md p-3 opacity-80 hover:opacity-100 hover:cursor-pointer "
        }
    >

        <IconComponent/>
        <div className={"flex-col flex "}>
            <div className="pl-2 pb-1">{title}</div>
        {selectedOrganisationId && <div
            className={"absolute bottom-0 right-3 text-xs py-1 whitespace-nowrap  font-semibold "}>
            {remainingNumberOfSimulations} crédit(s) restant(s)</div>}
        </div>
    </div>

}
