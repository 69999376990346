import {INIT_SIMULATION_FORM, newSimulationActionsType, SET_NEXT_STEP_INDEX} from "../../newSimulationActions";

const nextStepIndexInitialState: number = 0

const nextStepIndexReducer = (state = nextStepIndexInitialState, action: newSimulationActionsType) => {
    switch (action.type) {
        case SET_NEXT_STEP_INDEX:
            return action.payload
        case INIT_SIMULATION_FORM:
            return nextStepIndexInitialState
        default:
            return state;
    }
}
export default nextStepIndexReducer