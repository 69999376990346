import React from "react";
import {useSelector} from "react-redux";
import {
    selectSGENumberOfDistinctFailures,
    selectSGENumberOfFailures
} from "../../../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";

const HeadFailure = () => {
    const numbersOfDistinctFailures = useSelector(selectSGENumberOfDistinctFailures)
    const failedWeeks = useSelector(selectSGENumberOfFailures)

    return <div className={"flex gap-4 bg-gray-200 dark:bg-gray-700 p-1 px-2  rounded-t"}>
        <div className={"flex gap-2 items-center"}>
            <span className={"text-gray-400 dark:text-gray-500 text-xs font-semibold"}>Erreurs distinctes</span>
            <span className={"text-red-500 dark:text-red-400 text-sm font-bold"}>{numbersOfDistinctFailures}</span>
        </div>
        <div className={"flex gap-2 items-center"}>
            <span className={"text-gray-400 dark:text-gray-500 text-xs font-semibold"}>Semaines en échec</span>
            <span className={"text-red-500 dark:text-red-400 text-sm font-bold"}>{failedWeeks}</span>
        </div>
    </div>
}
export default HeadFailure