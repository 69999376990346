export class UserMailAndId {
    private _email : string;
    private _id : string


    constructor(email: string, id: string) {
        this._email = email;
        this._id = id;
    }
    serialize(){
        return {
            email :this._email,
            id :this._id,
        }
    }
    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }

    get id(): string {
        return this._id;
    }

    set id(value: string) {
        this._id = value;
    }
}