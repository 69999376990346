import {createAction} from "../../../store/createAction";
import {ActionsUnion} from "../../../store/actionsUnions";

export const SET_DARKMODE = 'SET_DARKMODE';
export const INIT_DARK_MODE = 'INIT_DARK_MODE';
export const SET_SIDEBAR_IS_REDUCED = 'SET_SIDEBAR_IS_REDUCED';

export const configActions = {
    setDarkMode:(darkMode : boolean) => createAction(SET_DARKMODE, darkMode),
    setSideBarIsReduced:(isReduced : boolean) => createAction(SET_SIDEBAR_IS_REDUCED, isReduced),
};

export type Actions = ActionsUnion<typeof configActions>;
