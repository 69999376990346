import {createAction} from "../../../store/createAction";
import {ActionsUnion} from "../../../store/actionsUnions";
import {Question} from "../../../adapters/primary/ui/composition/faq/AllQuestions";

export const SET_SORTING_TAG = 'SET_SORTING_TAG';
export const SET_SORTING_SEARCH = 'SET_SORTING_SEARCH';
export const SET_QUESTIONS = 'SET_QUESTIONS';
export const SET_EXPAND_QUESTION = 'SET_EXPAND_QUESTION';
export const RESET_SORTING_TAG = 'RESET_SORTING_TAG';
export const RESET_SORTING_SEARCH= 'RESET_SORTING_SEARCH';
export const ON_TAG_CLICK = 'ON_TAG_CLICK';
export const ON_SEARCH_TYPE = 'ON_SEARCH_TYPE';

export const faqActions = {
    // SAGA
    expandQuestionById:(questionId:string) => createAction(SET_EXPAND_QUESTION, questionId),
    tagOnClick: (tag:string) => createAction(ON_TAG_CLICK, tag),
    searchOnType:(search:string) => createAction(ON_SEARCH_TYPE, search),

    // REDUCER
    setQuestions:(questions:Question[]) => createAction(SET_QUESTIONS, questions),
    setSortingTag: (tag:string) => createAction(SET_SORTING_TAG, tag),
    setSortingSearch: (search:string) => createAction(SET_SORTING_SEARCH, search),

    resetTagOnClick: () => createAction(RESET_SORTING_TAG),

};

export type loaderActionsType = ActionsUnion<typeof faqActions>;
