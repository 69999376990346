import {useSelector} from "react-redux";
import {
    selectCashFlowExternalInvestmentData,
    selectCashFlowLoading
} from "../../../../../../../../corelogic/usecases/financialRestitution/financialRestitutionSelector";
import {
    makeSelectHasProducerLoanRepayment,
    selectHasLoanRepayment
} from "../../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectFinancialParameter";
import {useCallback, useState} from "react";
import {
    CapexAndOpexHead,
    CapexLoanHead,
    CumulativeFluxHead,
    RentHead,
    RoyaltyHead,
    TotalHead,
    YearHead
} from "../../../table/header/CashFlowHeadComponent";
import Loading from "../../../table/Loading";
import {
    CumulativeFlows,
    Investment,
    LoanRepayment,
    Maintenance,
    NetSavings,
    Rent,
    Royalty,
    YearlyResult
} from "../../../table/body/CashFlowBodyComponent";

const InvestorOpenTable = ({selectedType, id}: { selectedType: any, id:string }) => {
    const loading = useSelector(selectCashFlowLoading)
    const selectHasLoan = useCallback(selectedType.scope === 'producer' ? makeSelectHasProducerLoanRepayment(selectedType.id) : selectHasLoanRepayment, [selectedType])
    const hasLoan = useSelector(selectHasLoan)
    const [rowHover, setRowHover] = useState(-1)
    const CashFlowData = useSelector(selectCashFlowExternalInvestmentData);

    const columnsNumber = hasLoan ? 8 : 7
    return <div className="bg-white shadow-md rounded dark:bg-zinc-700">
        <table id={id} className="w-full table-auto rounded-lg">
            <thead className={'bg-white dark:bg-zinc-700'}>
            <tr>
                <YearHead/>
                <CapexAndOpexHead/>
                {hasLoan && <CapexLoanHead/>}
                <RentHead/>
                <RoyaltyHead/>
                <TotalHead/>
                <CumulativeFluxHead/>
            </tr>
            <tr></tr>
            </thead>
            {
                loading ? <Loading columnsNumber={columnsNumber}/>
                    : <tbody className="text-gray-500 text-xs font-light dark:text-zinc-400 bg-white dark:bg-zinc-700">
                    {
                        CashFlowData.map((cashFlowRow: any, idx: number) =>
                            <tr
                                onMouseEnter={() => setRowHover(idx)}
                                onMouseLeave={() => setRowHover(-1)}
                                className="border-b border-gray-200 hover:bg-gray-100 dark:hover:bg-zinc-600 dark:border-zinc-800">
                                <YearlyResult idx={idx}/>
                                <Investment cashFlowRow={cashFlowRow}/>
                                <Maintenance cashFlowRow={cashFlowRow}/>
                                {hasLoan && <LoanRepayment cashFlowRow={cashFlowRow}/>}
                                <Rent  idx={idx} rowHover={rowHover} cashFlowRow={cashFlowRow}/>
                                <Royalty idx={idx} rowHover={rowHover} cashFlowRow={cashFlowRow}/>
                                <NetSavings cashFlowRow={cashFlowRow}/>
                                <CumulativeFlows cashFlowRow={cashFlowRow} rowHover={rowHover} idx={idx}/>
                            </tr>)
                    }
                    </tbody>
            }
        </table>
    </div>
}
export default InvestorOpenTable