import {XIcon} from "../../components/assets/icon/XIcon";
import {
    isSearchingTagSelected,
    makeIsTagSelected,
    selectSearch,
    selectTag
} from "../../../../../corelogic/usecases/faq/faqSelector";
import {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {faqActions} from "../../../../../corelogic/usecases/faq/faqActions";
import {PlusIcon} from "../../components/assets/icon/PlusIcon";
import ChevronRight from "../../components/assets/icon/ChevronRight";

const Tags = () => {
    const isSearchingTag = useSelector(isSearchingTagSelected)
    const research = useSelector(selectSearch)
    return <div className={"flex flex-col gap-2"}>
        <span className={"text-gray-600 dark:text-gray-300 self-center text-md"}>Cliquez sur un badge pour filtrer par catégorie :</span>
    <div className={" w-full flex gap-2 justify-center flex-wrap"}>
        {isSearchingTag && <Tag title={`recherche : ${research}`}/>}
        <Tag title={'bilan économique'}/>
        <Tag title={'bilan financier'}/>
        <Tag title={"méthodologie"}/>
        <Tag title={"fonctionnement opérationnel d'une ACC"}/>
        <Tag title={"algorithme de répartition"}/>
    </div>
    </div>
}
const Tag = ({title}: { title: string }) => {
    const dispatch = useDispatch()
    const isActiveSelector = useCallback(makeIsTagSelected(title), [title])
    const isActive = useSelector(isActiveSelector)
    const tagName = useSelector(selectTag)
    const inactiveStyle = "flex items-center gap-2 px-2 py-1 uppercase bg-gray-300 dark:bg-gray-700 bg-opacity-60 dark:bg-opacity-60 text-gray-500 dark:text-gray-400 rounded font-semibold hover:cursor-pointer dark:hover:bg-opacity-100 hover:bg-opacity-100 text-sm"
    const activeStyle = "flex items-center gap-2 px-2 py-1 uppercase bg-blue-200 dark:bg-blue-700  text-blue-600 dark:text-blue-300 rounded font-semibold border border-blue-600 dark:border-0 text-sm hover:cursor-pointer "

    function handleOnTagClick(){
        if(title=== tagName){
            if(title.includes('recherche :')){
                dispatch(faqActions.searchOnType(''))
            }
            dispatch(faqActions.resetTagOnClick())
        } else {
            dispatch(faqActions.tagOnClick(title))
        }
    }
    return <div
        onClick={handleOnTagClick}
        className={isActive ? activeStyle : inactiveStyle}>
        {title}
        {isActive ? <XIcon className={'w-5 h-5 hover:text-blue-700'}/> : <ChevronRight className={'w-5 h-5'}/>}
    </div>
}

export default Tags
