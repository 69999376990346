import TitleText from "../../../../components/assets/text/TitleText";
import RegularBtn from "../../../../components/assets/button/RegularBtn";
import {ButtonState, Theme} from "../../../../../../../config/app-config";
import React from "react";
import {useSnackbar} from "notistack";
import {useDispatch, useSelector} from "react-redux";
import {selectFormCapexes} from "../../../../../../../corelogic/usecases/form/formSelector";

import {formActions} from "../../../../../../../corelogic/usecases/form/formActions";
import {FormCapex} from "./FormCapex";
import {availableCapexesPoints} from "./FormCapex.utils";
import {
    selectInjectionPoints
} from "../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectInjectionPointForm";

const CapexWrapper = ({disabled}:{disabled?:true}) => {
    const dispatch = useDispatch()
    const {enqueueSnackbar} = useSnackbar();
    const capexes = useSelector(selectFormCapexes)
    const injectionPoints =useSelector(selectInjectionPoints)
    function addCapex() {
        if(!disabled){
            let newCapex:FormCapex[] = [...capexes];
            if (capexes.length < injectionPoints.length + 1) {
            newCapex.push(new FormCapex(
                availableCapexesPoints(capexes, injectionPoints)[0], // push new available injection point
                    '',
                    null))
            dispatch(formActions.setCapexes(newCapex))
        } else {
            enqueueSnackbar("Il peut y avoir au maximum " + (injectionPoints.length + 1) + " capex (" + injectionPoints.length + " point(s) d'injection(s) + 1 capex global)",
                {
                    variant: "warning",
                })}
        }
    }


    return <div className={"flex justify-between items-center"}>
        <TitleText title={"Capex"} textSize={"text-sm"}
                   typeOfMargin={"small"}/>
        <div className={"w-min mr-4"}>
            <RegularBtn
                dataCy={'financial-params-add-capexes'}
                title={"Ajouter du Capex"}
                theme={Theme.SECONDARY} state={ButtonState.REGULAR} action={() => addCapex()}/></div>
    </div>
}
export default CapexWrapper
