import ReactEcharts from "echarts-for-react";
import React, {useEffect, useRef, useState} from "react";
import {getColorFromName, lineInitialOption, loadingOption} from "./config";
import {
    selectAutoconsumptionCDCValues,
    selectCDCLoading,
    selectConsumptionCDCValues,
    selectProductionCDCValues, selectSelectedType,
    selectSurplusCDCValues,
    selectTimestamps
} from "../../../../../corelogic/usecases/physicalRestitution/physicalRestitutionSelector";
import {useDispatch, useSelector} from "react-redux";
import {selectIsDarkMode} from "../../../../../corelogic/usecases/config/configSelector";
import {physicalRestitutionActions} from "../../../../../corelogic/usecases/physicalRestitution/physicalRestitutionActions";
import {
    selectSelectedSimulationId
} from "../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form/newSimulationSelector";
import {WrapperCard} from "../wrapper/WrapperCard";
import {
    selectIsFormUpdating
} from "../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-state/selectFormState";
import { useSimulationExecutionStatus } from "../../utils/useSimulationExecutionStatus";
import { ExecutionStatus } from "../../../../../corelogic/usecases/simulationExecutionStatus/simulationExecutionStatusActions";


export default function () {
    const type = useSelector(selectSelectedType)
    const selectedType = useSelector(selectSelectedType)
    const [option, setOption] = useState<any>(lineInitialOption(true))
    const timestamps = useSelector(selectTimestamps);
    const consumptionCDC = useSelector(selectConsumptionCDCValues)
    const productionCDC = useSelector(selectProductionCDCValues)
    const autoconsumptionCDC = useSelector(selectAutoconsumptionCDCValues)
    const surplusCDC = useSelector(selectSurplusCDCValues)
    const loading = useSelector(selectCDCLoading)
    const isDarkMode = useSelector(selectIsDarkMode)
    const CDCLineChartRef = useRef(null)

    const simulationId = useSelector(selectSelectedSimulationId)
    const isFormUpdating = useSelector(selectIsFormUpdating)
    const executionStatus = useSimulationExecutionStatus();

    const dispatch = useDispatch();

    // for Wrapper export to excel
    const dataToExport = formatDataToExcelExport(option)
    function formatDataToExcelExport(data:any){
        const dataFormatted: any[] = [];
        const xAxisData = data.xAxis.data;
        const seriesData = data.series;

        const headerRow = ['Datetime UTC', ...seriesData.map((series:any) => series.name)];
        dataFormatted.push(headerRow);

        for (let i = 0; i < xAxisData.length; i++) {
            const rowData: (string | number)[] = [xAxisData[i]];
            for (const series of seriesData) {
                rowData.push(series.data[i]);
            }
            dataFormatted.push(rowData);
        }
        return dataFormatted
    }

    useEffect(() => {
        return () => {
            dispatch(physicalRestitutionActions.cancelGetCDCData())
        }
    }, [])

    useEffect(() => {
        if (simulationId && !isFormUpdating && type.id && executionStatus === ExecutionStatus.finished) {
            dispatch(physicalRestitutionActions.getCDCData())
        }
    }, [type.id, type.aci, simulationId, isFormUpdating, executionStatus])


    useEffect(() => {
        setOption((option: any) => {
            const series = [];
            const initialSerie = {
                type: 'line',
                lineStyle: {
                    width: 0
                },
                showSymbol: false,
                areaStyle: {},
            }
            if (consumptionCDC.length) {
                const name = 'Consommation totale'
                series.push({
                    ...initialSerie,
                    id: `${name}${type.name}`,
                    name,
                    color: getColorFromName(name),
                    data: consumptionCDC,
                })
            }
            if (productionCDC.length) {
                const name = 'Production'
                series.push({
                    ...initialSerie,
                    id: `${name}${type.name}`,
                    name,
                    color: getColorFromName(name),
                    data: productionCDC,
                })
            }
            if (autoconsumptionCDC.length) {
                const name = type.aci ? 'Autoconsommation' : 'Autoconsommation collective'
                series.push({
                    ...initialSerie,
                    id: `${name}${type.name}`,
                    name,
                    color: getColorFromName(name),
                    data: autoconsumptionCDC,
                })
            }
            if (surplusCDC.length) {
                const name = 'Surplus'
                series.push({
                    ...initialSerie,
                    id: `${name}${type.name}`,
                    name,
                    color: getColorFromName(name),
                    data: surplusCDC,
                })
            }
            return {...option, series}
        })
    }, [autoconsumptionCDC])

    useEffect(() => {
        setOption((option: any) => ({...option, backgroundColor: isDarkMode ? "#3F3F46" : '#fff'}))
    }, [isDarkMode])

    useEffect(() => {
        setOption((option: any) => ({...option, xAxis: {...option.xAxis, data: timestamps}}))
    }, [timestamps])

    return <WrapperCard
        exportAsXLSData={dataToExport}
        exportAsPNGRef={CDCLineChartRef}
        title={selectedType.scope ? (selectedType.scope === 'producer' ? "Courbe de charge de la production" : "Courbe de charge de la consommation") : "Courbe de charge de l'opération d'autoconsommation collective"}>
        <div
            ref={CDCLineChartRef}
            className="flex flex-col items-center bg-white font-bold text-normal text-gray-700 h-[500px] shadow-sm rounded-md overflow-hidden  p-4  dark:bg-zinc-700 dark:text-gray-300 ">
            <ReactEcharts theme={isDarkMode ? 'dark' : ''} option={option} style={{height: '100%'}} className="w-full"
                          notMerge showLoading={loading}
                          loadingOption={loadingOption}/>
        </div>
    </WrapperCard>
}
