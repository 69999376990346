export enum CDCNature {
    // global
    totalConsoNetwork = 'total_conso_network',
    totalConso = 'total_conso',
    totalConsoAcc = 'total_conso_acc',
    totalConsoAc = 'total_conso_ac',
    totalConsoAci = 'total_conso_aci',
    totalExcess = 'excess',

    // injection point
    prod = "prod",
    prodNetwork = "prod_network",
    prodAc = "prod_ac",
    prodAcc = "prod_acc",
    excess = 'excess',

    // consumption point
    consoAcc = 'conso_acc',
    consoAc = 'conso_ac',
    consoNetwork = 'conso_network',
    conso = 'conso',


}

export enum metricsNature {

    // production distribution
    totalExcess = 'excess',
    prodAcc = 'prod_acc',
    excess = 'excess',
    prodAci = 'prod_aci',
    prodNetwork = 'prod_network',
    accAutoconsumptionRate = 'acc_autoconsumption_rate',
    aciAutoconsumptionRate = 'aci_autoconsumption_rate',

    // consumption distribution 
    totalConsoSupplierAcc = 'total_conso_supplier_acc',
    totalConsoSupplier = 'total_conso_supplier',
    totalConsoAcc = 'total_conso_acc',
    totalConsoAci = 'total_conso_aci',
    consoAcc = 'conso_acc',
    consoAci = 'conso_aci',
    consoSupplier = 'conso_supplier',
    accAutoproductionRate = 'acc_autoproduction_rate',
    aciAutoproductionRate = 'aci_autoproduction_rate',


}