import {ActionsUnion} from "../../../store/actionsUnions";
import {
    DistributionPriority
} from "../../models/types/new-simulation/form/operation-parameters/distribution/DistributionPriority";
import {
    DistributionShare
} from "../../models/types/new-simulation/form/operation-parameters/distribution/DistributionShare";
import {createAction} from "../../../store/createAction";
import {Seasonality} from "../../models/types/new-simulation/form/operation-parameters/seasonality/Seasonality";
import {
    DistributionUngroupedPriorities
} from "../../models/types/new-simulation/form/operation-parameters/distribution/DistributionUngroupedPriorities";
// distribution
export const SET_DISTRIBUTION_ALGORITHM= 'SET_DISTRIBUTION_ALGORITHM';
export const SET_DISTRIBUTION_PRIORITIES= 'SET_DISTRIBUTION_PRIORITIES';
export const SET_DISTRIBUTION_SHARES= 'SET_DISTRIBUTION_SHARES';
export const SET_DISTRIBUTION_UNGROUPED_PRIORITIES = 'SET_DISTRIBUTION_UNGROUPED_PRIORITIES';

// seasonality
export const SET_SEASONALITY_SEASONS_ACTIVATED='SET_SEASONALITY_SEASONS_ACTIVATED';
export const INIT_OPERATION_PARAMETERS_FORM = 'INIT_OPERATION_PARAMETERS_FORM';
export const SET_SURPLUS_ALLOCATION_PRIO= 'SET_SURPLUS_ALLOCATION_PRIO' ;
export const SET_SURPLUS_ALLOCATION = 'SET_SURPLUS_ALLOCATION';
export const operationParametersFormActions = {

    init: () => createAction(INIT_OPERATION_PARAMETERS_FORM),
    // distribution
    setDistributionAlgorithm: (algorithm: string) =>createAction(SET_DISTRIBUTION_ALGORITHM, algorithm),
    setDistributionPriorities: (priorities: DistributionPriority[]) =>createAction(SET_DISTRIBUTION_PRIORITIES, priorities),
    setDistributionShares: (shares: DistributionShare[]) =>createAction(SET_DISTRIBUTION_SHARES, shares),
    setDistributionUngroupedPriorities: (distribution : DistributionUngroupedPriorities) =>createAction(SET_DISTRIBUTION_UNGROUPED_PRIORITIES, distribution),

    // seasonality
    setSeasonality: (seasonality: Seasonality) => createAction(SET_SEASONALITY_SEASONS_ACTIVATED, seasonality),

}

export type formActionsType = ActionsUnion<typeof operationParametersFormActions>;
