import {newSimulationActionsType, SET_SAVING_FORM} from "../../newSimulationActions";

const savingFormInitialState = false
const savingFormReducer = (state = savingFormInitialState, action: newSimulationActionsType) => {
    switch (action.type) {
        case SET_SAVING_FORM:
            return action.payload
        default:
            return state;
    }
}
export default savingFormReducer