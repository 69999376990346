
type PaperType = {
    title: string|JSX.Element,
    icon: any,
    descriptions: string[]
}
export default function Paper({title, descriptions, icon}: PaperType) {
    return <div className="flex-col flex p-4 bg-slate-100 dark:bg-slate-700 mt-2">
        <div className={"bg-slate-300 p-2 w-min rounded-lg"}>
            {icon}
        </div>
        <div
            className={"font-semibold  text-md text-gray-700 dark:text-gray-300 py-2"}>
            {title}
        </div>
        <ul className={"list-disc text-xs   text-gray-700 dark:text-gray-300 py-1 pl-3"}>
        {
            descriptions.map((description) => {
                return <li>
                    {description}
                </li>
            })
        }
        </ul>
    </div>
}