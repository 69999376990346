export const formatNumber = (nbr: number, digit: number = 0) => nbr.toFixed(digit).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");


export const loadingOption = {
    text: 'Chargement en cours'
}
export const pieInitialOption = (nature: 'production' | 'consumption') => {
    return ({
        tooltip: {
            trigger: 'item',
        },
        legend: {
            left: 'right',
            top: 'center',
            orient: 'vertical',
            selectedMode: false,

        },
        toolbox:{
            show:true,
            feature:{
                dataView:{readOnly:true}
            }
        },
        series: [
            {
                type: 'pie',
                left: 0,
                radius: ['40%', '70%'],
                avoidLabelOverlap: true,
                center: ['30%', '50%'],
                itemStyle: {
                    borderRadius: 10,
                    borderWidth: 2,
                },

                label: {
                    show: true,
                    formatter: ({value}: any) => `${formatNumber(value, 0)} kWh`,
                },
                labelLine: {
                    show: true
                },
                markPoint: {
                    tooltip: {show: false},
                    pointer: false,
                    label: {
                        show: true,
                        fontSize: 20,
                        width: 130,
                        overflow: 'break',
                    },
                    data: [{
                        name: 'total',
                        value: `0 kWh`,
                        symbol: 'circle',
                        itemStyle: {color: 'transparent'},
                        x: '30%',
                        y: '50%',
                    }],
                },
                data: []
            }
        ]
    })
};

export const lineInitialOption = (withZoom: boolean = false, unit?: string) => ({
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow',
            ...withZoom && {
                label: {
                    formatter: ({value}: any) => {
                        const date = new Date(value);
                        return `${padTo2Digits(date.getUTCDate())}/${padTo2Digits(date.getUTCMonth() + 1)} ${padTo2Digits(date.getHours())}:${padTo2Digits(date.getMinutes())}`
                    }
                }
            }
        },
        valueFormatter: (value: any) => unit ? `${formatNumber(value, 2)} ${unit}` : `${formatNumber(value, 2)} kW`,
    },
    toolbox:{
      show:true,
      feature:{
          dataView:{readOnly:true},
      }
    },
    grid: {
        containLabel: true,
        left: "center",
        width: "100%",
        right: 30,
    },
    ...withZoom && {
        dataZoom: [
            {
                type: 'slider'
            }
        ]
    },
    legend : {},
    xAxis: {
        type: 'category',
        data: [],
        axisLabel: {
            ...withZoom && {
                formatter: (time: any) => {
                    const date = new Date(time);
                    return `${padTo2Digits(date.getUTCDate())}/${padTo2Digits(date.getUTCMonth() + 1)}`
                }
            }
        },
    },

    yAxis: [
        {
            type: 'value',
            name: unit ? `${unit}` : 'kW',
        }
    ],
    series: []
});

export const getColorFromName = (name: string) => {
    switch (name) {
        case 'Consommation totale':
        case 'Production totale':
            return '#2A5E8A';
        case 'Autoconsommation collective':
        case 'Autoconsommation':
            return '#f3b617';
        case 'Surplus':
            return '#c4c4c4';
    }
}

export function lightenDarkenColor(col: any, percent: any) {
    col = col.slice(1);

    let num = parseInt(col, 16);

    let r = (num >> 16) + percent;
    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    let b = ((num >> 8) & 0x00FF) + percent;
    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    let g = (num & 0x0000FF) + percent;
    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return "#" + (g | (b << 8) | (r << 16)).toString(16).padStart(6, '0');
}

function padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
}

