import React, {useEffect, useState} from "react";
import XMarkIcon from "../../../../components/assets/icon/XMarkIcon";
import InflationBadge from "./InflationBadge";
import Grid from "./Grid";
import {useDispatch, useSelector} from "react-redux";
import ArrowPathRoundIcon from "../../../../components/assets/icon/ArrowPathRoundIcon";
import {selectFormDuration} from "../../../../../../../corelogic/usecases/form/formSelector";
import {TrashIconSolid} from "../../../../components/assets/icon/TrashIcon";

export type InflationInterval = {
    value: number,
    startYear: number,
    endYear: number,
}


export default function VariableInflationInput({
                                                   uniqueId,
                                                   setInflationsAction,
                                                   resetInflations,
                                                   fetchLastSavedInflations,
                                                   fixedInflation,
                                                   inflationHasChanged,
                                                   inflations
                                               }:
                                                   { setInflationsAction(inf: InflationInterval[]): any, resetInflations(inf: InflationInterval[]): any, fetchLastSavedInflations(): any,uniqueId: string, inflations: InflationInterval[], fixedInflation?: string, inflationHasChanged: boolean }) {
    const dispatch = useDispatch()
    const formDuration = useSelector(selectFormDuration)
    const [oneYearWidth, setOneYearWidth] = useState(0)
    const COMPONENT_WIDTH = 957

    useEffect(() => {
        const paddingLeftAndRight = 24
        const borderYear0AndYearMax = 8
        const oneYearWidth = (COMPONENT_WIDTH - paddingLeftAndRight - borderYear0AndYearMax) / parseInt(formDuration)
        setOneYearWidth(oneYearWidth)
        const inflationsLengthDoesntMatchDuration = inflations[inflations.length - 1]?.endYear !== parseInt(formDuration)
        if (formDuration && inflationsLengthDoesntMatchDuration) {
            dispatch(setInflationsAction([{
                value: fixedInflation && fixedInflation !== '' ? parseInt(fixedInflation) : 0,
                startYear: 1,
                endYear: parseInt(formDuration)
            }]))
        }
    }, [formDuration])

    function textToHelp() {
        let textToHelp = 'Vous avez affecté une inflation de '
        inflations.forEach((inflation: InflationInterval, index: number) => {
            const commaOrDotText = index === inflations.length - 1 ? "." : ", "
            textToHelp += `${inflation.value}% sur les années ${inflation.startYear} à ${inflation.endYear} ${commaOrDotText}`
        })
        return textToHelp
    }

    function helperTextDisplayer() {
        return <span className={"truncate w-full"}>
            {inflations.map((inflation: InflationInterval, index: number) => {
                const HelperText = () => <><b>{inflation.value}%</b> sur les
                    années {inflation.startYear} à {inflation.endYear}</>
                const commaOrDotText = index === inflations.length - 1 ? "." : ", "
                return <>
                    <HelperText/>
                    {commaOrDotText}
                </>
            })}
        </span>
    }

    return <div
        style={{width: `${COMPONENT_WIDTH}px`, minWidth: `${COMPONENT_WIDTH}px`}}
        className={`py-2 overflow-hidden h-44 dark:bg-zinc-600 bg-slate-50 border border-slate-300 dark:border-zinc-700 rounded-lg`}>
        <Grid/>
        <div className={"flex p-4 "}>
            <SingleDisabledBadge width={oneYearWidth}/>

            {
                inflations.map((inflation: InflationInterval, index: number) => {
                    return <InflationBadge
                        key={inflation.startYear}
                        setInflations={setInflationsAction}
                        uniqueId={uniqueId}
                        oneYearWidth={oneYearWidth}
                        index={index}
                        inflations={inflations}
                        inflation={inflation}/>
                })
            }
        </div>
        <div className={"w-full justify-between flex px-4"}>

            <span
                className={"bottom-[72px] relative text-sm text-gray-600 dark:text-gray-50 min-w-max pr-1"}>
                {'Vous avez affecté une inflation de'}
            </span>
            <span title={textToHelp()}
                  className={"bottom-[72px] w-full relative text-sm text-gray-600 dark:text-gray-50 truncate "}>
                {helperTextDisplayer()}
            </span>
            <FetchLastSavedInflationButton inflationHasChanged={inflationHasChanged}
                                           fetchLastSavedInflations={fetchLastSavedInflations}/>
            <ResetInflationButton inflations={inflations}
                                  resetInflations={resetInflations}/>

        </div>
    </div>
}

function FetchLastSavedInflationButton({
                                           inflationHasChanged,
                                           fetchLastSavedInflations
                                       }: { inflationHasChanged: boolean, fetchLastSavedInflations(): any }) {
    const dispatch = useDispatch()
    const [title, setTitle] = useState("Réinitialiser les valeurs")
    const disabledStyle = " ml-2 rounded border-[0.5px] border-gray-600 dark:border-0 dark:bg-zinc-500 bg-gray-100 p-1 bottom-[72px] relative flex opacity-40"
    const activeStyle = "ml-2 rounded border-[0.5px] border-gray-600 dark:border-0 dark:bg-zinc-500 bg-gray-100 p-1 bottom-[72px] relative flex opacity-80 hover:cursor-pointer hover:opacity-100 hover:bg-gray-200 hover:dark:bg-zinc-700 "
    const style = inflationHasChanged ? activeStyle : disabledStyle

    useEffect(() => {
        if (inflationHasChanged) setTitle("Récupérer la dernière sauvegarde")
        else setTitle("Récupérer la dernière sauvegarde(pas de changements)")
    }, [inflationHasChanged])

    return <div
        onClick={() => dispatch(fetchLastSavedInflations())}
        title={title}
        className={style}>
        <ArrowPathRoundIcon className={"w-5 h-5  text-gray-600 dark:text-gray-50 "}/>
    </div>
}


function ResetInflationButton({
                                  inflations,
                                  resetInflations,
                              }: { inflations: InflationInterval[], resetInflations(inf: InflationInterval[]): any}) {
    const dispatch = useDispatch()
    const formDuration = useSelector(selectFormDuration)
    const [title, setTitle] = useState("Réinitialiser les valeurs")
    const disabledStyle = " ml-2 rounded border-[0.5px] border-gray-600 dark:border-0 dark:bg-zinc-500 bg-gray-100 p-1 bottom-[72px] relative flex opacity-40"
    const activeStyle = "ml-2 rounded border-[0.5px] border-gray-600 dark:border-0 dark:bg-zinc-500 bg-gray-100 p-1 bottom-[72px] relative flex opacity-80 hover:cursor-pointer hover:opacity-100 hover:bg-gray-200 hover:dark:bg-zinc-700 "
    const inflationIsNotInitial = inflations.length >0 ? inflations[0].startYear !== 1 || inflations[0].endYear !== parseInt(formDuration) || inflations[0].value !==0 : false
    const style = inflationIsNotInitial ? activeStyle : disabledStyle

    useEffect(() => {
        if (inflationIsNotInitial) setTitle("Réinitialiser les valeurs")
        else setTitle("Réinitialiser les valeurs (pas de changements)")
    }, [inflationIsNotInitial])

    return <div
        onClick={() => dispatch(resetInflations([{
            value: 0,
            startYear: 1,
            endYear: parseInt(formDuration)
        }]))}
        title={title}
        className={style}>
        <TrashIconSolid className={"w-5 h-5  text-gray-600 dark:text-gray-50 "}/>
    </div>
}
function SingleDisabledBadge({width}: { width: number }) {
    return <div className={"bottom-[84px] relative "}>
        <div className={"flex justify-center items-center"} style={{width: width}}>
            <div title={"La première année n'est pas prise en compte."}
                 className={"h-12 flex items-center justify-center cursor-help w-full px-1 py-1 bg-gray-300 dark:bg-gray-700 hover:bg-gray-400 hover:dark:bg-gray-800  text-white rounded-sm whitespace-nowrap font-bold "}>
                <XMarkIcon className={"w-6 h-6 text-gray-700 dark:text-gray-50"}/>
            </div>
        </div>
    </div>
}





