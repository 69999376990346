import {useDispatch, useSelector} from "react-redux";
import {newSimulationActions} from "../../../../../../corelogic/usecases/new-simulation/newSimulationActions";
import DownloadIcon from "../icon/DownloadIcon";
import React from "react";
import {selectIsLoadingSimulationForm} from "../../../../../../corelogic/usecases/loader/loaderSelector";
import SpinningSVG from "../Animated/SpinningSVG";
import {
    selectCurrentStep
} from "../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/steps/selectSteps";

export default function UploadCDCFile({action}: {action(): void}) {
    const currentStep = useSelector(selectCurrentStep)
    const dispatch = useDispatch()
    const isLoadingForm = useSelector(selectIsLoadingSimulationForm)
    function curveNature(isProducer:boolean){
        return isProducer ? "prod" : "conso"
    }

    function uploadWhen(aFileIsLoaded: any) {
        var data = new FormData()
        data.append('measurements_file', aFileIsLoaded)
        dispatch(newSimulationActions.uploadLoadCurve({
            stepLength: 30,
            body: data,
            type: "power",
            nature: curveNature(currentStep.index === 1),
        }))
        action()
    }

    return <div data-cy={"download-cdc-from-file"}
                onChange={(e:any) => uploadWhen(e.target.files[0])}
                className={"flex justify-between whitespace-nowrap align-center my-2 text-md  bg-blue-500 text-white rounded font-semibold hover:cursor-pointer  hover:bg-blue-600"}>
        <input
            data-cy={"input-cdc-from-file"}
            className={"hidden w-full"}
            multiple={false}
            accept={".xls,.xlsx"}
            disabled={isLoadingForm}
            type="file" id={"fileUpload"}/>
        <label
            className={"flex w-full justify-between items-center self-center text-sm hover:cursor-pointer"}
            htmlFor={"fileUpload"}>
            <span
                className={"py-3 px-4 "}>Téléverser une courbe de charge</span>
            <div
                className={"bg-blue-600 h-full p-2  flex items-center rounded-md bg-opacity-70"}>
                {isLoadingForm ? <SpinningSVG /> : <DownloadIcon/>}
            </div>
        </label>
    </div>
}
