import React from "react";
import withConditionalRender from "../../../../wrapper/withConditionalRender";
import {useDispatch, useSelector} from "react-redux";
import {PuzzlePieceIconSolid} from "../../../../../components/assets/icon/PuzzlePieceIcon";
import {selectIsLoadingNewCurve} from "../../../../../../../../corelogic/usecases/loader/loaderSelector";
import {
    selectShowLowCompletenessForm,
    selectUndefinitiveNewCurve
} from "../../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import {typologyFormActions} from "../../../../../../../../corelogic/usecases/typology-form/typologyFormActions";
import {
    selectEnableLowCompletionButton
} from "../../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-curve/selectFormCurve";
import {useSnackbar} from "notistack";

function LowCompletenessButton() {
    const loading = useSelector(selectIsLoadingNewCurve)
    const dispatch = useDispatch()
    const enableButton = useSelector(selectEnableLowCompletionButton)
    const {enqueueSnackbar} = useSnackbar()

    const showForm = useSelector(selectShowLowCompletenessForm)
    const isClickedStyle = enableButton ?
        (showForm ? ' border-2 border-blue-600 hover:cursor-pointer hover:bg-blue-300 dark:hover:bg-blue-500  '
            : ' hover:cursor-pointer hover:bg-blue-300 dark:hover:bg-blue-500   ')
        : ' opacity-40 '
    function onButtonClick(){
        if(enableButton && !loading){
            dispatch(typologyFormActions.showLowCompletenessForm(!showForm))
            dispatch(typologyFormActions.setFullCompletudeButton(false))
            dispatch(typologyFormActions.setRecalageButtonAction(false))
        } else {
            enqueueSnackbar("La courbe doit être d'au moins 1 semaine sans trous.", {variant : "warning"})

        }
    }

    return <span
        onClick={onButtonClick}
        title={enableButton ? "Permet de compléter la courbe avec peu de données." : "La courbe ne peux être complétée."}
        className={isClickedStyle + "flex bg-opacity-60 dark:bg-opacity-60 dark:hover:bg-opacity-80  p-1 gap-1 px-2 bg-blue-300  dark:bg-blue-700 items-center rounded "}>
        <PuzzlePieceIconSolid className={enableButton ? "text-blue-600 h-5 w-5 dark:text-blue-200" : "text-gray-600 h-5 w-5 dark:text-gray-200"}/>
         <span className={enableButton ? 'font-semibold dark:text-blue-200 text-blue-600 text-xs truncate' : 'font-semibold dark:text-gray-200 text-gray-600 text-xs truncate'}>
             Complétude peu de données
         </span>
</span>
}
export default withConditionalRender(LowCompletenessButton, selectUndefinitiveNewCurve)
