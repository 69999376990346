import {useSelector} from "react-redux";
import {
    selectCartoCircleInKm,
    selectInitialCircleCoordinates,
    selectOptimizedCircle
} from "../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import {
    atLeast1ActiveInjectionPointsWithAddress,
    selectActiveInjectionPointsWithAddressFilled,
    selectInactiveInjectionPointsWithAddressFilled
} from "../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectInjectionPointForm";
import {
    selectActiveACCConsumptionPointsWithAddressFilled,
    selectActiveACIConsumptionPointsWithAddressFilled,
    selectInactiveConsumptionPointsWithAddressFilled
} from "../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectConsumptionPointForm";
import L from "leaflet";
import React, {useEffect, useState} from "react";
import {
    addConsumptionPointsMarkerTo,
    addCustomkmCircleTo,
    addInjectionPointsMarkerTo,
    createMap,
    DARK_SAPPHIRE,
    DARK_SAPPHIRE_LIGHT,
    DISABLED_COLOR,
    ICELAND_POPPY,
    ICELAND_POPPY_DARKEN,
    ICELAND_POPPY_LIGHT,
    mapDensityToColor,
    TOMATO_RED,
    TOMATO_RED_DARKEN
} from "./LeafletMap";
import geoJSONData from "../communes_1.json"


export default function LeafletMapWithCommunes(){
    const [currentCicleCoords, setCurrentCircleCoords] = useState({lng: 0, lat: 0})
    const initialCircleCoordinates : {lng:number, lat:number} = useSelector(selectInitialCircleCoordinates)
    const optimizedCircle = useSelector(selectOptimizedCircle)

    useEffect(() => {
        if(optimizedCircle){
            setCurrentCircleCoords(optimizedCircle)
        } else if( initialCircleCoordinates){
            setCurrentCircleCoords(initialCircleCoordinates)
        }
    }, [initialCircleCoordinates, optimizedCircle]);

    const circleDiameter:number = useSelector(selectCartoCircleInKm)
    const activeWithAddressInjectionPoints = useSelector(selectActiveInjectionPointsWithAddressFilled)
    const inactiveWithAddressInjectionPoints = useSelector(selectInactiveInjectionPointsWithAddressFilled)
    const activeWithAddressACCConsumptionPoints = useSelector(selectActiveACCConsumptionPointsWithAddressFilled)
    const activeWithAddressACIConsumptionPoints = useSelector(selectActiveACIConsumptionPointsWithAddressFilled)

    const inactiveWithAddressConsumptionPoints = useSelector(selectInactiveConsumptionPointsWithAddressFilled)

    const atLeast1InjectionPointWithAddressActive = useSelector(atLeast1ActiveInjectionPointsWithAddress)
    let map: L.Map

    useEffect(() => {
        if (atLeast1InjectionPointWithAddressActive && activeWithAddressInjectionPoints[0].address) {
            var container: any = L.DomUtil.get('map');
            if (container != null) {
                container._leaflet_id = null;
            }
            if (map !== undefined) {
                map = map.off().remove()
            } else {
                map = createMap(activeWithAddressInjectionPoints[0].address)
                addCommunesDensity(map, circleDiameter)
                addLegend(map, circleDiameter)
                addConsumptionPointsMarkerTo(map, inactiveWithAddressConsumptionPoints, false, false)
                addInjectionPointsMarkerTo(map, inactiveWithAddressInjectionPoints, false)
                addInjectionPointsMarkerTo(map, activeWithAddressInjectionPoints, true)
                addConsumptionPointsMarkerTo(map, activeWithAddressACCConsumptionPoints, true, false)
                addConsumptionPointsMarkerTo(map, activeWithAddressACIConsumptionPoints, true, true)
                addCustomkmCircleTo(map, currentCicleCoords, circleDiameter, true)
            }
        }
    }, [activeWithAddressInjectionPoints,currentCicleCoords, circleDiameter])

    return <div className={"h-[400px] 2xl:h-[750px] w-full "} id="map"></div>
}


function addCommunesDensity(map: L.Map, circleDiameter:number) {

    var communesDensity = L.geoJSON(geoJSONData as any, {
        style: function(feature:any) {
            const density = feature.properties.density
            const isDisabled = circleDiameter >= 10 && density  <= 2 || circleDiameter >= 20 && density  <= 4

            return {
                fillColor: isDisabled ? DISABLED_COLOR : mapDensityToColor(density),
                weight: 0.5,
                opacity: 1,
                color: 'white',
                fillOpacity: isDisabled ? 0.95 : 0.4
            };
        }
    })
    communesDensity.addTo(map);
}

function addLegend(map: L.Map, circleDiameter:number) {
    const CustomControl = L.Control.extend(
        {
            options: {
                position: 'topright'
            },
            onAdd: function (map:L.Map) {

                var div = L.DomUtil.create('div', 'info legend flex flex-col gap-0.5'),
                    grades = [1,2,3,4,5,6,7],
                    labels = [
                        `Grands centres urbains ${circleDiameter >=10 ? '(désactivé)' : ''}`,
                        `Centres urbains intermédiaires ${circleDiameter >=10 ? '(désactivé)' : ''}`,
                        `Petites villes ${circleDiameter >=20 ? '(désactivé)' : ''}`,
                        `Ceintures urbaines ${circleDiameter >=20 ? '(désactivé)' : ''}`,
                        `Bourgs ruraux`,
                        'Rural à habitat dispersé',
                        'Rural à habitat très dispersé'
                    ],
                    colors = [
                        circleDiameter >=10 ? DISABLED_COLOR :TOMATO_RED_DARKEN,
                        circleDiameter >=10 ? DISABLED_COLOR :TOMATO_RED,
                        circleDiameter >=20 ? DISABLED_COLOR :ICELAND_POPPY_DARKEN,
                        circleDiameter >=20 ? DISABLED_COLOR :ICELAND_POPPY,
                        ICELAND_POPPY_LIGHT,
                        DARK_SAPPHIRE_LIGHT,
                        DARK_SAPPHIRE];

                // Loop through the grades and generate a label with a colored square for each grade
                for (var i = 0; i < grades.length; i++) {
                    div.innerHTML +=
                        '<div className="flex gap-2">' +
                        '<i style="background:' + colors[i] + '; opacity:0.5;"></i> ' + labels[i] + '<br>' +
                        '</div>';
                }
                return div;
            },
            onRemove: function (map:L.Map) {
                // Clean up when the control is removed from the map
            }
        })
    const legend = new CustomControl();
    legend.addTo(map)
}
