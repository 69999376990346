import {useSelector} from "react-redux";
import {
    selectCostsData,
    selectSurplusLoading
} from "../../../../../corelogic/usecases/financialRestitution/financialRestitutionSelector";
import {formatNumber} from "../../../../../corelogic/usecases/config";
import {CostAndGain} from "../../../../../corelogic/models/types/financialRestitution/CostAndGain";

const TVAAndAcciseTable = () => {
    const costs = useSelector(selectCostsData);
    const loading = useSelector(selectSurplusLoading);

    return <table id={'tva-accise-table'} className="bg-white  w-full  relative overflow-x-auto px-2 py-4 table-auto shadow-md rounded-lg dark:bg-zinc-700">
        <thead>
        <tr className="text-gray-500 text-xs leading-normal dark:text-zinc-400 bg-white dark:bg-zinc-700">
            <th align={"left"} className="py-3 px-4 ">Point d'injection</th>
            <th align={"right"} className="py-3 px-4 ">Accise sur l'electricité 1 an</th>
            <th align={"right"} className="py-3 px-4 ">TVA collectées dans la facture interne</th>
        </tr>
        </thead>
        <tbody className="text-gray-500 text-xs font-light bg-white dark:bg-zinc-700">
        {!loading ? costs.map((cost: CostAndGain, index: number) =>
                <tr key={cost.name || 'total'}
                    className={`border-b border-gray-200 hover:bg-gray-100 dark:hover:bg-zinc-600 ${index === costs.length - 1 ? 'dark:hover:bg-green-800  dark:bg-green-900 bg-green-300 hover:bg-green-400' : ''}`}>
                    <td align={"left"} className="py-2 px-4  whitespace-nowrap dark:text-zinc-200">
                        <span className="font-semibold">{cost.name}</span>
                    </td>
                    <td align={"right"} className="py-2 px-4 dark:text-zinc-200 ">
                <span
                    className={`font-semibold text-sm ${index === costs.length - 1 ? 'text-green-700 dark:text-green-200' : 'text-slate-400 dark:text-zinc-400'}`}>{formatNumber(-cost.acciseFirstYear || 0)} €</span>
                    </td>

                    <td align={"right"} className="py-2 px-4 ">
                <span
                    className={`font-semibold text-sm ${index === costs.length - 1 ? 'text-green-700 dark:text-green-200' : 'text-slate-400 dark:text-zinc-400'}`}>{formatNumber(cost.tvaCollected || 0)} €</span>
                    </td>

                </tr>)
            : [...Array(3)].map(() => {
                return <tr className="border-b border-gray-200 animate-pulse">
                    {[...Array(3)].map((_, idx) => {
                        return <td key={idx} className="py-2 px-4 text-left whitespace-nowrap">
                            <div className="flex items-left">
                                <span className="font-bold bg-gray-100 dark:bg-zinc-600 h-4 w-full"/>
                            </div>
                        </td>
                    })}
                </tr>
            })
        }
        </tbody>
    </table>
}

export default TVAAndAcciseTable
