import {PhysicalRestitutionGatewayInterface} from "../../../../corelogic/usecases/physicalRestitution/physicalRestitutionGateway.interface";
import apiClient from "../Api/ApiClient";

export class PhysicalRestitutionInApiGateway implements PhysicalRestitutionGatewayInterface {

    getCDC = async (simulationId: string, nature: string, pointId: string): Promise<any> => {
        const params: any = {

        }
        if(pointId) {
            params.point_id = pointId
        }
        const {data: {measurements}} = await apiClient.get(`/simulations/${simulationId}/physics/load_curves/${nature}`, {params,
            'axios-retry': {
                retries: 40,
                retryDelay: () => 3000,
            }});
        return measurements;
    }

    getCDCGroupedByMonth = async (simulationId: string, nature: string): Promise<any> => {
        const {data: {measurements}} = await apiClient.get(`/simulations/${simulationId}/physics/load_curves/${nature}?grouped_by_month=true`, {
            'axios-retry': {
                retries: 40,
                retryDelay: () => 3000,
            }});
        return measurements;
    }

    getMetricValue = async (simulationId: string, nature: string, pointId?: string): Promise<number> => {
        const params: any = {}
        if(pointId) {
            params.point_id = pointId
        }
        const {data: {value}} = await apiClient.get(`/simulations/${simulationId}/physics/metrics/${nature}`, {params});
        return value;
    }

}
