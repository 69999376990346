import {ConsumptionPoint} from "../../../models/types/new-simulation/form/ConsumptionPoint";
import {useDispatch, useSelector} from "react-redux";
import {
    selectSeasonalParameters
} from "../../new-simulation/new-simulation-selector/form-data/selectOperationParameter";
import {useEffect} from "react";
import {seasonalityUtils} from "./seasonality.utils";
import {
    selectConsumptionPoints
} from "../../new-simulation/new-simulation-selector/form-data/selectConsumptionPointForm";
import {
    operationParametersFormActions
} from "../operationParametersFormActions";
import {Seasonality} from "../../../models/types/new-simulation/form/operation-parameters/seasonality/Seasonality";

export const INITIAL_MONTHLY_SEASON = new Array(12).fill(true)
export const MONTHS = ["Jan", "Fév", "Mar", "Avr", "Mai", "Juin", "Juil", "Aou", "Sept", "Oct", "Nov", "Déc"]


export default function useSeasonalityRetrieve(): void {
    const dispatch = useDispatch()
    const backendSeasonality: Seasonality | null = useSelector(selectSeasonalParameters)
    const consumptionPoints: ConsumptionPoint[] = useSelector(selectConsumptionPoints)

    useEffect(() => {
        if (backendSeasonality) {
            dispatch(operationParametersFormActions.setSeasonality({
                consumptionPoints: backendSeasonality.consumptionPoints,
                seasonActivated: backendSeasonality.seasonActivated.map((season) => [...season])
            }))
        } else {
            dispatch(operationParametersFormActions.setSeasonality(seasonalityUtils.init(consumptionPoints)))
        }
    }, [backendSeasonality]);
}


