import {createAction} from "../../../store/createAction";
import {ActionsUnion} from "../../../store/actionsUnions";

export const HANDLE_ERROR = 'HANDLE_ERROR';
export const SET_ERROR = 'SET_ERROR';
export const RESET_ERROR = 'RESET_ERROR';
export const errorsActions = {
    handleError: (error: Error) => createAction(HANDLE_ERROR, error),
    setError:(error:string) => createAction(SET_ERROR, error),
    resetError:() => createAction(RESET_ERROR)
};

export type Actions = ActionsUnion<typeof errorsActions>;
