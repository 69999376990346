export class DistributionPriority {
    private readonly _point_id: string;
    private _priority: number;

    constructor(pointId: string, priority: number) {
        this._point_id = pointId;
        this._priority = priority;
    }

    get pointId(): string {
        return this._point_id;
    }

    get priority(): number {
        return this._priority;
    }

    set priority(value: number) {
        this._priority = value;
    }

    serialize() {
        return {point_id: this._point_id, priority: this._priority};
    }
}
