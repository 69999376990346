import {useDispatch} from "react-redux";
import {push} from "connected-react-router";

export default function NotFound()  {
    const dispatch = useDispatch()
    function pushToHomePage(){
        dispatch(push('/'))
    }
    return <div className="w-full mt-8 scale-95 2xl:scale-100 flex flex-col items-center min-h-[70vh] justify-center">
                <div className="uppercase text-secondary font-semibold">Erreur</div>
                <div className="text-gray-800 text-4xl font-extrabold my-2">Page introuvable</div>
                <div className="text-gray-500 mb-6">Nous nous excusons, mais nous n'arrivons pas à trouver la page que
                    vous recherchez.
                </div>
                <button onClick={pushToHomePage} className="bg-secondary opacity-80 text-violet-100 rounded-md px-4 py-2 hover:opacity-100">Retour à la
                    page d'accueil
                </button>
            </div>
}