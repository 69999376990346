import axios from "axios";
import axiosRetry from "axios-retry";

const apiClient = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_API_URL
},)

axiosRetry(apiClient, {
    retries: 48,
    retryDelay: () => 2500,
    retryCondition : (retryCondition)=> {
        if(retryCondition.response){
            return retryCondition.response.status === 409
        }
        else {
            return false
        }
    }
});

export default apiClient;