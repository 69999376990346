import React, {useEffect, useState} from "react";
import L from "leaflet";
import injectionPinImg from "../injection-pin.svg"
import inactiveInjectionPinImg from "../injection-pin-inactive.svg"
import consumptionPinImg from "../consumption-pin.svg"
import inactiveConsumptionPinImg from "../consumption-pin-inactive.svg"
import shadowPinImg from "../shadow-pin.svg"
import aciPinImg from "../aci-pin.svg"
import {useSelector} from "react-redux";
import './legend.css'
import {
    atLeast1ActiveInjectionPointsWithAddress,
    selectActiveInjectionPointsWithAddressFilled,
    selectInactiveInjectionPointsWithAddressFilled
} from "../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectInjectionPointForm";
import {InjectionPoint} from "../../../../../../corelogic/models/types/new-simulation/form/InjectionPoint";
import {AddressGouv} from "../../../../../../corelogic/models/types/new-simulation/external-api/AddressGouv";
import {ConsumptionPoint} from "../../../../../../corelogic/models/types/new-simulation/form/ConsumptionPoint";
import {
    selectCartoCircleInKm,
    selectInitialCircleCoordinates,
    selectOptimizedCircle,
} from "../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import {
    selectActiveACCConsumptionPointsWithAddressFilled,
    selectActiveACIConsumptionPointsWithAddressFilled,
    selectInactiveConsumptionPointsWithAddressFilled
} from "../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectConsumptionPointForm";

export const TOMATO_RED_DARKEN = 'rgba(183,0,16)'
export const TOMATO_RED = 'rgba(255,99,72)'

export const ICELAND_POPPY_DARKEN= 'rgba(255,165,2)'
export const ICELAND_POPPY = 'rgba(236,204,104)'

export const ICELAND_POPPY_LIGHT = 'rgba(46,213,115)'
export const DARK_SAPPHIRE_LIGHT = 'rgba(30,144,255)'

export const DARK_SAPPHIRE = 'rgba(55,66,250)'

export const DISABLED_COLOR = 'rgba(155,155,155,0.95)'


function mapDensityToColor(density: number) {
    switch(density){
        case 1: return TOMATO_RED_DARKEN;
        case 2: return TOMATO_RED;
        case 3: return ICELAND_POPPY_DARKEN;
        case 4: return ICELAND_POPPY;
        case 5: return ICELAND_POPPY_LIGHT;
        case 6: return DARK_SAPPHIRE_LIGHT;
        case 7: return DARK_SAPPHIRE;
    }
}
export default function LeafletMap() {
    const [currentCicleCoords, setCurrentCircleCoords] = useState({lng: 0, lat: 0})
    const initialCircleCoordinates : {lng:number, lat:number} = useSelector(selectInitialCircleCoordinates)
    const optimizedCircle = useSelector(selectOptimizedCircle)

    useEffect(() => {
        if(optimizedCircle){
            setCurrentCircleCoords(optimizedCircle)
        } else if( initialCircleCoordinates){
            setCurrentCircleCoords(initialCircleCoordinates)
        }
    }, [initialCircleCoordinates, optimizedCircle]);

    const circleDiameter:number = useSelector(selectCartoCircleInKm)
    const activeWithAddressInjectionPoints = useSelector(selectActiveInjectionPointsWithAddressFilled)
    const inactiveWithAddressInjectionPoints = useSelector(selectInactiveInjectionPointsWithAddressFilled)
    const activeWithAddressACCConsumptionPoints = useSelector(selectActiveACCConsumptionPointsWithAddressFilled)
    const activeWithAddressACIConsumptionPoints = useSelector(selectActiveACIConsumptionPointsWithAddressFilled)

    const inactiveWithAddressConsumptionPoints = useSelector(selectInactiveConsumptionPointsWithAddressFilled)

    const atLeast1InjectionPointWithAddressActive = useSelector(atLeast1ActiveInjectionPointsWithAddress)
    let map: L.Map
    useEffect(() => {
        if (atLeast1InjectionPointWithAddressActive && activeWithAddressInjectionPoints[0].address) {
            var container: any = L.DomUtil.get('map');
            if (container != null) {
                container._leaflet_id = null;
            }
            if (map !== undefined) {
                map = map.off().remove()
            } else {
                map = createMap(activeWithAddressInjectionPoints[0].address)
                addConsumptionPointsMarkerTo(map, inactiveWithAddressConsumptionPoints, false, false)
                addInjectionPointsMarkerTo(map, inactiveWithAddressInjectionPoints, false)
                addInjectionPointsMarkerTo(map, activeWithAddressInjectionPoints, true)
                addConsumptionPointsMarkerTo(map, activeWithAddressACCConsumptionPoints, true, false)
                addConsumptionPointsMarkerTo(map, activeWithAddressACIConsumptionPoints, true, true)
                addCustomkmCircleTo(map, currentCicleCoords, circleDiameter, false)
            }
        }
    }, [activeWithAddressInjectionPoints, circleDiameter, currentCicleCoords])

    return <div className={"h-[400px] 2xl:h-[750px] w-full "} id="map"></div>

}
function createMap(address: AddressGouv): L.Map {
    var map = L.map('map').setView([address.latitude, address.longitude], 13);
    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    })
        .addTo(map)
    return map
}

function addCustomkmCircleTo(map: L.Map, coord : {lng:number, lat:number}, customCircleDiameter:number, withDensity:boolean) {
    const getCircleColor = () => {
        if(withDensity) return '#ffffff'
        if(customCircleDiameter < 10) return TOMATO_RED
        if(customCircleDiameter < 20) return ICELAND_POPPY
        return DARK_SAPPHIRE
    }

    const circleColor= getCircleColor()
    var circle = L.circle([coord.lat, coord.lng], {
        color: withDensity ? 'rgba(255,255,255,0.8)' : circleColor,
        fillColor: circleColor,
        fillOpacity: 0.3,
        radius: Number((customCircleDiameter/2)*1000)
    })
        .addTo(map)
        .bindTooltip(`Cercle de ${customCircleDiameter} km`, {
            className: `font-bold text-white bg-gray-700 bg-opacity-80  rounded-lg border-0 py-1 px-2 `
        })
        .openTooltip()

    circle.on({
        click: function () {
            map.on('click', function (e) {
                circle.setLatLng(e.latlng);
            });
        },
        mousedown: function () {
            map.on('mousemove', function (e) {
                circle.setLatLng(e.latlng);
            });
        }
    });
    map.dragging.disable()
    map.on('click', function (e: any) {
        map.removeEventListener('mousemove');
    })
}

function addInjectionPointsMarkerTo(map: L.Map, injectionPoints: InjectionPoint[], active: boolean) {
    const markerStyle = ' text-white text-xs border-0 rounded-lg font-bold ' + (active ? ' bg-yellow-400 ' : ' bg-zinc-400 ')

    function createInjectionIcon() {
        var injectionIcon = L.icon({
            iconUrl: active ? injectionPinImg : inactiveInjectionPinImg,
            shadowUrl: shadowPinImg,
            iconSize: [42, 46],
            shadowSize: [22, 22],
            iconAnchor: [21, 46],
            shadowAnchor: [0, 22],
            popupAnchor: [-3, -76],
            className: active ? "" : "opacity-50"
        });
        return injectionIcon
    }

    injectionPoints.forEach((injectionPoint) => {
        if (injectionPoint.address) {
            L.marker([injectionPoint.address.latitude, injectionPoint.address.longitude], {icon: createInjectionIcon()})
                .addTo(map)
                .bindTooltip(injectionPoint.name, {
                    className: markerStyle
                })
        }
    })
}

function addConsumptionPointsMarkerTo(map: L.Map, consumptionPoints: ConsumptionPoint[], active: boolean, aci: boolean) {
    const markerStyle = '  text-xs border-0 rounded-lg font-bold ' + (active ? (aci ? 'bg-yellow-500 text-blue-500' : ' bg-blue-500 text-white') : ' bg-zinc-400  text-white')

    function createConsumptionIcon() {
        var consumptionIcon = L.icon({
            iconUrl: active ? (aci ? aciPinImg : consumptionPinImg) : inactiveConsumptionPinImg,
            shadowUrl: shadowPinImg,
            iconSize: [42, 46],
            shadowSize: [22, 22],
            iconAnchor: [21, 46],
            shadowAnchor: [0, 22],
            popupAnchor: [-3, -76],
            className: active ? "" : "opacity-50"
        });
        return consumptionIcon
    }

    consumptionPoints.map((consumptionPoint) => {
        if (consumptionPoint.address) {
            L.marker([consumptionPoint.address.latitude, consumptionPoint.address.longitude], {icon: createConsumptionIcon()})
                .addTo(map)
                .bindTooltip(consumptionPoint.name, {
                    className: markerStyle
                })
        }
    })

}

export {mapDensityToColor, createMap, addInjectionPointsMarkerTo, addConsumptionPointsMarkerTo, addCustomkmCircleTo}



