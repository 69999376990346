import EmptyTable from "./EmptyTable";
import React, {Fragment, useCallback, useEffect, useState} from "react";
import {ConsumptionPoint} from "../../../../../corelogic/models/types/new-simulation/form/ConsumptionPoint";
import {useDispatch, useSelector} from "react-redux";

import {XIconRed} from "../../components/assets/icon/XIcon";
import {newSimulationActions} from "../../../../../corelogic/usecases/new-simulation/newSimulationActions";
import CDCPrevisuChart from "../../components/assets/graphic/CDCPrevisuChart";
import {useSnackbar} from "notistack";
import {InjectionPoint} from "../../../../../corelogic/models/types/new-simulation/form/InjectionPoint";
import RainbowLoader from "../../components/assets/Animated/RainbowLoader";
import {displayKwHPrice} from "../../../../../corelogic/models/types/new-simulation/form/ComplementProviderKwhPrice";
import {SmallToggle} from "../../components/assets/toggle/Toggle";
import ConfirmModal from "../confirmation-dialog/ConfirmModal";
import {SortIcon} from "./InjectionPointsTable";
import Notification from "../paper/Notification";
import {amber, blue, green, State, yellow, zinc} from "../../../../../config/app-config";
import TableRowBadge from "../../components/assets/table/TableRowBadge";
import {TrashIconMini} from "../../components/assets/icon/TrashIcon";
import {MapPinIconSolid, MapPinIconSolidDisabled} from "../../components/assets/icon/MapPinIcon";
import {typologyFormActions} from "../../../../../corelogic/usecases/typology-form/typologyFormActions";
import {
    selectPointBeingEdited,
    selectPointEditedIndex
} from "../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import {selectIsLoadingSimulationForm} from "../../../../../corelogic/usecases/loader/loaderSelector";
import {
    selectCurves
} from "../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-curve/selectFormCurve";
import {
    makeSelectConsumptionPointsSorted,
    selectConsumptionPointsHaveDuplicatedNames
} from "../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectConsumptionPointForm";
import {
    selectInjectionPoints
} from "../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectInjectionPointForm";


export default function SoutiragePointsTable() {
    const dispatch = useDispatch()
    const {enqueueSnackbar} = useSnackbar()
    const [expand, setExpand]: any[] = useState([])
    const loadedCurves = useSelector(selectCurves)
    const [dialog, setDialog] = useState<{state:boolean, indexToApply : number}>({state : false, indexToApply : -1})
    const [deletePointDialog, setDeletePointDialog] = useState<{state:boolean, indexToApply : number}>({state : false, indexToApply : -1})

    const [sorting, setSorting] = useState({column :'', asc:true})
    const selectSortSoutiragePoints = useCallback(makeSelectConsumptionPointsSorted(sorting), [sorting])
    const soutiragePoints = useSelector(selectSortSoutiragePoints)
    const injectionPoints = useSelector(selectInjectionPoints)
    const soutiragePointsFormHaveDuplicatedNames =  useSelector(selectConsumptionPointsHaveDuplicatedNames)
    const isFormLoading = useSelector(selectIsLoadingSimulationForm)

    const rowToEdit = useSelector(selectPointBeingEdited)
    const indexOnEdit = useSelector(selectPointEditedIndex)

    useEffect(() => {
        if (!rowToEdit) {
            dispatch(typologyFormActions.setEditPoint(null))
            dispatch(typologyFormActions.setEditPointIndex(null))
            dispatch(typologyFormActions.setClear(true))
        }
    }, [rowToEdit])

    function handleExpand(index: number) {
        // expand update onClick
        let newExpand = [...expand]
        if (!expand[index]) {
            newExpand[index] = 1
        } else {
            newExpand[index] = 0
        }
        dispatch(newSimulationActions.loadCurve(soutiragePoints[index].loadCurveId))
        setExpand(newExpand)
    }


    function handleCheck(index: number) {
        if (soutiragePoints[index].injectionPointId) {
            setDialog({state : true, indexToApply :index})
        } else {
            dispatch(newSimulationActions.setPointState(soutiragePoints[index].id, !soutiragePoints[index].state))
        }
    }
    function handleSorting(column:string){
        setSorting({column: (sorting.column === column && !sorting.asc) ? '':column, asc: !(sorting.column === column && sorting.asc)})
    }

    function rowClassName(index: number) {
        let checkStateStyle: string = !soutiragePoints[index].state ? " text-opacity-60 bg-opacity-60 bg-zinc-100 hover:bg-zinc-100 dark:bg-zinc-600 dark:hover:bg-zinc-600" : ""
        let indexStateStyle: string = indexOnEdit === index ? " text-gray-600 dark:text-zinc-400 border-2 bg-blue-300 w-full dark:border-0 dark:bg-slate-800 border-blue-400" : " text-gray-600 dark:text-zinc-400 border-b border-gray-200 w-full hover:bg-gray-100 dark:hover:bg-zinc-600"
        return indexStateStyle + checkStateStyle
    }


    function onEditActionChange(index: number) {
        if (indexOnEdit === index) {
            return <div className="flex items-right items-end justify-center ">
                <span title={"Annuler"} onClick={() => handleCancelEditRow()}
                      className={"flex text-xs text-red-500 items-center font-semibold hover:cursor-pointer hover:scale-110"}>
                    Annuler
                    <div className={" icon " + " hover:cursor-pointer "}>
                        <XIconRed/>
                    </div>
                </span>
            </div>
        } else {
            return <div className="flex items-left ">
                <span title={"Editer le point"} className={" icon " + " hover:cursor-pointer "} onClick={() => handleEditRow(index)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"/>
                    </svg>
                </span>
                <span title={"Supprimer le point"}  onClick={() => setDeletePointDialog({state : true, indexToApply : index})}>
                    <TrashIconMini className={" icon " + " hover:cursor-pointer "}/>
                </span>
                <span>
                    {soutiragePoints[index].address ?
                        <div title={"Adresse indiquée : " + soutiragePoints[index].address.label}>
                            <MapPinIconSolid className={" icon " + " hover:cursor-help "}/>
                        </div>
                        : <div title={"pas d'adresse renseignée"}>
                            <MapPinIconSolidDisabled  className={" icon " +" hover:cursor-help "}/>
                        </div>
                    }
                </span>
                <span title={!expand[index] ? "Montrer la courbe de charge" : "Cacher la courbe de charge"}
                      className={" icon " + " hover:cursor-pointer "} onClick={() => handleExpand(index)}>
                    {!expand[index] ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                           stroke="currentColor" strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7"/>
                        </svg> :
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor" strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M5 15l7-7 7 7"/>
                        </svg>
                    }
                </span>

                <span className={"ml-2"}
                      title={!soutiragePoints[index].state ? "Activer le point" : "Désactiver le point"}>
                    <SmallToggle checked={soutiragePoints[index].state} action={() => handleCheck(index)}/>
                </span>
            </div>
        }
    }




    function handleCancelEditRow() {
        dispatch(newSimulationActions.setNewCurve(null))
        dispatch(newSimulationActions.setFirstNewCurve(null))
        dispatch(typologyFormActions.setEditPoint(null))
        dispatch(typologyFormActions.setEditPointIndex(null))
        dispatch(typologyFormActions.setClear(true))
    }

    function handleEditRow(index: number) {
        dispatch(typologyFormActions.setEditPoint(soutiragePoints[index]))
        dispatch(typologyFormActions.setEditPointIndex(index))
    }

    function getNameFromInjectionPoint(pointId: string | null): string {
        let injectionPointName: string = ""
        if (pointId) {
            injectionPoints.map((injectionPoint: InjectionPoint) => {
                if (injectionPoint.id === pointId) {
                    injectionPointName = injectionPoint.name
                }
            })
        }
        return injectionPointName
    }
    function handleConfirmDeleteRow() {
        const index = deletePointDialog.indexToApply

        const pointNameToDelete: string = soutiragePoints[index].name
        dispatch(newSimulationActions.removeConsumptionPoint(soutiragePoints[index]))
        enqueueSnackbar("Le point '" + pointNameToDelete + "' a été supprimé.", {variant: 'info'})
        setDeletePointDialog({state: false, indexToApply: deletePointDialog.indexToApply})

    }

    function handleDesactivatePoint(){
        let newSoutiragePoints: ConsumptionPoint[] = [...soutiragePoints]
        newSoutiragePoints[dialog.indexToApply].injectionPointId = null
        newSoutiragePoints[dialog.indexToApply].state = false
        dispatch(newSimulationActions.updateConsumptionPoint(newSoutiragePoints[dialog.indexToApply]))
        setDialog({state : false, indexToApply:-1})
    }

    return   <>
        {
            deletePointDialog.state &&
            <ConfirmModal
                title={"Supprimer un point de consommation"}
                description={"Le point de consommation (" + soutiragePoints[deletePointDialog.indexToApply].name + ") sera supprimé du formulaire et des résultats de simulation."}
                actionContinue={() => handleConfirmDeleteRow()}
                actionCancel={()=>setDeletePointDialog({state : false, indexToApply : -1})}
                isLoading={isFormLoading}
            />
        }

        {
            dialog.state && <ConfirmModal title={"Désactiver un point de consommation lié en ACI"}
                                                                               description={"Désactiver ce point va retirer son lien en ACI. Ce point d'injection sera libre pour une nouvelle affectation avec un point de soutirage."}
                                                                               actionContinue={() =>handleDesactivatePoint()}
                                                                               actionCancel={() => setDialog({state : false, indexToApply:-1})}/>
    }
    <div data-cy={"typology-consumption-table"} className={"flex flex-col box-border mx-4"}>
        {soutiragePointsFormHaveDuplicatedNames && <Notification state={State.ERROR} text={"Attention, il y a parmi vos points de soutirage des doublons dans les noms. Veuillez éditer le nom ou supprimer le point posant problème."}/>}
        <table className="w-full table-auto  mt-8 h-min">
                <thead>
                <tr className="bg-gray-200 text-gray-600 text-sm leading-normal ">
                    <th onClick={() => handleSorting('name')}
                        className="py-3 pl-3 pr-0 text-left hover:cursor-pointer hover:text-gray-500 flex gap-2">
                        Nom
                        <SortIcon focused={sorting.column === "name"} asc={sorting.asc}/>

                    </th>
                    <th className="py-3 px-0 text-left">Type</th>
                    <th className="py-3 px-0 text-left">Nombre</th>
                    <th onClick={() => handleSorting('segment')} className="py-3 px-6 text-left hover:cursor-pointer hover:text-gray-500 flex gap-2">
                        Segment
                        <SortIcon focused={sorting.column === "segment"} asc={sorting.asc}/>
                </th>
                    <th className="py-3 px-0 text-left">Profil Enedis</th>
                    <th title={"Prix du kWh (€ HT/kWh)"}
                        className="py-3 px-0 text-left overflow-ellipsis overflow-hidden  whitespace-nowrap max-w-[70px] 2xl:max-w-[1000px]">Prix
                        du kWh (€ HT/kWh)
                    </th>
                    <th className="py-3 px-0 text-left">ACI</th>
                    <th className="py-3 px-0 text-left overflow-ellipsis   whitespace-nowrap">TVA</th>
                    <th className="py-3 px-0 text-left">Actions</th>
                </tr>
                </thead>
                <tbody className="text-gray-600 text-sm font-light dark:text-zinc-300">
                {soutiragePoints && soutiragePoints.length > 0 && soutiragePoints.map((point: ConsumptionPoint, index: number) => {
                    return (<Fragment>
                        <tr className={rowClassName(index)}>
                            <td className="py-2 pl-3  text-left whitespace-nowrap font-medium">
                                <div title={point.name} className="flex items-center ">
                                    <span title={point.name}
                                          className={"overflow-ellipsis overflow-hidden  whitespace-nowrap max-w-[150px] 2xl:max-w-[1000px]"}>{point.name}</span>
                                </div>
                            </td>
                            <TableRowBadge
                                text={point.consumerType}
                                color={blue}
                                disabled={!soutiragePoints[index].state}/>
                            <td className="py-2 px-0  text-left">
                                <div
                                    className=" flex items-center justify-center font-bold">{point.numberOfPoints}</div>
                            </td>
                            <TableRowBadge
                                text={point.segment}
                                color={zinc}
                                disabled={!soutiragePoints[index].state}/>
                            <td className="py-2 px-0  text-left">
                                <div className="flex items-center">
                                    {point.heatingType ?
                                        <span title={point.accommodationType + " - " + point.heatingType}
                                              className={!soutiragePoints[index].state ? " text-opacity-40 bg-opacity-40 font-semibold badge bg-yellow-200 text-yellow-600 overflow-ellipsis overflow-hidden  whitespace-nowrap max-w-[100px] 2xl:max-w-[200px]" : " font-semibold badge bg-yellow-200 text-yellow-600 overflow-ellipsis overflow-hidden  whitespace-nowrap max-w-[100px] 2xl:max-w-[200px]"}>{point.accommodationType + " - " + point.heatingType}</span> :
                                        <span>-</span>}
                                </div>
                            </td>
                            <TableRowBadge text={displayKwHPrice(point, true)} color={green} disabled={!soutiragePoints[index].state} helpText={displayKwHPrice(point)}/>
                            <TableRowBadge text={getNameFromInjectionPoint(point.injectionPointId)} color={zinc} disabled={!soutiragePoints[index].state} />
                            <TableRowBadge
                                text={point.isVATRecoverable ? '€ HT ' : '€ TTC'}
                                color={point.isVATRecoverable ? yellow : amber}
                                disabled={!soutiragePoints[index].state}
                                helpText={point.isVATRecoverable ? "La récupération de la TVA est activée pour ce point." : "La récupération de la TVA n'est pas activée pour ce point."}/>
                            <td className="py-2 px-0  text-left">
                                {onEditActionChange(index)}
                            </td>
                        </tr>
                        {expand && expand[index] === 1 ?
                            <tr>
                                <td colSpan={10} className={"w-full"}>
                                    {loadedCurves[point.loadCurveId]?.loading ?
                                        <RainbowLoader text={"Chargement"}/> :
                                        <CDCPrevisuChart color="rgb(0,30,98)"
                                                         data={loadedCurves[point.loadCurveId]?.curve}/>}
                                </td>
                            </tr>
                            :
                            <Fragment/>
                        }
                    </Fragment>)
                })
                }
                </tbody>
            </table>

        {soutiragePoints.length === 0 && <EmptyTable title={"Aucun points de soutirage"}
                                                     description={"Vous n'avez pas encore ajouté de points de soutirage."}/>}
    </div>
    </>

}
