export default class LowCompletudeDate {
    private _days: string;
    private _months: string;
    private _years: string


    constructor(days: string, months: string, years: string) {
        this._days = days;
        this._months = months;
        this._years = years;
    }




    serialize():string{
        return this.years+'-'+this.monthsSerialized()+'-'+this.daysSerialized()
    }
    daysSerialized(){
        if(this.days.length === 1) return '0'+this.days
        return this.days
    }
    monthsSerialized(){
        if(this.months.length === 1) return '0'+this.months
        return this.months
    }
    makeAsDate = ():Date   =>   {
        return new Date(Number(this.years), Number(this.months) - 1, Number(this.days), 0, 0, 0)
    }

    yearsHasError(): boolean {
        const numericValue = this.years.replace(/\D/g, ''); // Remove non-digit characters
        const parsedValue = parseInt(numericValue, 10);
        return isNaN(parsedValue) || parsedValue < 2000 || parsedValue > 2099
    }
    

    monthsHasError(): boolean {
        const numericValue = this.months.replace(/\D/g, ''); // Remove non-digit characters
        const parsedValue = parseInt(numericValue, 10);
        return isNaN(parsedValue) || parsedValue < 1 || parsedValue > 12
    }


    daysHasError(): boolean {
        const numericValue = this.days.replace(/\D/g, ''); // Remove non-digit characters
        const parsedValue = parseInt(numericValue, 10);
        return isNaN(parsedValue) || parsedValue < 1 || parsedValue > 31
    }

    dateHasError(): boolean {
        const maxDay = new Date(parseInt(this.years), parseInt(this.months), 0).getDate();
        const daysError = Number(this.daysHasError()) > maxDay || this.daysHasError()
        return this.yearsHasError() || daysError || this.monthsHasError()
    }
    
    get days(): string {
        return this._days;
    }

    set days(value: string) {
        this._days = value;
    }

    get months(): string {
        return this._months;
    }

    set months(value: string) {
        this._months = value;
    }

    get years(): string {
        return this._years;
    }

    set years(value: string) {
        this._years = value;
    }
}


function addOneDayToDate(date: LowCompletudeDate): LowCompletudeDate {
    // Convert date component to a JavaScript Date object
    const day = parseInt(date.days, 10);
    const month = parseInt(date.months, 10) - 1; // Months are zero-based
    const year = parseInt(date.years, 10);

    const currentDate = new Date(year, month, day);

    // Add one day
    currentDate.setDate(currentDate.getDate() + 1);

    // Extract updated component
    const updatedDay = currentDate.getDate().toString();
    const updatedMonth = (currentDate.getMonth() + 1).toString(); // Months are zero-based, so add 1
    const updatedYear = currentDate.getFullYear().toString();

    // Create a new object with the updated date component
    const updatedDate = new LowCompletudeDate(updatedDay,updatedMonth, updatedYear )
    return updatedDate;
}

function exludeEndDateForBackend(date: LowCompletudeDate): LowCompletudeDate {
    // Convert date component to a JavaScript Date object
    const day = parseInt(date.days, 10);
    const month = parseInt(date.months, 10) - 1; // Months are zero-based
    const year = parseInt(date.years, 10);

    const currentDate = new Date(year, month, day);

    // Add one day
    currentDate.setDate(currentDate.getDate() - 1);

    // Extract updated component
    const updatedDay = currentDate.getDate().toString();
    const updatedMonth = (currentDate.getMonth() + 1).toString(); // Months are zero-based, so add 1
    const updatedYear = currentDate.getFullYear().toString();

    // Create a new object with the updated date component
    const updatedDate = new LowCompletudeDate(updatedDay,updatedMonth, updatedYear )
    return updatedDate;
}

export {addOneDayToDate, exludeEndDateForBackend}
