import {typologyFormActions} from "../../../../../../../corelogic/usecases/typology-form/typologyFormActions";
import {useDispatch, useSelector} from "react-redux";
import {newSimulationActions} from "../../../../../../../corelogic/usecases/new-simulation/newSimulationActions";
import React, {useEffect, useState} from "react";
import {selectIsLoadingNewCurve} from "../../../../../../../corelogic/usecases/loader/loaderSelector";

import {
    selectDisplayCDCUploadBtn,
    selectNoOptionChosen
} from "../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import DescriptionBtn from "./header/DescriptionBtn";
import RecalageForm from "../completude-and-recalage-button/recalage/form/RecalageForm";
import Divider from "../../../../components/assets/Divider";
import RecalageButton from "../completude-and-recalage-button/recalage/button/RecalageButton";
import ShowCDC from "./cdc/ShowCDC";
import FullCompletudeButton from "../completude-and-recalage-button/full-completude/FullCompletudeButton";
import LowCompletenessButton from "../completude-and-recalage-button/low-completeness/LowCompletenessButton";
import LowCompletenessForm from "../completude-and-recalage-button/low-completeness/form/LowCompletenessForm";
import FullCompletudeForm from "../completude-and-recalage-button/full-completude/FullCompletudeForm";
import {LoadCurveStatusBadge} from "../badge/LoadCurveBadge";
import TotalEnergyBadge from "../badge/TotalEnergyBadge";
import MaxPowerBadge from "../badge/MaxPowerBadge";
import {
    selectNewCurve
} from "../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-curve/selectFormCurve";

export default function UploadUnsavedCDCButton() {
    const dispatch = useDispatch()
    const newCurve = useSelector(selectNewCurve)
    const upload = useSelector(selectDisplayCDCUploadBtn)
    const loading = useSelector(selectIsLoadingNewCurve)
    const noOption = useSelector(selectNoOptionChosen)
    const [enableFileInput, setEnableFileInput] = useState(false)
    const hoverStyle = noOption ? " opacity-80 hover:opacity-100 hover:cursor-pointer  " : ""

    useEffect(() => {
        setEnableFileInput(newCurve !== null && noOption || upload || loading)
    }, [newCurve, noOption, upload, loading])

    function uploadWhen(aFileIsLoaded: any) {
        var data = new FormData()
        data.append('measurements_file', aFileIsLoaded)
        dispatch(newSimulationActions.uploadUnsavedLoadCurve(data))
    }

    function handleOnChange(e: any) {
        if(e.target.files){
            uploadWhen(e.target.files[0])
            dispatch(typologyFormActions.setCDCUploadOption('UPLOAD'))
        }
    }

    return <>
        <label
            htmlFor={"fileUpload"}
            onChange={(e: any) => handleOnChange(e)}
            className={"flex flex-col border-dashed h-full w-full px-6 py-4 bg-gray-100 dark:bg-gray-700 bg-opacity-20  rounded border-2 border-blue-500 justify-between items-center inline-flex " + hoverStyle}>
            <input
                data-cy={"input-cdc-from-file"}
                className={"hidden w-full"}
                multiple={false}
                disabled={enableFileInput}
                accept={".xls,.xlsx"}
                type="file" id={"fileUpload"}/>
            <DescriptionBtn/>
            {newCurve && <Divider/>}
            <div className={"w-full flex justify-end mb-2 gap-2"}>
                <LowCompletenessButton/>
                <FullCompletudeButton/>
                <RecalageButton/>
            </div>
            <RecalageForm/>
            <FullCompletudeForm/>
            <LowCompletenessForm/>
            {newCurve &&<div className={"flex w-full flex-wrap gap-2 relative top-14 left-6"}>
                <span
                    className={"text-sm text-gray-500  dark:text-gray-300 tracking-normal font-semibold"}>
                    Courbe de charge ajoutée
                </span>
                <LoadCurveStatusBadge/>
                <TotalEnergyBadge/>
                <MaxPowerBadge/>
            </div>}
            {upload && newCurve && <ShowCDC/>}
        </label>
    </>
}





