import {LabelInput} from "../../../../../../components/assets/FormAssets/TextInput";
import React, {useState} from "react";
import {typologyFormActions} from "../../../../../../../../../corelogic/usecases/typology-form/typologyFormActions";
import {useDispatch, useSelector} from "react-redux";
import {
    selectSGEPRMDisplayer,
    selectSGEPRMIsValid
} from "../../../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";

export default function PRMInput() {

    return <div className={""}>
        <LabelInput label={'PRM'} mandatory={true}/>
        <DigitInput/>
    </div>
}

const DigitInput: React.FC = () => {
    const dispatch  = useDispatch()
    const PRM = useSelector(selectSGEPRMDisplayer)
    const prmIsValid = useSelector(selectSGEPRMIsValid)
    const [mouseHasLeft, setMouseHasLeft] = useState(false)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(e.target.value.length <= 17){
            // Remove all non-digit characters
            const digitsOnly = e.target.value.replace(/\D/g, '');
            dispatch(typologyFormActions.setSGEPRM(digitsOnly));
        }
    }



    return (<div className={"flex flex-col"}>
            <input
                placeholder={"0000 0000 0000 01"}
                className={"px-2 w-full text-gray-700 dark:text-gray-200 focus:outline-none py-1 rounded bg-gray-100 dark:bg-purple-800 dark:bg-opacity-20 dark:focus:border-1 focus:border-purple-600 dark:focus:border-purple-300 focus:border-2 border-gray-200 dark:border-purple-700 border font-bold tracking-widest"}
                type="text"
                pattern="[0-9]*" // Allow only digits
                value={PRM}
                onBlur={() => setMouseHasLeft(true)}
                onChange={handleChange}
            />
            {!prmIsValid && mouseHasLeft &&
            <span className={"text-xs text-red-500  left-1 bottom-[-2]"}>
                Le PRM doit contenir 14 chiffres.
            </span>}
        </div>
    );
};

