import {RootState} from "../../../store/rootReducer";
import {createSelector} from "reselect";


const selectErrors = (state: RootState) => state.errors;

const selectEnolabErrorCode = createSelector(
    selectErrors,
    error => error?.response?.data?.enolab_error_code
);

const selectSnackbarErrors = (state: RootState) => state.snackbarErrors;

const selectSnackbarEnolabErrorCode = createSelector(
    selectSnackbarErrors,
    error => error?.response?.data?.enolab_error_code
);

export {
    selectErrors,
    selectEnolabErrorCode,
    selectSnackbarErrors,
    selectSnackbarEnolabErrorCode,
}


