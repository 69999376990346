import {createSelector} from "reselect";
import {StatusEnum} from "../../../../models/types/new-simulation/curve/Curve";
import {selectIsRoleEnogrid} from "../../../authentication/authenticationSelector";
import {State} from "../../../../../config/app-config";
import {selectNewSimulation, selectTargetYear} from "../form/newSimulationSelector";


const selectLoadedCurves = createSelector(
    selectNewSimulation,
    newSimulation => newSimulation.loadedCurves
)

const selectNewCurve = createSelector(
    selectLoadedCurves,
    loadedCurves => loadedCurves.newCurve
)
const selectFirstNewCurve = createSelector(
    selectLoadedCurves,
    loadedCurves => loadedCurves.firstNewCurve
)

const selectCurves = createSelector(
    selectLoadedCurves,
    loadCurves => loadCurves.curves
)

const makeSelectLoadedCurve = (id: string) => createSelector(
    selectCurves,
    loadedCurves => loadedCurves[id]?.curve
)


const selectNewCurveStatus = createSelector(
    selectNewCurve,
    (unsavedNewCurve) => unsavedNewCurve?.status)

const selectDisplayMaxPowerBadge = createSelector(
    selectNewCurve,
    (unsavedNewCurve) => unsavedNewCurve?.maxPower)

const selectDisplayTotalEnergyBadge = createSelector(
    selectNewCurve,
    selectNewCurveStatus,
    (unsavedNewCurve, status) => unsavedNewCurve?.totalEnergy && status === StatusEnum.VALID)

const selectNewCurveMaxDaysWithContiguousData = createSelector(
    selectNewCurve,
    (unsavedNewCurve) => unsavedNewCurve?.maxNbOfDaysWithContiguousData)
const selectNewCurveStepInMinutes = createSelector(
    selectNewCurve,
    (unsavedNewCurve) => unsavedNewCurve?.stepInMinutes)
const selectNewCurveDataCoversTargetYearWith30MnStep = createSelector(
    selectNewCurve,
    (unsavedNewCurve) => unsavedNewCurve?.dataCoversTargetYearWith30mnSteps)


//  New Curve Badge-Status
const selectCDCStatusBadgeState = createSelector(
    selectNewCurveStatus,
    selectIsRoleEnogrid,
    (status, isRoleEnogrid) => {
        switch (status) {
            case StatusEnum.STANDARD_COMPLETION_COMPLIANT :
                return State.WARNING;
            case StatusEnum.VALID :
                return State.SUCCESS;
            case StatusEnum.ONE_WEEK_COMPLETE : return State.WARNING;
            case StatusEnum.REJECTED:
                if(isRoleEnogrid) return State.WARNING
                return State.ERROR;
            default :
                return State.ERROR;
        }
    })

const selectCDCStatusBadgeText = createSelector(
    selectNewCurveStatus,
    selectNewCurveMaxDaysWithContiguousData,
    selectNewCurveStepInMinutes,
    selectNewCurveDataCoversTargetYearWith30MnStep,
    selectTargetYear,
    selectIsRoleEnogrid,
    (status, maxDaysContiguous, stepLength,dataCoversTargetYear,targetYear, isRoleEnogrid ) => {
        switch (status) {
            case StatusEnum.STANDARD_COMPLETION_COMPLIANT :
                if(maxDaysContiguous && maxDaysContiguous < 365) return "doit être complétée sur 1 an."
                if(stepLength && stepLength != 30) return "doit être complétée au pas de temps 30 minutes."
                if(!dataCoversTargetYear) return `doit être complétée et/ou recalée du 01/01/${targetYear} au 31/12/${targetYear}.`
                return "courbe de charge incomplète"
            case StatusEnum.VALID :
                return 'courbe valide'
            case StatusEnum.ONE_WEEK_COMPLETE : {
                return "doit être complétée sur 1 an."
            }
            case StatusEnum.REJECTED :
                if(!isRoleEnogrid) return 'rejetée : courbe incomplète ou pas de temps apériodique'
                if(maxDaysContiguous && maxDaysContiguous < 365) return "doit être complétée sur 1 an."
                return "courbe de charge incomplète"
            default :
                return 'rejetée : courbe incomplète ou pas de temps apériodique'
        }
    })
const selectCDCStatusBadgeHelper = createSelector(
    selectNewCurveStatus,
    selectNewCurveMaxDaysWithContiguousData,
    selectNewCurveStepInMinutes,
    selectNewCurveDataCoversTargetYearWith30MnStep,
    selectTargetYear,
    selectIsRoleEnogrid,
    (status, maxDaysContiguous, stepLength,dataCoversTargetYear, targetYear, isRoleEnogrid )  => {
        switch (status) {
            case StatusEnum.STANDARD_COMPLETION_COMPLIANT :
                if(maxDaysContiguous && maxDaysContiguous < 365) return "incomplet : doit être complétée sur 1 an."
                if(stepLength && stepLength != 30) return "incomplet : doit être complétée au pas de temps 30 minutes."
                if(!dataCoversTargetYear) return `incomplet : doit être complétée et/ou recalée du 01/01/${targetYear} au 31/12/${targetYear}.`
                return "incomplet : doit être complétée."
            case StatusEnum.VALID :
                return 'La courbe peut être ajoutée au point de soutirage.'
            case StatusEnum.ONE_WEEK_COMPLETE : return "doit être complétée sur 1 an."
            case StatusEnum.REJECTED :
                if(!isRoleEnogrid) return "Rejetée : la courbe de charge doit être raisonnablement complète sur au moins 6 mois."
                if(maxDaysContiguous && maxDaysContiguous < 365) return "incomplet : doit être complétée sur 1 an."
                return "incomplet : doit être complétée."
            default :
                return "Rejetée : la courbe de charge doit être raisonnablement complète sur au moins 6 mois."
        }
    })
const selectEnableFullCompletudeButton = createSelector(
    selectNewCurve,
    selectNewCurveStatus,
    selectIsRoleEnogrid,
    (newCurve, status, isEnogridRole) => {
        const enableCompletudeForNonEnogrid = !isEnogridRole && status !== StatusEnum.REJECTED
        const enableCompletude = isEnogridRole || enableCompletudeForNonEnogrid
        return newCurve && enableCompletude

    })
const selectEnableLowCompletionButton = createSelector(
    selectNewCurve,
    selectNewCurveStatus,
    (newCurve, status) => {
        const atLeast1WeekFetched = status !== StatusEnum.REJECTED
        return newCurve && atLeast1WeekFetched
    })

const selectNewCurveId = createSelector(
    selectNewCurve,
    newCurve => newCurve?.id
)
const selectNewCurveHasLoaded = createSelector(
    selectNewCurve,
    (newCurve) => newCurve !== null
)

const selectNewCurveIsValid = createSelector(
    selectNewCurveHasLoaded,
    selectNewCurveStatus,
    (hasLoaded, status) => hasLoaded && status !== null && status === StatusEnum.VALID
)

export {
    selectCDCStatusBadgeHelper,
    selectNewCurveIsValid,
    selectNewCurveStatus,
    selectIsRoleEnogrid,
    selectCurves,
    selectCDCStatusBadgeText,
    selectNewCurveHasLoaded,
    selectNewCurve,
    selectFirstNewCurve,
    selectEnableFullCompletudeButton,
    selectEnableLowCompletionButton,
    selectNewCurveId,
    selectNewCurveMaxDaysWithContiguousData,
    selectCDCStatusBadgeState,
    selectLoadedCurves,
    selectTargetYear,
    selectDisplayMaxPowerBadge,
    selectNewSimulation,
    selectDisplayTotalEnergyBadge,
    selectNewCurveStepInMinutes,
    selectNewCurveDataCoversTargetYearWith30MnStep,
    makeSelectLoadedCurve,
}