import {Actions, SET_DARKMODE, SET_SIDEBAR_IS_REDUCED} from "./configActions";
export interface Config {
    darkMode : boolean,
    sideBarIsReduced : boolean
}

export const configInitialState: Config = {
    darkMode : window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches,
    sideBarIsReduced : false
}

const configReducer = (state = configInitialState, action: Actions) => {
    switch (action.type) {
        case SET_DARKMODE :
            return {...state, darkMode:action.payload}
        case SET_SIDEBAR_IS_REDUCED :
            return {...state,  sideBarIsReduced : action.payload}
        default:
            return state;
    }
}

export default configReducer;
