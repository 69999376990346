import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {typologyFormActions} from "../../../../../../../../../../../corelogic/usecases/typology-form/typologyFormActions";
import {selectSGEDateEndYears} from "../../../../../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";

type DateInputType = {
    handleInput(e: React.ChangeEvent<HTMLInputElement>, currentIndex: number): void,
    handleFocus(e: React.FocusEvent<HTMLInputElement>): void,
    handleOnBlur(): void,
    inputRefs: React.MutableRefObject<(HTMLInputElement | null)[]>,
    setFocused(value: boolean): void
}
const YearsInput = ({handleInput, handleFocus, handleOnBlur, inputRefs, setFocused}: DateInputType) => {
    const dispatch = useDispatch()
    const years = useSelector(selectSGEDateEndYears)

    const updateNumber = (years: string) => {
        const numericValue = years.replace(/\D/g, ''); // Remove non-digit characters
        dispatch(typologyFormActions.setSGEEndDateYears(numericValue));
    };
    return <input
        placeholder={"22"}
        className={"bg-gray-100 dark:bg-purple-800 dark:bg-opacity-20 focus:outline-none max-w-[20px]"}
        type="text"
        onInput={(e: any) => handleInput(e, 2)}
        onFocus={handleFocus}
        ref={(el) => (inputRefs.current[2] = el)}
        maxLength={2}
        value={years}
        onClick={() => setFocused(true)}
        onBlur={handleOnBlur}
        onChange={(e) => updateNumber(e.target.value)}
    />
}

export default YearsInput
