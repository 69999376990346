import {RootState} from "../../../store/rootReducer";
import {createSelector} from "reselect";
import {selectAddresses, selectTargetYear,} from "../new-simulation/new-simulation-selector/form/newSimulationSelector";
import {isC5, isSegmentDoublePrice} from "../../models/types/new-simulation/form/SegmentDetailed";
import {ConsumptionPoint} from "../../models/types/new-simulation/form/ConsumptionPoint";
import {ComplementProviderKwhPrice} from "../../models/types/new-simulation/form/ComplementProviderKwhPrice";
import {PeakOffPeakHoursDefinition} from "../../models/types/new-simulation/form/PeakOffPeakHoursDefinition";
import {Pricing} from "../../models/types/new-simulation/form/Pricing";
import FlatPeakOffPeakPricing from "../../models/types/new-simulation/form/FlatPeakOffPeakPricing";
import SeasonalPeakOffPeakPricing from "../../models/types/new-simulation/form/SeasonalPeakOffPeakPricing";
import {selectIsLoadingNewCurve} from "../loader/loaderSelector";
import {v4 as uuidv4} from "uuid";
import {ContractType} from "../../models/types/new-simulation/form/ContractType";
import {selectIsRoleEnogrid} from "../authentication/authenticationSelector";
import {ConsumerType} from "../../models/types/new-simulation/form/ConsumerType";
import {SGEFetchedCDCStatus} from "./typologyFormReducer";
import LowCompletudeInput, {
    validateCoherencyBetweenRows,
    validateEveryRowsAreValid,
    validateMaximalDurationBetweenRows,
    validateNoOverlapBetweenRows
} from "../../../adapters/primary/ui/composition/form/load-curve-upload/completude-and-recalage-button/low-completeness/form/table/LowCompletudeInput";
import {
    selectFirstNewCurve,
    selectNewCurve,
    selectNewCurveIsValid
} from "../new-simulation/new-simulation-selector/form-curve/selectFormCurve";
import {selectConsumptionPoints} from "../new-simulation/new-simulation-selector/form-data/selectFinancialParameter";
import {selectPostcode} from "../new-simulation/new-simulation-selector/form-data/selectGeneralInfoForm";
import {
    selectActiveInjectionPointsWithAddressFilled
} from "../new-simulation/new-simulation-selector/form-data/selectInjectionPointForm";

const selectTypologyForm = (state: RootState): any => state.typologyForm;

export const selectCDCForm = createSelector(
    selectTypologyForm,
    restitution => restitution.cdcForm,
)

export const selectCDCLoadOption = createSelector(
    selectCDCForm,
    cdcForm => cdcForm.uploadOption,
)

export const selectNoOptionChosen = createSelector(
    selectCDCLoadOption,
    option => option === null,
)
export const selectDisplayCDCUploadBtn = createSelector(
    selectCDCLoadOption,
    (option) => option === 'UPLOAD',
)
export const selectDisplayCDCSGEBtn = createSelector(
    selectIsRoleEnogrid,
    selectCDCLoadOption,
    (roleEnogrid, option) => roleEnogrid && option === 'SGE',
)

export const selectDisplayCDCEnedisBtn = createSelector(
    selectCDCLoadOption,
    (option) => option === 'ENEDIS',
)

export const selectUndefinitiveNewCurve = createSelector(
    selectNewCurve,
    selectDisplayCDCEnedisBtn,
    (newCurve, isUploadedByEnedis) => newCurve && (!newCurve.isDefinitive() || isUploadedByEnedis)
)

export const selectDisplayAllCDCButtons = createSelector(
    selectNoOptionChosen,
    selectDisplayCDCUploadBtn,
    (noOption, upload) => noOption || upload
)

export const selectConsumptionForm = createSelector(
    selectTypologyForm,
    restitution => restitution.consumptionForm,
)
export const selectConsumptionPointACCNumber = createSelector(
    selectConsumptionForm,
    consumptionForm => consumptionForm.accNumber,
)
export const selectConsumptionPointACCNumberIsValid = createSelector(
    selectConsumptionPointACCNumber,
    (accNumber) => !isNaN(Number(accNumber)) && accNumber > 0 && accNumber.length > 0,
)
export const selectConsumptionPointConsumerType = createSelector(
    selectConsumptionForm,
    consumptionForm => consumptionForm.consumerType,
)
export const selectConsumptionPointIsVATRecoverable = createSelector(
    selectConsumptionForm,
    consumptionForm => consumptionForm.isVATRecoverable,
)
export const selectHeatingType = createSelector(
    selectConsumptionForm,
    consumptionForm => consumptionForm.heatingType,
)
export const selectAccommodationType = createSelector(
    selectConsumptionForm,
    consumptionForm => consumptionForm.accommodationType,
)
export const selectConsumptionPointName = createSelector(
    selectConsumptionForm,
    consumptionForm => consumptionForm.name,
)
export const selectHPHC = createSelector(
    selectConsumptionForm,
    (consumptionForm) => consumptionForm.HPHC)


export const selectHPHCIsEnabled = createSelector(
    selectHPHC,
    HPHC => HPHC.enable,
)
export const selectGeneratedKwhPrice = createSelector(
    selectHPHC,
    HPHC => HPHC.generatedKwhPrice,
)
export const selectGeneratedKwhPriceIsValid = createSelector(
    selectGeneratedKwhPrice,
    price => !isNaN(Number(price)) && price.toString().length > 0
)


export const selectHeurePleine = createSelector(
    selectHPHC,
    HPHC => HPHC.heurePleine,
)
export const selectHeureCreuse = createSelector(
    selectHPHC,
    HPHC => HPHC.heureCreuse,
)
function isValidTimeFormat(input: string): boolean {
    // Regular expression to match 'hh:mm:ss' format
    const timeFormatRegex = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;
    return timeFormatRegex.test(input);
}
export const selectHeurePleineIsValid = createSelector(
    selectHeurePleine,
    heurePleine => isValidTimeFormat(heurePleine) || false
)
export const selectHeureCreuseIsValid = createSelector(
    selectHeureCreuse,
    heureCreuse => isValidTimeFormat(heureCreuse) || false
)

function priceIsValid(prix: string) {
    return !isNaN(Number(prix)) && parseFloat(prix) > 0
}

export const selectHPPrix = createSelector(
    selectHPHC,
    HPHC => HPHC.HPPrix,
)
export const selectHCPrix = createSelector(
    selectHPHC,
    HPHC => HPHC.HCPrix,
)
export const selectHCPrixIsValid = createSelector(
    selectHCPrix,
    prix => priceIsValid(prix)
)
export const selectHPPrixIsValid = createSelector(
    selectHPPrix,
    prix => priceIsValid(prix)
)
export const selectHPEtePrix = createSelector(
    selectHPHC,
    HPHC => HPHC.HPEtePrix,
)
export const selectHPEteIsValid = createSelector(
    selectHPEtePrix,
    prix => priceIsValid(prix)
)
export const selectHCEtePrix = createSelector(
    selectHPHC,
    HPHC => HPHC.HCEtePrix,
)
export const selectHPHiverPrix = createSelector(
    selectHPHC,
    HPHC => HPHC.HPHiverPrix,
)
export const selectHCHiverPrix = createSelector(
    selectHPHC,
    HPHC => HPHC.HCHiverPrix,
)
export const selectHCEtePrixIsValid = createSelector(
    selectHCEtePrix,
    prix => priceIsValid(prix)
)
export const selectHPHiverPrixIsValid = createSelector(
    selectHPHiverPrix,
    prix => priceIsValid(prix)
)
export const selectHCHiverPrixIsValid = createSelector(
    selectHCHiverPrix,
    prix => priceIsValid(prix)
)


export const selectConsumptionPointACIPointId = createSelector(
    selectConsumptionForm,
    consumptionForm => consumptionForm.ACIPointId,
)
export const selectSegment = createSelector(
    selectConsumptionForm,
    consumptionForm => consumptionForm.segment,
)

export const selectComplementProviderKwhPriceToAdd = createSelector(
    selectHPHCIsEnabled,
    selectSegment,
    selectHeureCreuse,
    selectHeurePleine,
    selectHCPrix,
    selectHPPrix,
    selectHCHiverPrix,
    selectHPHiverPrix,
    selectHCEtePrix,
    selectHPEtePrix,
    selectGeneratedKwhPrice,
    (HPHC, segment, heureCreuse, heurePleine, HCPrix, HPPrix, HCHiverPrix, HPHiverPrix, HCEtePrix, HPEtePrix, generatedKwhPrice) => HPHC ?
        (isSegmentDoublePrice(segment) ?
            new ComplementProviderKwhPrice(new PeakOffPeakHoursDefinition(heureCreuse, heurePleine, new Pricing(new FlatPeakOffPeakPricing(parseFloat(HCPrix), parseFloat(HPPrix)))))
            : new ComplementProviderKwhPrice(new PeakOffPeakHoursDefinition(heureCreuse, heurePleine, new Pricing(new SeasonalPeakOffPeakPricing(parseFloat(HCEtePrix), parseFloat(HPEtePrix), parseFloat(HCHiverPrix), parseFloat(HPHiverPrix))))))
        : new ComplementProviderKwhPrice(parseFloat(generatedKwhPrice))
)


const selectTypology = createSelector(
    selectTypologyForm,
    (typology) => typology.typology)

export const selectEditPoint = createSelector(
    selectTypology,
    (typologyForm) => typologyForm.editPoint
)
export const selectClearForm = createSelector(
    selectTypology,
    (typologyForm) => typologyForm.clear
)

export const selectAddress = createSelector(
    selectTypology,
    (typologyForm) => typologyForm.address)

export const selectPointBeingEdited = createSelector(
    selectEditPoint,
    (editPoint) => editPoint.pointEdited)

export const selectPointEditedIndex = createSelector(
    selectEditPoint,
    (editPoint) => editPoint.index)

export const selectConsumptionPointNameIsValid = createSelector(
    selectConsumptionPointName,
    selectPointBeingEdited,
    selectConsumptionPoints,
    (formName, rowToEdit, consumptionPoints) => {
        const nameDoenstAlreadyExist = !consumptionPoints.some((existingPoint: ConsumptionPoint) => existingPoint.name === formName)
        const ownUniqueNameBeingEdited = rowToEdit?._name === formName && !(consumptionPoints.filter((existingPoint: ConsumptionPoint) => existingPoint.name === formName).length === 2)
        return ownUniqueNameBeingEdited || (formName.length > 1 && nameDoenstAlreadyExist)
    }
)

export const selectEnableNewSoutiragePointButton = createSelector(
    selectHPHCIsEnabled,
    selectSegment,
    selectHCPrixIsValid,
    selectHPPrixIsValid,
    selectHCHiverPrixIsValid,
    selectHPHiverPrixIsValid,
    selectHCEtePrixIsValid,
    selectHPEteIsValid,
    selectGeneratedKwhPriceIsValid,
    selectGeneratedKwhPrice,
    selectConsumptionPointNameIsValid,
    selectConsumptionPointName,
    selectConsumptionPointACCNumber,
    selectConsumptionPointACCNumberIsValid,
    selectNewCurveIsValid,
    selectIsLoadingNewCurve,
    selectHeurePleineIsValid,
    selectHeureCreuseIsValid,
    (HPHC, segment, HCPrixIsValid, HPPrixIsValid, HCHiverPrixIsValid, HPHiverPrixIsValid, HCEtePrixIsValid, HPEtePrixIsValid, generatedKwhPriceIsValid, generatedKwhPrice,
     nameIsValid, name, accNumber, accNumberIsValid, loadCurveIsValid, isLoadingCurve, isHPValid, isHCValid) => {
        const isPriceOk = HPHC ? (
            isSegmentDoublePrice(segment) ?
                (HCPrixIsValid && HPPrixIsValid) :
                (HCHiverPrixIsValid && HPHiverPrixIsValid && HCEtePrixIsValid && HPEtePrixIsValid)
        ) : generatedKwhPrice !== "" && generatedKwhPriceIsValid
        return (
            nameIsValid &&
            name !== "" &&
            accNumberIsValid &&
            accNumber !== "" &&
            isPriceOk &&
            loadCurveIsValid &&
            !isLoadingCurve && isHPValid && isHCValid
        )
    }
)

export const selectIsACIEnabled = createSelector(
    selectConsumptionForm,
    (consumptionForm) => consumptionForm.enableACI)


// EnedisCDC Button

export const selectIsEnableEnedisBtn = createSelector(
    selectSegment,
    selectPostcode,
    selectConsumptionPointConsumerType,
    (segment, postcode, consumerType) => postcode.length === 5 && isC5(segment) && consumerType === ConsumerType.individual)

export const selectContractTypeToAdd = createSelector(
    selectHPHCIsEnabled,
    selectSegment,
    (isHPHCEnabled, segment) => !isHPHCEnabled ? ContractType.base : isSegmentDoublePrice(segment) ? ContractType.double_price : ContractType.quadruple_price
)
export const selectAddressGouvFrom = createSelector(
    selectAddress,
    selectAddresses,
    (addressInput, addresses) => addressInput ? addresses.find((addr: any) => addr.label === addressInput) || null : null
)
export const selectSGE = createSelector(
    selectCDCForm,
    (cdcForm) => cdcForm.sge
)
export const selectSGEForm = createSelector(
    selectSGE,
    (sge) => sge.form
)
export const selectSGECDCSavedConfig = createSelector(
    selectSGE,
    (sge) => sge.CDCSavedConfig
)
// SGE : fetched cdc results by weeks
export const selectSGEFailuresByWeeks = createSelector(
    selectSGE,
    (sge) => sge.failuresByWeeks
)

export const selectSGESuccessPercentRate = createSelector(
    selectSGE,
    (sge) => sge.successRate * 100
)

export const selectSGENumberOfSuccess = createSelector(
    selectSGE,
    (sge) => sge.successfullResponseNumber
)

export const selectAtLeastOneSGEWeekFailed = createSelector(
    selectSGEFailuresByWeeks,
    (failure) => failure.length > 0
)


export const selectSGENumberOfFailures = createSelector(
    selectSGEFailuresByWeeks,
    (failures) => failures.length
)


export const selectSGEFailuresAggregatedByErrorCode = createSelector(
    selectSGEFailuresByWeeks,
    (failuresByWeeks) => {
        const groupedData: SGEFetchedCDCStatus[] = []
        failuresByWeeks.forEach((item: SGEFetchedCDCStatus) => {
            const existingGroup = groupedData.find((groupItem) =>
                groupItem.error.code === item.error.code && groupItem.from === item.to
            );
            if (existingGroup) {
                // Si un groupe existe avec le même code, alors la date de fin est mise à jour
                existingGroup.from = item.from
            } else {
                // Sinon, créez un nouveau groupe
                groupedData.push({...item});
            }
        })
        return groupedData
    }
)

export const selectSGENumberOfDistinctFailures = createSelector(
    selectSGEFailuresAggregatedByErrorCode,
    (aggregatedFailures) => aggregatedFailures.length
)

// SGE : DateEndInput
export const selectSGEDateEnd = createSelector(
    selectSGEForm,
    (sge) => sge.dateEnd
)
export const selectSGEDateEndYears = createSelector(
    selectSGEDateEnd,
    (dateEnd) => dateEnd.years
)
export const selectSGEDateEndYearsHasError = createSelector(
    selectSGEDateEndYears,
    (dateEndYears) => {
        const numericValue = dateEndYears.replace(/\D/g, ''); // Remove non-digit characters
        const parsedValue = parseInt(numericValue, 10);
        return isNaN(parsedValue) || parsedValue < 0 || parsedValue > 99
    }
)


export const selectSGEDateEndMonths = createSelector(
    selectSGEDateEnd,
    (dateEnd) => dateEnd.months
)
export const selectSGEDateEndMonthsHasError = createSelector(
    selectSGEDateEndMonths,
    (dateEndMonths) => {
        const numericValue = dateEndMonths.replace(/\D/g, ''); // Remove non-digit characters
        const parsedValue = parseInt(numericValue, 10);
        return isNaN(parsedValue) || parsedValue < 1 || parsedValue > 12
    }
)

export const selectSGEDateEndDays = createSelector(
    selectSGEDateEnd,
    (dateEnd) => dateEnd.days
)
export const selectSGEDateEndDaysHasError = createSelector(
    selectSGEDateEndDays,
    selectSGEDateEndMonths,
    selectSGEDateEndYears,
    (dateEndDays, month, year) => {
        const numericValue = dateEndDays.replace(/\D/g, ''); // Remove non-digit characters
        const parsedValue = parseInt(numericValue, 10);

        let maxDay = 31
        if (year && month) {
            maxDay = new Date(year + 2000, month, 0).getDate();
        }
        return isNaN(parsedValue) || parsedValue < 1 || parsedValue > maxDay
    }
)
export const selectAsDateSGEEndDate = createSelector(
    selectSGEDateEndDays,
    selectSGEDateEndMonths,
    selectSGEDateEndYears,
    (dayNumber, monthNumber, yearNumber) => {
        const years4Digits: number = 2000 + parseInt(yearNumber)
        return new Date(years4Digits, monthNumber - 1, dayNumber, 0, 0, 0)
    }
)

export const selectSGEDateEndIsOlderThanToday = createSelector(
    selectAsDateSGEEndDate,
    (dateEnd) => {
        const today = new Date()
        return dateEnd > today
    }
)

export const selectSGEDateEndDisplayer = createSelector(
    selectSGEDateEndDays,
    selectSGEDateEndMonths,
    selectSGEDateEndYears,
    (days, months, years) => days + ' / ' + months + ' / ' + years
)

// SGE : DateStartInput
export const selectSGEDateStart = createSelector(
    selectSGEForm,
    (sge) => sge.dateStart
)
export const selectSGEDateStartYears = createSelector(
    selectSGEDateStart,
    (dateStart) => dateStart.years
)


export const selectSGEDateStartYearsHasError = createSelector(
    selectSGEDateStartYears,
    (dateStartYears) => {
        const numericValue = dateStartYears.replace(/\D/g, ''); // Remove non-digit characters
        const parsedValue = parseInt(numericValue, 10);
        return isNaN(parsedValue) || parsedValue < 0 || parsedValue > 99
    }
)


export const selectSGEDateStartMonths = createSelector(
    selectSGEDateStart,
    (dateStart) => dateStart.months
)
export const selectSGEDateStartMonthsHasError = createSelector(
    selectSGEDateStartMonths,
    (dateStartMonths) => {
        const numericValue = dateStartMonths.replace(/\D/g, ''); // Remove non-digit characters
        const parsedValue = parseInt(numericValue, 10);
        return isNaN(parsedValue) || parsedValue < 1 || parsedValue > 12
    }
)

export const selectSGEDateStartDays = createSelector(
    selectSGEDateStart,
    (dateStart) => dateStart.days
)
export const selectAsDateSGEStartDate = createSelector(
    selectSGEDateStartDays,
    selectSGEDateStartMonths,
    selectSGEDateStartYears,
    (dayNumber, monthNumber, yearNumber) => {
        const years4Digits: number = 2000 + parseInt(yearNumber)
        return new Date(Number(years4Digits), monthNumber - 1, dayNumber, 0, 0, 0)
    }
)

export const selectDateStartIsOlderThanDateEnd = createSelector(
    selectAsDateSGEStartDate,
    selectAsDateSGEEndDate,
    (dateStart, dateEnd) => dateStart > dateEnd
)
export const selectSGEDateStartDaysHasError = createSelector(
    selectSGEDateStartDays,
    selectSGEDateStartMonths,
    selectSGEDateStartYears,
    (dateStartDays, month, year) => {
        const numericValue = dateStartDays.replace(/\D/g, ''); // Remove non-digit characters
        const parsedValue = parseInt(numericValue, 10);
        let maxDay = 31
        if (year && month) {
            maxDay = new Date(year + 2000, month, 0).getDate();
        }
        return isNaN(parsedValue) || parsedValue < 1 || parsedValue > maxDay

    }
)
export const selectSGEDateStartHasError = createSelector(
    selectSGEDateStartDaysHasError,
    selectSGEDateStartMonthsHasError,
    selectSGEDateStartYearsHasError,
    (dayHasError, monthHasError, yearHasError) => dayHasError || monthHasError || yearHasError
)
export const selectSGEDateStartDisplayer = createSelector(
    selectSGEDateStartDays,
    selectSGEDateStartMonths,
    selectSGEDateStartYears,
    (days, months, years) => days + ' / ' + months + ' / ' + years
)

export const selectSGEPRM = createSelector(
    selectSGEForm,
    (sge) => sge.prm
)
export const selectSGERetries = createSelector(
    selectSGEForm,
    (sge) => sge.retries
)
export const selectSGERetriesHasError = createSelector(
    selectSGERetries,
    (retries) => isNaN(retries) || retries <= 0 || retries > 9
)

export const selectSGEDelay = createSelector(
    selectSGEForm,
    (sge) => sge.delay
)
export const selectSGEFetchIfHoles = createSelector(
    selectSGEForm,
    (sge) => sge.fetchIfHoles
)

export const selectSGEDelayHasError = createSelector(
    selectSGEDelay,
    (delay) => isNaN(delay) || delay <= 0 || delay > 9
)


export const selectSGEPRMDisplayer = createSelector(
    selectSGEPRM,
    (prm) => {
        // Add a space every four digits
        let formattedValue = '';
        for (let i = 0; i < prm.length; i += 4) {
            formattedValue += prm.substr(i, 4);
            if (i + 4 < prm.length) {
                formattedValue += ' ';
            }
        }
        return formattedValue
    }
)
export const selectSGEFormTab = createSelector(
    selectSGE,
    selectNewCurve,
    (form, newCurve) => !!form.isFormSelected || !newCurve
)

export const selectSGEResultTab = createSelector(
    selectSGEFormTab,
    (formSelected) => !formSelected
)
export const selectSGEPRMIsValid = createSelector(
    selectSGEPRM,
    (PRM) => PRM.replace(/\D/g, '').length === 14
)
export const selectSGEDateEndHasError = createSelector(
    selectSGEDateEndDaysHasError,
    selectSGEDateEndMonthsHasError,
    selectSGEDateEndYearsHasError,
    selectSGEDateEndIsOlderThanToday,
    selectDateStartIsOlderThanDateEnd,
    (dayHasError, monthHasError, yearHasError, isOlderThanToday, dateEndNewerThanDateStart) => isOlderThanToday || dayHasError || monthHasError || yearHasError || dateEndNewerThanDateStart
)

export const selectCanFetchSGECurve = createSelector(
    selectSGEPRMIsValid,
    selectSGEDateStartHasError,
    selectSGEDateEndHasError,
    selectSGERetriesHasError,
    selectSGEDelayHasError,
    (PRMIsValid, dateStartHasError, dateEndHasError, retriesError, delayError) => PRMIsValid && !dateStartHasError && !dateEndHasError && !retriesError && !delayError
)

export const selectConsumptionPointToUpdate = createSelector(
    selectPointBeingEdited,
    selectConsumptionPointName,
    selectConsumptionPointConsumerType,
    selectConsumptionPointACCNumber,
    selectConsumptionPointACIPointId,
    selectSegment,
    selectDisplayCDCEnedisBtn,
    selectComplementProviderKwhPriceToAdd,
    selectHeatingType,
    selectAccommodationType,
    selectConsumptionPointIsVATRecoverable,
    selectAddressGouvFrom,
    selectNewCurve,
    selectContractTypeToAdd,
    (rowToEdit, name, consumerType, accNumber, ACIPointId, segment, enedis, complementProviderKwhPrice, heatingType, accommodationType, isVATRecoverable, addressGouvSelected, newCurve, contractType) => new ConsumptionPoint(
        rowToEdit?.id,
        name.trim().replace("  ", " "),
        consumerType,
        parseInt(accNumber),
        ACIPointId,
        newCurve?.id || '',
        segment,
        contractType,
        complementProviderKwhPrice,
        0,
        enedis ? heatingType : null,
        enedis ? accommodationType : null,
        rowToEdit?.state,
        isVATRecoverable,
        addressGouvSelected)
)
export const selectConsumptionPointToAdd = createSelector(
    selectConsumptionPointName,
    selectConsumptionPointConsumerType,
    selectConsumptionPointACCNumber,
    selectConsumptionPointACIPointId,
    selectSegment,
    selectDisplayCDCEnedisBtn,
    selectComplementProviderKwhPriceToAdd,
    selectHeatingType,
    selectAccommodationType,
    selectConsumptionPointIsVATRecoverable,
    selectAddressGouvFrom,
    selectNewCurve,
    selectContractTypeToAdd,
    (name, consumerType, accNumber, ACIPointId, segment, enedis, complementProviderKwhPrice, heatingType, accommodationType, isVATRecoverable, addressGouvSelected, newCurve, contractType) => new ConsumptionPoint(uuidv4(),
        name.trim().replace("  ", " "),
        consumerType,
        parseInt(accNumber),
        ACIPointId,
        newCurve?.id || '',
        segment,
        contractType,
        complementProviderKwhPrice,
        0,
        enedis ? heatingType : null,
        enedis ? accommodationType : null,
        true,
        isVATRecoverable,
        addressGouvSelected)
)

// Low completeness

const selectLowCompleteness = createSelector(
    selectCDCForm,
    (cdcForm) => cdcForm.lowCompletenessButton)
export const selectShowLowCompletenessForm = createSelector(
    selectLowCompleteness,
    selectNoOptionChosen,
    selectNewCurve,
    (lowCompleteness, noOptionChosen, newCurve) => lowCompleteness.show && !noOptionChosen && newCurve)
export const selectLowCompletenessTable = createSelector(
    selectLowCompleteness,
    (lowCompleteness) => lowCompleteness.tableInput)
export const selectLowCompletenessTableFirstDateStartHasError = createSelector(
    selectLowCompletenessTable,
    (table) => table[0].dateStart.daysHasError()
)
export const selectLowCompletenessTableFirstDateStartDays = createSelector(
    selectLowCompletenessTable,
    (table) => table[0].dateStart.days
)
export const makeSelectFromIndexLowCompletenessStartDate = (index: number) => createSelector(
    selectLowCompletenessTable,
    (table:LowCompletudeInput[]) => table[index].dateStart
)
export const makeSelectFromIndexLowCompletenessStartDateDays = (index: number) => createSelector(
    selectLowCompletenessTable,
    (table:LowCompletudeInput[]) => table[index].dateStart.days
)
export const makeSelectFromIndexLowCompletenessStartDateMonths = (index: number) => createSelector(
    selectLowCompletenessTable,
    (table:LowCompletudeInput[]) => table[index].dateStart.months
)
export const makeSelectFromIndexLowCompletenessStartDateYears = (index: number) => createSelector(
    selectLowCompletenessTable,
    (table:LowCompletudeInput[]) => table[index].dateStart.years
)

export const makeSelectFromIndexLowCompletenessEndDateDays = (index: number) => createSelector(
    selectLowCompletenessTable,
    (table:LowCompletudeInput[]) => table[index].dateEnd.days
)
export const makeSelectFromIndexLowCompletenessEndDateMonths = (index: number) => createSelector(
    selectLowCompletenessTable,
    (table:LowCompletudeInput[]) => table[index].dateEnd.months
)
export const makeSelectFromIndexLowCompletenessEndDateYears = (index: number) => createSelector(
    selectLowCompletenessTable,
    (table:LowCompletudeInput[]) => table[index].dateEnd.years
)
export const makeSelectFromIndexLowCompletenessValue = (index: number) => createSelector(
    selectLowCompletenessTable,
    (table:LowCompletudeInput[]) => table[index].value
)
export const makeLowCompletudeDateEndYearsHasError = (index:number) => createSelector(
    selectLowCompletenessTable,
    (table:LowCompletudeInput[]) => table[index].dateEnd.yearsHasError()
)
export const makeLowCompletudeDateStartYearsHasError = (index:number) => createSelector(
    selectLowCompletenessTable,
    (table:LowCompletudeInput[]) => table[index].dateStart.yearsHasError()
)
export const makeLowCompletudeDateEndMonthsHasError = (index:number) => createSelector(
    selectLowCompletenessTable,
    (table:LowCompletudeInput[]) => table[index].dateEnd.monthsHasError()
)
export const makeLowCompletudeDateStartMonthsHasError = (index:number) => createSelector(
    selectLowCompletenessTable,
    (table:LowCompletudeInput[]) => table[index].dateStart.monthsHasError()
)
export const makeLowCompletudeDateEndDaysHasError = (index:number) => createSelector(
    selectLowCompletenessTable,
    (table:LowCompletudeInput[]) => table[index].dateEnd.daysHasError()
)
export const makeLowCompletudeDateStartDaysHasError = (index:number) => createSelector(
    selectLowCompletenessTable,
    (table:LowCompletudeInput[]) => table[index].dateStart.daysHasError()
)

export const makeLowCompletudeStartDateHasError = (index:number) => createSelector(
    selectLowCompletenessTable,
    (table:LowCompletudeInput[]) => table[index].dateStart.dateHasError()
)


export const makeLowCompletudeEndDateHasError = (index:number) => createSelector(
    selectLowCompletenessTable,
    (table) => table[index].dateEndHasError()
)
export const makeIsLowCompletudeRowValid = (index:number) => createSelector(
    selectLowCompletenessTable,
    (table) => table[index].validateRow()
)


export const selectIsLowCompletudeTableValid = createSelector(
    selectLowCompletenessTable,
    (table) => validateEveryRowsAreValid(table)
)
export const selectIsLowCompletudeTableCoherent = createSelector(
    selectLowCompletenessTable,
    (table) => validateCoherencyBetweenRows(table)
)

export const selectIsLowCompletudeTableNotOverlaping = createSelector(
    selectLowCompletenessTable,
    selectTargetYear,
    (table, targetYear) => validateNoOverlapBetweenRows(table, targetYear)
)
export const selectIsLowCompletudeDurationNotOverAYear = createSelector(
    selectLowCompletenessTable,
    (table) => validateMaximalDurationBetweenRows(table)
)
// Recalage CDC
const selectRecalageButton = createSelector(
    selectCDCForm,
    (cdcForm) => cdcForm.recalageButton)

export const selectDisplayRecalageForm = createSelector(
    selectRecalageButton,
    selectNewCurveIsValid,
    (recalageButton, newCurveIsValid) => recalageButton.form && newCurveIsValid)


// Full-Completude CDC

export const selectFullCompletudeButton = createSelector(
    selectCDCForm,
    selectFirstNewCurve,
    (cdcForm, newCurve) => cdcForm.fullCompletudeButton && newCurve)

function isNumberOrFloatGreaterThanZero(input: string | null): boolean {
    if (!input) return true
    const numberRegex = /^(\d+|\d*\.\d+)$/;
    return numberRegex.test(input) && parseFloat(input) > 0;
}

export const makeIsRecalageEnergyValid = (recalageNumber: string | null) => () => isNumberOrFloatGreaterThanZero(recalageNumber)
export const makeIsRecalageEnergyButtonValid = (recalageNumber: string | null) => createSelector(
    makeIsRecalageEnergyValid(recalageNumber),
    (selectIsInputValid) => selectIsInputValid && recalageNumber && recalageNumber !== ""
)
export const selectRecalageEnergy = createSelector(
    selectRecalageButton,
    (recalageButton) => recalageButton.input)

const selectCartographie = createSelector(
    selectTypology,
    (typology) => typology.cartographie)

export const selectCartoCircleInKm = createSelector(
    selectCartographie,
    (cartographie) => cartographie.circleInKm)
export const selectShowCartoDensity = createSelector(
    selectCartographie,
    (cartographie) => cartographie.showDensityMap)

export const selectOptimizedCircle = createSelector(
    selectCartographie,
    (cartographie) => cartographie.optimizedCircle)


export const selectInitialCircleCoordinates = createSelector(
    selectActiveInjectionPointsWithAddressFilled,
    (pointsWithAddress) => {
        if(pointsWithAddress[0] && pointsWithAddress[0].address){
            return {
                lng : pointsWithAddress[0].address.longitude,
                lat : pointsWithAddress[0].address.latitude
            }
        } else return {lat:0, lng:0}
    })
