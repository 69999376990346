import React from "react";
import {useSelector} from "react-redux";
import {selectSGEDateStartHasError} from "../../../../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";

export default function ErrorInput({mouseHasLeft}: { mouseHasLeft: boolean }) {
    const hasError = useSelector(selectSGEDateStartHasError)

    return <>
        {
            hasError && mouseHasLeft &&
            <span className={"text-xs text-red-500  left-1 bottom-[-2]"}>
                La date n'est pas valide.
            </span>
        }
    </>

}
