import SeasonalPeakOffPeakPricing from "./SeasonalPeakOffPeakPricing";
import FlatPeakOffPeakPricing from "./FlatPeakOffPeakPricing";

export class Pricing {
    private _peakPricing : SeasonalPeakOffPeakPricing | FlatPeakOffPeakPricing


    constructor(peakPricing: SeasonalPeakOffPeakPricing | FlatPeakOffPeakPricing) {
        this._peakPricing = peakPricing;
    }
    serialize(){
        return this._peakPricing.serialize()
    }
    get peakPricing(): SeasonalPeakOffPeakPricing | FlatPeakOffPeakPricing {
        return this._peakPricing;
    }

    set peakPricing(value: SeasonalPeakOffPeakPricing | FlatPeakOffPeakPricing) {
        this._peakPricing = value;
    }
}