import {seasonalityUtils} from "./seasonality.utils";
import {
    operationParametersFormActions
} from "../operationParametersFormActions";
import {useDispatch, useSelector} from "react-redux";
import {
    selectSeasonalityForm
} from "../operationParametersFormSelector";
import {Seasonality} from "../../../models/types/new-simulation/form/operation-parameters/seasonality/Seasonality";

export type UseSeasonality = {
    toggleCell: (index: number, monthIndex: number) => void,
}
export default function useSeasonalityToggle(): UseSeasonality {
    const seasonality = useSelector(selectSeasonalityForm)
    const dispatch = useDispatch()

    function toggleCell(index: number, monthIndex: number) {
        const updatedSeasonality: Seasonality = seasonalityUtils.toggle(seasonality, index, monthIndex)
        dispatch(operationParametersFormActions.setSeasonality({...updatedSeasonality}))
    }

    return {toggleCell}
}
