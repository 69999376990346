import {
    loaderActionsType,
    SET_ARE_ALL_SIMULATIONS_LOADING,
    SET_ARELOADING_SIMULATIONS_FORM, SET_ENOPTI_IS_LOADING,
    SET_IS_LOADING_NEW_CURVE,
    SET_ISLOADING_ADDRESS_API,
    SET_ISLOADING_SIMULATION_FORM
} from "./loaderActions";


const initialState: any = {
    curveFromIdIsLoading : true,
    newCurveIsLoading : false,
    simulationFormIsLoading : true,
    simulationsFormAreLoading:false,
    addressAPIIsLoading : false,
    allSimulationsAreLoading:false,
    isEnoptiLoading : false
}

const currentStepIndexReducer = (state = initialState, action: loaderActionsType) => {
    switch (action.type) {
        case SET_ENOPTI_IS_LOADING :
            return {...state, isEnoptiLoading : action.payload}
        case SET_IS_LOADING_NEW_CURVE:
            return {...state, newCurveIsLoading : action.payload}
        case SET_ISLOADING_SIMULATION_FORM:
            return {...state, simulationFormIsLoading : action.payload}
        case SET_ARELOADING_SIMULATIONS_FORM:
            return {...state, simulationsFormAreLoading : action.payload}
        case SET_ISLOADING_ADDRESS_API:
            return {...state, addressAPIIsLoading : action.payload}
            case SET_ARE_ALL_SIMULATIONS_LOADING : 
            return {...state, allSimulationsAreLoading : action.payload}


        default:
            return state;
    }
}

export default currentStepIndexReducer;



