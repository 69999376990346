import React from "react";
import {EnolabColors} from "../../../../../../config/app-config";
import {useSelector} from "react-redux";
import {selectIsDarkMode} from "../../../../../../corelogic/usecases/config/configSelector";

export default function Badge({enolabColor, text, Icon, helper}:{enolabColor : EnolabColors, text : string, Icon :() => JSX.Element, helper?:string}){
    const dark = useSelector(selectIsDarkMode)
    return<span
        title={helper}
        style={{
            backgroundColor : dark ? enolabColor["900"] : enolabColor["100"],
            color : dark ?  enolabColor["300"]:enolabColor["800"],
        }}
        className={" text-xs font-semibold inline-flex items-center gap-1 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 cursor-help "}>
        <Icon/>
        {text}
</span>

}
