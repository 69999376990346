import {InjectionPoint} from "../../../../../../../corelogic/models/types/new-simulation/form/InjectionPoint";
import EmptyTable from "../../../table/EmptyTable";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {formActions} from "../../../../../../../corelogic/usecases/form/formActions";
import CapexTableBody from "./body/CapexTableBody";
import CapexTableHead from "./CapexTableHead";
import CapexWrapper from "./CapexWrapper";
import {selectFormCapexes} from "../../../../../../../corelogic/usecases/form/formSelector";
import {FormCapex} from "./FormCapex";
import {copyCapexesStoreToForm} from "./FormCapex.utils";
import {
    selectFinancialParameters
} from "../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectFinancialParameter";
import {
    selectInjectionPoints
} from "../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectInjectionPointForm";


export default function CapexTable({disabled}:{disabled?:true}) {
    const dispatch = useDispatch()

    const capexes:FormCapex[] = useSelector(selectFormCapexes)
    const storeFinancialParameters = useSelector(selectFinancialParameters)
    const injectionPoints: InjectionPoint[] = useSelector(selectInjectionPoints)


    useEffect(() => {
        // copie du store dans formulaire
        if (storeFinancialParameters) {
            dispatch(formActions.setCapexes(copyCapexesStoreToForm(storeFinancialParameters.globalCapex, injectionPoints)))
        }
    }, [storeFinancialParameters])

    return <div className=" right-6 2xl:right-0 2xl:scale-100 w-full">
        <div className="bg-white rounded-md shadow box-border dark:bg-zinc-600">
            <CapexWrapper disabled={disabled}/>
            <hr className={"w-full border dark:border-zinc-800"}/>
            <div className={"overflow-x-auto "}>
                {
                    capexes.length > 0 ?
                        <table className="bg-white shadow-md dark:bg-zinc-800 w-full  table-auto rounded-lg ">
                            <CapexTableHead/>
                            <CapexTableBody disabled={disabled}/>
                        </table>
                        :
                        <EmptyTable
                            title={"Pas de capex"}
                            description={"Ajouter des capex avec le bouton 'Ajouter un capex' au dessus du tableau"}/>
                }
            </div>
        </div>
        <hr className={"w-full  border-0 py-1 rounded-b bg-white dark:bg-zinc-800"}/>
    </div>
}
