import {createSelector} from "reselect";
import {selectNewSimulation} from "../form/newSimulationSelector";
import {selectStoredExternalInvestment} from "./selectExternalInvestment";
import {
    externalInvestsHasNewValidChanges
} from "../../../../../adapters/primary/ui/composition/balance-sheet/external-investment-form/ExternalInvestment.utils";

const selectExternalInvestmentForm = createSelector(
    selectNewSimulation,
    newSimulation => newSimulation.externalInvestmentForm
);
const selectShowExternalInvestment =  createSelector(
    selectExternalInvestmentForm,
    (externalForm) => externalForm.show
);

const selectExternalInvestments =  createSelector(
    selectExternalInvestmentForm,
    (externalForm) => externalForm.investments
);

const selectExternalInvestmentHasNewValidChanges = createSelector(
    selectExternalInvestments,
    selectStoredExternalInvestment,
    (externalInvestments, storedExternalInvestments) => externalInvestsHasNewValidChanges(externalInvestments, storedExternalInvestments)
);
export {
    selectExternalInvestmentForm,
    selectExternalInvestments,
    selectShowExternalInvestment,
    selectExternalInvestmentHasNewValidChanges,
}