import {GeneralInfo} from "../types/new-simulation/form/GeneralInfo";
import {Typology} from "../types/new-simulation/form/Typology";
import {OperationParameters} from "../types/new-simulation/form/operation-parameters/OperationParameters";
import {FinancialParameters} from "../types/new-simulation/form/FinancialParameters";
import {UserMailAndId} from "../types/new-simulation/UserMailAndId";
import {
    ExternalInvestment,
    globalExternalInvestment
} from "../../../adapters/primary/ui/composition/balance-sheet/external-investment-form/ExternalInvestment";


export class SimulationForm {
      _id: string;
     _general_info ?: GeneralInfo ;
     _typology : Typology ;
     _operation_parameters ?:OperationParameters ;
     _financial_parameters?:FinancialParameters ;
     private _organisation_id : string;
    _creation_timestamp:string;
     _update_timestamp:string;
     _created_by: UserMailAndId;
     _updated_by:UserMailAndId;
     private _target_year:number;
     _external_investment?:ExternalInvestment[];


    constructor(id: string , organisationId : string,  creation_timestamp:string, update_timestamp:string, created_by:UserMailAndId, updated_by:UserMailAndId, general_info?: GeneralInfo, typology?: Typology, operation_parameters?: OperationParameters, financial_parameters?: FinancialParameters, external_investment?:ExternalInvestment[], target_year=2022) {
        this._id = id;
        this._general_info = general_info;
        this._typology = typology || new Typology([], []);
        this._operation_parameters = operation_parameters;
        this._financial_parameters = financial_parameters;
        this._created_by = created_by;
        this._update_timestamp = update_timestamp;
        this._creation_timestamp = creation_timestamp;
        this._updated_by = updated_by;
        this._organisation_id = organisationId;
        this._target_year = target_year;
        this._external_investment = external_investment;
    }

    serialize(): SimulationForm {
        let serializedSimulationForm: any = {};
        if (this.organisation_id) {
            Object.assign(serializedSimulationForm, {organisation_id: this.organisation_id});
        }
        if (this._general_info && this._general_info.name && this._general_info.postcode) {
            const serializedGeneralInfo: any = this._general_info.serialize();
            Object.assign(serializedSimulationForm, {general_info: serializedGeneralInfo});
        }
        if (this._operation_parameters) {
            Object.assign(serializedSimulationForm, {operation_parameters: this._operation_parameters.serialize()});
        }
        if (this._financial_parameters) {
            Object.assign(serializedSimulationForm, {financial_parameters: this._financial_parameters.serialize()});
        }

        if (this._typology) {
            Object.assign(serializedSimulationForm, {
                typology: {
                    injection_points: this._typology._injectionPoints.map((injectionPoint) => injectionPoint.serialize()),
                    consumption_points: this._typology._consumptionPoints.map((consumptionPoint) => consumptionPoint.serialize()),
                },
            });
        }
        if(this._external_investment){
            Object.assign(serializedSimulationForm, {financing: this.serializeExternalInvestment(this._external_investment)});

        }
        return serializedSimulationForm;
    }
    serializeExternalInvestment(externalInvestment :ExternalInvestment[]){
        if(externalInvestment.length === 1 && externalInvestment[0].id === globalExternalInvestment.id) return externalInvestment[0].serialize()
        else return externalInvestment.map((thirdParty)=> thirdParty.serialize())
    }
    get target_year(): number {
        return this._target_year;
    }

    set target_year(value: number) {
        this._target_year = value;
    }

    set organisation_id(value: string) {
        this._organisation_id = value;
    }

    get organisation_id(): string {
        return this._organisation_id;
    }

    get creation_timestamp(): string {
        return this._creation_timestamp;
    }

    set id(value: string) {
        this._id = value;
    }

    set general_info(value: GeneralInfo) {
        this._general_info = value;
    }

    set typology(value: Typology) {
        this._typology = value;
    }

    get typology(): Typology {
        return this._typology;
    }

    set operation_parameters(value: OperationParameters) {
        this._operation_parameters = value;
    }

    set financial_parameters(value: FinancialParameters) {
        this._financial_parameters = value;
    }

    set creation_timestamp(value: string) {
        this._creation_timestamp = value;
    }

    get update_timestamp(): string {
        return this._update_timestamp;
    }

    set update_timestamp(value: string) {
        this._update_timestamp = value;
    }

    get created_by(): UserMailAndId {
        return this._created_by;
    }

    set created_by(value: UserMailAndId) {
        this._created_by = value;
    }

    get updated_by(): UserMailAndId {
        return this._updated_by;
    }

    set updated_by(value: UserMailAndId) {
        this._updated_by = value;
    }

    set external_investment(value: ExternalInvestment[]) {
        this._external_investment = value;
    }
}
