import {useSelector} from "react-redux";
import {
    selectEconomiesLoading,
    selectNoNullACCEconomiesData
} from "../../../../../corelogic/usecases/financialRestitution/financialRestitutionSelector";
import {formatNumber} from "../../../../../corelogic/usecases/config";

import {Saving} from "../../../../../corelogic/models/types/financialRestitution/Saving";
import {ConsumptionPoint} from "../../../../../corelogic/models/types/new-simulation/form/ConsumptionPoint";
import {
    selectComplementProviderInflationRateDisplayer, selectConsumptionPoints,
    selectFinancialDuration
} from "../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectFinancialParameter";


const BillsImpactACCByPointTable = ({displayTVA} :{displayTVA(pointName:string, consumptionPoints:ConsumptionPoint[]):void} ) => {
    const savingsACC = useSelector(selectNoNullACCEconomiesData);
    const duration = useSelector(selectFinancialDuration)
    const loading = useSelector(selectEconomiesLoading);
    const inflation = useSelector(selectComplementProviderInflationRateDisplayer)
    const consumptionPoints = useSelector(selectConsumptionPoints)
    return <table
        id={'bills-acc-by-point'}
        data-cy={'acc-impact-table'}
        className="bg-white w-full  relative overflow-x-auto p-2 table-auto shadow-md rounded-lg dark:bg-zinc-700">
        <thead>
        <tr className="text-gray-500 text-xs leading-normal dark:text-zinc-400 bg-white dark:bg-zinc-700">
            <th align={"left"} className="py-3 px-4 ">Site</th>
            <th align={"center"} colSpan={2}
                className="py-3 px-3 bg-blue-50 dark:bg-indigo-800 dark:text-indigo-300">Reduction sur la facture de
                complément
            </th>
            <th align={"center"} colSpan={2}
                className="py-3 px-3  bg-orange-50  dark:bg-orange-800 dark:text-orange-300">Facture interne
            </th>
            <th align={"center"} colSpan={2} className="py-3 px-3  bg-teal-50  dark:bg-teal-800 dark:text-teal-300">Gain
                net pour les consommateurs
            </th>
        </tr>
        <tr className="text-gray-500  text-xs leading-normal bg-white dark:bg-zinc-700">
            <th align={"left"} className="py-3 px-4 "/>
            <th align={"center"} className="py-3 px-4  dark:bg-indigo-800 dark:text-indigo-500 dark:bg-opacity-30">TOTAL AN 1</th>
            <th align={"center"} className="py-3 px-4 dark:bg-indigo-800 dark:text-indigo-500 dark:bg-opacity-30">{`TOTAL AN ${duration} (${inflation})`}</th>
            <th align={"center"} className="py-3 px-4  dark:bg-orange-800 dark:text-orange-500 dark:bg-opacity-30">TOTAL AN 1</th>
            <th align={"center"} className="py-3 px-4 dark:bg-orange-800 dark:text-orange-500 dark:bg-opacity-30">{`TOTAL AN ${duration} (${inflation})`}</th>
            <th align={"center"} className="py-3 px-4 dark:bg-teal-800 dark:text-teal-500 dark:bg-opacity-30  ">TOTAL AN 1</th>
            <th align={"center"} className="py-3 px-4 dark:bg-teal-800 dark:text-teal-500 dark:bg-opacity-30 ">{`TOTAL AN ${duration} (${inflation})`}</th>
        </tr>
        </thead>
        <tbody className="text-gray-500 text-xs font-light bg-white dark:bg-zinc-700">
        {!loading ?
            savingsACC.map((saving: Saving, index: number) => {
                return <tr key={saving.name}
                           className={`border-b border-gray-200 hover:bg-gray-100 dark:hover:bg-zinc-600`}>
                    <td align={"left"} className="py-2 px-4  whitespace-nowrap dark:text-zinc-200">
                        <span
                            className="font-semibold">{index === savingsACC.length - 1 ? "TOTAL" : saving.name + displayTVA(saving.name, consumptionPoints) || ''}</span>
                    </td>
                    <td align={"center"}
                        className={`py-2 px-4   ${index === savingsACC.length - 1 ? 'bg-blue-50 text-slate-500 dark:bg-indigo-800 dark:text-indigo-300 ' : 'dark:bg-indigo-800 dark:text-indigo-500 dark:bg-opacity-30'}`}>
                        <span
                            className={`font-semibold text-sm `}>{saving.collectiveAcFirstYearSaving != null ? formatNumber(saving?.collectiveAcFirstYearSaving) + ' €' : '-'}</span>
                    </td>
                    <td align={"center"}
                        className={`py-2 px-4 ${index === savingsACC.length - 1 ? 'bg-blue-50 text-slate-500 dark:bg-indigo-800 dark:text-indigo-300' : 'dark:bg-indigo-800 dark:text-indigo-500 dark:bg-opacity-30'}`}>
                        <span
                            className={`font-semibold text-sm `}>{saving.collectiveAcSaving != null ? formatNumber(saving?.collectiveAcSaving) + ' €' : '-'}</span>
                    </td>
                    <td align={"center"}
                        className={`py-2 px-4  ${index === savingsACC.length - 1 ? 'bg-orange-50 text-slate-500 dark:bg-orange-800 dark:text-orange-300' : 'dark:bg-orange-800 dark:text-orange-500 dark:bg-opacity-30'}`}>
                        <span
                            className={`font-semibold text-sm `}>{saving.internalBillFirstYear != null ? formatNumber(saving?.internalBillFirstYear) + ' €' : '-'}</span>
                    </td>
                    <td align={"center"}
                        className={`py-2 px-4  ${index === savingsACC.length - 1 ? ' bg-orange-50 text-slate-500 dark:bg-orange-800 dark:text-orange-300' : 'dark:bg-orange-800 dark:text-orange-500 dark:bg-opacity-30'}`}>
                        <span
                            className={`font-semibold text-sm `}>{saving.internalBillLastYear != null ? formatNumber(saving.internalBillLastYear) + ' €' : '-'}</span>
                    </td>
                    <td align={"right"}
                        className={`py-2 px-4  ${index === savingsACC.length - 1 ? 'bg-teal-50 text-slate-500 dark:bg-teal-800 dark:text-teal-300' : 'dark:bg-teal-800 dark:text-teal-500 dark:bg-opacity-30 '}`}>
                        <span
                            className={` font-semibold text-sm `}>{saving.consumerGainFirstYear != null ? formatNumber(saving.consumerGainFirstYear) + ' €' : '-'}</span>
                    </td>
                    <td align={"right"}
                        className={`py-2 px-4  ${index === savingsACC.length - 1 ? 'bg-teal-50 text-slate-500 dark:bg-teal-800 dark:text-teal-300' : 'dark:bg-teal-800 dark:text-teal-500 dark:bg-opacity-30 '}`}>
                        <span
                            className={` font-semibold text-sm`}>{saving.consumerGainLastYear != null ? formatNumber(saving.consumerGainLastYear) + ' €' : '-'}</span>
                    </td>
                </tr>
            })
            : [...Array(3)].map(() => {
                return <tr className="border-b border-gray-200 animate-pulse">
                    {[...Array(7)].map((_, idx) => {
                        return <td key={idx} className="py-2 px-4 text-left whitespace-nowrap">
                            <div className="flex items-left">
                                <span className="font-bold bg-gray-100 h-4 w-full dark:bg-zinc-600"/>
                            </div>
                        </td>
                    })}
                </tr>
            })
        }
        </tbody>
    </table>
}

export default BillsImpactACCByPointTable
