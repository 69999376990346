import {useDispatch} from "react-redux";
import {push} from "connected-react-router";
import React from "react";
import { QuestionMarkOutlined } from "../../../components/assets/icon/QuestionMarkIcon";

const Help = () => {
    const dispatch = useDispatch()

    function pushToHelpPage() {
        dispatch(push('/help'))
    }

    return <div
        title={"FAQ"}
        onClick={pushToHelpPage}
        className={"p-1 ml-4 hover:bg-slate-200 dark:hover:bg-slate-700 rounded-full  hover:cursor-pointer"}>
        <QuestionMarkOutlined
            className={" w-6 h-6 text-slate-400 dark:text-slate-500 hover:text-slate-700 dark:hover:text-slate-300"}/>
    </div>
}

export default Help
