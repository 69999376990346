import React from "react";
import {InjectionPoint} from "../../../../../../../corelogic/models/types/new-simulation/form/InjectionPoint";

type SelectorInputType = {
    label: string,
    points: InjectionPoint[],
    id: string,
    onChange: (event: any) => void,
    value: any,
    dataCy?:string
}
export default function PointsSelector({label, points, id, onChange, value, dataCy}: SelectorInputType) {
    return <label data-cy={"points-selector"}  className="w-full block uppercase tracking-wide text-gray-600 text-xs font-bold my-1">
        <span className="text-gray-700 mb-2">{label}</span>
        <select data-cy={dataCy} id={id}
                onChange={onChange}
                defaultValue={value}
                className="form-select block w-full mt-2 text-base bg-slate-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
            {points.map((point: InjectionPoint, index) => {
                return <option key={point.id}
                               value={point.id}>
                    {point.name}
                </option>
            })}
        </select>
    </label>
}
