import React, {Component, ComponentType, useEffect} from "react";
import {Route} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {selectIsAuthenticated} from "../../../../../corelogic/usecases/authentication/authenticationSelector";
import {authenticationActions} from "../../../../../corelogic/usecases/authentication/authenticationActions";

type ComponentTypeInterface = {
    Component : ComponentType,
    rest?:any[],
    exact?: boolean,
    path:string
}
export default function  ({Component, ...rest}:ComponentTypeInterface)  {
    const isAuthenticated = useSelector(selectIsAuthenticated)
    const dispatch = useDispatch()
    useEffect(() => {
        if(!isAuthenticated) {
            dispatch(authenticationActions.getSession())
        }
    }, [isAuthenticated])
    return (<Route {...rest} render={() => {return isAuthenticated && <Component/>}} />)
}



