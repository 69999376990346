import {newSimulationActionsType, SET_CLOSING_FORM} from "../../newSimulationActions";

const closeFormInitialState = false
const closeFormReducer = (state = closeFormInitialState, action: newSimulationActionsType) => {
    switch (action.type) {
        case SET_CLOSING_FORM:
            return action.payload
        default:
            return state;
    }
}
export default closeFormReducer