import {FormCapex} from "./FormCapex";
import {InjectionPoint} from "../../../../../../../corelogic/models/types/new-simulation/form/InjectionPoint";
import {Loan} from "../../../../../../../corelogic/models/types/new-simulation/form/Loan";
import {CapexEntries} from "../../../../../../../corelogic/models/types/new-simulation/form/CapexEntries";

export function atLeastOneLoanCapex(capexes : FormCapex[]){
    return capexes.some((capex)=> capex.loan)
}
export function validateAllCapexes(capexes:FormCapex[]){
    return capexes.every((cap)=> cap.validateCapex())
}

export function deleteLineFromArray(array: any[], index: number) {
    return [array.slice(0, index), array.slice(index + 1, array.length + 1)].flat()
}
function allPointsId(injectionPoints : InjectionPoint[]):string[] {
    return ["Capex global", injectionPoints.map((point: InjectionPoint) => point.id)].flat()
}
function usedPointsId(capexes : FormCapex[]):string[] {
    return capexes.map((cap: FormCapex) => cap.id)
}
export function availableCapexesPoints(capexes : FormCapex[], injectionPoints : InjectionPoint[]){
    let restInjectionPointCapex = new Set(allPointsId(injectionPoints))
    usedPointsId(capexes).forEach((usedPointId) => {
        if (restInjectionPointCapex.has(usedPointId)) {
            restInjectionPointCapex.delete(usedPointId)
        }
    })
    return Array.from(restInjectionPointCapex)
}

export function availableCapexesPointsPlusSelf(capexes : FormCapex[], injectionPoints : InjectionPoint[], capex : FormCapex):string[]{
    return  [capex.id, Array.from(availableCapexesPoints(capexes, injectionPoints))].flat()
}
export function copyCapexesStoreToForm(storeGlobalCapex : CapexEntries | null, storeInjectionPoints : InjectionPoint[] ):FormCapex[]{

    let capexRetrieve: FormCapex[] = []
    if(storeGlobalCapex){
        capexRetrieve = [new FormCapex("Capex global", storeGlobalCapex.capex, storeGlobalCapex.loan ? new Loan(storeGlobalCapex.loan.price, storeGlobalCapex.loan.duration, storeGlobalCapex.loan.rate):null)]
    }
    storeInjectionPoints.length > 0 && storeInjectionPoints.map((point: InjectionPoint) => {
        if (point.capexEntries) {
            capexRetrieve.push(new FormCapex(point.id, point.capexEntries.capex,point.capexEntries.loan ? new Loan(point.capexEntries.loan.price, point.capexEntries.loan.duration, point.capexEntries.loan.rate):null))
        }
    })
    return capexRetrieve
}

function getDifferenceBetweenCapexes(array1: FormCapex[], array2: FormCapex[]) {
    return array1.filter((object1: FormCapex) => {
        return !array2.some((object2: FormCapex) => {
            return object1.capex.toString() === object2.capex.toString()
                && object1.id === object2.id
                && object1.loan?.duration === object2.loan?.duration
                && object1.loan?.rate === object2.loan?.rate
                && object1.loan?.price === object2.loan?.price
        });
    });
}


export function capexesHaveChanged(capexes : FormCapex[], storeCapexes : FormCapex[] ){
    const lengthDifference = storeCapexes.length !== capexes.length
    return lengthDifference || getDifferenceBetweenCapexes(storeCapexes, capexes).length > 0 || getDifferenceBetweenCapexes(capexes, storeCapexes).length > 0

}