export enum SellingPriceFunction {
    FIXED_PRICE = 'FIXED_PRICE',
    FIXED_PRICE_WITH_INFLATION = 'FIXED_PRICE_WITH_INFLATION',
    DISCOUNT_COMPLEMENT_PRICE = 'DISCOUNT_COMPLEMENT_PRICE',
    GIFTED = 'GIFTED'
}


export function getKeyByValue(object: any, value: any) {
    return Object.keys(object).find(key => object[key] === value);
}
