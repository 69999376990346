import {combineReducers} from "redux";
import {
    formActionsType,
    SET_DISTRIBUTION_ALGORITHM,
    SET_DISTRIBUTION_PRIORITIES,
    SET_DISTRIBUTION_SHARES,
    SET_DISTRIBUTION_UNGROUPED_PRIORITIES,
    SET_SEASONALITY_SEASONS_ACTIVATED
} from "./operationParametersFormActions";
import {
    DistributionPriority
} from "../../models/types/new-simulation/form/operation-parameters/distribution/DistributionPriority";
import {
    DistributionShare
} from "../../models/types/new-simulation/form/operation-parameters/distribution/DistributionShare";
import {Seasonality} from "../../models/types/new-simulation/form/operation-parameters/seasonality/Seasonality";
import {
    DistributionUngroupedPriorities
} from "../../models/types/new-simulation/form/operation-parameters/distribution/DistributionUngroupedPriorities";


const initialSeasonalityFormReducer: Seasonality | null = null
const seasonalityFormReducer = (state = initialSeasonalityFormReducer, action: formActionsType) => {
    switch (action.type) {
        case SET_SEASONALITY_SEASONS_ACTIVATED:
            return action.payload
        default:
            return state;
    }
}

type DistributionTypeState = {
    selectedAlgorithm: null | string,
    distributionPriorities: DistributionPriority[],
    distributionUngroupedPriorities: DistributionUngroupedPriorities,
    distributionShares: DistributionShare[],

}
const initialDistributionFormReducer: DistributionTypeState = {
    selectedAlgorithm: null,
    distributionPriorities: [],
    distributionUngroupedPriorities: {consumptionColumnTitles : [], rows : []},
    distributionShares: []
}
const distributionFormReducer = (state = initialDistributionFormReducer, action: formActionsType) => {
    switch (action.type) {
        case SET_DISTRIBUTION_UNGROUPED_PRIORITIES :
            return {
                ...state,
                distributionUngroupedPriorities: action.payload
            }
        case SET_DISTRIBUTION_ALGORITHM:
            return {
                ...state,
                selectedAlgorithm: action.payload
            }
        case SET_DISTRIBUTION_PRIORITIES:
            return {
                ...state,
                distributionPriorities: action.payload
            }
        case SET_DISTRIBUTION_SHARES:
            return {
                ...state,
                distributionShares: action.payload
            }

        default:
            return state;
    }
}


export default combineReducers({
    distribution: distributionFormReducer,
    seasonality: seasonalityFormReducer,
});
