import {combineReducers} from "redux";
import {connectRouter} from 'connected-react-router'
import simulationsDisplayReducer from "../corelogic/usecases/simulations-display/simulationsDisplayReducer";
import newSimulationReducer from "../corelogic/usecases/new-simulation/new-simulation-reducer/newSimulationReducer";
import authenticationReducer from "../corelogic/usecases/authentication/authenticationReducer";
import physicalReducer from "../corelogic/usecases/physicalRestitution/physicalRestitutionReducer";
import financialRestitutionReducer from "../corelogic/usecases/financialRestitution/financialRestitutionReducer";
import restitutionReducer from "../corelogic/usecases/restitution/restitutionReducer";
import loaderReducer from "../corelogic/usecases/loader/loaderReducer";
import errorsReducer from "../corelogic/usecases/errors/errorsReducer";
import formReducer from "../corelogic/usecases/form/formReducer";
import snackBarErrorsReducer from "../corelogic/usecases/errors/snackbarErrorsReducer";
import configReducer from "../corelogic/usecases/config/configReducer";
import typologyFormReducer from "../corelogic/usecases/typology-form/typologyFormReducer";
import FAQReducer from "../corelogic/usecases/faq/faqReducer";
import operationParametersFormReducer
    from "../corelogic/usecases/operation-parameters-form/operationParametersFormReducer";
import snackbarReducer from "../corelogic/usecases/snackbar/snackbarReducer";
import { simulationExecutionStatusReducer } from "../corelogic/usecases/simulationExecutionStatus/simulationExecutionStatusReducer";

const rootReducer = (history?: any) => combineReducers({
    simulations : simulationsDisplayReducer,
    newSimulation : newSimulationReducer,
    router: connectRouter(history),
    loader : loaderReducer,
    authentication: authenticationReducer,
    physicalData: physicalReducer,
    financialData: financialRestitutionReducer,
    restitution: restitutionReducer,
    simulationExecutionStatus: simulationExecutionStatusReducer,
    
    typologyForm : typologyFormReducer,
    operationParametersForm : operationParametersFormReducer,
    financialForm : formReducer,

    errors: errorsReducer,
    snackbarErrors: snackBarErrorsReducer,
    snackbars: snackbarReducer,
    FAQ : FAQReducer,
    config : configReducer,
});

export type RootState = ReturnType<ReturnType <typeof rootReducer>>

export default rootReducer;
