export enum ProductionType {
    'photovoltaics'='Photovoltaïque',
    'co-generation'='Cogénération',
    'wind-turbine'='Eolienne',
    'hydroelectric'='Hydroélectrique',
    'geothermal'='Géothermique'
};

export function productionTypeFromObject(productionType: string): ProductionType  {
    switch (productionType) {
        case 'co-generation':
            return ProductionType['co-generation'];
        case 'geothermal':
            return ProductionType['geothermal'];
        case 'hydroelectric':
            return ProductionType['hydroelectric'];
        case 'photovoltaics':
            return ProductionType['photovoltaics'];
        case 'wind-turbine':
            return ProductionType['wind-turbine'];
        default:
            throw new Error('production type null')
    }
}
