import {useDispatch, useSelector} from "react-redux";
import {
    selectFormDuration,
    selectFormDurationIsValid, selectSellingPriceInflationToggled, selectVariableInflationsToggled,
    selectWillDurationChangeAffectVariableInflations
} from "../../../../../../../corelogic/usecases/form/formSelector";
import {formActions} from "../../../../../../../corelogic/usecases/form/formActions";
import TextInput from "../../../../components/assets/FormAssets/TextInput";
import {UnitType} from "../../../../../../../config/app-config";
import React from "react";
import {useSnackbar} from "notistack";
import {selectIsLoadingSimulationForm} from "../../../../../../../corelogic/usecases/loader/loaderSelector";

export default function DurationInput() {
    const dispatch = useDispatch()
    const duration = useSelector(selectFormDuration)
    const durationIsValid = useSelector(selectFormDurationIsValid)
    const sellingPriceTableHasInflationSet = useSelector(selectWillDurationChangeAffectVariableInflations)
    const inflationInputIsVariable = useSelector(selectVariableInflationsToggled)
    const toggleApplyFunctionWithVariableInflation = useSelector(selectSellingPriceInflationToggled)
    const {enqueueSnackbar} = useSnackbar()
    const isFormLoading = useSelector(selectIsLoadingSimulationForm)
    function durationOnChange(e: string) {
        // Basic Validation
        if(durationIsValid && toggleApplyFunctionWithVariableInflation){
            dispatch(formActions.setSellingPriceInflationToggle(false))
        }
        if(durationIsValid && (sellingPriceTableHasInflationSet || inflationInputIsVariable)){
            enqueueSnackbar('Vous avez affecté une inflation variable sur la fourniture de complément ou dans la définition du prix de vente interne. Enlevez cette inflation pour modifier la durée de simulation.', {variant : "warning"})
        } else {
            dispatch(formActions.setDuration(e));
        }
    }


    return <TextInput dataCy={"financial-params-duration"} mandatory={true} label={"DURÉE DE SIMULATION"}
                      placeholder={"(ex : 25)"}
                      unit={UnitType.YEAR} loading={isFormLoading}
                      id={"duration"}
                      onChange={durationOnChange} value={duration} error={{
        state: !durationIsValid,
        msg: "La durée doit être un entier positif (minimum : 2)."
    }}/>
}
