import {Loan} from "../../../../../../../corelogic/models/types/new-simulation/form/Loan";
import {
    isDurationValid,
    isPercentageValid,
    isPriceValid
} from "../../../../../../../corelogic/usecases/new-simulation/ValidatorsSelector";
import {InjectionPoint} from "../../../../../../../corelogic/models/types/new-simulation/form/InjectionPoint";

export class FormCapex {
    private _id: string | "Capex global"
    private _capex: string;
    private _loan: Loan | null;

    constructor(id: string | "Capex global", capex: string, loan: Loan | null) {
        this._id = id;
        this._capex = capex;
        this._loan = loan;
    }


    // UI Displayer && Custom Getters



    displayName(injectionPoints :InjectionPoint[]):string{
        const matchingPoint = injectionPoints.find((point)=> point.id === this.id)
        if(matchingPoint) return matchingPoint.name
        else return this.id
    }


    // Actions
    toggleLoan() {
        this.loan = this.loan ? null : new Loan('', '', '')
    }

    // Validators

    isPointEnabled(injectionPoints: InjectionPoint[]): boolean {
        return this.id === "Capex global" || injectionPoints.some((point) => point.id === this.id && point.state)
    }

    validateCapex(): boolean {
        return this.validateCapexPrice() && this.validateLoan()
    }

    validateCapexPrice() {
        return isPriceValid(this.capex)
    }

    validateLoan() {
        if (!this.loan) return true
        return this.validateLoanPrice() && this.validateLoanRate() && this.validateLoanDuration()
    }

    validateLoanPrice() {
        if (!this.capex || !this.loan) return true
        if (!this.loan?.price) return false
        return Number(this.capex) >= Number(this.loan.price) && isPriceValid(this.capex) && isPriceValid(this.loan.price)
    }

    validateLoanDuration() {
        return this.loan ? isDurationValid(this.loan?.duration) : true
    }

    validateLoanRate() {
        return this.loan ? isPercentageValid(this.loan.rate) : true
    }

    // Getters And Setters
    get id(): string | "Capex global" {
        return this._id;
    }

    set id(value: string | "Capex global") {
        this._id = value;
    }

    get capex(): string {
        return this._capex;
    }

    set capex(value: string) {
        this._capex = value;
    }

    get loan(): Loan | null {
        return this._loan;
    }

    set loan(value: Loan | null) {
        this._loan = value;
    }
}