import {useDispatch, useSelector} from "react-redux";
import {selectFormCapexes} from "../../../../../../../../corelogic/usecases/form/formSelector";
import React, {useCallback} from "react";
import {makePercentNumberIsValid} from "../../../../../../../../corelogic/usecases/new-simulation/ValidatorsSelector";
import {formActions} from "../../../../../../../../corelogic/usecases/form/formActions";
import TextInputForTable from "../../../../../components/assets/FormAssets/TextInputForTable";
import {loanInputType} from "./LoanPriceInput";
import {FormCapex} from "../FormCapex";
import {Loan} from "../../../../../../../../corelogic/models/types/new-simulation/form/Loan";

const LoanRateInput =({loan, index, loading, disabled}:loanInputType) => {
    const capexes = useSelector(selectFormCapexes)
    const dispatch = useDispatch()

    const selectLoanRateIsValid = useCallback(makePercentNumberIsValid(loan.rate), [loan.rate]);
    const rateLoanIsValid= useSelector(selectLoanRateIsValid)
    function rateLoanOnChange(e: string, index: number) {
        let capexChanged:FormCapex[] = [...capexes]
        capexChanged[index].loan = new Loan(capexChanged[index].loan?.price || '',  capexChanged[index].loan?.duration || '', e)
        dispatch(formActions.setCapexes(capexChanged));
    }


    return <td className={""}>
        {loan && <TextInputForTable
            isAFloat disabled={disabled}
            id={"capex-loan-rate" + index}
            index={index}
            onChange={rateLoanOnChange}
            value={loading ? 'chargement...':loan?.rate}
            isValid={rateLoanIsValid}/>}
    </td>
}
export default LoanRateInput
