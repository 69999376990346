import VariableInflationInput from "../../variable-inflation-widget/VariableInflationInput";
import {useDispatch, useSelector} from "react-redux";
import {
    selectHasSellingPriceInflationsChanged,
    selectSellingPriceTemporaryVariableInflations
} from "../../../../../../../../corelogic/usecases/form/formSelector";
import ConfirmationDialog from "../../../../confirmation-dialog/ConfirmationDialog";
import {formActions} from "../../../../../../../../corelogic/usecases/form/formActions";
import React from "react";

export default function PopupVariableInflation( {inflation}: { inflation?: string }) {
    const dispatch = useDispatch()

    const temporaryVariableInflations = useSelector(selectSellingPriceTemporaryVariableInflations)
    const inflationHasChanged = useSelector(selectHasSellingPriceInflationsChanged)

    return <ConfirmationDialog
        actionContinue={() => dispatch(formActions.confirmSellingPriceInflationModal(temporaryVariableInflations))}
        actionCancel={() => dispatch(formActions.cancelSellingPriceInflationModal())}
        title={"Prix de vente interne - Ajouter une inflation variable"}
        content={
            <VariableInflationInput uniqueId={"popup"} fetchLastSavedInflations={formActions.resetLastSavedSellingPriceInflations}
                                         resetInflations ={formActions.initSellingPriceInflations}
                                         setInflationsAction={formActions.setTemporarySellingPriceInflation}
                                         inflations={temporaryVariableInflations}
                                         fixedInflation={inflation}
                                         inflationHasChanged={inflationHasChanged}/>
        }
    />
}
