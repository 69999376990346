import {newSimulationActionsType, SET_NEW_CHANGES} from "../../newSimulationActions";

const formHasNewChangesInitialState = false;
const formHasNewChangesReducer = (state = formHasNewChangesInitialState, action: newSimulationActionsType) => {
    switch (action.type) {
        case SET_NEW_CHANGES:
            return action.payload
        default:
            return state;
    }
}
export default formHasNewChangesReducer