import {call, getContext, put, select, takeLatest} from "redux-saga/effects";
import {loaderActions} from "../../../loader/loaderActions";
import {SimulationForm} from "../../../../models/interfaces/SimulationForm";
import {GeneralInfo} from "../../../../models/types/new-simulation/form/GeneralInfo";
import {NewSimulationGatewayInterface} from "../../newSimulationGateway.interface";
import {
    ADD_CONSUMPTION_POINT,
    ADD_CONSUMPTION_POINT2,
    ADD_INJECTION_POINT,
    APPLY_EXTERNAL_INVESTMENT,
    GET_ADDRESS_FROM_API,
    GET_SIMULATION_FROM_ID, INIT_EXTERNAL_INVESTMENT_FORM,
    newSimulationActions,
    REMOVE_CONSUMPTION_POINT,
    REMOVE_INJECTION_POINT,
    SAVE_CURVE_AND_ADD_CONSUMPTION_POINT,
    SAVE_CURVE_AND_UPDATE_CONSUMPTION_POINT,
    SET_FINANCIAL_PARAMETERS,
    SET_GENERAL_INFO,
    SET_INJECTION_POINTS,
    SET_OPERATION_PARAMETERS,
    SET_POINT_STATE,
    UPDATE_CONSUMPTION_POINT,
    UPDATE_CONSUMPTION_POINT2,
    UPDATE_INJECTION_POINT
} from "../../newSimulationActions";
import {authenticationActions} from "../../../authentication/authenticationActions";
import {snackBarErrorsActions} from "../../../errors/snackbarErrorsActions";
import {
    selectOrganisationIdWithCreditLeft,
    selectSelectedSimulationId,
    selectSimulationForm
} from "../../new-simulation-selector/form/newSimulationSelector";
import {push, RouterLocation} from "connected-react-router";
import {selectLocation} from "../../../routing/routingSelector";
import {getSimulationIdFromUrl} from "../../../config";
import {Typology} from "../../../../models/types/new-simulation/form/Typology";
import {ConsumptionPoint} from "../../../../models/types/new-simulation/form/ConsumptionPoint";
import {selectConsumptionPointToAdd, selectConsumptionPointToUpdate} from "../../../typology-form/typologyFormSelector";
import {typologyFormActions} from "../../../typology-form/typologyFormActions";
import {InjectionPoint} from "../../../../models/types/new-simulation/form/InjectionPoint";
import {Curve} from "../../../../models/types/new-simulation/curve/Curve";
import {CurveLoaded} from "../../../../models/types/new-simulation/curve/CurveLoaded";
import {
    ExternalInvestment
} from "../../../../../adapters/primary/ui/composition/balance-sheet/external-investment-form/ExternalInvestment";
import {AddressGouv} from "../../../../models/types/new-simulation/external-api/AddressGouv";
import {selectNewCurve} from "../../new-simulation-selector/form-curve/selectFormCurve";
import {selectExternalInvestments} from "../../new-simulation-selector/form-data/selectExternalInvestmentForm";
import {
    removeInjectionPointInvestment
} from "../../../../../adapters/primary/ui/composition/balance-sheet/external-investment-form/ExternalInvestment.utils";

function* initExternalInvestmentForm(action:any) {
    try{
        const externalInvestment = action;
        const initialExternalInvestment = externalInvestment.map((invest:ExternalInvestment)=> invest.clone())
        yield put(newSimulationActions.setExternalInvestments(initialExternalInvestment))
    } catch (error: any) {
        yield put(snackBarErrorsActions.handleError(error));
        console.log('error on initExternalInvestment saga ... ', error);
    }
}

function* setGeneralInfo(action: any) {
    try {
        yield put(loaderActions.setIsLoadingSimulationForm(true));
        const {payload} = action;
        const {generalInfos, organisationId} = payload;
        const currentSimulation: SimulationForm = yield call(createOrFindSimulation, organisationId);
        currentSimulation._general_info = new GeneralInfo(generalInfos.name, generalInfos.postcode, generalInfos.typeOfOperation);
        currentSimulation.organisation_id = organisationId;
        const newSimulationGateway: NewSimulationGatewayInterface = yield getContext('newSimulationGatewayContext');
        const newSimulation: SimulationForm = yield call(newSimulationGateway.updateSimulation, currentSimulation);
        if (newSimulation && newSimulation._id && newSimulation._general_info) {
            yield put(newSimulationActions.setSimulationForm(newSimulation));
            yield put(authenticationActions.getSession());
            yield put(newSimulationActions.hasBeenUpdatedNotification(true));
        }
    } catch (error: any) {
        yield put(snackBarErrorsActions.handleError(error));
        console.log('error on setGeneralInfo saga ... ', error);
    }
    yield put(loaderActions.setIsLoadingSimulationForm(false));

}

function* getSimulationFromId() {
    try {
        const selectedSimulationId: string = yield select(selectSelectedSimulationId);
        const location: RouterLocation<any> = yield select(selectLocation);
        const pathname: string = location.pathname;
        const simulationId: string = getSimulationIdFromUrl(pathname);
        if (simulationId && selectedSimulationId !== simulationId) {
            yield put(loaderActions.setIsLoadingSimulationForm(true));
            const newSimulationGateway: NewSimulationGatewayInterface = yield getContext('newSimulationGatewayContext');
            const newSimulation: SimulationForm = yield call(newSimulationGateway.getSimulationFromId, simulationId);
            if(newSimulation && newSimulation._id){
                yield call(initExternalInvestmentForm,newSimulation._external_investment || [])
                yield put(newSimulationActions.setSimulationForm(newSimulation));
            }
        }
    } catch (error: any) {
        console.log('error on getSimulationForm', error);
        yield put(snackBarErrorsActions.handleError(error));
    }
    yield put(loaderActions.setIsLoadingSimulationForm(false));
}

function* createOrFindSimulation(organisationId?: string) {
    try {
        yield put(loaderActions.setIsLoadingSimulationForm(true));
        const selectedSimulationId: string = yield select(selectSelectedSimulationId);
        const defaultOrganisationId: string = yield select(selectOrganisationIdWithCreditLeft);
        if (!selectedSimulationId) {
            const newSimulationGateway: NewSimulationGatewayInterface = yield getContext('newSimulationGatewayContext');
            const newSimulation: SimulationForm = yield call(newSimulationGateway.postSimulation, organisationId ? organisationId : defaultOrganisationId);
            yield put(push(`/simulation/${newSimulation._id}/edit`));
            yield put(authenticationActions.getSession());
            return newSimulation;
        }
        const currentSimulation: SimulationForm = yield select(selectSimulationForm);
        yield put(loaderActions.setIsLoadingSimulationForm(false));
        return currentSimulation;

    } catch (error: any) {
        console.log(error);
        yield put(snackBarErrorsActions.handleError(error));
    }
}

function* setInjectionPoints(action: any) {
    try {
        yield put(loaderActions.setIsLoadingSimulationForm(true));

        const currentSimulation: SimulationForm = yield call(createOrFindSimulation);
        const {payload} = action;
        let newSimulationForm: SimulationForm = currentSimulation;

        if (newSimulationForm._typology) {
            newSimulationForm._typology.injectionPoints = payload;
        } else {
            newSimulationForm._typology = new Typology(payload, []);
        }
        const newSimulationGateway: NewSimulationGatewayInterface = yield getContext('newSimulationGatewayContext');
        const newSimulation: SimulationForm = yield call(newSimulationGateway.updateSimulation, newSimulationForm);
        if (newSimulation && newSimulation._id && newSimulation._typology?.injectionPoints) {
            yield put(newSimulationActions.setSimulationForm(newSimulation));
            yield put(newSimulationActions.hasBeenUpdatedNotification(true));
        }
    } catch (error: any) {
        console.log(error);
        yield put(snackBarErrorsActions.handleError(error));
    }
    yield put(loaderActions.setIsLoadingSimulationForm(false));

}

function* removeConsumptionPoint(action: any) {
    try {
        const currentSimulation: SimulationForm = yield call(createOrFindSimulation);
        const {payload} = action;
        currentSimulation._typology.consumptionPoints = currentSimulation._typology.consumptionPoints.filter((point) => point.id !== payload.id);
        if (currentSimulation._financial_parameters?.selling_price) {
            delete currentSimulation._financial_parameters?.selling_price[payload.id];
        }
        const newSimulationGateway: NewSimulationGatewayInterface = yield getContext('newSimulationGatewayContext');
        const newSimulation: SimulationForm = yield call(newSimulationGateway.updateSimulation, currentSimulation);
        yield put(newSimulationActions.setSimulationForm(newSimulation));
        yield put(newSimulationActions.hasBeenUpdatedNotification(true));
    } catch (error: any) {
        console.log(error);
        yield put(snackBarErrorsActions.handleError(error));
    }
}

function* addConsumptionPoint(action: any) {
    try {
        yield put(loaderActions.setIsLoadingSimulationForm(true));
        const currentSimulation: SimulationForm = yield call(createOrFindSimulation);
        const {payload} = action;
        currentSimulation._typology.consumptionPoints = [...currentSimulation._typology.consumptionPoints, payload];
        const newSimulationGateway: NewSimulationGatewayInterface = yield getContext('newSimulationGatewayContext');
        const newSimulation: SimulationForm = yield call(newSimulationGateway.updateSimulation, currentSimulation);
        yield put(newSimulationActions.setSimulationForm(newSimulation));
        yield put(newSimulationActions.hasBeenUpdatedNotification(true));
    } catch (error: any) {
        console.log(error);
        yield put(snackBarErrorsActions.handleError(error));
    }
    yield put(loaderActions.setIsLoadingSimulationForm(false));

}

function* addConsumptionPoint2() {
    try {
        yield put(loaderActions.setIsLoadingSimulationForm(true));

        const currentSimulation: SimulationForm = yield call(createOrFindSimulation);
        const pointToAdd: ConsumptionPoint = yield select(selectConsumptionPointToAdd)
        currentSimulation._typology.consumptionPoints = [...currentSimulation._typology.consumptionPoints, pointToAdd];
        const newSimulationGateway: NewSimulationGatewayInterface = yield getContext('newSimulationGatewayContext');
        const newSimulation: SimulationForm = yield call(newSimulationGateway.updateSimulation, currentSimulation);
        yield put(newSimulationActions.setSimulationForm(newSimulation));
        yield put(typologyFormActions.setClear(true))
        yield put(newSimulationActions.hasBeenUpdatedNotification(true));
    } catch (error: any) {
        console.log(error);
        yield put(snackBarErrorsActions.handleError(error));
    }
    yield put(loaderActions.setIsLoadingSimulationForm(false));

}

function* updateConsumptionPoint2() {
    try {
        yield put(loaderActions.setIsLoadingSimulationForm(true));

        const currentSimulation: SimulationForm = yield call(createOrFindSimulation);
        const pointToUpdate: ConsumptionPoint = yield select(selectConsumptionPointToUpdate)
        const indexOfPointToUpdate = currentSimulation._typology.consumptionPoints.findIndex((point: ConsumptionPoint) => point.id === pointToUpdate.id);
        currentSimulation._typology.consumptionPoints.splice(indexOfPointToUpdate, 1, pointToUpdate);
        const newSimulationGateway: NewSimulationGatewayInterface = yield getContext('newSimulationGatewayContext');
        const newSimulation: SimulationForm = yield call(newSimulationGateway.updateSimulation, currentSimulation);
        yield put(newSimulationActions.setSimulationForm(newSimulation));
        yield put(typologyFormActions.setClear(true))
        yield put(newSimulationActions.hasBeenUpdatedNotification(true));
    } catch (error: any) {
        console.log(error);
        yield put(snackBarErrorsActions.handleError(error));
    }
    yield put(loaderActions.setIsLoadingSimulationForm(false));

}

function* updateConsumptionPoint(action: any) {
    try {
        const currentSimulation: SimulationForm = yield call(createOrFindSimulation);
        const {payload} = action;
        const indexOfPointToUpdate = currentSimulation._typology.consumptionPoints.findIndex((point: ConsumptionPoint) => point.id === payload.id);
        currentSimulation._typology.consumptionPoints.splice(indexOfPointToUpdate, 1, payload);
        const newSimulationGateway: NewSimulationGatewayInterface = yield getContext('newSimulationGatewayContext');
        const newSimulation: SimulationForm = yield call(newSimulationGateway.updateSimulation, currentSimulation);
        yield put(newSimulationActions.setSimulationForm(newSimulation));
        yield put(newSimulationActions.hasBeenUpdatedNotification(true));
    } catch (error: any) {
        console.log(error);
        yield put(snackBarErrorsActions.handleError(error));
    }
}

function* removeInjectionPoint(action: any) {
    try {
        const currentSimulation: SimulationForm = yield call(createOrFindSimulation);
        const {payload} = action;

        currentSimulation._typology.injectionPoints = currentSimulation._typology.injectionPoints.filter((point) => point.id !== payload.id);
        if (currentSimulation._financial_parameters?.selling_price) {
            Object.values(currentSimulation._financial_parameters?.selling_price).forEach((sellingPricesSoutirages: any) => {
                delete sellingPricesSoutirages[payload.id];
            });
        }
        if (currentSimulation._external_investment) {
            currentSimulation._external_investment = removeInjectionPointInvestment(payload.id, currentSimulation._external_investment)
        }
        const newSimulationGateway: NewSimulationGatewayInterface = yield getContext('newSimulationGatewayContext');
        const newSimulation: SimulationForm = yield call(newSimulationGateway.updateSimulation, currentSimulation);
        yield put(newSimulationActions.setSimulationForm(newSimulation));
        yield put(newSimulationActions.hasBeenUpdatedNotification(true));
    } catch (error: any) {
        console.log(error);
        yield put(snackBarErrorsActions.handleError(error));
    }
}

function* addInjectionPoint(action: any) {
    try {
        const currentSimulation: SimulationForm = yield call(createOrFindSimulation);
        const {payload} = action;
        currentSimulation.typology.injectionPoints = [...currentSimulation.typology.injectionPoints, payload];
        const newSimulationGateway: NewSimulationGatewayInterface = yield getContext('newSimulationGatewayContext');
        const newSimulation: SimulationForm = yield call(newSimulationGateway.updateSimulation, currentSimulation);
        yield put(newSimulationActions.setSimulationForm(newSimulation));
        yield put(newSimulationActions.hasBeenUpdatedNotification(true));
    } catch (error: any) {
        console.log(error);
        yield put(snackBarErrorsActions.handleError(error));
    }
}

function* updateInjectionPoint(action: any) {
    try {
        const currentSimulation: SimulationForm = yield call(createOrFindSimulation);
        const {payload} = action;
        const indexOfPointToUpdate = currentSimulation._typology.injectionPoints.findIndex((point: InjectionPoint) => point.id === payload.id);
        currentSimulation._typology.injectionPoints.splice(indexOfPointToUpdate, 1, payload);
        const newSimulationGateway: NewSimulationGatewayInterface = yield getContext('newSimulationGatewayContext');
        const newSimulation: SimulationForm = yield call(newSimulationGateway.updateSimulation, currentSimulation);
        yield put(newSimulationActions.setSimulationForm(newSimulation));
        yield put(newSimulationActions.hasBeenUpdatedNotification(true));
    } catch (error: any) {
        console.log(error);
        yield put(snackBarErrorsActions.handleError(error));
    }
}

function* setPointState(action: any) {
    try {
        const currentSimulation: SimulationForm = yield call(createOrFindSimulation);
        const {payload} = action; //payload.pointId, payload.state
        const newSimulationGateway: NewSimulationGatewayInterface = yield getContext('newSimulationGatewayContext');
        currentSimulation._typology.injectionPoints.forEach((injectionPoint, index) => {
            if (injectionPoint.id === payload.pointId) {
                currentSimulation._typology.injectionPoints[index].state = payload.enable;
            }
        });
        currentSimulation._typology.consumptionPoints.forEach((consumptionPoint, index) => {
            if (consumptionPoint.id === payload.pointId) {
                currentSimulation._typology.consumptionPoints[index].state = payload.enable;
            }
        });
        const newSimulation: SimulationForm = yield call(newSimulationGateway.updateSimulation, currentSimulation);
        if (newSimulation && newSimulation._id) {
            yield put(newSimulationActions.setSimulationForm(newSimulation));
            yield put(newSimulationActions.hasBeenUpdatedNotification(true));
        }
    } catch (error: any) {
        yield put(snackBarErrorsActions.handleError(error));
        console.log('error on setOperationParameters saga ... ', error);
    }
}

function* setOperationParameters(action: any) {
    try {
        yield put(loaderActions.setIsLoadingSimulationForm(true));
        const currentSimulation: SimulationForm = yield call(createOrFindSimulation);
        const {payload} = action;
        currentSimulation._operation_parameters = payload;
        const newSimulationGateway: NewSimulationGatewayInterface = yield getContext('newSimulationGatewayContext');
        const newSimulation: SimulationForm = yield call(newSimulationGateway.updateSimulation, currentSimulation);
        if (newSimulation && newSimulation._id && newSimulation._operation_parameters) {
            yield put(newSimulationActions.setSimulationForm(newSimulation));
            yield put(newSimulationActions.hasBeenUpdatedNotification(true));
        }
    } catch (error: any) {
        yield put(snackBarErrorsActions.handleError(error));
        console.log('error on setOperationParameters saga ... ', error);
    }
    yield put(loaderActions.setIsLoadingSimulationForm(false));

}

function* setFinancialParameters(action: any) {
    try {
        yield put(loaderActions.setIsLoadingSimulationForm(true));

        const currentSimulation: SimulationForm = yield call(createOrFindSimulation);
        const {fin, injectionPoints} = action.payload;
        currentSimulation.financial_parameters = fin;

        const newSimulationGateway: NewSimulationGatewayInterface = yield getContext('newSimulationGatewayContext');
        currentSimulation._typology.injectionPoints = injectionPoints;
        const newSimulation: SimulationForm = yield call(newSimulationGateway.updateSimulation, currentSimulation);
        if (newSimulation && newSimulation._id && newSimulation._financial_parameters) {
            yield put(newSimulationActions.setSimulationForm(newSimulation));
            yield put(newSimulationActions.hasBeenUpdatedNotification(true));
        }
    } catch (error: any) {
        yield put(snackBarErrorsActions.handleError(error));
        console.log('error on setFinancialParameters saga ... ', error);
    }
    yield put(loaderActions.setIsLoadingSimulationForm(false));

}


function* saveCurveAndAddConsumptionPoint() {
    try {
        yield put(loaderActions.setIsLoadingSimulationForm(true));
        const unsavedCurve: Curve = yield select(selectNewCurve);
        const organisationId: string = yield select(selectOrganisationIdWithCreditLeft);
        const simulationId: string = yield select(selectSelectedSimulationId);
        const newSimulationGateway: NewSimulationGatewayInterface = yield getContext('newSimulationGatewayContext');
        const curveLoaded: CurveLoaded = yield call(newSimulationGateway.saveLoadCurve, unsavedCurve.measurement, simulationId, organisationId);
        const curve:Curve = yield call(newSimulationGateway.loadCurveFromId, curveLoaded.curveId);
        yield put(newSimulationActions.setNewCurve(curve));
        yield call(addConsumptionPoint2)
    } catch (error: any) {
        yield put(snackBarErrorsActions.handleError(error));
        console.log('saveCurveAndAddConsumptionPoint saga ... ', error);
    }
    yield put(loaderActions.setIsLoadingSimulationForm(false));

}

function* saveCurveAndUpdateConsumptionPoint() {
    try {
        yield put(loaderActions.setIsLoadingSimulationForm(true));
        const unsavedCurve: Curve = yield select(selectNewCurve);
        const organisationId: string = yield select(selectOrganisationIdWithCreditLeft);
        const simulationId: string = yield select(selectSelectedSimulationId);
        const newSimulationGateway: NewSimulationGatewayInterface = yield getContext('newSimulationGatewayContext');
        const curveLoaded: CurveLoaded = yield call(newSimulationGateway.saveLoadCurve, unsavedCurve.measurement, simulationId, organisationId);
        const curve:Curve = yield call(newSimulationGateway.loadCurveFromId, curveLoaded.curveId);
        yield put(newSimulationActions.setNewCurve(curve));
        yield call(updateConsumptionPoint2)
    } catch (error: any) {
        yield put(snackBarErrorsActions.handleError(error));
        console.log('saveCurveAndUpdateConsumptionPoint saga ... ', error);
    }
    yield put(loaderActions.setIsLoadingSimulationForm(false));

}

function* getAddressFromAPI(action: any) {

    function filterByTypes(addresses: AddressGouv[], types: string[]) {
        return addresses.filter((add: AddressGouv) => types.includes(add.type))
    }

    function ifExistSelectExactMatch(addresses: AddressGouv[], addressInput: string): AddressGouv[] {
        const existsExactMatch = addresses.find((address) => address.label + ', ' + address.context === addressInput)
        return existsExactMatch ? [existsExactMatch] : addresses
    }

    try {
        const {payload} = action;
        yield put(loaderActions.setIsLoadingAddressAPI(true));
        const newSimulationGateway: NewSimulationGatewayInterface = yield getContext('newSimulationGatewayContext');
        let addressesResponse: AddressGouv[]
        if (payload.types.length === 1) {
            // step 1 - only call API for municipality
            addressesResponse = yield call(newSimulationGateway.getAddressFromAPI, payload.address, payload.types[0]);
            addressesResponse = ifExistSelectExactMatch(addressesResponse, payload.address)
        } else {
            //step 2 & 3 - call API for all results then sort
            addressesResponse = yield call(newSimulationGateway.getAddressFromAPI, payload.address);
            addressesResponse = filterByTypes(addressesResponse, payload.types)
        }

        yield put(newSimulationActions.setAddressFromAPI(addressesResponse));
        yield put(loaderActions.setIsLoadingAddressAPI(false));
    } catch (error: any) {
        yield put(snackBarErrorsActions.handleError(error));
        console.log('error on getAddressFromAPI saga ... ', error);
    }
}




function* applyExternalInvestment(): any {
    try {
        yield put(loaderActions.setIsLoadingSimulationForm(true));

        const currentSimulation: SimulationForm = yield select(selectSimulationForm)
        const externalInvestmentForm: ExternalInvestment[] = yield select(selectExternalInvestments)

        currentSimulation.external_investment = externalInvestmentForm
        const newSimulationGateway: NewSimulationGatewayInterface = yield getContext('newSimulationGatewayContext');
        const newSimulation: SimulationForm = yield call(newSimulationGateway.updateSimulation, currentSimulation);
        yield put(newSimulationActions.setSimulationForm(newSimulation));
        yield put(newSimulationActions.hasBeenUpdatedNotification(true));
        if(newSimulation && newSimulation._id){
            yield call(initExternalInvestmentForm, newSimulation._external_investment || [])
            yield put(newSimulationActions.setSimulationForm(newSimulation));
        }
    } catch (error: any) {
        yield put(snackBarErrorsActions.handleError(error));
        console.log('error on pushNewCurveUnFormatted saga ...', error);
    }
    yield put(loaderActions.setIsLoadingSimulationForm(false));
}

function* applyExternalInvestmentSaga() {
    yield takeLatest(APPLY_EXTERNAL_INVESTMENT, updateSimulation(applyExternalInvestment));
}


const updateSimulation = (saga: any) => {
    return function* (action: any) {
        yield put(newSimulationActions.setIsUpdatingForm(true));
        yield call(saga, action);
        yield put(newSimulationActions.setIsUpdatingForm(false));
    };
};

function* setFinancialParametersSaga() {
    yield takeLatest(SET_FINANCIAL_PARAMETERS, updateSimulation(setFinancialParameters));
}

function* setOperationParametersSaga() {
    yield takeLatest(SET_OPERATION_PARAMETERS, updateSimulation(setOperationParameters));
}

function* setInjectionPointsSaga() {
    yield takeLatest(SET_INJECTION_POINTS, updateSimulation(setInjectionPoints));
}

function* setGeneralInfoSaga() {
    yield takeLatest(SET_GENERAL_INFO, updateSimulation(setGeneralInfo));
}

function* removeConsumptionPointSaga() {
    yield takeLatest(REMOVE_CONSUMPTION_POINT, updateSimulation(removeConsumptionPoint));
}

function* removeInjectionPointSaga() {
    yield takeLatest(REMOVE_INJECTION_POINT, updateSimulation(removeInjectionPoint));
}

function* addConsumptionPointSaga() {
    yield takeLatest(ADD_CONSUMPTION_POINT, updateSimulation(addConsumptionPoint));
}

function* addConsumptionPointSaga2() {
    yield takeLatest(ADD_CONSUMPTION_POINT2, updateSimulation(addConsumptionPoint2));
}


function* updateConsumptionPointSaga() {
    yield takeLatest(UPDATE_CONSUMPTION_POINT, updateSimulation(updateConsumptionPoint));
}

function* updateConsumptionPointSaga2() {
    yield takeLatest(UPDATE_CONSUMPTION_POINT2, updateSimulation(updateConsumptionPoint2));
}

function* addInjectionPointSaga() {
    yield takeLatest(ADD_INJECTION_POINT, updateSimulation(addInjectionPoint));
}

function* updateInjectionPointSaga() {
    yield takeLatest(UPDATE_INJECTION_POINT, updateSimulation(updateInjectionPoint));
}

function* getSimulationFromIdSaga() {
    yield takeLatest(GET_SIMULATION_FROM_ID, getSimulationFromId);
}

function* getAddressFromAPISaga() {
    yield takeLatest(GET_ADDRESS_FROM_API, getAddressFromAPI);
}

function* setPointStateSaga() {
    yield takeLatest(SET_POINT_STATE, setPointState);
}

function* saveCurveAndAddConsumptionPointSaga() {
    yield takeLatest(SAVE_CURVE_AND_ADD_CONSUMPTION_POINT, saveCurveAndAddConsumptionPoint)
}

function* saveCurveAndUpdateConsumptionPointSaga() {
    yield takeLatest(SAVE_CURVE_AND_UPDATE_CONSUMPTION_POINT, saveCurveAndUpdateConsumptionPoint)
}

function* initExternalInvestmentFormSaga() {
    yield takeLatest(INIT_EXTERNAL_INVESTMENT_FORM, initExternalInvestmentForm);
}


const newSimulationFormSaga = [
    applyExternalInvestmentSaga,
    setInjectionPointsSaga,
    setGeneralInfoSaga,
    removeConsumptionPointSaga,
    removeInjectionPointSaga,
    addConsumptionPointSaga,
    addConsumptionPointSaga2,
    setOperationParametersSaga,
    setFinancialParametersSaga,
    updateConsumptionPointSaga2,
    updateConsumptionPointSaga,
    addInjectionPointSaga,
    updateInjectionPointSaga,
    getSimulationFromIdSaga,
    getAddressFromAPISaga,
    setPointStateSaga,
    saveCurveAndUpdateConsumptionPointSaga,
    saveCurveAndAddConsumptionPointSaga,
    initExternalInvestmentFormSaga,
]

export default newSimulationFormSaga;
