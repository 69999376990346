import PaperClipIcon from "../icon/PaperClipIcon";
import React from "react";

export default function TemplateButton({action, filename}: { action(): void, filename: string }) {
    return <div
        onClick={action}
        className={"w-min flex p-1 gap-1 px-2 bg-blue-300 bg-opacity-60 dark:bg-blue-400 items-center rounded hover:cursor-pointer hover:bg-blue-300 dark:hover:bg-blue-500 dark:bg-opacity-20 dark:hover:bg-opacity-20"}>
        <PaperClipIcon className={"dark:text-blue-200 text-blue-600 h-5 w-5"}/>
        <span className={"uppercase dark:text-blue-200 text-blue-600 text-xs"}>
                        {filename}
                    </span>
    </div>
}
