import {useDispatch, useSelector} from "react-redux";
import {typologyFormActions} from "../../../../../../../corelogic/usecases/typology-form/typologyFormActions";
import {
    selectDisplayCDCEnedisBtn,
    selectNoOptionChosen
} from "../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import React from "react";
import EnedisCDCForm from "./EnedisCDCForm";
import Divider from "../../../../components/assets/Divider";
import CDCPrevisuChart from "../../../../components/assets/graphic/CDCPrevisuChart";
import Header from "./header/Header";
import RecalageForm from "../completude-and-recalage-button/recalage/form/RecalageForm";
import RecalageButton from "../completude-and-recalage-button/recalage/button/RecalageButton";
import LowCompletenessForm from "../completude-and-recalage-button/low-completeness/form/LowCompletenessForm";
import FullCompletudeForm from "../completude-and-recalage-button/full-completude/FullCompletudeForm";
import {LoadCurveStatusBadge} from "../badge/LoadCurveBadge";
import TotalEnergyBadge from "../badge/TotalEnergyBadge";
import MaxPowerBadge from "../badge/MaxPowerBadge";
import {
    selectNewCurve
} from "../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-curve/selectFormCurve";

export default function EnedisCDC() {
    const dispatch = useDispatch()
    const noOption = useSelector(selectNoOptionChosen)
    const enedis = useSelector(selectDisplayCDCEnedisBtn)
    const newCurve = useSelector(selectNewCurve)
    const opacity = enedis ? " opacity-100 " : " opacity-80 "
    const enableStyle = ' hover:cursor-pointer hover:opacity-100'

    function clickOnEnedisCDCBtn() {
        if (noOption) {
            dispatch(typologyFormActions.setCDCUploadOption('ENEDIS'))

        }
    }

    return <div
        onClick={clickOnEnedisCDCBtn}
        className={"w-full flex-col  items-start gap-1 inline-flex justify-end px-6 py-4  bg-gray-100 dark:bg-gray-700 rounded border border-blue-600 justify-between  gap-4 " +
            enableStyle + opacity}>
        <Header/>
        {enedis && <Divider/>}
        {enedis && (newCurve ? <CDC/> : <EnedisCDCForm/>)}
    </div>
}

function CDC() {
    const newCurve = useSelector(selectNewCurve)
    return <>
        <div className={"w-full flex justify-end my-2 gap-2"}>
            <RecalageButton/>
        </div>
        <RecalageForm/>
        <FullCompletudeForm/>
        <LowCompletenessForm/>
        {newCurve && <div className={"flex w-full flex-wrap gap-2 relative top-14 left-6"}>
                <span
                    className={"text-sm text-gray-500  dark:text-gray-300 tracking-normal font-semibold"}>
                    Courbe de charge ajoutée
                </span>
            <LoadCurveStatusBadge/>
            <TotalEnergyBadge/>
            <MaxPowerBadge/>
        </div>}
        <div className={"w-full"}><CDCPrevisuChart color={"rgb(59,130,246)"} data={newCurve}/></div>
    </>
}



