import {WrapperCard} from "../../wrapper/WrapperCard";
import React, {useRef, useState} from "react";
import {useSelector} from "react-redux";
import {
    selectIsOpenOperation
} from "../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectGeneralInfoForm";
import {ProdType} from "../../../containers/BalanceSheet";
import InvestorView from "./external-investment-data/InvestorView";
import InvestProducerView from "./external-invest-producer-data/InvestProducerView";


const BalanceSheetDataForExternalInvestment = ({selectedType}: { selectedType: ProdType }) => {
    // replace selector with correct selectors
    const [tab, setTab] = useState({
        menu: ['Tiers Investisseur(s)', 'Bilan Producteur(s)'],
        selected: 0
    })

    const cashFlowTableRef = useRef(null)
    const isOpenOperation: boolean = useSelector(selectIsOpenOperation)

    const tableId = 'cash-flow-table' + (isOpenOperation ? '-open' : '') + (tab.selected === 1 ? '-producer' : '-investor')

    return <>
        <div className={"p-4 w-full"}>
            <WrapperCard
                exportAsPNGRef={cashFlowTableRef}
                title={tab}
                setTab={setTab}
                exportTableId={tableId}>
                {
                    tab.selected === 0 ?
                        <InvestorView id={tableId} selectedType={selectedType} cashFlowTableRef={cashFlowTableRef} selectedView={tab.selected === 0}/>
                        : <InvestProducerView id={tableId} selectedType={selectedType} cashFlowTableRef={cashFlowTableRef} selectedView={tab.selected !== 0}/>
                }
            </WrapperCard>
        </div>
    </>
}

export default BalanceSheetDataForExternalInvestment;