import {useDispatch} from "react-redux";
import {push} from "connected-react-router";
import Logo from "../../../components/assets/icon/Logo";
import React from "react";

const HomeIcon = () => {
    const dispatch = useDispatch()

    function pushNewSimulationPage() {
        dispatch(push('/'))
    }

    return <span title={"Accueil"} onClick={pushNewSimulationPage}
                 className="flex-1 px-2 w-min h-min flex items-center justify-start hover:cursor-pointer hover:bg-slate-600">
            <a
                className="w-14 h-14 hover:cursor-pointer hover:animate-pulse rounded-sm flex items-center justify-center">
                <Logo/>
            </a>
            <a className="hidden uppercase ml-1 text-sm tracking-widest font-semibold text-secondary md:block mt-3"> EnoLab </a>
        </span>
}
export default HomeIcon