import React, {ComponentType, useState} from "react";
import {InformationCircleIcon} from "../../icon/InformationCircleIcon";
import CheckIcon from "../../icon/CheckIcon";

type SelectorInputType = {
    label: string,
    disabled: boolean,
    onClick(): void,
    Icon: ComponentType,
    description: string,
    showDescriptionAsTooltip?: boolean,
    dataCy?: string,
    badges: string[]
}

export default function SelectorInputCard({
                                              label,
                                              disabled,
                                              onClick,
                                              Icon,
                                              description,
                                              showDescriptionAsTooltip,
                                              dataCy,
                                              badges
                                          }: SelectorInputType) {

    function Badge({text, color, Icon, description}: { text: string, color: string, Icon: any, description?: string }) {
        return <div
            title={description}
            className={"flex gap-1 px-2 pb-1 items-center w-full"}>
            <Icon/>
            <span className={"BodySmallMedium  w-full " + color}>
            {text}
        </span>
        </div>
    }

    return disabled ? <div
            data-cy={dataCy}
            onClick={onClick}
            className="h-full flex justify-evenly flex-col items-center border border-slate-300 rounded-lg bg-slate-100 p-4 hover:cursor-pointer hover:bg-slate-200 hover:shadow-md dark:border-2 dark:border-zinc-600 dark:shadow-2xl dark:bg-zinc-700 dark:hover:bg-zinc-600">
            <div className={"h-full flex-col items-center flex pb-8"}>
                {description && <div className="text-slate-400 font-bold text-sm pb-2 dark:text-zinc-200">{label}</div>}
                <div
                    className="flex h-24 w-24 flex-col items-center rounded-full  bg-slate-50  p-5 hover:cursor-pointer pointer-events-none">
                    <Icon/>
                </div>
            </div>
            {
                badges.map((badge: string) =>
                    <Badge
                        Icon={() => CheckIcon({color: "text-emerald-600"})}
                        text={badge}
                        color={" dark:text-emerald-800 text-emerald-600  "}
                    />
                )
            }
            {
                showDescriptionAsTooltip ?
                    <Badge
                        Icon={InformationCircleIcon}
                        text={"Informations / cas particuliers"}
                        color={" dark:text-slate-400 text-slate-600 dark:hover:text-slate-300 hover:text-slate-700"}
                        description={description}/>
                    :
                    <div className={"h-full text-slate-600  text-center text-xs pt-2 dark:text-zinc-200"}>
                        {description}
                    </div>
            }

        </div> :
        <div
            data-cy={dataCy}
            className="h-full flex justify-evenly flex-col items-center rounded-lg border-2 border-slate-400 bg-slate-200 p-4 shadow-xl dark:border-2 dark:border-zinc-300 dark:bg-zinc-600 dark:shadow-2xl">
            <div className={"h-full flex-col items-center flex pb-8"}>
                {description && <div className="text-slate-700 font-bold text-sm pb-2 dark:text-zinc-100">{label}</div>}
                <div className="flex h-24 w-24 flex-col items-center rounded-full  bg-slate-50 p-5 ">
                    <Icon/>
                </div>
            </div>
            {badges.map((badge: string) => <Badge Icon={() => CheckIcon({color: "text-emerald-600"})} text={badge}
                                                  color={" text-emerald-600 "}/>)}
            {
                showDescriptionAsTooltip ?
                    <Badge
                        Icon={InformationCircleIcon}
                        text={"Informations / cas particuliers"}
                        color={" dark:text-slate-400 text-slate-600 dark:hover:text-slate-300 hover:text-slate-700"}
                        description={description}/>
                    :
                    <div className={"h-full text-slate-600  text-center text-xs pt-2 dark:text-zinc-200"}>
                        {description}
                    </div>
            }

        </div>
}
