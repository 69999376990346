import {SellingPriceFunction} from "../../../../../../../../corelogic/models/types/new-simulation/form/SellingPriceFunction";
import {useSnackbar} from "notistack";
import {useDispatch, useSelector} from "react-redux";
import {
    selectFormDuration,
    selectFormSellingPriceSelectedCells,
    selectSellingPriceInflation,
    selectSellingPriceInflationToApply,
    selectSellingPriceInflationToggled,
    selectSellingPriceVariableInflations
} from "../../../../../../../../corelogic/usecases/form/formSelector";
import React, {useEffect, useState} from "react";
import TitleText from "../../../../../components/assets/text/TitleText";
import SelectorInput from "../../../../../components/assets/FormAssets/selector/SelectorInput";
import TextInput, {LabelInput} from "../../../../../components/assets/FormAssets/TextInput";
import {blue, purple, red, State, UnitType, yellow, zinc} from "../../../../../../../../config/app-config";
import {PlayIconSolid} from "../../../../../components/assets/icon/PlayIcon";
import {SellingPriceMap} from "../../../../wrapper/SellingPriceTableWithFunction";
import Notification from "../../../../paper/Notification";
import FixedInflationInput from "../../FixedInflationInput";
import Switch from "../../../../../components/assets/toggle/Switch";
import {formActions} from "../../../../../../../../corelogic/usecases/form/formActions";
import PencilIcon from "../../../../../components/assets/icon/PencilIcon";
import {InflationInterval} from "../../variable-inflation-widget/VariableInflationInput";
import {selectIsDarkMode} from "../../../../../../../../corelogic/usecases/config/configSelector";

type SellingPriceHeaderType = {
    functionValue: string | undefined,
    setFunctionValue(fct: string): void,
    functionToApply: SellingPriceFunction,
    setFunctionToApply(fct: SellingPriceFunction): void,
    setSellingPriceTableRefresh(refresh: boolean): void,

}

export default function SellingPriceHeader({
                                               functionValue,
                                               setFunctionValue,
                                               functionToApply,
                                               setFunctionToApply,
                                               setSellingPriceTableRefresh
                                           }: SellingPriceHeaderType) {

    const dispatch = useDispatch()
    const sellingPriceSelectedCells = useSelector(selectFormSellingPriceSelectedCells)
    const [sellingPriceFunctionDisabled, setSellingPriceFunctionDisabled] = useState(true)
    const [valueAppliedIsValid, setValueAppliedIsValid] = useState(true)
    const [inflationAppliedIsValid, setInflationAppliedIsValid] = useState(true)
    const fixedInflation = useSelector(selectSellingPriceInflation)


    useEffect(() => {
        dispatch(formActions.initSellingPriceInflationToggle())
    }, [])
    useEffect(() => {
        setSellingPriceFunctionDisabled(!sellingPriceSelectedCells.some((rowCellsSelected: any) => rowCellsSelected.some((cellIsSelected: any) => cellIsSelected)))
    }, [sellingPriceSelectedCells])


    useEffect(() => {
        if (isGifted()) setValueAppliedIsValid(true)
        if (functionValue !== null && functionValue) {
            if (isFixedPrice() || isFixedPriceWithInflation()) {
                setValueAppliedIsValid(!isNaN(Number(functionValue)) && parseFloat(functionValue) >= 0)
            }
            if (functionToApply === SellingPriceFunction.DISCOUNT_COMPLEMENT_PRICE) {
                setValueAppliedIsValid(!isNaN(Number(functionValue)) && parseFloat(functionValue) >= 0 && parseFloat(functionValue) <= 100)
            }
        }
    }, [functionValue, functionToApply])
    useEffect(() => {
        if (fixedInflation && isFixedPriceWithInflation()) {
            setInflationAppliedIsValid(true) // TODO
        }
    }, [fixedInflation, functionToApply])

    function functionToApplyOnChange(e: React.ChangeEvent<HTMLSelectElement>) {
        switch (e.target.value) {
            case SellingPriceMap[SellingPriceFunction.FIXED_PRICE] :
                setFunctionToApply(SellingPriceFunction.FIXED_PRICE);
                break;
            case SellingPriceMap[SellingPriceFunction.DISCOUNT_COMPLEMENT_PRICE] :
                setFunctionToApply(SellingPriceFunction.DISCOUNT_COMPLEMENT_PRICE);
                break;
            case SellingPriceMap[SellingPriceFunction.FIXED_PRICE_WITH_INFLATION] :
                setFunctionToApply(SellingPriceFunction.FIXED_PRICE_WITH_INFLATION);
                break;
            case SellingPriceMap[SellingPriceFunction.GIFTED] :
                setFunctionToApply(SellingPriceFunction.GIFTED);
                break;
            default :
                break;
        }
    }

    function isFixedPriceWithInflation() {
        return functionToApply === SellingPriceFunction.FIXED_PRICE_WITH_INFLATION
    }

    function isFixedPrice() {
        return functionToApply === SellingPriceFunction.FIXED_PRICE
    }

    function isGifted() {
        return functionToApply === SellingPriceFunction.GIFTED
    }

    function functionValueOnChange(e: string) {
        // Basic Validation
        setFunctionValue(e);
    }

    return <div className={"flex flex-col xl:flex-row justify-between  items-start w-full "}>
        <TitleText
            title={"Prix de vente interne"}
            textSize={"text-sm"}
            typeOfMargin={"small"}/>
        <div className={"mt-2 flex w-full  mr-2 scale-90 justify-end gap-2"}>
            <SelectorInput value={SellingPriceMap[functionToApply]} id={"select-function-selling-price"}
                           onChange={functionToApplyOnChange} dataCy={"financial-params-selling-price-type"}
                           label={"Appliquer une fonction sur le prix"}
                           options={Object.values(SellingPriceMap)}/>
            {!isGifted() && <TextInput
                value={functionValue}
                dataCy={"financial-params-selling-price-value"}
                label={SellingPriceMap[functionToApply] === SellingPriceMap[SellingPriceFunction.FIXED_PRICE_WITH_INFLATION] ? "PRIX DE BASE" : SellingPriceMap[functionToApply].toLocaleUpperCase()}
                isAFloat
                error={{
                    state: !valueAppliedIsValid,
                    msg: isFixedPrice() || isFixedPriceWithInflation() ? "le prix doit être un nombre positif." : "la réduction doit être comprise entre 0 et 100 %"
                }}
                topMargin={" "} placeholder={isFixedPrice() || isFixedPriceWithInflation() ? "0.14": "10"} id={""} onChange={functionValueOnChange}
                unit={isFixedPrice() || isFixedPriceWithInflation() ? UnitType.EURO_HT_BY_KILO_WATT: UnitType.PERCENT}
            />}
            {isFixedPriceWithInflation() && <InflationInput/>}
            <ApplyPrice setSellingPriceTableRefresh={setSellingPriceTableRefresh} isFixedPrice={isFixedPrice}
                        isFixedPriceWithInflation={isFixedPriceWithInflation} functionToApply={functionToApply}
                        functionValue={functionValue} isGifted={isGifted}
                        functionValueIsValid={valueAppliedIsValid && inflationAppliedIsValid}
                        sellingPriceFunctionDisabled={sellingPriceFunctionDisabled}/>

        </div>
    </div>


}


const InputDisplayer = () => {
    const [inflationIsValid, setInflationIsValid] = useState(true)
    const fixedInflation = useSelector(selectSellingPriceInflation)
    const duration = useSelector(selectFormDuration)
    const toggle = useSelector(selectSellingPriceInflationToggled)
    const variableInflations = useSelector(selectSellingPriceVariableInflations)

    const dispatch = useDispatch()

    useEffect(() => {
        if (fixedInflation !== null && fixedInflation) {
            setInflationIsValid(!isNaN(Number(fixedInflation)) && parseFloat(fixedInflation) >= 0)
        }
    }, [fixedInflation])

    function inflationOnChange(e: string) {
        // Basic Validation
        dispatch(formActions.setSellingPriceInflation(e))
    }

    function textToHelp() {
        let textToHelp = 'Vous avez affecté une inflation de '
        variableInflations?.forEach((inflation: InflationInterval, index: number) => {
            const commaOrDotText = index === variableInflations.length - 1 ? "." : ", "
            textToHelp += `${inflation.value}% sur les années ${inflation.startYear} à ${inflation.endYear} ${commaOrDotText}`
        })
        return textToHelp
    }

    const variableInflationsPopup = toggle && duration
    const noDurationWarning = !duration
    const isFixedInput = !toggle

    if (variableInflationsPopup) {
        const variableInflationText = variableInflations ? `Inflation variable : ${variableInflations[0].value}% ..` : 'Ajouter une inflation'
        return <div onClick={() => dispatch(formActions.openModalSellingPriceInflations(true))}
                    className={"w-full rounded bg-slate-200 py-3 hover:cursor-pointer hover:bg-slate-300 flex items-center gap-2 justify-center dark:bg-zinc-600 hover:dark:bg-zinc-700"}>
                        <span title={textToHelp()}
                              className={"text-slate-600 dark:text-zinc-50"}>{variableInflationText} </span>
            <PencilIcon className={"text-slate-600 h-5 w-5 dark:text-zinc-50"}/>
        </div>
    }
    if (isFixedInput) {
        return <FixedInflationInput id={"step5-selling-price-inflation"} inflation={fixedInflation}
                                    inflationIsValid={inflationIsValid}
                                    inflationOnChange={inflationOnChange}/>
    }
    if (noDurationWarning) {
        return <Notification
            state={State.WARNING}
            text={"Attention, la durée de simulation doit être remplie pour gérer l'inflation variable."}/>
    }
    return <></>
}

function InflationInput() {

    return <div className={"flex flex-col w-full"}>
        <ToolTipAndSwitch/>
        <InputDisplayer />
    </div>

}


function ToolTipAndSwitch() {
    const dispatch = useDispatch()
    const sellingPriceInflationsToggle = useSelector(selectSellingPriceInflationToggled)

    function handleSwithOnClick() {
        dispatch(formActions.setSellingPriceInflationToggle(!sellingPriceInflationsToggle))
        dispatch(formActions.openModalSellingPriceInflations(!sellingPriceInflationsToggle))

    }

    return <div className={"flex gap-2"}>
            <LabelInput label={"INFLATION (FIXE/ VARIABLE)"}/>
        <Switch state={sellingPriceInflationsToggle}
                action={() => handleSwithOnClick()}/>
    </div>
}


type ApplyPriceType = {
    setSellingPriceTableRefresh: (refresh: boolean) => void,
    functionValueIsValid: boolean,
    functionValue?: string,
    sellingPriceFunctionDisabled: boolean,
    isFixedPrice(): boolean,
    isFixedPriceWithInflation(): boolean,
    isGifted(): boolean,
    functionToApply: SellingPriceFunction
}

function ApplyPrice({
                        sellingPriceFunctionDisabled,
                        functionValueIsValid,
                        functionValue,
                        setSellingPriceTableRefresh,
                        isFixedPrice,
                        isFixedPriceWithInflation,
                        isGifted,
                        functionToApply,
                    }: ApplyPriceType) {

    const {enqueueSnackbar} = useSnackbar();
    const btnSharedStyle = " mt-6 px-4 h-min py-3 flex items-center  rounded-r-md    shadow-xl  "
    const [style, setStyle] = useState(btnSharedStyle)
    const sellingPriceInflationToApply = useSelector(selectSellingPriceInflationToApply)
    const isDarkMode = useSelector(selectIsDarkMode)
    const inflationIfSelected = isFixedPriceWithInflation() ? sellingPriceInflationToApply : true
    const [btnColor, setBtnColor] = useState(zinc)
    const [hover, setHover] = useState(false)

    useEffect(() => {
        if (!sellingPriceFunctionDisabled && functionValueIsValid && inflationIfSelected && (functionValue || isGifted())) {
            setBtnColor(isFixedPrice() ? blue : isFixedPriceWithInflation() ? red : isGifted() ? purple : yellow)
            setStyle(btnSharedStyle + ` hover:cursor-pointer border-2  border-2 shadow-xl`)

        } else {
            setBtnColor(zinc)
            setStyle(btnSharedStyle + ` border-2 border-slate-300 dark:border-zinc-700 text-zinc-400 dark:bg-zinc-700 dark:text-zinc-300 `)
        }

    }, [sellingPriceFunctionDisabled, functionValueIsValid, functionValue, functionToApply, sellingPriceInflationToApply])

    function handleSellingPriceFunction() {
        if (!sellingPriceFunctionDisabled && functionValueIsValid && (functionValue || isGifted())) {
            setSellingPriceTableRefresh(true)
        } else {
            enqueueSnackbar("Sélectionnez au moins une cellule et ajouter un prix pour affecter la fonction ", {variant: "warning"})
        }
    }

    return <div onClick={handleSellingPriceFunction}
                style={{
                    backgroundColor: hover && btnColor.valueOf() !== zinc ? isDarkMode ? btnColor["600"] : btnColor["400"] : isDarkMode ? btnColor["700"] : btnColor["500"],
                    borderColor: isDarkMode ? btnColor["700"] : btnColor["500"],
                    color: isDarkMode ? btnColor["300"] : btnColor["100"],
                }}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                data-cy={"financial-params-selling-price-apply"}
                className={style}>
        <PlayIconSolid className={"w-5 h-5 text-white"}/>
    </div>
}
