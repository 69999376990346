import React from "react";
import {useSelector} from "react-redux";
import {selectIsLoadingSimulationForm} from "../../../../../corelogic/usecases/loader/loaderSelector";
import SideBarEnoLab from "../nav-bar/SideBarEnoLab";

export default (WrappedComponent: any, restitutionType: string)=>()=> {
    const simulationFormIsLoading = useSelector(selectIsLoadingSimulationForm)

    return <div className="shadow flex  w-full h-full-minus-navbar bg-gray-50 rounded dark:bg-zinc-900">
        <SideBarEnoLab pathName={restitutionType}/>
        <WrappedComponent/>
    </div>
}
