import {useCallback, useEffect, useState} from "react";
import {formatNumber, lightenDarkenColor, pieInitialOption} from "./config";
import {selectIsDarkMode} from "../../../../../corelogic/usecases/config/configSelector";
import {useSelector} from "react-redux";

const getColorFromName = (name: string) => {
    switch (name) {
        case 'Autoconsommation du collectif':
            return '#f3b617';
        case 'Autoconsommation individuelle':
            return lightenDarkenColor('#f3b617', 60);
        case 'Surplus':
            return '#c4c4c4';
        case 'Complément du réseau':
            return '#84a6cf';
    }
}

const usePieChartOption = (data: any, nature: 'consumption' | 'production') => {
    const isDarkMode = useSelector(selectIsDarkMode)
    const [option, setOption] = useState<any>(pieInitialOption(nature));
    const [total, setTotal] = useState<number>(0);
    const formatter = useCallback(({
                                       name,
                                       value
                                   }: { name: string, value: number }) => `${name}  <b>${formatNumber(value, 0)} kWh (${formatNumber(value / total * 100, 1)}%)</b>`, [total])

    useEffect(() => {
        if (data.length) {

            const total = data.reduce((acc: number, curr: any) => acc + curr.value, 0);
            setTotal(total);
            setOption((option: any) => ({
                ...option,
                tooltip: {
                    ...option.tooltip,
                    formatter,
                },
                series: [{
                    ...option.series[0],
                    name : '',
                    data,
                    color: data.map(({name}: {name: string}) => getColorFromName(name)),
                    markPoint: {
                        ...option.series[0].markPoint,
                        data: [{...option.series[0].markPoint.data[0], value: `${formatNumber(total)} kWh`}]
                    }
                }]
            }))

        }
    }, [data, formatter])
    useEffect(()=> {
        const newOption = {
            ...option,
            backgroundColor:isDarkMode ?"#3F3F46":'#fff',
            series : [
                {
                    ...option.series[0],
                    markPoint : {
                        ...option.series[0].markPoint,
                        label : {
                            ...option.series[0].markPoint.label,
                            color  : isDarkMode  ? 'white' : 'black'
                        }
                    }
                }
            ]
        }
        setOption(newOption)
    },[isDarkMode])
    return option;
}

export default usePieChartOption;
