import TextInput from "../../../../components/assets/FormAssets/TextInput";
import React from "react";
import {typologyFormActions} from "../../../../../../../corelogic/usecases/typology-form/typologyFormActions";
import {
    selectConsumptionPointName,
    selectConsumptionPointNameIsValid
} from "../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import {useDispatch, useSelector} from "react-redux";
import {selectIsLoadingSimulationForm} from "../../../../../../../corelogic/usecases/loader/loaderSelector";

export default function NameInput(){
    const dispatch = useDispatch()
    const name = useSelector(selectConsumptionPointName)
    const nameIsValid = useSelector(selectConsumptionPointNameIsValid);
    const isLoadingForm = useSelector(selectIsLoadingSimulationForm)
    function nameOnChange(e: string) {
        // In HTML, selector option cannot contains double spaces
        e = e.replace("  ", " ")
        // Basic Validation
        dispatch(typologyFormActions.setName(e));
    }

    return <TextInput dataCy={"typology-consumption-name"} mandatory={true} label={"NOM"}
                      placeholder={"(ex : T1)"} id={"step2-name"} loading={isLoadingForm}
                      onChange={nameOnChange} value={name} error={{
        state: !nameIsValid,
        msg: name.length < 2 ? "le nom doit être composé d'au moins 2 caractères." : "Le nom doit être unique."
    }}/>
}