import {ButtonState} from "../../../../../config/app-config";

export class Step {

    private _title: string;
    private _description: string[];
    private _index : number
    private _state: ButtonState;

    constructor(title: string, description: string[], state: ButtonState, index : number) {
        this._title = title;
        this._description = description;
        this._state = state
        this._index = index

    }

    static fromObject({title, description, state, index}: { title: string, description: string[], state: ButtonState , index:number}) {
        return new this(title, description, state, index )
    }


    get index(): number {
        return this._index;
    }

    set index(value: number) {
        this._index = value;
    }

    set title(value: string) {
        this._title = value;
    }

    set description(value: string[]) {
        this._description = value;
    }

    set state(value: ButtonState) {
        this._state = value;
    }

    get title(): string {
        return this._title;
    }

    get description(): string[] {
        return this._description;
    }

    get state(): ButtonState {
        return this._state;
    }
}