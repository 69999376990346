import {createAction} from "../../../store/createAction";
import {ActionsUnion} from "../../../store/actionsUnions";

export const GET_RESTITUTION = 'GET_RESTITUTION'
export const SET_RESTITUTION_DATA = 'SET_RESTITUTION'
export const SET_RESTITUTION_LOADING = 'SET_RESTITUTION_LOADING'

export const restitutionActions = {
    setRestitutionLoading: (loading: boolean) => createAction(SET_RESTITUTION_LOADING, loading),
    getRestitution: () => createAction(GET_RESTITUTION),
    setRestitution: (restitution: any) => createAction(SET_RESTITUTION_DATA, restitution),
};

export type Actions = ActionsUnion<typeof restitutionActions>;
