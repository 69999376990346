import {newSimulationActions} from "../new-simulation/newSimulationActions";
import {
    ADD_LOW_COMPLETUDE_ROW,
    CLICK_ON_SGE_FORM_TAB,
    CLICK_ON_SGE_RESULT_TAB, GENERATE_12_MONTHLY_ROWS,
    INIT_LOW_COMPLETUDE_TABLE,
    REMOVE_LOW_COMPLETUDE_ROW,
    RESET_CDC_UPLOAD,
    SAVE_SGE_CONFIG,
    typologyFormActions
} from "./typologyFormActions";

import {call, getContext, put, select, takeLatest} from "redux-saga/effects";
import {snackBarErrorsActions} from "../errors/snackbarErrorsActions";
import {loaderActions} from "../loader/loaderActions";
import {
    selectCartoCircleInKm,
    selectLowCompletenessTable,
    selectSGEDateEndDisplayer,
    selectSGEDateStartDisplayer,
    selectSGEDelay,
    selectSGEFetchIfHoles,
    selectSGEPRMDisplayer,
    selectSGERetries
} from "./typologyFormSelector";
import LowCompletudeInput
    from "../../../adapters/primary/ui/composition/form/load-curve-upload/completude-and-recalage-button/low-completeness/form/table/LowCompletudeInput";
import {
    selectFirstNewCurve,
    selectNewCurve
} from "../new-simulation/new-simulation-selector/form-curve/selectFormCurve";
import LowCompletudeDate, {
    addOneDayToDate
} from "../../../adapters/primary/ui/composition/form/load-curve-upload/completude-and-recalage-button/low-completeness/form/table/LowCompletudeDate";
import {selectSelectedSimulationId} from "../new-simulation/new-simulation-selector/form/newSimulationSelector";
import {NewSimulationGatewayInterface} from "../new-simulation/newSimulationGateway.interface";
import {useSelector} from "react-redux";
import {YEAR_N_MOINS_UN} from "../../../config/app-config";

function* findBestCirclePlace():any {
    try {
        yield put(loaderActions.setEnoptiIsLoading(true))
        const circleDiameter: number = yield select(selectCartoCircleInKm)
        const simulationId = yield select(selectSelectedSimulationId)
        const newSimulationGateway: NewSimulationGatewayInterface = yield getContext('newSimulationGatewayContext');
        console.log(circleDiameter, simulationId)
        const optimizedPerimeter: {lat : number, lng : number} = yield call(newSimulationGateway.optimizePerimeter, simulationId, circleDiameter);
        yield put(typologyFormActions.setBestCirclePlace(optimizedPerimeter))
    } catch (error: any) {
        yield put(snackBarErrorsActions.handleError(error));
        console.log('error on findBestCirclePlace', error)
    }
    yield put(loaderActions.setEnoptiIsLoading(false))

}
function* findBestCirclePlaceSaga(){
    yield takeLatest('FIND_BEST_CIRCLE_PLACE', findBestCirclePlace);
}

function* initLowCompletudeTable():any {
    try {
        const firstNewCurve = yield select(selectFirstNewCurve)
        const resMeasurement = firstNewCurve.measurement
        const lastTimeStampFetched = resMeasurement[resMeasurement.length-1].timestamp // ex : "2021-01-01T00:00:00+00:00"

        const year = lastTimeStampFetched.slice(0, 4) // 2021
        const month = lastTimeStampFetched.slice(5, 7) // 01
        const day = lastTimeStampFetched.slice(8, 10)// 01

        const nextDay = addOneDayToDate(new LowCompletudeDate(day,  month,  year))
        yield put(typologyFormActions.setLOWStartDateDays(nextDay.days,0))
        yield put(typologyFormActions.setLOWStartDateMonths(nextDay.months,0))
        yield put(typologyFormActions.setLOWStartDateYears(nextDay.years,0))
    } catch (error: any) {
        yield put(snackBarErrorsActions.handleError(error));
        console.log('error on initLowCompletudeTable', error)
    }
}
function* initLowCompletudeTableSaga() {
    yield takeLatest(INIT_LOW_COMPLETUDE_TABLE, initLowCompletudeTable);
}

function* addLowCompletudeRow():any {
    try {
        const lowCompletudeTable:LowCompletudeInput[] = yield select(selectLowCompletenessTable)
        const latestRow:LowCompletudeInput = lowCompletudeTable[lowCompletudeTable.length -1]
        const newRow:LowCompletudeInput = new LowCompletudeInput(
            new LowCompletudeDate(latestRow.dateEnd.days, latestRow.dateEnd.months, latestRow.dateEnd.years),
            new LowCompletudeDate('','',''),
            '')
        let newTable:LowCompletudeInput[] = [...lowCompletudeTable]
        newTable.push(newRow)
        yield put(typologyFormActions.setLowCompletudeTable(newTable))

    } catch (error: any) {
        yield put(snackBarErrorsActions.handleError(error));
        console.log('error on addLowCompletudeRow', error)
    }
}
function* removeLowCompletudeRowSaga() {
    yield takeLatest(REMOVE_LOW_COMPLETUDE_ROW, removeLowCompletudeRow);
}

function* removeLowCompletudeRow(action:any):any {
    try {
        const indexToRemove= action.payload
        const lowCompletudeTable:LowCompletudeInput[] = yield select(selectLowCompletenessTable)
        const newTable =  lowCompletudeTable.filter((_,index) => index !== indexToRemove)
        yield put(typologyFormActions.setLowCompletudeTable(newTable))

    } catch (error: any) {
        yield put(snackBarErrorsActions.handleError(error));
        console.log('error on addLowCompletudeRow', error)
    }
}
function* addLowCompletudeRowSaga() {
    yield takeLatest(ADD_LOW_COMPLETUDE_ROW, addLowCompletudeRow);
}

function* generate12MonthsStartingOnDay(action:any):any {
    try {
        const startingDay = action.payload
        const startDate = new LowCompletudeDate(startingDay, '0', '20'+YEAR_N_MOINS_UN())

        let newTable:LowCompletudeInput[] = []
        Array.from({ length: 12 }, (_, i) => {
            const month = i.toString();
            let nextMonth = (i + 1).toString();
            let year = startDate.years;

            // Handle December (12th month) case for year overlap
            if (i === 0) {  // When current month is December
                newTable.push(new LowCompletudeInput(
                    new LowCompletudeDate(startDate.days, '12', (parseInt(startDate.years)-1).toString()),
                    new LowCompletudeDate(startDate.days, '1', year),  // Use adjusted year for the end date
                    ''
                ));
            } else {
                newTable.push(new LowCompletudeInput(
                    new LowCompletudeDate(startDate.days, month, startDate.years),
                    new LowCompletudeDate(startDate.days, nextMonth, year),  // Use adjusted year for the end date
                    ''
                ));
            }
        })

        yield put(typologyFormActions.setLowCompletudeTable(newTable.reverse()))

    } catch (error: any) {
        yield put(snackBarErrorsActions.handleError(error));
        console.log('error on generate12MonthsStartingOnDay', error)
    }
}
function* generate12MonthsStartingOnDaySaga(){
    yield takeLatest(GENERATE_12_MONTHLY_ROWS, generate12MonthsStartingOnDay);

}

function* resetLoadCurveUpload(): any {
    try {
        yield put(newSimulationActions.setNewCurve(null))
        yield put(newSimulationActions.setFirstNewCurve(null))
        yield put(loaderActions.setIsLoadingNewCurve(false))
        yield put(typologyFormActions.setCDCUploadOption(null))
        yield put(typologyFormActions.setRecalageButtonAction(false))
        yield put(typologyFormActions.setRecalageInput(null))
        yield put(typologyFormActions.setSGEFormTab(true))
    } catch (error: any) {
        yield put(snackBarErrorsActions.handleError(error));
    }
}


function* resetLoadCurveUploadSaga() {
    yield takeLatest(RESET_CDC_UPLOAD, resetLoadCurveUpload);
}

export function* saveSGECDCConfig(): any {
    try {
        const prm: string = yield select(selectSGEPRMDisplayer)
        const startDate: string = yield select(selectSGEDateStartDisplayer)
        const endDate: string = yield select(selectSGEDateEndDisplayer)
        const retries: string = yield select(selectSGERetries)
        const delay: string = yield select(selectSGEDelay)
        const fetchIfHoles: string = yield select(selectSGEFetchIfHoles)

        const config: any = {
            prm: prm,
            dateStart: startDate,
            dateEnd: endDate,
            retries: retries,
            delay: delay,
            fetchIfHoles: fetchIfHoles
        }
        yield put(typologyFormActions.setSGECDCConfig(config))
    } catch (error: any) {
        yield put(snackBarErrorsActions.handleError(error));
    }
}

function* saveSGECDCConfigSaga() {
    yield takeLatest(SAVE_SGE_CONFIG, saveSGECDCConfig);
}

function* clickOnSGEResultTab(): any {
    try {
        const newCurve = yield select(selectNewCurve)

        if (newCurve) {
            yield put(typologyFormActions.setSGEFormTab(false))
        }

    } catch (error: any) {
        yield put(snackBarErrorsActions.handleError(error));
    }
}

function* clickOnSGEResultTabSaga() {
    yield takeLatest(CLICK_ON_SGE_RESULT_TAB, clickOnSGEResultTab);
}

function* clickOnSGEFormTab(): any {
    try {

        yield put(typologyFormActions.setSGEFormTab(true))
    } catch (error: any) {
        yield put(snackBarErrorsActions.handleError(error));
    }
}

function* clickOnSGEFormTabSaga() {
    yield takeLatest(CLICK_ON_SGE_FORM_TAB, clickOnSGEFormTab);
}

const typologyFormSagas = [generate12MonthsStartingOnDaySaga, findBestCirclePlaceSaga, initLowCompletudeTableSaga, removeLowCompletudeRowSaga, addLowCompletudeRowSaga, saveSGECDCConfigSaga, resetLoadCurveUploadSaga, clickOnSGEFormTabSaga, clickOnSGEResultTabSaga]
export default typologyFormSagas;
