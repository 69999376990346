import { createAction } from '../../../store/createAction';
import { ActionsUnion } from '../../../store/actionsUnions';

export const SET_SIMULATION_EXECUTION_STATUS = 'SET_SIMULATION_EXECUTION_STATUS';
export const GET_SIMULATION_EXECUTION_STATUS = 'GET_SIMULATION_EXECUTION_STATUS';

export enum ExecutionStatus {
    running = 'running',
    finished = 'finished',
}

export const simulationExecutionStatusActions = {
    setSimulationExecutionStatus: (status: ExecutionStatus) => createAction(SET_SIMULATION_EXECUTION_STATUS, status),
    getSimulationExecutionStatus: () => createAction(GET_SIMULATION_EXECUTION_STATUS),
};

export type Actions = ActionsUnion<typeof simulationExecutionStatusActions>;
