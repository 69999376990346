import TextInput from "../../../../components/assets/FormAssets/TextInput";
import PointsSelector from "../../../../components/assets/FormAssets/selector/PointsSelector";
import React, {useCallback} from "react";
import {
    selectConsumptionPointACCNumber,
    selectConsumptionPointACCNumberIsValid,
    selectConsumptionPointACIPointId,
    selectIsACIEnabled,
    selectPointBeingEdited
} from "../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import {useDispatch, useSelector} from "react-redux";
import {typologyFormActions} from "../../../../../../../corelogic/usecases/typology-form/typologyFormActions";
import {useSnackbar} from "notistack";
import {selectIsLoadingSimulationForm} from "../../../../../../../corelogic/usecases/loader/loaderSelector";
import {
    makeSelectNonLinkedInjectionPoints
} from "../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectInjectionPointForm";

export default function ACIOrNumberOfPointsInput() {
    const dispatch=useDispatch()
    const {enqueueSnackbar} = useSnackbar()

    const ACI = useSelector(selectIsACIEnabled)
    const aciPointId = useSelector(selectConsumptionPointACIPointId)
    const rowToEdit = useSelector(selectPointBeingEdited)

    const selectNonLinkedInjectionPoints = useCallback(makeSelectNonLinkedInjectionPoints(rowToEdit?.injectionPointId), [rowToEdit?.injectionPointId])
    const injectionPoints = useSelector(selectNonLinkedInjectionPoints)


    function handleACIChange() {
        if (rowToEdit && !rowToEdit.state) {
            enqueueSnackbar("Pour activer l'ACI, le point de soutirage doit être activé",
                {
                    variant: "warning",
                })
        } else {
            if (injectionPoints.length > 0) {
                dispatch(typologyFormActions.enableACI(!ACI))
                if (!ACI && !aciPointId) {
                    dispatch(typologyFormActions.setAciPointId(injectionPoints[0]?.id))
                } else {
                    dispatch(typologyFormActions.setAciPointId(null))
                }
            } else {
                enqueueSnackbar("Pour activer l'ACI, il faut avoir au moins un point d'injection actif et non lié à un point de soutirage",
                    {
                        variant: "warning",
                    })
                dispatch(typologyFormActions.enableACI(false))
            }
        }
    }




    return <>
        <div className="w-full mt-2 mb-4 flex flex-row items-center">
            <label className="mr-2 block tracking-wide text-gray-600 font-semibold dark:text-zinc-300"
                   htmlFor="grid-last-name"> Ajouter de l'autoconsommation individuelle</label>
            <div data-cy={"typology-consumption-aci-switch"} onClick={handleACIChange}
                 className={ACI ? "form-check-input appearance-none w-9 rounded-full float-left h-5 align-top bg-no-repeat bg-contain bg-orange-200 focus:outline-none cursor-pointer shadow-sm" : "form-check-input appearance-none w-9 rounded-full float-left h-5 align-top bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm"}
                 role="switch" id="flexSwitchCheckDefault">
                <div
                    className={ACI ? "w-5 h-5 ml-4 bg-secondary rounded-full " : "w-5 h-5 bg-white rounded-full "}/>
            </div>
        </div>
        {!ACI && <ACCNumberInput/>}
        {ACI && <ACIPointSelectorInput/>}
    </>
}
function ACIPointSelectorInput(){
    const dispatch=useDispatch()
    const aciPointId = useSelector(selectConsumptionPointACIPointId)
    const rowToEdit = useSelector(selectPointBeingEdited)
    const isLoadingForm = useSelector(selectIsLoadingSimulationForm)

    const selectNonLinkedInjectionPoints = useCallback(makeSelectNonLinkedInjectionPoints(rowToEdit?.injectionPointId), [rowToEdit?.injectionPointId])
    const injectionPoints = useSelector(selectNonLinkedInjectionPoints)

    const ACIInjectionPointOnChange = (event: any) => {
        dispatch(typologyFormActions.setAciPointId(event.target.value))
    }
    return <PointsSelector dataCy={"typology-consumption-aci-selector"} value={isLoadingForm ? 'chargement ...' : aciPointId}
                           onChange={ACIInjectionPointOnChange}
                           id={"select-link-consumption-production"}
                           label={"Lier avec un point d'injection"}
                           points={injectionPoints}/>
}
function ACCNumberInput(){
    const dispatch=useDispatch()
    const ACI = useSelector(selectIsACIEnabled)
    const isLoadingForm = useSelector(selectIsLoadingSimulationForm)

    const accNumber = useSelector(selectConsumptionPointACCNumber)
    const accNumberIsValid = useSelector(selectConsumptionPointACCNumberIsValid)

    function accNumberOnChange(e: string) {
        // Basic Validation
        dispatch(typologyFormActions.setAccNumber(e));
    }


    return <TextInput dataCy={"typology-consumption-number-dots"} id={"nombre-soutirage"} error={{
        state: !accNumberIsValid && !ACI,
        msg: "le champ doit être un nombre supérieur à zéro"
    }} mandatory={true} loading={isLoadingForm} isAFloat label={"NOMBRE DE POINTS À AJOUTER"} value={ACI ? "1" : accNumber}
                      onChange={accNumberOnChange} placeholder={"1"}/>
}