import {InjectionPoint} from "../../../../../../../corelogic/models/types/new-simulation/form/InjectionPoint";
import EmptyTable from "../../../table/EmptyTable";
import React, {useEffect, useState} from "react";
import {ButtonState, Theme} from "../../../../../../../config/app-config";
import {XIconRed} from "../../../../components/assets/icon/XIcon";
import {useDispatch, useSelector} from "react-redux";

import TitleText from "../../../../components/assets/text/TitleText";
import RegularBtn from "../../../../components/assets/button/RegularBtn";
import {formActions} from "../../../../../../../corelogic/usecases/form/formActions";
import {selectFormOpexesAreValid} from "../../../../../../../corelogic/usecases/form/formSelector";
import SelectorInputForOpex from "../../../../components/assets/FormAssets/selector/SelectorInputForOpex";
import {InformationCircleIcon} from "../../../../components/assets/icon/InformationCircleIcon";
import TextInputForTable from "../../../../components/assets/FormAssets/TextInputForTable";
import {selectIsLoadingSimulationForm} from "../../../../../../../corelogic/usecases/loader/loaderSelector";
import {
    selectFinancialParameters
} from "../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectFinancialParameter";
import {
    selectInjectionPoints
} from "../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectInjectionPointForm";

export type OpexFormType = {
    id: string,
    opexName: string,
    opex: string,
    opexAverageInflation: string,
}


export default function OpexTable() {
    const dispatch = useDispatch()
    const [opexes, setOpexes] = useState<OpexFormType[]>([])
    const [opexNameIsValid, setOpexNameIsValid] = useState([true])
    const [opexIsValid, setOpexIsValid] = useState([true])
    const [opexInflationIsValid, setOpexInflationIsValid] = useState([true])
    const storeFinancialParameters = useSelector(selectFinancialParameters)
    const opexesAreValid = useSelector(selectFormOpexesAreValid)
    const injectionPoints: InjectionPoint[] = useSelector(selectInjectionPoints)
    const allInjectionPointForOpex: { id: string, name: string }[] = [{
        id: "Opex global",
        name: "Opex global"
    }, injectionPoints.map((point: InjectionPoint) => ({id: point.id, name: point.name}))].flat()

    const isFormLoading = useSelector(selectIsLoadingSimulationForm)
    const [inflationError, setInflationError] = useState(false)
    const [priceError, setPriceError] = useState(false)
    const [nameError, setNameError] = useState(false)

    useEffect(() => {
        setNameError(opexNameIsValid.some((nameError: boolean) => !nameError))
    }, [opexNameIsValid])

    useEffect(() => {
        setInflationError(opexInflationIsValid.some((inflationError: boolean) => !inflationError))
    }, [opexInflationIsValid])

    useEffect(() => {
        setPriceError(opexIsValid.some((priceError: boolean) => !priceError))
    }, [opexIsValid])
    useEffect(() => {
        const rowOpexesAreValid: boolean[] = []
        opexNameIsValid.forEach((opexNameValid, index) => {
            rowOpexesAreValid.push(opexNameValid && opexIsValid[index] && opexInflationIsValid[index])
        })
        dispatch(formActions.setOpexesAreValid(rowOpexesAreValid))
    }, [opexNameIsValid, opexIsValid, opexInflationIsValid])

    function opexOnChange(e: string, index: number) {
        // Basic Validation
        let newOpex = [...opexes]
        newOpex[index].opex = e
        setOpexes(newOpex);
    }

    function opexNameOnChange(e: string, index: number) {
        // Basic Validation
        let newOpex = [...opexes]
        newOpex[index].opexName = e
        setOpexes(newOpex);
    }

    function opexInflationOnChange(e: string, index: number) {
        // Basic Validation
        let newOpex = [...opexes]
        newOpex[index].opexAverageInflation = e
        setOpexes(newOpex);
    }

    function deleteRow(opexIndexToDelete: number) {
        // need to send injection point with Opex removed
        let updatedPoints: InjectionPoint[] = injectionPoints
        injectionPoints.map((oldInjectionPoints, index) => {
            if (oldInjectionPoints.id === opexes[opexIndexToDelete].id) {
                updatedPoints[index] = oldInjectionPoints
                updatedPoints[index].opexEntries = null
            }
        })
        setOpexes(deleteLineFromArray(opexes, opexIndexToDelete))
        setOpexIsValid(deleteLineFromArray(opexIsValid, opexIndexToDelete))
        setOpexNameIsValid(deleteLineFromArray(opexNameIsValid, opexIndexToDelete))
        setOpexInflationIsValid(deleteLineFromArray(opexInflationIsValid, opexIndexToDelete))
        dispatch(formActions.setOpexesAreValid(deleteLineFromArray(opexesAreValid, opexIndexToDelete)))
    }

    function deleteLineFromArray(array: any[], index: number) {
        return [array.slice(0, index), array.slice(index + 1, array.length + 1)].flat()
    }

    function injectionPointOpexOnChange(e: React.ChangeEvent<HTMLSelectElement>, index: number) {
        let newInjectionOpex = [...opexes]
        newInjectionOpex[index].id = e.target.value
        setOpexes(newInjectionOpex)
    }


    function addOpex() {
        let newOpex = [...opexes];
        let restInjectionPointOpex = allInjectionPointForOpex

        let alreadyUsedInjectionPointOpex: string[] = opexes.map((op: any) => op.id)
        let newAvailablePoint: any = null
        restInjectionPointOpex.forEach(point => {
            if (!alreadyUsedInjectionPointOpex.includes(point.id) && point.id !== "Opex global") {
                newAvailablePoint = point
            }
        })
        newOpex.push({
            id: newAvailablePoint ? newAvailablePoint.id : "Opex global",
            opex: "0",
            opexName: newAvailablePoint ? "Opex " + newAvailablePoint.name : "Opex global",
            opexAverageInflation: "1.5",
        })
        setOpexes(newOpex)
    }

    useEffect(() => {

        let opexesToUpdate = [...opexes]
        let newOpexIsValid = [...opexIsValid]
        let newOpexNameIsValid = [...opexNameIsValid]
        let newOpexInflationIsValid = [...opexInflationIsValid]
        if (opexes.length > 0) {
            opexes.map((op: OpexFormType, index: number) => {
                newOpexIsValid[index] = !isNaN(Number(op.opex)) && Number(op.opex) >= 0
                newOpexInflationIsValid[index] = !isNaN(Number(op.opexAverageInflation)) && Number(op.opexAverageInflation) >= 0 && Number(op.opexAverageInflation) <= 100
                newOpexNameIsValid[index] = op.opexName.length >= 2 && opexes.filter((opex) => opex.id === op.id && opex.opexName === op.opexName).length <= 1
            })
            setOpexIsValid(newOpexIsValid)
            setOpexInflationIsValid(newOpexInflationIsValid)
            setOpexNameIsValid(newOpexNameIsValid)
        }
        dispatch(formActions.setOpexes(opexesToUpdate))
    }, [opexes])


    useEffect(() => {
        // copie du store dans formulaire
        if (storeFinancialParameters) {

            let opexesRetrieve: any[] = []
            storeFinancialParameters && storeFinancialParameters.globalOpexEntries && storeFinancialParameters.globalOpexEntries.map((globalOpex) => {
                opexesRetrieve.push({
                    id: "Opex global",
                    opex: globalOpex.opex,
                    opexName: globalOpex.opexName,
                    opexAverageInflation: globalOpex.opexAverageInflation
                })
            })

            injectionPoints.length > 0 && injectionPoints.map((point: InjectionPoint) => {

                if (point.opexEntries) {
                    point.opexEntries.map((opexEntrie) => {
                        opexesRetrieve.push({
                            id: point.id,
                            opexName: opexEntrie.opexName,
                            opex: opexEntrie.opex,
                            opexAverageInflation: opexEntrie.opexAverageInflation
                        })
                    })

                }
            })
            setOpexes(opexesRetrieve)
        }
    }, [storeFinancialParameters])

    function isPointEnabled(injectionPointId: string): boolean {
        return injectionPointId === "Opex global" || injectionPoints.some((point) => point.id === injectionPointId && point.state)
    }

    return <div className="right-6 2xl:right-0 w-full">

        <div className="bg-white rounded-md shadow box-border dark:bg-zinc-600">
            <div className={"flex justify-between items-center"}>
                <TitleText title={"Opex"} textSize={"text-sm"}
                           typeOfMargin={"small"}/>
                <div className={"w-min mr-4"}>
                    <RegularBtn
                        dataCy={'financial-params-add-opexes'}
                        title={"Ajouter un Opex"}
                        theme={Theme.SECONDARY} state={ButtonState.REGULAR} action={() => addOpex()}/></div>
            </div>
            <hr className={"w-full border dark:border-zinc-800"}/>
            <div className={"overflow-x-auto "}>
                {opexes.length > 0 ?
                    <table className="bg-white shadow-md dark:bg-zinc-800 w-full  table-auto rounded-lg ">
                        <thead className={"sticky top-0"}>
                        <tr className="bg-slate-100   text-gray-500 text-sm  dark:text-zinc-300 dark:hover:bg-zinc-800  dark:bg-zinc-800 ">
                            <th align={"left"} className=" py-5 px-4 bg-slate-100 dark:bg-zinc-800   ">
                                Affectation
                            </th>
                            <th title={"Le nom doit comporter au moins 2 caractères ou deux champs comporte le même nom"}
                                align={"right"} className=" py-5 px-4 bg-slate-100 dark:bg-zinc-800   ">
                                <div
                                    className={nameError ? "text-red-400 dark:text-red-500 flex items-center justify-end" : "flex items-center justify-end"}>
                                    Nom
                                    {nameError && <InformationCircleIcon width={"w-4 h-4"}
                                                                         textcolor={"text-red-400 dark:text-red-500"}/>}
                                </div>
                            </th>
                            <th title={"Le prix doit être un nombre positif"} align={"right"}
                                className=" py-5 px-4 bg-slate-100 dark:bg-zinc-800 ">
                                <div
                                    className={priceError ? "text-red-400 dark:text-red-500 flex items-center justify-end" : "flex items-center justify-end"}>
                                    Prix (€ HT /an)
                                    {priceError && <InformationCircleIcon width={"w-4 h-4"}
                                                                          textcolor={"text-red-400 dark:text-red-500"}/>}
                                </div>

                            </th>
                            <th title={"L'inflation est un nombre entre 0 et 100"} align={"right"}
                                className=" py-5 px-4 bg-slate-100 dark:bg-zinc-800 ">
                                <div
                                    className={inflationError ? "text-red-400 dark:text-red-500 flex items-center justify-end" : "flex items-center justify-end"}>
                                    Inflation (%)
                                    {inflationError && <InformationCircleIcon width={"w-4 h-4"}
                                                                              textcolor={"text-red-400 dark:text-red-500"}/>}
                                </div>
                            </th>
                            <th align={"left"} className=" py-5 px-2 w-min bg-slate-100 dark:bg-zinc-800 ">
                                Action
                            </th>
                        </tr>
                        </thead>
                        <tbody
                            className="text-gray-600 text-xs font-light dark:text-zinc-300 ">
                        <tr className={"bg-white dark:bg-zinc-800 py-1 h-2"}/>
                        {opexes.map((op: OpexFormType, index: number) => {
                            const disabledStyle: string = isPointEnabled(op.id) ? "" : " opacity-40 "
                            return <>
                                <tr className={opexesAreValid[index] ? " bg-white  dark:bg-zinc-800 " + disabledStyle : "bg-red-50 dark:bg-red-800 dark:bg-opacity-20 " + disabledStyle}>
                                    <td align={"left"}
                                        className={"  text-sm "}>
                                        <div className={"ml-2 "}>
                                            <SelectorInputForOpex valueId={op.id}
                                                                  onChange={(e) => injectionPointOpexOnChange(e, index)}
                                                                  id={"select-opex-" + index}
                                                                  options={allInjectionPointForOpex}/>
                                        </div>
                                    </td>
                                    <td className={""}>
                                        <TextInputForTable
                                            id={"opex-name-"+index}
                                            index={index} loading={isFormLoading}
                                            onChange={opexNameOnChange}
                                            value={op.opexName}
                                            isValid={opexNameIsValid[index]}/>
                                    </td>
                                    <td className={"ml-2 "}>
                                        <TextInputForTable
                                            isAFloat loading={isFormLoading}
                                            id={"opex-price-"+index}
                                            index={index}
                                            onChange={opexOnChange}
                                            value={op.opex}
                                            isValid={opexIsValid[index]}/>

                                    </td>
                                    <td className={"ml-2 "}>
                                        <TextInputForTable
                                            isAFloat loading={isFormLoading}
                                            id={"opex-inflation-"+index}
                                            index={index}
                                            onChange={opexInflationOnChange}
                                            value={op.opexAverageInflation}
                                            isValid={opexInflationIsValid[index]}/>

                                    </td>
                                    <td>
                                        <div
                                            className={" ml-4 w-min  text-center  rounded-md border-2  p-1 hover:cursor-pointer hover:bg-red-200 border-red-100 hover:border-red-500 bg-red-100 dark:hover:bg-red-700 dark:border-red-800  dark:bg-red-800"}
                                            onClick={() => deleteRow(index)}>
                                            <XIconRed/>
                                        </div>
                                    </td>
                                </tr>
                                {opexes[index] && opexes[index + 1] && opexes[index].id !== opexes[index + 1].id &&
                                <tr className={"bg-white dark:bg-zinc-800 h-2"}/>}
                            </>
                        })
                        }
                        </tbody>
                    </table>
                    :
                    <EmptyTable title={"Pas d'Opex"}
                                description={"Ajouter des opex avec le bouton 'Ajouter un opex' au dessus du tableau"}/>}
            </div>
        </div>
        <hr className={"w-full  border-0 py-1 rounded-b bg-white dark:bg-zinc-800"}/>
    </div>
}
