import React, {useRef, useState} from "react";
import DayInput from "./DayInput";
import DateDivider from "../../DateDivider";
import MonthInput from "./MonthInput";
import YearsInput from "./YearsInput";

export default function DateInput({setMouseHasLeft}: { setMouseHasLeft(hasLeft: boolean): void }) {
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
    const [focused, setFocused] = useState(false)

    const handleOnBlur = () => {
        setMouseHasLeft(true)
        setFocused(false)
    }
    const handleInput = (e: React.ChangeEvent<HTMLInputElement>, currentIndex: number) => {
        const inputValue = e.target.value;
        if (inputValue.length === 2) {
            const nextIndex = currentIndex + 1;
            const nextInput = inputRefs.current[nextIndex];
            if (nextInput) {
                nextInput.focus();
            }
        }
    };
    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        if (inputValue.length > 0) {
            e.target.select();
        }
    };

    return <div
        className={"px-2 text-gray-700 dark:text-gray-200 max-w-[190px]  py-1 rounded bg-gray-100 tracking-wide dark:bg-purple-800 dark:bg-opacity-20 "
        + (focused ? " border-2 border-purple-600 dark:border-purple-300  " : " border border-gray-200 dark:border-gray-700 ")}>
        <DayInput handleInput={handleInput} handleFocus={handleFocus} inputRefs={inputRefs}
                  handleOnBlur={handleOnBlur} setFocused={setFocused}/>
        <DateDivider/>
        <MonthInput handleInput={handleInput} handleFocus={handleFocus} inputRefs={inputRefs}
                    handleOnBlur={handleOnBlur} setFocused={setFocused}/>
        <DateDivider/>
        <YearsInput handleInput={handleInput} handleFocus={handleFocus} inputRefs={inputRefs}
                    handleOnBlur={handleOnBlur} setFocused={setFocused}/>
    </div>

}
