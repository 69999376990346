import { all, call, getContext, put, select, takeLatest } from 'redux-saga/effects';

import { metricsNature } from '../../models/types/physicalRestitution/NatureEnum';
import { selectSelectedSimulationId } from '../new-simulation/new-simulation-selector/form/newSimulationSelector';
import { PhysicalRestitutionGatewayInterface } from '../physicalRestitution/physicalRestitutionGateway.interface';
import {
    ExecutionStatus,
    GET_SIMULATION_EXECUTION_STATUS,
    simulationExecutionStatusActions,
} from './simulationExecutionStatusActions';

function* getSimulationExecutionStatus(): any {
    yield put(simulationExecutionStatusActions.setSimulationExecutionStatus(ExecutionStatus.running));
    const simulationId: string = yield select(selectSelectedSimulationId);
    if (!simulationId) {
        return;
    }
    const physicalRestitutionGateway: PhysicalRestitutionGatewayInterface = yield getContext(
        'physicalRestitutionGatewayContext',
    );

    try {
        yield call(physicalRestitutionGateway.getMetricValue, simulationId, metricsNature.totalConsoAcc);
        yield put(simulationExecutionStatusActions.setSimulationExecutionStatus(ExecutionStatus.finished));
    } catch (error: any) {}
}

function* getSimulationExecutionStatusSaga() {
    yield takeLatest(GET_SIMULATION_EXECUTION_STATUS, getSimulationExecutionStatus);
}

export const simulationExecutionStatusSagas = [getSimulationExecutionStatusSaga];
