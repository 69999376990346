import {Curve} from "./Curve";

export class CurveLoaded {
     _status: string
     private _messages : string[]
     private _curveId : string

    constructor(status: string, messages: string[], curveId: string) {
        this._status = status;
        this._messages = messages;
        this._curveId = curveId;
    }

    get status(): string {
        return this._status;
    }

    set status(value: string) {
        this._status = value;
    }

    set messages(value: string[]) {
        this._messages = value;
    }

    get curveId(): string {
        return this._curveId;
    }

    set curveId(value: string) {
        this._curveId = value;
    }

    get messages(): string[] {
        return this._messages;
    }


}