import {Actions, RESET_ERROR, SET_ERROR} from "./errorsActions";

const initialState:any = null

const errorsReducer = (state = initialState, action: Actions) => {
    switch (action.type) {
        case SET_ERROR:
            return action.payload;
        case RESET_ERROR:
            return null;
        default:
            return state;
    }
}

export default errorsReducer;