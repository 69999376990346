type ConfirmChangeRouteType = {
    title: string,
    content: any,
    actionContinue() : void,
    actionCancel(): void,
}

export default function ({title, content, actionContinue, actionCancel}: ConfirmChangeRouteType) {

    return <div id="defaultModal" tabIndex={-1} aria-hidden="true"
                className="transition duration-150 ease-in-out z-20 overflow-y-auto overflow-x-hidden bg-gray-400 fixed bg-opacity-70 w-screen h-screen  z-50 h-modal md:h-full dark:bg-zinc-600 dark:bg-opacity-70">
        <div className="scale-95 2xl:scale-100 relative p-4 w-1/2 h-full md:h-auto fixed 2xl:top-[20vh] top-0  self-center left-[10%] 2xl:left-[25%] w-[900px] ">
            <div className="relative min-w-fit bg-white rounded-lg shadow dark:bg-zinc-800">
                <div className="flex justify-between items-start p-5 rounded-t border-b dark:border-gray-600">
                    <h3 className="text-md font-semibold text-gray-900 lg:text-xl dark:text-white">
                        {title}
                    </h3>
                    <button onClick={actionCancel} type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            data-modal-toggle="defaultModal">
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd"
                                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                  clipRule="evenodd"></path>
                        </svg>
                    </button>
                </div>
                <div className="p-6 space-y-6 min-w-fit">
                    <div className="min-w-fit text-base whitespace-pre-wrap leading-relaxed text-gray-500 dark:text-gray-400">
                        {content}
                    </div>
                </div>
                <div
                    className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                    <button data-cy={"confirmation-dialog-true"} onClick={actionContinue}  className="bg-blue-800 text-white hover:bg-blue-700 hover:cursor-pointer focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">
                        Continuer
                    </button>
                    <button data-cy={'change-page-without-data-cancel'} onClick={actionCancel} data-modal-toggle="defaultModal" type="button"
                            className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">Annuler
                    </button>
                </div>
            </div>
        </div>
    </div>
}
