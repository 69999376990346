import {
    ADD_EXTERNAL_INVESTMENT,
    newSimulationActionsType,
    REMOVE_EXTERNAL_INVESTMENT, SET_EXTERNAL_INVESTMENTS,
    SHOW_EXTERNAL_INVESTMENT
} from "../../newSimulationActions";
import {
    ExternalInvestment
} from "../../../../../adapters/primary/ui/composition/balance-sheet/external-investment-form/ExternalInvestment";

const initialExternalInvestmentFormState : {show : boolean, investments:ExternalInvestment[]} = {
    show : false,
    investments: [],
}

const externalInvestmentFormReducer= (state = initialExternalInvestmentFormState, action: newSimulationActionsType) => {
    switch (action.type) {
        case SHOW_EXTERNAL_INVESTMENT :
            return {...state, show : action.payload}
        case ADD_EXTERNAL_INVESTMENT:
            return {
                ...state,
                investments: [...state.investments, action.payload],
            };
        case REMOVE_EXTERNAL_INVESTMENT:
            return {
                ...state,
                investments: state.investments.filter(
                    (investment) => investment.id !== action.payload.id
                ),
            };
        case SET_EXTERNAL_INVESTMENTS:
            return {
                ...state,
                investments: action.payload,
            };
        default:
            return state;
    }

}
export default externalInvestmentFormReducer