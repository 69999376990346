import {useDispatch, useSelector} from "react-redux";
import {
    makeIsRecalageEnergyButtonValid,
    makeIsRecalageEnergyValid,
    selectRecalageEnergy
} from "../../../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import {useCallback} from "react";
import {PlayIconSolid} from "../../../../../../components/assets/icon/PlayIcon";
import {newSimulationActions} from "../../../../../../../../../corelogic/usecases/new-simulation/newSimulationActions";


export default function ApplyRecalageButton() {
    const dispatch = useDispatch()
    const recalageEnergy:string = useSelector(selectRecalageEnergy)
    const selectIsButtonValid = useCallback(makeIsRecalageEnergyButtonValid(recalageEnergy), [recalageEnergy])
    const isInputValid = useSelector(selectIsButtonValid)
    const inputValidStyle=  isInputValid ? ' opacity-80 hover:opacity-100 hover:cursor-pointer ' : ' opacity-40 '

    function applyRecalageIfEnable(){
        if(isInputValid){
            dispatch(newSimulationActions.applyRecalage())
        }
    }
    return <div
        className={'bg-indigo-600 rounded   px-2 py-1  text-white text-sm font-semibold' + inputValidStyle}
        onClick={applyRecalageIfEnable}
    >
        <span className={"flex gap-2 items-center"}>
             Recaler la courbe de charge
            <PlayIconSolid className={'text-white h-5 w-5'}/>
        </span>
    </div>
}
