import Badge from "../../../../components/assets/tag/Badge";
import {yellow} from "../../../../../../../config/app-config";
import {BoltIconOutlined} from "../../../../components/assets/icon/BoltIcon";
import React from "react";
import {useSelector} from "react-redux";

import withConditionalRender from "../../../wrapper/withConditionalRender";
import {
    selectDisplayMaxPowerBadge,
    selectNewCurve
} from "../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-curve/selectFormCurve";

function MaxPowerBadge(){
    const newCurve = useSelector(selectNewCurve)
    const maxPowerInW = newCurve?.maxPower.toFixed(0) || 0

    return <span title={`Puissance maximale de ${maxPowerInW} W`}>
                <Badge enolabColor={yellow} text={`${maxPowerInW} W`}
                       Icon={() => <BoltIconOutlined className={"h-4 w-4 text-yellow-800 dark:text-yellow-300"}/>}/>
            </span>
}
export default withConditionalRender(MaxPowerBadge, selectDisplayMaxPowerBadge)
