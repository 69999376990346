import {ProdType} from "../../../../containers/BalanceSheet";
import {useDispatch, useSelector} from "react-redux";
import React, {useCallback, useEffect} from "react";
import {
    makeSelectCanViewExternalInvestment, makeSelectIsExternalInvestHybrid
} from "../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectInjectionPointForm";
import {
    makeSelectInvestorProducerTRA, makeSelectInvestorProducerTRI,
    selectInvestorProducerInternalValuation,
    selectInvestorProducerLCOE,
    selectInvestorProducerTRA,
    selectInvestorProducerTRI
} from "../../../../../../../corelogic/usecases/financialRestitution/financialRestitutionSelector";
import {
    selectIsOpenOperation
} from "../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectGeneralInfoForm";
import {
    financialRestitutionActions
} from "../../../../../../../corelogic/usecases/financialRestitution/financialRestitutionActions";
import CardsBalance from "../../CardsBalance";
import InvestorProducerOpenTable from "./table/InvestorProducerOpenTable";
import InvestorProducerSocialPatriTable from "./table/InvestorProducerSocialPatriTable";
import {
    selectStoredExternalInvestment
} from "../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectExternalInvestment";
import { useSimulationExecutionStatus } from "../../../../utils/useSimulationExecutionStatus";
import { ExecutionStatus } from "../../../../../../../corelogic/usecases/simulationExecutionStatus/simulationExecutionStatusActions";

const InvestProducerView = ({selectedType, cashFlowTableRef, id, selectedView}: { selectedType: ProdType, cashFlowTableRef:any, id:string, selectedView:boolean }) => {

    const dispatch = useDispatch();
    const externalInvestment = useSelector(selectStoredExternalInvestment)
    const selectCanViewExternalInvestment = useCallback(makeSelectCanViewExternalInvestment(selectedType.id), [selectedType])
    const canViewExternalInvestment: boolean = useSelector(selectCanViewExternalInvestment)

    const selectTRI = useCallback(makeSelectInvestorProducerTRI(selectedType.id), [selectedType])
    const TRI = useSelector(selectTRI)

    const selectTRA = useCallback(makeSelectInvestorProducerTRA(selectedType.id), [selectedType])
    const TRA = useSelector(selectTRA)

    const lcoe = useSelector(selectInvestorProducerLCOE)
    const internalValuation = useSelector(selectInvestorProducerInternalValuation)

    const isOpenOperation: boolean = useSelector(selectIsOpenOperation)
    const executionStatus = useSimulationExecutionStatus();

    useEffect(() => {
        if(canViewExternalInvestment && selectedView && executionStatus === ExecutionStatus.finished){
            dispatch(financialRestitutionActions.getExternalInvestmentProducerBalanceMetrics(selectedType.scope !== "global" ? selectedType.id : undefined));
            dispatch(financialRestitutionActions.getExternalInvestmentProducerCashFlowData(selectedType.scope !== "global" ? selectedType.id : undefined));
        }
    }, [selectedType, id, canViewExternalInvestment, externalInvestment, selectedView, executionStatus]);

    return <div className={'bg-slate-50  dark:bg-zinc-800 flex flex-col gap-4 '}>
        <CardsBalance tri={TRI} tra={TRA} lcoe={lcoe} internalValuation={internalValuation} />
        <div ref={cashFlowTableRef}>
            {
                isOpenOperation ?
                    <InvestorProducerOpenTable selectedType={selectedType} id={id}/> :
                    <InvestorProducerSocialPatriTable selectedType={selectedType} id={id}/>
            }
        </div>
    </div>
}
export default InvestProducerView;