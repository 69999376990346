import React from "react";
import {HeatingType} from "../../../../../../../corelogic/models/types/new-simulation/form/HeatingType";
import {AccommodationType} from "../../../../../../../corelogic/models/types/new-simulation/form/AccommodationType";
import {useDispatch, useSelector} from "react-redux";
import SelectorInput from "../../../../components/assets/FormAssets/selector/SelectorInput";
import RegularBtn from "../../../../components/assets/button/RegularBtn";
import {ButtonState, Theme} from "../../../../../../../config/app-config";
import {newSimulationActions} from "../../../../../../../corelogic/usecases/new-simulation/newSimulationActions";
import DownloadIcon from "../../../../components/assets/icon/DownloadIcon";
import {selectIsLoadingNewCurve} from "../../../../../../../corelogic/usecases/loader/loaderSelector";
import {
    selectAccommodationType,
    selectHeatingType
} from "../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import {typologyFormActions} from "../../../../../../../corelogic/usecases/typology-form/typologyFormActions";
import LoadSpinner from "../../../../components/assets/Animated/LoadSpinner";

export default function EnedisCDCForm() {
    const dispatch = useDispatch()

    const heatingType = useSelector(selectHeatingType)
    const accommodationType = useSelector(selectAccommodationType)
    const loading = useSelector(selectIsLoadingNewCurve)

    function heatingTypeOnChange(e: React.ChangeEvent<HTMLSelectElement>) {
        switch (e.target.value) {
            case HeatingType.gaz :
                dispatch(typologyFormActions.setHeatingType(HeatingType.gaz));
                break;
            case HeatingType.electricity :
                dispatch(typologyFormActions.setHeatingType(HeatingType.electricity));
                break;
            case HeatingType.other :
                dispatch(typologyFormActions.setHeatingType(HeatingType.other));
                break;
            case HeatingType.wood :
                dispatch(typologyFormActions.setHeatingType(HeatingType.wood));
                break;
            case HeatingType.collectivity :
                dispatch(typologyFormActions.setHeatingType(HeatingType.collectivity));
                break;
            default :
                break;
        }
    }
    function accommodationTypeOnChange(e: React.ChangeEvent<HTMLSelectElement>) {
        switch (e.target.value) {
            case AccommodationType.individual :
                dispatch(typologyFormActions.setAccommodationType(AccommodationType.individual));
                break;
            case AccommodationType.T1 :
                dispatch(typologyFormActions.setAccommodationType(AccommodationType.T1));
                break;
            case AccommodationType.T2 :
                dispatch(typologyFormActions.setAccommodationType(AccommodationType.T2));
                break;
            case AccommodationType.T3 :
                dispatch(typologyFormActions.setAccommodationType(AccommodationType.T3));
                break;
            case AccommodationType.T4 :
                dispatch(typologyFormActions.setAccommodationType(AccommodationType.T4));
                break;
            case AccommodationType.T5 :
                dispatch(typologyFormActions.setAccommodationType(AccommodationType.T5));
                break;
            default :
                break;
        }
    }
    function onClick(){
        if(!loading){
            dispatch(newSimulationActions.modelEnedis({accommodationType: accommodationType, heatingType: heatingType || '',}))
        }
    }
    return <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-x-4 items-end">
        <SelectorInput dataCy={"typology-consumption-heat-type"} value={heatingType} id={"select-type"}
                       onChange={heatingTypeOnChange}
                       label={"Type de chauffage"}
                       options={Object.values(HeatingType)}/>
        <SelectorInput dataCy={"typology-consumption-accommodation-type"} value={accommodationType}
                       id={"select-segment"}
                       onChange={accommodationTypeOnChange} label={"Type de logement"}
                       options={Object.values(AccommodationType)}/>
        <div className={"mb-1"}>
            <RegularBtn
                title={loading ? "Récupération en cours ..." : "Récupérer"}
                loading={loading}
                state={ButtonState.REGULAR}
                action={onClick}
                IconComponent={() => loading ? <LoadSpinner/> : <DownloadIcon className={"h-6 w-6 "}/>}
                theme={Theme.DEFAULT} />
        </div>
    </div>

}


