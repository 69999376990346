import {InformationCircleIcon} from "../../components/assets/icon/InformationCircleIcon";
import {useEffect, useState} from "react";

type NewReleaseWrapperType = {
    children: JSX.Element,
    title?: string,
    tooltipMessage?: string,
    relativePosition?:string
}

function NewReleaseWrapper({children, title, tooltipMessage, relativePosition}: NewReleaseWrapperType) {
    const [maxHeightToolTip, setMaxHeightToolTip] = useState<string>(" max-h-0 ")
    const [tooltip, setTooltip] = useState<boolean>(false)
    const position = relativePosition ? relativePosition : " bottom-6 right-5 "
    const titleStyle = title ? " px-2 py-1 rounded-sm bg-red-200 dark:bg-red-800 " : " p-1 rounded-full bg-red-500 dark:bg-red-800 "
    useEffect(() => {
        if(tooltipMessage){
            if (tooltip) {
                setMaxHeightToolTip(' max-h-[300px] h-min ')
            } else {
                setMaxHeightToolTip(' max-h-0 h-0 ')
            }
        }
    }, [tooltip, tooltipMessage])
    return <div className={"flex flex-col h-full"}>
        <div className={"w-0 h-0 z-40 "}>
            <div onMouseEnter={() => setTooltip(true)} onMouseLeave={() => setTooltip(false)}
                 className={position + titleStyle + "transition flex w-max origin-top-left text-red-500 items-center  min-h uppercase  relative shadow-lg  hover:cursor-default dark:text-red-300  "}>
                {title && <span className={' font-bold text-[0.6rem] tracking-widest self-center'}>{title}</span>}
                {tooltipMessage && <InformationCircleIcon width={' w-3 h-3 '} textcolor={'text-red-500'}/>}
            </div>
            {tooltipMessage && tooltip && <div
                className={maxHeightToolTip + position +" w-[200px] relative z-40 origin-bottom-left transition-expand text-red-500 bg-red-100 text-xs rounded-sm px-4 py-1 dark:text-red-400 dark:bg-red-800 "}>{tooltipMessage}</div>}
        </div>
        {children}
    </div>
}

export {NewReleaseWrapper}
