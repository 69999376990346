export class PhotovoltaicModel {
    private _orientation : string
    private _inclinaison : string
    private _loss : string

    constructor(orientation: string, inclinaison: string, loss : string) {
        this._orientation = orientation;
        this._inclinaison = inclinaison;
        this._loss = loss;
    }

    get loss(): string {
        return this._loss;
    }

    set loss(value: string) {
        this._loss = value;
    }

    get orientation(): string {
        return this._orientation;
    }

    set orientation(value: string) {
        this._orientation = value;
    }

    get inclinaison(): string {
        return this._inclinaison;
    }

    set inclinaison(value: string) {
        this._inclinaison = value;
    }
}