import React, {useEffect, useState} from "react";
import {Organisation} from "../../../../../../../corelogic/models/types/simulations-display/Organisation";
import {useDispatch, useSelector} from "react-redux";
import {selectSearch} from "../../../../../../../corelogic/usecases/faq/faqSelector";
import {faqActions} from "../../../../../../../corelogic/usecases/faq/faqActions";
import ChevronDown from "../../icon/ChevronDown";
import ChevronRight from "../../icon/ChevronRight";
import {MagnifyingOutlinedIcon} from "../../icon/MagnifyingGlass";
import {selectIsLoadingSimulationForm} from "../../../../../../../corelogic/usecases/loader/loaderSelector";

type SelectorInputType = {
    label?: string,
    options: Organisation[],
    id: string,
    onChange: (id : string) => void,
    valueId: string
}
export default function OrganisationsSelectorInput({label, options, id, onChange, valueId}: SelectorInputType) {
    const [optionsFiltered, setOptionsFiltered] = useState<Organisation[]>(options)
    const [showOptions, setShowOptions] = useState(false)

    const organisationSearched = useSelector(selectSearch)
    const isFormLoading  = useSelector(selectIsLoadingSimulationForm)

    useEffect(() => {
        if (organisationSearched.length > 0) {
            let optionsWithSearchedWord = options.filter((organisation: Organisation) => organisation.name.toLowerCase().includes(organisationSearched.toLowerCase()))
            setOptionsFiltered(optionsWithSearchedWord || [])
        } else {
            setOptionsFiltered(options)
        }
    }, [organisationSearched])



    const selectedOrganisationName = (fromOrganisationId : string):string => {
        return options.find((option)=> option.id === fromOrganisationId)?.name || ''
    }

    return <div className="w-full block uppercase tracking-wide text-gray-600  ">
        {label && <span className=" text-gray-700 font-bold text-xs dark:text-gray-200">{label}</span>}

        <div id={id}
             onClick={() => setShowOptions(!showOptions)}
             className={label ?
                 "form-select flex justify-between py-2 px-4 text-sm font-semibold opacity-80 hover:opacity-100 hover:cursor-pointer  block w-full text-base bg-slate-200 dark:text-gray-200 dark:bg-zinc-600  text-gray-700  leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                 : "form-select flex justify-between py-2 px-4 text-sm font-semibold opacity-80 hover:opacity-100 hover:cursor-pointer   block w-full text-base bg-slate-100 dark:text-gray-200 dark:bg-zinc-700  text-gray-700  leading-tight focus:outline-none focus:bg-white focus:border-gray-500"}>
            {isFormLoading ? 'Chargement ...' : selectedOrganisationName(valueId)}
            {
                showOptions ? <ChevronDown classname={' min-h-5 min-w-5 h-5 w-5 text-gray-800 dark:text-gray-100'}/> :
                <ChevronRight className={' min-h-5 min-w-5 h-5 w-5 text-gray-800 dark:text-gray-100'}/>
            }
        </div>
        {showOptions && <SearchBarWithOptions optionsFiltered={optionsFiltered} setShowOptions={setShowOptions} onChange={onChange}/>}
    </div>
}
const SearchBarWithOptions = ({optionsFiltered, setShowOptions, onChange}:{optionsFiltered:Organisation[], setShowOptions(opt:boolean):void,  onChange: (id : string) => void}) => {
    function handleOnOptionClick(option:Organisation, disabled : boolean){
        if(!disabled){
            onChange(option.id)
            setShowOptions(false)
        }
    }

    return <div className={"bg-slate-200  dark:bg-zinc-600 mt-1"}>
        <SearchBar/>
        {optionsFiltered.map((option: Organisation) => {
            const disabled = option.remaining_number_of_simulations === 0
            return <div className={disabled ?
                "opacity-60 hover:bg-slate-300 hover:dark:bg-zinc-500 form-select py-2 px-4 text-sm font-semibold  block w-full text-base dark:text-gray-200   text-gray-700  leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                :"opacity-80 hover:bg-slate-300 hover:dark:bg-zinc-500 hover:opacity-100 hover:cursor-pointer form-select py-2 px-4 text-sm font-semibold  block w-full text-base dark:text-gray-200   text-gray-700  leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            }
                        onClick={() => handleOnOptionClick(option, disabled) }>
                {option.name + " (" + option.remaining_number_of_simulations + " crédits restant)"}
            </div>
        })}
    </div>
}
const SearchBar = () => {
    const dispatch = useDispatch()
    const searchingText = useSelector(selectSearch)

    function handleOnChange(e: any) {
        dispatch(faqActions.searchOnType(e.target.value))
    }

    return <form className={"w-full p-2"}>
        <label htmlFor="default-search"
               className="mb-2   text-sm font-medium text-gray-900 sr-only dark:text-white">Recherchez une
            organisation par son nom ...</label>
        <div className="relative w-full ">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <MagnifyingOutlinedIcon className={"w-4 h-4 text-gray-500 dark:text-gray-400"}/>
            </div>
            <input
                onChange={handleOnChange}
                value={searchingText || ''}
                type="search" id="default-search"
                className="block w-full px-4 py-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:focus:ring-none"
                placeholder="Recherchez une
            organisation par son nom ..."/>
        </div>
    </form>

}
