import {FunctionComponent} from "react";
import {InformationCircleIconOrangeOnHover} from "../../components/assets/icon/InformationCircleIcon";

type Props = {
    Icon: FunctionComponent
    color: string
    title: string
    value: string
    loading : boolean,
    tooltip ?: string,
    dataCy?:string
}
const Card = ({Icon, color, title, value, loading, tooltip, dataCy}: Props) => {

    return <div title={tooltip}
                data-cy={dataCy}
        className={`min-w-0 rounded-lg overflow-ellipsis overflow-hidden shadow-sm hover:shadow-lg hover:bg-${color}-50 overflow-hidden bg-white dark:bg-zinc-700 dark:shadow-lg dark:hover:bg-zinc-600`}>
        <div className="p-4 flex items-left">
            <div
                className={`animate-pulse p-6 h-min rounded-full text-${color}-500 dark:text-indigo-100 bg-slate-100 dark:bg-indigo-400 mr-4`}>
                <Icon/>
            </div>
            <div className={"w-full"}>
                <div className={"flex align-center justify-between w-full"}>
                    <p className=" mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">{title}</p>
                    {tooltip && InformationCircleIconOrangeOnHover()}
                </div>

                {loading ?
                    <p className={`text-xl w-20 h-5 mt-4 rounded-xs font-semibold bg-slate-100 dark:bg-zinc-600 dark:bg-${color}-200`}/>
                    : <p className="text-xl  font-semibold text-gray-700 dark:text-gray-200">{value ? value : "-"}</p>}
            </div>
        </div>


    </div>
}

export default Card
