import ChevronRight from "../../components/assets/icon/ChevronRight";
import ChevronDown from "../../components/assets/icon/ChevronDown";
import {Question} from "./AllQuestions";
import {faqActions} from "../../../../../corelogic/usecases/faq/faqActions";
import {useDispatch, useSelector} from "react-redux";
import {makeIsQuestionExpanded, selectQuestionsSorted} from "../../../../../corelogic/usecases/faq/faqSelector";
import {useCallback} from "react";
import {selectIsDarkMode} from "../../../../../corelogic/usecases/config/configSelector";

const FAQResults = () => {
    const questions = useSelector(selectQuestionsSorted)
    return <div className={" w-[1000px]  flex flex-col gap-2 h-min"}>
        {questions.map((question: Question) => <Result question={question}/>)}
    </div>
}
const Result = ({question}: { question: Question }) => {
    const dispatch = useDispatch()
    const selectQuestionExpand = useCallback(makeIsQuestionExpanded(question), [question])
    const expand = useSelector(selectQuestionExpand)
    const isDarkMode = useSelector(selectIsDarkMode)
    return <div
        className={"flex flex-col gap-1   rounded-sm"}>
        <div
            onClick={() => dispatch(faqActions.expandQuestionById(question.id))}
            className={"px-2 py-3 w-full flex justify-between hover:bg-gray-200 dark:hover:bg-gray-700 hover:cursor-pointer "}>
            <span className={" text-gray-800 dark:text-gray-100 text-lg"}>{question.title}</span>
            {expand ? <ChevronDown classname={' min-h-6 min-w-6 h-6 w-6 text-gray-800 dark:text-gray-100'}/> :
                <ChevronRight className={' min-h-6 min-w-6 h-6 w-6 text-gray-800 dark:text-gray-100'}/>}
        </div>
        {expand && question.svg && <div className={"flex justify-center"}>{isDarkMode ? question.svg.dark : question.svg.light}</div>}
        {expand && question.description.map((desc) => <div className={"pl-3 pr-6  text-sm text-gray-600 dark:text-gray-300 font-light"}>{desc}</div>)}
    </div>
}

export default FAQResults
