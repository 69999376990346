import {put, select, takeLatest} from "redux-saga/effects";
import {snackBarErrorsActions} from "../errors/snackbarErrorsActions";
import {INIT_OPERATION_PARAMETERS_FORM, operationParametersFormActions,} from "./operationParametersFormActions";
import {selectOperationParameters} from "../new-simulation/new-simulation-selector/form-data/selectOperationParameter";
import {selectConsumptionPoints} from "../new-simulation/new-simulation-selector/form-data/selectConsumptionPointForm";
import {
    DistributionShare,
    sharesWithPercentage
} from "../../../adapters/primary/ui/composition/form/operation-parameters-form/distribution/DistributionBySharesTable";
import {
    DistributionPriority as DistributionPriorityModel
} from "../../models/types/new-simulation/form/operation-parameters/distribution/DistributionPriority";
import {ConsumptionPoint} from "../../models/types/new-simulation/form/ConsumptionPoint";
import {
    DistributionPriority
} from "../../../adapters/primary/ui/composition/form/operation-parameters-form/distribution/DistributionByPriorityTable";
import {
    DistributionShare as DistributionShareModel
} from "../../models/types/new-simulation/form/operation-parameters/distribution/DistributionShare";

function buildDistributionPriorities(existingPriorities: DistributionPriorityModel[], consumptionPoints: ConsumptionPoint[]): DistributionPriority[] {
    const existingPrioritiesPointIds = existingPriorities.map((p: DistributionPriorityModel) => p.pointId)
    const consumptionPointsIds = consumptionPoints.map((cp: ConsumptionPoint) => cp.id)
    const newPriorities = consumptionPoints.filter((cp: ConsumptionPoint) => !(existingPrioritiesPointIds.includes(cp.id))).map((cp: ConsumptionPoint) => new DistributionPriority(cp.id, cp.name, cp.state,1))
    const existingPrioritiesWithPointName = existingPriorities.filter((p: DistributionPriorityModel) => consumptionPointsIds.includes(p.pointId)).map((p: DistributionPriorityModel) => {
        const cp = consumptionPoints.filter((c: ConsumptionPoint) => c.id === p.pointId)[0]
        return new DistributionPriority(p.pointId, cp.name, cp.state,p.priority + 1)
    })
    return [...existingPrioritiesWithPointName, ...newPriorities];
}

function buildDistributionShares(existingShares: DistributionShareModel[], consumptionPoints: ConsumptionPoint[]): DistributionShare[] {
    const existingSharesPointIds = existingShares.map((s: DistributionShareModel) => s.pointId)
    const consumptionPointsIds = consumptionPoints.map((cp: ConsumptionPoint) => cp.id)
    const newShares = consumptionPoints.filter((cp: ConsumptionPoint) => !(existingSharesPointIds.includes(cp.id))).map((cp: ConsumptionPoint) => new DistributionShare(cp.id, cp.name,cp.state, 1))
    const existingSharesWithPointNameAndPercentage = existingShares
        .filter((p: DistributionShareModel) => consumptionPointsIds.includes(p.pointId))
        .map((s: DistributionShareModel) => {
            const cp = consumptionPoints.filter((c: ConsumptionPoint) => c.id === s.pointId)[0]
            return new DistributionShare(
                s.pointId,
                cp.name,
                cp.state,
                s.shares,
                !cp.state,
            )
        })
    const ExcessSharesWithPointNameAndPercentage = existingShares
        .filter((p: DistributionShareModel) => p.pointId === 'excess')
        .map((s: DistributionShareModel) => {
            return new DistributionShare(s.pointId, 'excess', true, s.shares)
        })
    return sharesWithPercentage([...existingSharesWithPointNameAndPercentage, ...ExcessSharesWithPointNameAndPercentage, ...newShares]);
}

function* initOperationParameters(): any {
    try {
        const excessDefaultShare = new DistributionShare('excess', 'excess',true,  0)
        const operationParameters = yield select(selectOperationParameters)
        const consumptionPoints = yield select(selectConsumptionPoints)

        // init distribution
        if (operationParameters) {
            yield put(operationParametersFormActions.setDistributionAlgorithm(operationParameters.distribution_algorithm))
            const initialDistributionPriorities = buildDistributionPriorities(operationParameters ? operationParameters.distribution_priorities : [], consumptionPoints)
            yield put(operationParametersFormActions.setDistributionPriorities(initialDistributionPriorities))
            const initialDistributionShares = buildDistributionShares(operationParameters ? operationParameters.distribution_shares : [excessDefaultShare], consumptionPoints)
            yield put(operationParametersFormActions.setDistributionShares(initialDistributionShares))
        } else {
            yield put(operationParametersFormActions.setDistributionAlgorithm(''))

            const initialDistributionPriorities = buildDistributionPriorities([], consumptionPoints)
            yield put(operationParametersFormActions.setDistributionPriorities(initialDistributionPriorities))
            const initialDistributionShares = buildDistributionShares([excessDefaultShare],consumptionPoints)
            yield put(operationParametersFormActions.setDistributionShares(initialDistributionShares))

        }

    } catch (error: any) {
        yield put(snackBarErrorsActions.handleError(error));
    }
}

function* initOperationParametersSaga() {
    yield takeLatest(INIT_OPERATION_PARAMETERS_FORM, initOperationParameters);
}

const operationParametersFormSaga = [
    initOperationParametersSaga,

]
export default operationParametersFormSaga;

