import {createSelector} from "reselect";
import {RootState} from "../../../store/rootReducer";

export const selectLoader = (state: RootState) => state.loader;
export const selectIsLoadingCurveFromId = createSelector(
    selectLoader,
    loader => loader.curveFromIdIsLoading
);
export const selectIsEnoptiLoading = createSelector(
    selectLoader,
    loader => loader.isEnoptiLoading
);
export const selectIsLoadingNewCurve = createSelector(
    selectLoader,
    loader => loader.newCurveIsLoading
);
const selectAreAllSimulationsLoading = createSelector(
    selectLoader,
    loader => loader.allSimulationsAreLoading
);

const selectIsLoadingSimulationForm = createSelector(
    selectLoader,
    loader => loader.simulationFormIsLoading
);
const selectAreLoadingSimulationsForm = createSelector(
    selectLoader,
    loader => loader.simulationsFormAreLoading
);
const selectIsLoadingAddressAPI = createSelector(
    selectLoader,
    loader => loader.addressAPIIsLoading
);




export {selectAreAllSimulationsLoading, selectIsLoadingSimulationForm, selectAreLoadingSimulationsForm, selectIsLoadingAddressAPI };
