import CardsBalance from "../CardsBalance";
import {WrapperCard} from "../../wrapper/WrapperCard";
import React, {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    selectIsOpenOperation
} from "../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectGeneralInfoForm";
import {
    selectInternalValuation,
    selectLCOE,
    selectTRA,
    selectTRI
} from "../../../../../../corelogic/usecases/financialRestitution/financialRestitutionSelector";
import {
    selectComplementProviderInflationRateDisplayer
} from "../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectFinancialParameter";
import {ProdType} from "../../../containers/BalanceSheet";
import OpenTable from "./table/OpenTable";
import SocialPatriTable from "./table/SocialPatriTable";
import {
    financialRestitutionActions
} from "../../../../../../corelogic/usecases/financialRestitution/financialRestitutionActions";
import {
    selectSelectedSimulationId
} from "../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form/newSimulationSelector";
import {
    selectStoredExternalInvestment
} from "../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectExternalInvestment";

const BalanceSheetData = ({selectedType}:{selectedType:ProdType}) => {
    const dispatch = useDispatch();
    const simulationId = useSelector(selectSelectedSimulationId)

    const cashFlowTableRef = useRef(null)
    const complementProviderInflationRate = useSelector(selectComplementProviderInflationRateDisplayer);

    const isOpenOperation: boolean = useSelector(selectIsOpenOperation)

    const tri = useSelector(selectTRI)
    const tra = useSelector(selectTRA)
    const lcoe = useSelector(selectLCOE)
    const internalValuation = useSelector(selectInternalValuation)
    const externalInvestment = useSelector(selectStoredExternalInvestment)

    useEffect(()=> {
        if(simulationId){
            dispatch(financialRestitutionActions.getBalanceMetrics(selectedType.scope !== "global" ? selectedType.id : undefined));
            dispatch(financialRestitutionActions.getCashFlowData(selectedType.scope !== "global" ? selectedType.id : undefined));
        }
    },[selectedType, simulationId, externalInvestment])

    return <>
        <CardsBalance tri={tri} tra={tra} lcoe={lcoe} internalValuation={internalValuation}/>
        <div className={"p-4 w-full"}>
            <WrapperCard
                exportAsPNGRef={cashFlowTableRef}
                title={`Bilan financier avec inflation du prix de l'électricité à ${complementProviderInflationRate}`}
                exportTableId={isOpenOperation ? 'cashflow-table-open' : 'cash-flow-table'}>
                <div ref={cashFlowTableRef}>
                    {isOpenOperation ? <OpenTable id={'cashflow-table-open'} selectedType={selectedType}/> : <SocialPatriTable id={'cash-flow-table'}/>}
                </div>
            </WrapperCard>
        </div>
    </>
}

export default BalanceSheetData;