import {useSelector} from "react-redux";

import CDCPrevisuChart from "../../../../../components/assets/graphic/CDCPrevisuChart";
import React from "react";
import {
    selectNewCurve
} from "../../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-curve/selectFormCurve";

export default function ShowCDC() {
    const newCurve = useSelector(selectNewCurve)
    return <div className={"w-full"}>
            <CDCPrevisuChart color={"#3b82f6"} data={newCurve}/>
        </div>
}
