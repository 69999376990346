import {HAS_BEEN_UPDATED, INIT_SIMULATION_FORM, newSimulationActionsType} from "../../newSimulationActions";

const hasBeenUpdatedInitialState: boolean = false

const hasBeenUpdatedNotificationReducer = (state = hasBeenUpdatedInitialState, action: newSimulationActionsType) => {
    switch (action.type) {
        case HAS_BEEN_UPDATED:
            return action.payload
        case INIT_SIMULATION_FORM:
            return hasBeenUpdatedInitialState
        default:
            return state;
    }
}
export default hasBeenUpdatedNotificationReducer