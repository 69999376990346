import {SegmentDetailed} from "../../../../../../../corelogic/models/types/new-simulation/form/SegmentDetailed";
import SelectorInput from "../../../../components/assets/FormAssets/selector/SelectorInput";
import React from "react";
import {typologyFormActions} from "../../../../../../../corelogic/usecases/typology-form/typologyFormActions";
import {useDispatch, useSelector} from "react-redux";
import {selectSegment} from "../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import {selectIsLoadingSimulationForm} from "../../../../../../../corelogic/usecases/loader/loaderSelector";

export default function SegmentSelectorInput(){
    const dispatch = useDispatch()
    const segment = useSelector(selectSegment)
    const isLoadingForm = useSelector(selectIsLoadingSimulationForm)

    function segmentOnChange(e: React.ChangeEvent<HTMLSelectElement>) {
        switch (e.target.value) {
            case SegmentDetailed.C5_CU :
                dispatch(typologyFormActions.setSegment(SegmentDetailed.C5_CU));
                break;
            case SegmentDetailed.C5_MUDT :
                dispatch(typologyFormActions.setSegment(SegmentDetailed.C5_MUDT));
                break;
            case SegmentDetailed.C5_CU4 :
                dispatch(typologyFormActions.setSegment(SegmentDetailed.C5_CU4));
                break;
            case SegmentDetailed.C4_CU4:
                dispatch(typologyFormActions.setSegment(SegmentDetailed.C4_CU4));
                break;
            case SegmentDetailed.C4_LU4:
                dispatch(typologyFormActions.setSegment(SegmentDetailed.C4_LU4));
                break;
            case SegmentDetailed.HTA_CU5 :
                dispatch(typologyFormActions.setSegment(SegmentDetailed.HTA_CU5));
                break;
            case SegmentDetailed.HTA_LU5 :
                dispatch(typologyFormActions.setSegment(SegmentDetailed.HTA_LU5));
                break;
            default :
                break;
        }
    }
    return <SelectorInput dataCy={"typology-consumption-segment"} value={isLoadingForm ? 'chargement ...' : segment} id={"select-segment"}
                          onChange={segmentOnChange}
                          label={"SEGMENT"}
                          options={Object.values(SegmentDetailed)}/>
}