import {useSelector} from "react-redux";
import {
    selectCashFlowData,
    selectCashFlowLoading
} from "../../../../../../../corelogic/usecases/financialRestitution/financialRestitutionSelector";
import {
    makeSelectHasProducerGiftedElectricity,
    makeSelectHasProducerLoanRepayment,
    selectComplementProviderInflationRateArrayString,
    selectHasGiftedElectricity,
    selectHasLoanRepayment,
    selectIsVariableInflation
} from "../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectFinancialParameter";
import {useCallback, useState} from "react";
import {
    makeSelectIsACI
} from "../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectConsumptionPointForm";
import {
    ACISavingsHead,
    BillAvoiding,
    CapexAndOpexHead,
    CapexLoanHead,
    CumulativeFluxHead,
    GiftedElectricityHead,
    IncomeExcessHead,
    IncomeProducer,
    InternalSellingHead,
    SelfConsumptionIncomeHead,
    TotalHead,
    VariableInflationHead,
    YearHead
} from "../../table/header/CashFlowHeadComponent";
import Loading from "../../table/Loading";
import {
    ACCSavings,
    ACISavings,
    CumulativeFlows,
    Excess,
    GiftedElecticity,
    Investment,
    LoanRepayment,
    Maintenance,
    NetSavings,
    ProducerGain,
    VariableInflation,
    YearlyResult
} from "../../table/body/CashFlowBodyComponent";

const OpenTable = ({selectedType, id}: { selectedType: any, id:string }) => {
    const loading = useSelector(selectCashFlowLoading)
    const variableInflationArray = useSelector(selectComplementProviderInflationRateArrayString)
    const selectIsACI = useCallback(makeSelectIsACI(selectedType), [selectedType])
    const isACI = useSelector(selectIsACI)

    const isInflationVariable = useSelector(selectIsVariableInflation)

    const selectHasGiftedEnergy = useCallback(selectedType.scope === 'producer' ? makeSelectHasProducerGiftedElectricity(selectedType.id) : selectHasGiftedElectricity, [selectedType])
    const hasGiftedElectricity = useSelector(selectHasGiftedEnergy)

    const selectHasLoan = useCallback(selectedType.scope === 'producer' ? makeSelectHasProducerLoanRepayment(selectedType.id) : selectHasLoanRepayment, [selectedType])
    const hasLoan = useSelector(selectHasLoan)
    const [rowHover, setRowHover] = useState(-1)
    const CashFlowData = useSelector(selectCashFlowData);

    const columnsNumber = 8 + (isInflationVariable ? 1 : 0) + (hasLoan ? 1 : 0) + (hasGiftedElectricity ? 1 : 0) + (isACI ? 1 : 0)
    return <div className="bg-white shadow-md rounded dark:bg-zinc-700">
        <table id={id} className="w-full table-auto rounded-lg">
            <thead className={'bg-white dark:bg-zinc-700'}>
            <tr>
                <YearHead/>
                {isInflationVariable && <VariableInflationHead/>}
                <CapexAndOpexHead/>
                {hasLoan && <CapexLoanHead/>}
                {(hasGiftedElectricity || isACI) && <BillAvoiding isACI={isACI} hasGiftedElectricity={hasGiftedElectricity}/>}
                <IncomeProducer  isOpenOperation={true}/>
                <TotalHead/>
                <CumulativeFluxHead/>
                <SelfConsumptionIncomeHead/>
            </tr>
            <tr>
                {hasGiftedElectricity && <GiftedElectricityHead/>}
                {isACI && <ACISavingsHead/>}
                {<IncomeExcessHead/>}
                {<InternalSellingHead/>}
            </tr>
            </thead>
            {
                loading ? <Loading columnsNumber={columnsNumber}/>
                    : <tbody className="text-gray-500 text-xs font-light dark:text-zinc-400 bg-white dark:bg-zinc-700">
                    {
                        CashFlowData.map((cashFlowRow: any, idx: number) =>
                            <tr
                                onMouseEnter={() => setRowHover(idx)}
                                onMouseLeave={() => setRowHover(-1)}
                                className="border-b border-gray-200 hover:bg-gray-100 dark:hover:bg-zinc-600 dark:border-zinc-800">
                                <YearlyResult idx={idx}/>
                                {variableInflationArray && isInflationVariable &&
                                    <VariableInflation inflationRate={variableInflationArray[idx]}/>}
                                <Investment cashFlowRow={cashFlowRow}/>
                                <Maintenance cashFlowRow={cashFlowRow}/>
                                {hasLoan && <LoanRepayment cashFlowRow={cashFlowRow}/>}
                                {hasGiftedElectricity &&
                                    <GiftedElecticity idx={idx} rowHover={rowHover} cashFlowRow={cashFlowRow}/>}
                                {isACI && <ACISavings idx={idx} rowHover={rowHover} cashFlowRow={cashFlowRow}/>}
                                <Excess cashFlowRow={cashFlowRow} rowHover={rowHover} idx={idx}/>
                                <ProducerGain idx={idx} rowHover={rowHover} cashFlowRow={cashFlowRow}/>
                                <NetSavings cashFlowRow={cashFlowRow}/>
                                <CumulativeFlows cashFlowRow={cashFlowRow} rowHover={rowHover} idx={idx}/>
                                <ACCSavings idx={idx} rowHover={rowHover} cashFlowRow={cashFlowRow}/>

                            </tr>)
                    }
                    </tbody>
            }
        </table>
    </div>
}
export default OpenTable