import {Simulation} from "../../models/types/simulations-display/Simulation";
import {
    Actions,
    INIT_ALL_SIMULATIONS,
    INIT_SIMULATIONS,
    SEARCH_SIMULATION,
    SET_ALL_SIMULATIONS,
    SET_SIMULATIONS,
    SET_SIMULATIONS_COUNT,
    SET_SIMULATIONS_CURRENT_PAGE
} from "./simulationsDisplayActions";
import {combineReducers} from "redux";

const initialSearchState:string =''
const searchReducer = (state = initialSearchState, action: Actions) => {
    switch (action.type) {
        case SEARCH_SIMULATION:
            return action.payload;
        default:
            return state;
    }
}
const initialSimulationState: Simulation[] = []

const simulationsReducer = (state = initialSimulationState, action: Actions) => {
    switch (action.type) {
        case SET_SIMULATIONS:
            return [...action.payload];
        case INIT_SIMULATIONS:
            return [];
        default:
            return state;
    }
}
const initialAllSimulationState: Simulation[] = [];

const allSimulationsReducer = (state = initialAllSimulationState, action: Actions) => {
  switch (action.type) {
    case SET_ALL_SIMULATIONS:
      return [...action.payload];
    case INIT_ALL_SIMULATIONS:
      return [];
    default:
      return state;
  }
};


const initialCurrentPageState : number = 1;
const simulationsCurrentPageReducer = (state = initialCurrentPageState, action: Actions) => {
    switch (action.type) {
        case SET_SIMULATIONS_CURRENT_PAGE:
            return action.payload;
        default:
            return state;
    }
}

const simulationsCountReducer = (state = 0, action: Actions) => {
    switch (action.type) {
        case SET_SIMULATIONS_COUNT:
            return action.payload;
        case INIT_SIMULATIONS:
            return 0;
        default:
            return state;
    }
}
export default combineReducers({
    simulations : simulationsReducer,
    simulationsCurrentPage : simulationsCurrentPageReducer,
    search : searchReducer,
    simulationsCount: simulationsCountReducer, 
    allSimulations: allSimulationsReducer,
});