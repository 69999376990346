import React from "react";
import withConditionalRender from "../../../../wrapper/withConditionalRender";

import {useDispatch, useSelector} from "react-redux";
import {PuzzlePieceIconSolid} from "../../../../../components/assets/icon/PuzzlePieceIcon";
import {
    selectFullCompletudeButton,
    selectUndefinitiveNewCurve
} from "../../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import {typologyFormActions} from "../../../../../../../../corelogic/usecases/typology-form/typologyFormActions";
import {useSnackbar} from "notistack";
import {
    selectEnableFullCompletudeButton
} from "../../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-curve/selectFormCurve";

function FullCompletudeButton() {
    const dispatch = useDispatch()
    const {enqueueSnackbar} = useSnackbar()
    const enableButton = useSelector(selectEnableFullCompletudeButton)
    const clicked = useSelector(selectFullCompletudeButton)
    const clickedStyle = clicked ? ' border-2 border-orange-600 ' : ' '
    const loadingStyle = enableButton ?
            ' bg-orange-300 dark:bg-orange-600 hover:cursor-pointer hover:bg-orange-300 dark:hover:bg-orange-600 dark:hover:bg-opacity-80 '
            : ' opacity-60 bg-orange-300  dark:bg-orange-600'

    function onButtonClick() {
        if(enableButton){
            dispatch(typologyFormActions.setFullCompletudeButton(!clicked))
            dispatch(typologyFormActions.setRecalageButtonAction(false))
            dispatch(typologyFormActions.showLowCompletenessForm(false))
        } else {
            enqueueSnackbar("La courbe doit être d'au moins 1 semaine sans trous.", {variant : "warning"})
        }

    }

    return <span
        onClick={onButtonClick}
        title={enableButton ? "Permet de compléter la courbe." : "La courbe ne peux être complétée."}
        className={"flex  p-1 gap-1 px-2  bg-opacity-60  items-center rounded  dark:bg-opacity-60 " + loadingStyle + clickedStyle}>
        <PuzzlePieceIconSolid
            className={enableButton ? "text-orange-600 h-5 w-5 dark:text-orange-200" : "text-orange-600 h-5 w-5 dark:text-orange-200"}/>
         <span
             className={enableButton ? 'font-semibold dark:text-orange-200 text-orange-600 text-xs truncate' : 'font-semibold dark:text-orange-200 text-orange-600 text-xs truncate'}>
             Complétude annuelle
         </span>
</span>
}

export default withConditionalRender(FullCompletudeButton, selectUndefinitiveNewCurve)
