import {call, getContext, put, select, takeLatest} from "redux-saga/effects";
import {DUPLICATE_SIMULATION, GET_ALL_SIMULATION, GET_SIMULATIONS, simulationsDisplayActions} from "./simulationsDisplayActions";
import {SimulationsDisplayGatewayInterface} from "./simulationsDisplayGateway.interface";
import {Simulation} from "../../models/types/simulations-display/Simulation";
import {snackBarErrorsActions} from "../errors/snackbarErrorsActions";
import {selectSimulations, selectSimulationsCurrentPage} from "./simulationsDisplaySelector";
import {loaderActions} from "../loader/loaderActions";
import {authenticationActions} from "../authentication/authenticationActions";
import { LandingSimulationConfig } from "../../../adapters/secondary/gateways/APISimulationsDisplayGateway";
import { selectSelectedOrganisationId } from "../authentication/authenticationSelector";

const PAGINATION_LIMIT = 10;

function* getSimulations() {
    try {
        yield put(loaderActions.setAreLoadingSimulationsForm(true))
        const currentPage: number = yield select(selectSimulationsCurrentPage);
        const organisationId: string = yield select(selectSelectedOrganisationId)
        const skip = (currentPage -1) * PAGINATION_LIMIT;
        const simulationGateway: SimulationsDisplayGatewayInterface = yield getContext('simulationsDisplayGatewayContext');
        const landingConfiguration: LandingSimulationConfig = yield call(simulationGateway.getSimulations, skip, PAGINATION_LIMIT, organisationId);
        yield put(simulationsDisplayActions.setSimulations(landingConfiguration.simulations));
        yield put(simulationsDisplayActions.setSimulationsCount(landingConfiguration.simulationsCount));
    } catch (error: any) {
        yield put(snackBarErrorsActions.handleError(error));
    }
    yield put(loaderActions.setAreLoadingSimulationsForm(false))

}

function* getAllSimulations() {
    try {
        yield put(loaderActions.setAreAllSimulationsLoading(true))
        const simulationGateway: SimulationsDisplayGatewayInterface = yield getContext('simulationsDisplayGatewayContext');
        const simulations: Simulation[] = yield call(simulationGateway.getAllSimulations);
        yield put(simulationsDisplayActions.setAllSimulations(simulations));
    } catch (error: any) { 
        console.log('getAllSimulations saga error ', error)
        yield put(snackBarErrorsActions.handleError(error));
    }
    
    yield put(loaderActions.setAreAllSimulationsLoading(false))
}

function* duplicateSimulations(action : any){
    try {
        yield put(loaderActions.setAreLoadingSimulationsForm(true))
        const {payload}= action
        let oldSimulations: Simulation[] = yield select(selectSimulations)
        const simulationGateway: SimulationsDisplayGatewayInterface = yield getContext('simulationsDisplayGatewayContext');
        const simulation: Simulation = yield call(simulationGateway.duplicateSimulation, payload);
        oldSimulations.push(simulation)
        yield put(simulationsDisplayActions.setSimulations(oldSimulations))
        yield put(authenticationActions.getSession())

    } catch (error: any) {
        yield put(snackBarErrorsActions.handleError(error));
    }
    yield put(loaderActions.setAreLoadingSimulationsForm(false))
}
export function* getSimulationsSaga() {
    yield takeLatest(GET_SIMULATIONS, getSimulations);
}
export function* duplicateSimulationsSaga(){
    yield takeLatest(DUPLICATE_SIMULATION, duplicateSimulations)
}
export function* getAllSimulationsSaga(){
    yield takeLatest(GET_ALL_SIMULATION, getAllSimulations)
}


const displaySimulationsSagas = [getAllSimulationsSaga, getSimulationsSaga, duplicateSimulationsSaga]
export default displaySimulationsSagas;
