import {LabelInput} from "../../../../components/assets/FormAssets/TextInput";
import {useDispatch, useSelector} from "react-redux";
import {
    selectFormDuration,
    selectFormDurationIsValid,
    selectHasInflationChanged,
    selectInflation,
    selectInflationIsValid,
    selectVariableInflations,
    selectVariableInflationsToggled
} from "../../../../../../../corelogic/usecases/form/formSelector";
import Switch from "../../../../components/assets/toggle/Switch";
import {formActions} from "../../../../../../../corelogic/usecases/form/formActions";
import VariableInflationInput from "../variable-inflation-widget/VariableInflationInput";
import Notification from "../../../paper/Notification";
import {State} from "../../../../../../../config/app-config";
import FixedInflationInput from "../FixedInflationInput";
import React from "react";

export default function InflationInput() {

    return <>
        <InflationInputToggle/>
        <div className={"flex flex-row align-center gap-2 w-full"}>

            <LabelInput label={"INFLATION DU TARIF DE FOURNITURE DE COMPLÉMENT (%/AN)"}
                        mandatory={true}/>
        </div>
        <InputDisplayer/>
    </>

}

function InflationInputToggle() {
    const dispatch = useDispatch()
    const variableInflationsToggled = useSelector(selectVariableInflationsToggled)

    return <div className={"w-full my-4 flex gap-2"}>
        <label
            className="z-20 block tracking-wide text-gray-600 font-semibold dark:text-zinc-300"
            htmlFor="grid-last-name"> Inflation
            <span className={variableInflationsToggled ? "opacity-40" : ""}>{" (fixe/ "}</span>
            <span className={variableInflationsToggled ? "" : "opacity-40"}>variable)</span>
        </label>
        <Switch state={variableInflationsToggled}
                action={() => dispatch(formActions.setVariableInflationsToggled(!variableInflationsToggled))}/>
    </div>
}

function InputDisplayer(){
    const dispatch = useDispatch()
    const duration = useSelector(selectFormDuration)
    const variableInflationsToggled = useSelector(selectVariableInflationsToggled)
    const inflation = useSelector(selectInflation)
    const durationIsValid = useSelector(selectFormDurationIsValid)
    const inflationIsValid = useSelector(selectInflationIsValid)
    const inflationHasChanged = useSelector(selectHasInflationChanged)
    const variableInflationsForm = useSelector(selectVariableInflations)

    const durationIsEmptyOrInvalid = !duration || !durationIsValid

    function inflationOnChange(e: string) {
        // Basic Validation
        dispatch(formActions.setInflation(e));
    }

    if (variableInflationsToggled && !durationIsEmptyOrInvalid) return <VariableInflationInput
        fetchLastSavedInflations={formActions.resetLastSavedVariableInflations}
        resetInflations={formActions.initVariableInflations}
        setInflationsAction={formActions.setVariableInflations}
        uniqueId={'form'}
        inflations={variableInflationsForm}
        fixedInflation={inflation}
        inflationHasChanged={inflationHasChanged}/>
    if (variableInflationsToggled && durationIsEmptyOrInvalid) {
        const isEmpty = !duration
        const textDisplayed = isEmpty ? "Attention, la durée de simulation doit être remplie pour gérer l'inflation variable." : "Attention, la durée de simulation n'est pas valide."
        return <Notification
            state={State.WARNING}
            text={textDisplayed}/>
    }
    if (!variableInflationsToggled) return <FixedInflationInput id={"step5-inflation"} inflation={inflation}
                                                                inflationIsValid={inflationIsValid}
                                                                inflationOnChange={inflationOnChange}/>
    return <>Erreur</>
}
