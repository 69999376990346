import {
    DistributionAlgorithm,
} from "../../../../../../../corelogic/models/types/new-simulation/form/operation-parameters/distribution/DistributionAlgorithm";
import React from "react";
import {useSelector} from "react-redux";
import {
    selectDistributionAlgorithmForm
} from "../../../../../../../corelogic/usecases/operation-parameters-form/operationParametersFormSelector";
import {PriorityInput} from "../PriorityInput";
import useByUngroupedPriority
    from "../../../../../../../corelogic/usecases/operation-parameters-form/distribution/ungrouped-by-priority/useByUngroupedPriority";
import Notification from "../../../paper/Notification";
import {State, zinc} from "../../../../../../../config/app-config";
import TableRowBadge from "../../../../components/assets/table/TableRowBadge";
import {
    selectConsumptionPoints
} from "../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectConsumptionPointForm";
import {ZERO_PRIORITY} from "../../../../../../../corelogic/models/types/new-simulation/form/operation-parameters/distribution/DistributionUngroupedPriorities";

export default function DistributionByUngroupedPriorityTable() {
    const distributionAlgorithm = useSelector(selectDistributionAlgorithmForm)
    const consumptionPoints = useSelector(selectConsumptionPoints)
    const {table, updatePriority, isValid} = useByUngroupedPriority()

    return <div>
        {distributionAlgorithm === DistributionAlgorithm.by_ungrouped_priority &&
            <div className={'flex flex-col'}>
             <span
                 className={"font-semibold whitespace-nowrap text-md text-gray-700 dark:text-gray-300 my-2 pt-4 pb-2"}>
                Configuration de l'algorithme dynamique par priorité dégroupée
             </span>
                {!isValid && <Notification state={State.ERROR}
                                           text={"Attention, un point de soutirage ne peut avoir deux points d'injection avec la même priorité."}/>}
                <div className="flex flex-row justify-between">
                    <div className="flex flex-row gap-4 mb-2">
                    <span className="text-gray-600 dark:text-gray-300 text-xs flex-wrap flex">
                        <b>1</b> : priorité la plus haute
                    </span>
                    <span className="text-gray-600 dark:text-gray-300 text-xs flex-wrap flex">
                        <b>0</b> : désactiver la répartition pour le couple
                    </span>
                    </div>
                </div>
                <div className={"overflow-x-auto lg:max-w-[920px] 2xl:max-w-[1200px]"}>
                    <table
                        className=" relative     px-2 py-4 table-auto rounded-lg">
                        <thead>
                        <tr className="text-gray-600 dark:text-gray-300 text-sm">
                            <th align={'left'} className="px-4 py-2 text-left truncate">Point d'injection / Point de
                                soutirage
                            </th>
                            {
                                table.consumptionColumnTitles.map((consumptionColumnTitle) => {
                                    return <th className={`px-4 py-2 font-medium
                                        ${consumptionColumnTitle.state ? '' : 'opacity-50'}
                                        `}>
                                        <div className={"flex-col flex gap-0 items-center w-full justify-center"}>
                                            <span className={"truncate"}>{consumptionColumnTitle.name}</span>
                                            <TableRowBadge
                                                text={consumptionColumnTitle.segment}
                                                color={zinc}
                                                disabled={!consumptionColumnTitle.state}/>
                                        </div>
                                    </th>
                                })
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {
                            table.rows.map((row, injectionPointIndex: number) => {
                                return <tr key={injectionPointIndex}
                                           className={
                                               (injectionPointIndex % 2 === 0) ? 'bg-gray-50 dark:bg-zinc-800 dark:bg-opacity-30' : 'bg-zinc-100 dark:bg-zinc-800 dark:bg-opacity-[15%]' +
                                               row.injectionPoint.state ? '' : ' opacity-50'
                                           }
                                    >
                                    <td className="border dark:border-zinc-700 px-4 py-1 text-sm text-gray-700 dark:text-gray-300">
                                        <div className={"flex items-center gap-1"}>
                                            {row.injectionPoint.name}
                                            <TableRowBadge
                                                text={row.injectionPoint.generatedKwhPrice + ' € HT/kWh'}
                                                color={zinc}
                                                disabled={false}/>
                                        </div>
                                    </td>

                                    {
                                        row.priorities.map((priority, consumptionPointIndex) => {
                                            const isNotActivated = !consumptionPoints[consumptionPointIndex]?.state;
                                            const isACI = consumptionPoints[consumptionPointIndex]?.isInACIWith(row.injectionPoint.id);
                                            const isZeroPrio = (priority === ZERO_PRIORITY);
                                            return <td align={'center'}
                                                       className={
                                                         'border dark:border-zinc-700 px-4 py-1 text-gray-700 dark:text-gray-300' +
                                                         (isACI || isZeroPrio || isNotActivated ? ' opacity-30' : '')}
                                                       >
                                                {isACI ?
                                                    "ACI":
                                                    <PriorityInput
                                                        min={0} max={99}
                                                        valeur={priority}
                                                        onChange={(val: any) => updatePriority(val, injectionPointIndex, consumptionPointIndex)}/>
                                                }
                                            </td>
                                        })
                                    }
                                </tr>
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        }
    </div>
}
