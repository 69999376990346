import TitleText from "../../../../components/assets/text/TitleText";
import DescriptionText from "../../../../components/assets/text/DescriptionText";
import TypologyPointAddressInput from "../../injection-point-form/TypologyPointAddressInput";
import HPHCComponent from "./HPHC";
import {ChooseConsumptionPointTVA} from "./ChooseTVA";
import LoadCurveUpload from "../../load-curve-upload/LoadCurveUpload";
import RegularBtn from "../../../../components/assets/button/RegularBtn";
import {ButtonState, Theme} from "../../../../../../../config/app-config";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {Step} from "../../../../../../../corelogic/models/types/new-simulation/steps/Step";
import {
    selectClearForm,
    selectPointBeingEdited
} from "../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import {newSimulationActions} from "../../../../../../../corelogic/usecases/new-simulation/newSimulationActions";
import {ConsumptionPoint} from "../../../../../../../corelogic/models/types/new-simulation/form/ConsumptionPoint";
import {typologyFormActions} from "../../../../../../../corelogic/usecases/typology-form/typologyFormActions";
import NameInput from "./NameInput";
import ConsumerTypeSelectorInput from "./ConsumerTypeSelectorInput";
import SegmentSelectorInput from "./SegmentSelectorInput";
import ACIOrNumberOfPointsInput from "./ACIOrNumberOfPointsInput";
import AddConsumptionPointButton from "./AddConsumptionPointButton";
import {selectIsLoadingSimulationForm} from "../../../../../../../corelogic/usecases/loader/loaderSelector";
import {
    selectClosingForm
} from "../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-action/selectFormAction";
import {
    selectNextStep,
    selectSteps
} from "../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/steps/selectSteps";
import {
    selectConsumptionPoints
} from "../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectFinancialParameter";

export default function ConsumptionPointForm() {
    const dispatch = useDispatch()
    const closingForm = useSelector(selectClosingForm)
    const steps: Step[] = useSelector(selectSteps)
    const nextStep = useSelector(selectNextStep)
    const rowToEdit = useSelector(selectPointBeingEdited)
    const clear = useSelector(selectClearForm)

    const soutiragePoints = useSelector(selectConsumptionPoints)
    const isLoadingForm = useSelector(selectIsLoadingSimulationForm)

    const [addressIsValid, setAddressIsValid] = useState(true)


    useEffect(() => {
        dispatch(newSimulationActions.setFormIsValid(soutiragePoints.some((soutiragePoint: ConsumptionPoint) => soutiragePoint.state)))
    }, [soutiragePoints])

    useEffect(() => {
        if (clear) {
            dispatch(typologyFormActions.setName(""))
            dispatch(typologyFormActions.setAccNumber("1"))
            dispatch(typologyFormActions.setGeneratedKwhPrice(""))
            dispatch(typologyFormActions.setClear(false))
            dispatch(typologyFormActions.enableACI(false))
            dispatch(typologyFormActions.setIsVATRecoverable(false))
            dispatch(typologyFormActions.enableHPHC(false))
            dispatch(typologyFormActions.setCDCUploadOption(null))
            dispatch(typologyFormActions.setEditPoint(null))
            dispatch(typologyFormActions.setAciPointId(null))
            dispatch(typologyFormActions.setAddress(""))
            dispatch(newSimulationActions.setNewCurve(null))
            dispatch(newSimulationActions.setFirstNewCurve(null))
        }
    }, [clear])



    useEffect(() => {
        // onClick Edit a row -> fill the form with the row data
        // -> Change form state in order to make user know he's editing the row
        if (rowToEdit) {
            dispatch(typologyFormActions.setName(rowToEdit.name))
            dispatch(typologyFormActions.setGeneratedKwhPrice(rowToEdit.generatedKwhPrice))
            dispatch(typologyFormActions.setAccNumber(rowToEdit.numberOfPoints.toString()))
            dispatch(typologyFormActions.setConsumerType(rowToEdit.consumerType))
            dispatch(typologyFormActions.setSegment(rowToEdit.segment))
            dispatch(typologyFormActions.setIsVATRecoverable(rowToEdit.isVATRecoverable))
            dispatch(typologyFormActions.setAddress(rowToEdit.address?.label || ""))
            dispatch(newSimulationActions.loadNewCurve(rowToEdit.loadCurveId))

            if (rowToEdit.injectionPointId) {
                // ACI
                dispatch(typologyFormActions.enableACI(true))
                dispatch(typologyFormActions.setAciPointId(rowToEdit.injectionPointId))
            } else {
                dispatch(typologyFormActions.enableACI(false))
            }

            if (rowToEdit._accommodationType && rowToEdit._heatingType) {
                // modélisation enedis
                dispatch(typologyFormActions.setAccommodationType(rowToEdit._accommodationType))
                dispatch(typologyFormActions.setHeatingType(rowToEdit._heatingType))
                dispatch(typologyFormActions.setCDCUploadOption('ENEDIS'))
            } else {
                dispatch(typologyFormActions.setCDCUploadOption('UPLOAD'))
            }
            if (typeof rowToEdit.complementProviderKwhPrice.peakOffPeakHoursDefinition === 'number') {
                // pas d'HP/HC
                dispatch(typologyFormActions.enableHPHC(false))
                dispatch(typologyFormActions.setGeneratedKwhPrice(rowToEdit.complementProviderKwhPrice.peakOffPeakHoursDefinition))
            } else {
                if (rowToEdit.complementProviderKwhPrice.peakOffPeakHoursDefinition.pricing.peakPricing.offPeakProviderKwhPrice) {
                    dispatch(typologyFormActions.enableHPHC(true))
                    dispatch(typologyFormActions.setHeurePleinePrix(rowToEdit.complementProviderKwhPrice.peakOffPeakHoursDefinition.pricing.peakPricing.peakKwhProviderPrice))
                    dispatch(typologyFormActions.setHeureCreusePrix(rowToEdit.complementProviderKwhPrice.peakOffPeakHoursDefinition.pricing.peakPricing.offPeakProviderKwhPrice))
                    dispatch(typologyFormActions.setHeurePleine(rowToEdit.complementProviderKwhPrice.peakOffPeakHoursDefinition.peakStartTime))
                    dispatch(typologyFormActions.setHeureCreuse(rowToEdit.complementProviderKwhPrice.peakOffPeakHoursDefinition.offPeakStartTime))
                } else {
                    dispatch(typologyFormActions.enableHPHC(true))
                    dispatch(typologyFormActions.setHeurePleineHiverPrix(rowToEdit.complementProviderKwhPrice.peakOffPeakHoursDefinition.pricing.peakPricing.winterPeakKwhProviderPrice))
                    dispatch(typologyFormActions.setHeureCreuseHiverPrix(rowToEdit.complementProviderKwhPrice.peakOffPeakHoursDefinition.pricing.peakPricing.winterOffPeakKwhProviderPrice))
                    dispatch(typologyFormActions.setHeurePleineEtePrix(rowToEdit.complementProviderKwhPrice.peakOffPeakHoursDefinition.pricing.peakPricing.summerPeakKwhProviderPrice))
                    dispatch(typologyFormActions.setHeureCreuseEtePrix(rowToEdit.complementProviderKwhPrice.peakOffPeakHoursDefinition.pricing.peakPricing.summerOffPeakKwhProviderPrice))
                    dispatch(typologyFormActions.setHeurePleine(rowToEdit.complementProviderKwhPrice.peakOffPeakHoursDefinition.peakStartTime))
                    dispatch(typologyFormActions.setHeureCreuse(rowToEdit.complementProviderKwhPrice.peakOffPeakHoursDefinition.offPeakStartTime))
                }

            }


        }
    }, [rowToEdit])

    useEffect(() => {
        // asked to close this form
        if (closingForm) {
            let stepsChange: Step[] = steps
            let soutiragePointsNames: string[] = soutiragePoints.map((point: ConsumptionPoint) => {
                return point.name
            })
            if (soutiragePoints.length !== 0) {
                stepsChange[steps[2].index] = new Step(steps[2].title, soutiragePointsNames, ButtonState.REGULAR, steps[2].index)
            } else {
                stepsChange[steps[2].index] = new Step(steps[2].title, ["étape 3/5"], ButtonState.DISABLED, steps[2].index)
            }
            dispatch(newSimulationActions.setSteps(stepsChange))
            dispatch(newSimulationActions.setCurrentStepIndex(nextStep.index))
            dispatch(newSimulationActions.setClosingForm(false))
        }
    }, [closingForm])


    function handleCancelEdition() {
        dispatch(newSimulationActions.setNewCurve(null))
        dispatch(newSimulationActions.setFirstNewCurve(null))
        dispatch(typologyFormActions.setEditPoint(null))
    }




    return <form className="w-full 2xl:max-w-[920px] mt-12">
        <TitleText
            title={rowToEdit ? "Modifier le point de soutirage : [" + rowToEdit.name + "]" : "Créer un nouveau point de soutirage"}/>
        <DescriptionText description={"Renseignez les informations de votre point de soutirage."}/>
        <div
            className={rowToEdit ? "flex flex-wrap p-4 bg-blue-50 border-2 dark:border-0 dark:bg-slate-800 border-blue-200 rounded-md" : "flex flex-wrap p-4"}>
            <NameInput/>
            <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-x-4">
                <ConsumerTypeSelectorInput/>
                <SegmentSelectorInput/>
            </div>
            <TypologyPointAddressInput addressIsValid={addressIsValid}
                                       setAddressIsValid={setAddressIsValid}/>
            <hr className="mx-2 my-3 w-full  dark:border-zinc-600"/>
            <ACIOrNumberOfPointsInput/>
            <hr className="mx-2 my-3 w-full  dark:border-zinc-600"/>
            <HPHCComponent/>
            <span className={"my-2 w-full"}/>
                <ChooseConsumptionPointTVA/>
            <hr className="mx-2 my-3 w-full  dark:border-zinc-600"/>
            <LoadCurveUpload/>
            <div className={"w-full"}>
                <div className={"text-gray-500 text-xs mt-4 mb-2"}>* champs obligatoires</div>
                <hr className={"w-full  dark:border-zinc-600"}/>
                <div className={"w-full flex"}>
                    <AddConsumptionPointButton />
                    {rowToEdit && <div className={"w-full ml-2"}>
                        <RegularBtn theme={Theme.SECONDARY} action={handleCancelEdition}
                                    state={ButtonState.REGULAR}
                                    title={"Annuler"}/>
                    </div>}
                </div>
            </div>
        </div>
    </form>

}
