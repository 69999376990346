import {useDispatch, useSelector} from "react-redux";
import React, {useCallback, useEffect, useState} from "react";

import {PathnameEnum} from "../../../../corelogic/models/types/routing/PathnameEnum";
import {newSimulationActions} from "../../../../corelogic/usecases/new-simulation/newSimulationActions";
import {
    selectSelectedSimulationId
} from "../../../../corelogic/usecases/new-simulation/new-simulation-selector/form/newSimulationSelector";
import {ProductionType} from "../../../../corelogic/models/types/new-simulation/form/ProductionType";
import withRestitutionNavBar from "../composition/wrapper/withRestitutionNavBar";
import ExternalInvestmentForm from "../composition/balance-sheet/external-investment-form/form/ExternalInvestmentForm";
import {
    makeSelectCanViewExternalInvestment,
    selectProductors
} from "../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectInjectionPointForm";
import HeaderBalance from "../composition/balance-sheet/HeaderBalance";
import BalanceSheetDataForExternalInvestment
    from "../composition/balance-sheet/cash-flow-external-invest-data/BalanceSheetDataForExternalInvestment";
import BalanceSheetData from "../composition/balance-sheet/cash-flow-data/BalanceSheetData";
import {
    selectStoredExternalInvestment
} from "../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectExternalInvestment";

export type ProdType = { id: string, name: string, scope: string, productionType?: ProductionType }

const BalanceSheet = () => {
    const dispatch = useDispatch();
    const productors = useSelector(selectProductors)
    const [selectedType, setSelectedType] = useState<ProdType>(productors[0])
    const [externalInvestmentView, setExternalInvestmentView] = useState(false)

    useEffect(() => {
        dispatch(newSimulationActions.getSimulationForm())
    }, [])

    return <div
        className="min-w-[640px]  w-full  flex flex-col items-end bg-gray-100 font-sans overflow-y-scroll dark:bg-zinc-800">
        <HeaderBalance selectedType={selectedType} setSelectedType={setSelectedType} externalInvestmentView={externalInvestmentView} setExternalInvestmentView={setExternalInvestmentView}/>
        <ExternalInvestmentForm/>
        {externalInvestmentView ?
            <BalanceSheetDataForExternalInvestment selectedType={selectedType}/>
            :<BalanceSheetData selectedType={selectedType}/>}

    </div>
}

export default withRestitutionNavBar(BalanceSheet, PathnameEnum.BALANCE_RESTITUTION)


