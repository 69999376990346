import {INIT_SIMULATION_FORM, newSimulationActionsType, SET_CURRENT_STEP} from "../../newSimulationActions";

const currentStepInitialState: number = 0

const currentStepIndexReducer = (state = currentStepInitialState, action: newSimulationActionsType) => {
    switch (action.type) {
        case SET_CURRENT_STEP:
            return action.payload
        case INIT_SIMULATION_FORM:
            return currentStepInitialState
        default:
            return state;
    }
}
export default currentStepIndexReducer