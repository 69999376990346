import {createAction} from "../../../store/createAction";
import {ActionsUnion} from "../../../store/actionsUnions";

export const SET_IS_LOADING_NEW_CURVE = 'SET_IS_LOADING_NEW_CURVE';
export const SET_ISLOADING_SIMULATION_FORM = 'SET_ISLOADING_SIMULATION_FORM';
export const SET_ARELOADING_SIMULATIONS_FORM = 'SET_ARELOADING_SIMULATIONS_FORM';
export const SET_ISLOADING_ADDRESS_API = 'SET_ISLOADING_ADDRESS_API';
export const SET_ARE_ALL_SIMULATIONS_LOADING = 'SET_ARE_ALL_SIMULATIONS_LOADING'
export const SET_ENOPTI_IS_LOADING = 'SET_ENOPTI_IS_LOADING'
export const loaderActions = {
    setIsLoadingNewCurve: (loading:boolean) => createAction(SET_IS_LOADING_NEW_CURVE, loading),
    setIsLoadingSimulationForm:(loading : boolean)=> createAction(SET_ISLOADING_SIMULATION_FORM, loading),
    setAreLoadingSimulationsForm:(loading : boolean)=> createAction(SET_ARELOADING_SIMULATIONS_FORM, loading),
    setIsLoadingAddressAPI:(loading : boolean)=> createAction(SET_ISLOADING_ADDRESS_API, loading),
    setAreAllSimulationsLoading:(loading : boolean)=> createAction(SET_ARE_ALL_SIMULATIONS_LOADING, loading),
    setEnoptiIsLoading:(loading : boolean)=> createAction(SET_ENOPTI_IS_LOADING, loading),
};

export type loaderActionsType = ActionsUnion<typeof loaderActions>;
