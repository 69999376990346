import AdjustmentIcon from "../../../../../../components/assets/icon/AdjustmentIcon";
import React from "react";
import withConditionalRender from "../../../../../wrapper/withConditionalRender";
import {typologyFormActions} from "../../../../../../../../../corelogic/usecases/typology-form/typologyFormActions";
import {useDispatch, useSelector} from "react-redux";
import {
    selectDisplayRecalageForm,
    selectUndefinitiveNewCurve
} from "../../../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import {useSnackbar} from "notistack";
import {
    selectNewCurveIsValid
} from "../../../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-curve/selectFormCurve";

function Recalage() {
    const dispatch = useDispatch()
    const click = useSelector(selectDisplayRecalageForm)
    const enable = useSelector(selectNewCurveIsValid)
    const {enqueueSnackbar} = useSnackbar()
    const isClickedStyle = enable ?
        (click ? ' border-2 border-indigo-600 hover:cursor-pointer hover:bg-indigo-300 dark:hover:bg-indigo-500 dark:hover:bg-opacity-20 '
            : ' hover:cursor-pointer hover:bg-indigo-300 dark:hover:bg-indigo-500  dark:hover:bg-opacity-20 ')
        : ' opacity-40 '

    function onClickBtn(){
        if(enable){
            dispatch(typologyFormActions.setFullCompletudeButton(false))
            dispatch(typologyFormActions.setRecalageButtonAction(!click))
            dispatch(typologyFormActions.showLowCompletenessForm(false))
        } else {
            enqueueSnackbar('La courbe doit être valide pour recaler en énergie.', {variant:'warning'})
        }
    }
    return <span
        onClick={onClickBtn}
        className={isClickedStyle + "flex  p-1 gap-1 px-2 bg-indigo-300 bg-opacity-60 dark:bg-indigo-400 items-center rounded dark:bg-opacity-20"}>
        <AdjustmentIcon className={"text-indigo-600 h-5 w-5 dark:text-indigo-200"}/>
         <span className={'font-semibold dark:text-indigo-200 text-indigo-600 text-xs truncate'}>
             Recaler en énergie
         </span>
</span>

}

export default withConditionalRender(Recalage, selectUndefinitiveNewCurve)

