import {useDispatch, useSelector} from "react-redux";
import {selectDisplayCDCUploadBtn} from "../../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import {selectIsLoadingNewCurve} from "../../../../../../../../corelogic/usecases/loader/loaderSelector";
import {typologyFormActions} from "../../../../../../../../corelogic/usecases/typology-form/typologyFormActions";
import React from "react";
import LoadSpinner from "../../../../../components/assets/Animated/LoadSpinner";
import XMarkIcon from "../../../../../components/assets/icon/XMarkIcon";

export default function XIcon() {
    const upload = useSelector(selectDisplayCDCUploadBtn)
    const loading = useSelector(selectIsLoadingNewCurve)
    const dispatch = useDispatch()

    return upload ? (
        loading ?
            <LoadSpinner/>
            :
            <div
                onClick={() => dispatch(typologyFormActions.resetCDCUpload())}>
                <XMarkIcon className={"h-5 w-5 text-gray-600 hover:bg-blue-200 rounded hover:cursor-pointer"}/>
            </div>) : <></>
}
