import {useSelector} from "react-redux";
import {Organisation} from "../../../../../../corelogic/models/types/simulations-display/Organisation";
import {selectOrganisations} from "../../../../../../corelogic/usecases/authentication/authenticationSelector";
import {selectOrganisationIdWithCreditLeft} from "../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form/newSimulationSelector";
import React, {useEffect} from "react";
import OrganisationsSelectorInput from "../../../components/assets/FormAssets/selector/OrganisationsSelectorInput";

export default function OrganisationInput({
                               organisationId,
                               setOrganisationId,
                               isNoSavedOrganisationId
                           }: { organisationId: string, setOrganisationId(id: string): void, isNoSavedOrganisationId: boolean }) {
    const organisations: Organisation[] = useSelector(selectOrganisations)
    const defaultOrganisationWithLeftCredit = useSelector(selectOrganisationIdWithCreditLeft)


    useEffect(() => {
        if (isNoSavedOrganisationId && defaultOrganisationWithLeftCredit) {
            setOrganisationId(defaultOrganisationWithLeftCredit)
        }
    }, [defaultOrganisationWithLeftCredit])

    function organisationOnChange(id: string) {
        setOrganisationId(id);
    }

    function partOfAtLeastTwoOrganisations() {
        return organisations.length > 1
    }

    return partOfAtLeastTwoOrganisations() ?
        <div className={"mx-4 mt-2 mb-6"}>
            <div className={"w-full mb-8"}>
                <OrganisationsSelectorInput label={"ORGANISATION RATTACHÉE"} id={"step1-orga-attached"}
                                            onChange={(id:string) => organisationOnChange(id)}
                                            valueId={organisationId}
                                            options={organisations}
                />
            </div>
        </div> : <></>

}

