import {newSimulationActionsType, SET_FORM_IS_VALID} from "../../newSimulationActions";

const formIsValidInitialState = true;
const formIsValidReducer = (state = formIsValidInitialState, action: newSimulationActionsType) => {
    switch (action.type) {
        case SET_FORM_IS_VALID:
            return action.payload
        default:
            return state;
    }
}
export default formIsValidReducer