import {ConsumptionPoint} from "../../../models/types/new-simulation/form/ConsumptionPoint";
import {INITIAL_MONTHLY_SEASON} from "./useSeasonalityRetrieve";
import {Seasonality} from "../../../models/types/new-simulation/form/operation-parameters/seasonality/Seasonality";

function toggle(seasonality:Seasonality, consumptionPointsIndex: number, month: number):Seasonality {
    let updatedSeasonality = seasonality
    if(consumptionPointsIndex < seasonality.seasonActivated.length){
        updatedSeasonality.seasonActivated[consumptionPointsIndex][month] = !updatedSeasonality.seasonActivated[consumptionPointsIndex][month]
    }
    return updatedSeasonality
}

function add(seasonality:Seasonality,consumptionPoint: ConsumptionPoint) {
    seasonality.consumptionPoints.push(consumptionPoint);
    seasonality.seasonActivated.push(INITIAL_MONTHLY_SEASON);
}

function remove(seasonality:Seasonality,consumptionPoint: ConsumptionPoint) {
    const index = seasonality.consumptionPoints.indexOf(consumptionPoint);
    if (index > -1) {
        seasonality.consumptionPoints.splice(index, 1);
        seasonality.seasonActivated.splice(index, 1);
    }
}

function hasChanged(seasonality:Seasonality | null, backendSeasonality: Seasonality | null): boolean {
    if(!seasonality) return false
    if(!backendSeasonality && !hasSeasonality(seasonality)) return false
    return JSON.stringify(seasonality.seasonActivated) !== JSON.stringify(backendSeasonality?.seasonActivated)
}
function hasSeasonality(seasonality:Seasonality | null): boolean {
    if(!seasonality) return false
    return seasonality.seasonActivated.some((season) => season.some((month) => !month))
}
function init(consumptionPoints: ConsumptionPoint[]): Seasonality {
    return {consumptionPoints, seasonActivated: consumptionPoints.map(() => [...INITIAL_MONTHLY_SEASON])}
}
export const seasonalityUtils = {
    toggle,
    add,
    remove,
    hasChanged,
    hasSeasonality,
    init
}
