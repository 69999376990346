import LowCompletudeDate, {exludeEndDateForBackend} from "./LowCompletudeDate";
import {Curve} from "../../../../../../../../../../corelogic/models/types/new-simulation/curve/Curve";
const ONE_YEAR_IN_DAYS = 365
export default class LowCompletudeInput {
    private _dateStart: LowCompletudeDate;
    private _dateEnd: LowCompletudeDate;
    private _value: string; // Volume in kWh


    constructor(dateStart: LowCompletudeDate, dateEnd: LowCompletudeDate, value: string) {
        this._dateStart = dateStart;
        this._dateEnd = dateEnd;
        this._value = value;
    }

    serialize() {
        return {
            start_date: this.dateStart.serialize(),
            end_date: exludeEndDateForBackend(this.dateEnd).serialize(),
            volume: parseInt(this.value) * 1000// in Wh for backend
        }
    }

    duration() {
        const dateEnd = this.dateEnd.makeAsDate()
        const dateStart = this.dateStart.makeAsDate()
        if (!dateStart || !dateEnd) {
            return 0
        }
        // Calculate the duration in milliseconds
        const millisecondsInADay = 24 * 60 * 60 * 1000; // 24 hours/day * 60 minutes/hour * 60 seconds/minute * 1000 milliseconds/second
        const durationInMilliseconds = dateEnd.getTime() - dateStart.getTime();
        return durationInMilliseconds / millisecondsInADay
    }

    set dateStart(value: LowCompletudeDate) {
        this._dateStart = value;
    }

    set dateEnd(value: LowCompletudeDate) {
        this._dateEnd = value;
    }

    set value(value: string) {
        this._value = value;
    }

    get dateStart(): LowCompletudeDate {
        return this._dateStart;
    }

    get dateEnd(): LowCompletudeDate {
        return this._dateEnd;
    }

    get value(): string {
        return this._value;
    }


    isDateEndOlderThanToday(): boolean {
        const dateEndAsDate = this.dateEnd.makeAsDate()
        if (!dateEndAsDate) return false
        else {
            const today = new Date()
            return dateEndAsDate > today
        }
    }

    isDateStartOlderThanDateEnd(): boolean {
        const dateEnd = this.dateEnd.makeAsDate()
        const dateStart = this.dateStart.makeAsDate()
        if (!dateStart || !dateEnd) return false
        else return dateStart >= dateEnd
    }

    isDurationGreaterThanOneYear(): boolean {
        return this.duration() > oneYearInDays(this.dateStart.makeAsDate(), this.dateEnd.makeAsDate())
    }

    dateEndHasError(): boolean {
        const maxDay = new Date(parseInt(this.dateEnd.years), parseInt(this.dateEnd.months), 0).getDate();
        const daysError = Number(this.dateEnd.days) > maxDay || this.dateEnd.daysHasError()
        return this.dateEnd.yearsHasError() || daysError || this.dateEnd.monthsHasError() || this.isDateEndOlderThanToday() || this.isDateStartOlderThanDateEnd() || this.isDurationGreaterThanOneYear()
    }

    validateVolume(): boolean {
        return this.value.length > 0 && !isNaN(Number(this.value))
    }

    validateRow(): boolean {
        return !this.dateStart.dateHasError() && !this.dateEndHasError() && this.validateVolume()
    }

    displayRowError(): string {
        if (this.dateStart.dateHasError()) return 'la date de début ' + this.displayDateStart() + ' est incorrecte.'
        if (this.dateEndHasError()) {
            if (this.dateEnd.dateHasError()) return 'la date de fin ' + this.displayDateEnd() + ' est incorrecte.'
            if (this.isDateEndOlderThanToday()) return 'la date de fin ' + this.displayDateEnd() + ' ne peux pas être supérieure à la date du jour.'
            if (this.isDateStartOlderThanDateEnd()) return 'la date de début (' + this.displayDateStart() + ') est supérieure ou égale à la date de fin (' + this.displayDateEnd() + ').'
            if (this.isDurationGreaterThanOneYear()) return 'la durée entre la date de début  (' + this.displayDateStart() + ') et la date de fin (' + this.displayDateEnd() + ')  est supérieure à 1 an.'
            return 'la date de fin ' + this.displayDateEnd() + ' est incorrecte.'
        }
        return 'le volume est incorrect.'
    }

    displayDateStart() {
        return this.dateStart.days + '/' + this.dateStart.months + '/' + this.dateStart.years
    }

    displayDateEnd() {
        return this.dateEnd.days + '/' + this.dateEnd.months + '/' + this.dateEnd.years
    }


}

export function oneYearInDays(dateStart:Date, dateEnd:Date):number{
    const ONE_YEAR_IN_DAYS_FOR_LEAP_YEARS = ONE_YEAR_IN_DAYS + 1
    function isLeapYear(year:number) {
        // Leap years are divisible by 4
        if (year % 4 === 0) {
            // If it's divisible by 100, it must also be divisible by 400 to be a leap year
            if (year % 100 === 0) {
                return year % 400 === 0;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }
    function containsFebruary29InRange(dateStart:Date, dateEnd:Date) {
        for (let year = dateStart.getFullYear(); year <= dateEnd.getFullYear(); year++) {
            if (isLeapYear(year)) {
                const leapYearDate = new Date(year, 1, 29);
                if (leapYearDate >= dateStart && leapYearDate <= dateEnd) {
                    return true;
                }
            }
        }
        return false;
    }
    if(containsFebruary29InRange(dateStart, dateEnd)) return ONE_YEAR_IN_DAYS_FOR_LEAP_YEARS
    else return ONE_YEAR_IN_DAYS
}


export function validateEveryRowsAreValid(table: LowCompletudeInput[]): boolean {
    return table.every((row: LowCompletudeInput) => row.validateRow())
}

export function validateMaximalDurationBetweenRows(sortedDescTable: LowCompletudeInput[]):boolean {
    let totalDuration:number = 0
    sortedDescTable.forEach((row:LowCompletudeInput) => {
        totalDuration += row.duration()
    })
    return totalDuration <= oneYearInDays(sortedDescTable[sortedDescTable.length-1].dateStart.makeAsDate(), sortedDescTable[0].dateEnd.makeAsDate())
}

export function validateNoOverlapBetweenRows(table: LowCompletudeInput[], targetYear: number): boolean {
    const isNotOverlaping = table.every((range, index, array) => {
        if (index === 0) {
            return true; // The first range is always coherent
        }
        const previousStartDate = new Date(`${targetYear}-${array[index - 1].dateStart.months}-${array[index - 1].dateStart.days}`);
        const previousEndDate = new Date(`${targetYear}-${array[index - 1].dateEnd.months}-${array[index - 1].dateEnd.days}`);

        const currentStartDate = new Date(`${targetYear}-${range.dateStart.months}-${range.dateStart.days}`);
        const currentEndDate = new Date(`${targetYear}-${range.dateEnd.months}-${range.dateEnd.days}`);
        return currentStartDate >= previousEndDate || currentEndDate <= previousStartDate
    });
    return isNotOverlaping

}

export function validateCoherencyBetweenRows(table: LowCompletudeInput[]): boolean {
    const isCoherent = table.every((range, index, array) => {
        if (index === 0) {
            return true; // The first range is always coherent
        }
        const previousEndDate = new Date(`${array[index - 1].dateEnd.years}-${array[index - 1].dateEnd.months}-${array[index - 1].dateEnd.days}`);
        const currentStartDate = new Date(`${range.dateStart.years}-${range.dateStart.months}-${range.dateStart.days}`);

        return currentStartDate < previousEndDate;
    });
    return isCoherent

}

export function sortLowCompletudeTableByDesc(dateRanges: LowCompletudeInput[]): LowCompletudeInput[] {
    return dateRanges.slice().sort((a, b) => a.dateStart.makeAsDate().getTime() - b.dateStart.makeAsDate().getTime()).reverse();
}

