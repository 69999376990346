import React from "react";
import azimuthGif from "../../utils/azimuth.gif"

export default function () {
    return <div
        className=" absolute right-[80px] z-50 bottom-[105px]  h-0 w-0  mt-2  shadow-lg  hover:cursor-default "
        role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex={-1}>
        <div className="py-1 z-[1] w-[450px] bg-white text-gray-700 dark:bg-zinc-800 dark:text-zinc-200 rounded-md" role="none">
            <span
                className={" block w-full text-left px-4 pt-2 text-md font-semibold"}>Angle d'orientation ou azimut</span>
            <hr className="my-3 w-full  dark:border-zinc-600"/>
            <div className={"flex flex-row justify-center"}>
                <img height="150px" width="150px" src={azimuthGif} alt={"inclinaison_gif"}/>
                <span className={"whitespace-pre-line  block w-full text-left px-4 pt-2 text-sm"}>
C'est l'angle des panneaux photovoltaïques par rapport à la direction sud. -90° est est, 0° est sud et 90° est ouest.                </span>

            </div>

        </div>
    </div>
}
