import {createAction} from "../../../store/createAction";
import {ActionsUnion} from "../../../store/actionsUnions";

export const HANDLE_SNACKBA_ERROR = 'HANDLE_SNACKBA_ERROR';
export const SET_SNACKBAR_ERROR = 'SET_SNACKBAR_ERROR';
export const RESET_SNACKBAR_ERROR = 'RESET_SNACKBAR_ERROR';
export const snackBarErrorsActions = {
    handleError: (error: Error) => createAction(HANDLE_SNACKBA_ERROR, error),
    setError:(error:string) => createAction(SET_SNACKBAR_ERROR, error),
    resetError:() => createAction(RESET_SNACKBAR_ERROR)
};

export type Actions = ActionsUnion<typeof snackBarErrorsActions>;