import {ConsumerType, consumerTypeFromObject} from "./ConsumerType";
import {ContractType, contractTypeFromObject} from "./ContractType";
import {HeatingType} from "./HeatingType";
import {AccommodationType} from "./AccommodationType";
import {ComplementProviderKwhPrice} from "./ComplementProviderKwhPrice";
import {SegmentDetailed, SegmentDetailedFromObject} from "./SegmentDetailed";
import {AddressGouv} from "../external-api/AddressGouv";
import {PeakOffPeakHoursDefinition} from "./PeakOffPeakHoursDefinition";
import {Pricing} from "./Pricing";
import SeasonalPeakOffPeakPricing from "./SeasonalPeakOffPeakPricing";
import FlatPeakOffPeakPricing from "./FlatPeakOffPeakPricing";

export class ConsumptionPoint {

    private _id: string;
    private _name: string;
    private _consumerType: ConsumerType;
    private _numberOfPoints: number;
    private _injectionPointId: string | null;
    private _loadCurveId: string;
    private _segment: SegmentDetailed;
    private _contractType: ContractType;
    private _complementProviderKwhPrice: ComplementProviderKwhPrice;
    private _complementProviderKwhPriceInflationRate ?: number;
    private _heatingType: HeatingType | null;
    private _accommodationType: AccommodationType | null;
    private _state: boolean; // activated / desactivated point
    private _isVATRecoverable: boolean
    private _address: AddressGouv | null


    constructor(id: string, name: string, consumerType: ConsumerType, numberOfPoints: number, injectionPointId: string | null, loadCurveId: string, segment: SegmentDetailed, contractType: ContractType, complementProviderKwhPrice: ComplementProviderKwhPrice, complementProviderKwhPriceInflationRate: number, heatingType: HeatingType | null, accommodationType: AccommodationType | null, state: boolean, isVATRecoverable: boolean, address: AddressGouv | null) {
        this._id = id;
        this._name = name;
        this._consumerType = consumerType;
        this._numberOfPoints = numberOfPoints;
        this._injectionPointId = injectionPointId;
        this._loadCurveId = loadCurveId;
        this._segment = segment;
        this._contractType = contractType;
        this._complementProviderKwhPrice = complementProviderKwhPrice;
        this._complementProviderKwhPriceInflationRate = complementProviderKwhPriceInflationRate;
        this._heatingType = heatingType;
        this._accommodationType = accommodationType;
        this._state = state;
        this._isVATRecoverable = isVATRecoverable;
        this._address = address;

    }

    static fromObject(updatedConsPoint:any): ConsumptionPoint {
        return new this(updatedConsPoint.id,
            updatedConsPoint.name.trim().replace("  ", " "),
            consumerTypeFromObject(updatedConsPoint.consumer_type),
            updatedConsPoint.number_of_points,
            updatedConsPoint.injection_point_id,
            updatedConsPoint.load_curve_id,
            SegmentDetailedFromObject(updatedConsPoint.segment, updatedConsPoint.sub_segment),
            contractTypeFromObject(updatedConsPoint.contract_type),
            typeof updatedConsPoint.complement_provider_kwh_price === 'number'
                ? new ComplementProviderKwhPrice(updatedConsPoint.complement_provider_kwh_price)
                : updatedConsPoint.complement_provider_kwh_price.pricing.summer_off_peak_kwh_provider_price
                    ? new ComplementProviderKwhPrice(new PeakOffPeakHoursDefinition(updatedConsPoint.complement_provider_kwh_price.off_peak_start_time, updatedConsPoint.complement_provider_kwh_price.peak_start_time, new Pricing(new SeasonalPeakOffPeakPricing(updatedConsPoint.complement_provider_kwh_price.pricing.summer_off_peak_kwh_provider_price, updatedConsPoint.complement_provider_kwh_price.pricing.summer_peak_kwh_provider_price, updatedConsPoint.complement_provider_kwh_price.pricing.winter_off_peak_kwh_provider_price, updatedConsPoint.complement_provider_kwh_price.pricing.winter_peak_kwh_provider_price))))
                    : new ComplementProviderKwhPrice(new PeakOffPeakHoursDefinition(updatedConsPoint.complement_provider_kwh_price.off_peak_start_time, updatedConsPoint.complement_provider_kwh_price.peak_start_time, new Pricing(new FlatPeakOffPeakPricing(updatedConsPoint.complement_provider_kwh_price.pricing.off_peak_provider_kwh_price, updatedConsPoint.complement_provider_kwh_price.pricing.peak_kwh_provider_price)))),
            updatedConsPoint.complement_provider_kwh_price_inflation_rate,
            updatedConsPoint.heating_type,
            updatedConsPoint.accommodation_type,
            updatedConsPoint.is_active,
            updatedConsPoint.is_vat_recoverable,
            updatedConsPoint.address ? new AddressGouv(updatedConsPoint.address.label,
                updatedConsPoint.address.latitude,
                updatedConsPoint.address.longitude,
                updatedConsPoint.address.context,
                updatedConsPoint.address.name,
                updatedConsPoint.address.type,
                updatedConsPoint.address.city,
                updatedConsPoint.address.postcode) : null
        )
    }

    serialize() {
        return {
            id: this._id,
            name: this._name.trim(),
            consumer_type: this.translateConsumerType(this._consumerType),
            number_of_points: this._injectionPointId ? 1 : this._numberOfPoints,
            injection_point_id: this._injectionPointId === "" ? null : this._injectionPointId,
            load_curve_id: this._loadCurveId,
            segment: this.getSegment(this._segment),
            sub_segment: this.getSubSegment(this._segment),
            contract_type: this.translateContractType(this._contractType),
            complement_provider_kwh_price: this._complementProviderKwhPrice.serialize(),
            heating_type: this._heatingType,
            accommodation_type: this._accommodationType,
            is_active: this._state,
            is_vat_recoverable: this._isVATRecoverable,
            address: this._address?.serialize()

        }

    }

    get address(): AddressGouv | null {
        return this._address;
    }

    set address(value: AddressGouv | null) {
        this._address = value;
    }

    get isVATRecoverable(): boolean {
        return this._isVATRecoverable;
    }

    get state(): boolean {
        return this._state;
    }

    set state(value: boolean) {
        this._state = value;
    }

    getSegment(segment: SegmentDetailed): string {
        if (segment.includes('HTA')) {
            return segment.substring(0, 3)
        } else {
            return segment.substring(0, 2)
        }
    }

    getSubSegment(segment: SegmentDetailed): string {
        if (segment.includes('HTA')) {
            return segment.substring(4)
        } else {
            return segment.substring(3)
        }
    }

    translateConsumerType(consumerType: ConsumerType) {
        switch (consumerType) {
            case ConsumerType['individual']:
                return 'individual';
            case ConsumerType['professional']:
                return 'professional'
        }
    }

    translateContractType(contractType: ContractType) {
        switch (contractType) {
            case ContractType['base']:
                return 'base';
            case ContractType['double_price']:
                return 'double_price';
            case ContractType['quadruple_price']:
                return 'quadruple_price';
        }
    }

    get heatingType(): any {
        return this._heatingType;
    }

    get accommodationType(): any {
        return this._accommodationType;
    }

    get id(): string {
        return this._id;
    }

    set id(value: string) {
        this._id = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get consumerType(): ConsumerType {
        return this._consumerType;
    }

    set consumerType(value: ConsumerType) {
        this._consumerType = value;
    }

    get numberOfPoints(): number {
        return this._injectionPointId ? 1 : this._numberOfPoints;
    }

    set numberOfPoints(value: number) {
        this._numberOfPoints = value;
    }

    get injectionPointId(): string | null {
        return this._injectionPointId;
    }

    set injectionPointId(value: string | null) {
        this._injectionPointId = value;
    }

    isInACIWith(producerId: string): boolean {
        return this._injectionPointId === producerId
    }

    get loadCurveId(): string {
        return this._loadCurveId;
    }

    set loadCurveId(value: string) {
        this._loadCurveId = value;
    }

    get segment(): SegmentDetailed {
        return this._segment;
    }

    set segment(value: SegmentDetailed) {
        this._segment = value;
    }

    get contractType(): ContractType {
        return this._contractType;
    }

    set contractType(value: ContractType) {
        this._contractType = value;
    }

    get complementProviderKwhPrice(): ComplementProviderKwhPrice {
        return this._complementProviderKwhPrice;
    }

    set complementProviderKwhPrice(value: ComplementProviderKwhPrice) {
        this._complementProviderKwhPrice = value;
    }


    set complementProviderKwhPriceInflationRate(value: number) {
        this._complementProviderKwhPriceInflationRate = value;
    }

    set heatingType(value: HeatingType) {
        this._heatingType = value;
    }

    set accommodationType(value: AccommodationType) {
        this._accommodationType = value;
    }
}
