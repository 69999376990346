import React, {useEffect} from "react";
import SimulationsTable from "../composition/table/SimulationsTable";
import {useDispatch, useSelector} from "react-redux";
import {simulationsDisplayActions} from "../../../../corelogic/usecases/simulations-display/simulationsDisplayActions";
import NewPageIcon from "../components/assets/icon/NewPageIcon";
import {newSimulationActions} from "../../../../corelogic/usecases/new-simulation/newSimulationActions";
import {
    selectAreCreditsRemaining,
    selectRemainingNumberOfSimulations,
    selectSelectedOrganisation
} from "../../../../corelogic/usecases/authentication/authenticationSelector";
import {SimulationForm} from "../../../../corelogic/models/interfaces/SimulationForm";
import {UserMailAndId} from "../../../../corelogic/models/types/new-simulation/UserMailAndId";
import {
    financialRestitutionActions
} from "../../../../corelogic/usecases/financialRestitution/financialRestitutionActions";
import {
    physicalRestitutionActions
} from "../../../../corelogic/usecases/physicalRestitution/physicalRestitutionActions";
import {push} from "connected-react-router";
import {authenticationActions} from "../../../../corelogic/usecases/authentication/authenticationActions";
import OrganisationSelector from "../components/assets/selector/OrganisationSelector";
import CreateSimulationsBtn from "../components/assets/button/CreateSimulationsBtn";
import {
    selectAllSimulations,
    selectSimulationsCurrentPage
} from "../../../../corelogic/usecases/simulations-display/simulationsDisplaySelector";
import {selectAreLoadingSimulationsForm} from "../../../../corelogic/usecases/loader/loaderSelector";

export default function () {
    const dispatch = useDispatch()
    const remainingNumberOfSimulations = useSelector(selectRemainingNumberOfSimulations)
    const creditLeft = useSelector(selectAreCreditsRemaining)
    const selectedOrganisation = useSelector(selectSelectedOrganisation)
    const allSimulations = useSelector(selectAllSimulations)
    const currentPage = useSelector(selectSimulationsCurrentPage)

    useEffect(() => {
        if (allSimulations.length === 0) {
            dispatch(simulationsDisplayActions.getAllSimulations())
        }
        dispatch(newSimulationActions.setCurrentStepIndex(0))
        dispatch(newSimulationActions.setNextStepIndex(0))
        dispatch(newSimulationActions.setSimulationForm(new SimulationForm("", "", "", "", new UserMailAndId("", ""), new UserMailAndId("", ""))))
        dispatch(financialRestitutionActions.initData())
        dispatch(physicalRestitutionActions.initData())
        dispatch(authenticationActions.setPasswordUpdated(false))
    }, [])

    useEffect(() => {
        dispatch(simulationsDisplayActions.getSimulations())
    }, [currentPage]);

    function redirectToCreationPage() {
        dispatch(push('/simulation/create'))
    }

    return (
        <div
            className={"min-w-[640px]  h-full-minus-navbar overflow-auto only:w-full flex items-start justify-center bg-gray-100 font-sans dark:bg-zinc-800"}>
            <div className="mt-8 w-5/6 scale-95 2xl:scale-100 transform-none 2xl:transform-none">
                <div className={"flex justify-between w-full items-end"}>
                    <div className="text-2xl font-semibold text-gray-700 dark:text-gray-300 flex items-center">Les
                        simulations de
                        <OrganisationSelector/>
                    </div>
                    <div className=" flex flex-col w-min">
                        <CreateSimulationsBtn dataCy={"homepage-huge-btn"}
                                              disabled={selectedOrganisation ? remainingNumberOfSimulations === 0 : !creditLeft}
                                              title="Nouvelle simulation"
                                              IconComponent={NewPageIcon} action={redirectToCreationPage}/>
                    </div>
                </div>
                <SimulationsTable/>
            </div>
        </div>
    )
}



