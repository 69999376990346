import {
    Actions,
    INIT_PHYSICAL_DATA,
    INIT_SELECTED_POINT,
    SET_CDC_DATA,
    SET_CDC_GROUPED_BY_MONTH_DATA,
    SET_CDC_GROUPED_BY_MONTH_LOADING,
    SET_CDC_LOADING,
    SET_IS_ACI,
    SET_METRICS,
    SET_METRICS_LOADING,
    SET_SELECTED_POINT
} from "./physicalRestitutionActions";
import MetricsData from "../../models/types/physicalRestitution/MetricsData";
import CDCData from "../../models/types/physicalRestitution/CDCData";
import {combineReducers} from "redux";

export type SelectedType = { id?: string, scope?: string, aci?: boolean }

const initialSelectedTypeState: SelectedType = {}
const selectedTypeReducer = (state = initialSelectedTypeState, action: Actions) => {
    switch (action.type) {
        case SET_SELECTED_POINT:
            return {...state, ...action.payload};
        case SET_IS_ACI:
            return {...state, aci: action.payload};
        case INIT_SELECTED_POINT:
            return {};
        default:
            return state;
    }
}
type PointData = {
    metrics: {
        [key in 'acc' | 'aci']: MetricsData
    },
    CDC: {
        [key in 'acc' | 'aci']: CDCData
    },
    CDCGroupedByMonth: {
        [key in 'acc' | 'aci']: any
    },
}
type PhysicalReducerType = {
    [pointId: string]: PointData
}
const initialPhysicalReducerState = {}
const physicalReducer = (state: PhysicalReducerType = initialPhysicalReducerState, action: Actions) => {

    let pointData = {} as PointData

    switch (action.type) {
        case SET_METRICS:
            pointData = state[action.payload.pointId] || {}
            return {
                ...state, [action.payload.pointId]: {
                    ...pointData,
                    metrics: {
                        ...(pointData.metrics || {}),
                        [action.payload.isAci ? 'aci' : 'acc']: action.payload.metrics,
                    }
                }
            };
        case SET_CDC_DATA:
            pointData = state[action.payload.pointId] || {}
            return {
                ...state, [action.payload.pointId]: {
                    ...pointData,
                    CDC: {
                        ...(pointData.CDC || {}),
                        [action.payload.isAci ? 'aci' : 'acc']: action.payload.data,
                    }
                }
            };
        case INIT_PHYSICAL_DATA :
            return {}

        case SET_CDC_GROUPED_BY_MONTH_DATA:
            pointData = state[action.payload.pointId] || {}
            return {
                ...state, [action.payload.pointId]: {
                    ...pointData,
                    CDCGroupedByMonth: {
                        ...(pointData.CDCGroupedByMonth || {}),
                        [action.payload.isAci ? 'aci' : 'acc']: action.payload.data,
                    }
                }
            };
        default:
            return state;
    }
}

const initLoadingsState = {
    metrics: true,
    CDC: true,
    CDCGroupedByMonth: true,

}
const loadingsReducer = (state = initLoadingsState, action: Actions) => {

    switch (action.type) {

        case SET_CDC_LOADING:
            return {
                ...state,
                CDC: action.payload.loading
            }


        case SET_CDC_GROUPED_BY_MONTH_LOADING:
            return {
                ...state,
                CDCGroupedByMonth: action.payload.loading
            }

        case SET_METRICS_LOADING:
            return {
                ...state,
                metrics: action.payload.loading
            }
        default:
            return state;
    }
}

export default combineReducers({
    selectedType: selectedTypeReducer,
    physicalResult: physicalReducer,
    loadings: loadingsReducer,
});
