import RegularBtn from "../../../../../components/assets/button/RegularBtn";
import LoadSpinner from "../../../../../components/assets/Animated/LoadSpinner";
import DownloadIcon from "../../../../../components/assets/icon/DownloadIcon";
import {ButtonState} from "../../../../../../../../config/app-config";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {typologyFormActions} from "../../../../../../../../corelogic/usecases/typology-form/typologyFormActions";
import {
    selectCanFetchSGECurve,
    selectSGEPRMDisplayer
} from "../../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import {selectIsLoadingNewCurve} from "../../../../../../../../corelogic/usecases/loader/loaderSelector";
import ConfirmModal from "../../../../confirmation-dialog/ConfirmModal";

export default function GetSGECurveButton() {
    const loading = useSelector(selectIsLoadingNewCurve)
    const prm = useSelector(selectSGEPRMDisplayer)
    const canFetchCurve = useSelector(selectCanFetchSGECurve)
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
    const dispatch = useDispatch()

    function buttonAction() {
        if (canFetchCurve) {
            dispatch(typologyFormActions.fetchSGECurve())
        }
        setOpenConfirmDialog(false)
    }

    return <>
        {openConfirmDialog && <ConfirmModal
            title={"Récupération avec accord du prm : " + prm}
            description={"Vous confirmez avoir l'accord pour récupérer la courbe de charge du PRM :" + prm + "."}
            actionContinue={buttonAction}
            actionCancel={() => setOpenConfirmDialog(false)}
        />}
        <RegularBtn
            action={() => setOpenConfirmDialog(true)}
            title={loading ? 'Récupération en cours (peut prendre quelques minutes) ...' : 'Récupérer la courbe de charge SGE'}
            IconComponent={() => loading ? <LoadSpinner/> : <DownloadIcon className={"h-6 w-6 "}/>}
            state={canFetchCurve ? ButtonState.REGULAR : ButtonState.DISABLED}
        />
    </>
}

