import {
    SurplusAllocation,
    SurplusAllocationAlgorithm
} from "../../../models/types/new-simulation/form/operation-parameters/surplus-allocation/SurplusAllocation";

export const isSurplusAllocationByPriority = (algorithm: SurplusAllocationAlgorithm) => {
    return algorithm === SurplusAllocationAlgorithm.by_priority
}
export const isSurplusAllocationByDefault = (algorithm: SurplusAllocationAlgorithm): boolean => {
    return algorithm === SurplusAllocationAlgorithm.by_default
}
export const sanitizeSurplusAllocation = (surplusAllocation: SurplusAllocation): SurplusAllocation => {
    if(isSurplusAllocationByDefault(surplusAllocation.selectedAlgorithm)) return surplusAllocation
    const priorityIndexes: number[] = Array.from(new Set<number>(surplusAllocation.priorities.map((p) => p.priority)))
    priorityIndexes.sort()
    const sanitisedPriorities = surplusAllocation.priorities.map((p) => {
      return {
        ...p,
        priority: priorityIndexes.indexOf(p.priority) +1
      }
    })
    return {
        ...surplusAllocation,
        priorities: sanitisedPriorities
    }
}

