import {call, getContext, put, select, takeLatest} from "redux-saga/effects";
import {GET_RESTITUTION, restitutionActions} from "./restitutionActions";
import {selectSelectedSimulationId} from "../new-simulation/new-simulation-selector/form/newSimulationSelector";
import {RestitutionGatewayInterface} from "./restitutionGateway.interface";
import {snackBarErrorsActions} from "../errors/snackbarErrorsActions";


function* getRestitution(): any {
    try {
        yield put(restitutionActions.setRestitutionLoading(true))
        const simulationId: string = yield select(selectSelectedSimulationId);
        const restitutionGateway: RestitutionGatewayInterface = yield getContext('restitutionGatewayContext');
        const restitution = yield call(restitutionGateway.getRestitution, simulationId)
        yield put(restitutionActions.setRestitution(restitution))
        yield put(restitutionActions.setRestitutionLoading(false))
    } catch (error: any) {
        yield put(
            restitutionActions.setRestitution({}),
        )
        yield put(snackBarErrorsActions.handleError(error));
    }
    yield put(restitutionActions.setRestitutionLoading(false))
}


function* getRestitutionSaga() {
    yield takeLatest(GET_RESTITUTION, getRestitution);
}


const RestitutionSagas = [getRestitutionSaga]
export default RestitutionSagas;
